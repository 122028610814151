import {SxProps} from '@mui/material';
import theme from '$modules/booking/theme';
import {BookingScreenColors} from '../../modules/booking/constant';

const styles: Record<string, SxProps> = {
  container: {
    display: 'flex',
    position: 'relative',
    flex: 1,
    gridGap: theme.spacing(1),
  },
  item: {
    display: 'flex',
    position: 'relative',
    flex: 1,

    '&:after': {
      background: theme.palette.common.white,
      position: 'absolute',
      content: '""',
      bottom: 0,
      width: '100%',
      height: '4px',
      borderradius: '0.625rem',
    },
  },
  coloredItem: {
    display: 'flex',
    position: 'relative',
    flex: 1,

    '&:after': {
      background: BookingScreenColors.StepperBottomBarGradient,
      position: 'absolute',
      content: '""',
      bottom: 0,
      width: '100%',
      height: '4px',
      borderradius: '0.625rem',
    },
  },
};

export default styles;
