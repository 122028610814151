import React from 'react';
import {Button, Divider} from '@mui/material';
import {ArrowLeftIcon} from '$assets/svgs';

interface QuestionnaireHeaderProps {
  handleBack: () => void;
  'data-testid'?: string;
}

const QuestionnaireHeader: React.FC<QuestionnaireHeaderProps> = ({
  handleBack,
  'data-testid': testId,
}) => {
  return (
    <header
      className="flex flex-col font-primary bg-primary h-fit"
      data-testid={testId}
    >
      <div className="px-10 py-5 border-0 border-b-1 border-solid border-divider">
        <Button
          startIcon={<ArrowLeftIcon />}
          className="!normal-case !font-normal !text-base !leading-6 !tracking-tighter text-primary-dark"
          onClick={handleBack}
        >
          Back to clinical data
        </Button>
        <Divider className="pt-5 px-10" />
      </div>
    </header>
  );
};

export default QuestionnaireHeader;
