import {Updater} from 'use-immer';
import MenuItem from '@mui/material/MenuItem';
import Loading from '~/components/Loading';
import FetchError from '~/components/FetchError';
import RenderSVG from '~/components/RenderSVG';
import SelectInput from '~/components/SelectInput';
import ActionCard from '~/modules/booking/components/ActionCard';
import Footer from '~/modules/booking/components/Footer';
import {
  BookingData,
  BookingView,
  SubcareSelectionViewChoices,
} from '~/modules/booking/types';
import {useAPIConstants} from '~/services/api/constants/hooks';
import {ConstantsAPIKey} from '~/services/api/constants/constants';
import {Subcare} from '~/services/api/constants/types';
import styles, {careSelectionViewStyles} from '../styles';
import {Box, Stack, Typography} from '@mui/material';

interface SubcareSelectionViewProps {
  bookingChoices: SubcareSelectionViewChoices;
  setActiveView: React.Dispatch<React.SetStateAction<BookingView>>;
  setBookingChoices: Updater<BookingData>;
}

function SubcareSelectionView(props: SubcareSelectionViewProps) {
  const {bookingChoices, setActiveView, setBookingChoices} = props;
  const {
    care: {id: careId},
  } = bookingChoices;
  const {data: items, isLoading} = useAPIConstants({
    key: ConstantsAPIKey.Subcare,
    careId: careId,
  });

  if (isLoading) return <Loading />;
  if (!items) return <FetchError />;

  const handleSubcareTypeChange = (value: string) => {
    setBookingChoices(draft => {
      if (!value) {
        draft.subcare = undefined;
      } else {
        draft.subcare = items.find(it => it.id.toString() === value);
      }
    });
  };

  const handleBackClick = () => {
    setActiveView(BookingView.CareSelectionView);
  };

  const handleContinueClick = () => {
    setActiveView(BookingView.ConsultantSelectionView);
  };

  const renderMenuItem = (item: Subcare) => {
    return (
      <MenuItem
        key={item.id}
        value={item.id.toString()}
        sx={careSelectionViewStyles.menuItemContainer}
        disabled={!item.isEnable}
        data-testid={`sub-care-${item.title}-menu-item`}
      >
        <Typography
          variant="subtitle1"
          sx={careSelectionViewStyles.menuItemTitle}
        >
          {item.title}
        </Typography>
        <Typography
          variant="caption"
          sx={careSelectionViewStyles.menuItemCaption}
        >
          {item.description}
        </Typography>
      </MenuItem>
    );
  };

  const renderValue = (value: string) => {
    if (!value) return 'Select a surgery';

    const item = items.find(it => it.id.toString() === value);
    if (!item) {
      handleSubcareTypeChange('');
      return '';
    }

    return item.title;
  };

  return (
    <Box sx={styles.sectionWrapper} flexDirection="column" gap={5}>
      <Stack gap={6} sx={styles.section}>
        <Box>
          <Typography variant="h2" sx={styles.title} textAlign="center">
            What type of care are you looking for?
          </Typography>
        </Box>
        <ActionCard
          active
          icon={
            bookingChoices.care.image ? (
              <RenderSVG svg={bookingChoices.care.image} />
            ) : null
          }
          title={bookingChoices.care.title}
          description={bookingChoices.care.description}
        />
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="h3" sx={styles.stepsTypeHead}>
            What surgery are you interested in?
          </Typography>
          <SelectInput
            items={items}
            renderValue={renderValue}
            placeholder="Select a surgery"
            renderItem={renderMenuItem}
            value={bookingChoices.subcare?.id.toString() || ''}
            onChange={handleSubcareTypeChange}
            testId="consultant-dropdown"
          />
        </Box>
      </Stack>
      <Box sx={styles.footerSection}>
        <Footer
          disableContinue={bookingChoices.care === undefined}
          onBackClick={handleBackClick}
          onContinueClick={handleContinueClick}
        />
      </Box>
    </Box>
  );
}

export default SubcareSelectionView;
