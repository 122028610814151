import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import {getAppointmentList} from '$api/frontdesk';
import {useMemo} from 'react';
import {AppointmentInfo} from '$modules/frontdesk/type';
import {getDocumentsList} from '$api/patientList/documents';
import {TestRecord} from './types';

export function useConsultationList(patientId: number) {
  const {data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage} =
    useInfiniteQuery({
      queryKey: ['timeline-consultation-list'],
      queryFn: ({pageParam}) =>
        getAppointmentList({
          perPage: 15,
          pageNumber: pageParam,
          orderBy: 'date',
          orderDirection: 'desc',
          patientId: patientId,
        }),
      initialPageParam: 1,
      getNextPageParam: (lastPage, _, lastPageParam) =>
        lastPageParam === lastPage.totalPages ? null : lastPageParam + 1,
    });

  const consultationSections = useMemo(() => {
    // sectionKey = 'year/month'
    // eg. 2024/02
    // sectionMap = { [sectionKey]: AppointmentInfo[] }
    const sections: {
      sectionKey: string;
      sectionTitle: string;
      records: AppointmentInfo[];
    }[] = [];

    const pages = data?.pages || [];

    let previousSectionKey = '';

    return pages.reduce((sectionList, page) => {
      const records = page.appointments || [];

      records.forEach(app => {
        const appointmentDate = app.appointment.date;
        const year = appointmentDate.getFullYear();
        const month = appointmentDate.getMonth() + 1;

        const yearString = year.toString();
        const monthString = month.toString().padStart(2, '0');

        const sectionKey = `${yearString}/${monthString}`;
        const sectionTitle = appointmentDate.toLocaleDateString('en-GB', {
          month: 'long',
          year: 'numeric',
        });

        if (sectionKey !== previousSectionKey) {
          sectionList.push({
            sectionKey: sectionKey,
            sectionTitle: sectionTitle,
            records: [],
          });

          previousSectionKey = sectionKey;
        }

        sectionList[sectionList.length - 1].records.push(app);
      });

      return sectionList;
    }, sections);
  }, [data]);

  const totalRecords = useMemo(() => {
    const pages = data?.pages || [];
    return pages.length === 0 ? 0 : pages[0].totalRecords;
  }, [data]);

  return {
    consultationSections,
    totalRecords: totalRecords || 0,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
}

export function useTestList(patientId: number) {
  const {data, isLoading} = useQuery({
    queryKey: ['tiimeline-test-list', patientId],
    queryFn: () => getDocumentsList({patientId: patientId.toString()}),
  });

  const testSections = useMemo(() => {
    // sectionKey = 'year/month'
    // eg. 2024/02
    // sectionMap = { [sectionKey]: AppointmentInfo[] }
    const sections: {
      sectionKey: string;
      sectionTitle: string;
      records: TestRecord[];
    }[] = [];

    const responseDocuments = data?.data || [];

    let previousSectionKey = '';

    return responseDocuments.reduce((secList, doc) => {
      const docDate = new Date(doc.created_at);

      const year = docDate.getFullYear();
      const month = docDate.getMonth() + 1;

      const yearString = year.toString();
      const monthString = month.toString().padStart(2, '0');

      const sectionKey = `${yearString}/${monthString}`;
      const sectionTitle = docDate.toLocaleDateString('en-GB', {
        month: 'long',
        year: 'numeric',
      });

      if (sectionKey !== previousSectionKey) {
        secList.push({
          sectionKey: sectionKey,
          sectionTitle: sectionTitle,
          records: [],
        });

        previousSectionKey = sectionKey;
      }

      secList[secList.length - 1].records.push({
        id: doc.id,
        name: doc.name,
        performedBy: doc.performed_by,
        patientId: doc.patient_id,
        documentURL: doc.document_url,
        createdBy: new Date(doc.created_at),
      });

      return secList;
    }, sections);
  }, [data]);

  return {
    testSections: testSections,
    totalRecords: data?.data.length || 0,
    isLoading,
    isFetchingNextPage: false,
    fetchNextPage: () => {},
    hasNextPage: false,
  };
}
