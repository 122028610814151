import {useEffect} from 'react';
import Loading from '~/components/Loading';
import {BookingView} from '~/modules/booking/types';
import {useAPIConstants} from '~/services/api/constants/hooks';
import {ConstantsAPIKey} from '~/services/api/constants/constants';

interface TransitionViewProps {
  setActiveView: (view: BookingView) => void;
}

function TransitionView(props: TransitionViewProps) {
  const {setActiveView} = props;

  const {data: hospitalInfo, isLoading} = useAPIConstants({
    key: ConstantsAPIKey.Hospital,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setActiveView(BookingView.AppointmentSelectionView);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [setActiveView]);

  const navigateScreen = () => {
    setActiveView(BookingView.AppointmentSelectionView);
  };

  if (isLoading || !hospitalInfo) {
    return (
      <div
        className="h-screen w-screen flex justify-center items-center background-gradient-booking"
        data-testid="transition-view-loading-container"
      >
        <Loading />
      </div>
    );
  }

  return (
    <div
      className="font-booking h-screen w-screen overflow-auto bg-center bg-cover px-8 py-6 lg:px-20 lg:py-8"
      style={{
        backgroundImage: `url(${hospitalInfo.backgroundImageURL})`,
      }}
      onClick={navigateScreen}
      data-testid="transition-view-container"
    >
      {hospitalInfo.logoURL && (
        <img src={hospitalInfo.logoURL} className="h-20 lg:h-36" />
      )}
      <div className="mt-12">
        <p className="font-bold text-2xl lg:text-3xl">{hospitalInfo.title}</p>
        <p className="font-normal text-base lg:text-2xl">
          {hospitalInfo.description}
        </p>
      </div>
    </div>
  );
}

export default TransitionView;
