import {Modal} from '@mui/material';
import BookingButton from '../BookingButton';

interface LeaveBookingModalProps {
  open: boolean;
  onClose: () => void;
  onLeave: () => void;
}

function LeaveBookingModal(props: LeaveBookingModalProps) {
  const {open, onClose, onLeave} = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="flex justify-center items-center"
    >
      <div
        className="font-booking bg-white max-w-2xl w-full p-6 rounded-lg outline-none shadow-lg"
        data-testid="leave-booking-modal"
      >
        <div>
          <h2 className="font-semibold text-2xl text-dark-primary mb-3">
            Do you want to leave the booking?
          </h2>
          <p className="font-lg text-grey-4">
            If you go back to the homepage now, all your entered{' '}
            <span className="font-semibold">
              booking information will be lost.
            </span>
          </p>
          <p className="font-lg text-grey-4">
            Are you sure you want to leave this page?
          </p>
        </div>
        <div className="flex gap-6 mt-9">
          <BookingButton onClick={onClose} testId="stay-on-page-button">
            Stay on this page
          </BookingButton>
          <BookingButton
            variant="tertiary"
            onClick={onLeave}
            testId="go-to-homepage-button"
          >
            Go to Homepage
          </BookingButton>
        </div>
      </div>
    </Modal>
  );
}

export default LeaveBookingModal;
