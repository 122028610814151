import {SetStateAction, useMemo, useState} from 'react';
import {useMutation, useQuery} from '@tanstack/react-query';
import {
  PostLoginVariables,
  postBookingSignIn,
  PostLoginErrorResponseData,
  postCreateAppointment,
  deleteAppointment,
  getCities,
  sendOtp,
  ResendOtp,
  validateOtp,
  putForgotPassword,
  PutForgotPasswordVariables,
  putResetPassword,
  PutResetPasswordVariables,
  PostCreateAppointmentErrorResponse,
  getPaymentCard,
  getPreferredPointOfContact,
  getCountries,
  postBlockSlots,
  PostBlockSlotsVariables,
  ValidatePostcodeVariables,
  postValidatePostcode,
  PostBlockSlotsResponseData,
} from '$api/bookingFlow';
import {ToastType} from '$components/Toast/constant';
import {handleErrorResponse} from '$utils/axios';
import {
  AppointmentDetailNavigatePayload,
  AppSummaryViewChoices,
  BookingData,
  BookingDataUpdater,
  BookingView,
  Country,
} from './types';
import {
  ValidateOtpResponseToBookingData,
  loginResponseToBookingData,
} from './utils';
import {useNavigate} from 'react-router-dom';
import {RoutePath} from '$constants/routes';
import {UserRoleName} from '$modules/admin/constant';
import {useAppDispatch} from '$redux/hooks';
import {loadAuth} from '$redux/authSlice';
import {AxiosError} from 'axios';
import {
  networkErrorToast,
  technicalGlitchToast,
} from '$constants/toastmessages';
import toast from '$utils/toast';
import {OfflineBookingStep} from '$modules/frontdesk/Calendar/OfflineBooking/constant';
import {FormState} from '$modules/frontdesk/Calendar/OfflineBooking/types';

export function useValidatePostcode(onSuccess: () => void) {
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {mutate, isPending, isError} = useMutation({
    mutationKey: ['validate-postcode'],
    mutationFn: (variables: ValidatePostcodeVariables) =>
      postValidatePostcode(variables),
    onSuccess: onSuccess,
    onError: error => {
      handleErrorResponse(error, 'Validate Postcode');
    },
    onSettled: () => setDisableSubmit(false),
  });

  const validatePostcode = (postcode: string, country: string) => {
    setDisableSubmit(true);
    mutate({postcode, country});
  };

  return {
    isLoading: disableSubmit || isPending,
    validatePostcode,
    isError,
  };
}

export function useBookingSignIn(
  setActiveView: (view: BookingView) => void,
  setBookingChoices: BookingDataUpdater
) {
  const dispatch = useAppDispatch();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {isPending, mutate} = useMutation({
    mutationKey: ['booking-sign-in'],
    mutationFn: postBookingSignIn,
    onSuccess: responseData => {
      const data = responseData.data;

      const token = data.token;
      const patientData = data.patient;

      const authData = {
        refreshToken: data.refresh_token,
        token: token,
        user: {
          id: patientData.id,
          fullName: patientData.full_name,
          hospitalId: null,
          email: patientData.email,
          roleName: UserRoleName.Patient,
          mobileNumber: patientData.mobile_number,
          profileUrl: null,
          timezone: null,
        },
      };

      dispatch(loadAuth(authData));

      const bookingData = loginResponseToBookingData(responseData);
      setBookingChoices(draft => {
        // Auto fill patient details
        // if logged in for the first time
        if (!draft.registered?.email) {
          draft.patientCarerDetails = bookingData.patientCarerDetails;
          draft.patientContactDetail = bookingData.patientContactDetail;
        }

        draft.registered = {
          isRegistered: true,
          email: data.patient.email,
          patientId: data.patient.id,
          patientMobileNumber: data.patient.mobile_number,
        };
      });

      setActiveView(BookingView.PatientCarerDetailsView);
    },
    onError: error =>
      handleErrorResponse<PostLoginErrorResponseData>(error, 'Login'),
    onSettled: () => setDisableSubmit(false),
  });

  const signIn = (variables: PostLoginVariables) => {
    setDisableSubmit(true);
    mutate(variables);
  };

  const {mutate: forgotPasswordMutate} = useMutation({
    mutationKey: ['forgot-password'],
    mutationFn: putForgotPassword,
    onSuccess: data => {
      toast(ToastType.Success, 'Forgot Password', data.message);
    },
    onError: error => handleErrorResponse(error, 'Forgot Password'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendForgotPasswordRequest = (variables: PutForgotPasswordVariables) => {
    setDisableSubmit(true);
    forgotPasswordMutate(variables);
  };

  return {
    disableSubmit,
    isPending,
    signIn,
    sendForgotPasswordRequest,
  };
}

export function useAppointment(
  choices: BookingData,
  setActiveView: (view: BookingView) => void,
  setBookingChoices: BookingDataUpdater
) {
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {isPending: isCreatePending, mutate: createMutate} = useMutation({
    mutationKey: ['create-appointment'],
    mutationFn: postCreateAppointment,
    onSuccess: data => {
      setBookingChoices(draft => {
        draft.appointmentId = data.data.appointment.id;
        if (draft.consultant) {
          draft.consultant.fullName = data.data.appointment.doctor_name;
        }
      });
      setActiveView(BookingView.AppSummaryView);
    },
    onError: error => {
      if (error instanceof AxiosError) {
        const axiosError =
          error as AxiosError<PostCreateAppointmentErrorResponse>;
        const response = axiosError.response;
        if (response) {
          if (response.status === 500) {
            return technicalGlitchToast();
          }
          if (response.data) {
            const data = response.data;
            const errors = data.errors;
            toast(ToastType.Error, 'Create Appointment', errors);
            return;
          }
        }
        if (axiosError.message === 'Network Error') {
          return networkErrorToast();
        }

        return technicalGlitchToast();
      } else {
        technicalGlitchToast();
      }
    },
    onSettled: () => setDisableSubmit(false),
  });

  const saveAppointment = (variables?: BookingData) => {
    setDisableSubmit(true);

    let payload = {...choices};

    if (variables) payload = {...payload, ...variables};

    if (payload) {
      createMutate({
        choices: payload,
      });
    }
  };

  return {
    isPending: isCreatePending,
    disableSubmit,
    saveAppointment,
  };
}

export function useDeleteAppointment(bookingChoices: AppSummaryViewChoices) {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const navigate = useNavigate();

  const {isPending, mutate} = useMutation({
    mutationKey: ['delete-appointment'],
    mutationFn: deleteAppointment,
    onSuccess: data => {
      toast(ToastType.Success, 'Appointment deleted', data.message);
      navigate(RoutePath.deleteSuccessRoute, {
        state: {
          careType: bookingChoices.care.title,
          date: bookingChoices.date,
        } as AppointmentDetailNavigatePayload,
      });
    },
    onError: error =>
      handleErrorResponse<PostLoginErrorResponseData>(
        error,
        'Delete Appointment'
      ),
    onSettled: () => setDisableSubmit(true),
  });

  const requestDeleteAppointment = () => {
    setDisableSubmit(true);
    mutate({id: bookingChoices.appointmentId});
  };

  return {
    disableSubmit,
    isPending,
    requestDeleteAppointment,
  };
}
export function useCities(countryId?: string) {
  const {data, isLoading, isError} = useQuery({
    queryKey: ['cities', countryId],
    queryFn: () => getCities({countryId}),
  });

  return {
    cities: data?.data || [],
    isLoading,
    isError,
  };
}

export function useSendOtp() {
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {isPending, mutate, isSuccess, reset} = useMutation({
    mutationKey: ['send-otp'],
    mutationFn: sendOtp,
    onSuccess: data => {
      toast(ToastType.Success, 'OTP Sent', data.message);
    },
    onError: error =>
      handleErrorResponse<PostLoginErrorResponseData>(error, 'Send OTP'),
    onSettled: () => setDisableSubmit(false),
  });

  const requestSendOtp = (email: string) => {
    setDisableSubmit(true);
    mutate(email);
  };

  return {
    disableSubmit,
    isPending,
    requestSendOtp,
    isSuccess,
    reset,
  };
}
export function usePatientResetPassword() {
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {mutate, isPending} = useMutation({
    mutationKey: ['reset-password'],
    mutationFn: putResetPassword,
    onSuccess: data => {
      navigate(RoutePath.loginRoute);
      toast(ToastType.Success, 'Reset password', data.message);
    },
    onError: error => handleErrorResponse(error, 'Patient Reset Password'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendPatientResetPasswordRequest = (
    variables: PutResetPasswordVariables
  ) => {
    setDisableSubmit(true);
    mutate(variables);
  };

  return {
    disableSubmit,
    isPending,
    sendPatientResetPasswordRequest,
  };
}

export function useResendOtp() {
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {isPending, mutate} = useMutation({
    mutationKey: ['resend-otp'],
    mutationFn: ResendOtp,
    onSuccess: data => {
      toast(ToastType.Success, 'OTP resent', data.message);
    },
    onError: error =>
      handleErrorResponse<PostLoginErrorResponseData>(error, 'Resend OTP'),
    onSettled: () => setDisableSubmit(false),
  });

  const requestResendOtp = (email: string) => {
    setDisableSubmit(true);
    mutate(email);
  };

  return {
    disableSubmit,
    isPending,
    requestResendOtp,
  };
}

export function useValidateOtp(
  setActiveView: (view: BookingView) => void,
  setBookingChoices: BookingDataUpdater
) {
  const dispatch = useAppDispatch();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {isPending, mutate, isSuccess} = useMutation({
    mutationKey: ['validate-otp'],
    mutationFn: ({email, otp}: {email: string; otp: number}) =>
      validateOtp(email, otp),
    onSuccess: responseData => {
      const data = responseData.data;

      const token = data.token;
      const refreshToken = data.refresh_token;
      const patientData = data.patient;

      const authData = {
        refreshToken: refreshToken,
        token: token,
        user: {
          id: patientData.id,
          fullName: patientData.full_name,
          hospitalId: null,
          email: patientData.email,
          roleName: UserRoleName.Patient,
          mobileNumber: patientData.mobile_number,
          profileUrl: null,
          timezone: null,
        },
      };

      dispatch(loadAuth(authData));

      const isPatientHasInfo = responseData.data.is_patient_with_full_details;

      const bookingData = ValidateOtpResponseToBookingData(responseData);

      setBookingChoices(draft => {
        // Auto fill patient details
        // if patient has details
        if (isPatientHasInfo) {
          draft.patientCarerDetails = bookingData.patientCarerDetails;
          draft.patientContactDetail = bookingData.patientContactDetail;
        } else {
          draft.patientCarerDetails = undefined;
          draft.patientContactDetail = undefined;
        }

        draft.registered = {
          isRegistered: false,
          email: data.patient.email,
          patientId: data.patient.id,
          isEmailvalidated: true,
        };
      });
      setActiveView(BookingView.PatientCarerDetailsView);

      toast(ToastType.Success, 'OTP Validated', responseData.message);
    },
    onError: error =>
      handleErrorResponse<PostLoginErrorResponseData>(error, 'Validate OTP'),
    onSettled: () => setDisableSubmit(false),
  });

  const requestValidateOtp = (email: string, otp: number) => {
    setDisableSubmit(true);
    mutate({email, otp});
  };

  return {
    disableSubmit,
    isPending,
    requestValidateOtp,
    isSuccess,
  };
}

export function usePaymentCards() {
  const {data, isLoading, isError} = useQuery({
    queryKey: ['payment-cards'],
    queryFn: getPaymentCard,
  });

  const cards = data?.data || [];

  return {cards, isLoading, isError};
}

export function usePreferredPointOfContact() {
  const {data, isLoading, isError} = useQuery({
    queryKey: ['preferred-point-contact'],
    queryFn: getPreferredPointOfContact,
  });

  return {
    preferredPointOfContact: data?.data || [],
    isLoading: isLoading,
    isError,
  };
}

export function useCountries() {
  const {data, isLoading, isError} = useQuery({
    queryKey: ['country-list'],
    queryFn: getCountries,
  });

  const countries: Country[] = useMemo(() => {
    const countryList = data?.data || [];

    return countryList.map(item => ({
      code: item.code,
      name: item.name,
      countryCode: item.country_code,
    }));
  }, [data]);

  return {countries: countries, isLoading, isError};
}

export function useBlockSlots({
  setActiveStep,
  setActiveView,
  setBookingChoices,
  setOfflineBookingFormData,
  createAppointment,
  offlineBookingFormData,
  onSuccessCallback,
}: {
  setActiveView?: (view: BookingView) => void;
  setActiveStep?: React.Dispatch<SetStateAction<OfflineBookingStep>>;
  setBookingChoices?: BookingDataUpdater;
  setOfflineBookingFormData?: React.Dispatch<SetStateAction<FormState>>;
  createAppointment?: (payload: FormState) => void;
  offlineBookingFormData?: FormState;
  onSuccessCallback?: (data: PostBlockSlotsResponseData) => void;
}) {
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {mutate} = useMutation({
    mutationKey: ['block-slots'],
    mutationFn: postBlockSlots,
    onSuccess: data => {
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
      if (setBookingChoices && setActiveView) {
        setBookingChoices(draft => {
          draft.appointmentId = data.data.appointment.id;
        });
        setActiveView(BookingView.ChoicesConfirmationView);
      } else if (setOfflineBookingFormData && setActiveStep) {
        const newAppointmentId = data.data.appointment.id;
        setOfflineBookingFormData((prevState: FormState) => ({
          appointment: {
            ...prevState.appointment,
            id: newAppointmentId,
          },
        }));
        if (createAppointment && offlineBookingFormData) {
          const updatedFormData = {
            ...offlineBookingFormData,
            appointment: {
              ...offlineBookingFormData.appointment,
              id: newAppointmentId,
            },
          };
          createAppointment(updatedFormData);
        }
        setActiveStep(OfflineBookingStep.PaymentDetails);
      }
    },
    onSettled: () => {
      setDisableSubmit(false);
    },
    onError: error => handleErrorResponse(error, 'Block Slots'),
  });

  const sendBlockSlotsRequest = (variables: PostBlockSlotsVariables) => {
    setDisableSubmit(true);
    mutate(variables);
  };

  return {
    disableSubmit,
    sendBlockSlotsRequest,
  };
}
