import {IconButton} from '@mui/material';
import {ALLERGY_SEVERITY_SEQUENCE, AllergySeverity} from './constant';
import {PencilIcon, TrashIcon} from '$assets/svgs';

interface AllergyItemProps {
  title: string;
  severity: AllergySeverity;
  onModify?: () => void;
  onDelete?: () => void;
  hideActions?: boolean;
}

function AllergyItem(props: AllergyItemProps) {
  const {title, severity, hideActions = false, onModify, onDelete} = props;

  const severityIndex = ALLERGY_SEVERITY_SEQUENCE.findIndex(
    item => item === severity
  );
  const items = Object.values(AllergySeverity).map((sev, index) => (
    <div
      key={sev}
      className={`severity-dot h-3 w-3 rounded-full ${
        index > severityIndex ? 'bg-white' : ''
      }`}
    />
  ));

  return (
    <div className="flex items-center">
      <div className="allergy-item py-1.5 flex-1">
        <p className="title mb-1.5 font-bold">{title}</p>
        <div className="severity-container flex items-center gap-1.5">
          {items}
          <span className="severity-title text-xs">{severity}</span>
        </div>
      </div>
      {!hideActions && (
        <div>
          <IconButton size="small" onClick={onModify}>
            <PencilIcon className="primary" />
          </IconButton>
          <IconButton size="small" onClick={onDelete}>
            <TrashIcon className="primary" />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default AllergyItem;
