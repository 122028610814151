import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useState} from 'react';
import toast from '$utils/toast';
import {ToastType} from '$components/Toast/constant';
import {handleErrorResponse} from '$utils/axios';
import {
  putPatientCare,
  PutPatientCarePayload,
} from '$api/patientList/patientDetails';

export function useCreatePatientCare(onSuccess: () => void) {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const queryClient = useQueryClient();

  const {mutate, isPending} = useMutation({
    mutationKey: ['patient-details-update-patient-care'],
    mutationFn: putPatientCare,
    onSuccess: data => {
      toast(ToastType.Success, 'Update Patient Care', data.message);
      queryClient.invalidateQueries({
        queryKey: ['patient-details'],
      });
      onSuccess();
    },
    onError: error => handleErrorResponse(error, 'Update Patient Care'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendCreatePatientCareRequest = (payload: PutPatientCarePayload) => {
    setDisableSubmit(true);
    mutate(payload);
  };

  return {sendCreatePatientCareRequest, isPending, disableSubmit};
}
