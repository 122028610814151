import {
  generateInitialValues,
  generateQuestionnairePayload,
  generateValidationSchema,
  paginateQuestionnaires,
} from '../utils';
import {FormikValues, useFormik} from 'formik';
import {
  Question,
  Questionnaires,
  QuestionnaireType,
} from '~/services/api/MedicalHistory/Questionnaire/types';
import MedicalHistoryQuestionnaireComponent from './component';
import {useEffect} from 'react';
import {MenuItem, Typography} from '@mui/material';
import {useState} from 'react';
import {momentDate} from '$utils/date';
import {
  BMI_DECIMAL_PRECISION,
  FEET_TO_METERS_CONVERSION_FACTOR,
  INCHES_IN_FOOT,
} from './constant';
import {calculateBMI, normalizeHeight, splitHeight} from './utils';

interface MedicalHistoryQuestionnaireFormProps {
  itemsPerPage: number;
  currentPage: number;
  questionnaire: Question[];
  handlePageChange: (page: number) => void;
  submitPatientQuestionnaire: (payload: Questionnaires[]) => void;
  handleBack: () => void;
  disableSubmit: boolean;
  isCreatingPatientQuestionnaire: boolean;
  questionnaireType: QuestionnaireType;
}

const MedicalHistoryQuestionnaireForm = (
  props: MedicalHistoryQuestionnaireFormProps
) => {
  const {
    currentPage,
    itemsPerPage,
    questionnaire,
    handlePageChange,
    submitPatientQuestionnaire,
    disableSubmit,
    handleBack,
    isCreatingPatientQuestionnaire,
  } = props;

  const {questionsToDisplay, startIndex, totalPages} = paginateQuestionnaires(
    questionnaire,
    currentPage,
    itemsPerPage
  );

  const initialValues = generateInitialValues(questionnaire);
  const validationSchema = generateValidationSchema(questionsToDisplay);

  const handleSubmitQuestionnaire = (values: FormikValues) => {
    const payload = generateQuestionnairePayload(values);
    submitPatientQuestionnaire(payload);
  };

  const handleFormSubmit = async () => {
    const errors = await formik.validateForm();
    const isFormValid = Object.keys(errors).length === 0;
    const isLastPage = currentPage === totalPages;

    if (isFormValid) {
      if (isLastPage) {
        formik.handleSubmit();
      } else {
        handlePageChange(currentPage + 1);
      }
    }
  };

  const formik = useFormik<FormikValues>({
    initialValues,
    validationSchema,
    onSubmit: values => {
      handleSubmitQuestionnaire(values);
    },
  });

  const [heightFieldId, setHeightFieldId] = useState<number | null>(null);
  const [weightFieldId, setWeightFieldId] = useState<number | null>(null);
  const [bmiFieldId, setBMIFieldId] = useState<number | null>(null);

  const renderYearItem = (item: string) => (
    <MenuItem key={item} value={item}>
      {item}
    </MenuItem>
  );

  const renderYearValue = (value: string, placeholder: string) => (
    <Typography>{value || placeholder}</Typography>
  );

  const bmiSync = (args: {heightValue?: string; weightValue?: string}) => {
    const {heightValue, weightValue} = args;
    if (!heightFieldId || !weightFieldId || !bmiFieldId) return;

    const heightInFeet = Number(
      heightValue || formik.values[heightFieldId.toString()]
    );
    const weight = Number(
      weightValue || formik.values[weightFieldId.toString()]
    );

    if (Number.isNaN(heightInFeet) || Number.isNaN(weight)) return;

    // Split the height into feet and inches
    const {feet, inches} = splitHeight(heightInFeet);

    // Normalize the height if inches are beyond 12
    if (inches >= INCHES_IN_FOOT) {
      const normalizedHeight = normalizeHeight(feet, inches);
      handleFieldChange(heightFieldId.toString(), normalizedHeight);
    }

    const totalHeightInFeet = feet + inches / INCHES_IN_FOOT;

    // Convert height from feet to meters
    const heightInMeters = totalHeightInFeet * FEET_TO_METERS_CONVERSION_FACTOR;

    const bmiValue = calculateBMI(
      weight,
      heightInMeters,
      BMI_DECIMAL_PRECISION
    );

    handleFieldChange(bmiFieldId.toString(), bmiValue.toString());
  };

  const handleFieldChange = (fieldId: string, value: string) => {
    formik.setFieldValue(fieldId, value);

    if (fieldId === heightFieldId?.toString()) {
      bmiSync({heightValue: value});
    }

    if (fieldId === weightFieldId?.toString()) {
      bmiSync({weightValue: value});
    }
  };

  const handleDateChange = (fieldId: string, date: Date | null) => {
    const dateString = date ? momentDate(date).toDate().toISOString() : '';
    formik.setFieldValue(fieldId, dateString);
  };

  useEffect(() => {
    const heightQuestion = questionnaire.find(
      ques => ques.question === 'Height'
    );
    if (heightQuestion) setHeightFieldId(heightQuestion.id);

    const weightQuestion = questionnaire.find(
      ques => ques.question === 'Weight'
    );
    if (weightQuestion) setWeightFieldId(weightQuestion.id);

    const bmiQuestion = questionnaire.find(
      ques => ques.question === 'BMI (if known)'
    );
    if (bmiQuestion) setBMIFieldId(bmiQuestion.id);
  }, [questionnaire]);

  return (
    <MedicalHistoryQuestionnaireComponent
      formik={formik}
      currentPage={currentPage}
      startIndex={startIndex}
      handleFieldChange={handleFieldChange}
      handleDateChange={handleDateChange}
      questionsToDisplay={questionsToDisplay}
      totalPages={totalPages}
      handlePageChange={handlePageChange}
      renderYearItem={renderYearItem}
      renderYearValue={renderYearValue}
      handleFooterSubmit={handleFormSubmit}
      handleBack={handleBack}
      disableSubmit={disableSubmit || isCreatingPatientQuestionnaire}
    />
  );
};

export default MedicalHistoryQuestionnaireForm;
