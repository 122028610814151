import React, {useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {getRoute, RouteSection} from '$utils/route';
import {PatientDetailTab} from '../../../constant';
import {QuestionnaireType} from '~/services/api/MedicalHistory/Questionnaire/types';
import MedicalHistoryQuestionnaire from './MedicalHistoryQuestionnaire';
import LifeStyleQuestionnaire from './LifeStyleQuestionnaire';
import DailyLifeAndHospitalExperience from './DailyLivingAndHospitalExperienceQuestionnaire';
import FamilyQuestionnaire from './FamilyQuestionnaire';
import {Box} from '@mui/material';

const Questionnaire: React.FC = () => {
  const location = useLocation();
  const {patientId} = useParams();
  const navigate = useNavigate();

  const currentQuestionnaireType: QuestionnaireType =
    location.state?.type || QuestionnaireType.MedicalHistory;

  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      navigate(getRoute(RouteSection.PatientList, patientId), {
        state: {
          activeSubTab: PatientDetailTab.ClinicalData,
        },
      });
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderQuestionnaireComponent = (questionnaire: QuestionnaireType) => {
    switch (questionnaire) {
      case QuestionnaireType.MedicalHistory:
        return (
          <MedicalHistoryQuestionnaire
            handleBack={handleBack}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={12}
            patientId={Number(patientId)}
          />
        );
      case QuestionnaireType.Lifestyle:
        return (
          <LifeStyleQuestionnaire
            handleBack={handleBack}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={5}
            patientId={Number(patientId)}
          />
        );
      case QuestionnaireType.DailyLiving:
        return (
          <DailyLifeAndHospitalExperience
            handleBack={handleBack}
            questionnaireType={QuestionnaireType.DailyLiving}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={5}
            patientId={Number(patientId)}
          />
        );
      case QuestionnaireType.HospitalExperience:
        return (
          <DailyLifeAndHospitalExperience
            handleBack={handleBack}
            questionnaireType={QuestionnaireType.HospitalExperience}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={4}
            patientId={Number(patientId)}
          />
        );
      case QuestionnaireType.Family:
        return (
          <FamilyQuestionnaire
            handleBack={handleBack}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={5}
            patientId={Number(patientId)}
          />
        );
      default:
        return <Box>No Questionnaires Found</Box>;
    }
  };

  return renderQuestionnaireComponent(currentQuestionnaireType);
};

export default Questionnaire;
