import {SetStateAction} from 'react';
import {FormikErrors, FormikTouched} from 'formik';
import EyeOPLogo from '$assets/svgs/eye-op.svg?react';
import EyeIcon from '$assets/svgs/eye.svg?react';
import EyeOffIcon from '$assets/svgs/eye-off.svg?react';
import FrontdeskButton from '$components/FrontdeskButton';
import Input from '$components/Input';
import LoginLayout from './layout';
import {LoginFormData} from './schema';
import './styles.scss';

interface LoginComponentProps {
  isLoginPending: boolean;
  disableSubmit: boolean;
  formValues: LoginFormData;
  formErrors: FormikErrors<LoginFormData>;
  formTouched: FormikTouched<LoginFormData>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleSubmit: () => void;
  handleForgetPassword: () => void;
  showPassword: boolean;
  setShowPassword: React.Dispatch<SetStateAction<boolean>>;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

function LoginComponent(props: LoginComponentProps) {
  const {
    disableSubmit,
    isLoginPending,
    formValues,
    formErrors,
    formTouched,
    showPassword,
    handleChange,
    handleBlur,
    handleSubmit,
    handleForgetPassword,
    setShowPassword,
    handleKeyDown,
  } = props;

  return (
    <div onKeyDown={handleKeyDown}>
      <LoginLayout>
        <div className="mb-8">
          <EyeOPLogo className="h-8 w-8" />
        </div>
        <h1 className="text-primary-color font-semibold text-3xl mb-6">
          Welcome back!
        </h1>
        <p className="text-base font-normal mb-6">
          Eye Op is your dedicated platform for effortless patient care, putting
          people first.
        </p>
        <div className="mb-4">
          <p className="mb-3 text-sm">Email address</p>
          <Input
            className="text-sm"
            type="email"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            value={formValues.email}
            placeholder="Enter your email address"
            touched={formTouched.email}
            error={Boolean(formErrors.email)}
            helperText={
              formErrors.email && formTouched.email ? formErrors.email : ''
            }
          />
        </div>
        <div className="mb-3">
          <p className="mb-3 text-sm">Password</p>
          <Input
            type={showPassword ? 'text' : 'password'}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={formValues.password}
            placeholder="Enter password"
            endIcon={showPassword ? <EyeIcon /> : <EyeOffIcon />}
            endIconClick={() => setShowPassword(v => !v)}
            touched={formTouched.password}
            error={Boolean(formErrors.password)}
            helperText={
              formErrors.password && formTouched.password
                ? formErrors.password
                : ''
            }
          />
        </div>
        <p
          className="text-primary-color text-xs font-normal select-none mb-32"
          onClick={disableSubmit ? () => {} : handleForgetPassword}
        >
          <span
            className={
              disableSubmit ? 'opacity-50' : 'hover:underline cursor-pointer'
            }
          >
            Forgot password?
          </span>
        </p>
        <div className="flex flex-col">
          <FrontdeskButton
            title="Enter"
            onClick={handleSubmit}
            disabled={disableSubmit}
            loading={isLoginPending}
          />
        </div>
      </LoginLayout>
    </div>
  );
}

export default LoginComponent;
