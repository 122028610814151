import React from 'react';
import {FormikErrors, FormikTouched} from 'formik';
import {Insurance, Payment} from '~/modules/booking/types';
import {BankIcon} from '~/assets/svgs';
import SelectInput from '~/components/SelectInput';
import Input from '~/components/Input';
import {EditBookingFormData} from '../schema';
import {EDIT_BOOKING_INSURANCE_OPTIONS} from '~/modules/frontdesk/constant';

type FinancialDetailsComponentProps = {
  values: EditBookingFormData;
  errors: FormikErrors<EditBookingFormData>;
  touched: FormikTouched<EditBookingFormData>;
  insuranceTypes: Insurance[];
  paymentMethods: Payment[];
  renderSelfPayingValue: (value: string) => React.ReactNode;
  renderSelfPayingItem: (item: string) => React.ReactNode;
  renderInsuranceMenuItem: (item: Insurance) => React.ReactNode;
  renderInsuranceValue: (value: string) => React.ReactNode;
  renderPaymentMethodMenuItem: (item: Payment) => React.ReactNode;
  renderPaymentMethodValue: (value: string) => React.ReactNode;
  handleSelfPayingChange: (value: string) => void;
  handlePaymentMethodChange: (value: string) => void;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
};

const FinancialDetailsComponent = (props: FinancialDetailsComponentProps) => {
  const {
    values,
    errors,
    touched,
    insuranceTypes,
    paymentMethods,
    renderSelfPayingValue,
    renderSelfPayingItem,
    renderInsuranceMenuItem,
    renderInsuranceValue,
    renderPaymentMethodMenuItem,
    renderPaymentMethodValue,
    handleSelfPayingChange,
    handlePaymentMethodChange,
    handleBlur,
    handleChange,
  } = props;

  return (
    <div className="flex flex-col p-6 bg-tertiary rounded-lg border-container flex-[5]">
      <div className="flex gap-2 items-center">
        <BankIcon />
        <h2 className="font-semibold text-lg leading-6 tracking-tight bg-gradient-clip">
          Financial details
        </h2>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <div className="font-normal text-sm leading-4 tracking-tight text-primary">
          Self paying
        </div>
        <SelectInput
          name="appointment.self_paying"
          value={values.appointment.self_paying ? 'Yes' : 'No'}
          items={EDIT_BOOKING_INSURANCE_OPTIONS}
          renderValue={renderSelfPayingValue}
          renderItem={renderSelfPayingItem}
          onBlur={handleBlur}
          onChange={handleSelfPayingChange}
          error={
            touched.appointment?.self_paying &&
            Boolean(errors.appointment?.self_paying)
          }
        />
        {touched.appointment?.self_paying &&
          errors.appointment?.self_paying && (
            <p className="m-0 font-sans font-normal text-xs leading-relaxed tracking-wide text-danger ">
              {errors.appointment?.self_paying}
            </p>
          )}
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <div className="font-normal text-sm leading-4 tracking-tight text-primary">
          {values.appointment.self_paying
            ? 'Payment Method'
            : 'Insurance provider'}
        </div>
        {values.appointment.self_paying ? (
          <>
            <SelectInput
              name="appointment.payment_mode"
              items={paymentMethods}
              placeholder="Select payment method"
              renderValue={renderPaymentMethodValue}
              renderItem={renderPaymentMethodMenuItem}
              value={'0'}
              onBlur={handleBlur}
              onChange={handlePaymentMethodChange}
              error={
                touched.appointment?.payment_mode &&
                Boolean(errors.appointment?.payment_mode)
              }
            />
            {touched.appointment?.payment_mode &&
              errors.appointment?.payment_mode && (
                <p className="m-0 font-sans font-normal text-xs leading-relaxed tracking-wide text-danger ">
                  {errors.appointment?.payment_mode}
                </p>
              )}
          </>
        ) : (
          <>
            <SelectInput
              name="appointment.insurance_id"
              items={insuranceTypes}
              placeholder="Select insurer"
              renderValue={renderInsuranceValue}
              renderItem={renderInsuranceMenuItem}
              value={String(values.appointment.insurance_id)}
              onBlur={handleBlur}
              onChange={handlePaymentMethodChange}
              error={
                touched.appointment?.insurance_id &&
                Boolean(errors.appointment?.insurance_id)
              }
            />
            {touched.appointment?.insurance_id &&
              errors.appointment?.insurance_id && (
                <p className="m-0 font-sans font-normal text-xs leading-relaxed tracking-wide text-danger ">
                  {errors.appointment?.insurance_id}
                </p>
              )}
          </>
        )}
      </div>
      {!values.appointment.self_paying && (
        <div className="flex flex-col gap-3 mt-3">
          <div className="font-normal text-sm leading-4 tracking-tight text-primary">
            Membership number
          </div>
          <Input
            name="appointment.membership_no"
            placeholder="Membership number"
            value={String(values.appointment.membership_no)}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.appointment?.membership_no}
            error={
              touched.appointment?.membership_no &&
              Boolean(errors.appointment?.membership_no)
            }
            helperText={
              touched.appointment?.membership_no &&
              errors.appointment?.membership_no
                ? errors.appointment?.membership_no
                : ''
            }
          />
        </div>
      )}
    </div>
  );
};

export default FinancialDetailsComponent;
