import {ApiRoute} from '$constants/api';
import axiosInstance from '..';
import {appendObjectToFormData} from './notes';

export interface GetDocumentsListParams {
  patientId: string;
}

export interface GetDocumentsListResponseData {
  success: boolean;
  data: [
    {
      id: number;
      name: string;
      performed_by: string;
      patient_id: number;
      document_url: string;
      created_at: string;
    },
  ];
  message: string;
}

export async function getDocumentsList(params: GetDocumentsListParams) {
  const {patientId} = params;
  const response = await axiosInstance.get<GetDocumentsListResponseData>(
    ApiRoute.Documents,
    {
      params: {patient_id: patientId},
    }
  );
  return response.data;
}

export interface PostDocumentParams {
  patientId: string;
  document: File;
}

export interface PostDocumentVariables {
  patientId: string;
  document: File;
  name: string;
  performedBy: string;
}

export interface PostDocumentResponseData {
  success: boolean;
  data: [];
  message: string;
}

export const postDocumentVariablesToRequestBody = (
  variables: PostDocumentVariables
) => {
  return {
    document: {
      patient_id: variables.patientId,
      document: variables.document,
      name: variables.name,
      performed_by: variables.performedBy,
    },
  };
};

export async function postDocument(variables: PostDocumentVariables) {
  const body = postDocumentVariablesToRequestBody(variables);
  const formData = new FormData();
  appendObjectToFormData(formData, body);

  const response = await axiosInstance.post<PostDocumentResponseData>(
    ApiRoute.Documents,
    formData
  );
  return response.data;
}

export interface DeleteDocumentParams {
  documentId: number;
}

export interface DeleteDocumentResponseData {
  success: boolean;
  data: [];
  message: string;
}

export async function deleteDocument(params: DeleteDocumentParams) {
  const {documentId} = params;
  const response = await axiosInstance.delete<DeleteDocumentResponseData>(
    ApiRoute.Documents + `/${documentId}`
  );
  return response.data;
}
