import {useEffect, useState} from 'react';
import AppointmentsComponent from './component';
import {useAppointmentList, useDebounce} from '$modules/frontdesk/hooks';
import {ThemeProvider} from '@emotion/react';
import theme from '$modules/frontdesk/theme';
import LocalStorage from '$utils/localStore';
import useTimezone from '../../../../hooks/useTimezone';
import moment from 'moment';

const AppointmentsContainer = () => {
  const timezone = useTimezone();
  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchTerm = useDebounce<string>(searchText, 500);
  const [dateState, setDateState] = useState<Date | null>(() => {
    const savedDate = LocalStorage.getItem(
      LocalStorage.APPOINTMENT_LIST_SELECTED_DATE
    );
    return savedDate
      ? new Date(savedDate)
      : moment().tz(timezone).startOf('day').toDate();
  });

  const [filterInputs, setFilterInputs] = useState({
    status: [] as string[],
    appointmentTypes: [] as number[],
    consultants: [] as number[],
  });

  const [apiFilters, setApiFilters] = useState({
    status: [] as string[],
    appointmentTypes: [] as number[],
    consultants: [] as number[],
  });

  const {appointments, isPending} = useAppointmentList({
    search: debouncedSearchTerm,
    fromDate: dateState || undefined,
    toDate: dateState || undefined,
    status: apiFilters.status,
    appointmentType: apiFilters.appointmentTypes,
    doctorId: apiFilters.consultants,
  });

  const handleDateChange = (date: Date | null) => {
    setDateState(date);
  };

  const handleFilterChange = (
    type: 'status' | 'appointmentTypes' | 'consultants',
    id: number | string
  ) => {
    setFilterInputs(prev => {
      const newFilter = [...prev[type]];
      const index = newFilter.indexOf(id);

      if (index === -1) {
        newFilter.push(id);
      } else {
        newFilter.splice(index, 1);
      }

      return {
        ...prev,
        [type]: newFilter,
      };
    });
  };

  const handleApplyFilters = () => {
    setApiFilters({...filterInputs});
  };

  const handleResetFilters = () => {
    setFilterInputs({
      status: [],
      appointmentTypes: [],
      consultants: [],
    });
    setApiFilters({status: [], appointmentTypes: [], consultants: []});
  };

  useEffect(() => {
    if (dateState) {
      LocalStorage.setItem(
        LocalStorage.APPOINTMENT_LIST_SELECTED_DATE,
        dateState.toISOString()
      );
    }
  }, [dateState]);

  return (
    <ThemeProvider theme={theme}>
      <AppointmentsComponent
        searchText={searchText}
        dateState={dateState}
        setSearchText={setSearchText}
        handleDateChange={handleDateChange}
        filters={filterInputs}
        onFilterChange={handleFilterChange}
        onApply={handleApplyFilters}
        onReset={handleResetFilters}
        appointments={appointments}
        isLoading={isPending}
      />
    </ThemeProvider>
  );
};

export default AppointmentsContainer;
