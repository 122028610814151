import {Button} from '@mui/material';
import DownloadIcon from '$assets/svgs/download.svg?react';
import SyringIcon from '$assets/svgs/syringe.svg?react';
import FrontdeskButton from '$components/FrontdeskButton';
import AllergyItem from './AllergyItem';
import AllergyDialog from './AllergyDialog';
import {SetStateAction} from 'react';
import {useAllergyList} from '../hooks';
import {useParams} from 'react-router-dom';
import AllergyModifyDialog from './AllergyModify';
import AllergyDeleteDialog from './AllergyDelete';

interface AllergiesComponentProps {
  modalOpen: boolean;
  modifyAllergyId: number | null;
  deleteAllergyId: number | null;
  setModalOpen: React.Dispatch<SetStateAction<boolean>>;
  setModifyAllergyId: React.Dispatch<React.SetStateAction<number | null>>;
  setDeleteAllergyId: React.Dispatch<React.SetStateAction<number | null>>;
}

function AllergiesComponent(props: AllergiesComponentProps) {
  const {
    modalOpen,
    setModalOpen,
    modifyAllergyId,
    setModifyAllergyId,
    deleteAllergyId,
    setDeleteAllergyId,
  } = props;

  const params = useParams();
  const patientId = Number(params.patientId);

  const {allergyList, downloadAllergyListPDF} = useAllergyList(patientId);
  const items = allergyList.map(all => (
    <AllergyItem
      key={all.id}
      title={all.name}
      severity={all.level}
      onModify={() => setModifyAllergyId(all.id)}
      onDelete={() => setDeleteAllergyId(all.id)}
    />
  ));

  const handleAddAllergyClick = () => {
    setModalOpen(true);
  };

  const handleDownloadClick = () => {
    if (!allergyList.length) return;
    downloadAllergyListPDF();
  };

  return (
    <div className="clinical-data__allergies overflow-auto col-span-2 row-span-2">
      <div className="header pb-2">
        <div className="card-title-container">
          <SyringIcon />
          <span className="card-title">Allergies</span>
        </div>
        <Button
          startIcon={<DownloadIcon />}
          className="download-full-list-button"
          onClick={handleDownloadClick}
          disabled={!allergyList.length}
        >
          Download full list
        </Button>
      </div>

      <div className="flex-1 overflow-auto">{items}</div>

      <AllergyDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        allergies={allergyList}
      />

      <AllergyModifyDialog
        open={modifyAllergyId !== null}
        onClose={() => setModifyAllergyId(null)}
        allergyId={modifyAllergyId}
        allergies={allergyList}
      />

      <AllergyDeleteDialog
        open={deleteAllergyId !== null}
        onClose={() => setDeleteAllergyId(null)}
        allergyId={deleteAllergyId}
        allergies={allergyList}
      />
      <div>
        <FrontdeskButton
          title="Add allergy"
          onClick={handleAddAllergyClick}
          className="mt-4"
        />
      </div>
    </div>
  );
}

export default AllergiesComponent;
