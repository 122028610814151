import {InferType, object, string} from 'yup';
import {ValidationMessage} from '$constants/messages';
import {POSTCODE_VALIDATION_REGEX} from '$constants/regex';

export const patientDetailTwoFormSchema = object({
  patient: object({
    address: string()
      .trim()
      .max(150, ValidationMessage.AddressLine1ExceedCharacterLimit)
      .required(ValidationMessage.AddressLine1Required),
    address2: string()
      .trim()
      .nullable()
      .max(150, ValidationMessage.AddressLine2ExceedCharacterLimit),
    postcode: string()
      .trim()
      .max(7, ValidationMessage.PostcodeExceedCharacterLimit)
      .matches(POSTCODE_VALIDATION_REGEX, ValidationMessage.PostcodeInvalid)
      .required(ValidationMessage.PostcodeRequired),
    city: string()
      .trim()
      .max(50, ValidationMessage.CityExceedCharacterLimit)
      .required(ValidationMessage.CityRequired),
    country: string()
      .trim()
      .max(60, ValidationMessage.CountryExceedCharacterLimit)
      .required(ValidationMessage.CountryRequired),
  }).required(),
});

export type PatientDetailTwoValidatedFormData = InferType<
  typeof patientDetailTwoFormSchema
>;

export type PatientDetailTwoFormData = {
  patient: {
    address: string;
    address2?: string | null;
    postcode: string;
    city: string;
    country: string;
  };
};
