import {IconButton} from '@mui/material';
import PencilIcon from '$assets/svgs/pencil.svg?react';
import {Medication} from '../types';
import {TrashIcon} from '$assets/svgs';

interface MedicationItemProps {
  item: Medication;
  onEditClick: () => void;
  onDeleteClick: () => void;
  disableClick: boolean;
}

function MedicationItem(props: MedicationItemProps) {
  const {item, onEditClick, onDeleteClick, disableClick} = props;

  return (
    <div className="medication-list-item flex items-center py-2">
      <div className="flex-1 overflow-auto">
        <p className="font-semibold">
          {item.name} {item.dosage}
        </p>
        <p className="font-normal">
          {item.frequency}, {item.instruction}
        </p>
      </div>
      <IconButton size="small" disabled={disableClick} onClick={onDeleteClick}>
        <TrashIcon className="primary" />
      </IconButton>
      <IconButton
        className="edit-pencil-button"
        disabled={disableClick}
        onClick={onEditClick}
      >
        <PencilIcon />
      </IconButton>
    </div>
  );
}

export default MedicationItem;
