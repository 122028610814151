import React from 'react';
import {Button} from '@mui/material';
import FrontdeskButton from '$components/FrontdeskButton';
import {BUTTON_TEXTS} from '$modules/frontdesk/PatientList/constant';

interface QuestionnaireFooterProps {
  currentPage: number;
  totalPages: number;
  handlePageChange: (page: number) => void;
  handleSubmit: () => void;
  isFormValid: boolean;
  disableSubmit: boolean;
  'data-testid'?: string;
}

const QuestionnaireFooter: React.FC<QuestionnaireFooterProps> = ({
  currentPage,
  totalPages,
  handlePageChange,
  handleSubmit,
  isFormValid,
  disableSubmit,
  'data-testid': testId,
}) => {
  return (
    <footer
      className="flex justify-between items-center px-10 py-4 !border-t-0 z-10 sticky bottom-10 left-0 right-0 bg-primary"
      data-testid={testId}
    >
      <span>{`Page ${currentPage} of ${totalPages}`}</span>
      <div className="flex gap-4">
        {currentPage > 1 && (
          <Button
            variant="outlined"
            disabled={disableSubmit}
            onClick={() => handlePageChange(currentPage - 1)}
            className="!bg-white !text-black !normal-case h-10 w-9 !border-none disabled:!cursor-not-allowed disabled:!pointer-events-auto"
          >
            {BUTTON_TEXTS.BACK}
          </Button>
        )}
        <FrontdeskButton
          title={currentPage === totalPages ? 'Submit' : 'Continue'}
          disabled={!isFormValid || disableSubmit}
          onClick={handleSubmit}
        />
      </div>
    </footer>
  );
};

export default QuestionnaireFooter;
