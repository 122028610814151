import React from 'react';
import {EditBookingFormData} from '../schema';
import {FormikErrors, FormikTouched} from 'formik';
import ContactDetailsComponent from './component';
import {usePreferredPointOfContact} from '~/modules/booking/hooks';
import Loading from '~/components/Loading';
import FetchError from '~/components/FetchError';
import {MenuItem, Typography} from '@mui/material';
import {Color} from '~/constants/style';

interface ContactDetailsIndexProps {
  values: EditBookingFormData;
  errors: FormikErrors<EditBookingFormData>;
  touched: FormikTouched<EditBookingFormData>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  setFieldTouched: (field: string, touched?: boolean) => void;
  setFieldValue: (field: string, value: unknown) => void;
}

const ContactDetailsIndex = (props: ContactDetailsIndexProps) => {
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    touched,
    values,
  } = props;

  const {
    preferredPointOfContact,
    isLoading: isPerferredPointOfContactLoading,
    isError: isPerferredPointOfContactError,
  } = usePreferredPointOfContact();

  const preferredContactOptions = preferredPointOfContact.map(
    item => item.name
  );

  if (isPerferredPointOfContactLoading) {
    return <Loading />;
  }

  if (!preferredPointOfContact || isPerferredPointOfContactError) {
    return <FetchError />;
  }

  const renderPreferredPointOfContactItem = (item: string) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  };

  const renderPreferredPointOfContactValue = (value: string) => {
    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value === '' ? 'Select an option' : value}
      </Typography>
    );
  };

  const handleFieldChange = (
    field: string,
    value: number | string | boolean | Date
  ) => {
    field = `appointment.${field}`;
    setFieldValue(field, value);

    if (field === 'appointment.patient.address.country') {
      setFieldValue('appointment.patient.address.city', '');
      setFieldTouched('appointment.patient.address.city', false);
    }
  };

  const country = values?.appointment?.patient?.address?.country;

  return (
    <ContactDetailsComponent
      country={country}
      errors={errors}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleFieldChange={handleFieldChange}
      preferredContactOptions={preferredContactOptions}
      renderPreferredPointOfContactItem={renderPreferredPointOfContactItem}
      renderPreferredPointOfContactValue={renderPreferredPointOfContactValue}
      values={values}
      touched={touched}
    />
  );
};

export default ContactDetailsIndex;
