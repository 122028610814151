import {postSendPushSubscription} from '$api/notification';
import {PUSH_NOTIFICATION_PUBLIC_KEY} from '$constants/env';
import {RoutePath} from '$constants/routes';
import {router} from '$lib/router';
import LocalStorage from '$utils/localStore';
import {getRoute, RouteSection} from '$utils/route';
import {notificationToast} from '$utils/toast';
import {ServiceWorkerMessageType} from './constant';
import {ServiceWorkerData} from './types';
import {urlB64ToUint8Array} from './utils';

let swRegistration: ServiceWorkerRegistration | null = null;

export async function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    const isProduction = import.meta.env.MODE === 'production';
    const fileName = isProduction ? '/service-worker.js' : '/dev-sw.js?dev-sw';
    const type = isProduction ? 'classic' : 'module';

    try {
      swRegistration = await navigator.serviceWorker.register(fileName, {
        type: type,
      });

      // await subscribePushManager()
      await registerEventHandlers();
    } catch (error) {
      console.error('[Service Worker] Registration:', error);
    }
  } else {
    console.warn('[Service Worker] Not supported!');
  }
}

export async function subscribePushManager() {
  if ('PushManager' in window) {
    try {
      if (!swRegistration || !PUSH_NOTIFICATION_PUBLIC_KEY) return;

      let subscription: PushSubscription | null =
        await swRegistration.pushManager.getSubscription();

      if (subscription) {
        const currentKey = LocalStorage.getItem(
          LocalStorage.SUBSCRIPTION_APPLICATION_SERVER_PUBLIC_KEY
        );

        if (currentKey !== PUSH_NOTIFICATION_PUBLIC_KEY) {
          await subscription.unsubscribe();
          subscription = await swRegistration.pushManager.getSubscription();
        }
      }

      if (!subscription) {
        const applicationServerKey = urlB64ToUint8Array(
          PUSH_NOTIFICATION_PUBLIC_KEY
        );

        subscription = await swRegistration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: applicationServerKey,
        });
        LocalStorage.setItem(
          LocalStorage.SUBSCRIPTION_APPLICATION_SERVER_PUBLIC_KEY,
          PUSH_NOTIFICATION_PUBLIC_KEY
        );
      }

      console.log(
        '[Push Manager] Subscribed successfully',
        JSON.stringify(subscription.toJSON())
      );
      await sendPushSubscription(subscription);
    } catch (error) {
      console.error('[Push Manager] Subscription failed:', error);
    }
  } else {
    console.warn('[Push Manager] Not supported!');
  }
}

export async function unsubscribePushManager() {
  if (!swRegistration) return;

  try {
    const subscription: PushSubscription | null =
      await swRegistration.pushManager.getSubscription();

    if (subscription) {
      await subscription.unsubscribe();
    }

    console.log('[Push Manager] Unsubscribed successfully');
  } catch (error) {
    // fail silently
    console.error('[Push Manager] Unsubscribe failed:', error);
  }
}

async function registerEventHandlers() {
  navigator.serviceWorker.addEventListener('message', handleMessage);
}

function handleMessage(event: MessageEvent<ServiceWorkerData>) {
  const eventData = event.data;
  const type = eventData.type;

  if (type === ServiceWorkerMessageType.ShowNotification) {
    const data = eventData.data;
    const createdAt = new Date(data.createdAt);

    const route = getRoute(
      RouteSection.Calendar,
      RoutePath.staff.calendar.bookingDetails,
      data.bookingId
    );

    notificationToast(data.title, data.message, createdAt, route);
  }

  if (type === ServiceWorkerMessageType.Navigate) {
    const route = eventData.data;
    router.navigate(route);
  }
}

export async function sendPushSubscription(subscription: PushSubscription) {
  try {
    await postSendPushSubscription({subscription});
  } catch {
    // fail silently
  }
}

export default swRegistration;
