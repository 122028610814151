import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  useAppointmentDetails,
  useAppointmentList,
  useBookingSlots,
  useConfirmBooking,
} from '../../hooks';
import ConfirmBookingComponent from './component';
import {Slot} from '../../type';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';
import ConfirmBookingDialog from './ConfirmBookingDialog';
import '../../styles.scss';
import {useAppSelector} from '$redux/hooks';
import {momentDate} from '$utils/date';
import useTimezone from '../../../../hooks/useTimezone';
import {isNumber} from '$utils/number';

function ConfirmBooking() {
  const params = useParams();
  const appointmentId = Number(params.id);
  const hospitalId = useAppSelector(state => state.auth.user?.hospitalId)!;
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [preferredTime, setPreferredTime] = useState('');
  const [selectedSlot, setSelectedSlot] = useState<Slot | undefined>();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const timezone = useTimezone();
  const {appointmentDetails, isLoading} = useAppointmentDetails({
    appointmentId,
  });
  const doctorId = appointmentDetails?.doctor.id;
  const {appointments, isLoading: isAppointmentLoading} = useAppointmentList({
    fromDate: selectedDate,
    toDate: selectedDate,
    doctorId: isNumber(doctorId) ? [doctorId] : undefined,
  });
  const {isPending, disableSubmit, sendConfirmBookingRequest} =
    useConfirmBooking(() => setShowConfirmationDialog(true));
  const {
    slots,
    slotsStartTime,
    slotsEndTime,
    isLoading: isSlotsLoading,
  } = useBookingSlots({
    date: selectedDate,
    preferredTime: preferredTime,
    doctorId: appointmentDetails?.doctor.id,
    hospitalId: hospitalId,
    subCareTypeId: appointmentDetails?.subCareType.id,
    appointmentTypeId: appointmentDetails?.appointmentType.id,
  });

  const handleConfirmBookingSubmit = () => {
    if (!selectedSlot) return;
    sendConfirmBookingRequest({
      appointmentId: appointmentId,
      startTime: selectedSlot.startTime,
      endTime: selectedSlot.endTime,
      doctorId: appointmentDetails!.doctor.id,
      preferredTime: preferredTime,
      date: selectedDate,
      confirmAt: new Date(),
    });
  };

  const handleNextClick = () => {
    setSelectedSlot(undefined);

    let momentSelectedDate = momentDate(selectedDate);
    momentSelectedDate = momentSelectedDate.tz(timezone).startOf('day');
    momentSelectedDate.add(1, 'day');

    setSelectedDate(momentSelectedDate.toDate());
  };

  const handlePrevClick = () => {
    setSelectedSlot(undefined);

    let momentSelectedDate = momentDate(selectedDate);
    momentSelectedDate = momentSelectedDate.tz(timezone).startOf('day');
    momentSelectedDate = momentSelectedDate.subtract(1, 'day');

    setSelectedDate(momentSelectedDate.toDate());
  };

  const handleDialogClose = () => {
    setShowConfirmationDialog(false);
  };

  useEffect(() => {
    const appointment = appointmentDetails?.appointment;
    if (appointment) {
      const {date, startTime, endTime} = appointment;
      setSelectedDate(date);
      setPreferredTime(appointment.perferredTime);

      if (startTime && endTime) {
        setSelectedSlot({
          startTime: startTime,
          endTime: endTime,
        });
      }
    }
  }, [appointmentDetails]);

  if (isLoading) return <Loading />;
  if (!appointmentDetails) return <FetchError />;

  return (
    <>
      <ConfirmBookingComponent
        preferredTime={preferredTime}
        setPreferredTime={setPreferredTime}
        slotsStartTime={slotsStartTime}
        slotsEndTime={slotsEndTime}
        appointmentDetails={appointmentDetails}
        appointments={appointments}
        selectedDate={selectedDate}
        isSlotsLoading={isSlotsLoading}
        disableRangeChange={isAppointmentLoading || isPending || isSlotsLoading}
        setSelectedDate={setSelectedDate}
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot}
        slots={slots}
        isPending={isPending}
        disableSubmit={disableSubmit}
        onConfirmBookingClick={handleConfirmBookingSubmit}
        onNextClick={handleNextClick}
        onPrevClick={handlePrevClick}
      />
      {showConfirmationDialog && (
        <ConfirmBookingDialog
          open={showConfirmationDialog}
          onClose={handleDialogClose}
          appointmentDetails={appointmentDetails}
          selectedDate={selectedDate}
        />
      )}
    </>
  );
}

export default ConfirmBooking;
