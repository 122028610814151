import {Button, Tabs, Tab, Divider} from '@mui/material';
import {ArrowLeftIcon} from '$assets/svgs';
import ClinicalDataContainer from './ClinicalData';
import {SyntheticEvent, useState} from 'react';
import {PATIENT_DETAILS_TAB_LIST, PatientDetailTab} from './constant';
import PatientDetailNotes from './Notes';
import './styles.scss';
import OverviewContainer from './Overview';
import Timeline from './Timeline';
import {useLocation} from 'react-router-dom';

interface PatientDetailsComponentProps {
  patientId: number;
  handleBackClick: () => void;
}
const PatientDetailsComponent: React.FC<PatientDetailsComponentProps> = ({
  handleBackClick,
}) => {
  const location = useLocation();
  const activeSubTab = location?.state?.activeSubTab;

  const [activeTab, setActiveTab] = useState<PatientDetailTab>(
    activeSubTab || PatientDetailTab.Overview
  );

  const [overviewSelectedNoteId, setOverviewSelectedNoteId] = useState<
    number | null
  >(null);

  const handleTabChange = (_ev: SyntheticEvent, value: PatientDetailTab) => {
    setActiveTab(value);
  };

  const tabs = PATIENT_DETAILS_TAB_LIST.map(({label, value}) => (
    <Tab key={value} label={label} value={value} />
  ));

  let tabContent: React.ReactNode = null;

  switch (activeTab) {
    case PatientDetailTab.Overview:
      tabContent = (
        <OverviewContainer
          setActiveTab={setActiveTab}
          setOverviewSelectedNoteId={setOverviewSelectedNoteId}
        />
      );
      break;
    case PatientDetailTab.ClinicalData:
      tabContent = <ClinicalDataContainer />;
      break;
    case PatientDetailTab.Timeline:
      tabContent = <Timeline />;
      break;
    case PatientDetailTab.Notes:
      tabContent = (
        <PatientDetailNotes overviewSelectedNoteId={overviewSelectedNoteId} />
      );
      break;
  }

  return (
    <div className="patient-details">
      <header className="patient-details__header">
        <div className="patient-details__header__navigation">
          <Button
            startIcon={<ArrowLeftIcon />}
            className="patient-details__button--back"
            onClick={handleBackClick}
          >
            Back
          </Button>
          <Divider className="mt-3 mx-10" />
        </div>
        <div className="patient-details__header__tab-bar">
          <Tabs value={activeTab} onChange={handleTabChange}>
            {tabs}
          </Tabs>
        </div>
      </header>
      <div className="patient-details__body overflow-auto">{tabContent}</div>
    </div>
  );
};

export default PatientDetailsComponent;
