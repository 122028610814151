import {useMutation} from '@tanstack/react-query';
import {postValidateNHSNumber} from '$api/patients';
import {useState} from 'react';
import {AxiosError} from 'axios';
import toast from '$utils/toast';
import {ToastType} from '$components/Toast/constant';

function useValidateNHSNumber(onSuccess: () => void) {
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {mutate, isPending, isError} = useMutation({
    mutationKey: ['validate-nhs-number'],
    mutationFn: postValidateNHSNumber,
    onSuccess: onSuccess,
    onError: error => {
      if (error instanceof AxiosError) {
        const message = error.response?.data?.message;
        if (message)
          toast(
            ToastType.Error,
            'NHS number validation',
            message,
            'nhs-number-validation'
          );
      }
    },
    onSettled: () => setDisableSubmit(false),
  });

  const validateNHSNumber = (nhsNumber: number) => {
    setDisableSubmit(true);
    mutate({nhsNumber: nhsNumber});
  };

  return {
    isLoading: disableSubmit || isPending,
    validateNHSNumber,
    isError,
  };
}

export default useValidateNHSNumber;
