import {useLocation, useNavigate} from 'react-router-dom';
import PatientDetailsComponent from './component';
import {getRoute, RouteSection} from '$utils/route';

const PatientDetailsContainer = () => {
  const location = useLocation();
  const patientId = location.state;
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(getRoute(RouteSection.PatientList));
  };

  return (
    <PatientDetailsComponent
      handleBackClick={handleBackClick}
      patientId={patientId}
    />
  );
};

export default PatientDetailsContainer;
