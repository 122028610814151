import IconButton from '$components/IconButton';
import CalendarApprovedIcon from '$assets/svgs/calendar-approved.svg?react';
import XCloseIcon from '$assets/svgs/x-close.svg?react';
import {Dialog} from '@mui/material';
import {BookingDetails} from '$modules/frontdesk/type';
import {useNavigate} from 'react-router-dom';
import {getRoute, RouteSection} from '$utils/route';
import {RoutePath} from '$constants/routes';
import FrontdeskButton from '$components/FrontdeskButton';

interface NoConsultantSelectedDialogProps {
  open: boolean;
  onClose: () => void;
  appointmentId: number;
  appointmentDetails: BookingDetails;
}

function NoConsultantSelectedDialog(props: NoConsultantSelectedDialogProps) {
  const {open, onClose, appointmentId, appointmentDetails} = props;
  const navigate = useNavigate();
  const handleEditBooking = () => {
    navigate(
      getRoute(
        RouteSection.Calendar,
        RoutePath.staff.calendar.editBooking,
        appointmentId
      ),
      {
        state: appointmentDetails,
      }
    );
  };
  return (
    <Dialog open={open} className="confirm-booking-dialog">
      <div className="header-container">
        <div className="calender-container">
          <CalendarApprovedIcon />
        </div>
        <IconButton onClick={onClose}>
          <XCloseIcon />
        </IconButton>
      </div>
      <h2 className="text-2xl font-semibold">Please select an Consultant</h2>
      <div className="info-container">
        <div className="info-cell">
          <p className="info-label">
            To book a slot, please first select a consultant through
            <strong> Edit Booking</strong>
          </p>
        </div>
      </div>
      <div className="info-footer">
        <FrontdeskButton title="Cancel" onClick={onClose} variant="danger" />
        <FrontdeskButton title="Edit booking" onClick={handleEditBooking} />
      </div>
    </Dialog>
  );
}

export default NoConsultantSelectedDialog;
