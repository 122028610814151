import Divider from '@mui/material/Divider';

function LoginFooter() {
  return (
    <div className="login-footer flex gap-6 text-dark-primary-color px-5 md:px-8 pb-10">
      <span className="text-sm">©Eye Op</span>
      <div className="flex gap-4">
        <span className="text-sm">Help center</span>
        <Divider orientation="vertical" />
        <span className="text-sm">Terms</span>
      </div>
      <span className="text-sm">Privacy policy</span>
    </div>
  );
}

export default LoginFooter;
