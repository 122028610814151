import React, {useState} from 'react';
import {PATIENT_DETAILS_MAP} from '../constant';
import {formatFullName} from '$utils/helper';
import {Divider, Tooltip} from '@mui/material';
import UserCircleIcon from '$assets/svgs/user-circle.svg?react';
import FrontdeskButton from '$components/FrontdeskButton';
import InvitePatientDialog from '../InvitePatientDialog';
import {dateToDDMMYYYY} from '$utils/date';
import IconButton from '$components/IconButton';
import {EditIcon} from '$assets/svgs';
import EditPatientDialog from '../EditPatient/EditPatientDialog';
import {
  PatientDetailsApiResponse,
  PutPatientDetailPayload,
} from '$api/patientList/patientDetails';
import {getRoute, RouteSection} from '$utils/route';
import '../styles.scss';
import './styles.scss';
interface PatientInfoComponentProps {
  patientDetails: Pick<PatientDetailsApiResponse, 'data'>;
  canInvitePatient: boolean;
  canEditPatient: boolean;
  modifyPatient: (payload: PutPatientDetailPayload) => void;
  isModifyingPatient: boolean;
  canViewProfile: boolean;
  patientId: number;
}
const PatientInfoComponent: React.FC<PatientInfoComponentProps> = ({
  patientDetails,
  canInvitePatient,
  canEditPatient,
  modifyPatient,
  isModifyingPatient,
  canViewProfile,
  patientId,
}) => {
  const [invitePatientDialog, setInvitePatientDialog] =
    useState<boolean>(false);
  const [editPatientOpen, setEditPatientOpen] = useState<boolean>(false);
  const {address, ...rest} = patientDetails?.data || {};
  const patientInviteEmail = patientDetails?.data?.email || '';
  const patientInviteStatus =
    patientDetails?.data.invitation_details?.status || '';
  const lastInviteSendDate =
    patientDetails?.data.invitation_details?.sent_at || '';
  const registrationStatus = patientDetails?.data?.registration_status;
  const accountDeleted = patientDetails?.data?.is_deleted;

  // Concatenate address line1 and line2 with a comma
  const concatenatedAddress = [address?.line1, address?.line2]
    .filter(Boolean)
    .join(', ');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const flattenedPatientDetails: any = {
    ...rest,
    ...address,
    concatenatedAddress,
  };
  const {full_name: patientName, uniq_code: patientUniqeCode} =
    flattenedPatientDetails;

  const half = Math.ceil(PATIENT_DETAILS_MAP.length / 2);
  const firstHalf = PATIENT_DETAILS_MAP.slice(0, half);
  const secondHalf = PATIENT_DETAILS_MAP.slice(half);

  const name = patientName || '-';
  const nameElement =
    name.length > 20 ? (
      <Tooltip title={patientName}>
        <span className="text">{formatFullName(name)}</span>
      </Tooltip>
    ) : (
      <div className="text">{patientName}</div>
    );

  const showInvitePatientButton = canInvitePatient && !registrationStatus;

  const handleEditPatientOpen = () => {
    setEditPatientOpen(!editPatientOpen);
  };

  const handleInvitePatientDialog = () => {
    setInvitePatientDialog(prevState => !prevState);
  };

  const handleViewFullProfileClick = () => {
    window.open(
      getRoute(RouteSection.PatientList, '', patientId),
      '_blank',
      'noopener noreferrer'
    );
  };

  const renderField = (item: (typeof PATIENT_DETAILS_MAP)[0]) => {
    const getDescription = () => {
      if (!item.description) {
        return '-';
      }

      switch (item.description) {
        case 'dob':
          return dateToDDMMYYYY(flattenedPatientDetails[item.description]);
        case 'address':
          return flattenedPatientDetails.concatenatedAddress;
        default:
          return flattenedPatientDetails[item.description] || '-';
      }
    };

    return (
      <div key={item.label} className="field-container">
        <div className="label">{item.label}</div>
        <div className="description">{getDescription()}</div>
      </div>
    );
  };

  return (
    <>
      <div className="patient-details row-span-3 bg-white">
        <div className="details-container">
          <div className="patient-avatar">
            {flattenedPatientDetails.profile_url ? (
              <img
                className="h-16 w-16 aspect-square rounded-full img-wrapper"
                src={flattenedPatientDetails.profile_url}
              />
            ) : (
              <UserCircleIcon className="primary h-16 w-16 aspect-square rounded-full img-wrapper " />
            )}
            <div className="name">
              {nameElement}
              <div className="uniq-code">
                {patientUniqeCode ? '#' + patientUniqeCode : '-'}
              </div>
            </div>
            <div className="field-container">
              <div className="label">Platform status</div>
              <div className="description">
                {registrationStatus ? 'Invited' : 'Not-invited'}
              </div>
              {accountDeleted && (
                <div className="text-danger font-semibold text-sm leading-6 tracking-tight">
                  Deleted Account
                </div>
              )}
            </div>
            {showInvitePatientButton && (
              <div className="invite-patient-btn">
                <FrontdeskButton
                  title="Invite patient"
                  onClick={handleInvitePatientDialog}
                />
              </div>
            )}
            {canViewProfile && (
              <div className="invite-patient-btn">
                <FrontdeskButton
                  title="View full profile"
                  onClick={handleViewFullProfileClick}
                />
              </div>
            )}
          </div>

          {canInvitePatient && invitePatientDialog && (
            <InvitePatientDialog
              open={invitePatientDialog}
              handleClose={handleInvitePatientDialog}
              status={patientInviteStatus}
              patientInviteEmail={patientInviteEmail}
              lastInviteSendDate={lastInviteSendDate}
            />
          )}

          <Divider orientation="vertical" className="divider" />
          <div className="patient-info">
            <div className="first-column">{firstHalf.map(renderField)}</div>
            <div className="second-column">{secondHalf.map(renderField)}</div>
          </div>
          {canEditPatient && (
            <IconButton className="flex h-fit" onClick={handleEditPatientOpen}>
              <EditIcon />
            </IconButton>
          )}
        </div>
      </div>
      {editPatientOpen && (
        <EditPatientDialog
          initialData={patientDetails}
          isModifyingPatientDetail={isModifyingPatient}
          modifyPatientRequest={modifyPatient}
          open={editPatientOpen}
          handleClose={handleEditPatientOpen}
        />
      )}
    </>
  );
};

export default PatientInfoComponent;
