import {usePatientQuestionnaire} from '~/services/api/MedicalHistory/Questionnaire/hooks';
import Loading from '~/components/Loading';
import FetchError from '~/components/FetchError';
import MedicalHistoryQuestionnaireForm from './form';
import {QuestionnaireType} from '~/services/api/MedicalHistory/Questionnaire/types';

interface MedicalQuestionnaireProps {
  patientId: number;
  currentPage: number;
  itemsPerPage: number;
  handlePageChange: (page: number) => void;
  handleBack: () => void;
}

function MedicalHistoryQuestionnaire(props: MedicalQuestionnaireProps) {
  const {handleBack, currentPage, handlePageChange, itemsPerPage, patientId} =
    props;

  const {
    disableSubmit,
    error,
    isCreatingPatientQuestionnaire,
    isLoading,
    questionnaire,
    submitPatientQuestionnaire,
  } = usePatientQuestionnaire({
    patientId,
    historyType: QuestionnaireType.MedicalHistory,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <FetchError />;
  }

  return (
    <MedicalHistoryQuestionnaireForm
      currentPage={currentPage}
      disableSubmit={disableSubmit || isCreatingPatientQuestionnaire}
      handleBack={handleBack}
      handlePageChange={handlePageChange}
      isCreatingPatientQuestionnaire={isCreatingPatientQuestionnaire}
      itemsPerPage={itemsPerPage}
      questionnaire={questionnaire}
      questionnaireType={QuestionnaireType.MedicalHistory}
      submitPatientQuestionnaire={submitPatientQuestionnaire}
    />
  );
}

export default MedicalHistoryQuestionnaire;
