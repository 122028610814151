import {ApiRoute, ConstantApiParamKey} from '$constants/api';
import {UserRoleName} from '$modules/admin/constant';
import axiosInstance from '.';

export interface PostLoginResponseData {
  data: {
    refresh_token: string;
    token: string;
    user: {
      id: number;
      profile_url: string | null;
      full_name: string;
      hospital_id: number | null;
      email: string;
      country_code: string;
      mobile_number: string;
      role_name: UserRoleName;
      hospital_details: {
        id: number;
        name: string;
        details: null;
        logo_url: null;
        country_code: string;
        mobile_number: string;
        email: string;
        sub_domain: string;
        timezone: string | null;
        more_info_url: string;
      };
    };
  };
  message: string;
}

interface PostLoginVariables {
  email: string;
  password: string;
}

export async function postLogin(variables: PostLoginVariables) {
  const {email, password} = variables;

  const response = await axiosInstance.post<PostLoginResponseData>(
    ApiRoute.Login,
    {
      user: {email, password},
    }
  );

  const responseData = response.data;
  const userData = responseData.data.user;

  return {
    refreshToken: responseData.data.refresh_token,
    token: responseData.data.token,
    user: {
      id: userData.id,
      profileUrl: userData.profile_url,
      fullName: userData.full_name,
      hospitalId: userData.hospital_id,
      email: userData.email,
      countryCode: userData.country_code,
      mobileNumber: userData.mobile_number,
      roleName: userData.role_name,
      timezone: userData.hospital_details.timezone,
    },
    message: responseData.message,
  };
}

export interface PutForgotPasswordResponseData {
  message: string;
}

export interface PutForgotPasswordVariables {
  email: string;
}

export async function putForgotPassword(variables: PutForgotPasswordVariables) {
  const {email} = variables;

  const response = await axiosInstance.put<PutForgotPasswordResponseData>(
    ApiRoute.ForgotPassword,
    {user: {email}}
  );

  return response.data;
}

export interface PutResetPasswordResponseData {
  message: string;
}

export interface PutResetPasswordVariables {
  token: string;
  password: string;
  confirmPassword: string;
}

export async function putResetPassword(variables: PutResetPasswordVariables) {
  const {password, confirmPassword, token} = variables;

  const response = await axiosInstance.put<PutResetPasswordResponseData>(
    ApiRoute.ResetPassword,
    {user: {password, confirm_password: confirmPassword, token}}
  );

  return response.data;
}

export interface PostValidateResetPasswordTokenResponseData {
  message: string;
}

export interface PostValidateResetPasswordTokenVariables {
  token: string;
}

export async function postValidateResetPasswordToken(
  variables: PostValidateResetPasswordTokenVariables
) {
  const {token} = variables;

  const response =
    await axiosInstance.post<PostValidateResetPasswordTokenResponseData>(
      ApiRoute.ValidatePasswordToken,
      {token}
    );

  return response.data;
}

interface GetHospitalInfoResponseData {
  data: {
    id: number;
    timezone: string;
    more_info_url: string;
    logo_url: string;
    background_img_url: string;
    title: string;
    description: string;
    mobile_number: string;
  };
}

export async function getHospitalInfo() {
  const response = await axiosInstance.get<GetHospitalInfoResponseData>(
    ApiRoute.Constant,
    {params: {key: ConstantApiParamKey.Hospital}}
  );

  return response.data;
}
