import {FormikErrors, FormikTouched} from 'formik';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import DatePicker from '$components/DatePicker';
import Input from '$components/Input';
import SelectInput from '$components/SelectInput';
import {Color} from '$constants/style';
import theme from '$modules/booking/theme';
import {PatientCarerDetailsFormData} from './schema';
import styles from '../../styles';
import {TITLE_ITEMS, GENDER_ITEMS} from '../../constant';
import {useCities, useCountries} from '../../hooks';

interface PatientDetailsFormProps {
  timezone: string;
  values: PatientCarerDetailsFormData;
  errors: FormikErrors<PatientCarerDetailsFormData>;
  touched: FormikTouched<PatientCarerDetailsFormData>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  setFieldTouched: (field: string, touched?: boolean) => void;
  setFieldValue: (field: string, value: unknown) => void;
  isPostCodeError: boolean;
}

function PatientDetailsForm(props: PatientDetailsFormProps) {
  const {
    timezone,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    isPostCodeError,
  } = props;

  const {countries} = useCountries();
  const {cities} = useCities(values?.patient?.country);

  const patient = values.patient;
  const errorsPatient = errors.patient;
  const touchedPatient = touched.patient;

  const renderTitleValue = (value: string) => {
    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value === '' ? 'Title' : value}
      </Typography>
    );
  };

  const renderTitleItem = (item: string) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  };

  const renderGenderValue = (value: string) => {
    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value === '' ? 'Gender' : value}
      </Typography>
    );
  };

  const renderGenderItem = (item: string) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  };

  const renderCityValue = (value: string) => {
    const item = cities.find(it => it.code === value);
    if (item) value = item.name;

    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value === '' ? 'Select City' : value}
      </Typography>
    );
  };

  const renderCityItem = (item: {code: string; name: string}) => (
    <MenuItem key={item.code} value={item.code}>
      {item.name}
    </MenuItem>
  );

  const renderCountryValue = (value: string) => {
    const item = countries.find(it => it.code === value);
    if (!item) {
      handleFieldChange('country', '');

      return (
        <Typography color={Color.TextPrimayPlaceholder}>
          Select country
        </Typography>
      );
    }

    return <Typography>{item.name}</Typography>;
  };

  const renderCountryItem = (item: {code: string; name: string}) => (
    <MenuItem key={item.code} value={item.code}>
      {item.name}
    </MenuItem>
  );

  const handleFieldChange = (field: string, defaultValue: unknown) => {
    const key = `patient.${field}`;

    return (value: unknown) => {
      setFieldTouched(key);
      setFieldValue(key, value || defaultValue);

      if (field === 'country') {
        setFieldValue('patient.city', '');
        setFieldTouched('patient.city', false);
      }
      if (field === 'city') {
        setFieldValue('patient.postcode', '');
        setFieldTouched('patient.postcode', false);
      }
    };
  };

  return (
    <Box flexDirection="column" display="flex" gap={2}>
      <Typography variant="h3" sx={styles.stepsTypeHead}>
        Patient details
      </Typography>
      <Box sx={styles.stepsTypeWrap}>
        <Box display="flex" flexDirection="column" gap={2} sx={{flex: 1}}>
          <Input
            name="patient.nhsNumber"
            placeholder="NHS number"
            type="number"
            value={patient?.nhsNumber || ''}
            onBlur={handleBlur}
            onChange={event => {
              if (event.target.value.length <= 10) handleChange(event);
            }}
            touched={touchedPatient?.nhsNumber}
            error={Boolean(errorsPatient?.nhsNumber)}
            helperText={
              touchedPatient?.nhsNumber && errorsPatient?.nhsNumber
                ? errorsPatient?.nhsNumber
                : ''
            }
          />
          <Box display="flex" flexDirection="column" gap={1}>
            <SelectInput
              name="patient.title"
              value={patient.title}
              items={TITLE_ITEMS}
              placeholder="Title"
              renderValue={renderTitleValue}
              renderItem={renderTitleItem}
              onBlur={handleBlur}
              onChange={handleFieldChange('title', '')}
            />
            {touchedPatient?.title && errorsPatient?.title && (
              <Typography
                variant="caption"
                color={theme.palette.error.main}
                fontWeight={400}
              >
                {errorsPatient?.title}
              </Typography>
            )}
          </Box>
          <Input
            name="patient.firstName"
            placeholder="First name"
            value={patient.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touchedPatient?.firstName}
            error={Boolean(errorsPatient?.firstName)}
            helperText={
              touchedPatient?.firstName && errorsPatient?.firstName
                ? errorsPatient?.firstName
                : ''
            }
          />
          <Input
            name="patient.lastName"
            placeholder="Last name"
            value={patient.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touchedPatient?.lastName}
            error={Boolean(errorsPatient?.lastName)}
            helperText={
              touchedPatient?.lastName && errorsPatient?.lastName
                ? errorsPatient?.lastName
                : ''
            }
          />
          <Box display="flex" flexDirection="column" gap={1}>
            <SelectInput
              name="patient.gender"
              value={patient.gender}
              items={GENDER_ITEMS}
              placeholder="Gender"
              renderValue={renderGenderValue}
              renderItem={renderGenderItem}
              onBlur={handleBlur}
              onChange={handleFieldChange('gender', '')}
            />
            {touchedPatient?.gender && errorsPatient?.gender && (
              <Typography
                variant="caption"
                color={theme.palette.error.main}
                fontWeight={400}
              >
                {errorsPatient?.gender}
              </Typography>
            )}
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <DatePicker
              isDOB
              disableFuture
              name="patient.dob"
              placeholder="Date of birth"
              value={patient.dob || null}
              onChange={handleFieldChange('dob', null)}
              timezone={timezone}
            />
            {touchedPatient?.dob && errorsPatient?.dob && (
              <Typography
                variant="caption"
                color={theme.palette.error.main}
                fontWeight={400}
              >
                {/* Formik issue https://github.com/jaredpalmer/formik/issues/3683 */}
                <>{errorsPatient?.dob}</>
              </Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={2} flex={1}>
          <Input
            name="patient.address"
            placeholder="Address line 1"
            value={patient.address}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touchedPatient?.address}
            error={Boolean(errorsPatient?.address)}
            helperText={
              touchedPatient?.address && errorsPatient?.address
                ? errorsPatient?.address
                : ''
            }
          />
          <Input
            name="patient.address2"
            placeholder="Address line 2 (optional)"
            value={patient.address2 || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touchedPatient?.address2}
            error={Boolean(errorsPatient?.address2)}
            helperText={
              touchedPatient?.address2 && errorsPatient?.address2
                ? errorsPatient?.address2
                : ''
            }
          />

          <SelectInput
            name="patient.country"
            value={patient.country}
            items={countries}
            placeholder="Country"
            renderValue={renderCountryValue}
            renderItem={renderCountryItem}
            onBlur={handleBlur}
            onChange={handleFieldChange('country', '')}
          />
          {touchedPatient?.country && errorsPatient?.country && (
            <Typography
              variant="caption"
              color={theme.palette.error.main}
              fontWeight={400}
            >
              {errorsPatient?.country}
            </Typography>
          )}
          <SelectInput
            name="patient.city"
            value={patient.city}
            items={cities}
            placeholder="Town or city"
            renderValue={renderCityValue}
            renderItem={renderCityItem}
            onBlur={handleBlur}
            onChange={handleFieldChange('city', '')}
          />
          {touchedPatient?.city && errorsPatient?.city && (
            <Typography
              variant="caption"
              color={theme.palette.error.main}
              fontWeight={400}
            >
              {errorsPatient?.city}
            </Typography>
          )}
          <Input
            name="patient.postcode"
            placeholder="Postcode"
            onBlur={handleBlur}
            onChange={handleChange}
            value={patient.postcode}
            touched={touchedPatient?.postcode}
            error={Boolean(errorsPatient?.postcode || isPostCodeError)}
            helperText={
              isPostCodeError ||
              (touchedPatient?.postcode && errorsPatient?.postcode)
                ? errorsPatient?.postcode
                : ''
            }
          />
        </Box>
      </Box>
    </Box>
  );
}

export default PatientDetailsForm;
