import {useCallback, useState} from 'react';
import {Button, Dialog, IconButton, MenuItem, Typography} from '@mui/material';
import {XCloseIcon} from '$assets/svgs';
import FrontdeskButton from '$components/FrontdeskButton';
import EditPatientDetailsForm from './EditPatientForm';
import {
  PatientDetailsApiResponse,
  PutPatientDetailPayload,
} from '$api/patientList/patientDetails';
import {useFormik} from 'formik';
import {
  EditPatientDetailsFormData,
  editPatientDetailsFormSchema,
  EditPatientDetailsValidatedFormData,
} from './schema';
import {Color} from '$constants/style';
import './styles.scss';
import {useCities, useCountries} from '$modules/booking/hooks';
import {dateToDDMMYYYY} from '$utils/date';

interface EditPatientDialogProps {
  initialData?: Pick<PatientDetailsApiResponse, 'data'> | undefined | null;
  isModifyingPatientDetail: boolean;
  modifyPatientRequest: (payload: PutPatientDetailPayload) => void;
  open: boolean;
  handleClose: () => void;
}

const renderItem = (item: string) => (
  <MenuItem key={item} value={item}>
    {item}
  </MenuItem>
);

const renderValue = (value: string, placeholder: string) => (
  <Typography color={value ? undefined : Color.TextPrimayPlaceholder}>
    {value || placeholder}
  </Typography>
);

const EditPatientDialog: React.FC<EditPatientDialogProps> = ({
  initialData,
  isModifyingPatientDetail,
  modifyPatientRequest,
  open,
  handleClose,
}) => {
  const [countryCode, setCountryCode] = useState(
    initialData?.data.address.country || ''
  );

  const initialDob = initialData?.data.dob
    ? new Date(initialData?.data.dob)
    : null;

  const handleSubmit = (data: EditPatientDetailsFormData) => {
    const values = data as EditPatientDetailsValidatedFormData;

    if (values) {
      const payload: PutPatientDetailPayload = {
        patient: {
          dob: dateToDDMMYYYY(values.patient.dob),
          email: values.patient.email,
          mobile_number: values.patient.phoneNumber,
          gender: values.patient.gender,
          address: {
            id: initialData?.data.address.id || 0,
            postcode: values.patient.postCode,
            city: values.patient.city,
            line1: values.patient.line1,
            line2: values.patient.line2 || '',
            country: values.patient.country,
          },
        },
      };
      modifyPatientRequest(payload);
      handleClose();
    }
  };

  const form = useFormik<EditPatientDetailsFormData>({
    initialValues: {
      patient: {
        dob: initialDob || null,
        email: initialData?.data.email || '',
        gender: initialData?.data.gender || '',
        phoneNumber: initialData?.data.mobile_number
          ? +initialData.data.mobile_number
          : NaN,
        postCode: initialData?.data.address.postcode || '',
        line1: initialData?.data.address.line1 || '',
        line2: initialData?.data.address.line2 || '',
        city: initialData?.data.address.city || '',
        country: initialData?.data.address.country || '',
      },
    },
    validationSchema: editPatientDetailsFormSchema,
    onSubmit: handleSubmit,
  });

  const {setFieldTouched, setFieldValue, submitForm} = form;

  const {countries} = useCountries();
  const {cities} = useCities(form.values?.patient?.country);

  const renderCountryValue = (value: string) => {
    const item = countries.find(it => it.code === value);
    if (item) value = item.name;

    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value ? value : 'Select country'}
      </Typography>
    );
  };

  const renderCountryItem = (item: {code: string; name: string}) => (
    <MenuItem key={item.code} value={item.code}>
      {item.name}
    </MenuItem>
  );

  const renderCityValue = (value: string) => {
    const item = cities.find(it => it.code === value);
    if (item) value = item.name;

    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value === '' ? 'Select City' : value}
      </Typography>
    );
  };

  const renderCityItem = (item: {code: string; name: string}) => (
    <MenuItem key={item.code} value={item.code}>
      {item.name}
    </MenuItem>
  );

  const handleFieldChange = useCallback(
    (field: string, defaultValue: unknown) => {
      const key = `patient.${field}`;
      return (value: unknown) => {
        setFieldTouched(key);
        setFieldValue(key, value || defaultValue);

        if (field === 'country') {
          setFieldValue('patient.city', '');
          setFieldTouched('patient.city', false);
          setCountryCode(value as string);
        }
      };
    },
    [setFieldTouched, setFieldValue]
  );

  return (
    <Dialog open={open} className="edit-patient-dialog-container">
      <div className="dialog-header">
        <div className="heading">Modify patient details</div>
        <div className="edit-patient-close-icon-container">
          <IconButton onClick={handleClose}>
            <XCloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center">
        <EditPatientDetailsForm
          values={form.values}
          errors={form.errors}
          touched={form.touched}
          handleBlur={form.handleBlur}
          handleChange={form.handleChange}
          handleFieldChange={handleFieldChange}
          cities={cities}
          countries={countries}
          countryCode={countryCode}
          renderCityItem={renderCityItem}
          renderCityValue={renderCityValue}
          renderCountryItem={renderCountryItem}
          renderCountryValue={renderCountryValue}
          renderItem={renderItem}
          renderValue={renderValue}
        />
      </div>
      <div className="dialog-footer">
        <Button className="cancel-button" onClick={handleClose}>
          Cancel
        </Button>
        <FrontdeskButton
          title="Save"
          disabled={isModifyingPatientDetail}
          onClick={submitForm}
        />
      </div>
    </Dialog>
  );
};

export default EditPatientDialog;
