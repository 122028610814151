import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {SelectInputProps as MUISelectInputProps} from '@mui/material/Select/SelectInput';
import {menuPaperStyles, selectInputStyles} from './styles';
import {ChevronDownFrontdeskIcon} from '$assets/svgs';

interface SelectInputProps<T> {
  name?: string;
  items: T[];
  value: string;
  placeholder?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange: (value: string) => void;
  renderValue: (value: string) => React.ReactNode;
  renderItem: (item: T) => React.ReactNode;
}

function SelectYear<T>(props: SelectInputProps<T>) {
  const {
    name,
    items,
    value,
    placeholder,
    onBlur,
    onChange,
    renderValue,
    renderItem,
  } = props;

  const menuItems = items.map(renderItem);

  const handleChange: MUISelectInputProps<string>['onChange'] = event => {
    onChange(event.target.value);
  };

  return (
    <FormControl>
      <Select
        variant="standard"
        disableUnderline
        IconComponent={ChevronDownFrontdeskIcon}
        name={name}
        placeholder={placeholder}
        displayEmpty
        value={value}
        onBlur={onBlur}
        onChange={handleChange}
        renderValue={value !== '' ? renderValue : () => placeholder}
        MenuProps={{
          slotProps: {
            paper: {
              sx: menuPaperStyles,
            },
          },
        }}
        sx={selectInputStyles}
        inputProps={{'aria-label': 'Without label'}}
      >
        {placeholder && <MenuItem value="">{placeholder}</MenuItem>}
        {menuItems}
      </Select>
    </FormControl>
  );
}

export default SelectYear;
