import SelectInput from '$components/SelectInput';
import {Box, MenuItem} from '@mui/material';
import {useCancellationReasonHook} from '../../hooks/useCancellationReasonHook';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';
import {ICancellationReason} from '../../types';
import {consultantSelectionViewStyles} from '$modules/booking/styles';
import './styles.scss';

interface CancelAppointmentFormProps {
  handleCancellationReasonChange: (value: string) => void;
  cancellationReasonId: string;
  setCancellationReasons: React.Dispatch<
    React.SetStateAction<ICancellationReason[]>
  >;
}

const CancelAppointmentForm: React.FC<CancelAppointmentFormProps> = ({
  handleCancellationReasonChange,
  cancellationReasonId,
  setCancellationReasons,
}) => {
  const {isPending, data} = useCancellationReasonHook();

  if (isPending) return <Loading />;
  if (!data) return <FetchError />;

  setCancellationReasons(data.data);

  const renderMenuItem = (item: ICancellationReason) => {
    return (
      <MenuItem
        key={item.id}
        value={item.id.toString()}
        sx={consultantSelectionViewStyles.menuItemContainer}
      >
        <span>{item.name}</span>
      </MenuItem>
    );
  };

  const renderValue = (value: string) => {
    if (!value) return 'Select reason';
    const item = data.data.find(it => it.id.toString() === value);
    if (!item) {
      return '';
    }
    return (
      <Box sx={consultantSelectionViewStyles.menuItemContainer}>
        <span>{item.name}</span>
      </Box>
    );
  };

  return (
    <div className="input-container">
      <div className="input-container-heading">Select a reason</div>
      <SelectInput
        items={data.data}
        renderValue={renderValue}
        renderItem={renderMenuItem}
        onChange={handleCancellationReasonChange}
        value={cancellationReasonId ? String(cancellationReasonId) : ''}
      />
    </div>
  );
};

export default CancelAppointmentForm;
