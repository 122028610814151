import {Typography} from '@mui/material';
import {
  appointmentListHeader,
  appointmentListHeaderBackButton,
} from '../Appointments/styles';
import CalendarDetailsPageHeader from '../CalendarDetailsPageHeader';
import PendingListItem from './PendingListItem';
import FilterOptionsContainer from '../FilterOptions';
import {AppointmentInfo} from '$modules/frontdesk/type';
import PendingListItemSkeleton from './PendingListItemSkeleton';

interface PendingRequestProps {
  newPatientRequests: boolean;
  searchText: string;
  setSearchText: (value: string) => void;
  filters: {
    appointmentTypes: number[];
    consultants: number[];
    status: string[];
  };
  onFilterChange: (
    type: 'appointmentTypes' | 'consultants' | 'status',
    id: number | string
  ) => void;
  onApply: () => void;
  onReset: () => void;
  appointments: AppointmentInfo[];
  isLoading: boolean;
}

const PendingComponent: React.FC<PendingRequestProps> = ({
  newPatientRequests,
  searchText,
  setSearchText,
  filters,
  onFilterChange,
  onApply,
  onReset,
  appointments,
  isLoading,
}) => {
  const pageTitle = newPatientRequests
    ? 'New patient requests'
    : 'Pending requests';

  return (
    <div className="flex w-full flex-col overflow-y-hidden bg-[#f6f6ff] px-8 pb-8">
      <div className="w-full bg-[#f6f6ff]">
        <CalendarDetailsPageHeader
          headerClassName={appointmentListHeader}
          headerBackButtonClassName={appointmentListHeaderBackButton}
          headerBackLabel="Back"
          containsSearchBox={true}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      </div>
      <div className="flex flex-col overflow-hidden py-4">
        <div className="flex w-full justify-between gap-4 overflow-y-auto">
          <div className="flex w-full flex-col gap-4 overflow-auto">
            <div className="flex flex-col gap-2">
              <Typography variant="pageTitle">{pageTitle}</Typography>
            </div>
            <div className="flex h-full w-full flex-col gap-4 overflow-x-auto overflow-y-auto">
              {isLoading ? (
                <PendingListItemSkeleton />
              ) : (
                <PendingListItem
                  isLoading={isLoading}
                  appointments={appointments}
                />
              )}
            </div>
          </div>
          <div className="flex w-full max-w-fit flex-col gap-4 overflow-x-auto overflow-y-auto px-2">
            <div className="flex w-full mt-14">
              <FilterOptionsContainer
                containStatus={false}
                containAppointmentTypes={!newPatientRequests}
                containConsultants={true}
                filters={filters}
                onFilterChange={onFilterChange}
                onApply={onApply}
                onReset={onReset}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingComponent;
