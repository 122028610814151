import {type SxProps} from '@mui/material';
import theme from '$modules/booking/theme';

const styles: Record<string, SxProps> = {
  button: {
    borderColor: theme.palette.primary.main,
    width: '100%',
  },
  input: {
    display: 'none',
  },
};

export default styles;
