import SelectInput from '$components/SelectInput';
import {Consultant} from '$modules/booking/types';
import {FormState} from '../types';
import './styles.scss';

interface ConsultantSelectionComponentProps {
  consultantList: Consultant[];
  offlineBookingFormData: FormState;
  handleConsultantChange: (value: string) => void;
  renderConsultantMenuItem: (item: Consultant) => React.ReactNode;
  renderValue: (value: string) => React.ReactNode;
}

const ConsultantSelectionComponent: React.FC<
  ConsultantSelectionComponentProps
> = ({
  consultantList,
  offlineBookingFormData,
  handleConsultantChange,
  renderConsultantMenuItem,
  renderValue,
}) => {
  const selectedConsultant = offlineBookingFormData.appointment.doctor_id;
  return (
    <>
      <h2 className="dialog-title">Select a consultant</h2>
      <div className="consultant-selection-container">
        <div className="">
          <SelectInput
            fullWidth
            items={consultantList}
            renderValue={renderValue}
            renderItem={renderConsultantMenuItem}
            onChange={handleConsultantChange}
            value={selectedConsultant ? selectedConsultant.toString() : ''}
          />
        </div>
      </div>
    </>
  );
};

export default ConsultantSelectionComponent;
