import {postOfflineCreateAppointment} from '$api/frontdesk';
import {ToastType} from '$components/Toast/constant';
import {
  networkErrorToast,
  technicalGlitchToast,
} from '$constants/toastmessages';
import toast from '$utils/toast';
import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {FormState, PostCreateAppointmentErrorResponseData} from '../types';
import {usePatientDetailsHook} from '$modules/frontdesk/PatientList/PatientDetails/hooks/usePatientDetailsHook';
import {OfflineBookingStep} from '../constant';
import {SetStateAction} from 'react';

export const useCreateOfflineAppointmentHook = (
  setActiveStep: React.Dispatch<SetStateAction<OfflineBookingStep>>,
  setOfflineBookedAppointmentId: React.Dispatch<React.SetStateAction<number>>,
  body: FormState,
  offlineBookingType: string
) => {
  const patientId = body.appointment.patient.id;
  const {data: patientDetails} = usePatientDetailsHook(patientId!);
  const {isPending, mutate} = useMutation({
    mutationKey: ['create-offline-appointment'],
    mutationFn: postOfflineCreateAppointment,
    onSuccess: data => {
      setOfflineBookedAppointmentId(data.data?.appointment?.id);
      setActiveStep(OfflineBookingStep.BookingConfirmed);
    },
    onError: error => {
      if (error instanceof AxiosError) {
        const axiosError =
          error as AxiosError<PostCreateAppointmentErrorResponseData>;
        const response = axiosError.response;

        if (response) {
          if (response.status === 500) {
            return technicalGlitchToast();
          }
          if (response.data) {
            const errorMessage =
              response.data.errors[0] || response.data.message || '';
            return toast(
              ToastType.Error,
              'Error creating appointment',
              errorMessage
            );
          }
        }

        if (axiosError.message === 'Network Error') {
          return networkErrorToast();
        }

        return technicalGlitchToast();
      } else {
        technicalGlitchToast();
      }
    },
  });

  const createAppointment = (payload: FormState) => {
    if (offlineBookingType === 'surgery') {
      if (patientDetails?.data.address.id)
        payload.appointment.patient.address.id =
          patientDetails?.data.address.id;
    }
    mutate(payload);
  };

  return {
    isPending,
    createAppointment,
  };
};
