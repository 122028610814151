import {useEffect, useState} from 'react';
import {useCheckInPatientHook} from '../../hooks/useCheckInPatientHook';
import {PatchCheckInPatientPayloadType} from '$api/frontdesk';
import CheckInTimerComponent from './component';

interface CheckInTimerProps {
  startTime: string;
  endTime?: string;
  appointmentId: number;
}

const CheckInTimer = (props: CheckInTimerProps) => {
  const {appointmentId, startTime, endTime} = props;
  const checkInTime = new Date(startTime).getTime();
  const checkOutTime = endTime ? new Date(endTime).getTime() : null;
  const {sendCheckInPatient} = useCheckInPatientHook();
  const [currentTime, setCurrentTime] = useState<number>(new Date().getTime());

  useEffect(() => {
    let intervalId = null;
    if (!endTime) {
      intervalId = setInterval(() => {
        setCurrentTime(new Date().getTime());
      }, 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [endTime]);

  const elapsedTime = checkOutTime
    ? checkOutTime - checkInTime
    : currentTime - checkInTime;
  const currentMinutes = Math.floor(
    (elapsedTime % (1000 * 60 * 60)) / (1000 * 60)
  );
  const currentSeconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);

  const handleEndTimer = () => {
    const payload: PatchCheckInPatientPayloadType = {
      appointmentId,
      check_out_time: new Date().toISOString(),
      check_in_time: startTime,
    };
    sendCheckInPatient(payload);
  };

  return (
    <CheckInTimerComponent
      currentMinutes={currentMinutes}
      currentSeconds={currentSeconds}
      handleEndTimer={handleEndTimer}
      showStopTimer={!endTime}
    />
  );
};

export default CheckInTimer;
