import {getBookingDetails} from '$api/frontdesk';
import {useQuery} from '@tanstack/react-query';

export const useBookingDetailsHook = (id: number) => {
  const {isPending, isError, data, isFetching} = useQuery({
    queryKey: ['appointment-detail', id],
    queryFn: () => getBookingDetails(id),
  });

  const bookingDetails = data?.data;

  return {
    isPending,
    isError,
    data: bookingDetails,
    isFetching,
  };
};
