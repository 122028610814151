import {CalendarPlusIcon} from '~/assets/svgs';
import SelectInput from '~/components/SelectInput';
import Input from '~/components/Input';
import {momentDate} from '~/utils/date';
import {EditBookingFormData} from '../schema';
import {FormikErrors, FormikTouched} from 'formik';
import {Care, Consultant} from '~/modules/booking/types';
import {PreferredTime, SubCare} from '../types';

interface AppointmentDetailsComponentProps {
  values: EditBookingFormData;
  errors: FormikErrors<EditBookingFormData>;
  touched: FormikTouched<EditBookingFormData>;
  preferredTimes: PreferredTime[];
  consultants: Consultant[];
  careTypes?: Care[];
  subCareTypes: SubCare[];
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  renderConsultantMenuItem: (item: Consultant) => React.ReactNode;
  renderConsultantValue: (value: string) => React.ReactNode;
  renderPreferredTimeMenuItem: (item: PreferredTime) => React.ReactNode;
  renderPreferredTimeValue: (value: string) => React.ReactNode;
  renderSubCareTypeMenuItem: (item: SubCare) => React.ReactNode;
  renderSubCareTypeValue: (value: string) => React.ReactNode;
  renderCareTypeMenuItem: (item: Care) => React.ReactNode;
  renderCareTypeValue: (value: string) => React.ReactNode;
  handleFieldChange: (
    field: string,
    value: number | string | boolean | Date
  ) => void;
}

const AppointmentDetailsComponent = (
  props: AppointmentDetailsComponentProps
) => {
  const {
    consultants,
    errors,
    handleBlur,
    renderConsultantMenuItem,
    renderConsultantValue,
    renderSubCareTypeMenuItem,
    renderSubCareTypeValue,
    subCareTypes,
    touched,
    values,
    handleFieldChange,
  } = props;
  return (
    <div className="flex flex-col p-6 bg-tertiary rounded-lg border-container flex-[5]">
      <div className="flex gap-2 items-center">
        <CalendarPlusIcon />
        <h2 className="font-semibold text-lg leading-6 tracking-tight bg-gradient-clip">
          Appointment details
        </h2>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <div className="font-normal text-sm leading-4 tracking-tight text-primary">
          Requested date
        </div>
        <Input
          name="appointment.date"
          value={momentDate(new Date(values.appointment.date))
            .format('DD/MM/YYYY')
            .toString()}
          onChange={() => {}}
          disabled
        />
      </div>
      {/* appointmentType */}
      <div className="flex flex-col gap-3 mt-3">
        <div className="font-normal text-sm leading-4 tracking-tight text-primary">
          Appointment Type
        </div>
        <Input
          name="appointment.care_type_id"
          value="Surgery consultation"
          onChange={() => {}}
          disabled
        />
      </div>

      <div className="flex flex-col gap-3 mt-3">
        <div className="font-normal text-sm leading-4 tracking-tight text-primary">
          Surgery Type
        </div>
        <SelectInput
          name="appointment.sub_care_type_id"
          items={subCareTypes}
          renderItem={renderSubCareTypeMenuItem}
          renderValue={renderSubCareTypeValue}
          value={String(values.appointment.sub_care_type_id)}
          onBlur={handleBlur}
          onChange={value => handleFieldChange('sub_care_type_id', +value)}
          error={
            touched.appointment?.sub_care_type_id &&
            Boolean(errors.appointment?.sub_care_type_id)
          }
        />
        {touched.appointment?.sub_care_type_id &&
          errors.appointment?.sub_care_type_id && (
            <p className="m-0 font-sans font-normal text-xs leading-relaxed tracking-wide text-danger">
              {errors.appointment?.sub_care_type_id}
            </p>
          )}
      </div>

      {/* consultants */}
      <div className="flex flex-col gap-3 mt-3">
        <div className="font-normal text-sm leading-4 tracking-tight text-primary">
          Consultant
        </div>
        <SelectInput
          name="appointment.doctor_id"
          items={consultants}
          renderValue={renderConsultantValue}
          renderItem={renderConsultantMenuItem}
          value={String(values.appointment.doctor_id)}
          onBlur={handleBlur}
          onChange={value => handleFieldChange('doctor_id', +value)}
          error={
            touched.appointment?.doctor_id &&
            Boolean(errors.appointment?.doctor_id)
          }
        />
        {touched.appointment?.doctor_id && errors.appointment?.doctor_id && (
          <p className="m-0 font-sans font-normal text-xs leading-relaxed tracking-wide text-danger ">
            {errors.appointment?.doctor_id}
          </p>
        )}
      </div>
    </div>
  );
};

export default AppointmentDetailsComponent;
