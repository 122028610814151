import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useState} from 'react';
import toast from '$utils/toast';
import {ToastType} from '$components/Toast/constant';
import {handleErrorResponse} from '$utils/axios';
import {
  putPatientStatus,
  PutPatientStatusPayload,
} from '$api/patientList/patientDetails';

export function usePutPatientStatus(onSuccess: () => void) {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const queryClient = useQueryClient();

  const {mutate, isPending} = useMutation({
    mutationKey: ['patient-status'],
    mutationFn: putPatientStatus,
    onSuccess: data => {
      toast(ToastType.Success, 'Update Patient Status', data.message);
      queryClient.invalidateQueries({
        queryKey: ['patient-details'],
      });
      onSuccess();
    },
    onError: error => handleErrorResponse(error, 'Update Patient Status'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendUpdatePatientStatusRequest = (payload: PutPatientStatusPayload) => {
    setDisableSubmit(true);
    mutate(payload);
  };

  return {sendUpdatePatientStatusRequest, isPending, disableSubmit};
}
