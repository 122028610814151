import {SxProps} from '@mui/material';
import theme from '$modules/booking/theme';

const styles: Record<string, SxProps> = {
  wrapper: {
    '&.MuiPaper-root': {
      position: 'relative',
    },
  },
  closeButton: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(1),
    borderRadius: '0.5rem',
    minWidth: 'unset',
    color: 'unset',

    [theme.breakpoints.up('lg')]: {
      right: '2rem',
      top: '2rem',
    },

    '& svg': {
      color: theme.palette.text.primary,
    },

    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
      '& svg': {
        color: theme.palette.primary2.darkest,
        opacity: 2,
      },
    },
    '&:hover svg': {
      color: theme.palette.primary2.darkest,
    },
  },
  body: {
    padding: theme.spacing(3, 6, 2, 3),

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(6, 9, 4, 4),
    },
  },
  actionsContainer: {
    padding: theme.spacing(3, 3),
    justifyContent: 'flex-start',
  },
};

export default styles;
