import {InferType, boolean, number, object, string} from 'yup';
import {ValidationMessage} from '$constants/messages';
import {MEMBERSHIP_VALIDATION_REGEX} from '$constants/regex';

export const patientInsuranceDetailFormSchema = object({
  selfPaying: boolean().required(ValidationMessage.SelectOption),
  paymentMode: number()
    .transform(value => (isNaN(value) ? undefined : value))
    .nullable()
    .notRequired(),
  insuranceId: number().when('selfPaying', {
    is: false,
    then: schema => schema.required(ValidationMessage.InsuranceRequired),
    otherwise: schema => schema.notRequired(),
  }),
  membershipNumber: string()
    .trim()
    .when(['selfPaying', 'insuranceId'], {
      is: (selfPaying: boolean) => !selfPaying,
      then: schema => schema.required(ValidationMessage.MembershipRequired),
      otherwise: schema => schema.notRequired(),
    })
    .matches(MEMBERSHIP_VALIDATION_REGEX, ValidationMessage.MembershipInvalid),
});

export type PatientInsuranceDetailFormData = InferType<
  typeof patientInsuranceDetailFormSchema
>;
