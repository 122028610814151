import {type Shadows} from '@mui/material';

export const enum Font {
  Lexend = '"Lexend", sans-serif',
  Inter = '"Inter", sans-serif',
}

export const enum Color {
  Black = '#000000',
  White = '#ffffff',

  PrimaryMain = '#7625DC',
  PrimaryLight = '#EBC3FF',
  PrimaryDark = '#D888FF',
  PrimaryDarkest = '#BB5EE8',

  Primary2Main = '#D9DAFF',
  Primary2Light = '#C0C2FF',
  Primary2Dark = '#6063F2',
  Primary2Darkest = '#3538CD',

  SidebarMain = '#F6F6FF',

  SecondaryMain = '#FFFD98',
  SecondaryDark = '#6371B6',

  TextPrimary = '#475467',
  TextSecondary = '#344054',
  TextTertiary = '#1D2939',

  TextPrimayPlaceholder = '#4754677F',

  Grey50 = '#F9FAFB',
  Grey100 = '#FCFCFD',
  Grey200 = '#F2F4F7',
  Grey300 = '#EAECF0',
  Grey400 = '#667085',
  Grey500 = '#101828',

  Purple600 = '#4A168C',

  ErrorMain = '#D92D20',
  ErrorLight = '#FEF3F2',

  SuccessMain = '#03820F',
  SuccessLight = '#F6FEF9',

  PendingMain = '#FEF0C7',

  GradientBackground = 'linear-gradient(180deg, #7625DC 0%, #6063F2 100%)',
  GradientHoverBackground = 'linear-gradient(180deg, #5629A9 0%, #503CB2 50%, #4C51BB 100%)',
  GradientDisabledBackground = 'linear-gradient(180deg, rgba(118, 37, 220, 0.40) 0%, rgba(96, 99, 242, 0.40) 100%)',
  GradientButtonBackground = 'linear-gradient(180deg, #7625DC 0%, #4A168C 100%)',

  ButtonDisabledBackground = 'rgba(255, 255, 255, 0.60)',

  BlackOpacity60 = 'rgba(118, 37, 220, 0.60)',
  BlackOpacity40 = 'rgba(255, 255, 255, 0.40)',
}

export const enum Border {
  Normal = '1px solid #F2F4F7',
  Disabled = '1px solid rgba(242, 244, 247, 0.60)',
}

// It is currently required to have 25 shadows
// https://github.com/mui/material-ui/issues/28820
export const THEME_SHADOWS: Shadows = [
  'none',
  '2px 2px 8px 4px #10182805',
  '4px 4px 16px 8px #1018280F',
  '12px 12px 24px 12px #1018280F',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
];
