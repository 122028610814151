import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styles, {
  EoCheckedIcon,
  EoErrorCheckedIcon,
  EoErrorIcon,
  EoIcon,
} from './styles';

interface RadioBoxProps {
  label: string;
  value: string;
  error?: boolean;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function RadioBox(props: RadioBoxProps) {
  const {label, error, disabled, value, checked, onChange} = props;

  const labelStyle = error
    ? styles.radioErrorLabel
    : disabled
      ? styles.radioDisabledLabel
      : styles.radioLabel;

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (!disabled && onChange) {
      onChange({
        target: {value},
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <Box sx={styles.boxStyle}>
      <FormControlLabel
        label={
          <Typography
            variant="body1"
            sx={{
              ...labelStyle,
              pointerEvents: 'auto',
              cursor: disabled ? 'default' : 'pointer',
            }}
            onClick={handleClick}
          >
            {label}
          </Typography>
        }
        control={
          <Radio
            disableRipple
            color="default"
            checked={checked}
            checkedIcon={error ? <EoErrorCheckedIcon /> : <EoCheckedIcon />}
            icon={error ? <EoErrorIcon /> : <EoIcon />}
            disabled={disabled}
            value={value}
            sx={{
              pointerEvents: 'auto',
              cursor: disabled ? 'default' : 'pointer',
            }}
            onChange={onChange}
          />
        }
        sx={styles.radioContainer}
      />
    </Box>
  );
}

export default RadioBox;
