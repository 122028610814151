import './styles.scss';

interface ActionCardProps {
  description: string;
  icon: React.ReactNode;
  title: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  testId?: string;
}

function ActionCard(props: ActionCardProps) {
  const {
    description,
    icon,
    title,
    active = false,
    disabled = false,
    onClick,
    testId,
  } = props;

  const containerClassName = `
    font-booking flex gap-12 p-6 lg:px-8 bg-white rounded-lg shadow-lg 
    border-2 border-solid focus:border-primary-4 hover:border-primary-4 
    ${active ? 'border-primary-4' : 'border-transparent'}
    ${disabled ? 'opacity-60 text-unset hover:border-transparent cursor-default' : 'opacity-100 cursor-pointer'}
  `;

  const iconClassName = `booking-flow-action-card-icon ${disabled ? 'disabled' : ''}`;

  return (
    <div
      className={containerClassName}
      onClick={disabled ? () => {} : onClick}
      data-testid={testId}
    >
      <span className={iconClassName}>{icon}</span>
      <div>
        <h3 className="text-lg lg:text-2xl">{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default ActionCard;
