import * as Yup from 'yup';
import {ValidationMessage} from '$constants/messages';

export const validationSchemaOtp = Yup.object().shape({
  email: Yup.string()
    .trim()
    .max(350, ValidationMessage.EmailExceedCharacterLimit)
    .email(ValidationMessage.EmailInvalid)
    .required(ValidationMessage.EmailRequired),
  otp: Yup.string()
    .required('OTP is required')
    .length(4, 'OTP must be exactly 4 characters'),
});

export interface OtpFormValues {
  otp: string;
  email: string;
}
