import {InferType, number, object, ref, string} from 'yup';
import {ValidationMessage} from '$constants/messages';

export const patientContactDetailFormSchema = object({
  patient: object({
    email: string()
      .trim()
      .email(ValidationMessage.EmailInvalid)
      .max(350, ValidationMessage.EmailExceedCharacterLimit)
      .required(ValidationMessage.EmailRequired),
    confirmEmail: string()
      .trim()
      .when('email', (email, schema) =>
        email
          ? schema
              .oneOf([ref('email')], ValidationMessage.EmailMisMatch)
              .required(ValidationMessage.EmailRequired)
          : schema.notRequired()
      ),
    phoneNumber: number()
      .typeError(ValidationMessage.PhoneNumberInvalid)
      .positive(ValidationMessage.PhoneNumberInvalid)
      .integer(ValidationMessage.PhoneNumberInvalid)
      .min(10_000_00, ValidationMessage.PhoneNumberInvalid)
      .max(999_999_999_999_999, ValidationMessage.PhoneNumberInvalid)
      .required(ValidationMessage.PhoneNumberRequired),
    preferredContactMethod: string().required(
      ValidationMessage.PreferredContactMethodRequired
    ),
  }),
});

export type PatientContactDetailsFormData = InferType<
  typeof patientContactDetailFormSchema
>;

export const patientContactDetailRegisteredFormSchema = object({
  patient: object({
    phoneNumber: number()
      .typeError(ValidationMessage.PhoneNumberInvalid)
      .positive(ValidationMessage.PhoneNumberInvalid)
      .integer(ValidationMessage.PhoneNumberInvalid)
      .min(10_000_00, ValidationMessage.PhoneNumberInvalid)
      .max(999_999_999_999_999, ValidationMessage.PhoneNumberInvalid)
      .required(ValidationMessage.PhoneNumberRequired),
    preferredContactMethod: string().required(
      ValidationMessage.PreferredContactMethodRequired
    ),
  }),
});
