import React from 'react';
import {PlusSquareIcon, SearchRefractionIcon} from '$assets/svgs';
import FrontdeskButton from '$components/FrontdeskButton';
import FrontdeskSearchbox from '$components/FrontdeskSearchBox';
import './styles.scss';

interface SelectOrBookPatientComponentProps {
  handleBookANewPatientClick: () => void;
  selectedPatientId: number | null;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

const SelectOrBookPatientComponent: React.FC<
  SelectOrBookPatientComponentProps
> = ({
  handleBookANewPatientClick,
  selectedPatientId,
  searchText,
  setSearchText,
}) => {
  return (
    <div className="search-patient-form-container">
      <div className="search-patient-dropdown">
        <FrontdeskSearchbox
          value={searchText}
          startIcon={<SearchRefractionIcon />}
          placeholder="Search patient"
          onChange={setSearchText}
        />
      </div>
      <div className="search-text-description">
        <div className="title">
          This is a global search, you can search for a patient by:
        </div>
        <div className="keywords">
          Name, Patient number, Phone number, Email, Date of birth (dd/mm/yyyy)
        </div>
      </div>
      {!selectedPatientId && !searchText && (
        <FrontdeskButton
          title="Book a new patient"
          className="book-patient-btn"
          startIcon={
            <span className="start-icon">
              <PlusSquareIcon />
            </span>
          }
          onClick={handleBookANewPatientClick}
        />
      )}
    </div>
  );
};

export default SelectOrBookPatientComponent;
