type QuestionnaireTitleProps = {
  title: string;
  className?: string;
  'data-testid'?: string;
};

const QuestionnaireTitle = ({
  title,
  className,
  'data-testid': testId,
}: QuestionnaireTitleProps) => {
  return (
    <div className={className} data-testid={testId}>
      {title}
    </div>
  );
};

export default QuestionnaireTitle;
