import {Button, ButtonProps} from '@mui/material';
import {PropsWithChildren} from 'react';

interface BookingButtonProps {
  variant?: 'primary' | 'secondary' | 'tertiary';
  startIcon?: ButtonProps['startIcon'];
  endIcon?: ButtonProps['endIcon'];
  testId?: string;
  disabled?: boolean;
  onClick: ButtonProps['onClick'];
}

function BookingButton(props: PropsWithChildren<BookingButtonProps>) {
  const {
    variant = 'primary',
    disabled = false,
    startIcon,
    endIcon,
    onClick,
    children,
    testId,
  } = props;

  let className =
    'text-base py-2 px-6 rounded-lg normal-case disabled:opacity-50';

  switch (variant) {
    case 'primary':
      className +=
        ' background-gradient-booking-button text-white disabled:text-white';
      break;
    case 'secondary':
      className += ' bg-white text-primary-4 disabled:text-primary-4';
      break;
    case 'tertiary':
      className += ' font-normal bg-grey-7';
      break;
    default:
      break;
  }

  return (
    <Button
      className={className}
      onClick={onClick}
      data-testid={testId}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {children}
    </Button>
  );
}

export default BookingButton;
