import Box from '@mui/material/Box';
import {
  BookingStepperItem,
  BookingStepperMobileActiveItem,
} from './BookingStepperItem';
import styles from './styles';
import {BookingStepperStage} from '../../types';

interface BookingStepperProps {
  activeStage: BookingStepperStage;
}

const BookingStepper = (props: BookingStepperProps) => {
  const {activeStage} = props;

  return (
    <Box>
      {/* Mobile view */}
      <Box sx={styles.mobileActiveStep}>
        <BookingStepperMobileActiveItem stage={activeStage} type="active" />
      </Box>
      <Box sx={styles.stepperWrap}>
        <BookingStepperItem
          stage={BookingStepperStage.Appointment}
          type={
            activeStage === BookingStepperStage.Appointment
              ? 'active'
              : 'disabled'
          }
        />
        <BookingStepperItem
          stage={BookingStepperStage.PatientDetails}
          type={
            activeStage === BookingStepperStage.Appointment
              ? 'inactive'
              : activeStage === BookingStepperStage.Insurance
                ? 'disabled'
                : 'active'
          }
        />
        <BookingStepperItem
          stage={BookingStepperStage.Insurance}
          type={
            activeStage === BookingStepperStage.Insurance
              ? 'active'
              : 'inactive'
          }
        />
      </Box>
    </Box>
  );
};

export default BookingStepper;
