import axiosInstance from '~/api';
import {
  GetPatientQuestionnaireParams,
  GetPatientQuestionnaireResponseData,
  PostPatientQuestionnairePayload,
  PostPatientQuestionnaireResponseData,
} from './types';
import {ApiRoute} from '~/constants/api';

export async function getPatientQuestionnaire(
  params: GetPatientQuestionnaireParams
) {
  const {historyType, patientId} = params;

  const response = await axiosInstance.get<GetPatientQuestionnaireResponseData>(
    ApiRoute.PatientQuestionnaire,
    {
      params: {
        patient_id: patientId,
        type: historyType,
      },
    }
  );

  return response.data;
}

export async function PostPatientQuestionnaire(
  payload: PostPatientQuestionnairePayload
) {
  const response =
    await axiosInstance.post<PostPatientQuestionnaireResponseData>(
      ApiRoute.PatientQuestionnaire,
      payload
    );

  return response.data;
}
