import FrontdeskButton from '$components/FrontdeskButton';
import {BookingPreferredTime} from '$modules/booking/types';
import {Slot} from '$modules/frontdesk/type';
import {dateToTimeShort} from '$utils/date';
import './styles.scss';

interface TimeSlotPickerProps {
  isLoading: boolean;
  isError?: boolean;
  slots: Slot[];
  selectedSlot?: Slot;
  preferredTime?: string;
  consultantName?: string;
  setSelectedSlot: (slot: Slot) => void;
}

function TimeSlotPicker(props: TimeSlotPickerProps) {
  const {
    isLoading,
    isError = false,
    slots,
    selectedSlot,
    preferredTime = BookingPreferredTime.All,
    consultantName,
    setSelectedSlot,
  } = props;

  const slotButtons = slots.map(slot => {
    const {startTime} = slot;

    const timeString = dateToTimeShort(startTime);

    const isActive =
      slot.startTime.toISOString() === selectedSlot?.startTime.toISOString() &&
      slot.endTime.toISOString() === selectedSlot.endTime.toISOString();

    return (
      <FrontdeskButton
        key={timeString}
        variant="secondary"
        title={timeString}
        active={isActive}
        onClick={() => setSelectedSlot(slot)}
      />
    );
  });

  if (isLoading) {
    return (
      <div className="animate-pulse font-normal text-base flex gap-4 mb-10">
        <div className="h-9 w-16 bg-slate-200 rounded-lg" />
        <div className="h-9 w-16 bg-slate-200 rounded-lg" />
        <div className="h-9 w-16 bg-slate-200 rounded-lg" />
        <div className="h-9 w-16 bg-slate-200 rounded-lg" />
      </div>
    );
  }

  if (isError) {
    return (
      <p className="font-normal text-base flex gap-4 mb-10">
        <span className="text-red-500">Failed to load available slots!</span>
      </p>
    );
  }

  if (slots.length === 0) {
    return (
      <p className="font-normal text-base flex gap-4 mb-10">
        <span>
          {preferredTime} slots {consultantName ? 'for ' + consultantName : ''}{' '}
          are fully booked
        </span>
      </p>
    );
  }

  return (
    <div className="flex gap-4 mb-10 flex-wrap time-slot-picker-container">
      {slotButtons}
    </div>
  );
}

export default TimeSlotPicker;
