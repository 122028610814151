import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import PatientDetailNotesComponent from './component';
import {useDeleteNotes, useNotes} from './hooks';
import useAuthUser from '../../../../../hooks/useAuthUser';
import {UserRoleName} from '$modules/admin/constant';

interface PatientDetailNotesProps {
  overviewSelectedNoteId: number | null;
}

function PatientDetailNotes(props: PatientDetailNotesProps) {
  const {overviewSelectedNoteId} = props;
  const user = useAuthUser();
  const isConsultant = user?.roleName === UserRoleName.Doctor;

  const params = useParams();
  const patientId = Number(params.patientId);

  const [isModify, setIsModify] = useState(Boolean(overviewSelectedNoteId));
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedNoteId, setSelectedNoteId] = useState<number | null>(
    overviewSelectedNoteId
  );

  const {notes, isLoading} = useNotes(patientId);

  const selectedNote = notes.find(n => n.id === selectedNoteId) || null;

  const {deleteNoteRequest, disableSubmit, isDeletingNote} = useDeleteNotes(
    () => setShowDeleteModal(false)
  );

  useEffect(() => {
    if (notes.length && !isConsultant) {
      setSelectedNoteId(notes[0].id);
    }
  }, [notes, isConsultant]);

  return (
    <PatientDetailNotesComponent
      notes={notes}
      isModify={isModify}
      setIsModify={setIsModify}
      isNotesLoading={isLoading}
      selectedNote={selectedNote}
      setSelectedNoteId={setSelectedNoteId}
      setShowDeleteModal={setShowDeleteModal}
      showDeleteModal={showDeleteModal}
      deleteNoteRequest={deleteNoteRequest}
      disableSubmit={disableSubmit}
      isDeletingNote={isDeletingNote}
    />
  );
}

export default PatientDetailNotes;
