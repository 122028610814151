import {PinIcon, PlusSquareIcon} from '$assets/svgs';
import FrontdeskButton from '$components/FrontdeskButton';
import {PatientDetailTab} from '../../constant';
import {Note} from '../../Notes/types';
import NotesOverViewCard from './NotesOverViewCard';
import '../styles.scss';
interface NotesOverviewComponentProps {
  isFrontDesk: boolean;
  setActiveTab: React.Dispatch<React.SetStateAction<PatientDetailTab>>;
  setOverviewSelectedNoteId: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  notes: Note[];
}
const NotesOverviewComponent: React.FC<NotesOverviewComponentProps> = ({
  isFrontDesk,
  setActiveTab,
  setOverviewSelectedNoteId,
  notes,
}) => {
  const handleEditNote = (noteId: number) => {
    setActiveTab(PatientDetailTab.Notes);
    setOverviewSelectedNoteId(noteId);
  };

  const displayNotes = () => {
    return notes.map(
      ({id, title, description, createdBy, createdAt, documentUrl}) => (
        <NotesOverViewCard
          key={id}
          id={id}
          title={title}
          description={description}
          createdBy={createdBy}
          createdAt={createdAt}
          documentUrl={documentUrl}
          handleEditNote={handleEditNote}
        />
      )
    );
  };

  return (
    <div className="notes row-span-2 row-start-4">
      <div className="notes-title-container">
        <div className="title">
          <PinIcon />
          <span className="title-text">Notes</span>
        </div>
        {!isFrontDesk && (
          <FrontdeskButton
            startIcon={<PlusSquareIcon />}
            title="New Note"
            onClick={() => setActiveTab(PatientDetailTab.Notes)}
          />
        )}
      </div>
      <div className="notes-card-container">
        {notes?.length > 0 ? (
          displayNotes()
        ) : (
          <div className="text-center py-4 font-bold">No data found</div>
        )}
      </div>
    </div>
  );
};

export default NotesOverviewComponent;
