import {useModifyAppointmentDetails} from '~/services/api/appointments/hooks';
import {useFormik} from 'formik';
import {EditBookingFormData, editBookingFormSchema} from './schema';
import {AppointmentDetails} from '~/services/api/appointments/types';
import ChipComponent from '~/components/ChipComponent';
import {getChipStatusColors} from '../../utils';
import {formatFullName} from '~/utils/helper';
import {ArrowLeftIcon, CheckIcon} from '~/assets/svgs';
import {Button, Tooltip} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {getRoute, RouteSection} from '~/utils/route';
import {RoutePath} from '~/constants/routes';
import FrontdeskButton from '~/components/FrontdeskButton';
import PatientDetailsIndex from './PatientDetails';
import AppointmentDetailsIndex from './AppointmentDetails';
import BookingToConfirmIcon from '$assets/svgs/BookingToConfirm.svg?react';
import './styles.scss';

interface EditBookingComponentProps {
  appointmentDetails: AppointmentDetails;
}

const EditBookingComponent = (props: EditBookingComponentProps) => {
  const {appointmentDetails} = props;
  const appointmentId = appointmentDetails.id.toString();

  const {
    sub_care_type,
    appointment_type,
    care_type,
    date,
    doctor,
    preferred_time,
    referred_by_physician,
    reason,
    self_booking,
    self_paying,
    payment_mode,
    insurance,
    membership_no,
    preferred_point_of_contact,
    country_code,
    contact_mobile_number,
    contact_email,
    patient,
    care_taker,
  } = appointmentDetails;

  const {
    id: patientId,
    prefix,
    first_name,
    last_name,
    dob,
    gender,
    country_code: patientCountryCode,
    mobile_number,
    email,
    address,
  } = patient;

  const {id: addressId, line1, line2, city, country, postcode} = address;

  const {
    disableSubmit,
    isModifyingAppointmentDetails,
    modifyAppointmentDetails,
  } = useModifyAppointmentDetails(appointmentId);

  const formik = useFormik<EditBookingFormData>({
    initialValues: {
      appointment: {
        sub_care_type_id: sub_care_type?.id,
        appointment_type_id: appointment_type.id,
        care_type_id: care_type.id,
        date: date || '',
        doctor_id: doctor.id,
        preferred_time: preferred_time || 'All time',
        referred_by_physician: referred_by_physician || false,
        reason: reason || '',
        self_booking: self_booking || false,
        self_paying: self_paying || false,
        payment_mode: payment_mode || '',
        insurance_id: insurance?.id,
        membership_no: membership_no || '',
        preferred_point_of_contact: preferred_point_of_contact || '',
        country_code: country_code || '',
        contact_mobile_number: Number(contact_mobile_number),
        contact_email: contact_email || '',
        patient: {
          id: patientId,
          prefix: prefix || '',
          first_name: first_name || '',
          last_name: last_name || '',
          dob: dob || '',
          gender: gender || '',
          country_code: patientCountryCode || '',
          mobile_number: Number(mobile_number),
          email: email || '',
          address: {
            id: addressId,
            line1: line1 || '',
            line2: line2 || '',
            city: city || '',
            country: country || '',
            postcode: postcode || '',
          },
        },
        care_taker: care_taker
          ? {
              id: care_taker.id,
              first_name: care_taker?.first_name || '',
              last_name: care_taker?.last_name || '',
              country_code: care_taker?.country_code || '',
              mobile_number: Number(care_taker.mobile_number),
              email: care_taker.email || '',
              relationship: care_taker.relationship,
            }
          : null,
      },
    },
    validationSchema: editBookingFormSchema,
    onSubmit: values => {
      const payload = {appointmentId, body: values};
      modifyAppointmentDetails(payload);
    },
  });

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(
      getRoute(
        RouteSection.Calendar,
        RoutePath.staff.calendar.bookingDetails,
        appointmentId
      )
    );
  };

  const {bgColor, textColor} = getChipStatusColors('pending');
  const patientName = `${first_name} ${last_name}` || 'Name loading...';

  const nameElement =
    patientName.length > 30 ? (
      <Tooltip title={patientName}>
        <span>{formatFullName(patientName)}</span>
      </Tooltip>
    ) : (
      <span>{patientName}</span>
    );

  return (
    <div className="flex flex-col flex-1 font-primary bg-primary">
      <header className="flex items-center justify-start mx-10 border-b-header">
        <Button
          startIcon={<ArrowLeftIcon />}
          className="!pt-6 !pb-6 !normal-case !font-normal !text-base !leading-6 !tracking-tight text-primary-dark"
          onClick={handleBackClick}
        >
          Back
        </Button>
      </header>
      <main className="flex flex-col justify-start flex-1 mt-4 ml-10 mr-10 mb-6 overflow-x-hidden overflow-y-auto">
        <h1 className="font-semibold text-2xl leading-7 tracking-tight text-primary-dark">
          Edit booking details
        </h1>
        <div className="flex gap-10 mt-6 justify-start">
          <div className="font-semibold text-2xl leading-7 tracking-tight text-primary">
            {nameElement}
          </div>
          <ChipComponent
            hasIcon
            className="chip-gradient"
            label="Booking to confirm"
            bgColor={bgColor}
            textColor={textColor}
            icon={<BookingToConfirmIcon />}
          />
        </div>
        <div className="mt-5 flex flex-col lg:flex-row flex-1 gap-6 overflow-x-hidden overflow-y-auto">
          <AppointmentDetailsIndex
            errors={formik.errors}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            setFieldTouched={formik.setFieldTouched}
            setFieldValue={formik.setFieldValue}
            touched={formik.touched}
            values={formik.values}
            appointmentId={appointmentId}
            careTypeId={care_type.id}
          />
          <PatientDetailsIndex
            errors={formik.errors}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            setFieldTouched={formik.setFieldTouched}
            setFieldValue={formik.setFieldValue}
            touched={formik.touched}
            values={formik.values}
          />
        </div>
      </main>
      <footer className="flex justify-end gap-3 ml-10 mr-10 mb-6">
        <FrontdeskButton
          title="Cancel"
          variant="secondary"
          onClick={handleBackClick}
        />
        <FrontdeskButton
          disabled={isModifyingAppointmentDetails || disableSubmit}
          loading={isModifyingAppointmentDetails || disableSubmit}
          startIcon={<CheckIcon />}
          title="Save changes"
          onClick={() => formik.handleSubmit()}
        />
      </footer>
    </div>
  );
};

export default EditBookingComponent;
