import {useState} from 'react';
import EyeOpLogo from '~/assets/svgs/eye-op.svg?react';
import HospitalLogo from '~/assets/svgs/site-text-logo.svg?react';
import HospitalMobileLogo from '~/assets/svgs/site-logo.svg?react';
import LeaveBookingModal from '~/modules/booking/components/LeaveBookingModal';
import {BookingDataUpdater, BookingView} from '~/modules/booking/types';
import {useAPIConstants} from '~/services/api/constants/hooks';
import {ConstantsAPIKey} from '~/services/api/constants/constants';

interface HeaderProps {
  setBookingData?: BookingDataUpdater;
  setActiveView?: React.Dispatch<React.SetStateAction<BookingView>>;
}

function Header(props: HeaderProps) {
  const {setActiveView, setBookingData} = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {data: hospitalInfo} = useAPIConstants({
    key: ConstantsAPIKey.Hospital,
  });

  const hospitalURL = hospitalInfo?.moreInfoUrl;

  const handleLeave = () => {
    setIsModalOpen(false);
    if (setBookingData && setActiveView) {
      setBookingData(() => ({}));
      setActiveView(BookingView.AppointmentSelectionView);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleHospitalLogoClick = () => {
    window.open(hospitalURL, '_blank');
  };

  return (
    <div className="flex justify-between items-center px-6 lg:px-20">
      <EyeOpLogo
        className="w-14 h-full aspect-square"
        onClick={handleOpen}
        data-testid="eyeop-logo"
      />
      <HospitalMobileLogo
        className="w-12 h-full aspect-square block lg:hidden"
        onClick={handleHospitalLogoClick}
        data-testid="hospital-mobile-logo"
      />
      <HospitalLogo
        className="w-44 h-full hidden lg:block"
        onClick={handleHospitalLogoClick}
        data-testid="hospital-logo"
      />

      {setBookingData && setActiveView && (
        <LeaveBookingModal
          open={isModalOpen}
          onClose={handleClose}
          onLeave={handleLeave}
        />
      )}
    </div>
  );
}

export default Header;
