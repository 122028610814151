import {AppointmentInfo} from '$modules/frontdesk/type';
import {dateToDDMMYYYY} from '$utils/date';

interface RecentUpdateItemProps {
  appointment: AppointmentInfo;
}

function RecentUpdateItem(props: RecentUpdateItemProps) {
  const {appointment} = props;
  const data = appointment.appointment;

  return (
    <div className="flex item-container">
      <div className="w-6 shrink-0 flex justify-center relative">
        <div className="item-dot h-3 w-3 rounded-full top-1 absolute" />
        <div className="item-bar w-0.5 h-full mt-2" />
      </div>
      <div className="flex-1">
        <h4 className="mb-3 title">{data.type}</h4>
        <div className="item-description-block rounded-lg p-3">
          <p className="flex">
            <span className="text-sm">Performed by: </span>
            <span className="text-sm font-bold ml-2">{data.doctorName}</span>
          </p>
          <p className="text-xs font-normal tertiary">
            {dateToDDMMYYYY(data.date)}
          </p>
        </div>
      </div>
    </div>
  );
}

export default RecentUpdateItem;
