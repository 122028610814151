export function getSubDomain() {
  return window.location.hostname.split('.')[0];
}

export function loadStylesheet(fileURL: string) {
  const head = document.head;
  const linkElement = document.createElement('link');

  linkElement.type = 'text/css';
  linkElement.rel = 'stylesheet';
  linkElement.href = fileURL;

  head.appendChild(linkElement);
}
