import {type SxProps} from '@mui/material';
import theme from '$modules/booking/theme';
import {CSSProperties} from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const styles: Record<string, SxProps> = {
  container: {
    background: theme.palette.common.white,
    borderRadius: '0.5rem',

    '& .MuiSelect-select': {
      padding: '0.75rem 1.5rem',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.5rem',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.grey[200],
    },

    '& .MuiOutlinedInput-root': {
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.grey[200],
          borderWidth: '1px',
        },
      },

      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey[200],
        borderWidth: '1px',
      },

      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
        borderWidth: '1px',
      },
    },
  },
};

export const menuPaperStyles: CSSProperties = {
  width: '0%',
  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  marginTop: '12px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.grey[300],
  borderRadius: '0.5rem',
  boxShadow: theme.shadows[1],
};

export default styles;
