import {SetStateAction, useCallback} from 'react';
import {useFormik} from 'formik';
import {PatientDetailOneFormData, patientDetailOneFormSchema} from './schema';
import {ArrowNarrowRightIcon} from '$assets/svgs';
import FrontdeskButton from '$components/FrontdeskButton';
import PatientDetailOneComponent from './component';
import {Button, MenuItem, Typography} from '@mui/material';
import {Color} from '$constants/style';
import {FormState} from '../../types';
import {useValidateUnqEmail} from '$modules/booking/validateEmailHook';
import useValidateNHSNumber from '../../../../../../hooks/useValidateNHSNumber';
import {OfflineBookingStep, INITIAL_FORM_STATE} from '../../constant';
import './styles.scss';

interface PatientDetailsOneContainerProps {
  offlineBookingFormData: FormState;
  setActiveStep: React.Dispatch<SetStateAction<OfflineBookingStep>>;
  setOfflineBookingFormData: React.Dispatch<React.SetStateAction<FormState>>;
}

const renderItem = (item: string) => (
  <MenuItem key={item} value={item}>
    {item}
  </MenuItem>
);

const renderValue = (value: string, placeholder: string) => (
  <Typography color={value ? undefined : Color.TextPrimayPlaceholder}>
    {value || placeholder}
  </Typography>
);

const PatientDetailsOneContainer: React.FC<PatientDetailsOneContainerProps> = ({
  offlineBookingFormData,
  setActiveStep,
  setOfflineBookingFormData,
}) => {
  const {disableSubmit, isPending, verifyEmail} = useValidateUnqEmail(() =>
    setActiveStep(OfflineBookingStep.PatientDetailsTwo)
  );

  const handleNHSValidationSuccess = () => {
    handleSubmit();
  };

  const {validateNHSNumber, isLoading} = useValidateNHSNumber(
    handleNHSValidationSuccess
  );

  const patientData = offlineBookingFormData?.appointment?.patient;

  const handleFormikSubmit = () => {
    const {nhsNumber} = formik.values.patient;

    if (nhsNumber && !patientData.nhs_number) {
      validateNHSNumber(nhsNumber);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const {title, firstName, lastName, email, gender, dob, nhsNumber} =
      formik.values.patient;

    setOfflineBookingFormData((prevState: FormState) => ({
      appointment: {
        ...prevState.appointment,
        patient: {
          ...prevState.appointment.patient,
          id: null,
          nhs_number: nhsNumber,
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          email: email.trim(),
          gender: gender,
          dob: dob,
          prefix: title,
        },
      },
    }));

    verifyEmail({
      patient: {
        email: email.trim(),
      },
    });
  };

  const formik = useFormik<PatientDetailOneFormData>({
    initialValues: {
      patient: {
        nhsNumber: patientData?.nhs_number || NaN,
        title: patientData?.prefix || '',
        firstName: patientData?.first_name || '',
        lastName: patientData?.last_name || '',
        email: patientData?.email || '',
        gender: patientData?.gender || '',
        dob: patientData?.dob || null,
      },
    },
    validationSchema: patientDetailOneFormSchema,
    onSubmit: handleFormikSubmit,
  });
  const {setFieldTouched, setFieldValue} = formik;

  const handleFieldChange = useCallback(
    (field: string, defaultValue: unknown) => {
      const key = `patient.${field}`;
      return (value: unknown) => {
        setFieldTouched(key);
        setFieldValue(key, value || defaultValue);
      };
    },
    [setFieldTouched, setFieldValue]
  );

  const handleBackButtonClick = () => {
    setOfflineBookingFormData(INITIAL_FORM_STATE);
    setActiveStep(OfflineBookingStep.SelectOrBookPatient);
  };

  const handleContinueClick = () => {
    formik.submitForm();
  };

  const disableContinue = Boolean(disableSubmit || isLoading);

  return (
    <>
      <PatientDetailOneComponent
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        renderTitleValue={value => renderValue(value, 'Title')}
        renderTitleItem={renderItem}
        renderGenderValue={value => renderValue(value, 'Gender')}
        renderGenderItem={renderItem}
        handleFieldChange={handleFieldChange}
      />
      <div className="dialog-footer">
        <Button className="back-btn" onClick={handleBackButtonClick}>
          Back
        </Button>
        <FrontdeskButton
          title="Continue"
          className="continue-btn"
          disabled={disableContinue}
          endIcon={<ArrowNarrowRightIcon />}
          onClick={handleContinueClick}
          loading={isPending}
        />
      </div>
    </>
  );
};

export default PatientDetailsOneContainer;
