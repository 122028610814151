import CalendarIcon from '$assets/svgs/calendar.svg?react';
import {AppointmentInfo} from '$modules/frontdesk/type';
import {dateToDDMMYYYY} from '$utils/date';

interface UpcomingEventsComponenProps {
  upcomingEvents: AppointmentInfo[];
}

function UpcomingEventsComponent(props: UpcomingEventsComponenProps) {
  const {upcomingEvents} = props;

  const items = upcomingEvents.map(event => (
    <div key={event.appointment.id} className="item py-2">
      <h5 className="text-sm font-bold mb-1">{event.appointment.type}</h5>
      <p className="text-xs">{dateToDDMMYYYY(event.appointment.date)}</p>
    </div>
  ));

  return (
    <div className="upcoming-event-card bg-white rounded-md p-4 w-64 h-full max-h-72 mt-12 overflow-auto flex flex-col">
      <div className="header flex gap-2 items-center">
        <CalendarIcon />
        <h4 className="text-base">Upcoming events</h4>
      </div>

      <div className="flex-1 overflow-auto">{items}</div>
    </div>
  );
}

export default UpcomingEventsComponent;
