import React from 'react';
import {useFormik} from 'formik';
import {Box, Button, Dialog, MenuItem} from '@mui/material';
import {XCloseIcon} from '$assets/svgs';
import IconButton from '$components/IconButton';
import FrontdeskButton from '$components/FrontdeskButton';
import SelectInput from '$components/SelectInput';
import Input from '$components/Input';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';
import {Consultant} from '$modules/booking/types';
import {consultantSelectionViewStyles} from '$modules/booking/styles';
import {getNameInitials} from '$modules/booking/utils';
import {usePutPatientClinicalDetails} from '../hooks';
import './styles.scss';
import {useConsultants} from '$modules/frontdesk/hooks';

interface ClinicalDetailsDialogProps {
  patientId: number;
  open: boolean;
  handleClose: () => void;
}
const ClinicalDetailsDialog: React.FC<ClinicalDetailsDialogProps> = ({
  patientId,
  open,
  handleClose,
}) => {
  const {isLoading, consultants: consultantList, isError} = useConsultants({});
  const {sendUpdatePatientClinicalDetailsRequest} =
    usePutPatientClinicalDetails(handleClose);

  const formik = useFormik({
    initialValues: {
      diagonsis: '',
      doctorId: '',
    },
    onSubmit: () => {
      sendUpdatePatientClinicalDetailsRequest({
        patientId,
        diagnosis: formik.values.diagonsis,
        doctor_id: +formik.values.doctorId,
      });
    },
  });
  const {handleBlur, handleChange, setFieldValue} = formik;
  const handleConsultantChange = (value: string) => {
    setFieldValue('doctorId', Number(value));
  };

  const renderConsultantMenuItem = (item: Consultant) => {
    return (
      <MenuItem
        key={item.id}
        value={item.id.toString()}
        sx={consultantSelectionViewStyles.menuItemContainer}
      >
        {item.profileUrl ? (
          <img
            src={item.profileUrl}
            style={consultantSelectionViewStyles.menuItemProfileImage}
          />
        ) : (
          <span style={consultantSelectionViewStyles.menuItemInitial}>
            {getNameInitials(item.fullName)}
          </span>
        )}
        <span>{item.fullName}</span>
      </MenuItem>
    );
  };

  const renderValue = (value: string) => {
    if (!value) return 'Select a consultant';
    const item = consultantList.find(it => it.id.toString() === value);
    if (!item) {
      handleConsultantChange('');
      return '';
    }

    return (
      <Box sx={consultantSelectionViewStyles.menuItemContainer}>
        {item.profileUrl ? (
          <img
            src={item.profileUrl}
            style={consultantSelectionViewStyles.menuItemProfileImage}
          />
        ) : (
          <span style={consultantSelectionViewStyles.menuItemInitial}>
            {getNameInitials(item.fullName)}
          </span>
        )}
        <span>{item.fullName}</span>
      </Box>
    );
  };

  if (isLoading) return <Loading />;
  if (isError) return <FetchError />;

  return (
    <>
      <Dialog open={open} className="clinical-details-dialog-container">
        <div className="dialog-header">
          <div className="heading">Clinical details</div>
          <IconButton onClick={handleClose}>
            <XCloseIcon />
          </IconButton>
        </div>
        <div className="dialog-body">
          <div className="dialog-field-container">
            <div className="dialog-label">Diagonsis</div>
            <Input
              name="diagonsis"
              placeholder="Write down the patient's diagnosis"
              value={formik.values.diagonsis}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <div className="dialog-field-container">
            <div className="dialog-label">Consultant</div>
            <SelectInput
              items={consultantList}
              renderValue={renderValue}
              renderItem={renderConsultantMenuItem}
              onChange={handleConsultantChange}
              value={
                formik.values.doctorId ? formik.values.doctorId.toString() : ''
              }
            />
          </div>
        </div>
        <div className="dialog-footer">
          <Button onClick={handleClose} className="cancel-btn">
            Cancel
          </Button>
          <FrontdeskButton
            title="Save"
            disabled={!formik.values.diagonsis || !formik.values.doctorId}
            onClick={() => {
              formik.submitForm();
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default ClinicalDetailsDialog;
