import {FileIcon} from '$assets/svgs';
import {DOCUMENTS_SKELETON_ITEM_COUNT} from '$modules/frontdesk/constant';

const DocumentSkeleton = () => {
  const skeletonItems = Array.from({length: DOCUMENTS_SKELETON_ITEM_COUNT}).map(
    (_, index) => (
      <div
        key={index}
        className="flex justify-between items-center p-4 bg-white border border-gray-200 rounded-lg animate-pulse"
      >
        <div className="flex gap-4 items-center">
          <div className="w-12 h-12 bg-gray-200 rounded-full"></div>
          <div className="flex flex-col gap-3">
            <div className="h-5 w-48 bg-gray-200 rounded"></div>
            <div className="h-5 w-28 bg-gray-200 rounded"></div>
          </div>
        </div>
        <div className="h-10 w-10 bg-gray-200 rounded-full"></div>
      </div>
    )
  );

  return (
    <div className="clinical-data__documents col-span-2 row-span-2">
      <div className="header">
        <div className="card-title-container">
          <FileIcon />
          <span className="card-title">Documents</span>
        </div>
      </div>

      <div className="flex-1 overflow-auto space-y-4">{skeletonItems}</div>
    </div>
  );
};

export default DocumentSkeleton;
