import React from 'react';
import {FormikErrors, FormikTouched} from 'formik';
import {Box, RadioGroup, Typography} from '@mui/material';
import {PatientContactDetailsFormData} from './schema';
import Input from '$components/Input';
import PhoneNumberInput from '../PhoneNumberInput';
import theme from '$modules/booking/theme';
import RadioBox from '$components/RadioBox';
import {PatientContactDetailViewChoices} from '../../types';
import {patientContactDetailViewStyles} from '../../styles';
import {usePreferredPointOfContact} from '$modules/booking/hooks';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';

interface PatientContactDetailFormProps {
  bookingChoices: PatientContactDetailViewChoices;
  values: PatientContactDetailsFormData;
  errors: FormikErrors<PatientContactDetailsFormData>;
  touched: FormikTouched<PatientContactDetailsFormData>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  setFieldTouched: (field: string) => void;
  setFieldValue: (field: string, value: unknown) => void;
}

function PatientContactDetailForm(props: PatientContactDetailFormProps) {
  const {bookingChoices, values, errors, touched, handleBlur, handleChange} =
    props;

  const patient = values.patient;
  const touchedPatient = touched.patient;
  const errorsPatient = errors.patient;

  const {preferredPointOfContact, isLoading, isError} =
    usePreferredPointOfContact();

  const preferredPointItems = preferredPointOfContact.map(item => (
    <RadioBox
      key={item.id}
      value={item.name}
      label={item.name}
      error={
        touchedPatient?.preferredContactMethod &&
        Boolean(errorsPatient?.preferredContactMethod)
      }
    />
  ));

  const countryCode = bookingChoices?.patientCarerDetails?.patient?.country;

  if (isLoading) return <Loading />;
  if (isError) return <FetchError />;

  return (
    <Box flexDirection="column" display="flex" gap={2}>
      <Typography variant="h3">Contact details</Typography>
      <Box display="flex" flexDirection="column" gap={5}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box sx={patientContactDetailViewStyles.inputContainer}>
            <Input
              name="patient.email"
              placeholder="Email address"
              value={patient?.email || ''}
              onBlur={handleBlur}
              onChange={handleChange}
              touched={touchedPatient?.email}
              error={touchedPatient?.email && Boolean(errorsPatient?.email)}
              helperText={(touchedPatient?.email && errorsPatient?.email) || ''}
            />
          </Box>
          {!bookingChoices.registered.email && (
            <Box sx={patientContactDetailViewStyles.inputContainer}>
              <Input
                name="patient.confirmEmail"
                placeholder="Confirm email address"
                value={patient?.confirmEmail || ''}
                onBlur={handleBlur}
                onChange={handleChange}
                touched={touchedPatient?.confirmEmail}
                error={
                  touchedPatient?.confirmEmail &&
                  Boolean(errorsPatient?.confirmEmail)
                }
                helperText={
                  (touchedPatient?.confirmEmail &&
                    errorsPatient?.confirmEmail) ||
                  ''
                }
              />
            </Box>
          )}
          <Box display="flex" flexDirection="column" gap={1}>
            <Box sx={patientContactDetailViewStyles.inputContainer}>
              <PhoneNumberInput
                name="patient.phoneNumber"
                value={values.patient.phoneNumber}
                country={countryCode}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {touchedPatient?.phoneNumber && errorsPatient?.phoneNumber && (
                <Typography
                  variant="caption"
                  color={theme.palette.error.main}
                  fontWeight={400}
                >
                  {errorsPatient.phoneNumber}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="h3">Preferred means of contact:</Typography>
          <RadioGroup
            row
            name="patient.preferredContactMethod"
            value={patient?.preferredContactMethod || NaN}
            onChange={handleChange}
            sx={{paddingLeft: theme.spacing(0.5)}}
          >
            {preferredPointItems}
          </RadioGroup>
          {touchedPatient?.preferredContactMethod &&
            errorsPatient?.preferredContactMethod && (
              <Typography
                variant="caption"
                color={theme.palette.error.main}
                fontWeight={400}
              >
                {errorsPatient.preferredContactMethod}
              </Typography>
            )}
        </Box>
      </Box>
    </Box>
  );
}

export default PatientContactDetailForm;
