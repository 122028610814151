import {eoCard} from '$modules/frontdesk/styles';
import theme from '$modules/frontdesk/theme';

export const eoCheckInTimerContainer = {
  ...eoCard,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.common.white,
  padding: theme.spacing(1),
  borderRadius: '0.375rem',
  width: '208px',
};

export const eoCheckInTime = {
  padding: theme.spacing(0, 1),
  borderRadius: '0.375rem',
  border: `1px solid ${theme.palette.grey[300]}`,
};

export const eoCheckInTimeContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
  gap: '0.25rem',
  padding: '0.25rem',
};

export const eoStopTimer = {
  display: 'flex',
  gap: '0.5rem',
  textTransform: 'none !important',
};
