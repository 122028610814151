import {Avatar, IconButton} from '@mui/material';
import UserCircleIcon from '$assets/svgs/user-circle.svg?react';
import LogoutIcon from '$assets/svgs/arrow-square-right.svg?react';
import useAuthUser from '../../hooks/useAuthUser';
import LogoutDialog from './LogoutDialog';

interface FooterProps {
  sidebarOpen: boolean;
  logoutModalOpen: boolean;
  toggleLogoutModal: () => void;
  onLogout: () => void;
}

function Footer(props: FooterProps) {
  const {sidebarOpen, logoutModalOpen, toggleLogoutModal, onLogout} = props;
  const {profileUrl, fullName, roleName} = useAuthUser()!;

  const avatar = profileUrl ? (
    <Avatar src={profileUrl} alt="Profile Picture" className="h-10 w-10" />
  ) : (
    <UserCircleIcon className="h-10 w-10 primary" />
  );

  return (
    <div className="p-4 mb-6 flex justify-between">
      {sidebarOpen && (
        <span className="flex items-center gap-1.5">
          {avatar}
          <div className="flex flex-col">
            <span className="text-sm font-semibold">{fullName}</span>
            <span className="text-sm font-normal">{roleName}</span>
          </div>
        </span>
      )}
      <IconButton onClick={toggleLogoutModal}>
        <LogoutIcon />
      </IconButton>
      <LogoutDialog
        open={logoutModalOpen}
        handleClose={toggleLogoutModal}
        onLogout={onLogout}
      />
    </div>
  );
}

export default Footer;
