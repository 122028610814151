import {ApiRoute, ConstantApiParamKey} from '$constants/api';
import {appendObjectToFormData} from '$modules/booking/components/LastStepForm/utils';
import store from '$redux/store';
import axiosInstance from '.';
import {
  Appointment,
  Care,
  Relationship,
  Insurance,
  Payment,
  BookingData,
  PaymentCard,
  City,
  Subcare,
} from '../modules/booking/types';
import {bookingChoicesToAppointmentRequestBody} from '../modules/booking/utils';

interface GetBookingTypesResponseData {
  message: string;
  data: {
    id: number;
    title: string;
    description: string;
    image: string;
    is_enable: boolean;
    display_name?: string;
    isActive: boolean;
    isEnable: boolean;
  }[];
}

export async function getBookingTypes(): Promise<{
  message: string;
  data: Appointment[];
}> {
  const response = await axiosInstance.get<GetBookingTypesResponseData>(
    ApiRoute.Constant,
    {params: {key: ConstantApiParamKey.Booking}}
  );

  return {
    message: response.data.message,
    data: response.data.data.map(info => ({
      ...info,
      enable: info.is_enable,
    })),
  };
}

interface GetAppointmentTypesResponseData {
  message: string;
  data: {
    id: number;
    title: string;
    description: string;
    image: string;
    is_enable: boolean;
    isActive: boolean;
    isEnable: boolean;
  }[];
}

export async function getAppointmentTypes(
  bookingId: number
): Promise<{message: string; data: Care[]}> {
  const response = await axiosInstance.get<GetAppointmentTypesResponseData>(
    ApiRoute.Constant,
    {params: {key: ConstantApiParamKey.Appointment, appointment_id: bookingId}}
  );

  return {
    message: response.data.message,
    data: response.data.data.map(info => ({
      ...info,
      enable: info.is_enable,
    })),
  };
}

interface GetCareTypesResponseData {
  message: string;
  data: {
    id: number;
    title: string;
    description: string;
    is_enable: boolean;
    isActive: boolean;
    isEnable: boolean;
  }[];
}

export async function getCareTypes(
  appointmentId?: number
): Promise<{message: string; data: Subcare[]}> {
  const response = await axiosInstance.get<GetCareTypesResponseData>(
    ApiRoute.Constant,
    {
      params: {
        key: ConstantApiParamKey.Care,
        care_id: appointmentId,
      },
    }
  );

  return {
    message: response.data.message,
    data: response.data.data.map(info => ({
      ...info,
      enable: info.is_enable,
    })),
  };
}

export interface GetConsultantResponseData {
  message: string;
  data: {
    id: number;
    profile_url: string | null;
    full_name: string;
    doctor_details: {
      bio_link: string;
      consultation_fee: number;
      followup_fee: number;
      specializations: {
        id: number;
        title: string;
      }[];
      description: string;
    };
  }[];
}

export interface GetConsultantParams {
  subCareId?: number;
  appointmentTypeId?: number;
}

export async function getConsultants(
  params: GetConsultantParams
): Promise<GetConsultantResponseData> {
  const {subCareId, appointmentTypeId} = params;

  const response = await axiosInstance.get<GetConsultantResponseData>(
    ApiRoute.Constant,
    {
      params: {
        key: ConstantApiParamKey.Consultant,
        sub_care_type_id: subCareId,
        appointment_id: appointmentTypeId,
      },
    }
  );

  return response.data;
}

interface GetRelationshipResponseBody {
  message: string;
  data: {
    id: number;
    name: string;
  }[];
}

export async function getRelationships(): Promise<{
  message: string;
  data: Relationship[];
}> {
  const response = await axiosInstance.get<GetRelationshipResponseBody>(
    ApiRoute.Constant,
    {params: {key: ConstantApiParamKey.Relationship}}
  );

  return {
    message: response.data.message,
    data: response.data.data,
  };
}

export interface GetCitiesResponseData {
  message: string;
  data: City[];
}

export interface GetCitiesParams {
  countryId?: string;
}

export async function getCities(
  params: GetCitiesParams
): Promise<GetCitiesResponseData> {
  const {countryId} = params;

  const response = await axiosInstance.get<GetCitiesResponseData>(
    ApiRoute.Constant,
    {
      params: {key: ConstantApiParamKey.City, country: countryId},
    }
  );

  return {
    message: response.data.message,
    data: response.data.data,
  };
}

export interface PostLoginResponseData {
  data: {
    refresh_token: string;
    token: string;
    patient: {
      id: number;
      nhs_number: number;
      full_name: string;
      first_name: string;
      last_name: string;
      dob: string;
      uniq_code: string;
      prefix: string;
      country_code: string;
      mobile_number: string;
      email: string;
      gender: string;
      marital_status: string;
      status: string;
      preferred_point_of_contact: string;
      address: {
        id: number;
        line1: string;
        line2: string;
        city: string;
        state: string;
        country: string;
        postcode: string;
        addressable_type: string;
        addressable_id: number;
      };
      last_appointment_details: {
        contact_mobile_number: string;
      };
    };
  };
  message: string;
}

export interface PostLoginErrorResponseData {
  message: string;
}

export interface PostLoginVariables {
  email: string;
  password: string;
}

export async function postBookingSignIn(variables: PostLoginVariables) {
  const {email, password} = variables;

  const response = await axiosInstance.post<PostLoginResponseData>(
    ApiRoute.BookingSignIn,
    {user: {email, password}}
  );
  return response.data;
}

export interface PostPatientEmailVerificationResponseData {
  message: string;
}

export interface PostPatientEmailPayload {
  patient: {
    email: string;
  };
}

export interface ValidatePostcodeResponse {
  success: boolean;
  message: string;
}

export interface ValidatePostcodeVariables {
  postcode: string;
  country: string;
}

export async function postValidatePostcode(
  payload: ValidatePostcodeVariables
): Promise<ValidatePostcodeResponse> {
  const response = await axiosInstance.post<ValidatePostcodeResponse>(
    `${ApiRoute.ValidatePostcode}?postcode=${payload.postcode}&country=${payload.country}`,
    payload
  );
  return response.data;
}

export interface PostPatientEmailErrorResponseData {
  message: string;
}

export async function postVerifyPatientContactEmail(
  payload: PostPatientEmailPayload
): Promise<PostPatientEmailVerificationResponseData> {
  const response =
    await axiosInstance.post<PostPatientEmailVerificationResponseData>(
      ApiRoute.PatientContactEmailVerification,
      payload
    );
  return response.data;
}

export interface GetInsuranceTypesResponseData {
  message: string;
  data: {
    id: number;
    name: string;
  }[];
}

export async function getInsuranceTypes(): Promise<{
  message: string;
  data: Insurance[];
}> {
  const response = await axiosInstance.get<GetInsuranceTypesResponseData>(
    ApiRoute.Constant,
    {params: {key: ConstantApiParamKey.Insurance}}
  );

  return {
    message: response.data.message,
    data: response.data.data,
  };
}

export interface GetPaymentTypesResponseData {
  message: string;
  data: {
    id: number;
    name: string;
  }[];
}

export async function getPaymentTypes(): Promise<{
  message: string;
  data: Payment[];
}> {
  const response = await axiosInstance.get<GetPaymentTypesResponseData>(
    ApiRoute.Constant,
    {params: {key: ConstantApiParamKey.Payment}}
  );

  return {
    message: response.data.message,
    data: response.data.data,
  };
}

interface DeleteAppointmentResponseData {
  message: string;
}

interface DeleteAppointmentVariables {
  id: number;
}

export async function deleteAppointment(
  variables: DeleteAppointmentVariables
): Promise<DeleteAppointmentResponseData> {
  const {id} = variables;

  const response = await axiosInstance.delete<DeleteAppointmentResponseData>(
    `${ApiRoute.Appointment}/${id}`
  );

  return response.data;
}

export interface AppointmentRequestData {
  appointment: {
    id?: number;
    sub_care_type_id?: number;
    appointment_type_id?: number;
    care_type_id?: number;
    date?: string;
    doctor_id?: number;
    preferred_time?: string;
    start_time?: string;
    end_time?: string;
    referred_by_physician?: boolean;
    referral?: File | null;
    reason?: string;
    self_booking?: boolean;
    self_paying?: boolean;
    payment_mode?: number;
    insurance_id?: number;
    membership_no?: string;
    hospital_id?: number | null;
    preferred_point_of_contact?: string;
    country_code?: string;
    contact_mobile_number?: string;
    contact_email?: string;

    patient: {
      id?: number;
      nhs_number?: number;
      prefix?: string;
      first_name?: string;
      last_name?: string;
      dob?: string;
      gender?: string;
      country_code?: '+44';
      mobile_number?: string | null;
      email?: string;
      password?: string;

      address: {
        id?: number;
        line1?: string;
        line2?: string | null;
        city?: string;
        country?: string;
        postcode?: string;
      };
    };

    care_taker?: {
      first_name?: string;
      last_name?: string;
      country_code?: '+44';
      mobile_number?: number;
      email?: string;
      password?: string;
      relationship?: string;
    } | null;
  };
}

export interface PostCreateAppointmentErrorResponse {
  success: string;
  errors: string[];
  message: string;
}
export interface PostCreateAppointmentResponseData {
  message: string;
  data: {
    appointment: {
      id: number;
      patient: {
        id: number;
        address: {
          id: number;
        };
      };
      doctor_name: string;
    };
  };
}

export interface PostCreateAppointmentVariables {
  choices: BookingData;
}

export async function postCreateAppointment(
  variables: PostCreateAppointmentVariables
) {
  const {choices} = variables;

  const body = bookingChoicesToAppointmentRequestBody(choices);

  const formData = new FormData();
  appendObjectToFormData(formData, body);

  const response = await axiosInstance.post<PostCreateAppointmentResponseData>(
    ApiRoute.Appointment,
    formData
  );
  return response.data;
}

export interface PostAppointmentResponseData {
  message: string;
}

export interface PostAppointmentVariables {
  id: number;
  choices: BookingData;
}

export async function postAppointment(variables: PostAppointmentVariables) {
  const {choices} = variables;

  const body = bookingChoicesToAppointmentRequestBody(choices);

  const response = await axiosInstance.post<PostAppointmentResponseData>(
    ApiRoute.Appointment,
    body
  );

  return response.data;
}

interface SendOtpRequestData {
  email: string;
}

interface SendOtpResponseData {
  success: boolean;
  data: [];
  message: string;
}

export async function sendOtp(email: string): Promise<SendOtpResponseData> {
  const requestData: SendOtpRequestData = {email};

  const response = await axiosInstance.post<SendOtpResponseData>(
    ApiRoute.BookingSendOtp,
    requestData
  );

  return response.data;
}
export interface PutForgotPasswordResponseData {
  message: string;
}

export interface PutForgotPasswordVariables {
  email: string;
}

export async function putForgotPassword(variables: PutForgotPasswordVariables) {
  const {email} = variables;

  const response = await axiosInstance.put<PutForgotPasswordResponseData>(
    ApiRoute.PatientForgotPassword,
    {patient: {email}}
  );

  return response.data;
}

interface ReSendOtpRequestData {
  email: string;
}

interface ReSendOtpResponseData {
  success: boolean;
  data: [];
  message: string;
}

export async function ResendOtp(email: string): Promise<ReSendOtpResponseData> {
  const requestData: ReSendOtpRequestData = {email};

  const response = await axiosInstance.post<ReSendOtpResponseData>(
    ApiRoute.BookingReSendOtp,
    requestData
  );

  return response.data;
}

interface ValidateOtpRequestData {
  email: string;
  otp: number;
}

export interface PostValidateOtpResponseData {
  success: boolean;
  data: {
    refresh_token: string;
    token: string;
    patient: {
      id: number;
      nhs_number: number;
      full_name: string;
      first_name: string;
      last_name: string;
      dob: string;
      uniq_code: string;
      prefix: string;
      country_code: string;
      mobile_number: string;
      email: string;
      gender: string;
      marital_status: string;
      status: string;
      preferred_point_of_contact: string;
      address: {
        id: number;
        line1: string;
        line2: string;
        city: string;
        state: string;
        country: string;
        postcode: string;
        addressable_type: string;
        addressable_id: number;
      };
    };
    is_patient_with_full_details: boolean;
  };
  message: string;
}

export async function validateOtp(
  email: string,
  otp: number
): Promise<PostValidateOtpResponseData> {
  const requestData: ValidateOtpRequestData = {email, otp};

  const response = await axiosInstance.post<PostValidateOtpResponseData>(
    ApiRoute.BookingValidateOtp,
    requestData
  );

  return response.data;
}

export interface PutResetPasswordResponseData {
  message: string;
}

export interface PutResetPasswordVariables {
  token: string;
  password: string;
  confirmPassword: string;
}

export async function putResetPassword(variables: PutResetPasswordVariables) {
  const {password, confirmPassword, token} = variables;

  const response = await axiosInstance.put<PutResetPasswordResponseData>(
    ApiRoute.PatientResetPassword,
    {patient: {password, confirm_password: confirmPassword, token}}
  );

  return response.data;
}

export interface PutSetPasswordResponseData {
  message: string;
}

export interface PutSetPasswordVariables {
  password: string;
  confirmPassword: string;
}

export async function putSetPassword(variables: PutSetPasswordVariables) {
  const {password, confirmPassword} = variables;
  const state = store.getState();
  const token = state.auth.token;

  const response = await axiosInstance.put<PutSetPasswordResponseData>(
    ApiRoute.PatientSetPassword,
    {patient: {password, confirm_password: confirmPassword}},
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return response.data;
}

export async function getPaymentCard(): Promise<{
  message: string;
  data: PaymentCard[];
}> {
  const response = await axiosInstance.get<GetInsuranceTypesResponseData>(
    ApiRoute.Constant,
    {params: {key: ConstantApiParamKey.CardType}}
  );

  return {
    message: response.data.message,
    data: response.data.data,
  };
}

export interface GetPreferredPointOfContactResponseData {
  message: string;
  data: {id: number; name: string}[];
}

export async function getPreferredPointOfContact() {
  const response =
    await axiosInstance.get<GetPreferredPointOfContactResponseData>(
      ApiRoute.Constant,
      {
        params: {key: ConstantApiParamKey.PreferredPointOfContact},
      }
    );

  return response.data;
}

export interface GetCountryListResponseData {
  success: boolean;
  data: {code: string; name: string; country_code: string}[];
  message: string;
}

export async function getCountries() {
  const response = await axiosInstance.get<GetCountryListResponseData>(
    ApiRoute.Constant,
    {
      params: {key: ConstantApiParamKey.Country},
    }
  );

  return response.data;
}

export interface PostBlockSlotsVariables {
  date: string;
  doctorId: number;
  startTime: string;
  endTime: string;
  id: number | undefined;
}

export interface PostBlockSlotsResponseData {
  data: {
    appointment: {
      id: number;
      start_time: string;
      end_time: string;
      date: string;
    };
  };
}

export async function postBlockSlots(variables: PostBlockSlotsVariables) {
  const {date, doctorId, startTime, endTime, id} = variables;
  const response = await axiosInstance.post<PostBlockSlotsResponseData>(
    ApiRoute.BlockSlots,
    {
      appointment: {
        id: id,
        date: date,
        doctor_id: doctorId,
        start_time: startTime,
        end_time: endTime,
      },
    }
  );

  return response.data;
}
