import {ConstantsAPIKey} from '~/services/api/constants/constants';
import {GETConstantsParams} from '~/services/api/constants/types';

const apiQueryKey = {
  constants: <K extends ConstantsAPIKey>(params: GETConstantsParams<K>) =>
    [
      'constants',
      params.key,
      params.careId,
      params.country,
      params.appointmentId,
      params.subcareId,
    ] as const,
};

export default apiQueryKey;
