import FrontdeskButton from '$components/FrontdeskButton';
import NoteCard from './NoteCard';
import NoteForm from './NoteForm';
import {Note} from './types';
import './styles.scss';
import NotePulse from './NotePulse';
import NoNotesCard from './NoNotesCard';
import {SetStateAction} from 'react';
import useAuthUser from '../../../../../hooks/useAuthUser';
import {UserRoleName} from '$modules/admin/constant';
import NoteDisplay from './NoteDisplay';

interface PatientDetailsNotesComponentProps {
  isModify: boolean;
  setIsModify: React.Dispatch<SetStateAction<boolean>>;
  notes: Note[];
  isNotesLoading: boolean;
  selectedNote: Note | null;
  setSelectedNoteId: React.Dispatch<SetStateAction<number | null>>;
  deleteNoteRequest: (noteId: number) => void;
  showDeleteModal: boolean;
  setShowDeleteModal: React.Dispatch<SetStateAction<boolean>>;
  disableSubmit: boolean;
  isDeletingNote: boolean;
}

function PatientDetailNotesComponent(props: PatientDetailsNotesComponentProps) {
  const {
    isModify,
    setIsModify,
    notes,
    isNotesLoading,
    selectedNote,
    setSelectedNoteId,
    deleteNoteRequest,
    showDeleteModal,
    setShowDeleteModal,
    disableSubmit,
    isDeletingNote,
  } = props;

  const {roleName} = useAuthUser()!;

  const handleNewNoteClick = () => {
    setSelectedNoteId(null);
    setIsModify(false);
  };

  const handleModifyNoteClick = () => {
    setIsModify(true);
  };

  const noteCards = notes.map(note => {
    const handleModify = () => {
      setSelectedNoteId(note.id);
      setIsModify(true);
    };

    const handleExpand = () => {
      setIsModify(false);
      setSelectedNoteId(note.id);
    };

    return (
      <NoteCard
        key={note.id}
        note={note}
        onEditClick={handleModify}
        onExpandClick={handleExpand}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteNoteRequest={deleteNoteRequest}
        isNoteDeleting={isDeletingNote}
        disableClick={disableSubmit}
      />
    );
  });

  const isConsultant = roleName === UserRoleName.Doctor;
  const showForm = isConsultant && (!selectedNote || isModify);

  return (
    <div className="patient-list-container flex flex-col flex-1">
      <div className="mb-6">
        <h1 className="text-2xl font-semibold primary-dark mb-6">Notes</h1>
        {isConsultant && (
          <FrontdeskButton
            className="w-full max-w-64"
            variant="primary"
            title="New note"
            onClick={handleNewNoteClick}
          />
        )}
      </div>

      {!isConsultant && !noteCards.length ? (
        <div className="flex flex-1 justify-center items-center">
          <p className="text-xl tertiary">No notes found</p>
        </div>
      ) : (
        <div className="flex flex-1 overflow-auto">
          <div className="flex flex-col w-full max-w-64 min-w-64 overflow-auto gap-4">
            {isNotesLoading ? (
              <NotePulse />
            ) : noteCards.length ? (
              noteCards
            ) : (
              <NoNotesCard />
            )}
          </div>
          {showForm ? (
            <NoteForm
              isModify={isModify}
              selectedNote={selectedNote}
              setIsModify={setIsModify}
            />
          ) : (
            selectedNote && (
              <NoteDisplay
                title={selectedNote.title}
                description={selectedNote.description}
                documentUrl={selectedNote.documentUrl}
                author={selectedNote.createdBy}
                onModifyClick={handleModifyNoteClick}
              />
            )
          )}
        </div>
      )}
    </div>
  );
}

export default PatientDetailNotesComponent;
