import {useMemo} from 'react';
import {useQuery} from '@tanstack/react-query';
import apiQueryKey from '~/services/api/queryKeys';
import {ConstantsAPIKey} from './constants';
import {getConstants} from './service';
import {GETConstantsParams, ConstantsKeyDataMap} from './types';
import {processConstantsAPIResponseData} from './utils';

export function useAPIConstants<K extends ConstantsAPIKey>(
  params: GETConstantsParams<K>
) {
  const {data, isLoading, isRefetching, isError} = useQuery({
    queryKey: apiQueryKey.constants(params),
    queryFn: () => getConstants(params),
  });

  const processedData = useMemo(() => {
    if (!data) return undefined;
    return processConstantsAPIResponseData(params.key, data);
  }, [data, params.key]) as ConstantsKeyDataMap[K] | undefined;

  return {data: processedData, isLoading, isRefetching, isError};
}
