import NotificationMessageIcon from '~/assets/svgs/notification-message.svg?react';
import {timeFromNow} from '~/utils/date';
import './styles.scss';

interface NotificationToastProps {
  title: string;
  message: string;
  time: Date;
}

function NotificationToast(props: NotificationToastProps) {
  const {title, message, time} = props;

  const timeFromNowString = timeFromNow(time);

  return (
    <div className="notification-toast">
      <div className="icon">
        <NotificationMessageIcon />
      </div>
      <div className="body">
        <p className="title">EYE OP • {timeFromNowString}</p>
        <p className="sub-title">{title}</p>
        <p className="description">{message}</p>
      </div>
    </div>
  );
}

export default NotificationToast;
