import {SetStateAction, useEffect, useState} from 'react';
import NoteFormComponent from './component';
import {Note} from '../types';
import {useCreateNote, useModifyNote} from '../hooks';
import {useParams} from 'react-router-dom';

interface NoteFormProps {
  isModify: boolean;
  selectedNote: Note | null;
  setIsModify: React.Dispatch<SetStateAction<boolean>>;
}

function NoteForm(props: NoteFormProps) {
  const {isModify, selectedNote, setIsModify} = props;

  const params = useParams();
  const patientId = Number(params.patientId);

  const [title, setTitle] = useState(selectedNote?.title || '');
  const [description, setDescription] = useState(
    selectedNote?.description || ''
  );
  const [document, setDocument] = useState<File | null>(null);

  const resetNoteForm = () => {
    setTitle('');
    setDescription('');
    setDocument(null);
  };

  const handleModifySuccess = () => {
    setIsModify(false);
  };

  const {
    sendCreateNoteRequest,
    isPending: isCreateNotePending,
    disableSubmit: createRequestDisableSubmit,
  } = useCreateNote(resetNoteForm);

  const {
    sendModifyNoteRequest,
    isPending: isModifyNotePending,
    disableSubmit: modifyRequestDisableSubmit,
  } = useModifyNote(handleModifySuccess);

  const disableSubmit =
    !title ||
    !description ||
    !title.trim() ||
    !description.trim() ||
    createRequestDisableSubmit ||
    modifyRequestDisableSubmit;

  const handleDocumentFileChange = (file: File | null) => {
    setDocument(file);
  };

  const handleSubmit = () => {
    if (isModify) {
      sendModifyNoteRequest({
        noteId: selectedNote?.id,
        title: title,
        description: description,
        document: document || undefined,
      });
    } else {
      sendCreateNoteRequest({
        title: title,
        description: description,
        patientId: patientId,
        document: document || undefined,
      });
    }
  };

  const handleReset = () => {
    setTitle(selectedNote?.title || '');
    setDescription(selectedNote?.description || '');
    setDocument(null);
  };

  useEffect(() => {
    setTitle(selectedNote?.title || '');
    setDescription(selectedNote?.description || '');
    setDocument(null);
  }, [selectedNote]);

  return (
    <NoteFormComponent
      isModify={isModify}
      title={title}
      setTitle={setTitle}
      description={description}
      setDescription={setDescription}
      document={document}
      handleDocumentChange={handleDocumentFileChange}
      onSubmit={handleSubmit}
      submitLoading={isCreateNotePending || isModifyNotePending}
      disableSubmit={disableSubmit}
      author={selectedNote?.createdBy}
      onReset={handleReset}
    />
  );
}

export default NoteForm;
