import {useParams} from 'react-router-dom';
import {useDocumentsHook} from '../../hooks/useDocumentsHook';
import DocumentsComponent from './component';
import {useState} from 'react';

function Documents() {
  const {patientId} = useParams();

  const {
    data: documentsList,
    isPending: isDocumentsLoading,
    deleteDocumentRequest,
    isDocumentDeleting,
  } = useDocumentsHook(patientId || '');

  const [uploadDocumentsDialogOpen, setUploadDocumentsDialogOpen] =
    useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleUploadDocumentsDialog = () => {
    setUploadDocumentsDialogOpen(!uploadDocumentsDialogOpen);
  };

  return (
    <DocumentsComponent
      patientId={patientId || ''}
      deleteDocumentRequest={deleteDocumentRequest}
      isDocumentDeleting={isDocumentDeleting}
      isDocumentsLoading={isDocumentsLoading}
      documentsList={documentsList}
      uploadDocumentsDialogOpen={uploadDocumentsDialogOpen}
      handleUploadDocumentsDialog={handleUploadDocumentsDialog}
      showDeleteModal={showDeleteModal}
      setShowDeleteModal={setShowDeleteModal}
    />
  );
}

export default Documents;
