import {InferType, object, string, number} from 'yup';
import {ValidationMessage} from '$constants/messages';
import {NAME_VALIDATION_REGEX} from '$constants/regex';

export const userSchema = object({
  firstName: string()
    .trim()
    .max(150, ValidationMessage.FirstNameExceedCharacterLimit)
    .matches(NAME_VALIDATION_REGEX, ValidationMessage.NameInvalidCharacter)
    .required(ValidationMessage.FirstNameRequired),
  lastName: string()
    .trim()
    .max(150, ValidationMessage.LastNameExceedCharacterLimit)
    .matches(NAME_VALIDATION_REGEX, ValidationMessage.NameInvalidCharacter)
    .required(ValidationMessage.LastNameRequired),
  roleId: string().required(ValidationMessage.UserRoleRequired),
  email: string()
    .trim()
    .email(ValidationMessage.EmailInvalid)
    .max(350, ValidationMessage.EmailExceedCharacterLimit)
    .required(ValidationMessage.EmailRequired),
  phoneNumber: number()
    .transform(value => (isNaN(value) ? null : value))
    .positive(ValidationMessage.PhoneNumberInvalid)
    .integer(ValidationMessage.PhoneNumberInvalid)
    .min(10_000_00, ValidationMessage.PhoneNumberInvalid)
    .max(999_999_999_999_999, ValidationMessage.PhoneNumberInvalid)
    .nullable(),
});

export type UserFormData = InferType<typeof userSchema>;
