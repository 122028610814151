import IconButton from '@mui/material/IconButton';
import EyeOpLogo from '$assets/svgs/eye-op.svg?react';
import EyeOpText from '$assets/svgs/eye-op-full.svg?react';
import HospitalLogo from '$assets/svgs/site-logo.svg?react';
import LeftDoubleArrow from '$assets/svgs/left-double-arrow.svg?react';
import RightDoubleArrow from '$assets/svgs/right-double-arrow.svg?react';

interface HeaderProps {
  open: boolean;
  onDrawerToggle: () => void;
}

function Header(props: HeaderProps) {
  const {open, onDrawerToggle} = props;

  return (
    <div className="flex justify-between items-center p-4">
      <div className="flex items-center gap-2.5">
        {open && (
          <>
            <EyeOpLogo className="h-6 w-6" />
            <div className="w-px h-2.5 bg-[#EBC3FF]" />
            <HospitalLogo className="h-6 w-6" />
            <EyeOpText className="h-6 ml-2.5" />
          </>
        )}
      </div>
      <IconButton onClick={onDrawerToggle}>
        {open ? (
          <LeftDoubleArrow className="primary" />
        ) : (
          <RightDoubleArrow className="primary" />
        )}
      </IconButton>
    </div>
  );
}

export default Header;
