import {AnnotationHeartIcon} from '$assets/svgs';
import useHospitalInfo from '../../../../hooks/useHospitalInfo';

const DisclaimerComponent = () => {
  const {hospitalInfo} = useHospitalInfo();
  const mobileNumber = hospitalInfo?.mobileNumber || '-';

  return (
    <div className="font-booking flex gap-x-4">
      <div className="border-2 rounded-sm ">
        <AnnotationHeartIcon />
      </div>
      <div className="font-booking font-normal tracking-tighter">
        <span className="text-primary text-base/5 ">
          Need assistance with your booking?
        </span>
        <span className="text-primary ms-1 text-lg/5">Contact us at</span>
        <a
          href={`tel:${mobileNumber}`}
          className="text-primary-bold ms-1 text-lg/6 font-semibold"
          style={{textDecoration: 'none'}}
        >
          {mobileNumber}
        </a>
      </div>
    </div>
  );
};

export default DisclaimerComponent;
