import axiosInstance from '~/services/api/axios';
import {ConstantsAPIKey} from './constants';
import {GETConstantsParams, GETConstantsResponseData} from './types';

export async function getConstants<K extends ConstantsAPIKey>(
  params: GETConstantsParams<K>
) {
  const response = await axiosInstance.get<GETConstantsResponseData<K>>(
    '/constants',
    {
      params: {
        key: params.key,
        country: params.country,
        appointment_id: params.appointmentId,
        care_id: params.careId,
        sub_care_type_id: params.subcareId,
      },
    }
  );

  return response.data;
}
