import EyeOPLogo from '$assets/svgs/eye-op.svg?react';
import LogOutLogo from '$assets/svgs/log-out.svg?react';
import FrontdeskButton from '$components/FrontdeskButton';
import {UserFormData} from './schema';
import {FormikErrors, FormikTouched} from 'formik';
import {UserData} from './types';
import UserTable from './UserTable';
import UserForm from './UserForm';
import './styles.scss';

interface AdminComponentProps {
  disableSubmit: boolean;
  isCreateUserLoading: boolean;
  users: UserData[];
  handleSignOut: () => void;
  formValues: UserFormData;
  formTouched: FormikTouched<UserFormData>;
  formErrors: FormikErrors<UserFormData>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleClearForm: () => void;
  handleSubmit: () => void;
  handleRoleIdChange: (value: string) => void;
}

function AdminComponent(props: AdminComponentProps) {
  const {
    disableSubmit,
    isCreateUserLoading,
    users,
    handleSignOut,
    formValues,
    formTouched,
    formErrors,
    handleClearForm,
    handleBlur,
    handleChange,
    handleSubmit,
    handleRoleIdChange,
  } = props;

  return (
    <div className="admin-container h-screen w-screen">
      <div className="w-full h-full max-w-7xl m-auto pt-8 pb-4 px-4 flex flex-col">
        <div className="mb-4 flex justify-between">
          <EyeOPLogo className="h-8 w-8" />
          <FrontdeskButton
            variant="secondary"
            title="Sign out"
            startIcon={<LogOutLogo className="h-3.5 w-3.5" />}
            onClick={handleSignOut}
          />
        </div>
        <UserForm
          handleClearForm={handleClearForm}
          disableSubmit={disableSubmit}
          isCreateUserLoading={isCreateUserLoading}
          formValues={formValues}
          formTouched={formTouched}
          formErrors={formErrors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleRoleIdChange={handleRoleIdChange}
        />
        <UserTable users={users} />
      </div>
    </div>
  );
}

export default AdminComponent;
