import DialogComponent from '$components/DialogComponent';
import {Button} from '@mui/material';
import {deleteDialogFooterStyles} from './styles';
import './styles.scss';

interface DeleteAppointmentModalProps {
  isDisabled: boolean;
  isOpen: boolean;
  onDelete: () => void;
  onClose: () => void;
}

function DeleteAppointmentModal(props: DeleteAppointmentModalProps) {
  const {isDisabled, onClose, onDelete, isOpen} = props;
  return (
    <DialogComponent
      disableHeader
      open={isOpen}
      handleClose={onClose}
      dialogBody={
        <div className="delete-appointment-modal-container">
          <h1 className="title">
            Are you sure you want to cancel this appointment?
          </h1>
          <p className="sub-title">
            If you confirm, this booking will be permanently cancelled and
            you'll need to create a new one to restore it.
          </p>
          <div className="footer">
            <Button
              className="delete-btn"
              sx={deleteDialogFooterStyles.deleteBtn}
              disabled={isDisabled}
              variant="contained"
              onClick={onDelete}
            >
              Delete appointment
            </Button>
            <Button
              className="cancel-btn"
              sx={deleteDialogFooterStyles.cancelBtn}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      }
    />
  );
}

export default DeleteAppointmentModal;
