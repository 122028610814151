import DatePicker from '$components/DatePicker';
import {Typography} from '@mui/material';
import ListComponent from './ListComponent';
import {appointmentListHeader, appointmentListHeaderBackButton} from './styles';
import CalendarDetailsPageHeader from '../CalendarDetailsPageHeader';
import {Color} from '$constants/style';
import FilterOptionsContainer from '../FilterOptions';
import {AppointmentInfo} from '$modules/frontdesk/type';
import useAuthUser from '../../../../hooks/useAuthUser';
import useTimezone from '../../../../hooks/useTimezone';
import {momentDate} from '$utils/date';
import './styles.scss';
import ListComponentSkeleton from './ListComponentSkeleton';

interface AppointmentsComponentProps {
  searchText: string;
  dateState: Date | null;
  setSearchText: (value: string) => void;
  handleDateChange: (value: Date | null) => void;
  filters: {
    status: string[];
    appointmentTypes: number[];
    consultants: number[];
  };
  onFilterChange: (
    type: 'status' | 'appointmentTypes' | 'consultants',
    id: number | string
  ) => void;
  onApply: () => void;
  onReset: () => void;
  appointments: AppointmentInfo[];
  isLoading: boolean;
}
const AppointmentsComponent: React.FC<AppointmentsComponentProps> = ({
  searchText,
  dateState,
  setSearchText,
  handleDateChange,
  filters,
  onFilterChange,
  onApply,
  onReset,
  appointments,
  isLoading,
}) => {
  const user = useAuthUser();
  const timezone = useTimezone();
  const isLoggedInUserDoctor = user?.roleName.includes('Doctor');
  const allowConsultantFilter = isLoggedInUserDoctor ? false : true;

  return (
    <div className="flex w-full flex-col overflow-y-hidden bg-[#f6f6ff] px-8 pb-8">
      <div className="w-full bg-[#f6f6ff]">
        <CalendarDetailsPageHeader
          headerClassName={appointmentListHeader}
          headerBackButtonClassName={appointmentListHeaderBackButton}
          headerBackLabel="Back"
          containsSearchBox={true}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      </div>
      <div className="flex flex-col overflow-hidden py-4">
        <div className="flex w-full justify-between gap-4 overflow-y-auto">
          <div className="flex w-full flex-col gap-4 overflow-auto">
            <div className="flex flex-col gap-2">
              <Typography variant="pageTitle">Appointment list</Typography>
              <Typography variant="h4" sx={{color: Color.Grey500}}>
                {dateState && momentDate(dateState).format('DD MMM')}
              </Typography>
            </div>
            <div className="flex h-full w-full flex-col gap-4 overflow-x-auto overflow-y-auto">
              {isLoading ? (
                <ListComponentSkeleton />
              ) : (
                <ListComponent
                  appointments={appointments}
                  isLoading={isLoading}
                />
              )}
            </div>
          </div>
          <div className="flex h-full w-full max-w-fit flex-col gap-4 overflow-x-auto overflow-y-auto px-2">
            <div className="flex">
              <DatePicker
                name="static-datepicker"
                value={dateState}
                onChange={handleDateChange}
                isStatic={true}
                timezone={timezone}
              />
            </div>
            <div className="flex w-full">
              <FilterOptionsContainer
                containStatus={true}
                containAppointmentTypes={true}
                containConsultants={allowConsultantFilter}
                filters={filters}
                onFilterChange={onFilterChange}
                onApply={onApply}
                onReset={onReset}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentsComponent;
