import {Updater} from 'use-immer';
import FetchError from '~/components/FetchError';
import Loading from '~/components/Loading';
import RenderSVG from '~/components/RenderSVG';
import ActionCard from '~/modules/booking/components/ActionCard';
import Footer from '~/modules/booking/components/Footer';
import {
  BookingData,
  BookingView,
  CareSelectionViewChoices,
} from '~/modules/booking/types';
import {useAPIConstants} from '~/services/api/constants/hooks';
import {ConstantsAPIKey} from '~/services/api/constants/constants';
import {Care} from '~/services/api/constants/types';

interface CareSelectionViewProps {
  bookingChoices: CareSelectionViewChoices;
  setActiveView: React.Dispatch<React.SetStateAction<BookingView>>;
  setBookingChoices: Updater<BookingData>;
}

function CareSelectionView(props: CareSelectionViewProps) {
  const {bookingChoices, setActiveView, setBookingChoices} = props;
  const {data, isLoading} = useAPIConstants({
    key: ConstantsAPIKey.Care,
    appointmentId: bookingChoices.appointment.id,
  });

  if (isLoading) return <Loading />;
  if (!data) return <FetchError />;

  const handleClick = (info: Care) => {
    setBookingChoices(draft => {
      draft.care = info;
    });
    setActiveView(BookingView.SubcareSelectionView);
  };

  const actionCards = data.map(info => {
    return (
      <ActionCard
        key={info.id}
        active={bookingChoices.care?.id === info.id}
        icon={info.image ? <RenderSVG svg={info.image} /> : null}
        title={info.title}
        description={info.description}
        onClick={info.isEnable ? () => handleClick(info) : undefined}
        disabled={!info.isEnable}
        testId={`care-${info.title}-card`}
      />
    );
  });

  const handleBackClick = () => {
    setActiveView(BookingView.AppointmentSelectionView);
  };

  return (
    <div className="font-booking flex flex-1 flex-col px-6 gap-10 lg:px-52 overflow-y-auto">
      <h2 className="text-center text-2xl lg:text-4xl pb-2 font-bold text-grey-2">
        What type of care are you looking for?
      </h2>
      <div className="flex flex-col gap-6 pb-4 flex-1 overflow-y-auto">
        {actionCards}
      </div>
      <Footer onBackClick={handleBackClick} />
    </div>
  );
}

export default CareSelectionView;
