import {InferType, object, ref, string} from 'yup';
import {ValidationMessage} from '$constants/messages';
import {
  LOWER_CASE_REGEX,
  SPECIAL_CHARACTER_REGEX,
  UPPER_CASE_REGEX,
} from '$constants/regex';
import {PasswordValidationStage} from '$modules/booking/components/PasswordValidationStepper/types';

export const resetPasswordSchema = object({
  password: string()
    .trim()
    .min(12, PasswordValidationStage.MinCharacter)
    .matches(UPPER_CASE_REGEX, PasswordValidationStage.UpperCase)
    .matches(LOWER_CASE_REGEX, PasswordValidationStage.LowerCase)
    .matches(SPECIAL_CHARACTER_REGEX, PasswordValidationStage.SpecialChar)
    .required(ValidationMessage.PasswordRequired),
  confirmPassword: string()
    .oneOf([ref('password')], ValidationMessage.ConfirmResetPasswordMatch)
    .required(ValidationMessage.ConfirmPasswordRequired),
});

export type ResetPasswordFormData = InferType<typeof resetPasswordSchema>;
