import {InferType, date, number, object, string} from 'yup';
import moment from '$utils/date';
import {NAME_VALIDATION_REGEX} from '$constants/regex';
import {ValidationMessage} from '$constants/messages';

export const patientDetailOneFormSchema = object({
  patient: object({
    nhsNumber: number()
      .transform((_, val) => (Number.isNaN(val) ? null : val))
      .nullable()
      .typeError(ValidationMessage.NHSNumberInvalid)
      .positive(ValidationMessage.NHSNumberInvalid)
      .integer(ValidationMessage.NHSNumberInvalid)
      .min(1_000_000_000, ValidationMessage.NHSNumberLength)
      .max(9_999_999_999, ValidationMessage.NHSNumberLength),
    title: string().required(ValidationMessage.TitleRequired),
    firstName: string()
      .trim()
      .max(150, ValidationMessage.FirstNameExceedCharacterLimit)
      .matches(NAME_VALIDATION_REGEX, ValidationMessage.NameInvalidCharacter)
      .required(ValidationMessage.FirstNameRequired),
    lastName: string()
      .trim()
      .max(150, ValidationMessage.LastNameExceedCharacterLimit)
      .matches(NAME_VALIDATION_REGEX, ValidationMessage.NameInvalidCharacter)
      .required(ValidationMessage.LastNameRequired),
    email: string()
      .trim()
      .email(ValidationMessage.EmailInvalid)
      .max(350, ValidationMessage.EmailExceedCharacterLimit)
      .required(ValidationMessage.EmailRequired),
    gender: string().required(ValidationMessage.GenderRequired),
    dob: date()
      .max(new Date(), ValidationMessage.DateOfBirthFutureDate)
      .required(ValidationMessage.DateOfBirthRequired)
      .test('is-18-or-older', '', value => {
        if (!value) return false;
        const today = moment();
        const birthDate = moment(value);
        const age = today.diff(birthDate, 'years', true);
        return age >= 18;
      }),
  }).required(),
});

export type PatientDetailOneValidatedFormData = InferType<
  typeof patientDetailOneFormSchema
>;

export type PatientDetailOneFormData = {
  patient: {
    nhsNumber?: number;
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    gender: string;
    dob: Date | null;
  };
};
