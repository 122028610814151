import {useState} from 'react';
import LoginComponent from './component';
import {useFormik} from 'formik';
import {LoginFormData, emailSchema, loginSchema} from './schema';
import {useLogin} from './hooks';
import useAuthUser from '../../hooks/useAuthUser';
import {UserRoleName} from '$modules/admin/constant';
import {Navigate} from 'react-router-dom';
import {getRoute, RouteSection} from '$utils/route';
import {RoutePath} from '$constants/routes';

function LoginContainer() {
  const [showPassword, setShowPassword] = useState(false);
  const authUser = useAuthUser();

  const {
    disableSubmit,
    isLoginPending,
    sendLoginRequest,
    sendForgotPasswordRequest,
  } = useLogin();

  const handleSubmit = (values: LoginFormData) => {
    sendLoginRequest(values);
  };

  const formik = useFormik<LoginFormData>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: handleSubmit,
  });

  const handleForgetPassword = async () => {
    formik.setTouched({});
    formik.setFieldTouched('email');

    const {email} = formik.values;

    try {
      emailSchema.validateSync(email);
      sendForgotPasswordRequest({email});
    } catch {
      // validation failed
      // formik shows error on email field
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  if (authUser) {
    const isStaff =
      authUser.roleName === UserRoleName.Frontdesk ||
      authUser.roleName === UserRoleName.Doctor;

    const navigationPath = isStaff
      ? getRoute(RouteSection.Calendar)
      : RoutePath.indexRoute;

    return <Navigate to={navigationPath} />;
  }

  return (
    <LoginComponent
      isLoginPending={isLoginPending}
      disableSubmit={disableSubmit}
      formValues={formik.values}
      formErrors={formik.errors}
      formTouched={formik.touched}
      handleChange={formik.handleChange}
      handleBlur={formik.handleBlur}
      handleSubmit={formik.handleSubmit}
      handleForgetPassword={handleForgetPassword}
      showPassword={showPassword}
      setShowPassword={setShowPassword}
      handleKeyDown={handleKeyDown}
    />
  );
}

export default LoginContainer;
