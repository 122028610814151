import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {Button, Stack, Typography, useMediaQuery} from '@mui/material';
import theme from '$modules/booking/theme';
import EyeOpLogo from '$assets/svgs/eye-op.svg?react';
import HospitalMobileLogo from '$assets/svgs/site-logo.svg?react';
import HospitalTextLogo from '$assets/svgs/site-text-logo.svg?react';
import CloseCircleIcon from '$assets/svgs/close-circle.svg?react';
import summaryVideo from '$assets/images/thankyou-booking.jpg';
import {AppSummaryViewStyles as styles} from '../styles';
import {BookingView, AppSummaryViewChoices, BookingDataUpdater} from '../types';
import {useDeleteAppointment} from '../hooks';
import {dateToDDMMYYYY} from '$utils/date';
import DeleteAppointmentModal from '../DeleteAppointment/DeleteAppointmentModal';
import {Color} from '$constants/style';
import {handleBeforeUnload} from '../utils';
import useHospitalInfo from '../../../hooks/useHospitalInfo';
import Footer from '../components/Footer';

interface AppSummaryViewProps {
  bookingChoices: AppSummaryViewChoices;
  setActiveView: (view: BookingView) => void;
  setBookingData: BookingDataUpdater;
}

function AppSummaryView(props: AppSummaryViewProps) {
  const {bookingChoices} = props;

  const {hospitalInfo} = useHospitalInfo();

  const {requestDeleteAppointment, disableSubmit} =
    useDeleteAppointment(bookingChoices);

  const dateString = dateToDDMMYYYY(bookingChoices.date);
  const consultantName = bookingChoices.consultant.fullName;

  let preferredContactString =
    bookingChoices.patientContactDetail.patient.preferredContactMethod;
  if (preferredContactString === 'Both') {
    preferredContactString = 'email and SMS';
  }

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const navigateToHospital = () => {
    if (hospitalInfo?.hospitalURL) {
      window.location.replace(hospitalInfo.hospitalURL);
    }
  };

  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  return (
    <Box sx={styles.screenSection}>
      <Box sx={styles.bookingWrapper} flexDirection="column">
        <Box sx={styles.appSummaryWrap} flexDirection="column">
          <Box sx={styles.header}>
            <Box display="flex" gap={1} alignItems="center">
              <EyeOpLogo className="site-logo" />
              {isLarge && <div className="h-3 w-px bg-[#EBC3FF]" />}
              {isLarge && <HospitalTextLogo className="h-24 w-24" />}
            </Box>
            <Box sx={styles.hospitalLogoWrap}>
              <HospitalMobileLogo className="hosptial-mobile-logo" />
            </Box>
          </Box>
          <Box sx={styles.appSummarySection}>
            <Stack spacing={1}>
              <Typography variant="h1" sx={styles.summaryHead}>
                Thank you for your booking!
              </Typography>
              <Typography variant="caption">
                Your appointment request with {consultantName} at Stoneygate Eye
                Hospital is confirmed. Appointment details will be sent via
                {preferredContactString}. Thank you for choosing us. For further
                assistance, please contact our support team.
              </Typography>
            </Stack>
            <Stack sx={styles.summaryActionBtnWrap}>
              {hospitalInfo?.hospitalURL && (
                <Button
                  variant="contained"
                  color="warning"
                  className="!bg-[#EBA300]"
                  sx={styles.bookingActionBtn}
                  onClick={navigateToHospital}
                >
                  <Typography variant="body1" color={Color.White}>
                    Discover more about Stoneygate
                  </Typography>
                </Button>
              )}
            </Stack>
            <Stack spacing={2}>
              <Typography sx={styles.summaryTitle} variant="h3">
                Your appointment summary:
              </Typography>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <Typography
                    variant="body1"
                    sx={{color: theme.palette.primary.main}}
                  >
                    Appointment
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{color: theme.palette.text.secondary}}
                  >
                    {bookingChoices.care.title} consultation
                  </Typography>
                </Stack>
                <Stack spacing={0.5}>
                  <Typography
                    variant="body1"
                    sx={{color: theme.palette.primary.main}}
                  >
                    Consultant
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{color: theme.palette.text.secondary}}
                  >
                    {consultantName}
                  </Typography>
                </Stack>
                <Stack spacing={0.5}>
                  <Typography
                    variant="body1"
                    sx={{color: theme.palette.primary.main}}
                  >
                    Date
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{color: theme.palette.text.secondary}}
                  >
                    {dateString}
                  </Typography>
                </Stack>
                <Stack spacing={0.5}>
                  <Typography
                    variant="body1"
                    sx={{color: theme.palette.primary.main}}
                  >
                    Time
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{color: theme.palette.text.secondary}}
                  >
                    To be sent via {preferredContactString}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack sx={styles.summaryActionBtnWrap}>
              <Button
                variant="contained"
                color="secondary"
                className="delete-button"
                sx={styles.bookingActionBtn}
                startIcon={<CloseCircleIcon />}
                onClick={handleOpenModal}
              >
                <Typography variant="body1" color={theme.palette.text.primary}>
                  Delete your booking
                </Typography>
              </Button>
            </Stack>
          </Box>
        </Box>
        <Box sx={styles.footerSection}>
          <Footer />
        </Box>
      </Box>
      <Box sx={styles.appSummaryVideoSection}>
        <img src={summaryVideo} alt="video" />
      </Box>

      {openModal && (
        <DeleteAppointmentModal
          isDisabled={disableSubmit}
          isOpen={openModal}
          onDelete={requestDeleteAppointment}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  );
}

export default AppSummaryView;
