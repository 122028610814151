import {SxProps} from '@mui/material';
import theme from '../theme';
import {Color} from '$constants/style';

export const deleteDialogFooterStyles: Record<string, SxProps> = {
  cancelBtn: {
    textTransform: 'none',
    color: theme.palette.error.main,
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.error.main,
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
  deleteBtn: {
    background: Color.GradientBackground,
    color: Color.White,
    boxShadow: 'none',
    fontWeight: '400',
    padding: '0.5rem 1.5rem',
    textTransform: 'none',
    borderRadius: '8px',

    '&:hover': {
      background: Color.GradientHoverBackground,
    },

    '&:focus': {
      background: Color.GradientHoverBackground,
    },

    '&.Mui-disabled': {
      background: Color.GradientDisabledBackground, // Background color with 42% opacity for disabled buttons
      color: Color.BlackOpacity40,
    },
  },
};
