import useAuthUser from './useAuthUser';

function useTimezone() {
  const authUser = useAuthUser();

  const timezone =
    authUser?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  return timezone;
}

export default useTimezone;
