import {Question} from '$api/patientList/questionnaire';
import {FormikProps, FormikValues} from 'formik';
import QuestionnaireComponent from './QuestionnaireComponent';

type QuestionnaireFormProps = {
  startIndex: number;
  formik: FormikProps<FormikValues>;
  questionsToDisplay: Question[];
  questionContainerClassName?: string;
  formClassName?: string;
  layoutClassName?: string;
  questionClassName?: string;
  'data-testid'?: string;
};

const QuestionnaireForm = ({
  questionClassName,
  layoutClassName,
  formClassName,
  startIndex,
  formik,
  questionsToDisplay,
  questionContainerClassName,
  'data-testid': testId,
}: QuestionnaireFormProps) => {
  return (
    <form
      onSubmit={formik.handleSubmit}
      className={formClassName}
      data-testid={testId}
    >
      <div className={layoutClassName}>
        {questionsToDisplay.map((question: Question, index: number) => (
          <div key={question.id} className={questionContainerClassName}>
            <h3
              className={questionClassName}
            >{`${startIndex + index + 1}. ${question.question}`}</h3>
            <QuestionnaireComponent formik={formik} question={question} />
          </div>
        ))}
      </div>
    </form>
  );
};

export default QuestionnaireForm;
