import {Typography, MenuItem, Button} from '@mui/material';
import {AllergySeverity} from '../constant';
import SelectInput from '$components/SelectInput';
import {Color} from '$constants/style';
import {SEVERITY_OPTIONS} from './constant';
import Input from '$components/Input';
import TrashIcon from '$assets/svgs/trash.svg?react';
import {AllergyFormData} from '../types';

interface AllergyFormProps {
  allergy: AllergyFormData;
  setName: (value: string) => void;
  setSeverity: (value: AllergySeverity | '') => void;
  onRemove?: () => void;
}

function AllergyForm(props: AllergyFormProps) {
  const {allergy, setName, setSeverity, onRemove} = props;

  const renderSeverityItem = (item: {label: string; value: string}) => {
    return (
      <MenuItem key={item.value} value={item.value}>
        {item ? item.label : 'Choose severity'}
      </MenuItem>
    );
  };

  const renderSeverityValue = (value: string) => {
    if (value === '') {
      return (
        <Typography color={Color.TextPrimayPlaceholder}>
          Choose severity
        </Typography>
      );
    }

    const item = SEVERITY_OPTIONS.find(it => it.value === value);

    if (!item) {
      return (
        <Typography color={Color.TextPrimayPlaceholder}>
          Choose severity
        </Typography>
      );
    }

    return <Typography>{item.label}</Typography>;
  };

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setName(e.target.value);
  };
  const handleSeverityChange = (value: string) => {
    const severity = value as AllergySeverity;
    setSeverity(value ? severity : '');
  };

  return (
    <div className="allergy-container">
      <div className="form-container">
        <label>
          Allergy
          <Input
            placeholder="Write down the patient's allergy"
            name="name"
            value={allergy.name}
            onChange={handleNameChange}
          />
        </label>
        <div className="severity-container">
          <label>Choose severity</label>
          <SelectInput
            value={allergy.level as string}
            onChange={handleSeverityChange}
            placeholder="Choose the severity"
            items={SEVERITY_OPTIONS}
            renderItem={renderSeverityItem}
            renderValue={renderSeverityValue}
          />
        </div>
      </div>
      {onRemove && (
        <div className="remove-container">
          <Button
            className="remove-button"
            startIcon={<TrashIcon />}
            onClick={onRemove}
          >
            Remove allergy
          </Button>
        </div>
      )}
    </div>
  );
}

export default AllergyForm;
