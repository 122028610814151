import NewAppointmentIcon from '$assets/svgs/annotation-question.svg?react';
import AppointmentReminderIcon from '$assets/svgs/clock.svg?react';
import AppointmentCancelledIcon from '$assets/svgs/calendar-plus-2.svg?react';
import {UserRoleName} from '$modules/admin/constant';

export enum NotificationType {
  NewAppointment = 'new_booking_request',
  AppointmentReminder = 'appointment_reminder',
  AppointmentCancelled = 'appointment_cancel',
}

export enum NotificationSubTab {
  All = 'all',
  New = NotificationType.NewAppointment,
  PendingRequest = 'pending_request',
  AppointmentCancel = NotificationType.AppointmentCancelled,
}

export const NOTIFICATION_SUB_TABS: {
  label: string;
  value: NotificationSubTab;
  showTo: string;
}[] = [
  {
    label: 'All',
    value: NotificationSubTab.All,
    showTo: 'all',
  },
  {
    label: 'New',
    value: NotificationSubTab.New,
    showTo: UserRoleName.Frontdesk,
  },
  {
    label: 'Pending requests',
    value: NotificationSubTab.PendingRequest,
    showTo: UserRoleName.Frontdesk,
  },
  {
    label: 'Booking cancellation',
    value: NotificationSubTab.AppointmentCancel,
    showTo: 'all',
  },
];

export const notificationIcon: Record<NotificationType, React.ReactNode> = {
  [NotificationType.NewAppointment]: <NewAppointmentIcon />,
  [NotificationType.AppointmentReminder]: <AppointmentReminderIcon />,
  [NotificationType.AppointmentCancelled]: <AppointmentCancelledIcon />,
};
