const FilterOptionSkeleton = () => {
  return (
    <div className="flex w-60 flex-col gap-4 overflow-x-auto mt-4">
      <div className="w-full rounded-xl bg-white p-4 shadow-sm animate-pulse">
        <div className="w-20 h-8 bg-gray-200 rounded-md mb-4"></div>
        <div className="flex flex-col gap-3">
          <div className="w-full h-5 bg-gray-200 rounded-md"></div>
          <div className="w-full h-5 bg-gray-200 rounded-md"></div>
          <div className="w-full h-5 bg-gray-200 rounded-md"></div>
        </div>
        <div className="w-32 h-6 bg-gray-200 rounded-md mt-4 mb-4"></div>
        <div className="flex flex-col gap-3">
          <div className="w-full h-5 bg-gray-200 rounded-md"></div>
          <div className="w-full h-5 bg-gray-200 rounded-md"></div>
          <div className="w-full h-5 bg-gray-200 rounded-md"></div>
          <div className="w-full h-5 bg-gray-200 rounded-md"></div>
        </div>
        <div className="flex justify-between mt-4">
          <div className="w-20 h-7 bg-gray-200 rounded-md"></div>
          <div className="w-20 h-7 bg-gray-200 rounded-md"></div>
        </div>
      </div>
    </div>
  );
};

export default FilterOptionSkeleton;
