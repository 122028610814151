export const RoutePath = {
  indexRoute: '/',
  bookingRoute: '/booking',
  loginRoute: '/login',
  resetPasswordRoute: '/reset-password',
  adminRoute: '/admin',
  deleteAppointmentRoute: '/booking/delete-appointment',
  deleteSuccessRoute: '/booking/delete-success',
  staff: {
    index: '/staff',
    patientList: {
      index: 'patient',
      patientDetails: 'details',
      questionnaire: 'questionnaire',
    },
    calendar: {
      index: 'calendar',
      confirmBooking: 'confirm-booking',
      rescheduleBooking: 'reschedule-booking',
      appointments: 'appointments',
      pendingRequests: 'pending-requests',
      newPatientRequests: 'new-patient-requests',
      bookingDetails: 'booking-details',
      editBooking: 'edit-booking',
    },
    notification: 'notification',
  },
  patient: {
    index: '/patient',
    login: 'login',
    register: 'register',
    forgotPassword: 'forgot-password',
    otpVerification: 'email-verification',
    createPassword: 'create-password',
    onBoarding: {
      index: 'onboarding',
    },
  },
};
