import {getPreferredTime} from '$api/frontdesk';
import {useQuery} from '@tanstack/react-query';

export const usePreferredTimeHook = () => {
  const {isPending, isError, data} = useQuery({
    queryKey: ['preferred-time'],
    queryFn: getPreferredTime,
  });

  return {
    isPending,
    isError,
    data,
  };
};
