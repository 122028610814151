import {useQuery} from '@tanstack/react-query';
import {getPaymentTypes} from '$api/bookingFlow';

export const usePaymentMethodHook = () => {
  const {isPending, isError, error, data} = useQuery({
    queryKey: ['payment-types'],
    queryFn: getPaymentTypes,
  });

  return {isPending, isError, error, data};
};
