import {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useMutation} from '@tanstack/react-query';
import {
  PutResetPasswordVariables,
  PostValidateResetPasswordTokenVariables,
  putResetPassword,
  postValidateResetPasswordToken,
} from '$api/login';
import toast from '$utils/toast';
import {ToastType} from '$components/Toast/constant';
import {handleErrorResponse} from '$utils/axios';
import {RoutePath} from '$constants/routes';

export function useResetPassword() {
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {mutate, isPending} = useMutation({
    mutationKey: ['reset-password'],
    mutationFn: putResetPassword,
    onSuccess: data => {
      navigate(RoutePath.loginRoute);
      toast(ToastType.Success, 'Reset password', data.message);
    },
    onError: error => handleErrorResponse(error, 'Reset password'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendResetPasswordRequest = (variables: PutResetPasswordVariables) => {
    setDisableSubmit(true);
    mutate(variables);
  };

  return {
    disableSubmit,
    isResetPasswordPending: isPending,
    sendResetPasswordRequest,
  };
}

export function useValidateResetPasswordToken() {
  const navigate = useNavigate();

  const {mutate, isPending} = useMutation({
    mutationKey: ['validate-reset-password-token'],
    mutationFn: postValidateResetPasswordToken,
    onError: error =>
      handleErrorResponse(error, 'Validate reset password', () => {
        navigate(RoutePath.loginRoute);
      }),
  });

  const sendValidateResetPasswordToken = useCallback(
    (variables: PostValidateResetPasswordTokenVariables) => {
      mutate(variables);
    },
    [mutate]
  );

  return {
    isValidateResetPasswordTokenPending: isPending,
    sendValidateResetPasswordToken,
  };
}
