export const PATIENT_LIST_PAGE_SIZE = 10;

export enum PatientStatus {
  Active = 'active',
  Archived = 'archived',
  Inactive = 'inactive',
  NewPatient = 'new_patient',
}

export enum LastAppointment {
  Week = 'last_7_days',
  Month = 'last_month',
  Quarter = 'last_quarter',
  Year = 'last_year',
}

export enum PatientListOrderBy {
  Name = 'name',
  UniqueCode = 'number',
  Status = 'status',
  LastAppointment = 'date',
}

export enum PatientListTableHeader {
  Name = 'Name',
  Number = 'Number',
  Status = 'Status',
  LastAppointment = 'Last Appointmetnt',
}

export const headerOrderByMap: Record<
  PatientListTableHeader,
  PatientListOrderBy
> = {
  [PatientListTableHeader.Name]: PatientListOrderBy.Name,
  [PatientListTableHeader.Number]: PatientListOrderBy.UniqueCode,
  [PatientListTableHeader.Status]: PatientListOrderBy.Status,
  [PatientListTableHeader.LastAppointment]: PatientListOrderBy.LastAppointment,
};
