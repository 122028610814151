import {SetStateAction} from 'react';
import {FormikErrors, FormikTouched} from 'formik';
import EyeOPLogo from '$assets/svgs/eye-op.svg?react';
import EyeIcon from '$assets/svgs/eye.svg?react';
import EyeOffIcon from '$assets/svgs/eye-off.svg?react';
import Input from '$components/Input';
import LoginLayout from '../layout';
import {ResetPasswordFormData} from './schema';
import FrontdeskButton from '$components/FrontdeskButton';
import PasswordCheckItem from './PasswordCheckItem';
import {PasswordValidationStage} from '$modules/booking/components/PasswordValidationStepper/types';
import {getPasswordErrors} from '$modules/booking/utils';

interface ResetPasswordComponentProps {
  isResetPasswordPending: boolean;
  disableSubmit: boolean;
  formValues: ResetPasswordFormData;
  formErrors: FormikErrors<ResetPasswordFormData>;
  formTouched: FormikTouched<ResetPasswordFormData>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleSubmit: () => void;
  showPassword: boolean;
  showConfirmPassword: boolean;
  setShowPassword: React.Dispatch<SetStateAction<boolean>>;
  setShowConfirmPassword: React.Dispatch<SetStateAction<boolean>>;
}

function ResetPasswordComponent(props: ResetPasswordComponentProps) {
  const {
    isResetPasswordPending,
    disableSubmit,
    formValues,
    formErrors,
    formTouched,
    showPassword,
    showConfirmPassword,
    handleSubmit,
    handleChange,
    handleBlur,
    setShowPassword,
    setShowConfirmPassword,
  } = props;

  const passwordErrors = getPasswordErrors(formValues.password);
  const stages = Object.values(PasswordValidationStage);

  const passwordCheckItems = stages.map(stage => (
    <PasswordCheckItem
      key={stage}
      text={stage}
      correct={!passwordErrors.has(stage)}
      isNeutral={!formValues.password}
    />
  ));

  return (
    <LoginLayout>
      <div className="mb-8">
        <EyeOPLogo className="h-8 w-8" />
      </div>
      <h1 className="text-primary-color font-semibold text-3xl mb-6">
        Reset your password
      </h1>
      <p className="text-base font-normal mb-6">
        Set up a new password for your account.
      </p>
      <div className="mb-3">
        <p className="mb-3 text-sm">New Password</p>
        <Input
          className="text-sm font-normal"
          type={showPassword ? 'text' : 'password'}
          name="password"
          onBlur={handleBlur}
          onChange={handleChange}
          value={formValues.password}
          placeholder="Enter password"
          endIcon={showPassword ? <EyeIcon /> : <EyeOffIcon />}
          endIconClick={() => setShowPassword(v => !v)}
          touched={formTouched.password}
          error={Boolean(formErrors.password)}
        />
      </div>
      <div className="mb-6">{passwordCheckItems}</div>
      <div className="mb-14">
        <p className="mb-3 text-sm">Confirm New Password</p>
        <Input
          className="text-sm font-normal"
          type={showConfirmPassword ? 'text' : 'password'}
          name="confirmPassword"
          onBlur={handleBlur}
          onChange={handleChange}
          value={formValues.confirmPassword}
          placeholder="Repeat password"
          endIcon={showConfirmPassword ? <EyeIcon /> : <EyeOffIcon />}
          endIconClick={() => setShowConfirmPassword(v => !v)}
          touched={formTouched.confirmPassword}
          error={Boolean(formErrors.confirmPassword)}
          helperText={
            formErrors.confirmPassword && formTouched.confirmPassword
              ? formErrors.confirmPassword
              : ''
          }
        />
      </div>
      <div className="flex flex-col">
        <FrontdeskButton
          title="Set a new password"
          disabled={disableSubmit}
          loading={isResetPasswordPending}
          onClick={handleSubmit}
        />
      </div>
    </LoginLayout>
  );
}

export default ResetPasswordComponent;
