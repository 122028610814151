import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Allergy, AllergyFormData} from '../types';
import {useModifyAllergy} from '../../hooks';
import useAuthUser from '../../../../../../../hooks/useAuthUser';
import AllergyItem from '../AllergyItem';
import DialogComponent from '$components/DialogComponent';
import {XCircleIcon} from '$assets/svgs';
import './styles.scss';
import FrontdeskButton from '$components/FrontdeskButton';

interface AllergyDeleteDialogProps {
  open: boolean;
  onClose: () => void;
  allergyId: number | null;
  allergies: Allergy[];
}

function AllergyDeleteDialog(props: AllergyDeleteDialogProps) {
  const {open, onClose, allergies, allergyId} = props;

  const user = useAuthUser();
  const params = useParams();
  const patientId = Number(params.patientId);

  const [activeAllergy, setActiveAllergy] = useState<Allergy | null>(null);

  const {
    sendModifyAllergyRequest,
    isPending,
    disableSubmit: disableSubmitRequest,
  } = useModifyAllergy(() => onClose());

  const handleDeleteAllergy = () => {
    const updatedAllergies: AllergyFormData[] = allergies.map(al => {
      if (al.id === allergyId) {
        return {
          id: al.id,
          name: al.name,
          level: al.level,
          destroy: true,
        };
      }

      return al;
    });

    sendModifyAllergyRequest({
      hospitalId: user?.hospitalId,
      patientId: patientId,
      allergies: updatedAllergies,
    });
  };

  useEffect(() => {
    const allergy = allergies.find(al => al.id === allergyId);
    if (allergy) setActiveAllergy(allergy);
  }, [allergies, allergyId]);

  return (
    <DialogComponent
      open={open}
      handleClose={onClose}
      headerIcon={<XCircleIcon className="header-icon-container" />}
      dialogBody={
        <div className="confirm-delete-container">
          <h1 className="title">
            Are you sure you want to delete this allergy ?
          </h1>
          <div className="body">
            {activeAllergy && (
              <AllergyItem
                hideActions
                title={activeAllergy.name}
                severity={activeAllergy.level}
              />
            )}
          </div>
          <div className="footer">
            <FrontdeskButton
              title="Cancel"
              disabled={isPending || disableSubmitRequest}
              className="cancel-btn"
              variant="danger"
              onClick={onClose}
            />
            <FrontdeskButton
              className="delete-btn"
              variant="primary"
              title="Delete"
              loading={disableSubmitRequest || isPending}
              disabled={disableSubmitRequest || isPending}
              onClick={handleDeleteAllergy}
            />
          </div>
        </div>
      }
    />
  );
}

export default AllergyDeleteDialog;
