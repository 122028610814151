import NotificationCard from './NotificationCard';
import {Notification} from './types';

interface SectionProps {
  title: string;
  notifications: Notification[];
}

function Section(props: SectionProps) {
  const {title, notifications} = props;

  const notificationCards = notifications.map(notif => (
    <NotificationCard key={notif.id} notification={notif} />
  ));

  return (
    <div className="mb-8">
      <h2 className="border-bottom pb-4">{title}</h2>
      {notificationCards}
    </div>
  );
}

export default Section;
