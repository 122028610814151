import {Divider} from '@mui/material';
import '../styles.scss';

function PatientInfoLoading() {
  return (
    <div
      className="patient-details row-span-3 bg-white"
      data-testid="patient-loading"
    >
      <div className="details-container h-full">
        <div className="patient-avatar animate-pulse">
          <div className="h-16 w-16 aspect-square bg-slate-200 rounded-full" />

          <div className="name">
            <div className="h-6 bg-slate-200 rounded-lg mb-2" />
            <div className="uniq-code">
              <div className="h-4 bg-slate-200 rounded-lg w-1/3" />
            </div>
          </div>
          <div className="field-container">
            <div className="h-4 bg-slate-200 rounded-lg w-2/3 mb-1" />
            <div className="description">
              <div className="h-4 bg-slate-200 rounded-lg w-1/3" />
            </div>
          </div>
        </div>
        <Divider orientation="vertical" className="divider mx-10" />
        <div className="patient-info animate-pulse">
          <div className="first-column">
            <div className="mb-8">
              <div className="h-4 bg-slate-200 rounded-lg w-2/3 mb-2" />
              <div className="h-4 bg-slate-200 rounded-lg w-1/3" />
            </div>
            <div className="mb-8">
              <div className="h-4 bg-slate-200 rounded-lg w-2/3 mb-2" />
              <div className="h-4 bg-slate-200 rounded-lg w-1/3" />
            </div>
            <div className="mb-8">
              <div className="h-4 bg-slate-200 rounded-lg w-2/3 mb-2" />
              <div className="h-4 bg-slate-200 rounded-lg w-1/3" />
            </div>
            <div className="mb-8">
              <div className="h-4 bg-slate-200 rounded-lg w-2/3 mb-2" />
              <div className="h-4 bg-slate-200 rounded-lg w-1/3" />
            </div>
          </div>
          <div className="second-column">
            <div className="mb-8">
              <div className="h-4 bg-slate-200 rounded-lg w-2/3 mb-2" />
              <div className="h-4 bg-slate-200 rounded-lg w-1/3" />
            </div>
            <div className="mb-8">
              <div className="h-4 bg-slate-200 rounded-lg w-2/3 mb-2" />
              <div className="h-4 bg-slate-200 rounded-lg w-1/3" />
            </div>
            <div className="mb-8">
              <div className="h-4 bg-slate-200 rounded-lg w-2/3 mb-2" />
              <div className="h-4 bg-slate-200 rounded-lg w-1/3" />
            </div>
            <div className="mb-8">
              <div className="h-4 bg-slate-200 rounded-lg w-2/3 mb-2" />
              <div className="h-4 bg-slate-200 rounded-lg w-1/3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientInfoLoading;
