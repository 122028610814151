import {useId} from 'react';
import {Updater} from 'use-immer';
import {Menu} from '@mui/material';
import FrontdeskButton from '$components/FrontdeskButton';
import FilterFunnelIcon from '$assets/svgs/filter-funnel.svg?react';
import './styles.scss';
import CheckBox from '$components/CheckBox';
import {FilterOptions} from '$modules/frontdesk/type';
import {parseSnakeCase} from '$modules/frontdesk/utils';

interface CalendarFilterMenuComponentProps {
  filterOptions: FilterOptions;
  selectedFilterOptions: FilterOptions;
  setSelectedFilterOptions: Updater<FilterOptions>;
  anchorElement: HTMLElement | null;
  onFilterClick: React.MouseEventHandler<HTMLButtonElement>;
  onMenuClose: () => void;
  onReset: () => void;
  onSave: () => void;
}

function CalendarFilterMenuComponent(props: CalendarFilterMenuComponentProps) {
  const {
    anchorElement,
    filterOptions,
    selectedFilterOptions,
    setSelectedFilterOptions,
    onFilterClick,
    onMenuClose,
    onReset,
    onSave,
  } = props;
  const menuId = useId();

  const isMenuOpen = anchorElement !== null;

  const renderListItem = <T extends keyof FilterOptions>(
    title: T,
    id: number,
    name: string,
    option: FilterOptions[T][number]
  ) => {
    const itemIndex = selectedFilterOptions[title].findIndex(
      opt => opt.id === id
    );
    const checked = itemIndex !== -1;

    const handleClick = () => {
      if (checked) {
        setSelectedFilterOptions(state => {
          state[title].splice(itemIndex, 1);
        });
      } else {
        setSelectedFilterOptions(state => {
          const array = state[title] as (typeof option)[];
          array.push(option);
        });
      }
    };

    return (
      <li
        key={title + id}
        className="list-item-container"
        onClick={handleClick}
      >
        <span className="list-item-text">
          {title === 'status' ? parseSnakeCase(name) : name}
        </span>
        <span>
          <CheckBox checked={checked} />
        </span>
      </li>
    );
  };

  const statusItems = filterOptions.status.map(opt =>
    renderListItem('status', opt.id, opt.name, opt)
  );

  const appointmentTypeItems = filterOptions.appointment_type.map(opt =>
    renderListItem('appointment_type', opt.id, opt.title, opt)
  );

  const filterCount =
    selectedFilterOptions.consultant.length +
    selectedFilterOptions.appointment_type.length +
    selectedFilterOptions.status.length;

  const applyButtonTitle = filterCount ? `Apply (${filterCount})` : 'Apply';

  return (
    <div>
      <FrontdeskButton
        variant="secondary"
        title="Filter"
        aria-controls={isMenuOpen ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        startIcon={<FilterFunnelIcon />}
        onClick={onFilterClick}
      />
      <Menu
        className="filter-menu-container"
        id={menuId}
        open={isMenuOpen}
        anchorEl={anchorElement}
        onClose={onMenuClose}
      >
        <h3 className="heading">Filter</h3>
        <h4 className="sub-heading">Status</h4>
        {statusItems}
        <h4 className="sub-heading">Appointment Type</h4>
        {appointmentTypeItems}
        <div className="action-button-container">
          <FrontdeskButton
            title="Reset"
            variant="tertiary-filled"
            onClick={onReset}
          />
          <FrontdeskButton title={applyButtonTitle} onClick={onSave} />
        </div>
      </Menu>
    </div>
  );
}

export default CalendarFilterMenuComponent;
