export enum TimelineTab {
  Consultation = 'consultation',
  Test = 'test',
}

export const TIMELINE_TAB_LIST: {label: string; value: TimelineTab}[] = [
  {
    label: 'Consultation',
    value: TimelineTab.Consultation,
  },
  {
    label: 'Test',
    value: TimelineTab.Test,
  },
];
