import {useQuery} from '@tanstack/react-query';
import {
  GetPatientInfoListParams,
  getPatientInfoList,
} from '$api/patientList/patientListView';

export function usePatientInfoList(params: GetPatientInfoListParams) {
  const {search, status, orderBy, pageNumber, orderDirection, lastAppointment} =
    params;

  const {data, isError, isLoading, isRefetching} = useQuery({
    queryKey: [
      'patient-info-list',
      search,
      status,
      orderBy,
      pageNumber,
      orderDirection,
      lastAppointment,
    ],
    queryFn: () => getPatientInfoList(params),
  });

  return {
    isLoading: isLoading || isRefetching,
    isError,
    message: data?.message || 'Could not get patients list!',
    patients: data?.patients || [],
    totalPages: data?.totalPages || 0,
    totalRecords: data?.totalRecords || 0,
  };
}
