import {SetStateAction} from 'react';
import {OfflineBookingStep} from '../constant';
import {FormState} from '../types';
import {TITLE_MAP} from './constant';
import {Appointment} from '~/modules/booking/types';
import './styles.scss';

interface AppointmentTypeSelectionComponentProps {
  appointmentType: Appointment[];
  setActiveStep: React.Dispatch<SetStateAction<OfflineBookingStep>>;
  offlineBookingFormData: FormState;
  setOfflineBookingFormData: React.Dispatch<React.SetStateAction<FormState>>;
  offlineBookingType: string;
}

const AppointmentTypeSelectionComponent: React.FC<
  AppointmentTypeSelectionComponentProps
> = ({
  appointmentType,
  setActiveStep,
  offlineBookingFormData,
  setOfflineBookingFormData,
  offlineBookingType,
}) => {
  const appointmentTypeId =
    offlineBookingFormData.appointment.appointment_type_id;

  if (offlineBookingType === 'new') {
    appointmentType = appointmentType.filter(
      ele => ele.displayName === 'Consultation'
    );
  }

  const handleClick = (appointmentTypeId: number) => {
    setOfflineBookingFormData((prevState: FormState) => ({
      appointment: {
        ...prevState.appointment,
        appointment_type_id: appointmentTypeId,
      },
    }));
    setActiveStep(OfflineBookingStep.ConsultationSelection);
  };

  return (
    <>
      <h2 className="dialog-title">{TITLE_MAP[offlineBookingType]}</h2>
      <div className="appointment-type-selection-container">
        {appointmentType &&
          appointmentType.map(
            appointment =>
              appointment.displayName !== 'Follow-up appointment' && (
                <div
                  className={`${
                    appointmentTypeId === appointment.id
                      ? 'appointment-selected '
                      : ''
                  } appointment-card`}
                  key={appointment.id}
                  onClick={() => handleClick(appointment.id)}
                >
                  <span className="appointment-card-text">
                    {appointment.displayName}
                  </span>
                </div>
              )
          )}
      </div>
    </>
  );
};

export default AppointmentTypeSelectionComponent;
