import {SetStateAction, useCallback} from 'react';
import {useFormik} from 'formik';
import {ArrowNarrowRightIcon} from '$assets/svgs';
import FrontdeskButton from '$components/FrontdeskButton';
import {
  PatientContactDetailsFormData,
  patientContactDetailFormSchema,
} from './schema';
import ContactDetailForm from './component';
import {FormState} from '../../types';
import './styles.scss';
import {Button} from '@mui/material';
import {OfflineBookingStep} from '../../constant';

interface ContactDetailContainerProps {
  setActiveStep: React.Dispatch<SetStateAction<OfflineBookingStep>>;
  offlineBookingFormData: FormState;
  setOfflineBookingFormData: React.Dispatch<React.SetStateAction<FormState>>;
}
const ContactDetailsContainer: React.FC<ContactDetailContainerProps> = ({
  setActiveStep,
  offlineBookingFormData,
  setOfflineBookingFormData,
}) => {
  const contactEmail = offlineBookingFormData.appointment.contact_email;
  const contactPhoneNumber =
    offlineBookingFormData.appointment.contact_mobile_number;
  const preferredPointOfContact =
    offlineBookingFormData.appointment.preferred_point_of_contact;

  const handleSubmit = () => {
    const contactEmail = formik.values.contactEmail;
    const contactPhoneNumber = formik.values.contactPhoneNumber;
    const preferredPointOfContact = formik.values.preferredPointOfContact;
    setOfflineBookingFormData((prevState: FormState) => ({
      appointment: {
        ...prevState.appointment,
        contact_email: contactEmail.trim(),
        contact_mobile_number: contactPhoneNumber.toString(),
        preferred_point_of_contact: preferredPointOfContact,
      },
    }));

    setActiveStep(OfflineBookingStep.AppointmentTypeSelection);
  };
  const formik = useFormik<PatientContactDetailsFormData>({
    initialValues: {
      contactEmail: contactEmail || '',
      contactPhoneNumber: +contactPhoneNumber || NaN,
      preferredPointOfContact: preferredPointOfContact || '',
    },
    validateOnChange: true,
    validationSchema: patientContactDetailFormSchema,
    onSubmit: handleSubmit,
  });

  const {setFieldTouched, setFieldValue} = formik;

  const handleFieldChange = useCallback(
    (field: string, defaultValue: unknown) => {
      return (value: unknown) => {
        setFieldTouched(field);
        setFieldValue(field, value || defaultValue);
      };
    },
    [setFieldTouched, setFieldValue]
  );

  const handleBackButtonClick = () => {
    setActiveStep(OfflineBookingStep.PatientDetailsTwo);
  };

  const handleContinueClick = () => formik.submitForm();

  return (
    <div className="contact-details-container">
      <ContactDetailForm
        offlineBookingFormData={offlineBookingFormData}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        handleFieldChange={handleFieldChange}
      />
      <div className="contact-details-dialog-footer">
        <Button className="back-btn" onClick={handleBackButtonClick}>
          Back
        </Button>
        <FrontdeskButton
          title="Continue"
          className="contact-details-continue-btn"
          endIcon={<ArrowNarrowRightIcon />}
          onClick={handleContinueClick}
        />
      </div>
    </div>
  );
};

export default ContactDetailsContainer;
