import {LastAppointment, PatientListOrderBy, PatientStatus} from '../constant';
import {PatientInfo} from '../types';
import PatientListTableComponent from './component';

interface PatientListTableProps {
  isLoading: boolean;
  isError: boolean;
  message: string;
  archivedOnly: boolean;
  patients: PatientInfo[];
  sortActiveColumn?: string;
  sortDirection?: boolean;
  patientStatus: PatientStatus[];
  lastAppointment?: LastAppointment;
  setPatientStatus: (value: PatientStatus[]) => void;
  setLastAppointment: (value?: LastAppointment) => void;
  setSortActiveColumn: (value?: PatientListOrderBy) => void;
  setSortDirection: React.Dispatch<React.SetStateAction<boolean>>;
}

function PatientListTable(props: PatientListTableProps) {
  const {
    isLoading,
    isError,
    message,
    archivedOnly,
    patients,
    sortActiveColumn,
    sortDirection,
    patientStatus,
    lastAppointment,
    setPatientStatus,
    setLastAppointment,
    setSortActiveColumn,
    setSortDirection,
  } = props;

  return (
    <PatientListTableComponent
      isLoading={isLoading}
      isError={isError}
      message={message}
      archivedOnly={archivedOnly}
      patients={patients}
      sortActiveColumn={sortActiveColumn}
      sortDirection={sortDirection}
      patientStatus={patientStatus}
      lastAppointment={lastAppointment}
      setPatientStatus={setPatientStatus}
      setLastAppointment={setLastAppointment}
      setSortActiveColumn={setSortActiveColumn}
      setSortDirection={setSortDirection}
    />
  );
}

export default PatientListTable;
