import React from 'react';
import UserCircleIcon from '$assets/svgs/user-circle.svg?react';
import {PencilIcon, LinkIcon} from '$assets/svgs';
import {dateToDDMMYYYY} from '$utils/date';
import {IconButton, Divider, Tooltip} from '@mui/material';
import {downloadAttachment} from '../../Notes/utils';
import {CreatedBy} from '../../Notes/types';
import '../styles.scss';

interface NotesOverviewCardProps {
  id: number;
  title: string;
  description: string;
  createdBy: CreatedBy;
  createdAt: Date;
  documentUrl: string;
  handleEditNote: (noteId: number) => void;
}
const NotesOverViewCard: React.FC<NotesOverviewCardProps> = ({
  id,
  title,
  description,
  createdAt,
  createdBy,
  documentUrl,
  handleEditNote,
}) => {
  return (
    <div className="notes-card">
      <div className="notes-body">
        <div className="notes-details">
          <div className="title-container gap-1">
            <IconButton onClick={() => handleEditNote(id)}>
              <PencilIcon className="h-4 w-4 primary" />
            </IconButton>
            <div className="title">{title}</div>
          </div>
          <div className="description">{description}</div>
        </div>
        <Divider orientation="vertical" />
        <div className="notes-creator-details">
          <div className="creator-avatar">
            <UserCircleIcon className="primary h-8 w-8 aspect-square" />
            <div className="creator-avatar-name">{createdBy.fullName}</div>
          </div>
          <div className="date">{dateToDDMMYYYY(createdAt)}</div>
          <div className="notes-creator-icon-container">
            <IconButton onClick={() => handleEditNote(id)}>
              <PencilIcon className="h-4 w-4 primary" />
            </IconButton>
            {documentUrl && (
              <Tooltip title="Download Attachment">
                <IconButton
                  onClick={() => downloadAttachment(documentUrl, 'attachment')}
                >
                  <LinkIcon className="h-4 w-4 primary" />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesOverViewCard;
