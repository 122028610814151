import {useFormik} from 'formik';
import {useState} from 'react';
import {Box, Stack, Typography} from '@mui/material';
import BookingStepper from '../components/BookingStepper';
import Footer from '../components/Footer';
import CheckBox from '$components/CheckBox';
import {
  PatientContactDetailsFormData,
  patientContactDetailFormSchema,
  patientContactDetailRegisteredFormSchema,
} from '../components/PatientContactDetailForm/schema';
import PatientContactDetailForm from '../components/PatientContactDetailForm';
import {
  BookingDataUpdater,
  BookingStepperStage,
  BookingView,
  PatientContactDetailViewChoices,
} from '../types';
import {PATIENT_CONTACT_DETAIL_CHECKBOX_LABEL} from '../constant';
import styles, {patientContactDetailViewStyles} from '../styles';
import {useValidateUnqEmail} from '../validateEmailHook';

interface PatientContactDetailViewProps {
  bookingChoices: PatientContactDetailViewChoices;
  setActiveView: (view: BookingView) => void;
  setBookingChoices: BookingDataUpdater;
}

export default function PatientContactDetailView(
  props: PatientContactDetailViewProps
) {
  const {bookingChoices, setActiveView, setBookingChoices} = props;
  const [consentChecked, setConsentChecked] = useState<boolean>(false);

  const {disableSubmit, isPending} = useValidateUnqEmail(() => {
    setActiveView(BookingView.PatientInsuranceDetailView);
  });

  const handleSubmit = (values: PatientContactDetailsFormData) => {
    // When a new patient verifies their email, a new patient record is created using that email.
    // Therefore, there's no need to check the uniqueness of the email at this stage, as it would show that the email is already taken.

    // if (!bookingChoices.registered.email && values.patient.email) {
    //   verifyEmail({
    //     patient: {
    //       email: values.patient.email,
    //     },
    //   });
    // }

    setBookingChoices(draft => {
      draft.patientContactDetail = values;
    });
    setActiveView(BookingView.PatientInsuranceDetailView);
  };

  const formik = useFormik<PatientContactDetailsFormData>({
    initialValues: bookingChoices.patientContactDetail || {
      patient: {
        email: bookingChoices.registered.email || '',
        confirmEmail: '',
        phoneNumber: NaN,
        preferredContactMethod: '',
      },
    },
    validationSchema: bookingChoices.registered.email
      ? patientContactDetailRegisteredFormSchema
      : patientContactDetailFormSchema,
    onSubmit: handleSubmit,
  });

  const handleBackClick = () =>
    setActiveView(BookingView.GPOrOpticianReferralView);

  const handleConsentCheckboxChange = () => {
    setConsentChecked(prevState => !prevState);
  };

  const handleContinueClick = () => {
    formik.submitForm();
  };

  const disableContinueButton = !consentChecked;

  return (
    <Box sx={styles.sectionWrapperWithStepper} flexDirection="column" gap={5}>
      <Stack gap={3} sx={patientContactDetailViewStyles.section}>
        <Box>
          <BookingStepper activeStage={BookingStepperStage.PatientDetails} />
        </Box>
        <Stack gap={1}>
          <Typography variant="h2" sx={styles.subHeading}>
            Personal details
          </Typography>
          <Typography variant="body1">
            Please fill in the details below to continue with the booking
            process
          </Typography>
        </Stack>
        <PatientContactDetailForm
          bookingChoices={props.bookingChoices}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          setFieldTouched={formik.setFieldTouched}
          setFieldValue={formik.setFieldValue}
        />
        <Box>
          <CheckBox
            checked={consentChecked}
            onChange={handleConsentCheckboxChange}
            label={PATIENT_CONTACT_DETAIL_CHECKBOX_LABEL}
          />
        </Box>
      </Stack>
      <Box sx={styles.footerSection}>
        <Footer
          disableBack={disableSubmit}
          disableContinue={disableContinueButton}
          continueLoading={isPending}
          onBackClick={handleBackClick}
          onContinueClick={handleContinueClick}
        />
      </Box>
    </Box>
  );
}
