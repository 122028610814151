import {useFormik} from 'formik';
import {Box, Stack, Typography} from '@mui/material';
import BookingStepper from '../components/BookingStepper';
import Footer from '../components/Footer';
import {
  BookingDataUpdater,
  BookingStepperStage,
  BookingView,
  PatientInsuranceDetailViewChoices,
} from '../types';
import styles, {patientContactDetailViewStyles} from '../styles';
import {
  PatientInsuranceDetailFormData,
  patientInsuranceDetailFormSchema,
} from '../components/PatientInsuranceDetailForm/schema';
import PatientInsuranceDetailForm from '../components/PatientInsuranceDetailForm';
import AppointmentSummaryTile from '../components/AppointmentSummaryTile';
import {useAppointment} from '../hooks';

interface PatientInsuranceDetailViewProps {
  bookingChoices: PatientInsuranceDetailViewChoices;
  setActiveView: (view: BookingView) => void;
  setBookingChoices: BookingDataUpdater;
}

export default function PatientInsuranceDetailView(
  props: PatientInsuranceDetailViewProps
) {
  const {bookingChoices, setActiveView, setBookingChoices} = props;

  const {disableSubmit, isPending, saveAppointment} = useAppointment(
    bookingChoices,
    setActiveView,
    setBookingChoices
  );

  const handleSubmit = (values: PatientInsuranceDetailFormData) => {
    setBookingChoices(draft => {
      draft.patientInsuranceDetail = values;
    });

    if (!bookingChoices.registered.isRegistered) {
      setActiveView(BookingView.LastStepView);
    } else {
      saveAppointment({patientInsuranceDetail: values});
    }
  };

  const formik = useFormik<PatientInsuranceDetailFormData>({
    initialValues: bookingChoices.patientInsuranceDetail || {
      selfPaying: false,
      insuranceId: NaN,
      paymentMode: NaN,
      membershipNumber: '',
    },
    validationSchema: patientInsuranceDetailFormSchema,
    onSubmit: handleSubmit,
  });

  const handleBackClick = () =>
    setActiveView(BookingView.PatientContactDetailView);

  const handleContinueClick = () => {
    formik.submitForm();
  };

  return (
    <Box sx={styles.sectionWrapperWithStepper} flexDirection="column" gap={5}>
      <Stack gap={3} sx={patientContactDetailViewStyles.section}>
        <Box>
          <BookingStepper activeStage={BookingStepperStage.Insurance} />
        </Box>
        <Stack gap={1}>
          <Typography variant="h2" sx={styles.subHeading}>
            Personal details
          </Typography>
          <Typography variant="body1">
            Please fill in the details below to continue with the booking
            process
          </Typography>
        </Stack>
        <PatientInsuranceDetailForm
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          setFieldTouched={formik.setFieldTouched}
          setFieldValue={formik.setFieldValue}
        />
      </Stack>
      <Box sx={styles.footerSection}>
        <AppointmentSummaryTile choices={bookingChoices} />
        <Footer
          disableContinue={
            disableSubmit ||
            (!formik.values.insuranceId && !formik.values.selfPaying)
          }
          continueButtonLabel={
            !bookingChoices.registered?.isRegistered
              ? ''
              : 'Submit booking request'
          }
          onBackClick={handleBackClick}
          continueLoading={isPending}
          onContinueClick={handleContinueClick}
        />
      </Box>
    </Box>
  );
}
