import {FormikErrors, FormikTouched} from 'formik';
import {MenuItem, Typography} from '@mui/material';
import FrontdeskButton from '$components/FrontdeskButton';
import Input from '$components/Input';
import SelectInput from '$components/SelectInput';
import PhoneNumberInput from '$modules/booking/components/PhoneNumberInput';
import {USER_ROLES, UserRole, UserRoleName} from './constant';
import {UserFormData} from './schema';
import {UserFormDataStringField} from './types';

interface UserFormProps {
  disableSubmit: boolean;
  isCreateUserLoading: boolean;
  formValues: UserFormData;
  formTouched: FormikTouched<UserFormData>;
  formErrors: FormikErrors<UserFormData>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleClearForm: () => void;
  handleSubmit: () => void;
  handleRoleIdChange: (value: string) => void;
}

const userFormFields = [
  {name: 'firstName', title: 'First name'},
  {name: 'lastName', title: 'Last name'},
  {name: 'email', title: 'Email'},
];

function UserForm(props: UserFormProps) {
  const {
    disableSubmit,
    isCreateUserLoading,
    formValues,
    formTouched,
    formErrors,
    handleClearForm,
    handleBlur,
    handleChange,
    handleSubmit,
    handleRoleIdChange,
  } = props;

  const renderRoleItem = (item: UserRole) => {
    return (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    );
  };

  const renderRoleValue = (value: string) => {
    let title = 'Role';
    const item = USER_ROLES.find(it => it.id.toString() === value);

    if (item) title = item.name;

    return (
      <Typography
        className={`text-tertiary-color ${value === '' ? 'opacity-40' : ''}`}
      >
        {title}
      </Typography>
    );
  };

  const formFields = userFormFields.map(field => {
    const name = field.name as UserFormDataStringField;
    const title = field.title as UserFormDataStringField;

    return (
      <div key={name} className="mb-2 w-full md:w-1/2 px-2">
        <span className="text-sm font-normal">{title}</span>
        <Input
          name={name}
          value={formValues[name]}
          touched={formTouched[name]}
          error={Boolean(formErrors[name])}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={title}
          helperText={
            formErrors[name] && formTouched[name] ? formErrors[name] : ''
          }
        />
      </div>
    );
  });

  const userRoles = USER_ROLES.filter(ur => ur.name !== UserRoleName.Admin);

  return (
    <div className="rounded-lg bg-white p-4 mb-2 flex flex-col">
      <h2 className="text-sm px-2 font-bold mb-2">Create user</h2>
      <div className="flex flex-wrap mb-2">
        {formFields}
        <div className="mb-2 w-full md:w-1/2 px-2 flex flex-col">
          <p className="text-sm font-normal">Role</p>
          <SelectInput
            name="roleId"
            renderValue={renderRoleValue}
            renderItem={renderRoleItem}
            items={userRoles}
            error={formTouched.roleId && Boolean(formErrors.roleId)}
            value={String(formValues.roleId)}
            placeholder="Role"
            onBlur={handleBlur}
            onChange={handleRoleIdChange}
          />
          {formTouched?.roleId && formErrors?.roleId && (
            <p className="text-xs text-error-color mt-2">
              {formErrors?.roleId}
            </p>
          )}
        </div>
        <div className="mb-2 w-full md:w-1/2 px-2 flex flex-col">
          <p className="text-sm font-normal">Contact Number</p>
          <PhoneNumberInput
            name="phoneNumber"
            placeholder="Phone number (optional)"
            value={formValues.phoneNumber || NaN}
            error={formTouched.phoneNumber && Boolean(formErrors.phoneNumber)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {formTouched?.phoneNumber && formErrors?.phoneNumber && (
            <p className="text-xs text-error-color mt-2">
              {formErrors?.phoneNumber}
            </p>
          )}
        </div>
      </div>
      <div className="flex gap-4 justify-center">
        <div className="flex-1 md:flex-none md:w-full md:max-w-56 flex flex-col">
          <FrontdeskButton
            variant="secondary"
            title="Clear"
            onClick={handleClearForm}
            disabled={disableSubmit}
            loading={isCreateUserLoading}
          />
        </div>
        <div className="flex-1 md:flex-none md:w-full md:max-w-56 flex flex-col">
          <FrontdeskButton
            title="Save"
            onClick={handleSubmit}
            disabled={disableSubmit}
            loading={isCreateUserLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default UserForm;
