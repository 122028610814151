import {useState} from 'react';
import {Box, Typography} from '@mui/material';
import {FormikErrors, FormikTouched} from 'formik';
import EyeIcon from '$assets/svgs/eye.svg?react';
import EyeOffIcon from '$assets/svgs/eye-off.svg?react';
import Input from '$components/Input';
import {emailSchema, EnterSystemFormData} from './schema';
import {registerWithStoneyGateViewStyles} from '../../styles';
import {PutForgotPasswordVariables} from '$api/bookingFlow';

interface EnterSystemFormProps {
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  values: EnterSystemFormData;
  errors: FormikErrors<EnterSystemFormData>;
  touched: FormikTouched<EnterSystemFormData>;
  setTouched: (touched: FormikTouched<EnterSystemFormData>) => void;
  setFieldTouched: (field: string, isTouched?: boolean) => void;
  sendForgotPasswordRequest: (variables: PutForgotPasswordVariables) => void;
}

function EnterSystemForm(props: EnterSystemFormProps) {
  const {
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
    setTouched,
    setFieldTouched,
    sendForgotPasswordRequest,
  } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleForgotPassword = () => {
    setTouched({});
    setFieldTouched('email');

    const {email} = values;

    try {
      emailSchema.validateSync(email);
      sendForgotPasswordRequest({email});
    } catch {
      setFieldTouched('email', true);
    }
  };

  return (
    <form>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Box sx={registerWithStoneyGateViewStyles.inputContainer}>
          <Input
            name="email"
            placeholder="Email address"
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.email}
            error={Boolean(errors.email)}
            helperText={errors.email && touched.email ? errors.email : ''}
          />
          <Typography variant="caption">
            Enter the email address you previously used on this platform
          </Typography>
        </Box>
        <Box sx={registerWithStoneyGateViewStyles.inputContainer}>
          <Input
            name="password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            endIcon={showPassword ? <EyeIcon /> : <EyeOffIcon />}
            endIconClick={handleClickShowPassword}
            value={values.password}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.password}
            error={Boolean(errors.password)}
            helperText={
              errors.password && touched.password ? errors.password : ''
            }
          />
          <Typography variant="caption">
            Enter the password you received via SMS or email
          </Typography>
          <Box>
            <Typography
              sx={registerWithStoneyGateViewStyles.forgotPasswordText}
              variant="caption"
              onClick={handleForgotPassword}
            >
              Forgot password?
            </Typography>
          </Box>
        </Box>
      </Box>
    </form>
  );
}

export default EnterSystemForm;
