import React from 'react';
import QuestionnaireHeader from '../QuestionnaireForm/QuestionnaireHeader';
import QuestionnaireTitle from '../QuestionnaireForm/QuestionnaireTitle';
import QuestionnaireForm from '../QuestionnaireForm/QuestionnaireForm';
import QuestionnaireFooter from '../QuestionnaireForm/QuestionnaireFooter';
import {CommonQuestionnaireComponentProps} from '../types';
import '../styles.scss';

const FamilyQuestionnaireComponent: React.FC<
  CommonQuestionnaireComponentProps
> = ({
  formik,
  startIndex,
  totalPages,
  questionsToDisplay,
  currentPage,
  handlePageChange,
  handleBack,
  handleFormSubmit,
  disableSubmit,
}) => {
  return (
    <div className="flex flex-col flex-1 bg-primary font-primary">
      <QuestionnaireHeader
        handleBack={handleBack}
        data-testid="family-questionnaire-header"
      />
      <div className="flex-1 overflow-y-auto px-10 pt-2">
        <QuestionnaireTitle
          title="Family history questionnaire"
          className="w-auto text-2xl font-semibold leading-7 tracking-tight font-inter text-primary-dark text-left mb-2"
          data-testid="family-questionnaire-title"
        />
        <QuestionnaireForm
          formik={formik}
          startIndex={startIndex}
          questionsToDisplay={questionsToDisplay}
          questionContainerClassName="flex-1 rounded-lg my-6 ml-5"
          questionClassName="font-inter font-semibold text-xl text-grey-dark leading-7 tracking-tight"
          data-testid="family-questionnaire-form"
        />
      </div>
      <QuestionnaireFooter
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        handleSubmit={handleFormSubmit}
        isFormValid={formik.isValid}
        disableSubmit={disableSubmit}
        data-testid="family-questionnaire-footer"
      />
    </div>
  );
};

export default FamilyQuestionnaireComponent;
