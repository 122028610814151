import React from 'react';
import CheckBox from '$components/CheckBox';
import {Box, Button, Stack, Typography} from '@mui/material';
import {FilterOptions} from '$modules/frontdesk/type';
import {
  applyButton,
  filterOptions,
  filterOptionsButtonContainer,
  filterOptionsContainer,
  resetButton,
} from './styles';
import {parseSnakeCase} from '$modules/frontdesk/utils';

interface FilterOptionsComponentProps {
  containStatus: boolean;
  containAppointmentTypes: boolean;
  containConsultants: boolean;
  data: FilterOptions;
  filters: {
    status?: string[];
    appointmentTypes: number[];
    consultants: number[];
  };
  onFilterChange: (
    type: 'status' | 'appointmentTypes' | 'consultants',
    id: number | string
  ) => void;
  onApply: () => void;
  onReset: () => void;
}

interface FilterItemProps {
  id: string | number;
  label: string;
  checked: boolean;
  onChange: () => void;
}

const FilterItem: React.FC<FilterItemProps> = ({
  id,
  label,
  checked,
  onChange,
}) => (
  <Box key={String(id)} sx={filterOptions}>
    <Typography variant="filterItems">{label}</Typography>
    <CheckBox checked={checked} onChange={onChange} />
  </Box>
);

const FilterOptionsComponent: React.FC<FilterOptionsComponentProps> = ({
  data,
  containAppointmentTypes,
  containConsultants,
  containStatus,
  filters,
  onFilterChange,
  onApply,
  onReset,
}) => {
  const filterCount =
    (containStatus ? filters.status?.length ?? 0 : 0) +
    (containAppointmentTypes ? filters.appointmentTypes.length : 0) +
    (containConsultants ? filters.consultants.length : 0);

  return (
    <Box sx={filterOptionsContainer}>
      <Typography variant="h3">Filter</Typography>
      {containStatus && (
        <>
          <Typography variant="filterTitle">Status</Typography>
          {data.status.map(status => (
            <FilterItem
              key={status.name}
              id={status.name}
              label={parseSnakeCase(status.name)}
              checked={filters.status?.includes(status.name) || false}
              onChange={() => onFilterChange('status', status.name)}
            />
          ))}
        </>
      )}
      {containAppointmentTypes && (
        <>
          <Typography variant="filterTitle">Appointment type</Typography>
          {data.appointment_type.map(appointment => (
            <FilterItem
              key={appointment.id}
              id={appointment.id}
              label={appointment.display_name}
              checked={filters.appointmentTypes.includes(appointment.id)}
              onChange={() =>
                onFilterChange('appointmentTypes', appointment.id)
              }
            />
          ))}
        </>
      )}
      {containConsultants && (
        <>
          <Typography variant="filterTitle">Consultant</Typography>
          <Box>
            {data.consultant.map(consultant => (
              <FilterItem
                key={consultant.id}
                id={consultant.id}
                label={consultant.full_name}
                checked={filters.consultants.includes(consultant.id)}
                onChange={() => onFilterChange('consultants', consultant.id)}
              />
            ))}
          </Box>
        </>
      )}
      <Stack sx={filterOptionsButtonContainer}>
        <Button variant="outlined" sx={resetButton} onClick={onReset}>
          Reset
        </Button>
        <Button variant="contained" onClick={onApply} sx={applyButton}>
          Apply({filterCount})
        </Button>
      </Stack>
    </Box>
  );
};

export default FilterOptionsComponent;
