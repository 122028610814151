import {eoCard} from '$modules/frontdesk/styles';
import {Box, Stack, Typography} from '@mui/material';
import {AppointmentDetailIcon} from '$assets/svgs';
import {dateToDDMMYYYY, dateToTimeShort} from '$utils/date';

interface AppointmentDetails {
  appointment_type_title: string;
  care_type_title: string;
  sub_care_type_title: string;
  preferred_time: string;
  doctor_full_name: string;
  date: string;
  start_time: string;
}

const AppointmentDetails = (details: AppointmentDetails) => {
  const {
    care_type_title,
    sub_care_type_title,
    doctor_full_name,
    date,
    start_time,
  } = details;

  const gradientText = {
    background: '-webkit-linear-gradient(180deg, #7625DC, #6063F2)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };

  return (
    <Box sx={{...eoCard, height: '100%'}}>
      <Stack spacing={2}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <AppointmentDetailIcon />
          <Typography variant="h4" style={gradientText}>
            Appointment Details
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1" sx={{mb: 1}}>
            Requested date and time
          </Typography>
          <Typography variant="subtitle2">
            {`${dateToDDMMYYYY(date)} - ${
              start_time ? dateToTimeShort(new Date(start_time)) : 'All time'
            }`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1" sx={{mb: 1}}>
            Appointment type
          </Typography>
          <Typography variant="subtitle2">
            {`${care_type_title} - ${sub_care_type_title}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1" sx={{mb: 1}}>
            Consultant
          </Typography>
          <Typography variant="subtitle2">{doctor_full_name}</Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default AppointmentDetails;
