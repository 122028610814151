import {object, number, string, InferType} from 'yup';
import {ValidationMessage} from '$constants/messages';

export const patientContactDetailFormSchema = object({
  preferredPointOfContact: string().required(
    ValidationMessage.PreferredContactMethodRequired
  ),
  contactEmail: string()
    .trim()
    .email(ValidationMessage.EmailInvalid)
    .max(350, ValidationMessage.EmailExceedCharacterLimit)
    .required(ValidationMessage.EmailRequired),
  contactPhoneNumber: number()
    .typeError(ValidationMessage.PhoneNumberInvalid)
    .positive(ValidationMessage.PhoneNumberInvalid)
    .integer(ValidationMessage.PhoneNumberInvalid)
    .min(10_000_00, ValidationMessage.PhoneNumberInvalid)
    .max(999_999_999_999_999, ValidationMessage.PhoneNumberInvalid)
    .required(ValidationMessage.PhoneNumberRequired),
});

export type PatientContactDetailsFormData = InferType<
  typeof patientContactDetailFormSchema
>;
