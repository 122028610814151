import {SxProps} from '@mui/material';

const styles: Record<string, SxProps> = {
  container: {
    height: '32px',
    borderRadius: '8px',
    mr: 3,
    '& .MuiChip-label': {
      boxShadow: '0px 4px 8px 4px #10182805',
      color: '#FFFFFF',
      padding: '8px',
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
    },
  },
  detailedContainer: {
    fontWeight: 400,
    fontSize: '0.875rem',
    textTransform: 'none',
    borderRadius: '8px',
    paddingTop: '0.375rem',
    paddingBottom: '0.375rem',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
  },
};

export default styles;
