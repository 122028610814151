import {useState} from 'react';
import PendingComponent from './component';
import {useAppointmentList, useDebounce} from '$modules/frontdesk/hooks';
import {ThemeProvider} from '@emotion/react';
import theme from '$modules/frontdesk/theme';

interface PendingContainerProps {
  newPatientRequests?: boolean;
}

const PendingContainer = (props: PendingContainerProps) => {
  const {newPatientRequests = false} = props;

  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchTerm = useDebounce<string>(searchText, 500);
  const [filterInputs, setFilterInputs] = useState({
    appointmentTypes: [] as number[],
    consultants: [] as number[],
    status: [] as string[],
  });

  const [apiFilters, setApiFilters] = useState({
    appointmentTypes: [] as number[],
    consultants: [] as number[],
    status: [] as string[],
  });

  const {appointments, isPending} = useAppointmentList({
    search: debouncedSearchTerm,
    status: newPatientRequests ? [] : ['pending'],
    appointmentType: apiFilters.appointmentTypes,
    doctorId: apiFilters.consultants,
    newPatient: newPatientRequests,
  });

  const handleFilterChange = (
    type: 'appointmentTypes' | 'consultants' | 'status',
    id: number | string
  ) => {
    setFilterInputs(prev => {
      const newFilter = [...prev[type]];
      const index = newFilter.indexOf(id);

      if (index === -1) {
        newFilter.push(id);
      } else {
        newFilter.splice(index, 1);
      }

      return {
        ...prev,
        [type]: newFilter,
      };
    });
  };

  const handleApplyFilters = () => {
    setApiFilters({...filterInputs});
  };

  const handleResetFilters = () => {
    setFilterInputs({
      appointmentTypes: [],
      consultants: [],
      status: ['pending'],
    });
    setApiFilters({
      appointmentTypes: [],
      consultants: [],
      status: ['pending'],
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <PendingComponent
        newPatientRequests={newPatientRequests}
        searchText={searchText}
        setSearchText={setSearchText}
        filters={filterInputs}
        onFilterChange={handleFilterChange}
        onApply={handleApplyFilters}
        onReset={handleResetFilters}
        appointments={appointments}
        isLoading={isPending}
      />
    </ThemeProvider>
  );
};

export default PendingContainer;
