import {ArrowNarrowRightIcon} from '$assets/svgs';
import FetchError from '$components/FetchError';
import FrontdeskButton from '$components/FrontdeskButton';
import Loading from '$components/Loading';
import {Button} from '@mui/material';
import DateAndTimeSelectionComponent from './component';
import {FormState} from '../types';
import {useBookingSlots, useConsultants} from '$modules/frontdesk/hooks';
import {Slot} from '$modules/frontdesk/type';
import {useAppSelector} from '$redux/hooks';
import './styles.scss';
import {OfflineBookingStep} from '../constant';
import {SetStateAction} from 'react';
import {useBlockSlots} from '$modules/booking/hooks';

interface DateAndTimeSelectionContainerProps {
  setActiveStep: React.Dispatch<SetStateAction<OfflineBookingStep>>;
  offlineBookingFormData: FormState;
  setOfflineBookingFormData: React.Dispatch<React.SetStateAction<FormState>>;
  offlineBookingType: string;
}
const DateAndTimeSelectionContainer: React.FC<
  DateAndTimeSelectionContainerProps
> = ({
  setActiveStep,
  offlineBookingFormData,
  setOfflineBookingFormData,
  offlineBookingType,
}) => {
  const selectedDoctorId = offlineBookingFormData.appointment.doctor_id;
  const appointmentTypeId =
    offlineBookingFormData?.appointment?.appointment_type_id;
  const subCareTypeId = offlineBookingFormData.appointment.sub_care_type_id;
  const dateSelected = offlineBookingFormData.appointment.date;
  const slotSelected =
    offlineBookingFormData.appointment.start_time !==
    offlineBookingFormData.appointment.end_time;

  const isContinueDisabled =
    !dateSelected || (offlineBookingType === 'new' ? !slotSelected : false);
  const hospitalId = useAppSelector(state => state.auth.user?.hospitalId);
  const {disableSubmit, sendBlockSlotsRequest} = useBlockSlots({
    setActiveStep,
    setOfflineBookingFormData,
  });

  const {
    isLoading: isPendingConsultantList,
    consultants: consultantList,
    isError: isErrorConsultantList,
  } = useConsultants({
    subCareId: subCareTypeId,
    appointmentTypeId: appointmentTypeId,
  });
  const {slots, isLoading: isPendingAvailableSlots} = useBookingSlots({
    doctorId: selectedDoctorId,
    date: dateSelected || undefined,
    hospitalId: hospitalId || undefined,
    subCareTypeId: subCareTypeId,
    appointmentTypeId: appointmentTypeId,
  });

  const isPending = isPendingAvailableSlots || isPendingConsultantList;
  const isError = !slots || isErrorConsultantList;

  if (isPending) return <Loading />;
  if (isError) return <FetchError />;

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setOfflineBookingFormData((prevState: FormState) => ({
        appointment: {
          ...prevState.appointment,
          date: date,
          start_time: null,
          end_time: null,
        },
      }));
    }
  };

  const handleSlotSelection = (slot: Slot) => {
    setOfflineBookingFormData((prevState: FormState) => ({
      appointment: {
        ...prevState.appointment,
        start_time: slot.startTime,
        end_time: slot.endTime,
      },
    }));
  };

  const handleContinueClick = () => {
    if (offlineBookingType !== 'surgery') {
      sendBlockSlotsRequest({
        id: offlineBookingFormData.appointment.id,
        date: dateSelected?.toISOString() || '',
        doctorId: selectedDoctorId,
        endTime:
          offlineBookingFormData.appointment.end_time?.toISOString() || '',
        startTime:
          offlineBookingFormData.appointment.start_time?.toISOString() || '',
      });
    }
    setActiveStep(OfflineBookingStep.PaymentDetails);
  };

  return (
    <>
      <DateAndTimeSelectionComponent
        isPendingAvailableSlots={isPendingAvailableSlots}
        consultants={consultantList}
        slots={slots}
        handleDateChange={handleDateChange}
        offlineBookingFormData={offlineBookingFormData}
        handleSlotSelection={handleSlotSelection}
        dateSelected={dateSelected}
        offlineBookingType={offlineBookingType}
      />
      <div className="dialog-footer">
        <Button
          className="back-btn"
          onClick={() => setActiveStep(OfflineBookingStep.ConsultantSelection)}
        >
          Back
        </Button>
        <FrontdeskButton
          title="Continue"
          className="continue-btn"
          disabled={isContinueDisabled || disableSubmit}
          endIcon={<ArrowNarrowRightIcon />}
          onClick={handleContinueClick}
        />
      </div>
    </>
  );
};

export default DateAndTimeSelectionContainer;
