import Button from '@mui/material/Button';
import {CalendarView} from './constant';

interface CalendarViewSwitchProps {
  view: CalendarView;
  changeView: (view: CalendarView) => void;
}

function CalendarViewSwitch(props: CalendarViewSwitchProps) {
  const {view, changeView} = props;

  const viewChangeHandler = (view: CalendarView) => {
    return () => changeView(view);
  };

  const buttons = Object.entries(CalendarView).map(([name, value]) => {
    return (
      <Button
        key={value}
        onClick={viewChangeHandler(value)}
        className={view === value ? 'active' : ''}
      >
        {name}
      </Button>
    );
  });

  return (
    <div className="calendar-view-switch flex items-center gap-3">
      {buttons}
    </div>
  );
}

export default CalendarViewSwitch;
