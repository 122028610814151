import {FormState} from './types';

export const INITIAL_FORM_STATE: FormState = {
  appointment: {
    id: undefined,
    sub_care_type_id: 0,
    appointment_type_id: 0,
    care_type_id: 0,
    date: null,
    start_time: null,
    end_time: null,
    doctor_id: 0,
    preferred_time: '',
    referred_by_physician: false,
    reason: '',
    self_booking: false,
    self_paying: true,
    payment_mode: '',
    insurance_id: 0,
    membership_no: '',
    hospital_id: 0,
    preferred_point_of_contact: '',
    contact_mobile_number: '',
    contact_email: '',
    operation_theatre_id: NaN,
    country_code: '+44',
    patient: {
      id: null,
      prefix: '',
      first_name: '',
      last_name: '',
      dob: null,
      gender: '',
      country_code: '+44',
      mobile_number: '',
      email: '',
      address: {
        id: null,
        line1: '',
        line2: '',
        city: '',
        country: '',
        postcode: '',
      },
    },
  },
};

export enum OfflineBookingStep {
  SelectOrBookPatient = 'SelectOrBookPatient',
  PatientDetailsOne = 'PatientDetailsOne',
  PatientDetailsTwo = 'PatientDetailsTwo',
  ContactDetails = 'ContactDetails',
  AppointmentTypeSelection = 'AppointmentTypeSelection',
  ConsultationSelection = 'ConsultationSelection',
  ConsultantSelection = 'ConsultantSelection',
  DateAndTimeSelection = 'DateAndTimeSelection',
  PaymentDetails = 'PaymentDetails',
  OperationTheatreContainer = 'OperationTheatreContainer',
  BookingConfirmed = 'BookingConfirmed',
}
