import {Button, TableCell, TableRow, Tooltip} from '@mui/material';
import ArrowSquareRightIcon from '$assets/svgs/arrow-square-right.svg?react';
import UserCircleIcon from '$assets/svgs/user-circle.svg?react';
import {PatientInfo} from '../types';
import {PATIENT_STATUS} from '../../constant';
import {momentDate} from '$utils/date';
import {useNavigate} from 'react-router-dom';
import {getRoute, RouteSection} from '$utils/route';
import {formatFullName} from '$utils/helper';

interface PatientListRowProps {
  info: PatientInfo;
}

function PatientListRow(props: PatientListRowProps) {
  const {info} = props;

  const {id: patientId} = info;
  const navigate = useNavigate();

  const {fullName, uniqCode, status, lastAppointmentDate} = info;

  const patientStatus =
    PATIENT_STATUS.find(patientStatus => patientStatus.value === status)
      ?.title || '-';

  const dateString = momentDate(lastAppointmentDate).format('DD/MM/yyyy');

  const handlePatientDetailsClick = () => {
    navigate(getRoute(RouteSection.PatientList, '', patientId), {
      state: patientId,
    });
  };

  let nameElement = <span>{fullName}</span>;

  if (fullName.length > 40) {
    nameElement = (
      <Tooltip title={fullName}>
        <span>{formatFullName(fullName)}</span>
      </Tooltip>
    );
  }

  return (
    <TableRow>
      <TableCell align="left">
        <div className="flex justify-start items-start">
          <div className="bg-white primary p-1 rounded-full w-8 h-8 flex justify-center items-center">
            {info.profileURL ? (
              <img className="h-6 w-6 rounded-full" src={info.profileURL} />
            ) : (
              <UserCircleIcon />
            )}
          </div>
        </div>
      </TableCell>
      <TableCell align="left">{nameElement}</TableCell>
      <TableCell align="left">{`#${uniqCode}` || '-'}</TableCell>
      <TableCell align="left">
        <span className="flex gap-3 items-center">
          <div className={`h-4 w-4 rounded patient-status-card-${status}`} />
          {patientStatus}
        </span>
      </TableCell>
      <TableCell align="left">{dateString}</TableCell>
      <TableCell align="right">
        <Button
          className="patient-details-button"
          startIcon={<ArrowSquareRightIcon />}
          onClick={handlePatientDetailsClick}
        >
          Patient details
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default PatientListRow;
