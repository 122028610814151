import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  BookingDataUpdater,
  BookingPreferredTime,
  BookingStepperStage,
  BookingView,
  DateSelectionViewChoices,
} from '../types';
import BookingStepper from '../components/BookingStepper';
import Footer from '../components/Footer';
import DatePicker from '$components/DatePicker';
import AppointmentSummaryTile from '../components/AppointmentSummaryTile';
import styles from '../styles';
import useHospitalInfo from '../../../hooks/useHospitalInfo';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';
import {useBookingSlots} from '$modules/frontdesk/hooks';
import TimeSlotPicker from '$components/TimeSlotPicker';
import {Slot} from '$modules/frontdesk/type';
import {useBlockSlots} from '../hooks';
import LocalStorage from '$utils/localStore';
import {NO_PREFERENCE_CONSULTANT} from '../constant';

interface DateSelectionViewProps {
  bookingChoices: DateSelectionViewChoices;
  setActiveView: (view: BookingView) => void;
  setBookingChoices: BookingDataUpdater;
}

function DateSelectionView(props: DateSelectionViewProps) {
  const {bookingChoices, setActiveView, setBookingChoices} = props;
  const {hospitalInfo, isLoading} = useHospitalInfo();
  const savedBlockedDate = LocalStorage.getItem(
    LocalStorage.BOOKING_BLOCKED_DATE
  );
  const savedBlockedSlot = LocalStorage.getItem(
    LocalStorage.BOOKING_BLOCKED_SLOT
  );

  let parsedBlockedSlot: Slot | null = null;
  let slotWithDates: Slot | null = null;

  if (savedBlockedSlot) {
    try {
      parsedBlockedSlot = JSON.parse(savedBlockedSlot) as Slot;
      slotWithDates = {
        startTime: new Date(parsedBlockedSlot.startTime),
        endTime: new Date(parsedBlockedSlot.endTime),
      } as Slot;
    } catch (error) {
      console.error('Error parsing JSON from local storage:', error);
    }
  }
  const {
    slots,
    isLoading: isSlotsLoading,
    isError: isSlotsError,
  } = useBookingSlots({
    date: bookingChoices?.date,
    preferredTime: BookingPreferredTime.All,
    doctorId: bookingChoices?.consultant?.id,
    hospitalId: hospitalInfo?.id,
    subCareTypeId: bookingChoices.care.id,
    appointmentTypeId: bookingChoices.appointment.id,
  });

  const {disableSubmit, sendBlockSlotsRequest} = useBlockSlots({
    setActiveView,
    setBookingChoices,
    onSuccessCallback: responseData => {
      const blockedDate = responseData.data.appointment.date;
      const blockedSlot = {
        startTime: new Date(responseData.data.appointment.start_time),
        endTime: new Date(responseData.data.appointment.end_time),
      };
      LocalStorage.setItem(LocalStorage.BOOKING_BLOCKED_DATE, blockedDate);
      LocalStorage.setItem(
        LocalStorage.BOOKING_BLOCKED_SLOT,
        JSON.stringify(blockedSlot)
      );
    },
  });

  const handleDateChange = (date: Date | null) => {
    setBookingChoices(draft => {
      draft.date = date || undefined;
      draft.slot = undefined;
    });
  };

  const handleSlotChange = (selectedSlot: Slot) => {
    setBookingChoices(draft => {
      draft.slot = selectedSlot;
    });
  };

  const handleBackClick = () => {
    setActiveView(BookingView.ConsultantSelectionView);
  };

  const handleContinueClick = () => {
    if (
      slotWithDates &&
      slotWithDates.startTime.toDateString() ===
        bookingChoices.slot?.startTime.toDateString() &&
      slotWithDates.startTime.toISOString() ===
        bookingChoices.slot.startTime.toISOString()
    ) {
      setActiveView(BookingView.ChoicesConfirmationView);
    } else {
      sendBlockSlotsRequest({
        id: bookingChoices.appointmentId,
        date: bookingChoices.date?.toISOString() || '',
        doctorId: bookingChoices.consultant.id,
        endTime: bookingChoices.slot?.endTime.toISOString() || '',
        startTime: bookingChoices.slot?.startTime.toISOString() || '',
      });
    }
  };

  const isContinueDisabled = !bookingChoices.date || !bookingChoices.slot;

  if (isLoading) return <Loading />;
  if (!hospitalInfo) return <FetchError />;

  return (
    <Box sx={styles.sectionWrapperWithStepper} flexDirection="column" gap={5}>
      <Stack gap={5} sx={styles.section}>
        <Box>
          <BookingStepper activeStage={BookingStepperStage.Appointment} />
        </Box>
        <Typography variant="h2" sx={styles.subHeading}>
          Let's book your appointment
        </Typography>
        <Box sx={styles.scrollSection}>
          <Box sx={styles.stepsTypeWrap}>
            <Box display="flex" flexDirection="column" gap={4} sx={{flex: 1}}>
              <Typography variant="h3" sx={styles.stepsTypeHead}>
                Select a date
              </Typography>
              <Box>
                <DatePicker
                  disablePast
                  value={bookingChoices.date || null}
                  onChange={handleDateChange}
                  timezone={hospitalInfo.timezone}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={4} sx={{flex: 1}}>
              {bookingChoices?.date && (
                <>
                  <Typography variant="h3" sx={styles.stepsTypeHead}>
                    Do you have a preferred time?
                  </Typography>
                  <TimeSlotPicker
                    isLoading={isSlotsLoading}
                    isError={isSlotsError}
                    consultantName={bookingChoices?.consultant.fullName}
                    preferredTime={BookingPreferredTime.All}
                    slots={
                      slotWithDates &&
                      savedBlockedDate &&
                      savedBlockedDate === bookingChoices.date.toISOString() &&
                      bookingChoices.consultant !== NO_PREFERENCE_CONSULTANT
                        ? [...slots, slotWithDates].sort(
                            (a, b) =>
                              a.startTime.getTime() - b.startTime.getTime()
                          )
                        : slots
                    }
                    selectedSlot={bookingChoices?.slot}
                    setSelectedSlot={handleSlotChange}
                  />
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Stack>
      <Box sx={styles.footerSection}>
        <AppointmentSummaryTile choices={bookingChoices} />
        <Footer
          disableContinue={isContinueDisabled || disableSubmit}
          onBackClick={handleBackClick}
          onContinueClick={handleContinueClick}
        />
      </Box>
    </Box>
  );
}

export default DateSelectionView;
