import {InferType, object, string} from 'yup';

import {ValidationMessage} from '$constants/messages';

export const emailSchema = string()
  .trim()
  .max(350, ValidationMessage.EmailExceedCharacterLimit)
  .email(ValidationMessage.EmailInvalid)
  .required(ValidationMessage.EmailRequired);

export const enterSystemFormSchema = object().shape(
  {
    email: emailSchema,
    password: string().trim().required(ValidationMessage.PasswordRequired),
  },
  [['email', 'password']]
);

export type EnterSystemFormData = Required<
  InferType<typeof enterSystemFormSchema>
>;
