import CheckBox from '$components/CheckBox';

interface FilterItemProps {
  name: string;
  checked: boolean;
  onClick: () => void;
}

function FilterItem(props: FilterItemProps) {
  const {name, checked, onClick} = props;

  return (
    <li className="list-item-container" onClick={onClick}>
      <span className="list-item-text">{name}</span>
      <span>
        <CheckBox checked={checked} />
      </span>
    </li>
  );
}

export default FilterItem;
