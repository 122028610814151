import {EditIcon, HospitalIcon} from '$assets/svgs';
import './styles.scss';

const ClinicalDetailsSkeleton = () => {
  return (
    <div className="clinical-data__clinical-details col-span-2 row-span-2">
      <div className="header">
        <div className="card-title-container">
          <HospitalIcon />
          <span className="card-title">Clinical Details </span>
        </div>
        <EditIcon className="btn-icon" />
      </div>
      <div className="clinical-data__clinical-details__list mt-4 animate-pulse">
        <div className="clinical-data__clinical-details__list__container mb-4">
          <div className="list-title h-5 w-24 bg-gray-200 rounded-md mb-2" />
          <div className="list-details h-5 w-40 bg-gray-200 rounded-md" />
        </div>
        <div className="clinical-data__clinical-details__list__container">
          <div className="list-title h-5 w-24 bg-gray-200 rounded-md mb-2" />
          <div className="list-details h-5 w-40 bg-gray-200 rounded-md" />
        </div>
      </div>
    </div>
  );
};

export default ClinicalDetailsSkeleton;
