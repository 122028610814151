import {Box, Button, Stack, Typography, useMediaQuery} from '@mui/material';
import summaryVideo from '$assets/gifs/booking_ending.gif';
import EyeOpLogo from '$assets/svgs/eye-op.svg?react';
import HospitalMobileLogo from '$assets/svgs/site-logo.svg?react';
import HospitalTextLogo from '$assets/svgs/site-text-logo.svg?react';
import theme from '$modules/booking/theme';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {RoutePath} from '$constants/routes';
import {styles} from './styles';
import {dateToDDMMYYYY} from '$utils/date';
import {AppointmentDetailNavigatePayload} from '$modules/booking/types';

function AppointmentDeleteSuccess() {
  const [appointmentDetails, setAppointmentDetails] =
    useState<AppointmentDetailNavigatePayload | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const appointment = location.state as AppointmentDetailNavigatePayload;
    if (appointment) {
      setAppointmentDetails(appointment);
    } else {
      setAppointmentDetails(null);
      navigate(RoutePath.bookingRoute);
    }
  }, [location.state, navigate]);

  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box sx={styles.screenSection}>
      <Box sx={styles.bookingWrapper} flexDirection="column">
        <Box sx={styles.appSummaryWrap} flexDirection="column">
          <Box sx={styles.header}>
            <Box display="flex" gap={1} alignItems="center">
              <EyeOpLogo className="site-logo" />
              {isLarge && <div className="h-3 w-px bg-[#EBC3FF]" />}
              {isLarge && <HospitalTextLogo className="h-24 w-24" />}
            </Box>
            <Box sx={styles.hospitalLogoWrap}>
              <HospitalMobileLogo />
            </Box>
          </Box>
          <Box sx={styles.appSummarySection}>
            <Stack spacing={3}>
              <Typography variant="h1" sx={styles.summaryHead}>
                Your booking has been cancelled
              </Typography>
              <Typography variant="caption" sx={styles.summaryBody}>
                We have received your cancellation for the{' '}
                {appointmentDetails?.careType} appointment on{' '}
                <span className="font-bold">
                  {dateToDDMMYYYY(appointmentDetails?.date)}
                </span>
                . We’re sorry to hear you won’t be able to visit us.
                <br />
                Feel free to schedule a new appointment whenever you’re ready.
              </Typography>
            </Stack>
            <Stack sx={styles.summaryActionBtnWrap}>
              <Button
                variant="contained"
                color="primary"
                sx={styles.continueBtn}
                onClick={() => navigate(RoutePath.bookingRoute)}
              >
                Book a new appointment
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.appSummaryVideoSection}>
        <img src={summaryVideo} alt="video" />
      </Box>
    </Box>
  );
}

export default AppointmentDeleteSuccess;
