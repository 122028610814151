import {RoutePath} from '$constants/routes';

export enum RouteSection {
  Calendar = 'calendar',
  Notification = 'notification',
  PatientList = 'patient',
  PatientInvite = 'patient-invite',
}

export function getRoute(
  section: RouteSection,
  subSection?: string,
  id?: number | string
) {
  let path = '';

  switch (section) {
    case RouteSection.Calendar:
      path = `${RoutePath.staff.index}/${RoutePath.staff.calendar.index}`;
      break;
    case RouteSection.PatientList:
      path = `${RoutePath.staff.index}/${RoutePath.staff.patientList.index}`;
      break;
    case RouteSection.PatientInvite:
      path = `${RoutePath.patient.index}`;
      break;
    case RouteSection.Notification:
      path = `${RoutePath.staff.index}/${RoutePath.staff.notification}`;
      break;
    default:
      break;
  }

  if (subSection) path += `/${subSection}`;
  if (id) path += `/${id}`;

  return path;
}
