import {Navigate, useSearchParams} from 'react-router-dom';
import {RoutePath} from '$constants/routes';
import {Box} from '@mui/material';
import './styles.scss';
import styles from '../styles';
import Header from '../components/Header';
import DeleteAppointmentComponent from './component';

function DeleteAppointment() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  if (!token) {
    return <Navigate to={RoutePath.bookingRoute} />;
  }

  return (
    <Box sx={styles.wrapper}>
      <Header />
      <DeleteAppointmentComponent token={token} />
    </Box>
  );
}

export default DeleteAppointment;
