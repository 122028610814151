import {ApiRoute} from '$constants/api';
import {
  LastAppointment,
  PatientListOrderBy,
  PatientStatus,
} from '$modules/frontdesk/PatientList/PatientListView/constant';
import {PatientInfo} from '$modules/frontdesk/PatientList/PatientListView/types';
import store from '$redux/store';
import axiosInstance from '..';

export interface GetPatientInfoListResponseData {
  data: {
    patients: {
      id: number;
      full_name: string;
      status: PatientStatus;
      uniq_code: string;
      profile_url: string;
      last_appointment_details: {
        date: string;
        country_code: string;
        contact_mobile_number: string;
      };
    }[];
    total_pages: number;
    total_records: number;
  };
  message: string;
}

export interface GetPatientInfoListParams {
  pageNumber?: number;
  perPage?: number;
  status?: PatientStatus[];
  lastAppointment?: LastAppointment;
  search?: string;
  orderBy?: PatientListOrderBy;
  orderDirection?: 'asc' | 'desc';
}

export async function getPatientInfoList(
  params: GetPatientInfoListParams
): Promise<{
  patients: PatientInfo[];
  totalPages: number;
  totalRecords: number;
  message: string;
}> {
  const {
    pageNumber,
    perPage,
    status,
    lastAppointment,
    search,
    orderBy,
    orderDirection,
  } = params;
  const token = store.getState().auth.token;

  const response = await axiosInstance<GetPatientInfoListResponseData>(
    ApiRoute.Patients,
    {
      headers: {Authorization: token},
      params: {
        page_no: pageNumber,
        per_page: perPage,
        status: status,
        last_appointment: lastAppointment,
        search: search,
        order_by: orderBy,
        order_direction: orderDirection,
      },
    }
  );

  const responseData = response.data;
  const patients = responseData?.data?.patients || [];

  return {
    patients: patients.map(info => ({
      id: info.id,
      fullName: info.full_name,
      status: info.status,
      uniqCode: info.uniq_code,
      profileURL: info.profile_url,
      lastAppointmentDate: new Date(info.last_appointment_details?.date),
    })),
    totalPages: responseData.data.total_pages,
    totalRecords: responseData.data.total_records,
    message: responseData.message,
  };
}
