import {useQuery} from '@tanstack/react-query';
import {getPatientList} from '$api/frontdesk';

export const usePatientListHook = (search?: string) => {
  const {isPending, isError, error, data} = useQuery({
    queryKey: ['patient-list', search],
    queryFn: () => getPatientList(search),
  });

  const patients = data?.data || [];

  return {
    isPending,
    isError,
    error,
    patients,
  };
};
