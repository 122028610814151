import React from 'react';
import {Dialog} from '@mui/material';
import {ArrowClockWise, ArrowNarrowRightIcon, XCloseIcon} from '$assets/svgs';
import IconButton from '$components/IconButton';
import FrontdeskButton from '$components/FrontdeskButton';
import {useCreateInvitation} from '../../ClinicalData/hooks';
import {dateToDDMMYYYY} from '$utils/date';
import './styles.scss';

interface InvitePatientDialogProps {
  open: boolean;
  handleClose: () => void;
  status: string;
  patientInviteEmail: string;
  lastInviteSendDate: string;
}
const InvitePatientDialog: React.FC<InvitePatientDialogProps> = ({
  open,
  handleClose,
  status,
  patientInviteEmail,
  lastInviteSendDate,
}) => {
  const {sendCreateInvitation, disableSubmit} =
    useCreateInvitation(handleClose);

  const handleSendInvitation = () => {
    sendCreateInvitation({resource_type: 'Patient', email: patientInviteEmail});
  };

  return (
    <>
      <Dialog open={open} className="invite-patient-dialog-container">
        <div className="dialog-header">
          <div className="heading">
            {status === ''
              ? 'Invite the patient to the platform'
              : 'The patient was already invited to the platform'}
          </div>
          <IconButton onClick={handleClose} className="x-icon">
            <XCloseIcon />
          </IconButton>
        </div>
        <div className="dialog-body">
          {status === '' && (
            <div className="body-heading">
              Send the invitation to the platform on <br /> this email :{' '}
              <span className="invite-email">{patientInviteEmail}</span>
            </div>
          )}
          {status === 'pending' && (
            <>
              <div className="invited-date">
                Date: {dateToDDMMYYYY(lastInviteSendDate)}
              </div>
              {/* <div>Contact patient</div> */}
            </>
          )}
        </div>
        <div className="dialog-footer">
          <FrontdeskButton
            variant="danger"
            title="Cancel"
            onClick={handleClose}
          />
          <FrontdeskButton
            disabled={disableSubmit}
            title={status === '' ? 'Send invitation' : 'Resend invitation'}
            onClick={handleSendInvitation}
            endIcon={
              status === '' ? <ArrowNarrowRightIcon /> : <ArrowClockWise />
            }
          />
        </div>
      </Dialog>
    </>
  );
};

export default InvitePatientDialog;
