import {type SxProps} from '@mui/material';

import theme from '$modules/booking/theme';

const styles: Record<string, SxProps> = {
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridGap: theme.spacing(3),
  },

  footerActionBtns: {
    padding: theme.spacing(1, 4),
    flex: 1,

    [theme.breakpoints.up('md')]: {
      flex: 'unset',
      padding: theme.spacing(1, 6),
    },
  },

  summaryWrap: {
    background: theme.palette.common.white,
    borderRadius: '0.5rem',
    padding: theme.spacing(2, 3),
    gridGap: theme.spacing(1),

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      gridGap: theme.spacing(2),
    },
  },
  summaryTitle: {
    color: theme.palette.primary2.darkest,
  },
};

export default styles;
