import EyeOPLogo from '$assets/svgs/eye-op.svg?react';
import clothImage from '$assets/images/Cloth_02.png';
import FrontdeskButton from '$components/FrontdeskButton';
import {useNavigate} from 'react-router-dom';
import {RoutePath} from '$constants/routes';

function IndexComponent() {
  const navigate = useNavigate();

  return (
    <div className="h-screen w-screen flex">
      <div className="login-container flex-1 flex flex-col">
        <div className="flex-1 overflow-auto">
          <div className="m-auto w-full max-w-xl py-16 px-5 h-full flex flex-col">
            <div className="mb-8">
              <EyeOPLogo className="h-8 w-8" />
            </div>

            <div className="flex-1 flex flex-col justify-center">
              <h1 className="text-primary-color font-semibold text-3xl mb-6">
                Welcome!
              </h1>

              <p className="text-base font-normal mb-8">
                Eye Op is your dedicated platform for effortless patient care,
                putting people first.
              </p>

              <div className="flex flex-col mb-6 w-full max-w-sm">
                <FrontdeskButton
                  title="Book an appointment"
                  onClick={() => navigate(RoutePath.bookingRoute)}
                />
              </div>
              <div className="flex flex-col mb-6 w-full max-w-sm">
                <FrontdeskButton
                  title="Staff login"
                  onClick={() => navigate(RoutePath.loginRoute)}
                />
              </div>

              {/* <div className="flex flex-col mb-6 w-full max-w-sm">
                <FrontdeskButton
                  title="Patient platform"
                  onClick={() => navigate(RoutePath.patient.index)}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block bg-green-200 flex-1 overflow-hidden">
        <img src={clothImage} className="h-full w-full" />
      </div>
    </div>
  );
}
export default IndexComponent;
