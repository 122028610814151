import {UserAuthData} from '$redux/authSlice/types';
import {UserRoleName} from './constants';
import {POSTSessionRefreshResponseData} from './types';

export function postSessionRefreshResponseToUserAuthData(
  responseData: POSTSessionRefreshResponseData
): UserAuthData | null {
  const data = responseData.data;

  if ('user' in data) {
    const user = data.user;

    return {
      id: user.id,
      fullName: user.full_name,
      hospitalId: user.hospital_id,
      email: user.email,
      roleName: user.role_name,
      mobileNumber: user.mobile_number,
      profileUrl: user.profile_url,
      timezone: user.hospital_details.timezone,
    };
  }

  if ('patient' in data) {
    const patient = data.patient;

    return {
      id: patient.id,
      fullName: patient.full_name,
      hospitalId: null,
      email: patient.email,
      roleName: UserRoleName.Patient,
      mobileNumber: patient.mobile_number,
      profileUrl: null,
      timezone: null,
    };
  }

  return null;
}
