import React from 'react';
import {FormikErrors, FormikTouched} from 'formik';
import Input from '$components/Input';
import {PatientContactDetailsFormData} from './schema';
import PhoneNumberInput from '$modules/booking/components/PhoneNumberInput';
import SelectInput from '$components/SelectInput';
import {MenuItem, Typography} from '@mui/material';
import {Color} from '$constants/style';
import './styles.scss';
import {usePreferredPointOfContact} from '$modules/booking/hooks';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';
import {FormState} from '../../types';

interface ContactDetailsComponentFormProps {
  offlineBookingFormData: FormState;
  values: PatientContactDetailsFormData;
  errors: FormikErrors<PatientContactDetailsFormData>;
  touched: FormikTouched<PatientContactDetailsFormData>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleFieldChange: (
    field: string,
    defaultValue: string | null
  ) => (value: unknown) => void;
}

const ContactDetailsComponent: React.FC<ContactDetailsComponentFormProps> = ({
  offlineBookingFormData,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  handleFieldChange,
}) => {
  const countryCode = offlineBookingFormData?.appointment?.country_code;
  const {contactEmail, contactPhoneNumber, preferredPointOfContact} = values;

  const {
    preferredPointOfContact: preferredContactItems,
    isLoading,
    isError,
  } = usePreferredPointOfContact();

  const renderItem = (item: string) => (
    <MenuItem key={item} value={item}>
      {item}
    </MenuItem>
  );

  const renderValue = (value: string) => (
    <Typography color={value ? undefined : Color.TextPrimayPlaceholder}>
      {value ? value : 'Choose an option'}
    </Typography>
  );

  if (isLoading) return <Loading />;
  if (isError) return <FetchError />;

  const preferredContactOptions = preferredContactItems.map(item => item.name);

  return (
    <>
      <h2 className="dialog-title">Contact details</h2>
      <div className="contact-detail-form-container">
        <div className="contact-detail-input-container">
          <div className="field-label">Email address</div>
          <Input
            name="contactEmail"
            placeholder="Email address"
            value={contactEmail}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched?.contactEmail}
            error={Boolean(touched?.contactEmail && errors?.contactEmail)}
            helperText={
              touched?.contactEmail && errors?.contactEmail
                ? errors.contactEmail
                : ''
            }
          />
        </div>
        <div className="contact-detail-input-container">
          <div className="field-label">Phone number</div>
          <PhoneNumberInput
            name="contactPhoneNumber"
            value={contactPhoneNumber}
            onBlur={handleBlur}
            country={countryCode}
            onChange={event => {
              if (event.target.value.length <= 13) handleChange(event);
            }}
          />
          {touched?.contactPhoneNumber && errors?.contactPhoneNumber && (
            <div className="contact-detail-error-text">
              {errors.contactPhoneNumber}
            </div>
          )}
        </div>
        <div className="contact-detail-input-container">
          <div className="field-label">Preferred point of contact</div>
          <SelectInput
            name="preferredPointOfContact"
            value={preferredPointOfContact}
            items={preferredContactOptions}
            renderValue={renderValue}
            renderItem={renderItem}
            onBlur={handleBlur}
            onChange={handleFieldChange('preferredPointOfContact', '')}
            error={
              touched?.preferredPointOfContact &&
              Boolean(errors.preferredPointOfContact)
            }
          />
          {touched?.preferredPointOfContact &&
            errors?.preferredPointOfContact && (
              <div className="contact-detail-error-text">
                {errors.preferredPointOfContact}
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default ContactDetailsComponent;
