import {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useImmer} from 'use-immer';
import {
  CustomContentGenerator,
  DayHeaderContentArg,
  EventClickArg,
  EventContentArg,
  SlotLabelContentArg,
} from '@fullcalendar/core';
import CalendarViewComponent from './component';
import {useCalendarApi} from './hooks';
import './styles.scss';
import {useAppointmentList, useConsultants, useDebounce} from '../../hooks';
import {Consultant, FilterOptions} from '../../type';
import {appointmentInfoToCalendarEvent} from '../../utils';
import {CalendarView} from './constant';
import {ALL_CONSULTANT_OPTION} from '../../constant';
import Tooltip from '@mui/material/Tooltip';
import CalendarRefContext from '../../../../context/CalendarRefContext';
import {RoutePath} from '$constants/routes';
import {RouteSection, getRoute} from '$utils/route';
import useTimezone from '../../../../hooks/useTimezone';
import {momentDate} from '$utils/date';
import {getAppointmentStatus, statusColorMap} from '../utils';
import LocalStorage from '$utils/localStore';
import useAuthUser from '../../../../hooks/useAuthUser';
import {UserRoleName} from '$modules/admin/constant';

function CalendarViewContainer() {
  const navigate = useNavigate();
  const timezone = useTimezone();
  const location = useLocation();
  const authUser = useAuthUser()!;
  const pathName = location.pathname;
  const isConsultant = authUser.roleName === UserRoleName.Doctor;

  const [selectedFilterOptions, setSelectedFilterOptions] =
    useImmer<FilterOptions>({
      appointment_type: [],
      consultant: [],
      status: [],
    });

  const [selectedConsultant, setSelectedConsultant] =
    useState<Consultant | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [newAppointmentModalOpen, setNewAppointmentModalOpen] = useState(false);
  const calendarRef = useContext(CalendarRefContext);
  const {
    startDate,
    endDate,
    currentView,
    rangeText,
    title,
    changeCalendarView,
    handleCalendarPrev,
    handleCalendarNext,
  } = useCalendarApi(calendarRef);

  const {consultants} = useConsultants({});
  const doctorIds: number[] = [];

  if (isConsultant) {
    doctorIds.push(authUser.id);
  } else if (selectedConsultant) {
    doctorIds.push(selectedConsultant.id);
  }

  let {appointments} = useAppointmentList({
    doctorId: doctorIds.filter(id => !Number.isNaN(id)),
    search: debouncedSearchTerm,
    fromDate: startDate,
    toDate: endDate,
    appointmentType: selectedFilterOptions.appointment_type.map(obj => obj.id),
    status: selectedFilterOptions.status.map(obj => obj.name),
  });

  if (doctorIds.length === 0) {
    appointments = [];
  }

  const handleChangeCalendarView = (view: CalendarView) => {
    if (
      view !== CalendarView.Day &&
      selectedConsultant?.fullName === ALL_CONSULTANT_OPTION.fullName
    ) {
      setSelectedConsultant(null);
    }

    changeCalendarView(view);
  };

  const handleNewAppointmentModalOpen = () => {
    setNewAppointmentModalOpen(!newAppointmentModalOpen);
  };

  const renderMonthEvent: CustomContentGenerator<EventContentArg> = info => {
    const {isConfirmed, status} = info.event.extendedProps;

    return (
      <div className="fc-event-main-frame flex items-center overflow-auto no-scrollbar cursor-pointer">
        <div className="fc-event-time shrink-0">{info.timeText}</div>
        <div className="fc-event-title shrink-0">- {info.event.title}</div>
        {isConfirmed && (
          <div
            className={`ml-2 h-2 w-2 ${statusColorMap[status]} rounded-full shrink-0`}
          />
        )}
      </div>
    );
  };

  const renderWeekEvent: CustomContentGenerator<EventContentArg> = info => {
    const {patientName, status} = info.event.extendedProps;

    return (
      <div className="fc-event-main-frame overflow-auto cursor-pointer">
        <p className="fc-event-time mb-2">{info.timeText}</p>
        <p className="fc-event-week-title mb-2">{info.event.title}</p>
        {patientName && (
          <Tooltip title={patientName}>
            <p
              className="fc-event-week-description text-xs font-normal mb-1 truncate shrink-0"
              data-tooltip-id={`tooltip-${info.event.id}`}
            >
              {patientName}
            </p>
          </Tooltip>
        )}
        {status !== 'cancel' && (
          <div
            className={`${statusColorMap[status]} rounded-[4px] font-semibold text-xs text-white px-1 py-0.5  mb-2`}
          >
            {getAppointmentStatus(status)}
          </div>
        )}
      </div>
    );
  };

  const renderDayEvent: CustomContentGenerator<EventContentArg> = info => {
    const {patientName, status} = info.event.extendedProps;

    return (
      <div className="fc-event-main-frame overflow-auto flex flex-row items-center gap-6 overflow-auto cursor-pointer">
        <div className="fc-event-time shrink-0">{info.timeText}</div>
        <div className="fc-event-week-title shrink-0">{info.event.title}</div>
        {patientName && (
          <Tooltip title={patientName}>
            <div
              className="fc-event-week-description text-xs font-normal truncate shrink-0"
              data-tooltip-id={`tooltip-${info.event.id}`}
            >
              {patientName}
            </div>
          </Tooltip>
        )}
        {status !== 'cancel' && (
          <div
            className={`${statusColorMap[status]} rounded font-semibold text-xs text-white px-1 py-0.5`}
          >
            {getAppointmentStatus(status)}
          </div>
        )}
      </div>
    );
  };

  const renderWeekHeaderContent: CustomContentGenerator<
    DayHeaderContentArg
  > = info => {
    const weekDay = momentDate(info.date).format('ddd').toUpperCase();
    const date = momentDate(info.date).format('DD');

    return (
      <div className="flex flex-col items-start px-3 py-1">
        <p className="text-sm">{weekDay}</p>
        <p className="font-semibold text-base">{date}</p>
      </div>
    );
  };

  const renderSlotLabelContent: CustomContentGenerator<
    SlotLabelContentArg
  > = info => {
    const {date} = info;
    const dateObj = momentDate(date);

    const minutes = dateObj.minutes();
    const hours = dateObj.hours();

    if (minutes === 0 && hours !== 0) {
      const dateString = dateObj.format('H a').toLowerCase();

      return <span className="text-xs">{dateString}</span>;
    }

    return '';
  };

  const handleEventClick = (args: EventClickArg) => {
    const {event} = args;
    const appointmentId = event.extendedProps.appointmentId;

    navigate(
      getRoute(
        RouteSection.Calendar,
        RoutePath.staff.calendar.bookingDetails,
        appointmentId
      ),
      {state: {backRoute: pathName}}
    );
  };

  const handleSelectedConsultantChange = (value: Consultant | null) => {
    setSelectedConsultant(value);
    if (value) {
      LocalStorage.setItem(
        LocalStorage.CALENDAR_VIEW_SELECTED_CONSULTANT,
        JSON.stringify(value)
      );
    } else {
      LocalStorage.removeItem(LocalStorage.CALENDAR_VIEW_SELECTED_CONSULTANT);
    }
  };

  const today = new Date();
  const filteredAppointments = appointments.filter(
    record => record.appointment.status.toLowerCase() !== 'cancel'
  );
  const calendarEvents = filteredAppointments.map(
    appointmentInfoToCalendarEvent
  );

  useEffect(() => {
    const storeValue = LocalStorage.getItem(
      LocalStorage.CALENDAR_VIEW_SELECTED_CONSULTANT
    );

    if (storeValue) {
      const storeConsultant = JSON.parse(storeValue) as Consultant;

      if (storeConsultant.fullName === ALL_CONSULTANT_OPTION.fullName) {
        setSelectedConsultant(storeConsultant);
      } else {
        const consultant = consultants.find(
          con => con.id === storeConsultant.id
        );

        if (consultant) {
          setSelectedConsultant(consultant);
        }
      }
    }
  }, [consultants]);

  return (
    <CalendarViewComponent
      timezone={timezone}
      selectedFilterOptions={selectedFilterOptions}
      setSelectedFilterOptions={setSelectedFilterOptions}
      calendarEvents={calendarEvents}
      selectedConsultant={selectedConsultant}
      setSelectedConsultant={handleSelectedConsultantChange}
      searchText={searchText}
      setSearchText={setSearchText}
      consultants={consultants}
      calendarRef={calendarRef}
      currentView={currentView}
      rangeText={rangeText}
      title={title}
      today={today}
      newAppointmentModalOpen={newAppointmentModalOpen}
      handleNewAppointmentModalOpen={handleNewAppointmentModalOpen}
      changeCalendarView={handleChangeCalendarView}
      handleCalendarPrev={handleCalendarPrev}
      handleCalendarNext={handleCalendarNext}
      renderDayEvent={renderDayEvent}
      renderMonthEvent={renderMonthEvent}
      renderWeekEvent={renderWeekEvent}
      renderWeekHeaderContent={renderWeekHeaderContent}
      renderSlotLabelContent={renderSlotLabelContent}
      handleEventClick={handleEventClick}
    />
  );
}

export default CalendarViewContainer;
