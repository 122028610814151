import {RouterProvider} from 'react-router-dom';
import {router} from '$lib/router';
import useAuthLoad from '../hooks/useAuthLoad';

function AppRouter() {
  const {isLoading} = useAuthLoad();

  return isLoading ? null : <RouterProvider router={router} />;
}

export default AppRouter;
