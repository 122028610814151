import {PinIcon} from '$assets/svgs';
import '../styles.scss';

const NotesOverviewSkeleton = () => {
  return (
    <div
      className="notes row-span-2 row-start-4"
      data-testid="note-overview-skeleton"
    >
      <div className="notes-title-container mb-4">
        <div className="title flex items-center space-x-0">
          <PinIcon />
          <span className="title-text">Notes</span>
        </div>
      </div>
      <div className="notes-card-container">
        <div className="notes-card p-4 mb-4 bg-white shadow-md rounded-md">
          <div className="notes-body">
            <div className="notes-details mb-4">
              <div className="title-container mb-2">
                <div className="w-3/4 h-4 bg-gray-200 rounded-md animate-pulse"></div>
              </div>
              <div className="w-full h-12 bg-gray-200 rounded-md animate-pulse"></div>
            </div>
            <div className="notes-creator-details flex items-center space-x-4">
              <div className="notes-creator-icon-container">
                <div className="w-8 h-8 bg-gray-200 rounded-full animate-pulse"></div>
              </div>
              <div className="w-1/3 h-4 bg-gray-200 rounded-md animate-pulse"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesOverviewSkeleton;
