import {loadAuth} from '$redux/authSlice';
import {UserAuthData} from '$redux/authSlice/types';
import {useAppDispatch} from '$redux/hooks';
import LocalStorage from '$utils/localStore';
import {useEffect, useState} from 'react';
import {setMomentTimezone} from '$utils/date';
import {unsubscribePushManager} from '../worker/registration';

function useAuthLoad() {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const authString = LocalStorage.getItem(LocalStorage.AUTH_USER_DATA);

    if (authString) {
      const authData = JSON.parse(authString) as {
        refreshToken: string;
        token: string;
        user: UserAuthData;
      };

      dispatch(loadAuth(authData));

      if (authData.user.timezone) {
        setMomentTimezone(authData.user.timezone);
      }

      setIsLoading(false);
    } else {
      unsubscribePushManager().finally(() => setIsLoading(false));
    }
  }, [dispatch]);

  return {isLoading};
}

export default useAuthLoad;
