import {useState} from 'react';
import {FormikErrors, FormikTouched} from 'formik';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import EyeIcon from '$assets/svgs/eye.svg?react';
import EyeOffIcon from '$assets/svgs/eye-off.svg?react';
import Input from '$components/Input';
import ProgressStepper from '$components/ProgressStepper';
import {LastStepFormData} from './schema';
import styles from './styles';
import PasswordValidationStepper from '../PasswordValidationStepper';
import {getPasswordErrors} from '$modules/booking/utils';

interface LastStepFormProps {
  values: LastStepFormData;
  errors: FormikErrors<LastStepFormData>;
  touched: FormikTouched<LastStepFormData>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  setFieldTouched: (field: string) => void;
  setFieldValue: (field: string, value: unknown) => void;
}

function LastStepForm(props: LastStepFormProps) {
  const {values, touched, errors, handleBlur, handleChange} = props;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const passwordErrors = getPasswordErrors(values.password);

  return (
    <Grid container>
      <Grid item xs={12} sm={6} lg={4}>
        <Box sx={styles.passwordContainer}>
          <Input
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="Create password"
            value={values.password || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.password}
            error={Boolean(errors.password)}
            endIcon={showPassword ? <EyeIcon /> : <EyeOffIcon />}
            endIconClick={() => setShowPassword(v => !v)}
          />
        </Box>

        <ProgressStepper
          itemCount={6}
          activeCount={values.password ? 6 - passwordErrors.size : 0}
        />

        <PasswordValidationStepper
          passwordErrors={passwordErrors}
          touched={touched.password}
          error={errors.password}
          validated={Boolean(values.password) && !errors.password}
        />

        <Input
          type={showConfirmPassword ? 'text' : 'password'}
          name="confirmPassword"
          placeholder="Confirm password"
          value={values.confirmPassword || ''}
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.confirmPassword}
          error={Boolean(errors.confirmPassword)}
          helperText={
            errors.confirmPassword && touched.confirmPassword
              ? errors.confirmPassword
              : ''
          }
          endIcon={showConfirmPassword ? <EyeIcon /> : <EyeOffIcon />}
          endIconClick={() => setShowConfirmPassword(v => !v)}
        />
      </Grid>
    </Grid>
  );
}

export default LastStepForm;
