import {useState} from 'react';
import CalendarFilterMenuComponent from './component';
import {useAppointmentFilterOptions} from '$modules/frontdesk/hooks';
import {FilterOptions} from '$modules/frontdesk/type';
import {Updater, useImmer} from 'use-immer';

interface CalendarFilterMenuProps {
  selectedFilterOptions: FilterOptions;
  setSelectedFilterOptions: Updater<FilterOptions>;
}

function CalendarFilterMenu(props: CalendarFilterMenuProps) {
  const {selectedFilterOptions, setSelectedFilterOptions} = props;

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const [currentFilterOptions, setCurrentFilterOptions] =
    useImmer<FilterOptions>(selectedFilterOptions);

  const {filterOptions} = useAppointmentFilterOptions();

  const handleFilterClick: React.MouseEventHandler<HTMLButtonElement> = ev =>
    setAnchorElement(ev.currentTarget);

  const handleMenuClose = (resetCurrent = true) => {
    setAnchorElement(null);

    if (resetCurrent) {
      setCurrentFilterOptions(draft => {
        draft.status = selectedFilterOptions.status;
        draft.appointment_type = selectedFilterOptions.appointment_type;
        draft.consultant = selectedFilterOptions.consultant;
      });
    }
  };

  const handleReset = () => {
    setSelectedFilterOptions({
      consultant: [],
      appointment_type: [],
      status: [],
    });
    setCurrentFilterOptions({
      consultant: [],
      appointment_type: [],
      status: [],
    });
  };

  const handleSave = () => {
    handleMenuClose(false);
    setSelectedFilterOptions(draft => {
      draft.status = currentFilterOptions.status;
      draft.appointment_type = currentFilterOptions.appointment_type;
      draft.consultant = currentFilterOptions.consultant;
    });
  };

  return (
    <CalendarFilterMenuComponent
      setSelectedFilterOptions={setCurrentFilterOptions}
      filterOptions={filterOptions}
      selectedFilterOptions={currentFilterOptions}
      anchorElement={anchorElement}
      onFilterClick={handleFilterClick}
      onMenuClose={handleMenuClose}
      onReset={handleReset}
      onSave={handleSave}
    />
  );
}

export default CalendarFilterMenu;
