import {useEffect, useState} from 'react';
import FullCalendar from '@fullcalendar/react';
import {ViewApi} from '@fullcalendar/core';
import {CalendarView} from './constant';
import {momentDate} from '$utils/date';
import useAuthUser from '../../../../hooks/useAuthUser';
import {UserRoleName} from '$modules/admin/constant';

export function useCalendarApi(calendarRef: React.RefObject<FullCalendar>) {
  const [view, setView] = useState<ViewApi>();
  const [forceRenderFlag, setForceRenderFlag] = useState(false);

  const authUser = useAuthUser()!;
  const isFrontdesk = authUser.roleName === UserRoleName.Frontdesk;

  const currentView = (view?.type ||
    (isFrontdesk ? CalendarView.Day : CalendarView.Week)) as CalendarView;
  const title = view?.title || '';

  useEffect(() => {
    const api = calendarRef.current?.getApi();
    if (!api) return;

    setView(api.view);
  }, [calendarRef, forceRenderFlag]);

  const changeCalendarView = (view: CalendarView) => {
    const api = calendarRef.current?.getApi();
    if (!api) return;

    api.changeView(view);
    setForceRenderFlag(v => !v);
  };

  const handleCalendarNext = () => {
    const api = calendarRef.current?.getApi();
    if (!api) return;

    api.next();
    setForceRenderFlag(v => !v);
  };

  const handleCalendarPrev = () => {
    const api = calendarRef.current?.getApi();
    if (!api) return;

    api.prev();
    setForceRenderFlag(v => !v);
  };

  const getRangeText = () => {
    if (!view) return;

    const startDate = momentDate(view?.currentStart);
    const endDate = momentDate(view?.currentEnd);
    endDate.subtract({minute: 1});

    const start2Digit = startDate.format('DD');
    const end2Digit = endDate.format('DD');
    const startMonthShort = startDate.format('MMM');
    const monthShort = endDate.format('MMM');

    switch (currentView) {
      case CalendarView.Week:
        return `${start2Digit} ${startMonthShort !== monthShort ? startMonthShort : ''} - ${end2Digit} ${monthShort}`;
      case CalendarView.Month:
        return monthShort;
      default:
        return `${start2Digit} ${monthShort}`;
    }
  };

  return {
    startDate: view?.activeStart,
    endDate: view?.activeEnd,
    currentView,
    rangeText: getRangeText(),
    title,
    changeCalendarView,
    handleCalendarPrev,
    handleCalendarNext,
  };
}
