import {ApiRoute} from '~/constants/api';
import {
  GETAppointmentDetailsParams,
  GETAppointmentDetailsResponseData,
  PutAppointmentDetailsParams,
  PUTAppointmentDetailsResponseData,
} from './types';
import axiosInstance from '../axios';

export const getAppointmentDetails = async (
  props: GETAppointmentDetailsParams
) => {
  const {appointmentId} = props;
  const response = await axiosInstance.get<GETAppointmentDetailsResponseData>(
    ApiRoute.Appointment + '/' + appointmentId
  );
  return response.data;
};

export const putAppointmentDetails = async (
  props: PutAppointmentDetailsParams
) => {
  const {appointmentId, body} = props;

  const response = await axiosInstance.put<PUTAppointmentDetailsResponseData>(
    ApiRoute.Appointment + '/' + appointmentId,
    body
  );

  return response.data;
};
