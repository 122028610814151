import Box from '@mui/material/Box';
import styles from './styles';

interface IProgressStepper {
  itemCount: number;
  activeCount: number;
}

const ProgressStepper = (props: IProgressStepper) => {
  const {itemCount, activeCount} = props;

  const items = Array(itemCount)
    .fill(0)
    .map((_, i) => (
      <Box key={i} sx={i < activeCount ? styles.coloredItem : styles.item} />
    ));

  return <Box sx={styles.container}>{items}</Box>;
};

export default ProgressStepper;
