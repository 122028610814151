import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup, {RadioGroupProps} from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import FetchError from '$components/FetchError';
import Loading from '$components/Loading';
import RadioBox from '$components/RadioBox';
import {BookingData, BookingDataUpdater} from '../../types';
import {useAPIConstants} from '~/services/api/constants/hooks';
import {ConstantsAPIKey} from '~/services/api/constants/constants';
import CareTypeSection from './CareTypeSection';

interface AppointmentTypeSectionProps {
  formChoices: BookingData;
  setFormChoices: BookingDataUpdater;
}

function AppointmentTypeSection(props: AppointmentTypeSectionProps) {
  const {formChoices, setFormChoices} = props;

  const {data, isLoading} = useAPIConstants({
    key: ConstantsAPIKey.Appointment,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    return <FetchError />;
  }

  const handleAppointmentChange: RadioGroupProps['onChange'] = e => {
    const item = data.find(it => it.id.toString() === e.target.value);
    setFormChoices(draft => {
      draft.appointment = item || undefined;
    });
  };

  const appointmentTypeButtons = data.map(item => (
    <RadioBox
      key={item.id}
      value={item.id.toString()}
      label={item.title}
      disabled={!item.isEnable}
    />
  ));

  return (
    <>
      <FormControl>
        <FormLabel id="appointment-type1">
          <Typography variant="h4">What would you like to book?</Typography>
        </FormLabel>
        <RadioGroup
          value={formChoices.appointment?.id}
          name="radio-buttons-group"
          onChange={handleAppointmentChange}
        >
          {appointmentTypeButtons}
        </RadioGroup>
      </FormControl>
      {formChoices.appointment && (
        <CareTypeSection
          formChoices={formChoices}
          setFormChoices={setFormChoices}
        />
      )}
    </>
  );
}

export default AppointmentTypeSection;
