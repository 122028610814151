import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import './styles.scss';

interface FrontdeskSearchboxProps {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  startIcon?: React.ReactNode;
}

function FrontdeskSearchbox(props: FrontdeskSearchboxProps) {
  const {value, startIcon, placeholder, onChange} = props;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = ev => {
    onChange(ev.target.value);
  };

  return (
    <TextField
      className="front-desk-search-box-container"
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      InputProps={
        startIcon
          ? {
              startAdornment: (
                <InputAdornment position="start">{startIcon}</InputAdornment>
              ),
            }
          : undefined
      }
    />
  );
}

export default FrontdeskSearchbox;
