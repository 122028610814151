import {ApiRoute} from '$constants/api';
import axiosInstance from '..';

interface PatientCareType {
  id: number;
  name: string;
  color: string;
}

interface Address {
  id: number;
  line1: string;
  line2: string | null;
  city: string;
  city_name: string | null;
  state: string | null;
  country: string;
  country_name: string | null;
  postcode: string;
}

interface Invitation {
  id: number;
  email: string;
  accepted_at: string;
  sent_at: string;
  status: string;
}

export interface PatientData {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  mobile_number: string;
  email: string;
  dob: string;
  gender: string;
  marital_status: string | null;
  uniq_code: string | null;
  country_code: string;
  prefix: string;
  status: string | null;
  preferred_point_of_contact: string;
  hospital_id: number | null;
  registration_date: string;
  registration_status: boolean;
  address: Address;
  special_cares: PatientCareType[];
  invitation_details: Invitation;
  is_deleted: boolean;
}

export interface PatientDetailsApiResponse {
  success: boolean;
  data: PatientData;
  message: string;
}

export async function getPatientDetails(
  id: number
): Promise<PatientDetailsApiResponse | null> {
  if (!id) return null;
  const response = await axiosInstance.get<PatientDetailsApiResponse>(
    ApiRoute.Patients + `/${id}`
  );

  return {
    success: response.data.success,
    data: response.data.data,
    message: response.data.message,
  };
}

export interface PatientCareApiResponse {
  success: boolean;
  data: PatientCareType[];
  message: string;
}

export async function getPatientCare(): Promise<PatientCareApiResponse> {
  const response = await axiosInstance<PatientCareApiResponse>(
    ApiRoute.PatientCare
  );

  return {
    success: response.data.success,
    data: response.data.data,
    message: response.data.message,
  };
}

export interface PutPatientCarePayload {
  patientId: number;
  special_care: number[];
}

export interface PutPatientCareResponseData {
  message: string;
}

export async function putPatientCare(payload: PutPatientCarePayload) {
  const {patientId, special_care} = payload;
  const response = await axiosInstance.put<PutPatientCareResponseData>(
    ApiRoute.Patients + `/${patientId}${ApiRoute.UpdateDetails}`,
    {
      patient_detail: {
        special_care: special_care,
      },
    }
  );

  return response.data;
}

export interface PatientClinicalDetailsApiResponse {
  success: boolean;
  data: {
    id: number;
    diagnosis: string;
    doctor_id: number;
    doctor_full_name?: string;
  };
  message: string;
}

export async function getPatientClinicalDetails(
  patientId: number
): Promise<PatientClinicalDetailsApiResponse> {
  const response = await axiosInstance.get<PatientClinicalDetailsApiResponse>(
    ApiRoute.Patients + `/${patientId}${ApiRoute.ClinicalDetails}`
  );
  return {
    success: response.data.success,
    data: response.data.data,
    message: response.data.message,
  };
}

export interface PutPatientClinicalDetailsPayload {
  patientId: number;
  diagnosis: string;
  doctor_id: number;
}

export interface PutPatientClinicalDetailsResponseData {
  message: string;
}

export async function putPatientClinicalDetail(
  payload: PutPatientClinicalDetailsPayload
) {
  const {patientId, diagnosis, doctor_id} = payload;
  const response =
    await axiosInstance.put<PutPatientClinicalDetailsResponseData>(
      ApiRoute.Patients + `/${patientId}${ApiRoute.UpdateClinicalDetails}`,
      {
        clinic_data: {
          diagnosis,
          doctor_id,
        },
      }
    );

  return response.data;
}

export interface PutPatientStatusPayload {
  patientId: number;
  status: string;
}

export interface PutPatientClinicalDetailsResponseData {
  message: string;
}

export async function putPatientStatus(payload: PutPatientStatusPayload) {
  const {patientId, status} = payload;
  const response =
    await axiosInstance.put<PutPatientClinicalDetailsResponseData>(
      ApiRoute.Patients + `/${patientId}${ApiRoute.UpdateStatus}`,
      {
        patient: {
          status,
        },
      }
    );

  return response.data;
}

export interface PutPatientDetailPayload {
  patient: {
    dob: string;
    email: string;
    mobile_number: number;
    gender: string;
    address: {
      id: number;
      postcode: string;
      line1: string;
      line2: string;
      city: string;
      country: string;
    };
  };
}

export interface putPatientDetailResponseData {
  success: boolean;
  data?: [];
  errors?: [];
  message: string;
}

export async function putPatientDetail(
  patientId: number,
  payload: PutPatientDetailPayload
) {
  const response = await axiosInstance.put<putPatientDetailResponseData>(
    ApiRoute.Patients + `/${patientId}`,
    payload
  );
  return response.data;
}
