import {useState} from 'react';
import OfflineBookingComponent from './component';
import PatientDetailsOne from './PatientDetails/PatientDetailsOne';
import SelectOrBookPatient from './SelectOrBookPatient';
import PatientDetailsTwo from './PatientDetails/PatientDetailsTwo';
import ContactDetails from './PatientDetails/ContactDetails';
import AppointmentTypeSelection from './AppointmentTypeSelection';
import ConsultationSelection from './ConsultationSelection';
import ConsultantSelection from './ConsultantSelection';
import DateAndTimeSelection from './DateAndTimeSelection';
import BookingConfirmed from './BookingConfirmed';
import PaymentDetails from './PaymentDetails';
import {INITIAL_FORM_STATE, OfflineBookingStep} from './constant';
import {FormState} from './types';
import OperationTheatreContainer from './OperationTheatre';

interface OfflineBookingContainerProps {
  newAppointmentModalOpen: boolean;
  handleNewAppointmentModalOpen: () => void;
}
const OfflineBookingContainer: React.FC<OfflineBookingContainerProps> = ({
  newAppointmentModalOpen,
  handleNewAppointmentModalOpen,
}) => {
  let dialogBody = null;
  const [activestep, setActiveStep] = useState<OfflineBookingStep>(
    OfflineBookingStep.SelectOrBookPatient
  );
  const [offlineBookingType, setOfflineBookingType] = useState<string>('new');
  const [offlineBookingFormData, setOfflineBookingFormData] =
    useState<FormState>(INITIAL_FORM_STATE);
  const [offlineBookedAppointmentId, setOfflineBookedAppointmentId] =
    useState<number>(NaN);

  switch (activestep) {
    case OfflineBookingStep.SelectOrBookPatient:
      dialogBody = (
        <SelectOrBookPatient
          setActiveStep={setActiveStep}
          offlineBookingFormData={offlineBookingFormData}
          setOfflineBookingFormData={setOfflineBookingFormData}
          offlineBookingType={offlineBookingType}
          setOfflineBookingType={setOfflineBookingType}
        />
      );
      break;
    case OfflineBookingStep.PatientDetailsOne:
      dialogBody = (
        <PatientDetailsOne
          offlineBookingFormData={offlineBookingFormData}
          setActiveStep={setActiveStep}
          setOfflineBookingFormData={setOfflineBookingFormData}
        />
      );
      break;
    case OfflineBookingStep.PatientDetailsTwo:
      dialogBody = (
        <PatientDetailsTwo
          offlineBookingFormData={offlineBookingFormData}
          setActiveStep={setActiveStep}
          setOfflineBookingFormData={setOfflineBookingFormData}
        />
      );
      break;
    case OfflineBookingStep.ContactDetails:
      dialogBody = (
        <ContactDetails
          setActiveStep={setActiveStep}
          offlineBookingFormData={offlineBookingFormData}
          setOfflineBookingFormData={setOfflineBookingFormData}
        />
      );
      break;
    case OfflineBookingStep.AppointmentTypeSelection:
      dialogBody = (
        <AppointmentTypeSelection
          setActiveStep={setActiveStep}
          offlineBookingFormData={offlineBookingFormData}
          setOfflineBookingFormData={setOfflineBookingFormData}
          offlineBookingType={offlineBookingType}
        />
      );
      break;
    case OfflineBookingStep.ConsultationSelection:
      dialogBody = (
        <ConsultationSelection
          setActiveStep={setActiveStep}
          offlineBookingFormData={offlineBookingFormData}
          setOfflineBookingFormData={setOfflineBookingFormData}
          offlineBookingType={offlineBookingType}
        />
      );
      break;
    case OfflineBookingStep.ConsultantSelection:
      dialogBody = (
        <ConsultantSelection
          setActiveStep={setActiveStep}
          offlineBookingFormData={offlineBookingFormData}
          setOfflineBookingFormData={setOfflineBookingFormData}
        />
      );
      break;
    case OfflineBookingStep.DateAndTimeSelection:
      dialogBody = (
        <DateAndTimeSelection
          setActiveStep={setActiveStep}
          offlineBookingFormData={offlineBookingFormData}
          setOfflineBookingFormData={setOfflineBookingFormData}
          offlineBookingType={offlineBookingType}
        />
      );
      break;
    case OfflineBookingStep.PaymentDetails:
      dialogBody =
        offlineBookingType === 'new' ? (
          <PaymentDetails
            setActiveStep={setActiveStep}
            offlineBookingFormData={offlineBookingFormData}
            setOfflineBookingFormData={setOfflineBookingFormData}
            offlineBookingType={offlineBookingType}
            setOfflineBookedAppointmentId={setOfflineBookedAppointmentId}
          />
        ) : (
          <OperationTheatreContainer
            setActiveStep={setActiveStep}
            offlineBookingFormData={offlineBookingFormData}
            setOfflineBookingFormData={setOfflineBookingFormData}
            offlineBookingType={offlineBookingType}
            setOfflineBookedAppointmentId={setOfflineBookedAppointmentId}
          />
        );
      break;
    case OfflineBookingStep.BookingConfirmed:
      dialogBody = (
        <BookingConfirmed
          appointmentId={offlineBookedAppointmentId}
          offlineBookingType={offlineBookingType}
          offlineBookingFormData={offlineBookingFormData}
        />
      );
      break;
    default:
      dialogBody = (
        <SelectOrBookPatient
          setActiveStep={setActiveStep}
          offlineBookingFormData={offlineBookingFormData}
          setOfflineBookingFormData={setOfflineBookingFormData}
          offlineBookingType={offlineBookingType}
          setOfflineBookingType={setOfflineBookingType}
        />
      );
  }

  return (
    <OfflineBookingComponent
      open={newAppointmentModalOpen}
      handleClose={handleNewAppointmentModalOpen}
      dialogBody={dialogBody}
      activeStep={activestep}
    />
  );
};

export default OfflineBookingContainer;
