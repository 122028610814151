export const enum ConstantsAPIKey {
  Appointment = 'appointment',
  Care = 'care',
  Subcare = 'sub_care',
  Consultant = 'consultant',
  CancellationReason = 'cancellation_reason',
  CardType = 'card_type',
  CountryList = 'country_list',
  Hospital = 'hospital',
  Insurance = 'insurance',
  PaymentMode = 'payment_mode',
  PreferredPointOfContact = 'preferred_point_of_contact',
  PreferredTime = 'preferred_time',
  Relationship = 'relationship',
  Address = 'address',
}
