import {useQuery} from '@tanstack/react-query';
import {getMedicalHistory} from './service';
import toast from '~/utils/toast';
import {ToastType} from '~/components/Toast/constant';
import {downloadMedicalHistoryPDF} from './utils';

export function useMedicalHistory(patientId: number) {
  const {
    data: medicalHistory,
    isLoading: isMedicalHistoryLoading,
    isError: isMedicalHistoryFetchError,
  } = useQuery({
    queryKey: ['patient-medical-history', patientId],
    queryFn: () => getMedicalHistory({patientId}),
  });

  const downloadPDFWithToast = async (questionnaireType: string) => {
    await downloadMedicalHistoryPDF(patientId, questionnaireType)
      .then(() => {
        return toast(
          ToastType.Success,
          'Medical questionnaire full list',
          'PDF downloaded successfully'
        );
      })
      .catch(error => {
        const errorMessage =
          error instanceof Error ? error.message : 'An unknown error occurred';
        toast(
          ToastType.Error,
          'Medical questionnaire full list',
          `Failed to download PDF: ${errorMessage}`
        );
      });
  };

  return {
    medicalHistory: medicalHistory?.data.medicalHistory || [],
    isMedicalHistoryLoading,
    isMedicalHistoryFetchError,
    downloadMedicalHistoryPDF: downloadPDFWithToast,
  };
}
