import {Chip} from '@mui/material';
import React from 'react';
import styles from './styles';

interface ChipComponentProps {
  label: string;
  bgColor: string;
  textColor: string;
  icon?: React.ReactNode;
  hasIcon?: boolean;
  className?: string;
}
const ChipComponent: React.FC<ChipComponentProps> = ({
  label,
  bgColor,
  textColor,
  icon,
  hasIcon,
  className,
}) => {
  return (
    <Chip
      label={label}
      className={className}
      icon={icon ? <span>{icon}</span> : undefined}
      sx={{
        backgroundColor: bgColor,
        height: 'auto',
        ...(hasIcon ? styles.detailedContainer : styles.container),
        '& .MuiChip-label': {
          color: textColor,
        },
      }}
    />
  );
};

export default ChipComponent;
