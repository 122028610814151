import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {SelectInputProps as MUISelectInputProps} from '@mui/material/Select/SelectInput';
import styles, {menuPaperStyles} from './styles';

interface SelectInputProps<T> {
  name?: string;
  items: T[];
  value: string;
  fullWidth?: boolean;
  placeholder?: string;
  error?: boolean;
  testId?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange: (value: string) => void;
  renderValue: (value: string) => React.ReactNode;
  renderItem: (item: T) => React.ReactNode;
}

function SelectInput<T>(props: SelectInputProps<T>) {
  const {
    name,
    items,
    value,
    placeholder,
    fullWidth,
    error,
    testId,
    onBlur,
    onChange,
    renderValue,
    renderItem,
  } = props;

  const menuItems = items.map(renderItem);

  const handleChange: MUISelectInputProps<string>['onChange'] = event => {
    onChange(event.target.value);
  };

  return (
    <FormControl sx={styles.container} fullWidth={fullWidth}>
      <Select
        name={name}
        placeholder={placeholder}
        displayEmpty
        value={value}
        error={error}
        onBlur={onBlur}
        onChange={handleChange}
        renderValue={renderValue}
        MenuProps={{
          slotProps: {
            paper: {
              style: menuPaperStyles,
            },
          },
        }}
        inputProps={{'aria-label': 'Without label'}}
        data-testid={testId}
      >
        {placeholder && <MenuItem value="">{placeholder}</MenuItem>}
        {menuItems}
      </Select>
    </FormControl>
  );
}

export default SelectInput;
