import {useState} from 'react';
import {useFormik} from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import RadioBox from '$components/RadioBox';
import theme from '$modules/booking/theme';
import BookingStepper from '../components/BookingStepper';
import EnterSystemForm from '../components/EnterSystemForm';
import Footer from '../components/Footer';
import EmailVerificationForm from '../components/EmailVerificationForm';
import {
  EnterSystemFormData,
  enterSystemFormSchema,
} from '../components/EnterSystemForm/schema';
import {useBookingSignIn} from '../hooks';
import styles, {registerWithStoneyGateViewStyles} from '../styles';
import {
  BookingDataUpdater,
  BookingStepperStage,
  BookingView,
  RegisterWithStoneygateViewChoices,
} from '../types';

interface RegisterWithStoneygateViewProps {
  bookingChoices: RegisterWithStoneygateViewChoices;
  setActiveView: (view: BookingView) => void;
  setBookingChoices: BookingDataUpdater;
}
export default function RegisterWithStoneygateView(
  props: RegisterWithStoneygateViewProps
) {
  const {bookingChoices, setActiveView, setBookingChoices} = props;

  const registered = bookingChoices.registered;

  const isEmailVerifiedAlready = bookingChoices.registered?.isEmailvalidated;

  const {disableSubmit, isPending, signIn, sendForgotPasswordRequest} =
    useBookingSignIn(setActiveView, setBookingChoices);

  const [isValidateOtpSuccess, setIsValidateOtpSuccess] = useState(false);
  const [isEmailCleared, setIsEmailCleared] = useState(false);

  const handleFormSubmit = (values: EnterSystemFormData) => {
    const email = values.email?.trim() ?? '';
    const password = values.password?.trim() ?? '';

    if (email && password) {
      signIn({
        email: email,
        password: password,
      });
    } else {
      setActiveView(BookingView.PatientCarerDetailsView);
    }
  };

  const formik = useFormik<EnterSystemFormData>({
    initialValues: {
      email: bookingChoices.registered?.email || '',
      password: bookingChoices.password || '',
    },
    validationSchema: enterSystemFormSchema,
    onSubmit: handleFormSubmit,
  });

  const handleButtonClick = (value: boolean) => {
    if (value !== registered?.isRegistered) {
      setBookingChoices(draft => {
        draft.registered = {isRegistered: value};
      });
    }
  };

  const handleBackClick = () => {
    setActiveView(BookingView.ChoicesConfirmationView);
  };

  const handleContinueClick = () => {
    if (registered?.isRegistered) {
      formik.handleSubmit();
    } else {
      setBookingChoices(draft => {
        draft.registered = {
          isRegistered: false,
          patientId: registered?.patientId,
          email: bookingChoices.registered?.email || '',
          isEmailvalidated: isEmailVerifiedAlready,
        };
      });
      setActiveView(BookingView.PatientCarerDetailsView);
    }
  };

  const disableContinueButton =
    disableSubmit ||
    registered === undefined ||
    (registered.isRegistered && !formik.values.email) ||
    (!registered.isRegistered && !formik.values.email) ||
    (!isEmailVerifiedAlready && !isValidateOtpSuccess) ||
    (isEmailVerifiedAlready && !formik.values.email) ||
    isEmailCleared;

  const disableContinueButtonForYesClick =
    disableSubmit || !formik.values.email || !formik.values.password;

  return (
    <Box sx={styles.sectionWrapperWithStepper} flexDirection="column" gap={5}>
      <Stack gap={5} sx={styles.section}>
        <BookingStepper activeStage={BookingStepperStage.PatientDetails} />
        <Typography variant="h2" sx={styles.subHeading}>
          Are you already registered on the Stoneygate online platform?
        </Typography>
        <RadioGroup value={registered?.isRegistered || false}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Button
              onClick={() => handleButtonClick(true)}
              sx={registerWithStoneyGateViewStyles.radioContainer}
              variant="contained"
              color="secondary"
            >
              <RadioBox
                label="Yes"
                value={String(true)}
                checked={registered?.isRegistered === true}
              />
            </Button>
            <Button
              onClick={() => handleButtonClick(false)}
              sx={registerWithStoneyGateViewStyles.radioContainer}
              variant="contained"
              color="secondary"
            >
              <RadioBox
                label="No"
                value={String(false)}
                checked={registered?.isRegistered === false}
              />
            </Button>
          </Box>
        </RadioGroup>
        {registered?.isRegistered && (
          <Box display={'flex'} flexDirection={'column'} gap={4}>
            <Box>
              <Typography variant="h2" sx={styles.subHeading}>
                Do you want to enter the system?
              </Typography>
              <Typography variant="body1" color={theme.palette.text.primary}>
                You can also continue with the booking process without entering
                the system.
              </Typography>
            </Box>
            <EnterSystemForm
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              setTouched={formik.setTouched}
              setFieldTouched={formik.setFieldTouched}
              sendForgotPasswordRequest={sendForgotPasswordRequest}
            />
          </Box>
        )}
        {registered?.isRegistered === false && (
          <EmailVerificationForm
            bookingChoices={bookingChoices}
            isDisabled={isEmailVerifiedAlready}
            isEmailVerifiedAlready={isEmailVerifiedAlready}
            setBookingChoices={setBookingChoices}
            setActiveView={setActiveView}
            setIsValidateOtpSuccess={setIsValidateOtpSuccess}
            setIsEmailCleared={setIsEmailCleared}
          />
        )}
      </Stack>

      <Box sx={styles.footerSection}>
        <Footer
          disableBack={disableSubmit}
          disableContinue={
            registered?.isRegistered === undefined
              ? true
              : registered?.isRegistered
                ? disableContinueButtonForYesClick
                : disableContinueButton
          }
          continueLoading={isPending}
          onBackClick={handleBackClick}
          onContinueClick={handleContinueClick}
        />
      </Box>
    </Box>
  );
}
