import DatePicker from '$components/DatePicker';
import TimeSlotPicker from '$components/TimeSlotPicker';
import {Consultant} from '$modules/booking/types';
import {Slot} from '$modules/frontdesk/type';
import {dateToDDMMYYYY} from '$utils/date';
import useTimezone from '../../../../../hooks/useTimezone';
import {FormState} from '../types';
import {TITLE_MAP} from './constant';
import './styles.scss';

interface DateAndTimeSelectionComponentProps {
  isPendingAvailableSlots: boolean;
  consultants: Consultant[];
  slots: Slot[];
  handleDateChange: (date: Date | null) => void;
  offlineBookingFormData: FormState;
  handleSlotSelection: (slot: Slot) => void;
  dateSelected: Date | null;
  offlineBookingType: string;
}
const DateAndTimeSelectionComponent: React.FC<
  DateAndTimeSelectionComponentProps
> = ({
  isPendingAvailableSlots,
  consultants,
  slots,
  handleDateChange,
  offlineBookingFormData,
  handleSlotSelection,
  dateSelected,
  offlineBookingType,
}) => {
  const timezone = useTimezone();

  const selectedDate = offlineBookingFormData.appointment.date;
  const selectedStartTime = offlineBookingFormData.appointment.start_time;
  const selectedEndTime = offlineBookingFormData.appointment.end_time;
  const selectedDoctorId = offlineBookingFormData.appointment.doctor_id;
  const doctor = consultants.find(it => it.id === Number(selectedDoctorId));

  const selectedSlot: Slot | undefined =
    selectedStartTime && selectedEndTime
      ? {
          startTime: new Date(selectedStartTime),
          endTime: new Date(selectedEndTime),
        }
      : undefined;

  return (
    <>
      <h2 className="dialog-title">{TITLE_MAP[offlineBookingType]}</h2>
      <div className="select-date-time-container">
        <div className="input-container">
          <DatePicker
            disablePast
            name="date"
            placeholder="Select a date"
            value={selectedDate ? selectedDate : null}
            onChange={handleDateChange}
            timezone={timezone}
          />
        </div>

        {offlineBookingType === 'new' && dateSelected && (
          <>
            <div className="doctor-availability-text">
              {doctor && doctor.fullName} availabilities for{' '}
              {selectedDate ? dateToDDMMYYYY(selectedDate) : '-'}
            </div>
            {slots.length > 0 ? (
              <div className="slot-container">
                <TimeSlotPicker
                  isLoading={isPendingAvailableSlots}
                  slots={slots}
                  selectedSlot={selectedSlot}
                  setSelectedSlot={handleSlotSelection}
                  consultantName={doctor?.fullName}
                />
              </div>
            ) : (
              <div className="no-slots">No slots available</div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DateAndTimeSelectionComponent;
