import React from 'react';
import {Patients} from '$api/frontdesk';
import {dateToDDMMYYYY} from '$utils/date';
import {FormState} from './types';
import Loading from '$components/Loading';

interface SearchPatientResultProps {
  searchText: string;
  patients: Patients[] | null;
  setOfflineBookingFormData: React.Dispatch<React.SetStateAction<FormState>>;
  setOfflineBookingType: React.Dispatch<React.SetStateAction<string>>;
  setSelectedPatientId: React.Dispatch<React.SetStateAction<number | null>>;
  isPending: boolean;
}

const SearchPatientResult: React.FC<SearchPatientResultProps> = ({
  searchText,
  patients,
  setOfflineBookingFormData,
  setOfflineBookingType,
  setSelectedPatientId,
  isPending,
}) => {
  const handleClick = (id: number) => {
    setSelectedPatientId(id);
    setOfflineBookingType('surgery');
    setOfflineBookingFormData((prev: FormState) => ({
      appointment: {
        ...prev.appointment,
        patient: {
          id: id,
          address: {id: null},
        },
      },
    }));
  };

  const highlightMatch = (fullName: string, searchText: string) => {
    if (!searchText) return fullName;

    const parts = fullName.split(new RegExp(`(${searchText})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === searchText.toLowerCase() ? (
            <strong key={index}>{part}</strong>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  return (
    <div className="search-patient-info-container">
      {isPending ? (
        <Loading />
      ) : patients && patients.length > 0 ? (
        patients.map(patient => (
          <div
            key={patient.id}
            className="search-patient-info-item"
            onClick={() => handleClick(patient.id)}
          >
            <div className="patient-name">
              {highlightMatch(patient.fullName, searchText)}
            </div>
            <div className="patient-dob">{dateToDDMMYYYY(patient.dob)}</div>
          </div>
        ))
      ) : (
        <div className="no-data-found">No data found</div>
      )}
    </div>
  );
};

export default SearchPatientResult;
