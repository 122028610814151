import {useMemo} from 'react';
import {CalendarEvent} from './CalendarView/type';
import {
  CALENDAR_TIMELINE_COLUMN_WIDTH,
  CALENDAR_TIMELINE_PIXEL_MINUTE_RATIO,
} from '../constant';
import {momentDate} from '$utils/date';
import {getAppointmentStatus, statusColorMap} from './utils';

interface CalendarTimelineProps {
  slotsStartTime: Date;
  slotsEndTime: Date;
  appointmentStatus: string;
  activeEvents: CalendarEvent[];
  events: CalendarEvent[];
}

function CalendarTimeline(props: CalendarTimelineProps) {
  const {
    slotsStartTime,
    slotsEndTime,
    activeEvents,
    appointmentStatus,
    events,
  } = props;

  const renderEvent = (event: CalendarEvent, active: boolean) => {
    const {title, start, end} = event;

    if (!(start >= slotsStartTime && end <= slotsEndTime)) return;

    const calendarStartTime = new Date(slotsStartTime);

    const startHours = momentDate(start).format('HH');
    const startMinutes = momentDate(start).format('mm');

    const endHours = momentDate(end).format('HH');
    const endMinutes = momentDate(end).format('mm');

    const timeText = `${startHours}:${startMinutes} - ${endHours}:${endMinutes}`;

    const startTime = (start.getTime() - calendarStartTime.getTime()) / 60000;
    const endTime = (end.getTime() - calendarStartTime.getTime()) / 60000;

    const startPixels = startTime * CALENDAR_TIMELINE_PIXEL_MINUTE_RATIO;
    const widthPixels =
      (endTime - startTime) * CALENDAR_TIMELINE_PIXEL_MINUTE_RATIO;

    return (
      <div
        key={title + event.start.toISOString()}
        className={`fc-event-main absolute top-20 ${active ? 'active' : ''}`}
        style={{
          left: startPixels,
          width: widthPixels,
        }}
      >
        <div className="fc-event-main-frame overflow-auto">
          <p className="fc-event-time mb-2">{timeText}</p>
          <p className="fc-event-week-title mb-2">{title}</p>
          {appointmentStatus !== 'cancel' && (
            <div
              className={`${statusColorMap[appointmentStatus]} rounded-[4px] font-semibold text-xs text-white px-1 py-0.5  mb-2`}
            >
              {getAppointmentStatus(appointmentStatus)}
            </div>
          )}
        </div>
      </div>
    );
  };

  const columns = useMemo(() => {
    const list = [];

    const calendarTime = momentDate(slotsStartTime);
    calendarTime.set({minutes: 0});

    while (calendarTime.isBefore(momentDate(slotsEndTime))) {
      const hoursString = calendarTime.format('HH');
      const minutesString = calendarTime.format('mm');

      list.push(
        <div
          key={calendarTime.valueOf()}
          className="flex-shrink-0"
          style={{width: CALENDAR_TIMELINE_COLUMN_WIDTH}}
        >
          <div className="calendar-timeline-cell p-3">
            {hoursString}:{minutesString}
          </div>
          <div className="calendar-timeline-cell h-48"></div>
        </div>
      );

      calendarTime.add({minutes: 30});
    }

    return list;
  }, [slotsStartTime, slotsEndTime]);

  const eventCards = events.map(info => renderEvent(info, false));
  const activeEventCards = activeEvents.map(info => renderEvent(info, true));

  return (
    <div className="calendar-timeline-container rounded-xl bg-white my-3 flex overflow-auto relative w-fit max-w-full">
      {columns}
      <div>{eventCards}</div>
      <div>{activeEventCards}</div>
    </div>
  );
}

export default CalendarTimeline;
