import {ClockCheckIcon} from '$assets/svgs';
import ChipComponent from '$components/ChipComponent';
import {Typography, Tooltip} from '@mui/material';
import {listItemDateDay} from './styles';
import {getChipStatusColors, parseSnakeCase} from '$modules/frontdesk/utils';
import {AppointmentInfo} from '$modules/frontdesk/type';
import {useLocation, useNavigate} from 'react-router-dom';
import {RouteSection, getRoute} from '$utils/route';
import {RoutePath} from '$constants/routes';
import {momentDate} from '$utils/date';
import {formatFullName} from '$utils/helper';
import NoDataFound from '$components/NoDataFound';
import Loading from '$components/Loading';

interface ListComponentProps {
  appointments: AppointmentInfo[];
  isLoading: boolean;
}

const ListComponent: React.FC<ListComponentProps> = ({
  appointments,
  isLoading,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;

  return (
    <div className="flex w-full flex-col gap-4 h-screen">
      {isLoading ? (
        <Loading color="info" />
      ) : appointments.length > 0 ? (
        <div className="flex w-full flex-col gap-4">
          {appointments.map(ele => {
            if (ele.appointment.status === 'pending') return;

            const {bgColor, textColor} = getChipStatusColors(
              ele.appointment?.status || 'pending'
            );

            const handleNavigation = () => {
              navigate(
                getRoute(
                  RouteSection.Calendar,
                  RoutePath.staff.calendar.bookingDetails,
                  ele.appointment.id
                ),
                {state: {backRoute: pathName}}
              );
            };

            const date = momentDate(ele.appointment?.date);
            const startTime = momentDate(ele.appointment?.startTime);
            const endTime = momentDate(ele.appointment?.endTime);

            const appointmentDay = date.format('ddd');
            const appointmentDate = date.format('D');

            const startTimeString = startTime.format('HH:mm');
            const endTimeString = endTime.format('HH:mm');

            const patientName = ele.appointment?.patientName || '-';
            const nameElement =
              patientName.length > 30 ? (
                <Tooltip title={patientName}>
                  <span>{formatFullName(patientName)}</span>
                </Tooltip>
              ) : (
                <span>{patientName}</span>
              );

            return (
              <div
                key={ele.appointment?.id}
                onClick={handleNavigation}
                className="w-full min-w-fit cursor-pointer rounded-xl border-[1px] border-solid border-[#47546726] bg-white px-4 py-2"
              >
                <div className="flex w-full items-center justify-between">
                  <div className="flex flex-col justify-center border-0 border-r-[1px] border-solid border-[#47546726] pr-4">
                    <Typography variant="body1" sx={listItemDateDay}>
                      {date.isValid() ? appointmentDay : '-'}
                    </Typography>
                    <Typography variant="dayTitle">
                      {date.isValid() ? appointmentDate : '-'}
                    </Typography>
                  </div>
                  <div className="flex w-full items-center justify-between pl-4">
                    <div className="w-full">
                      <Typography variant="h4">{nameElement}</Typography>
                      <div className="flex gap-4">
                        <Typography variant="careTitle">
                          {ele.appointment?.type || '-'}
                        </Typography>
                        <div className="flex items-center gap-1">
                          <ClockCheckIcon />
                          {startTime.isValid() && endTime.isValid() ? (
                            <Typography variant="careTitle">{`${startTimeString} - ${endTimeString}`}</Typography>
                          ) : (
                            <Typography variant="careTitle">
                              No time specified
                            </Typography>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <ChipComponent
                      label={parseSnakeCase(ele.appointment?.status)}
                      bgColor={bgColor}
                      textColor={textColor}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        !isLoading && (
          <div className="no-data-found-wrapper">
            <NoDataFound
              dataTitle="No Appointments yet!"
              dataDescription=" Appointment will appear once the patient books the
                      appointment"
            />
          </div>
        )
      )}
    </div>
  );
};

export default ListComponent;
