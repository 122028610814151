import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup, {RadioGroupProps} from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import FetchError from '$components/FetchError';
import Loading from '$components/Loading';
import RadioBox from '$components/RadioBox';
import {BookingData, BookingDataUpdater} from '../../types';
import {useAPIConstants} from '~/services/api/constants/hooks';
import {ConstantsAPIKey} from '~/services/api/constants/constants';

interface SubcareTypeSectionProps {
  formChoices: BookingData;
  setFormChoices: BookingDataUpdater;
}

function SubcareTypeSection(props: SubcareTypeSectionProps) {
  const {formChoices, setFormChoices} = props;

  const {data, isLoading} = useAPIConstants({
    key: ConstantsAPIKey.Subcare,
    careId: formChoices?.care?.id,
  });

  if (isLoading) return <Loading />;
  if (!data) return <FetchError />;

  const handleSubcareChange: RadioGroupProps['onChange'] = e => {
    const item = data.find(it => it.id.toString() === e.target.value);
    setFormChoices(draft => {
      draft.subcare = item || undefined;
    });
  };

  const subcareTypeButtons = data.map(item => (
    <RadioBox
      key={item.id}
      value={item.id.toString()}
      label={item.title}
      disabled={!item.isEnable}
    />
  ));

  return (
    <FormControl>
      <FormLabel id="appointment-type1">
        <Typography variant="h4">
          What surgery are you interested in?
        </Typography>
      </FormLabel>
      <RadioGroup
        value={formChoices.subcare?.id}
        name="radio-buttons-group"
        onChange={handleSubcareChange}
      >
        {subcareTypeButtons}
      </RadioGroup>
    </FormControl>
  );
}

export default SubcareTypeSection;
