import {useQuery} from '@tanstack/react-query';
import {getHospitalInfo} from '$api/login';
import {setMomentTimezone} from '$utils/date';
import {HospitalInfo} from '../types/globalTypes';

function useHospitalInfo() {
  const {data, isLoading} = useQuery({
    queryKey: ['hospital-info'],
    queryFn: getHospitalInfo,
  });

  const responseData = data?.data;
  const timezone = responseData?.timezone;

  const hospitalInfo: HospitalInfo | undefined = responseData
    ? {
        id: responseData.id,
        timezone: responseData.timezone,
        hospitalURL: responseData.more_info_url,
        logoURL: responseData.logo_url,
        backgroundImageURL: responseData.background_img_url,
        title: responseData.title,
        description: responseData.description,
        mobileNumber: responseData.mobile_number,
      }
    : undefined;

  if (timezone) setMomentTimezone(timezone);

  return {hospitalInfo, isLoading};
}

export default useHospitalInfo;
