import {MenuItem, Typography} from '@mui/material';
import {FormikErrors, FormikTouched} from 'formik';
import {useCities, useCountries} from '~/modules/booking/hooks';
import {EditBookingFormData} from '../schema';
import {Color} from '~/constants/style';
import useTimezone from '~/hooks/useTimezone';
import Loading from '~/components/Loading';
import FetchError from '~/components/FetchError';
import PatientDetailsComponent from './component';

interface PatientDetailsIndexProps {
  values: EditBookingFormData;
  errors: FormikErrors<EditBookingFormData>;
  touched: FormikTouched<EditBookingFormData>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  setFieldTouched: (field: string, touched?: boolean) => void;
  setFieldValue: (field: string, value: unknown) => void;
}

const PatientDetailsIndex = (props: PatientDetailsIndexProps) => {
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    touched,
    values,
  } = props;

  const timezone = useTimezone();

  const {
    countries,
    isError: isCountriesError,
    isLoading: isCountriesLoading,
  } = useCountries();
  const {
    cities,
    isError: isCitiesError,
    isLoading: isCitiesLoading,
  } = useCities(values?.appointment?.patient?.address?.country);

  if (isCountriesLoading || isCitiesLoading) {
    return <Loading />;
  }

  if (!countries || !cities || isCountriesError || isCitiesError) {
    return <FetchError />;
  }

  const renderGenderMenuItem = (item: string) => (
    <MenuItem key={item} value={item}>
      {item}
    </MenuItem>
  );

  const renderGenderValue = (value: string) => (
    <Typography color={value ? undefined : Color.TextPrimayPlaceholder}>
      {value}
    </Typography>
  );

  const renderCountryValue = (value: string) => {
    const item = countries.find(it => it.code === value);
    if (item) value = item.name;

    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value ? value : 'Select country'}
      </Typography>
    );
  };

  const renderCountryItem = (item: {code: string; name: string}) => (
    <MenuItem key={item.code} value={item.code}>
      {item.name}
    </MenuItem>
  );

  const renderCityValue = (value: string) => {
    const item = cities.find(it => it.code === value);
    if (item) value = item.name;

    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value === '' ? 'Select City' : value}
      </Typography>
    );
  };

  const renderCityItem = (item: {code: string; name: string}) => (
    <MenuItem key={item.code} value={item.code}>
      {item.name}
    </MenuItem>
  );

  const renderGPOrOpticianReferralValue = (value: string) => {
    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value === '' ? 'Select an option' : value}
      </Typography>
    );
  };

  const renderGPOrOpticianReferralItem = (item: string) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  };

  const renderSelfBookingValue = (value: string) => {
    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value === '' ? 'Select an option' : value}
      </Typography>
    );
  };
  const renderSelfBookingItem = (item: string) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  };

  const handleFieldChange = (
    field: string,
    value: number | string | boolean | Date
  ) => {
    field = `appointment.${field}`;
    setFieldValue(field, value);

    if (field === 'appointment.patient.address.country') {
      setFieldValue('appointment.patient.address.city', '');
      setFieldTouched('appointment.patient.address.city', false);
    }
  };

  return (
    <PatientDetailsComponent
      setFieldChanged={setFieldTouched}
      setFieldValue={setFieldValue}
      handleFieldChange={handleFieldChange}
      renderGPOrOpticianReferralItem={renderGPOrOpticianReferralItem}
      renderGPOrOpticianReferralValue={renderGPOrOpticianReferralValue}
      renderSelfBookingItem={renderSelfBookingItem}
      renderSelfBookingValue={renderSelfBookingValue}
      timezone={timezone}
      errors={errors}
      handleBlur={handleBlur}
      handleChange={handleChange}
      renderGenderMenuItem={renderGenderMenuItem}
      renderGenderValue={renderGenderValue}
      renderCountryItem={renderCountryItem}
      renderCountryValue={renderCountryValue}
      renderCityItem={renderCityItem}
      renderCityValue={renderCityValue}
      touched={touched}
      values={values}
      cities={cities}
      countries={countries}
    />
  );
};

export default PatientDetailsIndex;
