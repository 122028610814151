import React from 'react';
import {FormikErrors, FormikTouched} from 'formik';
import {ContactDetailIcon} from '~/assets/svgs';
import Input from '~/components/Input';
import PhoneNumberInput from '~/modules/booking/components/PhoneNumberInput';
import SelectInput from '~/components/SelectInput';
import {EditBookingFormData} from '../schema';

interface ContactDetailsComponentProps {
  country: string;
  preferredContactOptions: string[];
  values: EditBookingFormData;
  errors: FormikErrors<EditBookingFormData>;
  touched: FormikTouched<EditBookingFormData>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  renderPreferredPointOfContactItem: (item: string) => React.ReactNode;
  renderPreferredPointOfContactValue: (value: string) => React.ReactNode;
  handleFieldChange: (
    field: string,
    value: number | string | boolean | Date
  ) => void;
}

const ContactDetailsComponent = (props: ContactDetailsComponentProps) => {
  const {
    values,
    errors,
    touched,
    handleFieldChange,
    handleChange,
    handleBlur,
    country,
    preferredContactOptions,
    renderPreferredPointOfContactItem,
    renderPreferredPointOfContactValue,
  } = props;
  return (
    <div>
      <div className="flex gap-2 items-center mt-6">
        <ContactDetailIcon />
        <h2 className="font-semibold text-lg leading-6 tracking-tight bg-gradient-clip">
          Contact details
        </h2>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <div className="font-normal text-sm leading-4 tracking-tight text-primary">
          Email address
        </div>
        <Input
          name="appointment.contact_email"
          placeholder="Email address"
          value={values.appointment.contact_email}
          onBlur={handleBlur}
          onChange={handleChange}
          error={
            touched.appointment?.contact_email &&
            Boolean(errors.appointment?.contact_email)
          }
          helperText={
            touched.appointment?.contact_email &&
            errors.appointment?.contact_email
              ? errors.appointment?.contact_email
              : ''
          }
          touched={touched.appointment?.contact_email}
        />
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <div className="font-normal text-sm leading-4 tracking-tight text-primary">
          Phone number
        </div>
        <PhoneNumberInput
          name="appointment.contact_mobile_number"
          value={values.appointment.contact_mobile_number}
          onBlur={handleBlur}
          country={country}
          onChange={event => {
            if (event.target.value.length <= 13) handleChange(event);
          }}
          error={
            touched.appointment?.contact_mobile_number &&
            Boolean(errors.appointment?.contact_mobile_number)
          }
        />
        {touched.appointment?.contact_mobile_number &&
          errors.appointment?.contact_mobile_number && (
            <p className="m-0 font-sans font-normal text-xs leading-relaxed tracking-wide text-danger ">
              {errors.appointment?.contact_mobile_number}
            </p>
          )}
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <div className="font-normal text-sm leading-4 tracking-tight text-primary">
          Preferred point of contact
        </div>
        <SelectInput
          name="appointment.preferred_point_of_contact"
          value={values.appointment.preferred_point_of_contact}
          items={preferredContactOptions}
          renderValue={renderPreferredPointOfContactValue}
          renderItem={renderPreferredPointOfContactItem}
          onBlur={handleBlur}
          error={
            touched.appointment?.preferred_point_of_contact &&
            Boolean(errors.appointment?.preferred_point_of_contact)
          }
          onChange={value =>
            handleFieldChange('preferred_point_of_contact', value)
          }
        />
        {touched.appointment?.preferred_point_of_contact &&
          errors.appointment?.preferred_point_of_contact && (
            <p className="m-0 font-sans font-normal text-xs leading-relaxed tracking-wide text-danger ">
              {errors.appointment?.preferred_point_of_contact}
            </p>
          )}
      </div>
    </div>
  );
};

export default ContactDetailsComponent;
