import {useState} from 'react';
import {Updater} from 'use-immer';
import FetchError from '~/components/FetchError';
import Loading from '~/components/Loading';
import RenderSVG from '~/components/RenderSVG';
import ActionCard from '~/modules/booking/components/ActionCard';
import {
  AppointmentSelectionViewChoices,
  BookingData,
  BookingView,
} from '~/modules/booking/types';
import {useAPIConstants} from '~/services/api/constants/hooks';
import {ConstantsAPIKey} from '~/services/api/constants/constants';
import {Appointment} from '~/services/api/constants/types';
import {useEffect} from 'react';
import LocalStorage from '$utils/localStore';
import Footer from '../components/Footer';

interface AppointmentSelectionViewProps {
  bookingChoices: AppointmentSelectionViewChoices;
  setActiveView: React.Dispatch<React.SetStateAction<BookingView>>;
  setBookingChoices: Updater<BookingData>;
}

function AppointmentSelectionView(props: AppointmentSelectionViewProps) {
  const {bookingChoices, setActiveView, setBookingChoices} = props;
  const [showAllDescription, setShowAllDescription] = useState(false);
  const {data, isLoading} = useAPIConstants({
    key: ConstantsAPIKey.Appointment,
  });

  useEffect(() => {
    LocalStorage.removeItem(LocalStorage.BOOKING_BLOCKED_SLOT);
    LocalStorage.removeItem(LocalStorage.BOOKING_BLOCKED_DATE);
  }, []);

  if (isLoading) return <Loading />;
  if (!data) return <FetchError />;

  const filteredData = data.filter(
    appointment => appointment.title.toLowerCase() !== 'surgery'
  );

  const handleClick = (info: Appointment) => {
    setBookingChoices(draft => {
      draft.appointment = info;
    });
    setActiveView(BookingView.CareSelectionView);
  };

  const actionCards = filteredData.map(info => {
    return (
      <ActionCard
        key={info.id}
        active={bookingChoices.appointment?.id === info.id}
        icon={info.image ? <RenderSVG svg={info.image} /> : null}
        title={info.title}
        description={info.description}
        onClick={info.isEnable ? () => handleClick(info) : undefined}
        disabled={!info.isEnable}
        testId={`appointment-${info.title}-card`}
      />
    );
  });

  return (
    <>
      <div className="font-booking flex flex-1 flex-col px-6 gap-10 lg:px-52 overflow-y-auto">
        <div>
          <h2 className="text-center text-2xl lg:text-4xl pb-2 font-bold text-grey-2">
            What would you like to book?
          </h2>
          <p
            className={`text-center text-grey-2 ${showAllDescription ? 'block' : 'hidden'} lg:block`}
          >
            At Stoneygate, we offer a variety of services to accommodate all our
            patients' needs. Choose the option that best fits your requirements.
          </p>
          {!showAllDescription && (
            <p className="text-center text-grey-2 lg:hidden">
              At Stoneygate, we offer a variety...
            </p>
          )}
          {!showAllDescription && (
            <p
              className="booking-flow-sub-title-gradient lg:hidden"
              onClick={() => setShowAllDescription(true)}
            >
              see all
            </p>
          )}
        </div>
        <div className="flex flex-col gap-6 pb-4 overflow-y-auto">
          {actionCards}
        </div>
        <div className="flex flex-col flex-1 justify-end">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default AppointmentSelectionView;
