import {useState} from 'react';
import FetchError from '$components/FetchError';
import Loading from '$components/Loading';
import {IconButton, Popover} from '@mui/material';
import {usePatientDetailsHook} from '../hooks/usePatientDetailsHook';
import {PATIENT_CARE_DEFAULT_COLOR} from './constant';
import PatientCareDialog from './PatientCareDialog';
import {
  DotsVertical,
  FaceContentIcon,
  PlusSquareIcon,
  ToggleRedIcon,
  ToggleGreenIcon,
} from '$assets/svgs';
import useAuthUser from '../../../../../hooks/useAuthUser';
import {UserRoleName} from '$modules/admin/constant';
import {useParams} from 'react-router-dom';
import RecentUpdate from './RecentUpdate';
import {PatientDetailTab} from '../constant';
import ActiveInactivePatientDialog from './ActiveInactivePatientDialog';
import NotesOverviewContainer from './NotesOverview';
import PatientInfoContainer from './PatientInfo';
import './styles.scss';
import PatientCareListSkeleton from './PatientCareListSkeleton';

interface OverviewComponentProps {
  setActiveTab: React.Dispatch<React.SetStateAction<PatientDetailTab>>;
  setOverviewSelectedNoteId: React.Dispatch<
    React.SetStateAction<number | null>
  >;
}

const OverviewComponent: React.FC<OverviewComponentProps> = ({
  setActiveTab,
  setOverviewSelectedNoteId,
}) => {
  const params = useParams();
  const patientId = Number(params.patientId);
  const {roleName} = useAuthUser()!;
  const isFrontdesk = roleName === UserRoleName.Frontdesk;
  const [patientCareOpen, setPatientCareOpen] = useState<boolean>(false);
  const [activeInactiveStatusDialog, setActiveInactiveStatusDialog] =
    useState<boolean>(false);
  const [activeInactiveAnchorEl, setActiveInactiveAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const {isPending: isPatientDetailsLoading, data: patientDetails} =
    usePatientDetailsHook(patientId);
  const isPending = isPatientDetailsLoading;
  const isError = !patientDetails;
  if (isPending) <Loading />;
  if (isError) <FetchError />;

  const {
    address,
    special_cares: patientCaresList,
    ...rest
  } = patientDetails?.data || {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const flattenedPatientDetails: any = {...rest, ...address};
  const {status: patientStatus} = flattenedPatientDetails;

  const handlePatientCareOpen = () => {
    setPatientCareOpen(!patientCareOpen);
  };

  const handleActiveInactivePopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setActiveInactiveAnchorEl(event.currentTarget);
  };

  const handleActiveInactivePopoverClose = () => {
    setActiveInactiveAnchorEl(null);
  };

  const handleActiveInactivePatientDialog = () => {
    setActiveInactiveStatusDialog(prevState => !prevState);
  };

  const open = Boolean(activeInactiveAnchorEl);
  const id = open ? 'active-inactive-popover' : undefined;

  return (
    <>
      <div className="overview overflow-auto">
        <div className="overview__header">
          <div className="heading">Overview</div>
          <IconButton
            className="dots-vertical-btn"
            aria-describedby={id}
            onClick={handleActiveInactivePopoverClick}
          >
            <DotsVertical />
          </IconButton>
          <Popover
            className="active-inactive-popover"
            id={id}
            open={open}
            anchorEl={activeInactiveAnchorEl}
            onClose={handleActiveInactivePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div
              className="active-inactive-popover-container"
              onClick={() => {
                handleActiveInactivePopoverClose();
              }}
            >
              <div
                onClick={handleActiveInactivePatientDialog}
                style={{cursor: 'pointer'}}
              >
                {patientStatus === 'active' ? (
                  <ToggleRedIcon />
                ) : (
                  <ToggleGreenIcon />
                )}
              </div>
              <div
                className={`active-inactive-popover-container__label${patientStatus === 'active' ? '--inactive' : '--active'}`}
              >
                Set as {patientStatus === 'active' ? 'inactive' : 'active'}
              </div>
            </div>
          </Popover>
          {activeInactiveStatusDialog && (
            <ActiveInactivePatientDialog
              open={activeInactiveStatusDialog}
              handleClose={handleActiveInactivePatientDialog}
              status={patientStatus}
              patientId={patientId}
            />
          )}
        </div>
        <div className="overview__body overflow-auto grid">
          <PatientInfoContainer
            patientId={patientId}
            canEditPatient={true}
            canInvitePatient={true}
            canViewFullProfile={false}
          />
          {isPatientDetailsLoading ? (
            <PatientCareListSkeleton />
          ) : (
            <>
              <div className="patient-care overflow-auto row-span-2">
                <div className="title-container">
                  <div className="title">
                    <FaceContentIcon />
                    <span className="title-text">Patient care</span>
                  </div>
                  <PlusSquareIcon
                    className="icon patinet-care-icon"
                    onClick={handlePatientCareOpen}
                  />
                </div>
                <div className="box-container">
                  {patientCaresList && patientCaresList.length > 0 ? (
                    patientCaresList.map(patientCare => (
                      <div
                        className="box-item"
                        key={patientCare.id}
                        style={{
                          backgroundColor:
                            patientCare.color || PATIENT_CARE_DEFAULT_COLOR,
                        }}
                      >
                        {patientCare.name}
                      </div>
                    ))
                  ) : (
                    <div className="text-center py-4 font-bold">
                      No data found
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          <NotesOverviewContainer
            patientId={patientId}
            isFrontDesk={isFrontdesk}
            setActiveTab={setActiveTab}
            setOverviewSelectedNoteId={setOverviewSelectedNoteId}
          />
          <RecentUpdate />
        </div>
      </div>
      {patientCareOpen && (
        <PatientCareDialog
          patientId={patientId}
          open={patientCareOpen}
          handleClose={handlePatientCareOpen}
          patientCaresList={patientCaresList || []}
        />
      )}
    </>
  );
};

export default OverviewComponent;
