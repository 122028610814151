import {LinearProgress, TableCell, TableHead, TableRow} from '@mui/material';
import ArrowDownIcon from '$assets/svgs/arrow-down.svg?react';
import DoubleArrowIcon from '$assets/svgs/switch-vertical-01.svg?react';
import FilterMenu from './FilterMenu';
import {
  LastAppointment,
  PatientListOrderBy,
  PatientListTableHeader,
  PatientStatus,
  headerOrderByMap,
} from '../constant';

interface PatientListHeadProps {
  isLoading: boolean;
  archivedOnly: boolean;
  sortActiveColumn?: string;
  sortDirection?: boolean;
  patientStatus: PatientStatus[];
  lastAppointment?: LastAppointment;
  setPatientStatus: (value: PatientStatus[]) => void;
  setLastAppointment: (value?: LastAppointment) => void;
  setSortActiveColumn: (value?: PatientListOrderBy) => void;
  setSortDirection: React.Dispatch<React.SetStateAction<boolean>>;
}

function PatientListHead(props: PatientListHeadProps) {
  const {
    isLoading,
    archivedOnly,
    sortActiveColumn,
    sortDirection,
    patientStatus,
    lastAppointment,
    setPatientStatus,
    setLastAppointment,
    setSortActiveColumn,
    setSortDirection,
  } = props;

  const headerCells = Object.values(PatientListTableHeader).map(headTitle => {
    const handleClick = () => {
      if (sortActiveColumn === headerOrderByMap[headTitle]) {
        setSortDirection(value => !value);
      } else {
        setSortActiveColumn(headerOrderByMap[headTitle]);
        setSortDirection(true);
      }
    };

    return (
      <TableCell
        key={headTitle}
        align="left"
        onClick={handleClick}
        className={
          headTitle === PatientListTableHeader.Name ? 'w-3/12' : 'w-2/12'
        }
      >
        <span className="flex items-center gap-1 cursor-pointer">
          <span>{headTitle}</span>
          {sortActiveColumn === headerOrderByMap[headTitle] ? (
            <ArrowDownIcon
              className={sortDirection ? 'primary' : 'primary flip-vertical'}
            />
          ) : (
            <DoubleArrowIcon className="primary h-4 w-4" />
          )}
        </span>
      </TableCell>
    );
  });

  return (
    <TableHead>
      <TableRow>
        <TableCell className="w-1/12"></TableCell>
        {headerCells}
        <TableCell align="right">
          <span className="flex items-center justify-end gap-1">
            <FilterMenu
              archivedOnly={archivedOnly}
              patientStatus={patientStatus}
              lastAppointment={lastAppointment}
              setPatientStatus={setPatientStatus}
              setLastAppointment={setLastAppointment}
            />
          </span>
        </TableCell>
      </TableRow>
      {isLoading && (
        <TableRow>
          <TableCell colSpan={6} className="p-0">
            <LinearProgress className="w-full" />
          </TableCell>
        </TableRow>
      )}
    </TableHead>
  );
}

export default PatientListHead;
