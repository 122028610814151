import {resetAuth} from '$redux/authSlice';
import store from '$redux/store';
import {v4 as uuid} from 'uuid';
import LocalStorage from './localStore';
import {unsubscribePushManager} from '../worker/registration';

export const convertDateString = (dateString: string): string => {
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
};

export function formatDateString(dateString: string): string {
  const date = new Date(dateString);
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
}

export const logout = () => {
  unsubscribePushManager();

  LocalStorage.removeItem(LocalStorage.AUTH_USER_DATA);
  LocalStorage.removeItem(LocalStorage.APPOINTMENT_LIST_SELECTED_DATE);
  store.dispatch(resetAuth());
};

export const getNewID = () => {
  return uuid();
};

export function formatFullName(name?: string) {
  if (name && name.length > 20) {
    return name.substring(0, 20) + '...';
  }

  return name;
}
