interface Option {
  label: string;
  value: string;
}

export const FREQUENCY_ITEMS: Option[] = [
  {label: 'As needed', value: 'As needed'},
  {label: 'Every day', value: 'Every day'},
  {label: 'Every other day', value: 'Every other day'},
  {label: 'Every 2 days', value: 'Every 2 days'},
  {label: 'Every 3 days', value: 'Every 3 days'},
  {label: 'Every 4 days', value: 'Every 4 days'},
  {label: 'Every 5 days', value: 'Every 5 days'},
  {label: 'Every 6 days', value: 'Every 6 days'},
  {label: 'Once a week', value: 'Once a week'},
];
