import React from 'react';
import {Button, Dialog} from '@mui/material';
import {ArrowSquareUpIcon, XCloseIcon} from '$assets/svgs';
import IconButton from '$components/IconButton';
import FrontdeskButton from '$components/FrontdeskButton';

import './styles.scss';

import Droparea from './Droparea';
import {useDocumentsHook} from '../../hooks/useDocumentsHook';
import {useFormik} from 'formik';
import {DocumentUploadFormData, documentUploadFormSchema} from './schema';
import Input from '$components/Input';

interface UploadDocumentsDialogProps {
  patientId: string;
  open: boolean;
  handleClose: () => void;
}
const UploadDocumentsDialog: React.FC<UploadDocumentsDialogProps> = ({
  patientId,
  open,
  handleClose,
}) => {
  const {uploadDocumentRequest} = useDocumentsHook(patientId);

  const formik = useFormik<DocumentUploadFormData>({
    initialValues: {
      document: '',
      documentName: '',
      performedBy: '',
    },
    validationSchema: documentUploadFormSchema,
    onSubmit: values => handleFormSubmit(values),
  });

  const handleFormSubmit = (values: DocumentUploadFormData) => {
    uploadDocumentRequest(
      patientId,
      values.document as File,
      values.documentName,
      values.performedBy
    );
    handleClose();
  };

  return (
    <>
      <Dialog open={open} className="document-dialog-container">
        <div className="dialog-header">
          <div className="dialog-header-container">
            <ArrowSquareUpIcon />
          </div>
          <IconButton onClick={handleClose}>
            <XCloseIcon />
          </IconButton>
        </div>
        <div className="dialog-field-container">
          <div>
            <Droparea
              name="document"
              errors={formik.errors}
              setErrors={formik.setErrors}
              handleFieldChange={formik.setFieldValue}
              values={formik.values}
            />
            {formik.errors.document && (
              <p className="errorText">
                {typeof formik.errors.document === 'string'
                  ? formik.errors.document
                  : ''}
              </p>
            )}
          </div>
          <Input
            name="documentName"
            value={formik.values.documentName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.documentName && Boolean(formik.errors.documentName)
            }
            helperText={
              formik.touched.documentName && formik.errors.documentName
                ? formik.errors.documentName
                : ''
            }
            touched={formik.touched.documentName}
            placeholder="Document Name"
          />
          <Input
            name="performedBy"
            value={formik.values.performedBy}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors.performedBy)}
            helperText={
              formik.touched.performedBy && formik.errors.performedBy
                ? formik.errors.performedBy
                : ''
            }
            touched={formik.touched.performedBy}
            placeholder="Performed By"
          />
        </div>

        <div className="dialog-footer">
          <Button className="cancel-button" onClick={handleClose}>
            Cancel
          </Button>
          <FrontdeskButton
            title="Continue"
            onClick={() => formik.handleSubmit()}
          />
        </div>
      </Dialog>
    </>
  );
};

export default UploadDocumentsDialog;
