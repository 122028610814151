import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import PatientListRow from './PatientListRow';
import PatientListHead from './PatientListHead';
import {PatientInfo} from '../types';
import {LastAppointment, PatientListOrderBy, PatientStatus} from '../constant';
import './styles.scss';

export interface PatientListTableComponentProps {
  isLoading: boolean;
  isError: boolean;
  message: string;
  archivedOnly: boolean;
  patients: PatientInfo[];
  sortActiveColumn?: string;
  sortDirection?: boolean;
  patientStatus: PatientStatus[];
  lastAppointment?: LastAppointment;
  setPatientStatus: (value: PatientStatus[]) => void;
  setLastAppointment: (value?: LastAppointment) => void;
  setSortActiveColumn: (value?: PatientListOrderBy) => void;
  setSortDirection: React.Dispatch<React.SetStateAction<boolean>>;
}

function PatientListTableComponent(props: PatientListTableComponentProps) {
  const {
    isLoading,
    isError,
    message,
    archivedOnly,
    patients,
    sortActiveColumn,
    sortDirection,
    patientStatus,
    lastAppointment,
    setPatientStatus,
    setLastAppointment,
    setSortActiveColumn,
    setSortDirection,
  } = props;

  const rows = patients.map(info => (
    <PatientListRow key={info.id} info={info} />
  ));

  const errorRow = (
    <TableRow>
      <TableCell colSpan={6} className="text-center text-error">
        {message}
      </TableCell>
    </TableRow>
  );

  const noEntriesRow = (
    <TableRow>
      <TableCell colSpan={6} className="text-center tertiary primary-font">
        No patient found
      </TableCell>
    </TableRow>
  );

  const loadingRow = (
    <TableRow>
      <TableCell colSpan={6} className="text-center">
        Loading...
      </TableCell>
    </TableRow>
  );

  return (
    <div className="flex-1 flex flex-col overflow-auto">
      <TableContainer component={Paper} className="patient-list-table">
        <Table stickyHeader aria-label="simple table" className="max-h-full">
          <PatientListHead
            isLoading={isLoading}
            archivedOnly={archivedOnly}
            sortActiveColumn={sortActiveColumn}
            sortDirection={sortDirection}
            patientStatus={patientStatus}
            lastAppointment={lastAppointment}
            setPatientStatus={setPatientStatus}
            setLastAppointment={setLastAppointment}
            setSortActiveColumn={setSortActiveColumn}
            setSortDirection={setSortDirection}
          />
          <TableBody>{isLoading && loadingRow}</TableBody>
          <TableBody>
            {!isLoading &&
              (isError ? errorRow : rows.length ? rows : noEntriesRow)}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default PatientListTableComponent;
