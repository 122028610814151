import React, {SetStateAction} from 'react';
import {FormState} from '../types';
import OperationTheatreComponent from './component';
import {Button} from '@mui/material';
import FrontdeskButton from '$components/FrontdeskButton';
import {ArrowNarrowRightIcon} from '$assets/svgs';
import {useBookingSlots} from '$modules/frontdesk/hooks';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';
import {useAppSelector} from '$redux/hooks';
import {useCreateOfflineAppointmentHook} from '../hooks/useCreateOfflineAppointmentHook';
import {useBookingTypeHook} from '../hooks/useBookingTypeHook';
import {OfflineBookingStep} from '../constant';
import {useBlockSlots} from '$modules/booking/hooks';
import './styles.scss';

interface OperationTheatreContainerProps {
  setActiveStep: React.Dispatch<SetStateAction<OfflineBookingStep>>;
  offlineBookingFormData: FormState;
  setOfflineBookingFormData: React.Dispatch<SetStateAction<FormState>>;
  offlineBookingType: string;
  setOfflineBookedAppointmentId: React.Dispatch<SetStateAction<number>>;
}
const OperationTheatreContainer: React.FC<OperationTheatreContainerProps> = ({
  setActiveStep,
  offlineBookingFormData,
  setOfflineBookingFormData,
  offlineBookingType,
  setOfflineBookedAppointmentId,
}) => {
  const hospitalId = useAppSelector(state => state.auth.user?.hospitalId);
  const selectedStartTime = offlineBookingFormData.appointment.start_time;
  const selectedDoctorId = offlineBookingFormData.appointment.doctor_id;
  const dateSelected = offlineBookingFormData.appointment.date;
  const selectedOperationTheatre =
    offlineBookingFormData.appointment.operation_theatre_id;
  const appointmentId = offlineBookingFormData.appointment.appointment_type_id;
  const subCareTypeId = offlineBookingFormData.appointment.sub_care_type_id;
  const appointmentTypeId =
    offlineBookingFormData.appointment.appointment_type_id;

  const {slots, isLoading: isPendingAvailableSlots} = useBookingSlots({
    doctorId: selectedDoctorId,
    date: dateSelected || undefined,
    hospitalId: hospitalId || undefined,
    appointmentTypeId: appointmentTypeId,
    subCareTypeId: subCareTypeId,
  });

  const {data} = useBookingTypeHook();

  const {createAppointment, isPending} = useCreateOfflineAppointmentHook(
    setActiveStep,
    setOfflineBookedAppointmentId,
    offlineBookingFormData,
    offlineBookingType
  );
  const {sendBlockSlotsRequest} = useBlockSlots({
    setActiveStep,
    setOfflineBookingFormData,
    createAppointment,
    offlineBookingFormData,
  });

  const bookingType = data?.data.find(ele => ele.id === appointmentId);
  const isBookingTypeConsultant =
    bookingType?.title === 'Appointment' && bookingType.id === appointmentId;

  const handleConfirmBooking = () => {
    sendBlockSlotsRequest({
      id: offlineBookingFormData.appointment.id,
      date: dateSelected?.toISOString() || '',
      doctorId: selectedDoctorId,
      endTime: offlineBookingFormData.appointment.end_time?.toISOString() || '',
      startTime:
        offlineBookingFormData.appointment.start_time?.toISOString() || '',
    });
  };

  if (isPendingAvailableSlots) return <Loading />;
  if (!slots || !data) return <FetchError />;

  return (
    <>
      <OperationTheatreComponent
        setActiveStep={setActiveStep}
        offlineBookingFormData={offlineBookingFormData}
        setOfflineBookingFormData={setOfflineBookingFormData}
        offlineBookingType={offlineBookingType}
        slots={slots}
        isBookingTypeConsultant={isBookingTypeConsultant}
      />
      <div className="dialog-footer">
        <Button
          className="back-btn"
          onClick={() => setActiveStep(OfflineBookingStep.ConsultantSelection)}
        >
          Back
        </Button>
        <FrontdeskButton
          title="Confirm booking"
          disabled={
            isBookingTypeConsultant
              ? !selectedStartTime
              : !selectedStartTime || !selectedOperationTheatre
          }
          endIcon={<ArrowNarrowRightIcon />}
          onClick={handleConfirmBooking}
          loading={isPending}
        />
      </div>
    </>
  );
};

export default OperationTheatreContainer;
