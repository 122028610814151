import {
  getPatientDetails,
  putPatientDetail,
  PutPatientDetailPayload,
  putPatientDetailResponseData,
} from '$api/patientList/patientDetails';
import {ToastType} from '$components/Toast/constant';
import queryClient from '$lib/queryClient';
import {handleErrorResponse} from '$utils/axios';
import toast from '$utils/toast';
import {useMutation, useQuery} from '@tanstack/react-query';

export const usePatientDetailsHook = (patientId: number) => {
  const {isPending, isError, error, data, isFetching} = useQuery({
    queryKey: ['patient-details'],
    queryFn: () => getPatientDetails(patientId),
  });

  const {mutate: modiyPatientDetails, isPending: isModifyingPatient} =
    useMutation({
      mutationKey: ['patient-details'],
      mutationFn: (payload: PutPatientDetailPayload) =>
        putPatientDetail(patientId, payload),
      onSuccess: data => {
        queryClient.invalidateQueries({queryKey: ['patient-details']});
        return toast(ToastType.Success, 'Modify Patient', data.message);
      },
      onError: error =>
        handleErrorResponse<putPatientDetailResponseData>(
          error,
          'Modify patient'
        ),
    });

  const modifyPatient = (payload: PutPatientDetailPayload) => {
    modiyPatientDetails(payload);
  };

  return {
    isPending,
    isError,
    error,
    data,
    modifyPatient,
    isModifyingPatient,
    isFetching,
  };
};
