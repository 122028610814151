import {PropsWithChildren} from 'react';
import MUIIconButton from '@mui/material/IconButton';
import styles from './styles';

interface IconButtonProps {
  onClick: () => void;
  className?: string;
}

function IconButton(props: PropsWithChildren<IconButtonProps>) {
  const {children, onClick, className} = props;
  return (
    <MUIIconButton
      sx={styles.iconButton}
      onClick={onClick}
      className={className}
    >
      {children}
    </MUIIconButton>
  );
}

export default IconButton;
