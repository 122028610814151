import FetchError from '$components/FetchError';
import Loading from '$components/Loading';
import FrontdeskButton from '$components/FrontdeskButton';
import {ArrowNarrowRightIcon} from '$assets/svgs';
import ConsultantSelectionComponent from './component';
import {consultantSelectionViewStyles} from '$modules/booking/styles';
import {getNameInitials} from '$modules/booking/utils';
import {Box, Link, MenuItem, Typography} from '@mui/material';
import {Button} from '@mui/material';
import {FormState} from '../types';
import {Consultant} from '$modules/booking/types';
import {OfflineBookingStep} from '../constant';
import {SetStateAction} from 'react';
import './styles.scss';
import {useConsultants} from '$modules/frontdesk/hooks';

interface ConsultantSelectionContainerProps {
  setActiveStep: React.Dispatch<SetStateAction<OfflineBookingStep>>;
  offlineBookingFormData: FormState;
  setOfflineBookingFormData: React.Dispatch<React.SetStateAction<FormState>>;
}

const ConsultantSelectionContainer: React.FC<
  ConsultantSelectionContainerProps
> = ({setActiveStep, offlineBookingFormData, setOfflineBookingFormData}) => {
  const {isLoading, consultants, isError} = useConsultants({
    subCareId: offlineBookingFormData?.appointment?.sub_care_type_id,
    appointmentTypeId: offlineBookingFormData?.appointment?.appointment_type_id,
  });
  const isContinueDisabled = offlineBookingFormData.appointment.doctor_id;

  if (isLoading) return <Loading />;
  if (isError) return <FetchError />;

  const handleConsultantChange = (value: string) => {
    setOfflineBookingFormData((prevState: FormState) => ({
      appointment: {
        ...prevState.appointment,
        doctor_id: Number(value),
      },
    }));
  };

  const renderConsultantMenuItem = (item: Consultant) => {
    return (
      <MenuItem key={item.id} value={item.id.toString()}>
        <Box
          width="100%"
          display="flex"
          // sx={consultantSelectionViewStyles.menuItemContainer}
          alignItems="flex-start"
          gap="8px"
        >
          <Box>
            {item.profileUrl ? (
              <img
                src={item.profileUrl}
                style={consultantSelectionViewStyles.menuItemProfileImage}
              />
            ) : (
              <span style={consultantSelectionViewStyles.menuItemInitial}>
                {getNameInitials(item.fullName)}
              </span>
            )}
          </Box>
          <Box sx={consultantSelectionViewStyles.menuItemBody}>
            <Box sx={consultantSelectionViewStyles.menuItemDetails}>
              <Box display="flex" gap="0.25rem" flexWrap="wrap">
                <Typography fontWeight="bold">{item.fullName}</Typography>{' '}
                {item.doctorDetails && (
                  <>
                    <Typography sx={consultantSelectionViewStyles.fontThin}>
                      Consultation cost
                    </Typography>{' '}
                    <Typography fontWeight="bold">
                      £{item.doctorDetails?.consultationFee}
                    </Typography>
                  </>
                )}
              </Box>
              {item.doctorDetails?.description && (
                <Box>
                  <Typography sx={consultantSelectionViewStyles.fontThin}>
                    {item.doctorDetails?.description}
                  </Typography>
                </Box>
              )}
            </Box>
            {item.doctorDetails?.bioLink && (
              <Box>
                <Link
                  target="_blank"
                  href={item.doctorDetails?.bioLink}
                  sx={consultantSelectionViewStyles.menuItemBioText}
                  className="underline-hover"
                >
                  Read more
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      </MenuItem>
    );
  };

  const renderValue = (value: string) => {
    if (!value) return 'Select a consultant';
    const item = consultants.find(it => it.id.toString() === value);
    if (!item) {
      handleConsultantChange('');
      return '';
    }

    return (
      <Box sx={consultantSelectionViewStyles.valueContainer}>
        {item.profileUrl ? (
          <img
            src={item.profileUrl}
            style={consultantSelectionViewStyles.menuItemProfileImage}
          />
        ) : (
          <span style={consultantSelectionViewStyles.menuItemInitial}>
            {getNameInitials(item.fullName)}
          </span>
        )}
        <span>{item.fullName}</span>
      </Box>
    );
  };

  return (
    <>
      <ConsultantSelectionComponent
        consultantList={consultants}
        offlineBookingFormData={offlineBookingFormData}
        handleConsultantChange={handleConsultantChange}
        renderConsultantMenuItem={renderConsultantMenuItem}
        renderValue={renderValue}
      />
      <div className="dialog-footer">
        <Button
          className="back-btn"
          onClick={() =>
            setActiveStep(OfflineBookingStep.ConsultationSelection)
          }
        >
          Back
        </Button>
        <FrontdeskButton
          title="Continue"
          className="continue-btn"
          disabled={!isContinueDisabled}
          endIcon={<ArrowNarrowRightIcon />}
          onClick={() => setActiveStep(OfflineBookingStep.DateAndTimeSelection)}
        />
      </div>
    </>
  );
};

export default ConsultantSelectionContainer;
