import {usePatientQuestionnaire} from '~/services/api/MedicalHistory/Questionnaire/hooks';
import {QuestionnaireType} from '~/services/api/MedicalHistory/Questionnaire/types';
import Loading from '~/components/Loading';
import FetchError from '~/components/FetchError';
import DailyLivingAndHospitalExperienceForm from './form';

interface DailyLifeAndHospitalExperienceProps {
  questionnaireType: QuestionnaireType;
  patientId: number;
  currentPage: number;
  itemsPerPage: number;
  handlePageChange: (page: number) => void;
  handleBack: () => void;
}

const DailyLivingAndHospitalExperience = (
  props: DailyLifeAndHospitalExperienceProps
) => {
  const {
    currentPage,
    handleBack,
    handlePageChange,
    itemsPerPage,
    patientId,
    questionnaireType,
  } = props;

  const {
    submitPatientQuestionnaire,
    disableSubmit,
    questionnaire,
    error,
    isCreatingPatientQuestionnaire,
    isLoading,
  } = usePatientQuestionnaire({
    patientId,
    historyType: questionnaireType,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (!questionnaire || error) {
    return <FetchError />;
  }

  return (
    <DailyLivingAndHospitalExperienceForm
      currentPage={currentPage}
      disableSubmit={disableSubmit || isCreatingPatientQuestionnaire}
      handleBack={handleBack}
      handlePageChange={handlePageChange}
      isCreatingPatientQuestionnaire={isCreatingPatientQuestionnaire}
      itemsPerPage={itemsPerPage}
      questionnaire={questionnaire}
      questionnaireType={questionnaireType}
      submitPatientQuestionnaire={submitPatientQuestionnaire}
    />
  );
};

export default DailyLivingAndHospitalExperience;
