import {isNumber} from '$utils/number';
import {Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';

interface NavigationListItemProps {
  title: string;
  icon: React.ReactNode;
  sidebarOpen: boolean;
  active: boolean;
  route: string;
  badgeNumber?: number;
}

function NavigationListItem(props: NavigationListItemProps) {
  const {title, icon, sidebarOpen, active, badgeNumber, route} = props;

  const navigate = useNavigate();

  const buttonClassName = `button p-2 bg-primary w-full min-w-0 ${active ? 'active' : ''}`;

  const handleButtonClick = () => {
    navigate(route);
  };

  const renderBadge = (badge: number | undefined) => {
    return isNumber(badge) && badge > 0 ? (
      <div className="bg-[#d92d20] absolute rounded-lg w-8 h-6 -top-4 -left-5 flex items-center justify-center px-2 py-1 font-normal text-sm text-white">
        {badge}
      </div>
    ) : null;
  };

  const renderBadgeInline = (badge: number | undefined) => {
    return isNumber(badge) && badge > 0 ? (
      <span className="badge px-3">{badge}</span>
    ) : null;
  };

  return (
    <li className="px-4 py-0 navigation-list-item mb-4">
      <Button className={buttonClassName} onClick={handleButtonClick}>
        <span className="flex items-center gap-4">
          <span className="flex items-center icon-color relative">
            {icon}
            {!sidebarOpen && renderBadge(badgeNumber)}
          </span>
          {sidebarOpen && <span className="flex items-center">{title}</span>}
        </span>
        {sidebarOpen && renderBadgeInline(badgeNumber)}
      </Button>
    </li>
  );
}

export default NavigationListItem;
