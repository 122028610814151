import {getPatientCare} from '$api/patientList/patientDetails';
import {useQuery} from '@tanstack/react-query';

export const usePatientCareHook = () => {
  const {isPending, isError, error, data} = useQuery({
    queryKey: ['patient-care'],
    queryFn: () => getPatientCare(),
  });

  return {
    isPending,
    isError,
    error,
    data,
  };
};
