import {Box} from '@mui/material';
import Checkbox, {CheckboxProps} from '@mui/material/Checkbox';
import styles, {EoCheckedIcon, EoIcon, ErrorEoIcon} from './styles';

interface CheckBoxProps extends CheckboxProps {
  error?: boolean;
  label?: string;
}

function CheckBox({error, label, ...otherProps}: CheckBoxProps) {
  return (
    <Box sx={styles.container}>
      <Checkbox
        sx={{
          '&:hover': {bgcolor: 'transparent'},
        }}
        disableRipple
        color="success"
        checkedIcon={<EoCheckedIcon />}
        icon={error ? <ErrorEoIcon /> : <EoIcon />}
        inputProps={{'aria-label': 'Checkbox'}}
        name="abc"
        {...otherProps}
      />
      {label && (
        <Box component="label" sx={styles.labelWrapper}>
          {label}
        </Box>
      )}{' '}
    </Box>
  );
}

export default CheckBox;
