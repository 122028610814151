import {
  fourColGrid,
  eoDivider,
  patientDetailContainer,
} from '$modules/frontdesk/styles';
import {Box, Stack, Typography, Tooltip} from '@mui/material';
import {UserDetailIcon} from '$assets/svgs';
import {PATIENT_DETAILS_MAP} from './constant';

interface PatientDetails {
  gender: string;
  dob: string;
  self_booking: string;
  referred_by_physician: string;
  line1: string;
  line2: string;
  postcode: string;
  city: string;
  country: string;
  city_name: string;
  country_name: string;
  reason: string;
  [value: string]: string;
}

const PatientDetails = (details: PatientDetails) => {
  const gradientText = {
    background: '-webkit-linear-gradient(180deg, #7625DC, #6063F2)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };

  const MAX_LENGTH_TRUNCATE = 20;

  const renderDetail = (
    label: string,
    value: string,
    labelVariant: 'subtitle1' | 'subtitle2',
    titleVariant: 'subtitle1' | 'subtitle2',
    containsTooltip: boolean,
    helperFunction: ((value: string) => string) | null,
    className: string
  ) => {
    return (
      <Box key={label}>
        <Typography variant={labelVariant} className={className}>
          {label}
        </Typography>
        {containsTooltip && value?.length > MAX_LENGTH_TRUNCATE ? (
          <Tooltip title={value} placement="bottom-end" arrow>
            <Typography variant={titleVariant}>
              {helperFunction ? helperFunction(value) : value}
            </Typography>
          </Tooltip>
        ) : (
          <Typography variant={titleVariant}>
            {helperFunction ? (!value ? '-' : helperFunction(value)) : value}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Stack spacing={2}>
      <Box sx={patientDetailContainer}>
        <UserDetailIcon />
        <Typography variant="h4" sx={gradientText}>
          Patient details
        </Typography>
      </Box>
      <Box sx={fourColGrid}>
        {PATIENT_DETAILS_MAP.map(
          ({
            label,
            value,
            labelVariant,
            titleVariant,
            containsTooltip,
            helperFunction,
            className,
          }) =>
            renderDetail(
              label,
              details[value],
              labelVariant,
              titleVariant,
              containsTooltip,
              helperFunction,
              className
            )
        )}
      </Box>
      <Box sx={eoDivider} />
    </Stack>
  );
};

export default PatientDetails;
