import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/de';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import CalendarIcon from '$assets/svgs/calendar.svg?react';
import styles from './styles';
import {StaticDatePicker} from '@mui/x-date-pickers/StaticDatePicker';
import {
  ChevronDownLeftIcon,
  ChevronDownRightIcon,
  StaticDatePickerNextButtonIcon,
  StaticDatePickerPrevButtonIcon,
} from '$assets/svgs';
import {DateValidationError} from '@mui/x-date-pickers/models';
import {useMemo, useState} from 'react';
import moment, {momentDate} from '$utils/date';
import {Moment} from 'moment';

interface DatePickerProps {
  name?: string;
  value: Date | null;
  placeholder?: string;
  disablePast?: boolean;
  disableFuture?: boolean;
  onChange: (v: Date | null) => void;
  isStatic?: boolean;
  timezone: string;
  isDOB?: boolean;
  isDisabled?: boolean;
}

function DatePicker(props: DatePickerProps) {
  const {
    name,
    value,
    disablePast = false,
    disableFuture = false,
    placeholder = 'Select a date',
    onChange,
    isStatic,
    timezone,
    isDOB,
    isDisabled,
  } = props;
  const [error, setError] = useState<DateValidationError | null>(null);

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'disablePast':
        return 'Date cannot be in the past';
      case 'maxDate':
        return isDOB ? 'Must be 18 years old' : '';
      default: {
        return '';
      }
    }
  }, [error, isDOB]);

  const handleChange = (value: Moment | null) => {
    if (value && value.isValid()) {
      const today = moment().tz(timezone).startOf('day');

      if (value.isSame(today, 'day')) {
        value = value.tz(timezone).startOf('day');
        const date = value.toDate();

        return onChange(date);
      }

      if (!isDOB && disablePast && value.isBefore(moment())) {
        return onChange(null);
      }

      if (disableFuture && value.isAfter(moment())) {
        return onChange(null);
      }

      value = value.tz(timezone).startOf('day');
      const date = value.toDate();

      return onChange(date);
    } else {
      return onChange(null);
    }
  };

  const maxDate = isDOB
    ? moment().tz(timezone).startOf('day').subtract(18, 'year')
    : undefined;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
      {isStatic ? (
        <StaticDatePicker
          disabled={isDisabled}
          autoFocus={true}
          timezone={timezone}
          disablePast={!isDOB && disablePast}
          maxDate={maxDate}
          disableHighlightToday={false}
          showDaysOutsideCurrentMonth
          sx={styles.staticPicker}
          slotProps={{
            layout: {sx: styles.staticLayout},
          }}
          dayOfWeekFormatter={date => date.format('dd').toUpperCase()}
          slots={{
            toolbar: () => null,
            actionBar: () => null,
            switchViewButton: () => undefined,
            previousIconButton: StaticDatePickerPrevButtonIcon,
            nextIconButton: StaticDatePickerNextButtonIcon,
            leftArrowIcon: ChevronDownLeftIcon,
            rightArrowIcon: ChevronDownRightIcon,
          }}
          value={value ? momentDate(value).tz(timezone) : null}
          onChange={handleChange}
        />
      ) : (
        <DesktopDatePicker
          disabled={isDisabled}
          name={name}
          timezone={timezone}
          maxDate={maxDate}
          disablePast={!isDOB && disablePast}
          disableFuture={disableFuture}
          onError={newError => setError(newError)}
          value={value ? momentDate(value).tz(timezone) : null}
          format="DD/MM/YYYY"
          sx={styles.picker}
          slotProps={{
            layout: {sx: styles.layout},
            textField: {placeholder, helperText: errorMessage},
          }}
          onChange={handleChange}
          slots={{openPickerIcon: CalendarIcon}}
        />
      )}
    </LocalizationProvider>
  );
}

export default DatePicker;
