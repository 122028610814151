import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useImmer} from 'use-immer';
import {Dialog, IconButton} from '@mui/material';
import XCloseIcon from '$assets/svgs/x-close.svg?react';
import {getNewID} from '$utils/helper';
import {AllergySeverity} from '../constant';
import {Allergy, AllergyFormData} from '../types';
import FrontdeskButton from '$components/FrontdeskButton';
import {useModifyAllergy} from '../../hooks';
import useAuthUser from '../../../../../../../hooks/useAuthUser';
import AllergyForm from '../AllergyDialog/AllergyForm';

interface AllergyModifyDialogProps {
  open: boolean;
  onClose: () => void;
  allergyId: number | null;
  allergies: Allergy[];
}

function AllergyModifyDialog(props: AllergyModifyDialogProps) {
  const {open, onClose, allergies, allergyId} = props;

  const user = useAuthUser();
  const params = useParams();
  const patientId = Number(params.patientId);
  const [formData, setFormData] = useImmer<AllergyFormData>({
    name: '',
    level: '',
  });

  const {
    sendModifyAllergyRequest,
    isPending,
    disableSubmit: disableSubmitRequest,
  } = useModifyAllergy(() => onClose());

  const handleNameChange = (value: string) => {
    setFormData(draft => {
      draft.name = value;
    });
  };

  const handleSeverityChange = (value: AllergySeverity | '') => {
    setFormData(draft => {
      draft.level = value;
    });
  };

  useEffect(() => {
    const allergy = allergies.find(al => al.id === allergyId);

    if (allergy) {
      setFormData(() => allergy);
    } else {
      setFormData(() => ({
        name: '',
        level: '',
        isNew: getNewID(),
      }));
    }
  }, [allergyId, allergies, setFormData]);

  const handleSaveAllergy = () => {
    const updatedAllergies = allergies.map(al => {
      if (al.id === allergyId) {
        return formData;
      } else {
        return al;
      }
    });

    sendModifyAllergyRequest({
      hospitalId: user?.hospitalId,
      patientId: patientId,
      allergies: updatedAllergies,
    });
  };

  const disableSubmit = !formData.name || !formData.level;

  return (
    <Dialog className="allergy-dialog" open={open} onClose={onClose}>
      <div className="header">
        <h2 className="title">Edit allergy</h2>
        <IconButton onClick={onClose}>
          <XCloseIcon />
        </IconButton>
      </div>
      <div className="body">
        <AllergyForm
          allergy={formData}
          setName={handleNameChange}
          setSeverity={handleSeverityChange}
        />
      </div>
      <div className="actions justify-end">
        <div className="main-actions">
          <FrontdeskButton
            variant="secondary"
            title="Cancel"
            onClick={onClose}
          />
          <FrontdeskButton
            variant="primary"
            title="Save"
            loading={isPending}
            disabled={disableSubmit || disableSubmitRequest}
            onClick={handleSaveAllergy}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default AllergyModifyDialog;
