import React from 'react';
import CheckInPatientComponent from './component';
import DialogComponent from '$components/DialogComponent';

interface CheckInPatientContainerProps {
  checkInPatientModalOpen: boolean;
  setCheckInPatientModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  appointmentId: number;
  patientProfileExist: boolean;
  patientName: string;
}
const CheckInPatientContainer: React.FC<CheckInPatientContainerProps> = ({
  checkInPatientModalOpen,
  setCheckInPatientModalOpen,
  appointmentId,
  patientProfileExist,
  patientName,
}) => {
  return (
    <DialogComponent
      open={checkInPatientModalOpen}
      handleClose={setCheckInPatientModalOpen}
      dialogBody={
        <CheckInPatientComponent
          checkInPatientModalOpen={checkInPatientModalOpen}
          setCheckInPatientModalOpen={setCheckInPatientModalOpen}
          appointmentId={appointmentId}
          patientProfileExist={patientProfileExist}
          patientName={patientName}
        />
      }
    />
  );
};

export default CheckInPatientContainer;
