import {FormikErrors, FormikTouched} from 'formik';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FileUpload from '$components/FileUpload';
import RadioBox from '$components/RadioBox';
import TextArea from '$components/TextArea';
import theme from '$modules/booking/theme';
import styles from '../../styles';
import {GPOrOpticianReferralFormData} from './schema';

interface GPOrOpticianReferralFormProps {
  values: GPOrOpticianReferralFormData;
  errors: FormikErrors<GPOrOpticianReferralFormData>;
  touched: FormikTouched<GPOrOpticianReferralFormData>;
  handleBlur: React.FocusEventHandler<HTMLTextAreaElement>;
  handleChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  setFieldTouched: (field: string) => void;
  setFieldValue: (field: string, value: unknown) => void;
}

function GPOrOpticianReferralForm(props: GPOrOpticianReferralFormProps) {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
  } = props;

  const handleHasReferralChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    switch (value) {
      case String(true):
        setFieldValue('hasReferral', true);
        break;
      case String(false):
        setFieldValue('hasReferral', false);
        break;
      default:
        setFieldValue('hasReferral', undefined);
        break;
    }
  };

  const handleReferralFileChange = (file: File | null) => {
    setFieldTouched('referralFile');
    setFieldValue('referralFile', file);
  };

  return (
    <>
      <Box flexDirection="column" display="flex" gap={2}>
        <Typography variant="h3" sx={styles.stepsTypeHead}>
          Do you have a GP or optician referral?
        </Typography>
        <Box flexDirection="column" display="flex" gap={0}>
          <RadioGroup
            row
            sx={{display: 'flex', gap: '2rem'}}
            value={String(values.hasReferral)}
            onChange={handleHasReferralChange}
          >
            <RadioBox
              value={String(true)}
              label="Yes"
              error={touched.hasReferral && Boolean(errors.hasReferral)}
            />
            <RadioBox
              value={String(false)}
              label="No"
              error={touched.hasReferral && Boolean(errors.hasReferral)}
            />
          </RadioGroup>
          {touched.hasReferral && errors.hasReferral && (
            <Typography color={theme.palette.error.main} fontWeight={400}>
              {errors.hasReferral}
            </Typography>
          )}
        </Box>
      </Box>
      <Box flexDirection="column" display="flex" gap={2}>
        {values.hasReferral === true && (
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            flex={1}
            maxWidth="sm"
          >
            <FileUpload
              name="referralFile"
              value={values.referralFile || undefined}
              onBlur={handleBlur}
              onChange={handleReferralFileChange}
            />
            {touched.referralFile && errors.referralFile && (
              <Typography
                variant="caption"
                color={theme.palette.error.main}
                fontWeight={400}
              >
                {errors.referralFile}
              </Typography>
            )}
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          flex={1}
          maxWidth="sm"
        >
          <Typography variant="h3" sx={styles.stepsTypeHead}>
            Reason for your booking
          </Typography>
          <TextArea
            name="bookingReason"
            placeholder="If needed, you can provide some additional details here explaining why you're booking this appointment (optional)."
            value={values.bookingReason || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.bookingReason && Boolean(errors.bookingReason)}
          />
          {touched.bookingReason && errors.bookingReason && (
            <Typography
              variant="caption"
              color={theme.palette.error.main}
              fontWeight={400}
            >
              {errors.bookingReason}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
}

export default GPOrOpticianReferralForm;
