import {Color} from '$constants/style';
import theme from '$modules/booking/theme';
import {scrollbarStyles} from '$styles/index';
import {SxProps} from '@mui/material';

export const styles: Record<string, SxProps> = {
  screenSection: {
    display: 'flex',
    flex: 1,

    [theme.breakpoints.up('lg')]: {
      overflowX: 'hidden',
      overflowY: 'auto',
      ...scrollbarStyles,
    },
  },

  bookingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
    background: 'linear-gradient(118.28deg, #FFF1D5 1.91%, #F3F3FF 77.03%)',
    padding: theme.spacing(7.5, 2.5),

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(10),
      overflowX: 'hidden',
      overflowY: 'auto',
      ...scrollbarStyles,
    },
  },

  appSummaryWrap: {
    display: 'flex',
    gridGap: theme.spacing(5),
    flex: 1,

    [theme.breakpoints.up('lg')]: {
      gridGap: theme.spacing(8),
      overflowX: 'hidden',
      overflowY: 'auto',
      ...scrollbarStyles,
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',

    '& .site-logo': {
      width: '52px',
      height: '100%',
    },

    '& .hosptial-logo': {
      width: '178px',
      height: '100%',
    },

    [theme.breakpoints.up('lg')]: {
      width: 'unset',
    },
  },

  appSummarySection: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gridGap: theme.spacing(4),

    [theme.breakpoints.up('lg')]: {
      gridGap: theme.spacing(6),
      ...scrollbarStyles,
    },
  },

  summaryActionBtnWrap: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(3),

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },

  summaryHead: {
    color: theme.palette.primary.main,
    fontSize: '2rem',
    fontWeight: 700,

    [theme.breakpoints.up('lg')]: {
      fontSize: '48px',
      fontWeight: 700,
    },
  },

  summaryBody: {
    color: '#1D2939',
    fontSize: '0.75 rem',

    [theme.breakpoints.up('lg')]: {
      fontSize: '12px',
    },
  },

  bookingActionBtn: {
    textTransform: 'none',

    '&.delete-button': {
      '& svg': {
        color: theme.palette.error.main,
      },
    },
  },
  appSummaryVideoSection: {
    display: 'none',
    position: 'relative',
    overflow: 'hidden',

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },

    [theme.breakpoints.up('lg')]: {
      display: 'block',
      width: '50%',
    },
  },
  hospitalLogoWrap: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  continueBtn: {
    background: Color.GradientBackground,
    color: Color.White,
    boxShadow: 'none',
    fontWeight: '400',
    padding: '0.5rem 1.5rem',
    borderRadius: '8px',
    textTransform: 'none',

    '&:hover': {
      background: Color.GradientHoverBackground,
    },

    '&:focus': {
      background: Color.GradientHoverBackground,
    },

    '&.Mui-disabled': {
      background: Color.GradientDisabledBackground,
      color: Color.BlackOpacity40,
    },
  },
};
