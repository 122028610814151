import {LastAppointment, PatientStatus} from './PatientListView/constant';

export interface FilterOption<T> {
  title: string;
  value: T;
}

export const PATIENT_STATUS: FilterOption<PatientStatus>[] = [
  {
    title: 'New Patient',
    value: PatientStatus.NewPatient,
  },
  {
    title: 'Active',
    value: PatientStatus.Active,
  },
  {
    title: 'Inactive',
    value: PatientStatus.Inactive,
  },
];

export const LAST_APPOINTMENT: FilterOption<LastAppointment>[] = [
  {
    title: 'Last 7 days',
    value: LastAppointment.Week,
  },
  {
    title: 'Last 30 days',
    value: LastAppointment.Month,
  },
  {
    title: 'Last quarter',
    value: LastAppointment.Quarter,
  },
  {
    title: 'Last year',
    value: LastAppointment.Year,
  },
];

export const BUTTON_TEXTS = {
  ADD_HOSPITAL_ADMISSION: 'Add hospital admission',
  BACK: 'Back',
};
