import {GetDocumentsListResponseData} from '$api/patientList/documents';
import {FileIcon, FileUploadIcon} from '$assets/svgs';
import {Button} from '@mui/material';
import UploadDocumentsDialog from './DocumentsModal';
import DocumentList from './DocumentList';
import {DeleteDocumentVariables} from '../../hooks/useDocumentsHook';

interface DocumentsComponentProps {
  patientId: string;
  isDocumentsLoading: boolean;
  documentsList?: GetDocumentsListResponseData;
  deleteDocumentRequest: ({
    documentId,
    onSuccess,
  }: DeleteDocumentVariables) => void;
  isDocumentDeleting: boolean;
  uploadDocumentsDialogOpen: boolean;
  handleUploadDocumentsDialog: () => void;
  showDeleteModal: boolean;
  setShowDeleteModal: (show: boolean) => void;
}

function DocumentsComponent(props: DocumentsComponentProps) {
  const {
    patientId,
    documentsList,
    deleteDocumentRequest,
    isDocumentDeleting,
    isDocumentsLoading,
    uploadDocumentsDialogOpen,
    handleUploadDocumentsDialog,
    setShowDeleteModal,
    showDeleteModal,
  } = props;

  return (
    <div className="clinical-data__documents col-span-2 row-span-2">
      <div className="header">
        <div className="card-title-container">
          <FileIcon />
          <span className="card-title">Documents</span>
        </div>
        <Button
          variant="text"
          className="normal-case text-[#7625DC]"
          startIcon={<FileUploadIcon />}
          onClick={handleUploadDocumentsDialog}
        >
          Upload File
        </Button>
      </div>
      {isDocumentsLoading && (
        <div className="text-center py-4">Loading documents...</div>
      )}
      {documentsList && (
        <DocumentList
          deleteDocumentRequest={deleteDocumentRequest}
          isDocumentDeleting={isDocumentDeleting}
          documentsList={documentsList}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
        />
      )}
      {uploadDocumentsDialogOpen && (
        <UploadDocumentsDialog
          patientId={patientId}
          open={uploadDocumentsDialogOpen}
          handleClose={handleUploadDocumentsDialog}
        />
      )}
    </div>
  );
}

export default DocumentsComponent;
