import {Dialog, IconButton, MenuItem, Typography} from '@mui/material';
import XCloseIcon from '$assets/svgs/x-close.svg?react';
import FrontdeskButton from '$components/FrontdeskButton';
import Input from '$components/Input';
import {Color} from '$constants/style';
import SelectInput from '$components/SelectInput';
import {FREQUENCY_ITEMS} from '../constant';
import {useFormik} from 'formik';
import medicationSchema, {MedicationFormData} from './schema';
import theme from '$modules/booking/theme';
import '../styles.scss';
import {useCreateMedication, useModifyMedication} from '../../hooks';
import {useParams} from 'react-router-dom';
import {Medication} from '../../types';
import {useEffect} from 'react';

interface MedicationDialogProps {
  open: boolean;
  onClose: () => void;
  medication?: Medication;
}

function MedicationDialog(props: MedicationDialogProps) {
  const {open, onClose, medication} = props;

  const params = useParams();
  const patientId = Number(params.patientId);
  const dialogTitle = medication ? 'Edit medication' : 'Add medication';

  const {sendCreateMedicationRequest, isPending, disableSubmit} =
    useCreateMedication(() => onClose());

  const {
    sendModifyMedicationRequest,
    isPending: isModifyPending,
    disableSubmit: isModifyDisableSubmit,
  } = useModifyMedication(() => onClose());

  const handleSubmit = (values: MedicationFormData) => {
    const payload = {
      name: values.name,
      dosage: values.dosage,
      frequency: values.frequency,
      patientId: patientId,
      instruction: values.instruction,
    };

    if (medication) {
      sendModifyMedicationRequest({
        id: medication.id,
        ...payload,
      });
    } else {
      sendCreateMedicationRequest(payload);
    }
  };

  const formik = useFormik<MedicationFormData>({
    initialValues: {
      name: medication?.name || '',
      dosage: medication?.dosage || '',
      frequency: medication?.frequency || '',
      instruction: medication?.instruction || '',
    },
    validationSchema: medicationSchema,
    onSubmit: handleSubmit,
  });

  const renderFrequencyItem = (item: {label: string; value: string}) => {
    return (
      <MenuItem key={item.value} value={item.value}>
        {item ? item.label : 'Choose the frequency'}
      </MenuItem>
    );
  };

  const renderFrequencyValue = (value: string) => {
    if (value === '') {
      return (
        <Typography color={Color.TextPrimayPlaceholder}>
          Choose the frequency
        </Typography>
      );
    }

    const item = FREQUENCY_ITEMS.find(it => it.value === value);

    if (!item) {
      return (
        <Typography color={Color.TextPrimayPlaceholder}>
          Choose the frequency
        </Typography>
      );
    }

    return <Typography>{item.label}</Typography>;
  };

  const handleFrequencyChange = (value: string) => {
    formik.setFieldValue('frequency', value);
  };

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const setValues = formik.setValues;
  const resetForm = formik.resetForm;

  useEffect(() => {
    if (medication) {
      setValues({
        name: medication?.name,
        dosage: medication.dosage,
        frequency: medication.frequency,
        instruction: medication.instruction,
      });
    } else {
      resetForm();
    }
  }, [setValues, medication, resetForm]);

  const values = formik.values;
  const touched = formik.touched;
  const errors = formik.errors;

  return (
    <Dialog className="medication-dialog" open={open} onClose={handleClose}>
      <div className="header">
        <h2 className="title">{dialogTitle}</h2>
        <IconButton onClick={handleClose}>
          <XCloseIcon />
        </IconButton>
      </div>

      <div className="body">
        <label>
          Medication name
          <Input
            placeholder="Write the medication name"
            name="name"
            value={values.name || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={touched.name}
            error={Boolean(errors.name)}
            helperText={touched?.name && errors?.name ? errors?.name : ''}
          />
        </label>
        <label>
          Dosage
          <Input
            placeholder="Write dosage"
            name="dosage"
            value={values.dosage || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={touched.dosage}
            error={Boolean(errors.dosage)}
            helperText={touched?.dosage && errors?.dosage ? errors?.dosage : ''}
          />
        </label>
        <div className="frequency-container">
          <label>Choose the frequency</label>
          <SelectInput
            value={
              Number.isNaN(values.frequency) ? '' : values.frequency.toString()
            }
            onChange={handleFrequencyChange}
            placeholder="Choose the frequency"
            items={FREQUENCY_ITEMS}
            renderItem={renderFrequencyItem}
            renderValue={renderFrequencyValue}
            error={Boolean(touched.frequency && errors.frequency)}
          />
          {touched?.frequency && errors?.frequency && (
            <Typography
              variant="caption"
              color={theme.palette.error.main}
              fontWeight={400}
            >
              {errors?.frequency}
            </Typography>
          )}
        </div>
        <label>
          Additional instructions
          <Input
            placeholder="Write here any additional detail (optional)."
            name="instruction"
            value={values.instruction || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={touched.instruction}
            error={Boolean(errors.instruction)}
            helperText={
              touched?.instruction && errors?.instruction
                ? errors?.instruction
                : ''
            }
          />
        </label>
      </div>

      <div className="actions">
        <FrontdeskButton
          variant="secondary"
          title="Cancel"
          onClick={handleClose}
        />
        <FrontdeskButton
          variant="primary"
          title="Save"
          onClick={() => formik.handleSubmit()}
          loading={isPending || isModifyPending}
          disabled={disableSubmit || isModifyDisableSubmit}
        />
      </div>
    </Dialog>
  );
}

export default MedicationDialog;
