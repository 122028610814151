import {useAppointmentList} from '$modules/frontdesk/hooks';
import {useMemo, useState} from 'react';
import TimelineComponent from './component';
import {useConsultationList, useTestList} from './hooks';
import {TimelineTab} from './constant';
import {useParams} from 'react-router-dom';
import moment from '$utils/date';
import TimelineSkeleton from './TimelineSkeleton';

function Timeline() {
  const params = useParams();
  const patientId = Number(params.patientId);

  const [activeTab, setActiveTab] = useState<TimelineTab>(
    TimelineTab.Consultation
  );

  const tomorrowDate = moment().startOf('day').add(1, 'day').toDate();

  const {
    consultationSections,
    totalRecords: totalConsultationRecords,
    isLoading: isConsultationLoading,
    isFetchingNextPage: isConsultationFetchingNextPage,
    fetchNextPage: consultationFetchNextPage,
    hasNextPage: consultationHasNextPage,
  } = useConsultationList(patientId);

  const {
    testSections,
    totalRecords: totalTestRecords,
    isLoading: isTestLoading,
    isFetchingNextPage: isTestFetchingNextPage,
    fetchNextPage: testFetchNextPage,
    hasNextPage: testHasNextPage,
  } = useTestList(patientId);

  const {appointments: upcomingEvents, isLoading: isUpcomingEventsLoading} =
    useAppointmentList({
      fromDate: tomorrowDate,
      patientId: patientId,
      orderBy: 'date',
      orderDirection: 'desc',
      status: ['confirm'],
    });

  const fetchNextPage = useMemo(() => {
    switch (activeTab) {
      case TimelineTab.Consultation:
        return consultationFetchNextPage;
      case TimelineTab.Test:
        return testFetchNextPage;
      default:
        return () => {};
    }
  }, [activeTab, consultationFetchNextPage, testFetchNextPage]);

  const hasNextPage = useMemo(() => {
    switch (activeTab) {
      case TimelineTab.Consultation:
        return consultationHasNextPage;
      case TimelineTab.Test:
        return testHasNextPage;
      default:
        return false;
    }
  }, [activeTab, consultationHasNextPage, testHasNextPage]);

  if (isUpcomingEventsLoading || isConsultationLoading || isTestLoading)
    return <TimelineSkeleton />;

  return (
    <TimelineComponent
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      consultationSections={consultationSections}
      testSections={testSections}
      upcomingEvents={upcomingEvents}
      totalConsultation={totalConsultationRecords}
      totalTest={totalTestRecords}
      isFetchingNextPage={
        isConsultationFetchingNextPage || isTestFetchingNextPage
      }
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
    />
  );
}

export default Timeline;
