import {useRef, useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import SidebarDrawer from '$components/SidebarDrawer';
import CalendarRefContext from '../../context/CalendarRefContext';
import {subscribePushManager} from '../../worker/registration';

function StaffLayout() {
  const calendarRef = useRef<FullCalendar>(null);

  useEffect(() => {
    subscribePushManager();
  }, []);

  return (
    <CalendarRefContext.Provider value={calendarRef}>
      <div className="front-desk-container h-screen w-screen flex">
        <SidebarDrawer />
        <Outlet />
      </div>
    </CalendarRefContext.Provider>
  );
}

export default StaffLayout;
