import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup, {RadioGroupProps} from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import FetchError from '$components/FetchError';
import Loading from '$components/Loading';
import RadioBox from '$components/RadioBox';
import {BookingData, BookingDataUpdater} from '../../types';
import {useAPIConstants} from '~/services/api/constants/hooks';
import {ConstantsAPIKey} from '~/services/api/constants/constants';
import SubcareTypeSection from './SubcareTypeSection';

interface CareTypeSectionProps {
  formChoices: BookingData;
  setFormChoices: BookingDataUpdater;
}

function CareTypeSection(props: CareTypeSectionProps) {
  const {formChoices, setFormChoices} = props;

  const {data, isLoading} = useAPIConstants({
    key: ConstantsAPIKey.Care,
    appointmentId: formChoices.appointment?.id,
  });

  if (isLoading) return <Loading />;
  if (!data) return <FetchError />;

  const handleCareTypeChange: RadioGroupProps['onChange'] = e => {
    const item = data.find(it => it.id.toString() === e.target.value);
    setFormChoices(draft => {
      draft.care = item || undefined;
    });
  };

  const careTypeButtons = data.map(item => (
    <RadioBox
      key={item.id}
      value={item.id.toString()}
      label={item.title}
      disabled={!item.isEnable}
    />
  ));

  return (
    <>
      <FormControl>
        <FormLabel id="appointment-type1">
          <Typography variant="h4">
            What kind of appointment would you like to book?
          </Typography>
        </FormLabel>
        <RadioGroup
          value={formChoices.care?.id}
          name="radio-buttons-group"
          onChange={handleCareTypeChange}
        >
          {careTypeButtons}
        </RadioGroup>
      </FormControl>
      {formChoices.appointment && formChoices.care && (
        <SubcareTypeSection
          formChoices={formChoices}
          setFormChoices={setFormChoices}
        />
      )}
    </>
  );
}

export default CareTypeSection;
