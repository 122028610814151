import {boolean, InferType, number, object, string} from 'yup';
import {ValidationMessage} from '$constants/messages';
import {
  MEMBERSHIP_VALIDATION_REGEX,
  POSTCODE_VALIDATION_REGEX,
} from '$constants/regex';

export const editBookingFormSchema = object({
  appointment: object({
    sub_care_type_id: number().required(),
    appointment_type_id: number().required(),
    care_type_id: number().required(),
    date: string().required(),
    doctor_id: number().required('Required field'),
    preferred_time: string().required(),
    referred_by_physician: boolean().required(),
    reason: string().notRequired(),
    self_booking: boolean().required(),
    self_paying: boolean().required(),
    payment_mode: string().notRequired(),
    insurance_id: number().when('self_paying', {
      is: false,
      then: schema => schema.required(ValidationMessage.InsuranceRequired),
      otherwise: schema => schema.notRequired(),
    }),
    preferred_point_of_contact: string().required(),
    country_code: string().required(),
    membership_no: string()
      .trim()
      .when(['self_paying', 'insurance_id'], {
        is: (selfPaying: boolean) => !selfPaying,
        then: schema => schema.required(ValidationMessage.MembershipRequired),
        otherwise: schema => schema.notRequired(),
      })
      .matches(
        MEMBERSHIP_VALIDATION_REGEX,
        ValidationMessage.MembershipInvalid
      ),
    contact_email: string()
      .trim()
      .email(ValidationMessage.EmailInvalid)
      .max(350, ValidationMessage.EmailExceedCharacterLimit)
      .required(),
    contact_mobile_number: number()
      .typeError(ValidationMessage.PhoneNumberInvalid)
      .positive(ValidationMessage.PhoneNumberInvalid)
      .integer(ValidationMessage.PhoneNumberInvalid)
      .min(10_000_00, ValidationMessage.PhoneNumberInvalid)
      .max(999_999_999_999_999, ValidationMessage.PhoneNumberInvalid)
      .required(ValidationMessage.PhoneNumberRequired),
    patient: object({
      id: number().required(),
      prefix: string().required(),
      first_name: string().required(),
      last_name: string().required(),
      dob: string().required(),
      gender: string().required(),
      country_code: string().required(),
      mobile_number: number()
        .typeError(ValidationMessage.PhoneNumberInvalid)
        .positive(ValidationMessage.PhoneNumberInvalid)
        .integer(ValidationMessage.PhoneNumberInvalid)
        .min(10_000_00, ValidationMessage.PhoneNumberInvalid)
        .max(999_999_999_999_999, ValidationMessage.PhoneNumberInvalid),
      email: string()
        .trim()
        .email(ValidationMessage.EmailInvalid)
        .max(350, ValidationMessage.EmailExceedCharacterLimit),
      address: object({
        id: number().required(),
        line1: string()
          .trim()
          .max(150, ValidationMessage.AddressLine1ExceedCharacterLimit)
          .required(ValidationMessage.AddressLine1Required),
        line2: string()
          .required()
          .trim()
          .max(150, ValidationMessage.AddressLine1ExceedCharacterLimit)
          .notRequired(),
        postcode: string()
          .trim()
          .max(7, ValidationMessage.PostcodeExceedCharacterLimit)
          .matches(POSTCODE_VALIDATION_REGEX, ValidationMessage.PostcodeInvalid)
          .required(ValidationMessage.PostcodeRequired),
        city: string()
          .trim()
          .max(50, ValidationMessage.CityExceedCharacterLimit)
          .required(ValidationMessage.CityRequired),
        country: string()
          .trim()
          .max(60, ValidationMessage.CountryExceedCharacterLimit)
          .required(ValidationMessage.CountryRequired),
      }).required(),
    }).required(),
    care_taker: object({
      id: number().required(),
      first_name: string().required(),
      last_name: string().required(),
      country_code: string().required(),
      mobile_number: number()
        .typeError(ValidationMessage.PhoneNumberInvalid)
        .positive(ValidationMessage.PhoneNumberInvalid)
        .integer(ValidationMessage.PhoneNumberInvalid)
        .min(10_000_00, ValidationMessage.PhoneNumberInvalid)
        .max(999_999_999_999_999, ValidationMessage.PhoneNumberInvalid),
      email: string()
        .trim()
        .email(ValidationMessage.EmailInvalid)
        .max(350, ValidationMessage.EmailExceedCharacterLimit),
      relationship: string().required(),
    }).nullable(),
  }),
}).required();

export type EditBookingFormData = InferType<typeof editBookingFormSchema>;
