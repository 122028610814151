import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import InfoCircleIcon from '$assets/svgs/info-circle.svg?react';
import theme from '$modules/booking/theme';
import styles from './styles';

interface InputProps {
  name: string;
  value: string | number;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  touched?: boolean;
  error?: boolean;
  helperText?: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  endIcon?: React.ReactNode;
  multiline?: boolean;
  endIconClick?: () => void;
  endIconClassname?: string;
}

function Input(props: InputProps) {
  const {
    name,
    value,
    className,
    touched,
    error,
    helperText,
    type,
    placeholder,
    endIcon,
    multiline,
    disabled = false,
    endIconClick,
    onBlur,
    onChange,
    endIconClassname,
  } = props;

  let endAdornment: React.ReactNode = null;

  if (error && touched) {
    endAdornment = (
      <InputAdornment position="end">
        <IconButton
          sx={{color: theme.palette.error.main}}
          className="!text-error-color"
          onClick={endIconClick}
        >
          <InfoCircleIcon />
        </IconButton>
      </InputAdornment>
    );
  }

  if (endIcon) {
    endAdornment = (
      <InputAdornment position="end">
        <IconButton
          sx={{
            color:
              error && touched
                ? theme.palette.error.main
                : theme.palette.text.primary,
          }}
          className={
            endIconClassname ? endIconClassname : '!text-tertiary-color'
          }
          onClick={endIconClick}
        >
          {endIcon}
        </IconButton>
      </InputAdornment>
    );
  }

  return (
    <Box sx={styles.container}>
      <OutlinedInput
        multiline={multiline}
        rows={multiline ? 4 : 1}
        name={name}
        value={value}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        type={type}
        error={touched && error}
        className={className}
        sx={styles.input}
        placeholder={placeholder}
        endAdornment={endAdornment}
      />
      {helperText &&
        (error && touched ? (
          <Typography
            variant="caption"
            color={theme.palette.error.main}
            fontWeight={400}
          >
            {helperText}
          </Typography>
        ) : (
          <Typography
            variant="caption"
            color={theme.palette.text.primary}
            fontWeight={300}
          >
            {helperText}
          </Typography>
        ))}
    </Box>
  );
}

export default Input;
