import {type SxProps} from '@mui/material';
import styled from '@mui/material/styles/styled';
import theme from '$modules/booking/theme';

const styles: Record<string, SxProps> = {
  radioLabel: {
    color: theme.palette.text.primary,
  },
  radioErrorLabel: {
    color: theme.palette.error.main,
  },
  radioDisabledLabel: {
    color: theme.palette.text.primary,
    opacity: 0.75,
  },
  labelStyle: {
    pointerEvents: 'auto',
    cursor: 'pointer',
  },
  disabledLabelStyle: {
    pointerEvents: 'auto',
    cursor: 'default',
  },
  radioContainer: {
    marginLeft: 0,
    marginRight: 0,
  },
  boxStyle: {
    pointerEvents: 'none',
  },
};

export const EoIcon = styled('span')(() => ({
  borderRadius: '50%',
  width: 24,
  height: 24,
  border: '1.5px solid #475467',
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  'input:disabled ~ &': {
    borderColor: 'rgba(71,84,103,.6)',
  },
}));

export const EoCheckedIcon = styled(EoIcon)({
  '&::before': {
    width: 12,
    height: 12,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    content: '""',
  },
});

export const EoErrorIcon = styled(EoIcon)({
  borderColor: theme.palette.error.main,
});

export const EoErrorCheckedIcon = styled(EoIcon)({
  borderColor: theme.palette.error.main,

  '&::before': {
    width: 12,
    height: 12,
    backgroundColor: theme.palette.error.main,
    borderRadius: '50%',
    content: '""',
  },
});

export default styles;
