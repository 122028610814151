export interface PatientDetail {
  label: string;
  description: string;
}

export const PATIENT_DETAILS_MAP: PatientDetail[] = [
  {
    label: 'Date of birth',
    description: 'dob',
  },
  {
    label: 'Gender',
    description: 'gender',
  },
  {
    label: 'Address',
    description: 'address',
  },
  {
    label: 'Post code',
    description: 'postcode',
  },
  {
    label: 'Town or city',
    description: 'city_name',
  },
  {
    label: 'Country',
    description: 'country_name',
  },
  {
    label: 'NHS Number',
    description: 'nhs_number',
  },
  {
    label: 'Email address',
    description: 'email',
  },
  {
    label: 'Phone number',
    description: 'mobile_number',
  },
  {
    label: 'Registration date',
    description: '',
  },
];

export const PATIENT_CARE_DEFAULT_COLOR = '#7625dc';
