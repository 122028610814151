import {SetStateAction} from 'react';
import {TextField} from '@mui/material';
import FrontdeskButton from '$components/FrontdeskButton';
import NoteAuthor from '../NoteAuthor';
import {Note} from '../types';
import DocumentUpload from './DocumentUpload';

interface NoteFormComponentProps {
  isModify: boolean;
  title: string;
  setTitle: React.Dispatch<SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<SetStateAction<string>>;
  document: File | null;
  handleDocumentChange: (file: File | null) => void;
  onSubmit: () => void;
  submitLoading: boolean;
  disableSubmit: boolean;
  author?: Note['createdBy'];
  onReset: () => void;
}

function NoteFormComponent(props: NoteFormComponentProps) {
  const {
    isModify,
    title,
    setTitle,
    description,
    setDescription,
    onSubmit,
    submitLoading,
    disableSubmit,
    author,
    onReset,
    document,
    handleDocumentChange,
  } = props;

  const handleTitleChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const value = event.target.value;
    setTitle(value.substring(0, 150));
  };

  const handleDescriptionChange: React.ChangeEventHandler<
    HTMLTextAreaElement
  > = event => {
    const value = event.target.value;
    setDescription(value.substring(0, 5000));
  };

  return (
    <div className="flex flex-col flex-1 ml-6 min-w-96 min-h-[500px]">
      <div className="flex-1 bg-white rounded-xl flex py-6 flex-col overflow-auto">
        <div className="flex justify-between items-center mb-4 px-6">
          <div className="flex gap-2 justify-center items-center">
            <h4 className="text-2xl mr-8">
              {isModify ? 'Modify' : 'New'} note
            </h4>
            {isModify && author && (
              <NoteAuthor
                profileUrl={author.profileUrl}
                fullName={author.fullName}
              />
            )}
          </div>
          <DocumentUpload
            name="attachment"
            value={document || undefined}
            onChange={handleDocumentChange}
          />
        </div>
        <TextField
          placeholder="Write the note title here"
          className="note-title-field w-full px-6"
          value={title}
          onChange={handleTitleChange}
        />
        <textarea
          className="note-description-field flex-1 overflow-auto px-6"
          placeholder="Add your notes body here e.g. a summary or considerations to remember later or share with other clinicians. (max 5000 characters)"
          value={description}
          onChange={handleDescriptionChange}
        />
      </div>
      <div className="flex justify-end gap-3 mt-8">
        <FrontdeskButton
          variant="secondary"
          disabled={disableSubmit}
          title={'Discard changes'}
          onClick={onReset}
        />
        <FrontdeskButton
          title="Save note"
          disabled={disableSubmit}
          loading={submitLoading}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
}

export default NoteFormComponent;
