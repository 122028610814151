import {useParams} from 'react-router-dom';
import {useDeleteMedication, useMedicationList} from '../hooks';
import MedicationListComponent from './component';
import {useState} from 'react';
import MedicationListSkeleton from './MedicationListSkeleton';

function MedicationList() {
  const params = useParams();
  const patientId = Number(params.patientId);

  const [openMedicationDialog, setOpenMedicationDialog] = useState(false);
  const [openDeleteMedicationDialog, setOpenDeleteMedicationDialog] =
    useState(false);
  const [medicationId, setMedicationId] = useState<number>();
  const {medicationList, isLoading} = useMedicationList(patientId);
  const {deleteMedicationRequest, disableSubmit, isDeletingMedication} =
    useDeleteMedication(() => setOpenDeleteMedicationDialog(false));

  const medication = medicationList.find(m => m.id === medicationId);

  const handleDeleteMedicationRequest = (id: number) => {
    deleteMedicationRequest(id);
  };

  if (isLoading) return <MedicationListSkeleton />;

  return (
    <MedicationListComponent
      patientId={patientId}
      medication={medication}
      openMedicationDialog={openMedicationDialog}
      setMedicationId={setMedicationId}
      setOpenMedicationDialog={setOpenMedicationDialog}
      disableClick={disableSubmit || isDeletingMedication}
      handleDeleteMedicationRequest={handleDeleteMedicationRequest}
      openDeleteMedicationDialog={openDeleteMedicationDialog}
      setOpenDeleteMedicationDialog={setOpenDeleteMedicationDialog}
    />
  );
}

export default MedicationList;
