import {
  getNotificationList,
  GetNotificationListParams,
  getNotificationUnreadCount,
  putNotificationMarkRead,
  PutNotificationMarkReadVariables,
} from '$api/notification';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {useMemo, useState} from 'react';
import {Notification} from './types';

export function useNotificationUnreadCount() {
  const {data} = useQuery({
    queryKey: ['frontdesk-notification-unread-count'],
    queryFn: getNotificationUnreadCount,
  });

  return {count: data?.data.unread_notifications_count};
}

export function useNotificationList(params: GetNotificationListParams) {
  const {
    data,
    isLoading,
    isError,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'frontdesk-notification-list',
      params.perPage,
      params.status,
      params.type,
    ],
    queryFn: ({pageParam}) =>
      getNotificationList({
        perPage: params.perPage,
        status: params.status,
        type: params.type,
        pageNumber: pageParam,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) =>
      lastPageParam === lastPage?.data?.total_pages ? null : lastPageParam + 1,
  });

  const notifications = useMemo(() => {
    const pages = data?.pages || [];

    return pages.reduce((acc, page) => {
      const data = page?.data?.notifications || [];

      for (const notif of data) {
        acc.push({
          id: notif.id,
          title: notif.title,
          description: notif.description,
          notificationType: notif.notification_type,
          createdAt: new Date(notif.created_at),
          seenAt: notif.seen_at ? new Date(notif.seen_at) : null,
          senderId: notif.sender_id,
          senderType: notif.sender_type,
          senderName: notif.sender_name,
          unread: notif.unread,
          resourceId: notif.resource_id,
          resourceType: notif.resource_type,
        });
      }

      return acc;
    }, [] as Notification[]);
  }, [data]);

  const {
    totalNotifications,
    pendingRequestCount,
    newBookingCount,
    appointmentCancelledCount,
    newPatientsCount,
  } = useMemo(() => {
    const pages = data?.pages;
    if (!pages)
      return {
        totalNotifications: null,
        pendingRequestCount: null,
        newBookingCount: null,
        appointmentCancelledCount: null,
        newPatientsCount: null,
      };

    const lastPage = pages[pages.length - 1];
    return {
      totalNotifications: lastPage.data.all_notification_count,
      pendingRequestCount: lastPage.data.pending_request_count,
      newBookingCount: lastPage.data.new_booking_request_count,
      appointmentCancelledCount: lastPage.data.cancelled_appointment_count,
      newPatientsCount: lastPage.data.new_patients_count,
    };
  }, [data]);

  return {
    notifications,
    totalNotifications,
    pendingRequestCount,
    newBookingCount,
    appointmentCancelledCount,
    newPatientsCount,
    isLoading,
    isError,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
}

export function useNotificationMarkRead() {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const queryClient = useQueryClient();

  const {mutate, isPending} = useMutation({
    mutationKey: ['frontdesk-notification-mark-read'],
    mutationFn: putNotificationMarkRead,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['frontdesk-notification-unread-count'],
      });
      queryClient.invalidateQueries({
        queryKey: ['frontdesk-notification-list'],
      });
    },
  });

  const sendMarkReadRequest = (variables: PutNotificationMarkReadVariables) => {
    setDisableSubmit(true);
    mutate(variables);
  };

  return {sendMarkReadRequest, isPending, disableSubmit};
}
