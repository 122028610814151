import {useNavigate} from 'react-router-dom';
import DeleteAppointmentModal from './DeleteAppointmentModal';
import {useDeleteAppointment} from './hooks';
import {RoutePath} from '$constants/routes';
import Loading from '$components/Loading';
import {useEffect} from 'react';
import toast from '$utils/toast';
import {ToastType} from '$components/Toast/constant';

interface DeleteAppointmentComponentProps {
  token: string;
}

function DeleteAppointmentComponent(props: DeleteAppointmentComponentProps) {
  const {token} = props;
  const navigate = useNavigate();

  const {
    appointmentDetails,
    isAppointmentsLoading,
    isAppointmentDetailsError,
    requestDeleteAppointment,
    disableSubmit,
  } = useDeleteAppointment(token);

  useEffect(() => {
    if (isAppointmentDetailsError) {
      toast(
        ToastType.Error,
        'Appointment does not exist',
        'The appointment has already been deleted.'
      );
      navigate(RoutePath.bookingRoute);
    }
  }, [
    appointmentDetails,
    isAppointmentsLoading,
    navigate,
    isAppointmentDetailsError,
  ]);

  if (isAppointmentsLoading) {
    return <Loading />;
  }

  return (
    <DeleteAppointmentModal
      isOpen={true}
      isDisabled={disableSubmit}
      onDelete={requestDeleteAppointment}
      onClose={() => navigate(RoutePath.bookingRoute)}
    />
  );
}

export default DeleteAppointmentComponent;
