import {useState, useEffect} from 'react';
import {useFormik} from 'formik';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import BookingStepper from '../components/BookingStepper';
import AppointmentSummaryTile from '../components/AppointmentSummaryTile';
import Footer from '../components/Footer';
import {
  GPOrOpticianReferralFormData,
  gpOrOpticianReferralFormSchema,
  GPOrOpticianReferralValidatedFormData,
} from '../components/GPOrOpticianReferralForm/schema';
import styles from '../styles';
import {
  BookingDataUpdater,
  BookingStepperStage,
  BookingView,
  GPOrOpticianReferralViewChoices,
} from '../types';
import GPOrOpticianReferralForm from '../components/GPOrOpticianReferralForm';

interface GPOrOpticianReferralViewProps {
  bookingChoices: GPOrOpticianReferralViewChoices;
  setActiveView: (view: BookingView) => void;
  setBookingChoices: BookingDataUpdater;
}

function GPOrOpticianReferralView(props: GPOrOpticianReferralViewProps) {
  const {bookingChoices, setActiveView, setBookingChoices} = props;
  const [isFormValid, setIsFormValid] = useState(false);

  const handleSubmit = (vals: GPOrOpticianReferralFormData) => {
    const values = vals as GPOrOpticianReferralValidatedFormData;

    const payload: GPOrOpticianReferralValidatedFormData = {
      hasReferral: values.hasReferral,
      bookingReason: values.bookingReason,
    };

    if (values.hasReferral) {
      payload.referralFile = values.referralFile;
    }

    setBookingChoices(draft => {
      draft.gpOrOpticianReferral = payload;
    });

    setActiveView(BookingView.PatientContactDetailView);
  };

  const formik = useFormik<GPOrOpticianReferralFormData>({
    initialValues: bookingChoices.gpOrOpticianReferral || {
      hasReferral: null,
      referralFile: null,
      bookingReason: '',
    },
    validationSchema: gpOrOpticianReferralFormSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    const {hasReferral} = formik.values || {};

    const isValid = formik.isValid && hasReferral !== null;
    setIsFormValid(isValid);
  }, [
    formik.isValid,
    formik.values?.hasReferral,
    formik.values?.bookingReason,
    formik.values,
  ]);

  const handleBackClick = () => {
    setActiveView(BookingView.PatientCarerDetailsView);
  };

  const handleContinueClick = () => {
    formik.handleSubmit();
  };

  return (
    <Box sx={styles.sectionWrapperWithStepper} flexDirection="column" gap={5}>
      <Stack gap={5} sx={styles.section}>
        <Box>
          <BookingStepper activeStage={BookingStepperStage.PatientDetails} />
        </Box>
        <Box>
          <Typography variant="h2" sx={styles.subHeading}>
            Personal Details
          </Typography>
          <Typography variant="body1">
            Please fill in the details below to continue with the booking
            process
          </Typography>
        </Box>
        <GPOrOpticianReferralForm
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          setFieldTouched={formik.setFieldTouched}
          setFieldValue={formik.setFieldValue}
        />
      </Stack>

      <Box sx={styles.footerSection}>
        <AppointmentSummaryTile choices={bookingChoices} />
        <Footer
          onBackClick={handleBackClick}
          onContinueClick={handleContinueClick}
          disableContinue={!isFormValid}
        />
      </Box>
    </Box>
  );
}

export default GPOrOpticianReferralView;
