import React from 'react';
import {Dialog} from '@mui/material';
import {CheckCircleBrokenIcon, XCloseIcon} from '$assets/svgs';
import IconButton from '$components/IconButton';
import './styles.scss';

interface DialogComponentProps {
  open: boolean;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  dialogTitle?: string;
  dialogBody?: React.ReactNode;
  disableHeader?: boolean;
  headerIcon?: React.ReactNode;
}
const DialogComponent: React.FC<DialogComponentProps> = ({
  open,
  handleClose,
  dialogBody,
  disableHeader = false,
  headerIcon = <CheckCircleBrokenIcon />,
}) => {
  return (
    <>
      <Dialog open={open} className="dialog-container">
        {!disableHeader && (
          <div className="dialog-header">
            <div className="icon-container">{headerIcon}</div>
            <IconButton onClick={() => handleClose(prev => !prev)}>
              <XCloseIcon />
            </IconButton>
          </div>
        )}
        {dialogBody}
      </Dialog>
    </>
  );
};

export default DialogComponent;
