import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import styles, {hideSpinnerStyles} from './styles';
import theme from '$modules/booking/theme';
import {useCountries} from '$modules/booking/hooks';

interface PhoneNumberInputProps {
  name: string;
  value: number;
  error?: boolean;
  country?: string;
  placeholder?: string;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

function PhoneNumberInput(props: PhoneNumberInputProps) {
  const {name, value, error, placeholder, country, onBlur, onChange} = props;

  const {countries} = useCountries();
  const valueString = Number.isNaN(value) ? '' : value.toString();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown' ||
      ['e', 'E', '+', '-', '.'].includes(event.key)
    ) {
      event.preventDefault();
    }
  };

  const countryItem = countries.find(item => item.code === country);
  const countryCode = countryItem?.countryCode || '+44';

  return (
    <Box
      sx={styles.container}
      className="phone-number-container"
      borderColor={error ? theme.palette.error.main : theme.palette.grey[200]}
    >
      <Box sx={styles.countryContainer}>
        <Typography>{countryCode}</Typography>
      </Box>
      <OutlinedInput
        sx={{...styles.input, ...hideSpinnerStyles}}
        name={name}
        placeholder={placeholder || 'Phone number'}
        type="number"
        value={valueString}
        onBlur={onBlur}
        onChange={onChange}
        inputProps={{min: 0}}
        onKeyDown={handleKeyDown}
      />
    </Box>
  );
}

export default PhoneNumberInput;
