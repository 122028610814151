export const scrollbarStyles = {
  '&::-webkit-scrollbar': {
    width: '1rem',
    height: '1rem',
  },
  // Additional styles for scrollbar track and thumb if needed
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba($color: #000, $alpha: 0.25)',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba($color: #000, $alpha: 0.25)',
  },
};
