import {Box, MenuItem, RadioGroup, Typography} from '@mui/material';
import RadioBox from '$components/RadioBox';
import {FormikErrors, FormikTouched} from 'formik';
import {PatientInsuranceDetailFormData} from './schema';
import styles, {patientInsuranceDetailViewStyles} from '../../styles';
import SelectInput from '$components/SelectInput';
import {useQuery} from '@tanstack/react-query';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';
import {getInsuranceTypes, getPaymentTypes} from '$api/bookingFlow';
import Input from '$components/Input';
import {Insurance} from '../../types';
import PaymentCardIcon from '$components/PaymentCardIcon';
import {usePaymentCards} from '$modules/booking/hooks';

interface PatientInsuranceDetailFormProps {
  values: PatientInsuranceDetailFormData;
  errors: FormikErrors<PatientInsuranceDetailFormData>;
  touched: FormikTouched<PatientInsuranceDetailFormData>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  setFieldTouched: (field: string) => void;
  setFieldValue: (field: string, value: unknown) => void;
}

function PatientInsuranceDetailForm(props: PatientInsuranceDetailFormProps) {
  const {values, setFieldValue, handleBlur, touched, errors, handleChange} =
    props;
  const selfPaying = values.selfPaying;

  const {data, isLoading} = useQuery({
    queryKey: selfPaying ? ['payment-types'] : ['insurance-types'],
    queryFn: selfPaying ? getPaymentTypes : getInsuranceTypes,
  });

  const {cards, isLoading: isCardsLoading} = usePaymentCards();

  if (isLoading || isCardsLoading) return <Loading />;
  if (!data) return <FetchError />;

  const items = data.data;

  const handleSelfPayingChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    setFieldValue(
      'selfPaying',
      e.target.value === 'true'
        ? true
        : e.target.value === 'false'
          ? false
          : undefined
    );
    setFieldValue('paymentMode', 'Card');
    setFieldValue('insuranceId', '');
    setFieldValue('membershipNumber', '');
  };

  const handlePaymentMethodChange = (value: string) => {
    if (!value) {
      if (selfPaying) {
        setFieldValue('paymentMode', '');
      } else {
        setFieldValue('insuranceId', '');
      }
      return;
    }

    const item = items.find(it =>
      selfPaying ? it.name === value : it.id.toString() === value
    );

    if (item) {
      if (selfPaying) {
        setFieldValue('paymentMode', item.name);
      } else {
        setFieldValue('insuranceId', item.id.toString());
      }
    } else {
      if (selfPaying) {
        setFieldValue('paymentMode', '');
      } else {
        setFieldValue('insuranceId', '');
      }
    }
  };

  const renderMenuItem = (item: Insurance) => {
    return (
      <MenuItem
        key={item.id}
        value={selfPaying ? item.name : item.id.toString()}
      >
        <Typography variant="subtitle1">{item.name}</Typography>
      </MenuItem>
    );
  };

  const renderValue = (value: string) => {
    if (!value)
      return selfPaying ? 'Payment method(optional)' : 'Name of the insurer';

    const item = items.find(it =>
      selfPaying ? it.name === value : it.id.toString() === value
    );
    if (!item) {
      handlePaymentMethodChange('');
      return '';
    }

    return item.name;
  };

  const cardItems = cards.map(card => (
    <Box
      key={card.id}
      sx={patientInsuranceDetailViewStyles.paymentCardContainer}
    >
      <PaymentCardIcon type={card.name} />
    </Box>
  ));

  return (
    <>
      <Box flexDirection="column" display="flex" gap={2}>
        <Typography variant="h3" sx={styles.stepsTypeHead}>
          Are you self paying?
        </Typography>
        <RadioGroup
          row
          sx={{display: 'flex', gap: '2rem'}}
          onChange={handleSelfPayingChange}
          value={String(selfPaying)}
        >
          <RadioBox value={String(true)} label="Yes, I am" />
          <RadioBox value={String(false)} label="No, I'm not" />
        </RadioGroup>
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        {selfPaying ? (
          <>
            <Typography variant="h3">We accept the following cards</Typography>
            <Box display="flex" gap="1.25rem">
              {cardItems}
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h3">Private insurance details</Typography>
            <Box display="flex" flexDirection="column" gap={1}>
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                maxWidth="24rem"
              >
                <SelectInput
                  items={items}
                  renderValue={renderValue}
                  placeholder={'Name of the insurer'}
                  renderItem={renderMenuItem}
                  value={String(values.insuranceId) || ''}
                  onBlur={handleBlur}
                  onChange={handlePaymentMethodChange}
                />
              </Box>
              <Box sx={patientInsuranceDetailViewStyles.inputContainer}>
                <Input
                  name="membershipNumber"
                  placeholder="Membership number"
                  value={values.membershipNumber || ''}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched?.membershipNumber}
                  error={Boolean(errors?.membershipNumber)}
                  helperText={
                    touched?.membershipNumber && errors?.membershipNumber
                      ? errors?.membershipNumber
                      : ''
                  }
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default PatientInsuranceDetailForm;
