import ListIcon from '$assets/svgs/list.svg?react';
import {RECENT_UPDATE_SKELETON_ITEM_COUNT} from '$modules/frontdesk/constant';

function RecentUpdateSkeleton() {
  const skeletonItems = Array.from({
    length: RECENT_UPDATE_SKELETON_ITEM_COUNT,
  });

  return (
    <div className="recent-updates overflow-auto flex flex-col mt-2 row-span-3 animate-pulse">
      <div className="title-container mb-5">
        <div className="title flex items-center">
          <ListIcon className="h-6 w-6" />
          <span className="title-text">Recent updates</span>
        </div>
      </div>
      <div className="overflow-auto flex-1 space-y-5 mt-5">
        {skeletonItems.map((_, index) => (
          <div key={index} className="flex item-container">
            <div className="w-6 shrink-0 flex justify-center relative">
              <div className="item-dot h-3 w-3 bg-gray-300 rounded-full top-1 absolute" />
              <div className="item-bar w-0.5 h-full mt-2 bg-gray-300" />
            </div>
            <div className="flex-1 space-y-2">
              <div className="w-1/3 h-4 bg-gray-300 rounded"></div>
              <div className="item-description-block rounded-lg p-3 space-y-2">
                <div className="w-1/2 h-3 bg-gray-300 rounded"></div>
                <div className="w-1/4 h-2 bg-gray-300 rounded"></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RecentUpdateSkeleton;
