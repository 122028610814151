import {getCancellationReason} from '$api/frontdesk';
import {useQuery} from '@tanstack/react-query';

export const useCancellationReasonHook = () => {
  const {isPending, isError, data} = useQuery({
    queryKey: ['cancellation-reason'],
    queryFn: getCancellationReason,
  });

  return {
    isPending,
    isError,
    data,
  };
};
