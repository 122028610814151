import {ToastType} from '$components/Toast/constant';
import {handleErrorResponse} from '$utils/axios';
import toast from '$utils/toast';
import {useMutation, useQuery} from '@tanstack/react-query';
import {deleteAppointment, getAppointmentDetails} from './api';
import {useNavigate} from 'react-router-dom';
import {RoutePath} from '$constants/routes';
import {useState} from 'react';
import {AppointmentDetailNavigatePayload} from '../types';

export function useDeleteAppointment(token: string) {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const navigate = useNavigate();

  const {
    data: appointmentDetails,
    isLoading: isAppointmentsLoading,
    isError: isAppointmentDetailsError,
  } = useQuery({
    queryKey: ['delete-appointment'],
    queryFn: () => getAppointmentDetails({token}),
  });

  const {mutate, isPending: isAppointmentDeleting} = useMutation({
    mutationKey: ['delete-appointment'],
    mutationFn: deleteAppointment,
    onSuccess: data => {
      toast(ToastType.Success, 'Delete appointment', data.message);
      navigate(RoutePath.deleteSuccessRoute, {
        state: {
          careType: appointmentDetails?.data?.appointment_type_name,
          date: appointmentDetails?.data?.date,
        } as AppointmentDetailNavigatePayload,
      });
    },
    onError: error => {
      handleErrorResponse(error, 'Delete appointment');
    },
    onSettled: () => setDisableSubmit(true),
  });

  const requestDeleteAppointment = () => {
    setDisableSubmit(true);
    mutate({token});
  };

  return {
    appointmentDetails,
    isAppointmentsLoading,
    isAppointmentDeleting,
    disableSubmit,
    requestDeleteAppointment,
    isAppointmentDetailsError,
  };
}
