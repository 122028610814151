import {ToastContainer} from 'react-toastify';
import {Provider} from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import {QueryClientProvider} from '@tanstack/react-query';
import queryClient from '$lib/queryClient';
import store from '$redux/store';
import AppRouter from '$components/AppRouter';

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <AppRouter />
        <ToastContainer limit={5} />
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
