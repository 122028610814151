import React from 'react';
import {Button, Dialog} from '@mui/material';
import {CheckCircleBrokenIcon, XCloseIcon} from '$assets/svgs';
import IconButton from '$components/IconButton';
import './styles.scss';
import FrontdeskButton from '$components/FrontdeskButton';
import {usePutPatientStatus} from '../hooks';

interface ActiveInactivePatientDialogProps {
  open: boolean;
  handleClose: () => void;
  status: string;
  patientId: number;
}
const ActiveInactivePatientDialog: React.FC<
  ActiveInactivePatientDialogProps
> = ({open, handleClose, status, patientId}) => {
  const {sendUpdatePatientStatusRequest} = usePutPatientStatus(handleClose);
  return (
    <>
      <Dialog open={open} className="active-inactive-dialog-container">
        <div className="dialog-header">
          <div className="icon-container">
            <CheckCircleBrokenIcon />
          </div>

          <IconButton onClick={handleClose}>
            <XCloseIcon />
          </IconButton>
        </div>
        <div className="dialog-body">
          <div className="heading">
            Do you want to set the patient as{' '}
            {status === 'active' ? 'inactive' : 'active'} ?
          </div>
        </div>
        <div className="dialog-footer">
          <Button onClick={handleClose} className="cancel-btn">
            Cancel
          </Button>
          <FrontdeskButton
            title="Confirm"
            onClick={() =>
              sendUpdatePatientStatusRequest({
                patientId: patientId,
                status: status === 'active' ? 'inactive' : 'active',
              })
            }
          />
        </div>
      </Dialog>
    </>
  );
};

export default ActiveInactivePatientDialog;
