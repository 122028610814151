import React from 'react';
import {usePatientDetailsHook} from '../../hooks/usePatientDetailsHook';
import FetchError from '$components/FetchError';
import PatientInfoComponent from './component';
import PatientInfoLoading from './loading';

interface PatientInfoProps {
  patientId: number;
  canInvitePatient: boolean;
  canEditPatient: boolean;
  canViewFullProfile: boolean;
}
const PatientInfoContainer: React.FC<PatientInfoProps> = ({
  patientId,
  canInvitePatient,
  canEditPatient,
  canViewFullProfile,
}) => {
  const {
    data: patientDetails,
    modifyPatient,
    isModifyingPatient,
    isFetching,
  } = usePatientDetailsHook(patientId);
  const isError = !patientDetails;

  if (isFetching) return <PatientInfoLoading />;
  if (isError) return <FetchError />;

  return (
    <PatientInfoComponent
      patientDetails={patientDetails}
      canEditPatient={canEditPatient}
      canInvitePatient={canInvitePatient}
      modifyPatient={modifyPatient}
      isModifyingPatient={isModifyingPatient}
      canViewProfile={canViewFullProfile}
      patientId={patientId}
    />
  );
};

export default PatientInfoContainer;
