import {useQuery} from '@tanstack/react-query';
import {getBookingTypes} from '$api/bookingFlow';

export const useBookingTypeHook = () => {
  const {isPending, isError, error, data} = useQuery({
    queryKey: ['booking-types'],
    queryFn: getBookingTypes,
  });

  return {isPending, isError, error, data};
};
