import {FinancialDetailIcon} from '$assets/svgs';
import {eoCard} from '$modules/frontdesk/styles';
import {Grid, Box, Stack, Typography} from '@mui/material';

interface FinancialDetails {
  self_paying: boolean;
  insurance: {
    id: number;
    name: string;
  };
  membership_no: string;
  payment_mode: string;
}

const FinancialDetails = (details: FinancialDetails) => {
  const {self_paying, insurance, membership_no, payment_mode} = details;

  const gradientText = {
    background: '-webkit-linear-gradient(180deg, #7625DC, #6063F2)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };

  return (
    <Grid item sx={{flex: 1}}>
      <Box sx={{...eoCard, height: '100%'}}>
        <Stack spacing={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <FinancialDetailIcon />
            <Typography variant="h4" style={gradientText}>
              Financial Details
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" sx={{mb: 1}}>
              Self paying
            </Typography>
            <Typography variant="subtitle2">
              {self_paying ? 'Yes' : ' No'}
            </Typography>
          </Box>
          {!self_paying ? (
            <>
              <Box>
                <Typography variant="subtitle1" sx={{mb: 1}}>
                  Insurance provider
                </Typography>
                <Typography variant="subtitle2">{insurance.name}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" sx={{mb: 1}}>
                  Membership number
                </Typography>
                <Typography variant="subtitle2">{membership_no}</Typography>
              </Box>
            </>
          ) : (
            <Box>
              <Typography variant="subtitle1" sx={{mb: 1}}>
                Payment mode
              </Typography>
              <Typography variant="subtitle2">
                {payment_mode || 'Card'}
              </Typography>
            </Box>
          )}
        </Stack>
      </Box>
    </Grid>
  );
};

export default FinancialDetails;
