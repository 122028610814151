import {
  generateInitialValues,
  generateQuestionnairePayload,
  generateValidationSchema,
  paginateQuestionnaires,
} from '../utils';
import {FormikValues, useFormik} from 'formik';
import {
  Question,
  Questionnaires,
  QuestionnaireType,
} from '~/services/api/MedicalHistory/Questionnaire/types';
import LifeStyleQuestionnaireComponent from './component';

interface LifeStyleQuestionnaireFormProps {
  itemsPerPage: number;
  currentPage: number;
  questionnaire: Question[];
  handlePageChange: (page: number) => void;
  submitPatientQuestionnaire: (payload: Questionnaires[]) => void;
  handleBack: () => void;
  disableSubmit: boolean;
  isCreatingPatientQuestionnaire: boolean;
  questionnaireType: QuestionnaireType;
}

const LifeStyleQuestionnaireForm = (props: LifeStyleQuestionnaireFormProps) => {
  const {
    currentPage,
    itemsPerPage,
    questionnaire,
    handlePageChange,
    submitPatientQuestionnaire,
    disableSubmit,
    handleBack,
    questionnaireType,
    isCreatingPatientQuestionnaire,
  } = props;

  const {questionsToDisplay, startIndex, totalPages} = paginateQuestionnaires(
    questionnaire,
    currentPage,
    itemsPerPage
  );

  const initialValues = generateInitialValues(questionnaire);
  const validationSchema = generateValidationSchema(questionsToDisplay);

  const handleSubmitQuestionnaire = (values: FormikValues) => {
    const payload = generateQuestionnairePayload(values);
    submitPatientQuestionnaire(payload);
  };

  const handleFormSubmit = async () => {
    const errors = await formik.validateForm();
    const isFormValid = Object.keys(errors).length === 0;
    const isLastPage = currentPage === totalPages;

    if (isFormValid) {
      if (isLastPage) {
        formik.handleSubmit();
      } else {
        handlePageChange(currentPage + 1);
      }
    }
  };

  const formik = useFormik<FormikValues>({
    initialValues,
    validationSchema,
    onSubmit: values => {
      handleSubmitQuestionnaire(values);
    },
  });

  return (
    <LifeStyleQuestionnaireComponent
      currentPage={currentPage}
      disableSubmit={disableSubmit || isCreatingPatientQuestionnaire}
      formik={formik}
      handleBack={handleBack}
      handleFormSubmit={handleFormSubmit}
      handlePageChange={handlePageChange}
      questionsToDisplay={questionsToDisplay}
      startIndex={startIndex}
      totalPages={totalPages}
      questionnaireType={questionnaireType}
    />
  );
};

export default LifeStyleQuestionnaireForm;
