import {ToastType} from '$components/Toast/constant';
import {API_DOMAIN} from '$constants/env';
import {
  networkErrorToast,
  technicalGlitchToast,
} from '$constants/toastmessages';
import toast from '$utils/toast';
import {AxiosError} from 'axios';

export function createAcceptHeaderValue(version: number): string {
  return `application/${API_DOMAIN}; version=${version}`;
}

export function handleErrorResponse<T extends {message: string}>(
  error: unknown,
  context: string,
  callback?: (data: T) => void
) {
  if (error instanceof AxiosError) {
    const axiosError = error as AxiosError<T>;
    const response = axiosError.response;

    if (response) {
      if (response.status === 500) {
        return technicalGlitchToast();
      }

      if (response.data) {
        const data = response.data;

        toast(ToastType.Error, `${context} error`, data.message);
        if (callback) callback(data);

        return;
      }
    }

    if (axiosError.message === 'Network Error') {
      return networkErrorToast();
    }

    return technicalGlitchToast();
  } else {
    technicalGlitchToast();
  }
}
