import HTMLReactParser from 'html-react-parser';

interface RenderSVGProps {
  svg: string;
}

function RenderSVG(props: RenderSVGProps) {
  const {svg} = props;

  return HTMLReactParser(svg);
}

export default RenderSVG;
