import theme from '$modules/booking/theme';
import {SxProps} from '@mui/material';

const styles: Record<string, SxProps> = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: '0.5rem',
  },
  input: {
    fontSize: '1rem',
    color: theme.palette.text.primary,
    fontWeight: 400,
    letterSpacing: '-0.75px',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
    },

    '& fieldset': {
      borderColor: theme.palette.grey[200],
    },

    '& input': {
      padding: 0,
    },

    '&.Mui-error,.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
        borderColor: theme.palette.error.main,
      },
    },

    '&.Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },
    },

    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
      },
    },

    '& input::placeholder': {
      color: theme.palette.text.primary,
    },
  },
  iconButton: {
    color: theme.palette.text.primary,
  },
};

export default styles;
