export enum PatientDetailTab {
  Overview = 'overview',
  ClinicalData = 'clinical-data',
  Timeline = 'timeline',
  Notes = 'notes',
}

export const PATIENT_DETAILS_TAB_LIST: {
  label: string;
  value: PatientDetailTab;
}[] = [
  {
    label: 'Overview',
    value: PatientDetailTab.Overview,
  },
  {
    label: 'Clinical Data',
    value: PatientDetailTab.ClinicalData,
  },
  {
    label: 'Timeline',
    value: PatientDetailTab.Timeline,
  },
  {
    label: 'Notes',
    value: PatientDetailTab.Notes,
  },
];
