import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import FetchError from '$components/FetchError';
import RescheduleBookingComponent from './component';
import {
  useAppointmentDetails,
  useAppointmentList,
  useBookingSlots,
  useRescheduleBooking,
} from '../../hooks';
import {Slot} from '../../type';
import '../../styles.scss';
import {useAppSelector} from '$redux/hooks';
import {isNumber} from '$utils/number';
import RescheduleAppointmentSkeleton from './RescheduleAppointmentSkeleton';

function RescheduleBooking() {
  const params = useParams();
  const appointmentId = Number(params.id);
  const hospitalId = useAppSelector(state => state.auth.user?.hospitalId)!;

  const [selectedSlot, setSelectedSlot] = useState<Slot | undefined>();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [preferredTime, setPreferredTime] = useState('');

  const {appointmentDetails, isLoading} = useAppointmentDetails({
    appointmentId,
  });

  const doctorId = appointmentDetails?.doctor.id;

  const {appointments} = useAppointmentList({
    fromDate: selectedDate,
    toDate: selectedDate,
    doctorId: isNumber(doctorId) ? [doctorId] : undefined,
  });

  const {
    slots,
    slotsStartTime,
    slotsEndTime,
    isLoading: isSlotsLoading,
  } = useBookingSlots({
    date: selectedDate,
    preferredTime: preferredTime,
    doctorId: appointmentDetails?.doctor.id,
    hospitalId: hospitalId,
    subCareTypeId: appointmentDetails?.subCareType.id,
    appointmentTypeId: appointmentDetails?.appointmentType.id,
  });

  const {isPending, disableSubmit, sendRescheduleBookingRequest} =
    useRescheduleBooking();

  const handleRescheduleBooking = () => {
    if (!selectedSlot) return;

    sendRescheduleBookingRequest({
      appointmentId: appointmentId,
      startTime: selectedSlot.startTime,
      endTime: selectedSlot.endTime,
      preferredTime: preferredTime,
      date: selectedDate || new Date(),
    });
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedSlot(undefined);
      setSelectedDate(date);
    }
  };

  useEffect(() => {
    const appointment = appointmentDetails?.appointment;
    if (appointment) {
      const {date, startTime, endTime} = appointment;
      setSelectedDate(date);
      setPreferredTime(appointment.perferredTime);

      if (startTime && endTime) {
        setSelectedSlot({
          startTime: startTime,
          endTime: endTime,
        });
      }
    }
  }, [appointmentDetails]);

  if (isLoading) return <RescheduleAppointmentSkeleton />;
  if (!appointmentDetails) return <FetchError />;

  const hasSelectedDifferentSlot =
    appointmentDetails.appointment.startTime !== selectedSlot?.startTime;

  return (
    <RescheduleBookingComponent
      isSlotsLoading={isSlotsLoading}
      preferredTime={preferredTime}
      setPreferredTime={setPreferredTime}
      slotsStartTime={slotsStartTime}
      slotsEndTime={slotsEndTime}
      appointmentDetails={appointmentDetails}
      appointments={appointments}
      slots={slots}
      selectedSlot={selectedSlot}
      setSelectedSlot={setSelectedSlot}
      selectedDate={selectedDate}
      onDateChange={handleDateChange}
      isPending={isPending}
      disableSubmit={
        disableSubmit || !selectedDate || !hasSelectedDifferentSlot
      }
      onRescheduleBookingClick={handleRescheduleBooking}
    />
  );
}

export default RescheduleBooking;
