import {ApiRoute} from '$constants/api';
import axiosInstance from '..';

interface GetNotesListResponseData {
  success: boolean;
  data: {
    id: number;
    created_by: {
      id: number;
      profile_url: string | null;
      full_name: string;
    };
    appointment_id: number;
    patient_id: number;
    title: string;
    description: string;
    created_at: string;
    document_url: string;
  }[];
  message: string;
}

interface GetNotesListPramas {
  patientId: number;
}

export async function getNotesList(params: GetNotesListPramas) {
  const {patientId} = params;

  const response = await axiosInstance.get<GetNotesListResponseData>(
    ApiRoute.Notes,
    {params: {patient_id: patientId}}
  );

  return response.data;
}

interface PostCreateNoteResponseData {
  message: string;
}

export interface PostCreateNoteVariables {
  title: string;
  description: string;
  patientId: number;
  document?: File;
}

export const createNoteVariablesToRequestBody = (
  variables: PostCreateNoteVariables
) => {
  return {
    note: {
      title: variables.title,
      description: variables.description,
      patient_id: variables.patientId,
      document: variables.document,
    },
  };
};

export const appendObjectToFormData = (
  formData: FormData,
  obj: unknown,
  parentKey = ''
) => {
  // Check if obj is an object and not null
  if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj).forEach(key => {
      const value = (obj as Record<string, unknown>)[key];
      // Construct the key for formData
      const fullKey = parentKey ? `${parentKey}[${key}]` : key;

      // return if value is undefined
      if (value === undefined) {
        return;
      }

      // check if value is itslef an nested object
      if (
        value instanceof Object &&
        !(value instanceof File) &&
        !(value instanceof Date) &&
        !Array.isArray(value)
      ) {
        // Recursively append object properties
        appendObjectToFormData(formData, value, fullKey);
      } else {
        // Handle File or Blob types directly
        if (value instanceof File || value instanceof Blob) {
          formData.append(fullKey, value);
        } else {
          // Convert other types to string and append
          const stringValue = value === null ? '' : String(value);
          formData.append(fullKey, stringValue);
        }
      }
    });
  }
};

export async function postCreateNote(variables: PostCreateNoteVariables) {
  const body = createNoteVariablesToRequestBody(variables);
  const formData = new FormData();
  appendObjectToFormData(formData, body);

  const response = await axiosInstance.post<PostCreateNoteResponseData>(
    ApiRoute.Notes,
    formData
  );

  return response.data;
}

interface PutModifyNoteResponseData {
  message: string;
}

export interface PutModifyNoteVariables {
  noteId?: number;
  title: string;
  description: string;
  document?: File;
}

export const updateNoteVariablesToRequestBody = (
  variables: PutModifyNoteVariables
) => {
  return {
    note: {
      title: variables.title,
      description: variables.description,
      document: variables.document,
    },
  };
};

export async function putModifyNote(variables: PutModifyNoteVariables) {
  const body = updateNoteVariablesToRequestBody(variables);
  const formData = new FormData();
  appendObjectToFormData(formData, body);

  const response = await axiosInstance.put<PutModifyNoteResponseData>(
    `${ApiRoute.Notes}/${variables.noteId}`,
    formData
  );

  return response.data;
}

export const deleteNote = async (noteId: number) => {
  const response = await axiosInstance.delete(`${ApiRoute.Notes}/${noteId}`);
  return response.data;
};
