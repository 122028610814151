import {
  deleteDocument,
  getDocumentsList,
  postDocument,
} from '$api/patientList/documents';
import {ToastType} from '$components/Toast/constant';
import toast from '$utils/toast';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

export interface DeleteDocumentVariables {
  documentId: number;
  onSuccess?: () => void;
}

export const useDocumentsHook = (patientId: string) => {
  const queryClient = useQueryClient();

  const {isPending, isError, error, data} = useQuery({
    queryKey: ['documents', patientId],
    queryFn: () => getDocumentsList({patientId}),
  });

  const {mutate: deleteDocumentMutation, isPending: isDocumentDeleting} =
    useMutation({
      mutationKey: ['documents', patientId],
      mutationFn: ({documentId}: DeleteDocumentVariables) =>
        deleteDocument({documentId}),
      onSuccess: (data, {onSuccess}) => {
        queryClient.invalidateQueries({queryKey: ['documents', patientId]});
        if (onSuccess) {
          onSuccess();
        }
        return toast(ToastType.Success, 'Delete Document', data.message);
      },
      onError: error => {
        return toast(ToastType.Error, 'Delete Document', error.message);
      },
    });

  const {mutate: uploadDocument} = useMutation({
    mutationKey: ['documents', patientId],
    mutationFn: ({
      patientId,
      document,
      name,
      performedBy,
    }: {
      patientId: string;
      document: File;
      name: string;
      performedBy: string;
    }) =>
      postDocument({
        patientId,
        document,
        name,
        performedBy,
      }),
    onSuccess: data => {
      queryClient.invalidateQueries({queryKey: ['documents', patientId]});
      return toast(ToastType.Success, 'Upload Document', data.message);
    },
    onError: error => {
      return toast(ToastType.Error, 'Upload Document', error.message);
    },
  });

  const uploadDocumentRequest = (
    patientId: string,
    document: File,
    name: string,
    performedBy: string
  ) => {
    uploadDocument({patientId, document, name, performedBy});
  };

  const deleteDocumentRequest = ({
    documentId,
    onSuccess,
  }: DeleteDocumentVariables) => {
    deleteDocumentMutation({
      documentId,
      onSuccess,
    });
  };

  return {
    isPending,
    isError,
    error,
    data,
    deleteDocumentRequest,
    isDocumentDeleting,
    uploadDocumentRequest,
  };
};
