import {useState} from 'react';
import AllergiesComponent from './component';

function Allergies() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modifyAllergyId, setModifyAllergyId] = useState<number | null>(null);
  const [deleteAllergyId, setDeleteAllergyId] = useState<number | null>(null);

  return (
    <AllergiesComponent
      modalOpen={modalOpen}
      modifyAllergyId={modifyAllergyId}
      setModalOpen={setModalOpen}
      setModifyAllergyId={setModifyAllergyId}
      deleteAllergyId={deleteAllergyId}
      setDeleteAllergyId={setDeleteAllergyId}
    />
  );
}

export default Allergies;
