import {useQuery} from '@tanstack/react-query';
import {getPatientClinicalDetails} from '$api/patientList/patientDetails';

export const usePatientClinicalDetailsHook = (patientId: number) => {
  const {isPending, isError, error, data} = useQuery({
    queryKey: ['patient-clinical-details'],
    queryFn: () => getPatientClinicalDetails(patientId),
  });

  return {
    isPending,
    isError,
    error,
    data,
  };
};
