import {useContext, useEffect, useState} from 'react';
import {logout} from '$utils/helper';
import SidebarDrawerComponent from './component';
import CalendarRefContext from '../../context/CalendarRefContext';
import './styles.scss';

function SidebarDrawer() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const calendarRef = useContext(CalendarRefContext);

  const handleDrawerToggle = () => {
    setSidebarOpen(value => !value);
  };

  const toggleLogoutModal = () => {
    setLogoutModalOpen(value => !value);
  };

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const fullCalendar = calendarRef.current;
      if (!fullCalendar) return;

      fullCalendar.requestResize();
    }, 400);

    return () => timeoutId && clearTimeout(timeoutId);
  }, [sidebarOpen, calendarRef]);

  return (
    <SidebarDrawerComponent
      open={sidebarOpen}
      logoutModalOpen={logoutModalOpen}
      toggleLogoutModal={toggleLogoutModal}
      onLogout={handleLogout}
      onDrawerToggle={handleDrawerToggle}
    />
  );
}

export default SidebarDrawer;
