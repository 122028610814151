import {GetDocumentsListResponseData} from '$api/patientList/documents';
import {DownloadIcon, TrashIcon} from '$assets/svgs';
import {IconButton} from '@mui/material';
import {downloadAttachment} from '../../Notes/utils';
import {dateToDDMMYYYY} from '$utils/date';
import DeleteConfirmDialog from '$components/DeleteConfirmDialog';
import {DeleteDocumentVariables} from '../../hooks/useDocumentsHook';

interface DocumentListProps {
  documentsList: GetDocumentsListResponseData;
  deleteDocumentRequest: ({
    documentId,
    onSuccess,
  }: DeleteDocumentVariables) => void;
  isDocumentDeleting: boolean;
  showDeleteModal: boolean;
  setShowDeleteModal: (show: boolean) => void;
}

function DocumentList(props: DocumentListProps) {
  const {
    deleteDocumentRequest,
    isDocumentDeleting,
    documentsList,
    setShowDeleteModal,
    showDeleteModal,
  } = props;
  return (
    <div className=" flex flex-col gap-2 overflow-y-auto">
      {documentsList.data.length > 0 ? (
        documentsList.data.map(document => (
          <div
            key={document.id}
            className="rounded-2xl border-2 border-[#F2F4F7] border-solid flex gap-2 justify-between documents-center px-2 py-1"
          >
            <div className="flex flex-col">
              <h3 className="text-[#101828] font-semibold">{document.name}</h3>
              <p className="text-[#475467] text-xs">
                {dateToDDMMYYYY(document.created_at)}
              </p>
            </div>
            <div className="flex justify-center items-center">
              {document.document_url && (
                <IconButton
                  aria-label="download"
                  size="small"
                  onClick={() => {
                    downloadAttachment(document.document_url, 'attachment');
                  }}
                >
                  <DownloadIcon className="primary" />
                </IconButton>
              )}
              <IconButton
                disabled={isDocumentDeleting}
                aria-label="delete"
                size="small"
                onClick={() => setShowDeleteModal(true)}
              >
                <TrashIcon className="primary" />
              </IconButton>
            </div>
            {showDeleteModal && (
              <DeleteConfirmDialog
                title="Document"
                isDisabled={isDocumentDeleting}
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onDelete={() =>
                  deleteDocumentRequest({
                    documentId: document.id,
                    onSuccess: () => setShowDeleteModal(false),
                  })
                }
              />
            )}
          </div>
        ))
      ) : (
        <div className="text-center py-4">No data found</div>
      )}
    </div>
  );
}

export default DocumentList;
