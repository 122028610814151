const NotFound = () => {
  return (
    <div className="flex flex-col w-full h-full justify-center items-center font-bold text-2xl">
      <div className="flex justify-center items-center flex-col gap-4">
        <div className="text-4xl">404</div>
        <div className="text-2xl">Oops! Page Not Found</div>
      </div>
    </div>
  );
};

export default NotFound;
