import {ApiRoute} from '$constants/api';
import {AllergySeverity} from '$modules/frontdesk/PatientList/PatientDetails/ClinicalData/Allergies/constant';
import {AllergyFormData} from '$modules/frontdesk/PatientList/PatientDetails/ClinicalData/Allergies/types';
import axiosInstance from '..';
import {QuestionnaireType} from './questionnaire';

interface AxiosRequestConfig {
  params?: Record<string, string | number | boolean>;
  headers?: Record<string, string>;
  responseType?: 'blob';
}
const downloadPDF = async (
  url: string,
  filename: string,
  config: AxiosRequestConfig = {}
) => {
  const response = await axiosInstance.get(url, {
    ...config,
    headers: {
      ...config.headers,
      Format: 'pdf',
    },
    responseType: 'blob',
  });

  const blob = new Blob([response.data], {type: 'application/pdf'});
  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(downloadUrl);
};

export const downloadMedicationListPDF = (patientId: number) =>
  downloadPDF(ApiRoute.Medications, 'medication_list_report.pdf', {
    params: {patient_id: patientId},
  });

export const downloadAllergyListPDF = (patientId: number) =>
  downloadPDF(
    `${ApiRoute.Patients}/${patientId}${ApiRoute.AllergyList}`,
    'allergy_list_report.pdf'
  );

export const downloadMedicalHistoryPDF = (
  patientId: number,
  questionnaireType: string
) =>
  downloadPDF(
    ApiRoute.PatientQuestionnaire,
    `patient_${questionnaireType}_report.pdf`,
    {
      params: {patient_id: patientId, type: questionnaireType},
    }
  );

interface GetMedicationListResponseData {
  success: boolean;
  data: [
    {
      id: number;
      created_by: {
        id: number;
        profile_url: string | null;
        full_name: string;
      };
      appointment_id: number;
      patient_id: number;
      name: string;
      dosage: string;
      frequency: string;
      instruction: string;
      created_at: string;
    },
  ];
  message: string;
}

interface GetMedicationListParams {
  patientId: number;
}

export async function getMedicationList(params: GetMedicationListParams) {
  const response = await axiosInstance.get<GetMedicationListResponseData>(
    ApiRoute.Medications,
    {params: {patient_id: params.patientId}}
  );

  return response.data;
}

interface PostMedicationResponseData {
  message: string;
}

export interface PostMedicationVariables {
  name: string;
  dosage: string;
  frequency: string;
  instruction?: string;
  patientId: number;
}

export async function postCreateMedication(variables: PostMedicationVariables) {
  const {name, dosage, frequency, instruction, patientId} = variables;

  const response = await axiosInstance.post<PostMedicationResponseData>(
    ApiRoute.Medications,
    {
      medication: {
        name: name,
        dosage: dosage,
        frequency: frequency,
        instruction: instruction,
        patient_id: patientId,
      },
    }
  );

  return response.data;
}

interface PutMedicationResponseData {
  message: string;
}

export interface PutMedicationVariables {
  id: number;
  name: string;
  dosage: string;
  frequency: string;
  instruction?: string;
  patientId: number;
}

export async function putModifyMedication(variables: PutMedicationVariables) {
  const {id, name, dosage, frequency, instruction, patientId} = variables;

  const response = await axiosInstance.put<PutMedicationResponseData>(
    `${ApiRoute.Medications}/${id}`,
    {
      medication: {
        name: name,
        dosage: dosage,
        frequency: frequency,
        instruction: instruction,
        patient_id: patientId,
      },
    }
  );

  return response.data;
}

export const deleteMedication = async (id: number) => {
  const response = await axiosInstance.delete(`${ApiRoute.Medications}/${id}`);
  return response.data;
};

interface GetAllergyListResponseData {
  success: boolean;
  data: {
    id: number;
    name: string;
    level: AllergySeverity;
  }[];
  message: string;
}

interface GetAllergyListParams {
  patientId: number;
}

export async function getAllergyList(params: GetAllergyListParams) {
  const {patientId} = params;

  const response = await axiosInstance.get<GetAllergyListResponseData>(
    `${ApiRoute.Patients}/${patientId}${ApiRoute.AllergyList}`
  );

  return response.data;
}

interface PutAllergyResponseData {
  message: string;
}

export interface PutAllergyVariables {
  patientId: number;
  allergies: AllergyFormData[];
  hospitalId?: number | null;
}

export async function putModifyAllergy(variables: PutAllergyVariables) {
  const {allergies, patientId, hospitalId} = variables;

  const attributes = allergies.map(all => ({
    id: all.id || null,
    name: all.name,
    level: all.level,
    hospital_id: hospitalId,
    _destroy: all.destroy,
  }));

  const response = await axiosInstance.put<PutAllergyResponseData>(
    `${ApiRoute.Patients}/${patientId}${ApiRoute.UpdateAlergy}`,
    {
      patient: {
        allergies_attributes: attributes,
      },
    }
  );

  return response.data;
}

export interface MedicalHistoryVariables {
  patientId: number;
}

export interface MedicalHistory {
  key: QuestionnaireType;
  title: string;
  completedOn: string;
  totalCount: number;
  completedCount: number;
}

export interface MedicalHistoryResponseData {
  success: boolean;
  data: {
    medical_history: {
      key: QuestionnaireType;
      title: string;
      completed_on: string;
      total_count: number;
      completed_count: number;
    }[];
    allergy_count: number;
    medication_count: number;
  };
  message: string;
}

export async function getMedicalHistory(
  params: MedicalHistoryVariables
): Promise<{
  success: boolean;
  data: {
    medicalHistory: MedicalHistory[];
    allergyCount: number;
    medicationCount: number;
  };
  message: string;
}> {
  const {patientId} = params;
  const response = await axiosInstance.get<MedicalHistoryResponseData>(
    ApiRoute.MedicalHistory,
    {
      params: {
        patient_id: patientId,
      },
    }
  );

  return {
    success: response.data.success,
    data: {
      medicalHistory: response.data.data.medical_history.map(info => ({
        key: info.key,
        title: info.title,
        completedOn: info.completed_on,
        totalCount: info.total_count,
        completedCount: info.completed_count,
      })),
      allergyCount: response.data.data.allergy_count,
      medicationCount: response.data.data.medication_count,
    },
    message: response.data.message,
  };
}
