import moment from 'moment-timezone';

export function dateToTimeShort(date: Date) {
  const momentObj = momentDate(date);

  const hours = momentObj.format('HH');
  const minutes = momentObj.format('mm');

  const timeString = `${hours}:${minutes}`;

  return timeString;
}

export function dateStringShort(date: Date) {
  return date.toISOString().split('T')[0];
}

export function getTimeOfDay() {
  const date = new Date();
  const hours = date.getHours();

  if (hours < 12) {
    return 'Morning';
  } else if (hours < 17) {
    return 'Afternoon';
  } else {
    return 'Evening';
  }
}

export function dateToDDMMYYYY(date?: Date | string) {
  if (!date) return '-';

  if (typeof date === 'string') {
    date = new Date(date);
  }

  const dateObj = momentDate(date);
  return dateObj.format('DD/MM/YYYY');
}

export function setMomentTimezone(timezone: string) {
  moment.tz.setDefault(timezone);
}

export function momentDate(date: Date) {
  return moment(date);
}

export function timeFromNow(date: Date) {
  return momentDate(date).fromNow();
}

export function formatTimeAMPM(date: Date) {
  return momentDate(date).format('HH:mm A');
}

export default moment;
