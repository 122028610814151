import {SetStateAction} from 'react';
import DialogComponent from '$components/DialogComponent';
import CancelAppointmentComponent from './component';

interface CancelAppointmentContainerProps {
  cancelAppointmentModal: boolean;
  setCancelAppointmentModal: React.Dispatch<SetStateAction<boolean>>;
}

const CancelAppointmentContainer: React.FC<CancelAppointmentContainerProps> = ({
  cancelAppointmentModal,
  setCancelAppointmentModal,
}) => {
  return (
    <DialogComponent
      open={cancelAppointmentModal}
      handleClose={setCancelAppointmentModal}
      dialogBody={
        <CancelAppointmentComponent
          cancelAppointmentModal={cancelAppointmentModal}
          setCancelAppointmentModal={setCancelAppointmentModal}
        />
      }
    />
  );
};

export default CancelAppointmentContainer;
