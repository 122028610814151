import moment from '$utils/date';
import {Notification} from './types';

export function segregateNotifications(notifications: Notification[]): {
  today: Notification[];
  thisWeek: Notification[];
  thisMonth: Notification[];
  past: Notification[];
} {
  const today = moment().startOf('day');

  const thisWeek = moment().startOf('week');
  const thisMonth = moment().startOf('month');

  return notifications.reduce(
    (acc, notification) => {
      const createdAt = moment(notification.createdAt);

      if (createdAt.isSameOrAfter(today)) {
        acc.today.push(notification);
      } else if (createdAt.isSameOrAfter(thisWeek)) {
        acc.thisWeek.push(notification);
      } else if (createdAt.isSameOrAfter(thisMonth)) {
        acc.thisMonth.push(notification);
      } else {
        acc.past.push(notification);
      }

      return acc;
    },
    {
      today: [] as Notification[],
      thisWeek: [] as Notification[],
      thisMonth: [] as Notification[],
      past: [] as Notification[],
    }
  );
}
