export const NEW_APPOINTMENT_TITLE = 'Select a date and time';
export const SURGERY_APPOINTMENT_TITLE = 'Select a date';

interface ITitleMap {
  [key: string]: string;
  new: string;
  surgery: string;
}
export const TITLE_MAP: ITitleMap = {
  new: NEW_APPOINTMENT_TITLE,
  surgery: SURGERY_APPOINTMENT_TITLE,
};
