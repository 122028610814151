import {ValidationMessage} from '$constants/messages';
import {POSTCODE_VALIDATION_REGEX} from '$constants/regex';
import {date, InferType, number, object, string} from 'yup';

export const editPatientDetailsFormSchema = object({
  patient: object({
    dob: date()
      .max(new Date(), ValidationMessage.DateOfBirthFutureDate)
      .required(ValidationMessage.DateOfBirthRequired),
    email: string()
      .trim()
      .email(ValidationMessage.EmailInvalid)
      .max(350, ValidationMessage.EmailExceedCharacterLimit)
      .required(ValidationMessage.EmailRequired),
    gender: string().required(ValidationMessage.GenderRequired),
    phoneNumber: number()
      .typeError(ValidationMessage.PhoneNumberInvalid)
      .positive(ValidationMessage.PhoneNumberInvalid)
      .integer(ValidationMessage.PhoneNumberInvalid)
      .min(10_000_00, ValidationMessage.PhoneNumberInvalid)
      .max(999_999_999_999_999, ValidationMessage.PhoneNumberInvalid)
      .required(ValidationMessage.PhoneNumberRequired),
    postCode: string()
      .trim()
      .max(7, ValidationMessage.PostcodeExceedCharacterLimit)
      .matches(POSTCODE_VALIDATION_REGEX, ValidationMessage.PostcodeInvalid)
      .required(ValidationMessage.PostcodeRequired),
    line1: string()
      .trim()
      .max(150, ValidationMessage.AddressLine1ExceedCharacterLimit)
      .required(ValidationMessage.AddressLine1Required),
    line2: string()
      .trim()
      .max(150, ValidationMessage.AddressLine1ExceedCharacterLimit)
      .optional(),
    city: string().required(ValidationMessage.CityRequired),
    country: string()
      .trim()
      .max(60, ValidationMessage.CountryExceedCharacterLimit)
      .required(ValidationMessage.CountryRequired),
  }).required(),
});

export type EditPatientDetailsFormData = {
  patient: {
    dob: Date | null;
    email: string;
    gender: string;
    phoneNumber: number;
    country: string;
    postCode: string;
    line1: string;
    line2: string;
    city: string;
  };
};

export type EditPatientDetailsValidatedFormData = InferType<
  typeof editPatientDetailsFormSchema
>;
