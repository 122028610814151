import {SxProps} from '@mui/material';

import {Color} from '$constants/style';
import theme from '$modules/booking/theme';

import {BookingScreenColors} from '../../constant';

const stepperIconWrap: SxProps = {
  alignItems: 'center',
  justifyContent: 'center',
  width: '2rem',
  height: '2rem',
  borderRadius: '0.5rem',
  background: Color.GradientBackground,

  '& svg': {
    width: '1.5rem',
    height: '1.5rem',
    color: theme.palette.common.white,
  },
};

const styles: Record<string, SxProps> = {
  stepperItem: {
    display: 'flex',
    position: 'relative',
    flex: 1,
    gridGap: theme.spacing(1),
    maxWidth: '184px',

    '&.stepper-item': {
      paddingBottom: '1rem',

      '&:after': {
        position: 'absolute',
        content: '""',
        bottom: 0,
        width: '100%',
        height: '4px',
        background: BookingScreenColors.StepperBottomBarGradient,
        borderRadius: '0.5rem',
      },

      '& .stepper-icon, .stepper-title': {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'flex',
        },
      },
      '&.inactive-step': {
        '& .stepper-icon, .stepper-title': {
          display: 'none',
        },

        '&:after': {
          background: theme.palette.common.white,
        },
      },
    },
  },

  stepperWrap: {
    display: 'flex',
    gridGap: theme.spacing(4),
  },

  stepperIconWrap: stepperIconWrap,

  stepperIconWrapWithFlex: {
    display: 'flex',
    ...stepperIconWrap,
  },

  stepTitle: {
    color: theme.palette.primary.main,
  },

  mobileActiveStep: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
};

export default styles;
