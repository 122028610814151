import {SxProps, styled} from '@mui/material/styles';
// import CheckedIcon from '../../../public/checked-icon.svg';

const styles: Record<string, SxProps> = {
  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  labelWrapper: {
    cursor: 'default',
    marginTop: 0.5,
  },
};

export const EoIcon = styled('span')(() => ({
  borderRadius: 5,
  border: '1px solid #7625DC',
  width: 16,
  height: 16,
  backgroundColor: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  'input:disabled ~ &': {
    border: '1px solid #475467',
    opacity: '0.6',
  },
}));

export const EoCheckedIcon = styled(EoIcon)({
  backgroundColor: '#7625DC',
  '&::before': {
    display: 'block',
    width: 15,
    height: 15,
    backgroundImage: 'url(/checked-icon.svg)',
    content: '""',
  },
});

export const ErrorEoIcon = styled(EoIcon)({
  borderColor: '#D92D20',
});

export default styles;
