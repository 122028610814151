import {Button} from '@mui/material';
import DownloadIcon from '$assets/svgs/download.svg?react';
import SyringIcon from '$assets/svgs/syringe.svg?react';
import {ALLERGY_SKELETON_ITEM_COUNT} from '$modules/frontdesk/constant';

function AllergySkeleton() {
  const skeletonItems = Array.from({length: ALLERGY_SKELETON_ITEM_COUNT}).map(
    (_, index) => (
      <div
        key={index}
        className="flex justify-between items-center p-4 bg-white border border-gray-200 rounded-lg animate-pulse"
      >
        <div className="flex gap-4 items-center">
          <div className="flex flex-col gap-3">
            <div className="h-5 w-32 bg-gray-200 rounded-lg"></div>
            <div className="h-5 bg-slate-200 rounded-lg w-1/3" />
          </div>
        </div>
      </div>
    )
  );

  return (
    <div className="clinical-data__allergies overflow-auto col-span-2 row-span-2">
      <div className="header pb-2">
        <div className="card-title-container">
          <SyringIcon />
          <span className="card-title">Allergies</span>
        </div>
        <Button
          startIcon={<DownloadIcon />}
          className="download-full-list-button"
          disabled
        >
          Download full list
        </Button>
      </div>

      <div className="flex-1 overflow-auto space-y-4">{skeletonItems}</div>
    </div>
  );
}

export default AllergySkeleton;
