import VialIcon from '$assets/svgs/vial.svg?react';
import {AppointmentInfo} from '$modules/frontdesk/type';
import {dateToDDMMYYYY, momentDate} from '$utils/date';

interface ConsultationCardProps {
  consultation: AppointmentInfo;
}

function ConsultationCard(props: ConsultationCardProps) {
  const {consultation} = props;

  const appointment = consultation.appointment;

  const dateString = dateToDDMMYYYY(appointment.date);
  const timeString = momentDate(appointment.startTime).format('HH:mm');

  return (
    <div className="section-item bg-white rounded-lg p-4 flex gap-4 mb-4">
      <div>
        <span className="section-icon-container p-1 flex justify-center items-center rounded-full text-white">
          <VialIcon />
        </span>
      </div>
      <div className="flex-1 pr-6 mt-0.5">
        <h6 className="section-title text-lg font-bold mb-3">
          {appointment.type}
        </h6>
        <p>
          Performed by:{' '}
          <span className="font-bold">{appointment.doctorName}</span>
        </p>
      </div>
      <div className="pr-8 mt-0.5 flex flex-col">
        <span className="text-base font-bold mb-1">{dateString}</span>
        <span className="text-base font-normal text-right">{timeString}</span>
      </div>
    </div>
  );
}

export default ConsultationCard;
