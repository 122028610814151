import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '$assets/svgs/check.svg?react';
import ToastXIcon from '$assets/svgs/toast-x.svg?react';
import theme from '$modules/booking/theme';
import styles from './styles';
import {PasswordValidationStage} from './types';
import {ListItemText} from '@mui/material';

interface PasswordValidationStepperProps {
  passwordErrors: Set<string>;
  touched?: boolean;
  error?: string;
  validated?: boolean;
}

function PasswordValidationStepper(props: PasswordValidationStepperProps) {
  const {passwordErrors, touched, error, validated} = props;

  const stages = Object.values(PasswordValidationStage);
  const items: React.ReactNode[] = stages.map(stage => {
    const hasError = passwordErrors.has(stage);
    const color = validated
      ? theme.palette.success.main
      : error && touched
        ? hasError
          ? theme.palette.error.main
          : theme.palette.success.main
        : theme.palette.primary.main;

    const icon = validated ? (
      <CheckIcon className="h-5 w-5" />
    ) : error && touched ? (
      hasError ? (
        <ToastXIcon className="h-6 w-6" />
      ) : (
        <CheckIcon className="h-5 w-5" />
      )
    ) : null;

    return (
      <ListItem
        key={stage}
        sx={
          !validated && !(error && touched)
            ? styles.listItem
            : styles.iconListItem
        }
      >
        {icon && (
          <ListItemIcon sx={{minWidth: '30px', color: color}}>
            {icon}
          </ListItemIcon>
        )}
        <ListItemText sx={{...styles.itemText, color: color}}>
          {stage}
        </ListItemText>
      </ListItem>
    );
  });

  return <List sx={styles.list}>{items}</List>;
}

export default PasswordValidationStepper;
