import {
  PostMedicationVariables,
  PutAllergyVariables,
  PutMedicationVariables,
  deleteMedication,
  getAllergyList,
  getMedicalHistory,
  getMedicationList,
  postCreateMedication,
  putModifyAllergy,
  putModifyMedication,
} from '$api/patientList/clinicalDetails';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {Medication} from './types';
import {useState} from 'react';
import toast from '$utils/toast';
import {ToastType} from '$components/Toast/constant';
import {handleErrorResponse} from '$utils/axios';
import {Allergy} from './Allergies/types';
import {
  putPatientClinicalDetail,
  PutPatientClinicalDetailsPayload,
} from '$api/patientList/patientDetails';
import {postInvitePatient, PostInvitePayloadProps} from '$api/frontdesk';
import {
  downloadMedicationListPDF,
  downloadAllergyListPDF,
  downloadMedicalHistoryPDF,
} from '$api/patientList/clinicalDetails';

export function useMedicationList(patientId: number) {
  const {data, isLoading} = useQuery({
    queryKey: ['patient-details-medication-list', patientId],
    queryFn: () => getMedicationList({patientId}),
  });

  const medicationData = data?.data || [];
  const medicationList: Medication[] = medicationData.map(medication => ({
    id: medication.id,
    createdBy: {
      id: medication.created_by.id,
      profileUrl: medication.created_by.profile_url,
      fullName: medication.created_by.full_name,
    },
    patientId: medication.patient_id,
    name: medication.name,
    dosage: medication.dosage,
    frequency: medication.frequency,
    instruction: medication.instruction,
    createdAt: new Date(medication.created_at),
  }));

  const downloadPDFWithToast = async () => {
    await downloadMedicationListPDF(patientId)
      .then(() => {
        return toast(
          ToastType.Success,
          'Medication full list',
          'PDF downloaded successfully'
        );
      })
      .catch(error => {
        const errorMessage =
          error instanceof Error ? error.message : 'An unknown error occurred';
        toast(
          ToastType.Error,
          'Allergy full list',
          `Failed to download PDF: ${errorMessage}`
        );
      });
  };

  return {
    medicationList,
    isLoading,
    downloadMedicationListPDF: downloadPDFWithToast,
  };
}

export function useCreateMedication(onSuccess: () => void) {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const queryClient = useQueryClient();

  const {mutate, isPending} = useMutation({
    mutationKey: ['patient-details-create-medication'],
    mutationFn: postCreateMedication,
    onSuccess: data => {
      toast(ToastType.Success, 'Create medication', data.message);
      queryClient.invalidateQueries({
        queryKey: ['patient-details-medication-list'],
      });
      onSuccess();
    },
    onError: error => handleErrorResponse(error, 'Create medication'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendCreateMedicationRequest = (variables: PostMedicationVariables) => {
    setDisableSubmit(true);
    mutate(variables);
  };

  return {sendCreateMedicationRequest, isPending, disableSubmit};
}

export function useModifyMedication(onSuccess: () => void) {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const queryClient = useQueryClient();

  const {mutate, isPending} = useMutation({
    mutationKey: ['patient-details-modify-medication'],
    mutationFn: putModifyMedication,
    onSuccess: data => {
      toast(ToastType.Success, 'Modify medication', data.message);
      queryClient.invalidateQueries({
        queryKey: ['patient-details-medication-list'],
      });
      onSuccess();
    },
    onError: error => handleErrorResponse(error, 'Modify medication'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendModifyMedicationRequest = (variables: PutMedicationVariables) => {
    setDisableSubmit(true);
    mutate(variables);
  };

  return {sendModifyMedicationRequest, isPending, disableSubmit};
}

export const useDeleteMedication = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {mutate: deleteMedicationRequest, isPending: isDeletingMedication} =
    useMutation({
      mutationKey: ['patient-details-delete-medication'],
      mutationFn: deleteMedication,
      onMutate: () => setDisableSubmit(true),
      onSuccess: data => {
        toast(ToastType.Success, 'Delete medication', data.message);
        queryClient.invalidateQueries({
          queryKey: ['patient-details-medication-list'],
        });
        onSuccess();
      },
      onError: error => handleErrorResponse(error, 'Delete medication'),
      onSettled: () => {
        setDisableSubmit(false);
      },
    });

  return {
    deleteMedicationRequest,
    isDeletingMedication,
    disableSubmit,
  };
};

export function useAllergyList(patientId: number) {
  const {data, isLoading} = useQuery({
    queryKey: ['patient-details-allergy-list', patientId],
    queryFn: () => getAllergyList({patientId}),
  });

  const allergyList = (data?.data || []) as Allergy[];

  const downloadPDFWithToast = async () => {
    await downloadAllergyListPDF(patientId)
      .then(() => {
        return toast(
          ToastType.Success,
          'Allergy full list',
          'PDF downloaded successfully'
        );
      })
      .catch(error => {
        const errorMessage =
          error instanceof Error ? error.message : 'An unknown error occurred';
        toast(
          ToastType.Error,
          'Allergy full list',
          `Failed to download PDF: ${errorMessage}`
        );
      });
  };

  return {allergyList, isLoading, downloadAllergyListPDF: downloadPDFWithToast};
}

export function useModifyAllergy(onSuccess: () => void) {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const queryClient = useQueryClient();

  const {mutate, isPending} = useMutation({
    mutationKey: ['patient-details-modify-allergies'],
    mutationFn: putModifyAllergy,
    onSuccess: data => {
      toast(ToastType.Success, 'Modify allergies', data.message);
      queryClient.invalidateQueries({
        queryKey: ['patient-details-allergy-list'],
      });
      onSuccess();
    },
    onError: error => handleErrorResponse(error, 'Modify allergies'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendModifyAllergyRequest = (variables: PutAllergyVariables) => {
    setDisableSubmit(true);
    mutate(variables);
  };

  return {sendModifyAllergyRequest, isPending, disableSubmit};
}

export function usePutPatientClinicalDetails(onSuccess: () => void) {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const queryClient = useQueryClient();

  const {mutate, isPending} = useMutation({
    mutationKey: ['patient-details-clinical-data-clinical-details'],
    mutationFn: putPatientClinicalDetail,
    onSuccess: data => {
      toast(ToastType.Success, 'Update Patient Clinical Details', data.message);
      queryClient.invalidateQueries({
        queryKey: ['patient-clinical-details'],
      });
      onSuccess();
    },
    onError: error =>
      handleErrorResponse(error, 'Update Patient Clinical Details'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendUpdatePatientClinicalDetailsRequest = (
    payload: PutPatientClinicalDetailsPayload
  ) => {
    setDisableSubmit(true);
    mutate(payload);
  };

  return {sendUpdatePatientClinicalDetailsRequest, isPending, disableSubmit};
}

export function useCreateInvitation(onSuccess: () => void) {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const queryClient = useQueryClient();

  const {mutate, isPending} = useMutation({
    mutationKey: ['patient-send-invitation'],
    mutationFn: postInvitePatient,
    onSuccess: data => {
      toast(ToastType.Success, 'Send invitation', data.message);
      queryClient.invalidateQueries({
        queryKey: ['patient-details'],
      });
      onSuccess();
    },
    onError: error => handleErrorResponse(error, 'Send invitation'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendCreateInvitation = (variables: PostInvitePayloadProps) => {
    setDisableSubmit(true);
    mutate(variables);
  };

  return {sendCreateInvitation, isPending, disableSubmit};
}

export function useMedicalHistory(patientId: number) {
  const {
    data: medicalHistory,
    isLoading: isMedicalHistoryLoading,
    isError: isMedicalHistoryFetchError,
  } = useQuery({
    queryKey: ['patient-medical-history', patientId],
    queryFn: () => getMedicalHistory({patientId}),
  });

  const downloadPDFWithToast = async (questionnaireType: string) => {
    await downloadMedicalHistoryPDF(patientId, questionnaireType)
      .then(() => {
        return toast(
          ToastType.Success,
          'Medical questionnaire full list',
          'PDF downloaded successfully'
        );
      })
      .catch(error => {
        const errorMessage =
          error instanceof Error ? error.message : 'An unknown error occurred';
        toast(
          ToastType.Error,
          'Medical questionnaire full list',
          `Failed to download PDF: ${errorMessage}`
        );
      });
  };

  return {
    medicalHistory: medicalHistory?.data.medicalHistory || [],
    isMedicalHistoryLoading,
    isMedicalHistoryFetchError,
    downloadMedicalHistoryPDF: downloadPDFWithToast,
  };
}
