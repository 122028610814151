import {useEffect, useState} from 'react';
import PatientListComponent from './component';
import {usePatientInfoList} from './hooks';
import './styles.scss';
import {LastAppointment, PatientListOrderBy, PatientStatus} from './constant';
import {useDebounce} from '$modules/frontdesk/hooks';

interface PatientListViewProps {
  archivedOnly?: boolean;
}

function PatientListView(props: PatientListViewProps) {
  const {archivedOnly = false} = props;

  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce<string>(searchText, 500);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortActiveColumn, setSortActiveColumn] =
    useState<PatientListOrderBy>();
  const [patientStatus, setPatientStatus] = useState<PatientStatus[]>(
    archivedOnly ? [PatientStatus.Archived] : []
  );
  const [lastAppointment, setLastAppointment] = useState<LastAppointment>();
  const [sortDirection, setSortDirection] = useState<boolean>(true);
  const {patients, totalPages, totalRecords, isLoading, isError, message} =
    usePatientInfoList({
      search: debouncedSearchTerm,
      pageNumber: currentPage,
      perPage: 10,
      orderBy: sortActiveColumn,
      orderDirection: sortDirection ? 'desc' : 'asc',
      status: patientStatus,
      lastAppointment: lastAppointment,
    });

  const handleNextPage = () => {
    const updatedPage = Math.min(currentPage + 1, totalPages);
    setCurrentPage(updatedPage);
  };

  const handlePreviousPage = () => {
    const updatedPage = Math.max(currentPage - 1, 1);
    setCurrentPage(updatedPage);
  };

  const handleSearchTextChange = (value: string) => {
    setSearchText(value);
  };

  useEffect(() => {
    if (archivedOnly) {
      setPatientStatus([PatientStatus.Archived]);
    } else {
      setPatientStatus([]);
    }
  }, [archivedOnly]);

  return (
    <PatientListComponent
      isLoading={isLoading}
      isError={isError}
      message={message}
      archivedOnly={archivedOnly}
      searchText={searchText}
      currentPage={currentPage}
      patients={patients}
      totalPages={totalPages}
      totalRecords={totalRecords}
      sortActiveColumn={sortActiveColumn}
      sortDirection={sortDirection}
      patientStatus={patientStatus}
      lastAppointment={lastAppointment}
      setPatientStatus={setPatientStatus}
      setLastAppointment={setLastAppointment}
      setSortActiveColumn={setSortActiveColumn}
      setSortDirection={setSortDirection}
      onNextPage={handleNextPage}
      onPreviousPage={handlePreviousPage}
      onSearchTextChange={handleSearchTextChange}
      setCurrentPage={setCurrentPage}
    />
  );
}

export default PatientListView;
