import React, {SetStateAction} from 'react';
import {FormState} from '../types';
import TimeSlotPicker from '$components/TimeSlotPicker';
import {Slot} from '$modules/frontdesk/type';
import './styles.scss';
import OperationThreatreSection from './OperationTheatreSection';
import {OfflineBookingStep} from '../constant';

interface OperationTheatreComponentProps {
  setActiveStep: React.Dispatch<SetStateAction<OfflineBookingStep>>;
  offlineBookingFormData: FormState;
  setOfflineBookingFormData: React.Dispatch<React.SetStateAction<FormState>>;
  offlineBookingType: string;
  slots: Slot[];
  isBookingTypeConsultant: boolean;
}
const OperationTheatreComponent: React.FC<OperationTheatreComponentProps> = ({
  setActiveStep,
  offlineBookingFormData,
  setOfflineBookingFormData,
  slots,
  isBookingTypeConsultant,
}) => {
  const selectedStartTime = offlineBookingFormData.appointment.start_time;
  const selectedEndTime = offlineBookingFormData.appointment.end_time;
  const dateSelected = offlineBookingFormData.appointment.date;
  const selectedOperationTheatre =
    offlineBookingFormData.appointment.operation_theatre_id;

  const selectedSlot: Slot | undefined =
    selectedStartTime && selectedEndTime
      ? {
          startTime: new Date(selectedStartTime),
          endTime: new Date(selectedEndTime),
        }
      : undefined;

  const handleSlotSelection = (slot: Slot) => {
    setOfflineBookingFormData((prevState: FormState) => ({
      appointment: {
        ...prevState.appointment,
        start_time: slot.startTime,
        end_time: slot.endTime,
      },
    }));
  };

  return (
    <>
      <h2 className="dialog-title">Select a time</h2>
      <div className="time-slot-container">
        <div>
          <TimeSlotPicker
            isLoading={false}
            slots={slots}
            selectedSlot={selectedSlot}
            setSelectedSlot={handleSlotSelection}
          />
        </div>
      </div>
      {selectedStartTime && !isBookingTypeConsultant && (
        <OperationThreatreSection
          dateSelected={dateSelected}
          selectedStartTime={selectedStartTime}
          selectedEndTime={selectedEndTime}
          selectedOperationTheatre={selectedOperationTheatre}
          setActiveStep={setActiveStep}
          setOfflineBookingFormData={setOfflineBookingFormData}
        />
      )}
    </>
  );
};

export default OperationTheatreComponent;
