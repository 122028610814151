import Box from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import styles from './styles';

interface LoadingProps {
  color?: CircularProgressProps['color'];
}
const Loading: React.FC<LoadingProps> = ({color = 'primary'}) => {
  return (
    <Box sx={styles.container}>
      <CircularProgress color={color} />
    </Box>
  );
};

export default Loading;
