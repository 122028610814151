import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useMutation} from '@tanstack/react-query';
import {PostLoginVariables} from '$api/bookingFlow';
import {
  PutForgotPasswordVariables,
  putForgotPassword,
  postLogin,
} from '$api/login';
import {handleErrorResponse} from '$utils/axios';
import LocalStorage from '$utils/localStore';
import toast from '$utils/toast';
import {ToastType} from '$components/Toast/constant';
import {USER_ROLES} from '$modules/admin/constant';
import {technicalGlitchToast} from '$constants/toastmessages';
import {useAppDispatch} from '$redux/hooks';
import {loadAuth} from '$redux/authSlice';
import {setMomentTimezone} from '$utils/date';
import {subscribePushManager} from '../../worker/registration';

export function useLogin() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [disableSubmit, setDisableSubmit] = useState(false);

  const {mutate: loginMutate, isPending: isLoginPending} = useMutation({
    mutationKey: ['login'],
    mutationFn: postLogin,
    onError: error => handleErrorResponse(error, 'Login'),
    onSuccess: responseData => {
      const userData = responseData.user;
      LocalStorage.setItem(
        LocalStorage.AUTH_USER_DATA,
        JSON.stringify(responseData)
      );

      dispatch(loadAuth(responseData));

      if (responseData?.user?.timezone) {
        setMomentTimezone(responseData.user.timezone);
      }

      subscribePushManager();

      const role = USER_ROLES.find(ur => ur.name === userData.roleName);
      if (!role) technicalGlitchToast();
      else {
        navigate(role.homeRoute);
        toast(ToastType.Success, 'Login success', responseData.message);
      }
    },
    onSettled: () => setDisableSubmit(false),
  });

  const sendLoginRequest = (variables: PostLoginVariables) => {
    setDisableSubmit(true);
    loginMutate(variables);
  };

  const {mutate: forgotPasswordMutate} = useMutation({
    mutationKey: ['forgot-password'],
    mutationFn: putForgotPassword,
    onSuccess: data => {
      toast(ToastType.Success, 'Forgot Password', data.message);
    },
    onError: error => handleErrorResponse(error, 'Forgot password'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendForgotPasswordRequest = (variables: PutForgotPasswordVariables) => {
    setDisableSubmit(true);
    forgotPasswordMutate(variables);
  };

  return {
    disableSubmit,
    isLoginPending,
    sendLoginRequest,
    sendForgotPasswordRequest,
  };
}
