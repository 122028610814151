interface SectionItemProps<T> {
  title: string;
  records: T[];
  renderItem: (item: T) => React.ReactNode;
}

function SectionItem<T>(props: SectionItemProps<T>) {
  const {title, records, renderItem} = props;

  const items = records.map(item => renderItem(item));

  return (
    <div className="flex flex-col mb-8">
      <h5 className="text-lg font-bold mb-6">{title}</h5>
      {items}
    </div>
  );
}

export default SectionItem;
