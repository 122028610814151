export enum QuestionnaireType {
  HospitalExperience = 'hospital_experience',
  DailyLiving = 'daily_living',
  MedicalHistory = 'medical',
  Lifestyle = 'lifestyle',
  Family = 'family',
}

export enum QuestionType {
  Radio = 'radio',
  Dropdown = 'dropdown',
  TextField = 'textField',
  MultiText = 'multiText',
  Date = 'date',
  SelectYear = 'selectYear',
  Textarea = 'textarea',
  NumericField = 'numericField',
}

export enum QuestionConditionType {
  Yes = 'Yes',
  No = 'No',
  All = 'All',
}
export interface Question {
  answer: string | string[] | null;
  id: number;
  question: string;
  question_type: QuestionType;
  placeholder: string | null;
  label: string | null;
  options: string[] | null;
  sub_questions: Question[];
  condition: QuestionConditionType | null;
}

export interface GetPatientQuestionnaireResponseData {
  success: boolean;
  data: Question[];
  message: string;
}

export interface GetPatientQuestionnaireParams {
  historyType: QuestionnaireType;
  patientId: number;
}

export interface PostPatientQuestionnaireResponseData {
  success: boolean;
  message: string;
}

export interface Questionnaires {
  id: number;
  answer: string | string[];
}

export interface PostPatientQuestionnairePayload {
  patient: {
    id: number;
    history_type: QuestionnaireType;
    questionnaires: Questionnaires[];
  };
}

export interface Questionnaires {
  id: number;
  answer: string | string[];
}
