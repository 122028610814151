import {useFormik} from 'formik';
import AdminComponent from './component';
import {UserFormData, userSchema} from './schema';
import {useCreateUser, useUsers} from './hooks';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';
import LocalStorage from '$utils/localStore';
import {useAppDispatch} from '$redux/hooks';
import {resetAuth} from '$redux/authSlice';

function Admin() {
  const dispatch = useAppDispatch();

  const {isLoading: isUsersLoading, users} = useUsers();
  const {
    isPending: isCreateUserPending,
    disableSubmit,
    sendCreateUserRequest,
  } = useCreateUser(() => formik.resetForm());

  const handleSubmit = (values: UserFormData) => {
    sendCreateUserRequest({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      countryCode: '+44',
      mobileNumber: values.phoneNumber,
      roleId: Number(values.roleId),
    });
  };

  const formik = useFormik<UserFormData>({
    initialValues: {
      firstName: '',
      lastName: '',
      roleId: '',
      email: '',
      phoneNumber: NaN,
    },
    validationSchema: userSchema,
    onSubmit: handleSubmit,
  });

  const handleRoleIdChange = (value: string) => {
    formik.setFieldValue('roleId', value);
  };

  const handleSignOut = () => {
    LocalStorage.removeItem(LocalStorage.AUTH_USER_DATA);
    dispatch(resetAuth());
  };

  const handleClearForm = () => {
    formik.resetForm();
  };

  if (isUsersLoading) return <Loading />;
  if (!users) return <FetchError />;

  return (
    <AdminComponent
      disableSubmit={disableSubmit}
      isCreateUserLoading={isCreateUserPending}
      users={users}
      handleSignOut={handleSignOut}
      handleClearForm={handleClearForm}
      formValues={formik.values}
      formTouched={formik.touched}
      formErrors={formik.errors}
      handleBlur={formik.handleBlur}
      handleChange={formik.handleChange}
      handleSubmit={formik.handleSubmit}
      handleRoleIdChange={handleRoleIdChange}
    />
  );
}

export default Admin;
