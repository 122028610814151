import {InferType, object, ref, string} from 'yup';
import {ValidationMessage} from '$constants/messages';
import {
  LOWER_CASE_REGEX,
  NO_SPACE_REGEX,
  NUMBER_REGEX,
  SPECIAL_CHARACTER_REGEX,
  UPPER_CASE_REGEX,
} from '$constants/regex';
import {PasswordValidationStage} from '../PasswordValidationStepper/types';

export const passwordSchema = string()
  .trim()
  .min(12, PasswordValidationStage.MinCharacter)
  .matches(UPPER_CASE_REGEX, PasswordValidationStage.UpperCase)
  .matches(LOWER_CASE_REGEX, PasswordValidationStage.LowerCase)
  .matches(SPECIAL_CHARACTER_REGEX, PasswordValidationStage.SpecialChar)
  .matches(NUMBER_REGEX, PasswordValidationStage.Number)
  .matches(NO_SPACE_REGEX, PasswordValidationStage.NoSpace);

export const lastStepSchema = object({
  password: passwordSchema,
  confirmPassword: string()
    .trim()
    .oneOf([ref('password')], ValidationMessage.ConfirmPasswordMatch)
    .when('password', {
      is: (value: string) => value,
      then: schema =>
        schema.required(ValidationMessage.ConfirmPasswordRequired),
      otherwise: schema => schema.notRequired(),
    }),
});

export type LastStepFormData = InferType<typeof lastStepSchema>;
