import CheckIcon from '$assets/svgs/check.svg?react';
import ToastXIcon from '$assets/svgs/toast-x.svg?react';

interface PasswordCheckItemProps {
  isNeutral: boolean;
  correct: boolean;
  text: string;
}

function PasswordCheckItem(props: PasswordCheckItemProps) {
  const {text, correct, isNeutral} = props;

  if (!isNeutral && correct)
    return (
      <p className="text-success-color text-xs flex gap-2">
        <CheckIcon className="h-3 w-3" />
        {text}
      </p>
    );

  if (!isNeutral && !correct)
    return (
      <p className="text-error-color text-xs flex gap-2">
        <ToastXIcon className="h-3 w-3" />
        {text}
      </p>
    );

  return <p className="text-tertiary-color text-xs">{text}</p>;
}

export default PasswordCheckItem;
