import axios from 'axios';
import {ToastType} from '~/components/Toast/constant';
import {API_URL} from '~/constants/env';
import {updateRefreshPending} from '~/redux/authSlice';
import store from '~/redux/store';
import {getSubDomain} from '~/utils';
import {
  getAuthToken,
  getRefreshToken,
  getRefreshPending,
  login,
  logout,
} from '~/utils/auth';
import toast from '~/utils/toast';
import {postSessionRefresh} from './sessions/refresh/service';
import {postSessionRefreshResponseToUserAuthData} from './sessions/refresh/utils';
import {createAcceptHeaderValue} from './utils';

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: createAcceptHeaderValue(1),
    'Sub-Domain': getSubDomain(),
  },
});

axiosInstance.interceptors.request.use(config => {
  // set authorization if logged in (token exists)
  const token = getAuthToken();
  if (token) config.headers.Authorization = token;

  return config;
});

axiosInstance.interceptors.response.use(
  response => response,
  handleErrorResponse
);

async function handleErrorResponse(error: unknown) {
  if (axios.isAxiosError(error)) {
    const responseData = error.response?.data;
    const responseMessage = responseData?.message;

    const isTokenInvalid =
      error.response?.status === 401 &&
      responseMessage?.toLowerCase().includes('token is invalid');

    if (isTokenInvalid) {
      const isRefreshPending = getRefreshPending();

      if (!isRefreshPending) {
        const refreshToken = getRefreshToken();
        const refreshSuccess = await refreshAuth(refreshToken);

        if (!refreshSuccess) {
          logout();
          toast(
            ToastType.Error,
            'Session expired',
            'Your session has expired. Please log in again.',
            'session-expired'
          );
        }
      }
    }
  }

  return Promise.reject(error);
}

async function refreshAuth(refreshToken: string | null) {
  if (!refreshToken) return false;

  store.dispatch(updateRefreshPending(true));

  try {
    const responseData = await postSessionRefresh({refreshToken: refreshToken});
    const userAuthData = postSessionRefreshResponseToUserAuthData(responseData);

    const updatedToken = responseData?.data?.token;
    const updatedRefreshToken =
      responseData?.data?.refresh_token || refreshToken;

    if (updatedToken && userAuthData) {
      login(updatedToken, userAuthData, updatedRefreshToken);
    }

    return true;
  } catch {
    return false;
  } finally {
    store.dispatch(updateRefreshPending(false));
  }
}

export default axiosInstance;
