import {useState} from 'react';
import {
  GetPatientQuestionnaireResponseData,
  PostPatientQuestionnairePayload,
  PostPatientQuestionnaireResponseData,
  Questionnaires,
  QuestionnaireType,
} from './types';
import {useNavigate} from 'react-router-dom';
import {useMutation, useQuery} from '@tanstack/react-query';
import {
  networkErrorToast,
  technicalGlitchToast,
} from '$constants/toastmessages';
import toast from '~/utils/toast';
import {ToastType} from '~/components/Toast/constant';
import {AxiosError} from 'axios';
import queryClient from '~/lib/queryClient';
import {getRoute, RouteSection} from '~/utils/route';
import {PatientDetailTab} from '~/modules/frontdesk/PatientList/PatientDetails/constant';
import {getPatientQuestionnaire, PostPatientQuestionnaire} from './service';

export function usePatientQuestionnaire({
  patientId,
  historyType,
}: {
  patientId: number;
  historyType: QuestionnaireType;
}) {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const navigate = useNavigate();
  const {data, isLoading, error} =
    useQuery<GetPatientQuestionnaireResponseData>({
      queryKey: ['patient-questionnaire', historyType, patientId],
      queryFn: () => getPatientQuestionnaire({patientId, historyType}),
    });

  const {
    mutate: createPatientQuestionnaire,
    isPending: isCreatingPatientQuestionnaire,
  } = useMutation({
    mutationKey: ['patient-questionnaire', historyType, patientId],
    mutationFn: (payload: PostPatientQuestionnairePayload) =>
      PostPatientQuestionnaire(payload),
    onError: error => {
      if (error instanceof AxiosError) {
        const axiosError =
          error as AxiosError<PostPatientQuestionnaireResponseData>;
        const response = axiosError.response;

        if (response) {
          if (response.status === 500) {
            return technicalGlitchToast();
          }

          if (response.data) {
            const data = response.data;
            return toast(
              ToastType.Error,
              'Questionnaire submission error',
              data.message
            );
          }
        }

        if (axiosError.message === 'Network Error') {
          return networkErrorToast();
        }

        return technicalGlitchToast();
      } else {
        technicalGlitchToast();
      }
    },
    onSuccess: (data: PostPatientQuestionnaireResponseData) => {
      queryClient.invalidateQueries({
        queryKey: ['patient-questionnaire', historyType, patientId],
      });
      queryClient.invalidateQueries({
        queryKey: ['patient-medical-history', patientId],
      });
      navigate(getRoute(RouteSection.PatientList, patientId.toString()), {
        state: {
          activeSubTab: PatientDetailTab.ClinicalData,
        },
      });
      return toast(ToastType.Success, 'Questionnaire submitted', data.message);
    },
    onSettled: () => setDisableSubmit(false),
  });

  const submitPatientQuestionnaire = (questionnaires: Questionnaires[]) => {
    const payload: PostPatientQuestionnairePayload = {
      patient: {
        id: patientId,
        history_type: historyType,
        questionnaires,
      },
    };
    setDisableSubmit(true);
    createPatientQuestionnaire(payload);
  };

  return {
    questionnaire: data?.data || [],
    isLoading,
    error,
    disableSubmit,
    submitPatientQuestionnaire,
    isCreatingPatientQuestionnaire,
  };
}
