import {Color} from '$constants/style';
import theme from '$modules/frontdesk/theme';

export const filterOptionsContainer = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  background: theme.palette.common.white,
  borderRadius: '0.75rem',
  padding: theme.spacing(2, 2),
  border: '1px solid #47546726',
};

export const filterOptions = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const filterTypeWrapper = {
  overflowX: 'hidden',
  overflowY: 'auto',
  height: '100px',
};

export const filterOptionsButtonContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'end',
  gap: 3,
};

export const resetButton = {
  background: '#F2F4F7',
  color: '#344054',
  textTransform: 'none',
};

export const applyButton = {
  background: Color.GradientButtonBackground,
};
