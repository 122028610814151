import {Dialog, IconButton} from '@mui/material';
import {CalendarApprovedIcon, XCloseIcon} from '$assets/svgs';
import './styles.scss';
import {OfflineBookingStep} from './constant';

interface OfflineBookingComponentProps {
  open: boolean;
  handleClose: () => void;
  dialogBody: React.ReactNode;
  activeStep: string;
}
const OfflineBookingComponent: React.FC<OfflineBookingComponentProps> = ({
  open,
  handleClose,
  dialogBody,
  activeStep,
}) => {
  return (
    <>
      {dialogBody ? (
        <Dialog
          open={open}
          className={
            activeStep === OfflineBookingStep.BookingConfirmed
              ? 'booking-confirmed-container'
              : 'offline-booking-dialog-container'
          }
        >
          {activeStep === OfflineBookingStep.BookingConfirmed ? (
            <div className="dialog-header">
              <div className="icon-container">
                <CalendarApprovedIcon />
              </div>
              <IconButton onClick={handleClose}>
                <XCloseIcon />
              </IconButton>
            </div>
          ) : (
            <div className="dialog-close">
              <IconButton onClick={handleClose}>
                <XCloseIcon />
              </IconButton>
            </div>
          )}
          {dialogBody}
        </Dialog>
      ) : null}
    </>
  );
};

export default OfflineBookingComponent;
