import {ContactDetailIcon} from '$assets/svgs';
import {Stack, Typography, Box} from '@mui/material';

interface ContactDetails {
  email: string;
  mobile_number: string;
  preferred_point_of_contact: string;
}

const ContactDetails = (details: ContactDetails) => {
  const {email, mobile_number, preferred_point_of_contact} = details;

  const gradientText = {
    background: '-webkit-linear-gradient(180deg, #7625DC, #6063F2)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };

  return (
    <Stack spacing={2}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <ContactDetailIcon />
        <Typography variant="h4" style={gradientText}>
          Contact details
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1" sx={{mb: 1}}>
          Email address
        </Typography>
        <Typography variant="subtitle2">{email}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1" sx={{mb: 1}}>
          Phone number
        </Typography>
        <Typography variant="subtitle2">{mobile_number}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1" sx={{mb: 1}}>
          Preferred point of contact
        </Typography>
        <Typography variant="subtitle2">
          {preferred_point_of_contact}
        </Typography>
      </Box>
    </Stack>
  );
};

export default ContactDetails;
