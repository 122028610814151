import SelectInput from '$components/SelectInput';
import {Subcare} from '$modules/booking/types';
import {FormState} from '../types';
import {TITLE_MAP} from './constant';
import './styles.scss';

interface ConsultationSelectionComponentProps {
  consultationList: Subcare[];
  offlineBookingFormData: FormState;
  handleConsultationChange: (value: string) => void;
  renderConsultationMenuItem: (item: Subcare) => React.ReactNode;
  renderValue: (value: string) => React.ReactNode;
  offlineBookingType: string;
}

const ConsultationSelectionComponent: React.FC<
  ConsultationSelectionComponentProps
> = ({
  consultationList,
  offlineBookingFormData,
  handleConsultationChange,
  renderConsultationMenuItem,
  renderValue,
  offlineBookingType,
}) => {
  const selectedConsultationType =
    offlineBookingFormData.appointment.sub_care_type_id;

  return (
    <>
      <h2 className="dialog-title">{TITLE_MAP[offlineBookingType]}</h2>
      <div className="consultation-selection-container">
        <div className="input-container">
          <SelectInput
            items={consultationList}
            renderValue={renderValue}
            renderItem={renderConsultationMenuItem}
            onChange={handleConsultationChange}
            value={
              selectedConsultationType
                ? selectedConsultationType.toString()
                : ''
            }
          />
        </div>
      </div>
    </>
  );
};

export default ConsultationSelectionComponent;
