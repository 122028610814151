import theme from '$modules/frontdesk/theme';
import {type SxProps} from '@mui/material';

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;

export const selectInputStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  color: '#7625DC',
  '& .MuiSelect-icon': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
  },
  '&.Mui-focused .MuiSelect-icon': {
    transform: 'translateY(-50%) rotate(180deg)',
  },
  '&.MuiSelect-selectMenu[aria-expanded="true"] .MuiSelect-icon': {
    transform: 'translateY(-50%) rotate(180deg)',
  },
};

export const menuPaperStyles: SxProps = {
  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  borderRadius: '16px',
  '& .Mui-selected': {
    color: '#7625DC',
  },
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.grey[300],
  boxShadow: theme.shadows[1],
};
