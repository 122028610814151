import {ApiRoute} from '$constants/api';
import axiosInstance from '.';

export interface PostValidateNHSNumberVariables {
  nhsNumber: number;
}

interface PostValidateNHSNumberResponseData {
  message: string;
}

export async function postValidateNHSNumber(
  variables: PostValidateNHSNumberVariables
) {
  const {nhsNumber} = variables;

  const response = await axiosInstance.post<PostValidateNHSNumberResponseData>(
    ApiRoute.PatientValidateNHSNumber,
    {
      patient: {nhs_number: nhsNumber},
    }
  );

  return response.data;
}
