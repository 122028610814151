import React from 'react';
import {GENDER_ITEMS, TITLE_ITEMS} from '$modules/booking/constant';
import Input from '$components/Input';
import SelectInput from '$components/SelectInput';
import {FormikErrors, FormikTouched} from 'formik';
import DatePicker from '$components/DatePicker';
import {PatientDetailOneFormData} from './schema';
import './styles.scss';
import useTimezone from '../../../../../../hooks/useTimezone';

interface PatientDetailOneComponentFormProps {
  values: PatientDetailOneFormData;
  errors: FormikErrors<PatientDetailOneFormData>;
  touched: FormikTouched<PatientDetailOneFormData>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  renderTitleValue: (value: string) => React.ReactNode;
  renderTitleItem: (item: string) => React.ReactNode;
  renderGenderValue: (value: string) => React.ReactNode;
  renderGenderItem: (item: string) => React.ReactNode;
  handleFieldChange: (
    field: string,
    defaultValue: string | null
  ) => (value: unknown) => void;
}

const PatientDetailOneComponent: React.FC<
  PatientDetailOneComponentFormProps
> = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  renderTitleValue,
  renderTitleItem,
  renderGenderValue,
  renderGenderItem,
  handleFieldChange,
}) => {
  const {patient} = values;
  const errorsPatient = errors.patient || {};
  const touchedPatient = touched.patient || {};

  const timezone = useTimezone();

  return (
    <>
      <h2 className="dialog-title">Patient details 1</h2>
      <div className="patient-detail-one-form-container">
        <div className="patient-details-one-input-container">
          <div className="field-label">NHS number</div>
          <Input
            name="patient.nhsNumber"
            placeholder="NHS number"
            type="number"
            value={patient.nhsNumber || ''}
            onBlur={handleBlur}
            onChange={event => {
              if (event.target.value.length <= 10) handleChange(event);
            }}
            touched={touchedPatient?.nhsNumber}
            error={Boolean(errorsPatient?.nhsNumber)}
            helperText={
              touchedPatient?.nhsNumber && errorsPatient?.nhsNumber
                ? errorsPatient.nhsNumber
                : ''
            }
          />
        </div>
        <div className="patient-details-one-input-container">
          <div className="field-label">Title</div>
          <SelectInput
            name="patient.title"
            value={patient.title}
            items={TITLE_ITEMS}
            renderValue={renderTitleValue}
            renderItem={renderTitleItem}
            onBlur={handleBlur}
            onChange={handleFieldChange('title', '')}
            error={touchedPatient?.title && Boolean(errorsPatient?.title)}
          />
          {touchedPatient?.title && errorsPatient?.title && (
            <div className="patient-details-one-error-text">
              {errorsPatient.title}
            </div>
          )}
        </div>
        <div className="patient-details-one-input-container">
          <div className="field-label">First name</div>
          <Input
            name="patient.firstName"
            placeholder="First name"
            value={patient.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touchedPatient?.firstName}
            error={Boolean(errorsPatient?.firstName)}
            helperText={
              touchedPatient?.firstName && errorsPatient?.firstName
                ? errorsPatient.firstName
                : ''
            }
          />
        </div>
        <div className="patient-details-one-input-container">
          <div className="field-label">Last name</div>
          <Input
            name="patient.lastName"
            placeholder="Last name"
            value={patient.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touchedPatient?.lastName}
            error={Boolean(errorsPatient?.lastName)}
            helperText={
              touchedPatient?.lastName && errorsPatient?.lastName
                ? errorsPatient.lastName
                : ''
            }
          />
        </div>
        <div className="patient-details-one-input-container">
          <div className="field-label">Email address</div>
          <Input
            name="patient.email"
            placeholder="Email address"
            value={patient.email}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touchedPatient?.email}
            error={Boolean(touchedPatient?.email && errorsPatient?.email)}
            helperText={
              touchedPatient?.email && errorsPatient?.email
                ? errorsPatient?.email
                : ''
            }
          />
        </div>
        <div className="patient-details-one-input-container">
          <div className="field-label">Gender</div>
          <SelectInput
            name="patient.gender"
            value={patient.gender}
            items={GENDER_ITEMS}
            renderValue={renderGenderValue}
            renderItem={renderGenderItem}
            onBlur={handleBlur}
            onChange={handleFieldChange('gender', '')}
            error={touchedPatient?.gender && Boolean(errorsPatient?.gender)}
          />
          {touchedPatient?.gender && errorsPatient?.gender && (
            <div className="patient-details-one-error-text">
              {errorsPatient.gender}
            </div>
          )}
        </div>
        <div className="patient-details-one-input-container">
          <div className="field-label">Date of birth</div>
          <DatePicker
            disableFuture
            name="patient.dob"
            placeholder="Date of birth"
            value={patient.dob || null}
            onChange={handleFieldChange('dob', null)}
            timezone={timezone}
            isDOB
          />
          {touchedPatient?.dob && errorsPatient?.dob && (
            <div className="patient-details-one-error-text">
              {errorsPatient.dob}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PatientDetailOneComponent;
