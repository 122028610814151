import {useParams} from 'react-router-dom';
import {useAppointmentList} from '$modules/frontdesk/hooks';
import moment from '$utils/date';
import RecentUpdateComponent from './RecentUpdateComponent';
import RecentUpdateSkeleton from './RecentUpdateSkeleton';

function RecentUpdate() {
  const params = useParams();
  const patientId = Number(params.patientId);

  const todaysDate = moment().startOf('day').toDate();

  const {appointments, isLoading} = useAppointmentList({
    toDate: todaysDate,
    patientId,
    orderBy: 'date',
    orderDirection: 'desc',
    status: ['confirm'],
  });

  if (isLoading) return <RecentUpdateSkeleton />;

  return <RecentUpdateComponent appointments={appointments} />;
}

export default RecentUpdate;
