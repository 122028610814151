import FrontdeskButton from '$components/FrontdeskButton';
import {Button, TextField} from '@mui/material';
import CancelAppointmentForm from './CancelAppointmentForm';
import {
  CANCEL_APPOINTMENT_TITLE,
  CANCEL_APPOINTMENT_TITLE_DESCRIPTION,
  OTHER_PERSONAL_REASON,
} from './constant';
import {SetStateAction, useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useCancelAppointmentHook} from '../../hooks/useCancelAppointmentHook';
import {ICancellationReason} from '../../types';
import CheckBox from '$components/CheckBox';
import './styles.scss';

interface CancelAppointmentComponentProps {
  cancelAppointmentModal: boolean;
  setCancelAppointmentModal: React.Dispatch<SetStateAction<boolean>>;
}

const CancelAppointmentComponent: React.FC<CancelAppointmentComponentProps> = ({
  cancelAppointmentModal,
  setCancelAppointmentModal,
}) => {
  const params = useParams();
  const appointmentId = Number(params.id);
  const [cancellationReasonId, setCancellationReasonId] = useState<string>('');
  const [otherCancellationReason, setOtherCancellationReason] =
    useState<string>('');
  const [cancellationReasons, setCancellationReasons] = useState<
    ICancellationReason[]
  >([]);
  const [informPatient, setInformPatient] = useState<boolean>(false);
  const {isPending, sendCancelAppointment} = useCancelAppointmentHook(
    setCancelAppointmentModal
  );

  const isOtherPersonalCancellationReason = useCallback(
    (value: string) => {
      return cancellationReasons.some(
        reason =>
          reason.id.toString() === value &&
          reason.name === OTHER_PERSONAL_REASON
      );
    },
    [cancellationReasons]
  );

  const handleCancellationReasonChange = useCallback(
    (value: string) => {
      setCancellationReasonId(value);
      if (!isOtherPersonalCancellationReason(value)) {
        setOtherCancellationReason('');
      }
    },
    [isOtherPersonalCancellationReason]
  );

  const handleDeleteAppointmentClick = useCallback(() => {
    if (
      isOtherPersonalCancellationReason(cancellationReasonId) &&
      !otherCancellationReason?.trim()
    ) {
      return;
    }

    const payload = {
      cancellationReason: +cancellationReasonId,
      otherCancellationReason: isOtherPersonalCancellationReason(
        cancellationReasonId
      )
        ? otherCancellationReason
        : '',
      appointmentId: appointmentId,
      informPatient: informPatient,
    };
    sendCancelAppointment(payload);
  }, [
    cancellationReasonId,
    otherCancellationReason,
    appointmentId,
    informPatient,
    isOtherPersonalCancellationReason,
    sendCancelAppointment,
  ]);

  return (
    <div className="cancel-appointment-container">
      <div className="cancel-appointment-title">
        <div className="title-text">{CANCEL_APPOINTMENT_TITLE}</div>
        <div className="title-desc">{CANCEL_APPOINTMENT_TITLE_DESCRIPTION}</div>
      </div>
      <div className="cancel-appointment-body">
        <CancelAppointmentForm
          handleCancellationReasonChange={handleCancellationReasonChange}
          cancellationReasonId={cancellationReasonId}
          setCancellationReasons={setCancellationReasons}
        />
        {isOtherPersonalCancellationReason(cancellationReasonId) && (
          <div className="other-reason-input-container">
            <TextField
              className="other-reason-input"
              label="Please specify reason here"
              variant="outlined"
              multiline
              fullWidth
              value={otherCancellationReason}
              onChange={e => setOtherCancellationReason(e.target.value)}
            />
          </div>
        )}
        <div className="cancel-check-box">
          <CheckBox
            label="Inform Patient"
            onClick={() => setInformPatient(prevState => !prevState)}
          />
        </div>
      </div>
      <div
        className={`cancel-appointment-footer ${
          isOtherPersonalCancellationReason(cancellationReasonId)
            ? 'with-textfield'
            : ''
        }`}
      >
        <Button
          className="cancel-btn"
          onClick={() => setCancelAppointmentModal(!cancelAppointmentModal)}
        >
          Cancel
        </Button>
        <FrontdeskButton
          title="Delete Appointment"
          loading={isPending}
          disabled={
            !cancellationReasonId ||
            (isOtherPersonalCancellationReason(cancellationReasonId) &&
              !otherCancellationReason?.trim())
          }
          onClick={handleDeleteAppointmentClick}
        />
      </div>
    </div>
  );
};

export default CancelAppointmentComponent;
