import FrontdeskButton from '$components/FrontdeskButton';
import {NOTIFICATION_SUB_TABS, NotificationSubTab} from './constant';
import NotificationSectionList from './NotificationSectionList';
import NotificationSkeleton from './NotificationSkeleton';
import FetchError from '$components/FetchError';
import {Notification} from './types';
import {PutNotificationMarkReadVariables} from '$api/notification';
import {useNavigate} from 'react-router-dom';
import {getRoute, RouteSection} from '$utils/route';
import {RoutePath} from '$constants/routes';
import {isNumber} from '$utils/number';
import useAuthUser from '../../../hooks/useAuthUser';
import {UserRoleName} from '$modules/admin/constant';
import './styles.scss';

interface NotificationsComponentProps {
  sendMarkReadRequest: (variables: PutNotificationMarkReadVariables) => void;
  isMarkReadPending: boolean;
  isMarkReadDisabled: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  notifications: Notification[];
  pendingRequestCount: number | null;
  totalNotifications: number | null;
  newBookingCount: number | null;
  appointmentCancelledCount: number | null;
  newPatientsCount: number | null;
  activeTab: NotificationSubTab;
  isLoading: boolean;
  isError: boolean;
  fetchNextPage: () => void;
  setActiveTab: React.Dispatch<React.SetStateAction<NotificationSubTab>>;
}

function NotificationsComponent(props: NotificationsComponentProps) {
  const {
    notifications,
    pendingRequestCount,
    totalNotifications,
    newBookingCount,
    appointmentCancelledCount,
    newPatientsCount,
    activeTab,
    isFetchingNextPage,
    isMarkReadPending,
    isMarkReadDisabled,
    hasNextPage,
    isLoading,
    isError,
    fetchNextPage,
    setActiveTab,
    sendMarkReadRequest,
  } = props;

  const navigate = useNavigate();
  const {roleName} = useAuthUser()!;

  const notificationCount: Record<NotificationSubTab, number | null> = {
    [NotificationSubTab.All]: totalNotifications,
    [NotificationSubTab.New]: newBookingCount,
    [NotificationSubTab.PendingRequest]: pendingRequestCount,
    [NotificationSubTab.AppointmentCancel]: appointmentCancelledCount,
  };

  const tabButtons = NOTIFICATION_SUB_TABS.map(item => {
    if (!(item.showTo.includes(roleName) || item.showTo === 'all')) return;
    let label = item.label;
    if (isNumber(notificationCount[item.value])) {
      label += ` (${notificationCount[item.value]})`;
    }

    return (
      <FrontdeskButton
        key={item.value}
        title={label}
        variant="secondary"
        active={item.value === activeTab}
        onClick={() => setActiveTab(item.value)}
      />
    );
  });

  const handleMarkAllRead = () => {
    sendMarkReadRequest({});
  };

  const handleNewPatientRequestsNavigate = () => {
    navigate(
      getRoute(
        RouteSection.Calendar,
        RoutePath.staff.calendar.newPatientRequests
      ),
      {state: {backRoute: getRoute(RouteSection.Notification)}}
    );
  };

  let content: React.ReactNode = null;

  switch (true) {
    case isLoading:
      content = <NotificationSkeleton />;
      break;
    case isError:
      content = <FetchError />;
      break;
    default:
      content = (
        <NotificationSectionList
          notifications={notifications}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
        />
      );
      break;
  }

  return (
    <div className="frontdesk-notifications flex-1 p-8 flex flex-col">
      <h1 className="text-secondary font-bold text-2xl mb-8">Notifications</h1>
      <div className="flex flex-1 gap-20 overflow-auto">
        <div className="h-full flex-1 flex flex-col overflow-auto">
          <div className="mb-6 flex justify-between py-5">
            <div className="flex gap-2">{tabButtons}</div>
            <div className="flex gap-2">
              {roleName && roleName !== UserRoleName.Doctor && (
                <div className="relative">
                  <FrontdeskButton
                    variant="tertiary"
                    title="New patient requests"
                    onClick={handleNewPatientRequestsNavigate}
                  />
                  {newPatientsCount ? (
                    <div className="bg-[#d92d20] absolute rounded-lg w-8 h-6 -top-3 -left-4 flex items-center justify-center px-2 py-1 font-normal text-sm text-white">
                      {newPatientsCount}
                    </div>
                  ) : null}
                </div>
              )}
              <FrontdeskButton
                onClick={handleMarkAllRead}
                title="Mark all as read"
                loading={isMarkReadPending}
                disabled={isMarkReadDisabled}
              />
            </div>
          </div>

          {content}
        </div>
      </div>
    </div>
  );
}

export default NotificationsComponent;
