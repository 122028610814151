import {ConstantsAPIKey} from './constants';
import {GETConstantsResponseData, ConstantsKeyDataMap} from './types';

export function processConstantsAPIResponseData<K extends ConstantsAPIKey>(
  key: K,
  data: GETConstantsResponseData<K>
) {
  switch (key) {
    case ConstantsAPIKey.Address: {
      const responseData =
        data as GETConstantsResponseData<ConstantsAPIKey.Address>;

      const inputData = responseData.data;
      const outputData: ConstantsKeyDataMap[ConstantsAPIKey.Address] =
        inputData.map(item => ({
          code: item.code,
          name: item.name,
        }));

      return outputData;
    }
    case ConstantsAPIKey.Appointment: {
      const responseData =
        data as GETConstantsResponseData<ConstantsAPIKey.Appointment>;
      const inputData = responseData.data;
      const outputData: ConstantsKeyDataMap[ConstantsAPIKey.Appointment] =
        inputData.map(item => ({
          id: item.id,
          image: item.image,
          title: item.title,
          description: item.description,
          displayName: item.display_name,
          isActive: item.is_active,
          isEnable: item.is_enable,
        }));

      return outputData;
    }
    case ConstantsAPIKey.CancellationReason: {
      const responseData =
        data as GETConstantsResponseData<ConstantsAPIKey.CancellationReason>;
      const inputData = responseData.data;
      const outputData: ConstantsKeyDataMap[ConstantsAPIKey.CancellationReason] =
        inputData.map(item => ({
          id: item.id,
          name: item.name,
        }));

      return outputData;
    }
    case ConstantsAPIKey.CardType: {
      const responseData =
        data as GETConstantsResponseData<ConstantsAPIKey.CardType>;
      const inputData = responseData.data;
      const outputData: ConstantsKeyDataMap[ConstantsAPIKey.CardType] =
        inputData.map(item => ({
          id: item.id,
          name: item.name,
        }));

      return outputData;
    }
    case ConstantsAPIKey.Care: {
      const responseData =
        data as GETConstantsResponseData<ConstantsAPIKey.Care>;
      const inputData = responseData.data;
      const outputData: ConstantsKeyDataMap[ConstantsAPIKey.Care] =
        inputData.map(item => ({
          id: item.id,
          title: item.title,
          description: item.description,
          image: item.image,
          isActive: item.is_active,
          isEnable: item.is_enable,
        }));

      return outputData;
    }
    case ConstantsAPIKey.Consultant: {
      const responseData =
        data as GETConstantsResponseData<ConstantsAPIKey.Consultant>;
      const inputData = responseData.data;
      const outputData: ConstantsKeyDataMap[ConstantsAPIKey.Consultant] =
        inputData.map(item => ({
          id: item.id,
          fullName: item.full_name,
          profileURL: item.profile_url,
          doctorDetails: item.doctor_details
            ? {
                bioLink: item.doctor_details.bio_link,
                consultationFee: item.doctor_details.consultation_fee,
                followupFee: item.doctor_details.followup_fee,
                description: item.doctor_details.description,
                specializations: item.doctor_details.specializations.map(
                  it => ({
                    id: it.id,
                    title: it.title,
                  })
                ),
              }
            : null,
        }));

      return outputData;
    }
    case ConstantsAPIKey.CountryList: {
      const responseData =
        data as GETConstantsResponseData<ConstantsAPIKey.CountryList>;
      const inputData = responseData.data;
      const outputData: ConstantsKeyDataMap[ConstantsAPIKey.CountryList] =
        inputData.map(item => ({
          id: item.id,
          code: item.code,
          name: item.name,
          countryCode: item.country_code,
        }));

      return outputData;
    }
    case ConstantsAPIKey.Hospital: {
      const responseData =
        data as GETConstantsResponseData<ConstantsAPIKey.Hospital>;
      const inputData = responseData.data;
      const outputData: ConstantsKeyDataMap[ConstantsAPIKey.Hospital] = {
        id: inputData.id,
        name: inputData.name,
        details: inputData.details,
        logoURL: inputData.logo_url,
        countryCode: inputData.country_code,
        mobileNumber: inputData.mobile_number,
        email: inputData.email,
        subDomain: inputData.sub_domain,
        timezone: inputData.timezone,
        moreInfoUrl: inputData.more_info_url,
        title: inputData.title,
        description: inputData.description,
        backgroundImageURL: inputData.background_img_url,
      };

      return outputData;
    }
    case ConstantsAPIKey.Insurance: {
      const responseData =
        data as GETConstantsResponseData<ConstantsAPIKey.Insurance>;
      const inputData = responseData.data;
      const outputData: ConstantsKeyDataMap[ConstantsAPIKey.Insurance] =
        inputData.map(item => ({
          id: item.id,
          name: item.name,
        }));

      return outputData;
    }
    case ConstantsAPIKey.PaymentMode: {
      const responseData =
        data as GETConstantsResponseData<ConstantsAPIKey.PaymentMode>;
      const inputData = responseData.data;
      const outputData: ConstantsKeyDataMap[ConstantsAPIKey.PaymentMode] =
        inputData.map(item => ({
          id: item.id,
          name: item.name,
        }));

      return outputData;
    }
    case ConstantsAPIKey.PreferredPointOfContact: {
      const responseData =
        data as GETConstantsResponseData<ConstantsAPIKey.PreferredPointOfContact>;
      const inputData = responseData.data;
      const outputData: ConstantsKeyDataMap[ConstantsAPIKey.PreferredPointOfContact] =
        inputData.map(item => ({
          id: item.id,
          name: item.name,
        }));

      return outputData;
    }
    case ConstantsAPIKey.PreferredTime: {
      const responseData =
        data as GETConstantsResponseData<ConstantsAPIKey.PreferredTime>;
      const inputData = responseData.data;
      const outputData: ConstantsKeyDataMap[ConstantsAPIKey.PreferredTime] =
        inputData.map(item => ({
          id: item.id,
          name: item.name,
        }));

      return outputData;
    }
    case ConstantsAPIKey.Relationship: {
      const responseData =
        data as GETConstantsResponseData<ConstantsAPIKey.Relationship>;
      const inputData = responseData.data;
      const outputData: ConstantsKeyDataMap[ConstantsAPIKey.Relationship] =
        inputData.map(item => ({
          id: item.id,
          name: item.name,
        }));

      return outputData;
    }
    case ConstantsAPIKey.Subcare: {
      const responseData =
        data as GETConstantsResponseData<ConstantsAPIKey.Subcare>;
      const inputData = responseData.data;
      const outputData: ConstantsKeyDataMap[ConstantsAPIKey.Subcare] =
        inputData.map(item => ({
          id: item.id,
          title: item.title,
          description: item.description,
          isActive: item.is_active,
          isEnable: item.is_enable,
        }));

      return outputData;
    }
  }

  return undefined;
}
