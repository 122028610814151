import {PropsWithChildren} from 'react';
import clothImage from '$assets/images/Cloth_02.png';
import LoginFooter from './LoginFooter';

function LoginLayout(props: PropsWithChildren) {
  const {children} = props;

  return (
    <div className="h-screen w-screen flex">
      <div className="login-container flex-1 flex flex-col">
        <div className="flex-1 overflow-auto">
          <div className="m-auto w-full max-w-xl py-16 px-5">{children}</div>
        </div>
        <LoginFooter />
      </div>
      <div className="hidden lg:block bg-green-200 flex-1 overflow-hidden">
        <img src={clothImage} className="h-full w-full" />
      </div>
    </div>
  );
}

export default LoginLayout;
