import React, {SetStateAction} from 'react';
import PaymentDetailsComponent from './component';
import FrontdeskButton from '$components/FrontdeskButton';
import {ArrowNarrowRightIcon} from '$assets/svgs';
import {Button, MenuItem, Typography} from '@mui/material';
import {useFormik} from 'formik';
import {
  PatientInsuranceDetailFormData,
  patientInsuranceDetailFormSchema,
} from './schema';
import FetchError from '$components/FetchError';
import Loading from '$components/Loading';
import {useInsuranceTypeHook} from '../hooks/useInsuranceTypeHook';
import {Color} from '$constants/style';
import {Insurance} from '$modules/booking/types';
import {useCreateOfflineAppointmentHook} from '../hooks/useCreateOfflineAppointmentHook';
import {FormState} from '../types';
import './styles.scss';
import {usePaymentCards} from '$modules/booking/hooks';
import {OfflineBookingStep} from '../constant';

interface PaymentDetailsContainerProps {
  setActiveStep: React.Dispatch<SetStateAction<OfflineBookingStep>>;
  offlineBookingFormData: FormState;
  setOfflineBookingFormData: React.Dispatch<SetStateAction<FormState>>;
  offlineBookingType: string;
  setOfflineBookedAppointmentId: React.Dispatch<SetStateAction<number>>;
}

const PaymentDetailsContainer: React.FC<PaymentDetailsContainerProps> = ({
  setActiveStep,
  offlineBookingFormData,
  setOfflineBookingFormData,
  offlineBookingType,
  setOfflineBookedAppointmentId,
}) => {
  const selectedSelfPaying = offlineBookingFormData.appointment.self_paying;
  const selectedInsuranceId = offlineBookingFormData.appointment.insurance_id;
  const selectedPaymentMode = offlineBookingFormData.appointment.payment_mode;
  const selectedMembershipNumber =
    offlineBookingFormData.appointment.membership_no;
  const {createAppointment, isPending: isCreateAppointmentPending} =
    useCreateOfflineAppointmentHook(
      setActiveStep,
      setOfflineBookedAppointmentId,
      offlineBookingFormData,
      offlineBookingType
    );

  const handleSubmit = async () => {
    const {selfPaying, insuranceId, membershipNumber, paymentMode} =
      formik.values;

    setOfflineBookingFormData((prevState: FormState) => {
      const updatedState = {
        appointment: {
          ...prevState.appointment,
          self_paying: selfPaying,
          payment_mode: paymentMode || '0',
          insurance_id: Number(insuranceId),
          membership_no: membershipNumber?.trim(),
        },
      };

      return updatedState;
    });
  };

  const formik = useFormik<PatientInsuranceDetailFormData>({
    initialValues: {
      selfPaying:
        selectedSelfPaying === false
          ? false
          : selectedSelfPaying === true
            ? true
            : null,
      paymentMode: selectedPaymentMode || '0',
      insuranceId: selectedInsuranceId || '',
      membershipNumber: selectedMembershipNumber || '',
    },
    validationSchema: patientInsuranceDetailFormSchema,
    onSubmit: handleSubmit,
  });
  const {setFieldValue} = formik;
  const {isPending: isInsuranceTypesPending, data: insuranceTypes} =
    useInsuranceTypeHook();
  const {
    cards,
    isLoading: isPaymentCardsLoading,
    isError: isPaymentCardsError,
  } = usePaymentCards();

  const isPending = isInsuranceTypesPending || isPaymentCardsLoading;
  const isError = !insuranceTypes || isPaymentCardsError;

  if (isPending) return <Loading />;
  if (isError) return <FetchError />;

  const insuranceProviderList = insuranceTypes?.data;

  const handleSelfPayingChange = (value: string) => {
    setFieldValue('selfPaying', value === 'Self paying' ? true : false);
    setFieldValue('insuranceId', '');
    setFieldValue('membershipNumber', '');
    setOfflineBookingFormData((prevState: FormState) => ({
      appointment: {
        ...prevState.appointment,
        self_paying: value === 'Self paying' ? true : false,
      },
    }));
  };

  const handlePaymentMethodChange = (value: string) => {
    if (!selectedSelfPaying) {
      const items = insuranceTypes?.data;

      if (!value) {
        setFieldValue('insuranceId', '');
        return;
      }

      const item = items.find(it => it.id.toString() === value);

      if (item) {
        setFieldValue('insuranceId', item.id.toString());
      } else {
        setFieldValue('insuranceId', '');
      }
    }
  };

  const handleFormSubmit = async () => {
    await formik.submitForm();

    if (Object.keys(formik.errors).length === 0) {
      setOfflineBookingFormData((prevState: FormState) => {
        const updatedState = {
          ...prevState,
          appointment: {
            ...prevState.appointment,
            self_paying: formik.values.selfPaying,
            payment_mode: !formik.values.selfPaying ? '' : 'Card',
            insurance_id: Number(formik.values.insuranceId),
            membership_no: formik.values.membershipNumber?.trim(),
          },
        };
        createAppointment(updatedState);
        return updatedState;
      });
    }
  };

  const renderPaymentValue = (value: string) => {
    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value === '' ? 'Select an option' : value}
      </Typography>
    );
  };

  const renderPaymentItem = (item: string) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  };

  const renderMenuItem = (item: Insurance) => {
    return (
      <MenuItem key={item.id} value={item.id.toString()}>
        <Typography variant="subtitle1">{item.name}</Typography>
      </MenuItem>
    );
  };

  const renderValue = (value: string) => {
    if (!value) return 'Name of the insurer';

    const items = insuranceTypes?.data;

    const item = items?.find(it => it.id.toString() === value);
    return item ? item.name : '';
  };

  const isContinueDisabled = !(
    formik.values.selfPaying ||
    (!formik.values.selfPaying &&
      formik.values.insuranceId &&
      formik.values.membershipNumber)
  );

  return (
    <>
      <PaymentDetailsComponent
        insuranceProviderList={insuranceProviderList || []}
        cards={cards}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        handleSelfPayingChange={handleSelfPayingChange}
        handlePaymentMethodChange={handlePaymentMethodChange}
        renderPaymentValue={renderPaymentValue}
        renderPaymentItem={renderPaymentItem}
        renderMenuItem={renderMenuItem}
        renderValue={renderValue}
      />
      <div className="dialog-footer">
        <Button
          className="back-btn"
          onClick={() => {
            handleSubmit();
            setActiveStep(OfflineBookingStep.DateAndTimeSelection);
          }}
        >
          Back
        </Button>
        <FrontdeskButton
          title="Confirm booking"
          className="confirm-booking-btn"
          disabled={isContinueDisabled}
          endIcon={<ArrowNarrowRightIcon />}
          onClick={handleFormSubmit}
          loading={isCreateAppointmentPending}
        />
      </div>
    </>
  );
};

export default PaymentDetailsContainer;
