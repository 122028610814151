import {useLocation} from 'react-router-dom';
import {Divider} from '@mui/material';
import {useNotificationUnreadCount} from '$modules/frontdesk/Notifications/hooks';
import Drawer from './Drawer';
import Header from './Header';
import Footer from './Footer';
import NavigationListItem from './NavigationListItem';
import {
  NAVIGATION_BOTTOM_ITEMS,
  NAVIGATION_TOP_ITEMS,
  NavigationItem,
} from './constant';
import {useMemo} from 'react';

interface SidebarDrawerComponentProps {
  open: boolean;
  logoutModalOpen: boolean;
  toggleLogoutModal: () => void;
  onLogout: () => void;
  onDrawerToggle: () => void;
}

function SidebarDrawerComponent(props: SidebarDrawerComponentProps) {
  const {open, logoutModalOpen, toggleLogoutModal, onLogout, onDrawerToggle} =
    props;

  const location = useLocation();
  const pathName = location.pathname;

  const {count: unreadNotificationCount} = useNotificationUnreadCount();
  const isActivePath = (currentSideMenu: string, currentUrl: string) => {
    return currentSideMenu.split('/').slice(0, 3).join('/') === currentUrl;
  };

  const badgeNumberMap: Record<NavigationItem, number | undefined> =
    useMemo(() => {
      return {
        [NavigationItem.Notification]: unreadNotificationCount,
      } as Record<NavigationItem, number | undefined>;
    }, [unreadNotificationCount]);

  const topItems = NAVIGATION_TOP_ITEMS.map(item => (
    <NavigationListItem
      key={item.route}
      title={item.title}
      icon={item.icon}
      route={item.route}
      sidebarOpen={open}
      active={isActivePath(pathName, item.route)}
      badgeNumber={badgeNumberMap[item.id]}
    />
  ));

  const bottomItems = NAVIGATION_BOTTOM_ITEMS.map(item => (
    <NavigationListItem
      key={item.route}
      title={item.title}
      icon={item.icon}
      route={item.route}
      sidebarOpen={open}
      active={isActivePath(pathName, item.route)}
      badgeNumber={badgeNumberMap[item.id]}
    />
  ));

  return (
    <Drawer
      variant="permanent"
      open={open}
      className="flex flex-col"
      transitionDuration={400}
    >
      <Header open={open} onDrawerToggle={onDrawerToggle} />
      <Divider className="mb-4" />
      <div className="flex-1 flex flex-col justify-between overflow-y-auto overflow-x-hidden pt-4">
        <ul>{topItems}</ul>
        <ul>{bottomItems}</ul>
      </div>
      <Footer
        sidebarOpen={open}
        logoutModalOpen={logoutModalOpen}
        toggleLogoutModal={toggleLogoutModal}
        onLogout={onLogout}
      />
    </Drawer>
  );
}

export default SidebarDrawerComponent;
