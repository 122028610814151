import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {Consultant} from '../../type';
import {ALL_CONSULTANT_OPTION} from '../../constant';

interface ConsultantAutocompleteProps {
  value: Consultant | null;
  consultants: Consultant[];
  showAllConsultants?: boolean;
  onChange: (value: Consultant | null) => void;
}

function ConsultantAutocomplete(props: ConsultantAutocompleteProps) {
  const {value, consultants, showAllConsultants, onChange} = props;

  const handleChange = (
    _event: React.SyntheticEvent,
    value: (Consultant & {label: string}) | null
  ) => {
    if (value) {
      const {id, profileUrl, fullName, doctorDetails} = value;
      onChange({id, profileUrl, fullName, doctorDetails});
    } else {
      onChange(null);
    }
  };

  let options = consultants.map(con => ({
    id: con.id,
    profileUrl: con.profileUrl,
    fullName: con.fullName,
    label: con.fullName,
    doctorDetails: con.doctorDetails,
  }));

  if (showAllConsultants) {
    options = [...options, ALL_CONSULTANT_OPTION];
  }

  const selectedValue = value
    ? {
        ...value,
        label: value.fullName,
      }
    : value;

  return (
    <Autocomplete
      className="calendar-consultant-autocomplete-container"
      options={options}
      onChange={handleChange}
      isOptionEqualToValue={(opt, val) => opt.label === val.label}
      value={selectedValue}
      renderInput={params => (
        <TextField {...params} placeholder="Select Consultant" />
      )}
    />
  );
}

export default ConsultantAutocomplete;
