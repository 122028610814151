export const NEW_APPOINTMENT_TYPE_TITLE = 'Select an appointment';
export const SURGEY_APPOINTMENT_TYPE_TITLE =
  'What kind of appointment would you like to book?';

interface ITitleMap {
  [key: string]: string;
  new: string;
  surgery: string;
}
export const TITLE_MAP: ITitleMap = {
  new: NEW_APPOINTMENT_TYPE_TITLE,
  surgery: SURGEY_APPOINTMENT_TYPE_TITLE,
};
