import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import {UserData} from './types';

interface UserTableProps {
  users: UserData[];
}

function UserTable(props: UserTableProps) {
  const {users} = props;

  return (
    <div className="flex-1 flex flex-col rounded-lg overflow-auto">
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" className="!font-bold">
                Full name
              </TableCell>
              <TableCell align="left" className="!font-bold">
                Email address
              </TableCell>
              <TableCell align="left" className="!font-bold">
                Mobile number
              </TableCell>
              <TableCell align="left" className="!font-bold">
                Role
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(userData => (
              <TableRow key={userData.id}>
                <TableCell align="left">{userData.fullName}</TableCell>
                <TableCell align="left">{userData.email}</TableCell>
                <TableCell align="left">
                  {userData.mobileNumber
                    ? `${userData.countryCode} ${userData.mobileNumber}`
                    : '-'}
                </TableCell>
                <TableCell align="left">{userData.roleName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default UserTable;
