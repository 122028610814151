import {Theme, CSSObject} from '@mui/material/styles';
import {DRAWER_WIDTH} from './constant';

export function openedMixin(theme: Theme): CSSObject {
  return {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  };
}

export function closedMixin(theme: Theme): CSSObject {
  return {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '72px',
  };
}
