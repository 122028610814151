import {RoutePath} from '$constants/routes';
import {RouteSection, getRoute} from '$utils/route';

export interface UserRole {
  id: number;
  name: string;
  homeRoute: string;
}

export enum UserRoleName {
  Admin = 'Admin',
  Doctor = 'Doctor',
  Frontdesk = 'Front Desk',
  Patient = 'Patient',
}

export const USER_ROLES: UserRole[] = [
  {
    id: 1,
    name: UserRoleName.Admin,
    homeRoute: RoutePath.adminRoute,
  },
  {
    id: 2,
    name: UserRoleName.Doctor,
    homeRoute: getRoute(RouteSection.Calendar),
  },
  {
    id: 3,
    name: UserRoleName.Frontdesk,
    homeRoute: getRoute(RouteSection.Calendar),
  },
];
