import {useId, useState} from 'react';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import FileUploadIcon from '$assets/svgs/file-upload.svg?react';
import {useDropzone} from 'react-dropzone';
import {
  ACCEPTED_FILES,
  MAX_FILE_SIZE,
  MAX_FILES,
} from '$modules/booking/constant';
import {FileSizeValidationMessage} from '$constants/messages';

interface DocumentUploadProps {
  name?: string;
  value?: File;
  onChange: (file: File | null) => void;
}

function DocumentUpload(props: DocumentUploadProps) {
  const {name, value, onChange} = props;
  const [error, setError] = useState<string | null>(null);

  const {getInputProps} = useDropzone({
    maxFiles: MAX_FILES,
    maxSize: MAX_FILE_SIZE,
    accept: ACCEPTED_FILES,
    noDrag: true,
    onDrop(acceptedFiles, fileRejections) {
      if (fileRejections.length) {
        setError(FileSizeValidationMessage.FileSizeExceed);
        onChange(null);
      } else {
        setError(null);
        onChange(acceptedFiles[0]);
      }
    },
  });

  const id = useId();

  return (
    <div className="flex flex-col justify-center items-center">
      <Button
        component="label"
        htmlFor={id}
        startIcon={<FileUploadIcon />}
        variant="text"
        className="normal-case text-[#7625DC]"
      >
        Upload attachment
        <Input
          id={id}
          inputProps={getInputProps()}
          className="hidden"
          name={name}
          type="file"
        />
      </Button>
      <Typography variant="caption">
        {value?.name || 'PNG, JPG, PDF (Max 5MB)'}
      </Typography>
      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </div>
  );
}

export default DocumentUpload;
