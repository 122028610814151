import React from 'react';
import {Button} from '@mui/material';
import './styles.scss';
import {NOTIFICATION_SKELETON_ITEM_COUNT} from '$modules/frontdesk/constant';

const NotificationSkeleton = () => {
  const skeletonSections = Array.from({
    length: NOTIFICATION_SKELETON_ITEM_COUNT,
  });

  return (
    <div className="h-full flex-1 flex flex-col overflow-hidden">
      <div className="mb-4">
        <div className="h-8 w-24 bg-slate-300 rounded animate-pulse"></div>
      </div>

      {skeletonSections.map((_, sectionIndex) => (
        <React.Fragment key={sectionIndex}>
          <div className="mb-2">
            <Button
              className="animate-pulse w-full justify-start normal-case text-left flex gap-4 py-3.5 border-bottom"
              disabled
            >
              <div className="flex items-start justify-center">
                <div className="p-2 bg-slate-300 rounded-lg flex">
                  <div className="h-6 w-6 animate-pulse" />
                </div>
              </div>
              <div className="flex-1 flex flex-col gap-1">
                <div className="h-3.5 w-40 rounded-lg bg-slate-300 font-bold primary" />
                <div className="h-3.5 w-1/2 rounded-lg bg-slate-300 font-normal text-primary" />
                <div className="h-3 w-20 rounded-lg bg-slate-300 text-grey-dark font-normal" />
              </div>
            </Button>
          </div>
          {/* function to add divider*/}
          {(sectionIndex + 1) % 3 === 0 && sectionIndex !== 9 && (
            <div className="mb-4 mt-7">
              <div className="h-8 w-24 bg-slate-300 rounded animate-pulse"></div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default NotificationSkeleton;
