import FetchError from '$components/FetchError';
import Loading from '$components/Loading';
import ConsultationSelectionComponent from './component';
import FrontdeskButton from '$components/FrontdeskButton';
import {ArrowNarrowRightIcon} from '$assets/svgs';
import Button from '@mui/material/Button';
import {consultantSelectionViewStyles} from '$modules/booking/styles';
import {Box, MenuItem} from '@mui/material';
import {Subcare} from '$modules/booking/types';
import './styles.scss';
import {FormState} from '../types';
import {useAppointmentTypeHook} from '../hooks/useAppointmentTypeHook';
import {OfflineBookingStep} from '../constant';
import {SetStateAction, useMemo} from 'react';
import {useAPIConstants} from '~/services/api/constants/hooks';
import {ConstantsAPIKey} from '~/services/api/constants/constants';

interface ConsultationSelectionContainerProps {
  setActiveStep: React.Dispatch<SetStateAction<OfflineBookingStep>>;
  offlineBookingFormData: FormState;
  setOfflineBookingFormData: React.Dispatch<React.SetStateAction<FormState>>;
  offlineBookingType: string;
}

const ConsultationSelectionContainer: React.FC<
  ConsultationSelectionContainerProps
> = ({
  setActiveStep,
  offlineBookingFormData,
  setOfflineBookingFormData,
  offlineBookingType,
}) => {
  const appointmentId = offlineBookingFormData.appointment.appointment_type_id;
  const {isPending: isAppointmentTypePending, data: appointmentTypes} =
    useAppointmentTypeHook(appointmentId);

  const careTypeId = useMemo(() => {
    const item = appointmentTypes?.data.find(item => item.enable);
    return item?.id;
  }, [appointmentTypes]);

  const {data, isLoading: isCareTypePending} = useAPIConstants({
    key: ConstantsAPIKey.Subcare,
    careId: careTypeId,
  });

  const isPending = isAppointmentTypePending || isCareTypePending;
  const isError = !appointmentTypes || !data;

  if (isPending) return <Loading />;
  if (isError) return <FetchError />;

  const isContinueDisabled = offlineBookingFormData.appointment.care_type_id;

  const renderConsultationMenuItem = (item: Subcare) => {
    return (
      <MenuItem
        key={item.id}
        value={item.id.toString()}
        sx={consultantSelectionViewStyles.menuItemContainer}
      >
        <span>{item.title}</span>
      </MenuItem>
    );
  };

  const renderValue = (value: string) => {
    if (!value)
      return offlineBookingType === 'new'
        ? 'Select consultation'
        : 'Select surgery';
    const item = data.find(it => it.id.toString() === value);
    if (!item) {
      handleConsultationChange('');
      return '';
    }
    return (
      <Box sx={consultantSelectionViewStyles.menuItemContainer}>
        <span>{item.title}</span>
      </Box>
    );
  };

  const handleConsultationChange = (value: string) => {
    if (careTypeId) {
      setOfflineBookingFormData((prevState: FormState) => ({
        appointment: {
          ...prevState.appointment,
          care_type_id: careTypeId,
          sub_care_type_id: Number(value),
        },
      }));
    }
  };

  return (
    <>
      <ConsultationSelectionComponent
        consultationList={data}
        offlineBookingFormData={offlineBookingFormData}
        handleConsultationChange={handleConsultationChange}
        renderConsultationMenuItem={renderConsultationMenuItem}
        renderValue={renderValue}
        offlineBookingType={offlineBookingType}
      />
      <div className="dialog-footer">
        <Button
          className="back-btn"
          onClick={() =>
            setActiveStep(OfflineBookingStep.AppointmentTypeSelection)
          }
        >
          Back
        </Button>
        <FrontdeskButton
          title="Continue"
          className="continue-btn"
          disabled={!isContinueDisabled}
          endIcon={<ArrowNarrowRightIcon />}
          onClick={() => setActiveStep(OfflineBookingStep.ConsultantSelection)}
        />
      </div>
    </>
  );
};

export default ConsultationSelectionContainer;
