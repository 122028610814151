import {SetStateAction} from 'react';
import DownloadIcon from '$assets/svgs/download.svg?react';
import PillIcon from '$assets/svgs/pill.svg?react';
import {Button} from '@mui/material';
import MedicationItem from './MedicationItem';
import FrontdeskButton from '$components/FrontdeskButton';
import {Medication} from '../types';
import MedicationDialog from './MedicationDialog';
import {useMedicationList} from '../hooks';
import DeleteConfirmDialog from '$components/DeleteConfirmDialog';

interface MedicationListComponentProps {
  patientId: number;
  medication?: Medication;
  openMedicationDialog: boolean;
  setMedicationId: React.Dispatch<SetStateAction<number | undefined>>;
  setOpenMedicationDialog: React.Dispatch<SetStateAction<boolean>>;
  openDeleteMedicationDialog: boolean;
  setOpenDeleteMedicationDialog: React.Dispatch<SetStateAction<boolean>>;
  handleDeleteMedicationRequest: (id: number) => void;
  disableClick: boolean;
}

function MedicationListComponent({
  patientId,
  medication,
  openMedicationDialog,
  setMedicationId,
  openDeleteMedicationDialog,
  handleDeleteMedicationRequest,
  setOpenMedicationDialog,
  setOpenDeleteMedicationDialog,
  disableClick,
}: MedicationListComponentProps) {
  const {medicationList, isLoading, downloadMedicationListPDF} =
    useMedicationList(patientId);

  const items = medicationList.map(item => {
    const handleEditClick = () => {
      setMedicationId(item.id);
      setOpenMedicationDialog(true);
    };

    const handleDeleteClick = () => {
      setMedicationId(item.id);
      setOpenDeleteMedicationDialog(true);
    };

    return (
      <MedicationItem
        disableClick={disableClick}
        onDeleteClick={handleDeleteClick}
        key={item.id}
        item={item}
        onEditClick={handleEditClick}
      />
    );
  });

  const handleAddMedicationClick = () => {
    setMedicationId(undefined);
    setOpenMedicationDialog(true);
  };

  const handleDownloadClick = () => {
    downloadMedicationListPDF();
  };

  const handleMedicationDelete = () => {
    const medicationId = medication?.id;
    if (medicationId) {
      handleDeleteMedicationRequest(medicationId);
    }
  };

  return (
    <div className="clinical-data__medication-list overflow-auto col-span-3 row-span-3 row-start-3">
      <div className="header pb-2">
        <div className="card-title-container">
          <PillIcon />
          <span className="card-title">Medication List</span>
        </div>
        <Button
          startIcon={<DownloadIcon />}
          className="download-full-list-button"
          onClick={handleDownloadClick}
          disabled={isLoading || !medicationList.length}
        >
          Download full list
        </Button>
      </div>

      <div className="flex-1 overflow-auto">{items}</div>
      <MedicationDialog
        open={openMedicationDialog}
        onClose={() => setOpenMedicationDialog(false)}
        medication={medication}
      />
      {openDeleteMedicationDialog && (
        <DeleteConfirmDialog
          title="Medication"
          isDisabled={disableClick}
          isOpen={openDeleteMedicationDialog}
          onClose={() => setOpenDeleteMedicationDialog(false)}
          onDelete={handleMedicationDelete}
        />
      )}
      <div>
        <FrontdeskButton
          title="Add medication"
          onClick={handleAddMedicationClick}
        />
      </div>
    </div>
  );
}

export default MedicationListComponent;
