import {Fragment} from 'react';
import QuestionCard from './questionCard';
import {FormikProps, FormikValues} from 'formik';
import QuestionnaireTitle from '../QuestionnaireForm/QuestionnaireTitle';
import {MAX_QUESTIONS_PER_ROW} from './constant';
import QuestionnaireHeader from '../QuestionnaireForm/QuestionnaireHeader';
import QuestionnaireFooter from '../QuestionnaireForm/QuestionnaireFooter';
import '../styles.scss';
import {Question} from '~/services/api/MedicalHistory/Questionnaire/types';

interface MedicalHistoryQuestionnaireComponentProps {
  formik: FormikProps<FormikValues>;
  currentPage: number;
  startIndex: number;
  questionsToDisplay: Question[];
  totalPages: number;
  handleFieldChange: (fieldId: string, value: string) => void;
  handleDateChange: (fieldId: string, date: Date | null) => void;
  handlePageChange: (page: number) => void;
  renderYearItem: (item: string) => JSX.Element;
  renderYearValue: (value: string, placeholder: string) => JSX.Element;
  handleFooterSubmit: () => Promise<void>;
  handleBack: () => void;
  disableSubmit: boolean;
}

function MedicalHistoryQuestionnaireComponent(
  props: MedicalHistoryQuestionnaireComponentProps
) {
  const {
    formik,
    currentPage,
    handleFieldChange,
    handleDateChange,
    handleFooterSubmit,
    handlePageChange,
    questionsToDisplay,
    renderYearItem,
    renderYearValue,
    startIndex,
    totalPages,
    handleBack,
    disableSubmit,
  } = props;

  return (
    <div className="flex flex-col flex-1 bg-primary font-primary">
      <QuestionnaireHeader
        handleBack={handleBack}
        data-testid="medical-questionnaire-header"
      />
      <div className="flex-1 overflow-y-auto px-10 pt-2">
        <QuestionnaireTitle
          title="Medical history questionnaire"
          className="w-auto text-2xl font-semibold leading-7 tracking-tighter font-inter text-primary-dark text-left mb-2"
          data-testid="medical-questionnaire-title"
        />
        {currentPage === 1 && (
          <div className="mb-6">
            <p className="text-xl text-[#475467] font-semibold">
              Have you ever had, or are you currently being treated for, any of
              the following?
            </p>
          </div>
        )}

        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
            {questionsToDisplay.map((question, idx) => (
              <Fragment key={idx}>
                <QuestionCard
                  errors={formik.errors}
                  touched={formik.touched}
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                  handleFieldChange={handleFieldChange}
                  handleDateChange={handleDateChange}
                  values={formik.values}
                  fieldName={question.id.toString()}
                  id={idx}
                  question={question}
                  startIndex={startIndex}
                  renderYearItem={renderYearItem}
                  renderYearValue={renderYearValue}
                  data-testid={`medical-questionnaire-card-${question.id}`}
                />
                {(idx + 1) % MAX_QUESTIONS_PER_ROW === 0 &&
                  idx + 1 !== questionsToDisplay.length && (
                    <hr className="hidden md:block col-span-1 md:col-span-2 lg:col-span-3 border-b-1 border-gray-400 opacity-15" />
                  )}
              </Fragment>
            ))}
          </div>
        </form>
      </div>
      <QuestionnaireFooter
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        handleSubmit={handleFooterSubmit}
        isFormValid={formik.isValid}
        disableSubmit={disableSubmit}
        data-testid="medical-questionnaire-footer"
      />
    </div>
  );
}

export default MedicalHistoryQuestionnaireComponent;
