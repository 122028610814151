import {useParams} from 'react-router-dom';
import BookingDetailsComponent from './component';
import FetchError from '$components/FetchError';
import {ThemeProvider} from '@emotion/react';
import theme from '$modules/frontdesk/theme';
import BookingDetailsSkeleton from './BookingDetailsSkeleton';
import {useBookingDetailsHook} from '../hooks/useBookingDetailsHook';

const BookingDetailsContainer = () => {
  const params = useParams();
  const appointmentId = Number(params.id);

  const {data: bookingDetails, isFetching} =
    useBookingDetailsHook(appointmentId);

  if (isFetching) return <BookingDetailsSkeleton />;
  if (!bookingDetails) return <FetchError />;

  return (
    <ThemeProvider theme={theme}>
      <BookingDetailsComponent appointment={bookingDetails?.appointment} />
    </ThemeProvider>
  );
};

export default BookingDetailsContainer;
