import {useState} from 'react';
import FilterMenuComponent from './component';
import {LastAppointment, PatientStatus} from '../../constant';

interface FilterMenuProps {
  archivedOnly: boolean;
  patientStatus: PatientStatus[];
  lastAppointment?: LastAppointment;
  setPatientStatus: (value: PatientStatus[]) => void;
  setLastAppointment: (value?: LastAppointment) => void;
}

function FilterMenu(props: FilterMenuProps) {
  const {
    archivedOnly,
    patientStatus,
    lastAppointment,
    setPatientStatus,
    setLastAppointment,
  } = props;

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [filterPatientStatus, setFilterPatientStatus] = useState(patientStatus);
  const [filterLastAppointment, setFilterLastAppointment] =
    useState(lastAppointment);

  const handleMenuClose = () => setAnchorElement(null);

  const handleSave = () => {
    setPatientStatus(filterPatientStatus);
    setLastAppointment(filterLastAppointment);
    handleMenuClose();
  };

  const handleReset = () => {
    setPatientStatus([]);
    setFilterPatientStatus([]);
    setLastAppointment(undefined);
    setFilterLastAppointment(undefined);
  };

  const handleFilterClick: React.MouseEventHandler<HTMLButtonElement> = ev =>
    setAnchorElement(ev.currentTarget);

  return (
    <FilterMenuComponent
      archivedOnly={archivedOnly}
      anchorElement={anchorElement}
      patientStatus={filterPatientStatus}
      lastAppointment={filterLastAppointment}
      setPatientStatus={setFilterPatientStatus}
      setLastAppointment={setFilterLastAppointment}
      onFilterClick={handleFilterClick}
      onMenuClose={handleMenuClose}
      onSave={handleSave}
      onReset={handleReset}
    />
  );
}

export default FilterMenu;
