import {object, string, InferType, mixed} from 'yup';
import {ValidationMessage} from '$constants/messages';

export const documentUploadFormSchema = object({
  document: mixed().required(ValidationMessage.DocumentRequired),
  documentName: string().required(ValidationMessage.DocumentNameRequired),
  performedBy: string().required(ValidationMessage.DocumentPerformedByRequired),
});

export type DocumentUploadFormData = InferType<typeof documentUploadFormSchema>;
