import VialIcon from '$assets/svgs/vial.svg?react';
import {dateToDDMMYYYY, momentDate} from '$utils/date';
import {TestRecord} from './types';

interface TestCardProps {
  testRecord: TestRecord;
}

function TestCard(props: TestCardProps) {
  const {testRecord} = props;

  const date = testRecord.createdBy;

  const dateString = dateToDDMMYYYY(date);
  const timeString = momentDate(date).format('HH:mm');

  return (
    <div className="section-item bg-white rounded-lg p-4 flex gap-4 mb-4">
      <div>
        <span className="section-icon-container p-1 flex justify-center items-center rounded-full text-white">
          <VialIcon />
        </span>
      </div>
      <div className="flex-1 pr-6 mt-0.5">
        <h6 className="section-title text-lg font-bold mb-3">
          {testRecord.name}
        </h6>
        <p>
          Performed by:{' '}
          <span className="font-bold">{testRecord.performedBy || '-'}</span>
        </p>
      </div>
      <div className="pr-8 mt-0.5 flex flex-col">
        <span className="text-base font-bold mb-1">{dateString}</span>
        <span className="text-base font-normal text-right">{timeString}</span>
      </div>
    </div>
  );
}

export default TestCard;
