import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CalendarCheckIcon from '$assets/svgs/calendar-check.svg?react';
import ShieldTickIcon from '$assets/svgs/shield-tick.svg?react';
import UserIcon from '$assets/svgs/user.svg?react';
import styles from './styles';
import {BookingStepperStage} from '../../types';

export interface BookingStepperItemProps {
  stage: BookingStepperStage;
  type: 'active' | 'disabled' | 'inactive';
}

const bookingStepperStageMap: Record<
  BookingStepperStage,
  {icon: React.ReactNode; title: string}
> = {
  [BookingStepperStage.Appointment]: {
    icon: <CalendarCheckIcon />,
    title: 'Appointment',
  },
  [BookingStepperStage.PatientDetails]: {
    icon: <UserIcon />,
    title: 'Patient details',
  },
  [BookingStepperStage.Insurance]: {
    icon: <ShieldTickIcon />,
    title: 'Insurance',
  },
};

export function BookingStepperItem(props: BookingStepperItemProps) {
  const {stage, type} = props;

  let itemClassName = 'stepper-item';
  let iconClassName = 'stepper-icon';
  let titleClassName = 'stepper-title';

  switch (type) {
    case 'disabled':
      iconClassName += ' disabled';
      titleClassName += ' disabled';
      break;
    case 'inactive':
      itemClassName += ' inactive-step';
      break;
    case 'active':
    default:
      break;
  }

  return (
    <Box sx={styles.stepperItem} className={itemClassName}>
      <Box sx={styles.stepperIconWrap} className={iconClassName}>
        {bookingStepperStageMap[stage].icon}
      </Box>
      <Typography variant="h4" sx={styles.stepTitle} className={titleClassName}>
        {bookingStepperStageMap[stage].title}
      </Typography>
    </Box>
  );
}

export function BookingStepperMobileActiveItem(props: BookingStepperItemProps) {
  const {stage} = props;

  return (
    <Box sx={styles.stepperItem}>
      <Box sx={styles.stepperIconWrapWithFlex}>
        {bookingStepperStageMap[stage].icon}
      </Box>
      <Typography variant="h4" sx={styles.stepTitle}>
        {bookingStepperStageMap[stage].title}
      </Typography>
    </Box>
  );
}
