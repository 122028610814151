import React, {SetStateAction, useEffect, useState} from 'react';
import {usePatientListHook} from '../hooks/usePatientListHook';
import SelectOrBookPatientComponent from './component';
import {FormState} from '../types';
import FrontdeskButton from '$components/FrontdeskButton';
import {ArrowNarrowRightIcon} from '$assets/svgs';
import {OfflineBookingStep} from '../constant';
import PatientInfoContainer from '$modules/frontdesk/PatientList/PatientDetails/Overview/PatientInfo';
import SearchPatientResult from '../SearchPatientResult';
import './styles.scss';
import {useDebounce} from '$modules/frontdesk/hooks';

interface SelectOrBookPatientContainerProps {
  setActiveStep: React.Dispatch<SetStateAction<OfflineBookingStep>>;
  offlineBookingFormData: FormState;
  setOfflineBookingFormData: React.Dispatch<React.SetStateAction<FormState>>;
  offlineBookingType: string;
  setOfflineBookingType: React.Dispatch<React.SetStateAction<string>>;
}

const SelectOrBookPatientContainer: React.FC<
  SelectOrBookPatientContainerProps
> = ({
  setActiveStep,
  offlineBookingFormData,
  setOfflineBookingFormData,
  offlineBookingType,
  setOfflineBookingType,
}) => {
  const isContinueBtnDisabled =
    !offlineBookingFormData.appointment?.patient?.id;
  const [selectedPatientId, setSelectedPatientId] = useState<number | null>(
    null
  );
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce<string>(searchText, 500);
  const {patients, isPending} = usePatientListHook(debouncedSearchText);
  const isSearching = searchText && !selectedPatientId;
  const hasSelectedPatient = Boolean(selectedPatientId);

  const handleBookANewPatientClick = () => {
    setActiveStep(OfflineBookingStep.PatientDetailsOne);
  };

  const handleContinueClick = () => {
    if (offlineBookingType === 'surgery') {
      setActiveStep(OfflineBookingStep.AppointmentTypeSelection);
    }
  };

  useEffect(() => {
    setSelectedPatientId(null);
  }, [searchText]);

  return (
    <>
      <h2 className="dialog-title">Please select the patient</h2>
      <div className="dialog-body">
        <SelectOrBookPatientComponent
          handleBookANewPatientClick={handleBookANewPatientClick}
          selectedPatientId={selectedPatientId}
          searchText={searchText}
          setSearchText={setSearchText}
        />
        {isSearching && (
          <div className="search-patient-result-wrapper">
            <SearchPatientResult
              searchText={debouncedSearchText}
              patients={patients}
              setOfflineBookingFormData={setOfflineBookingFormData}
              setOfflineBookingType={setOfflineBookingType}
              setSelectedPatientId={setSelectedPatientId}
              isPending={isPending}
            />
          </div>
        )}
        {hasSelectedPatient && selectedPatientId && (
          <div className="patient-info-container">
            <PatientInfoContainer
              key={selectedPatientId}
              patientId={selectedPatientId}
              canEditPatient={false}
              canInvitePatient={false}
              canViewFullProfile={true}
            />
          </div>
        )}
      </div>
      <div className="dialog-footer">
        <FrontdeskButton
          disabled={isContinueBtnDisabled}
          title="Continue"
          className="continue-btn"
          endIcon={<ArrowNarrowRightIcon />}
          onClick={handleContinueClick}
        />
      </div>
    </>
  );
};

export default SelectOrBookPatientContainer;
