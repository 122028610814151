import {InferType, boolean, object, string, mixed} from 'yup';
import {ValidationMessage} from '$constants/messages';

export const gpOrOpticianReferralFormSchema = object({
  hasReferral: boolean().required(ValidationMessage.SelectOption),
  bookingReason: string().trim(),
  referralFile: mixed().when('hasReferral', {
    is: true,
    then: schema =>
      schema
        .required(ValidationMessage.ReferralFileRequired)
        .test('', ValidationMessage.ReferralFileSize, value => {
          const file = value as File;
          const fileSize = file.size / (1024 * 1024);

          return fileSize <= 5;
        }),
    otherwise: schema => schema.notRequired(),
  }),
});

export type GPOrOpticianReferralValidatedFormData = Omit<
  InferType<typeof gpOrOpticianReferralFormSchema>,
  'referralFile'
> & {referralFile?: File};

export interface GPOrOpticianReferralFormData {
  hasReferral: boolean | null;
  bookingReason?: string;
  referralFile?: File | null;
}
