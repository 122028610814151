import FrontdeskButton from '$components/FrontdeskButton';

interface TabCardProps {
  active: boolean;
  label: string;
  count: number;
  onClick: () => void;
}

function TabCard(props: TabCardProps) {
  const {active, label, count, onClick} = props;

  return (
    <FrontdeskButton
      active={active}
      variant="secondary"
      title={`${label} (${count})`}
      onClick={onClick}
    />
  );
}

export default TabCard;
