import DownloadIcon from '$assets/svgs/download.svg?react';
import PillIcon from '$assets/svgs/pill.svg?react';
import {MEDICATIONLIST_SKELETON_ITEM_COUNT} from '$modules/frontdesk/constant';
import {Button} from '@mui/material';

const MedicationListSkeleton = () => {
  const skeletonItems = Array.from({
    length: MEDICATIONLIST_SKELETON_ITEM_COUNT,
  });
  return (
    <div className="clinical-data__medication-list overflow-auto col-span-3 row-span-3 row-start-3 space-y-4">
      <div className="header pb-2">
        <div className="card-title-container">
          <PillIcon />
          <span className="card-title">Medication List</span>
        </div>
        <Button
          startIcon={<DownloadIcon />}
          className="download-full-list-button"
          disabled
        >
          Download full list
        </Button>
      </div>

      <div className="flex-1 overflow-auto space-y-2">
        {skeletonItems.map((_, index) => (
          <div
            key={index}
            className={
              'flex justify-between items-center p-4 bg-white border border-gray-200 rounded-lg animate-pulse'
            }
          >
            <div className="flex-1 overflow-auto">
              <div className="bg-gray-200 h-5 w-1/4 mb-2 rounded"></div>

              <div className="bg-gray-200 h-4 w-3/5 rounded"></div>
            </div>

            <div className="ml-4">
              <div className="bg-gray-200 h-8 w-8 rounded-full"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default MedicationListSkeleton;
