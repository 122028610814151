import {PENDINGREQUESTS_SKELETON_ITEM_COUNT} from '$modules/frontdesk/constant';

const PendingListItemSkeleton = () => {
  const skeletonItems = Array.from({
    length: PENDINGREQUESTS_SKELETON_ITEM_COUNT,
  });
  return (
    <div className="flex flex-col gap-4">
      {skeletonItems.map((_, index) => (
        <div
          key={index}
          className="rounded-xl border border-gray-200 bg-white px-4 py-3 shadow-sm animate-pulse flex w-full flex-col gap-4 overflow-x-auto overflow-y-auto"
        >
          <div className="flex items-center justify-between gap-4">
            <div className="w-14 h-10 bg-gray-200 rounded-md"></div>
            <div className="w-px bg-gray-200 h-10"></div>
            <div className="flex-1">
              <div className="w-2/3 h-5 bg-gray-200 rounded-full mb-2"></div>
              <div className="w-1/3 h-5 bg-gray-200 rounded-full"></div>
            </div>
            <div className="w-20 h-8 bg-gray-200 rounded-full"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PendingListItemSkeleton;
