import {useQuery} from '@tanstack/react-query';
import {getAppointmentTypes} from '$api/bookingFlow';

export const useAppointmentTypeHook = (bookingId: number) => {
  const {isPending, isError, error, data} = useQuery({
    queryKey: ['appointment-types'],
    queryFn: () => getAppointmentTypes(bookingId),
  });

  return {isPending, isError, error, data};
};
