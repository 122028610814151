import React from 'react';
import {FormikErrors, FormikTouched} from 'formik';
import {UsersEditIcon} from '~/assets/svgs';
import SelectInput from '~/components/SelectInput';
import DatePicker from '~/components/DatePicker';
import {Typography} from '@mui/material';
import {Color} from '~/constants/style';
import Input from '~/components/Input';
import {City, Country} from '~/modules/booking/types';
import {EditBookingFormData} from '../schema';
import {GENDER_ITEMS} from '~/modules/frontdesk/PatientList/PatientDetails/Overview/EditPatient/constants';
import ContactDetailsIndex from '../ContactDetails';

interface PatientDetailsComponentProps {
  values: EditBookingFormData;
  errors: FormikErrors<EditBookingFormData>;
  touched: FormikTouched<EditBookingFormData>;
  countries: Country[];
  cities: City[];
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  setFieldChanged: (field: string, touched?: boolean) => void;
  setFieldValue: (field: string, value: unknown) => void;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  renderGenderMenuItem: (item: string) => React.ReactNode;
  renderGenderValue: (value: string) => React.ReactNode;
  renderCountryValue: (value: string) => React.ReactNode;
  renderCountryItem: (item: {code: string; name: string}) => React.ReactNode;
  renderCityValue: (value: string) => React.ReactNode;
  renderCityItem: (item: {code: string; name: string}) => React.ReactNode;
  renderSelfBookingValue: (value: string) => React.ReactNode;
  renderSelfBookingItem: (item: string) => React.ReactNode;
  renderGPOrOpticianReferralValue: (value: string) => React.ReactNode;
  renderGPOrOpticianReferralItem: (item: string) => React.ReactNode;
  handleFieldChange: (
    field: string,
    value: number | string | boolean | Date
  ) => void;
  timezone: string;
}

const PatientDetailsComponent = (props: PatientDetailsComponentProps) => {
  const {
    values,
    errors,
    touched,
    cities,
    countries,
    handleFieldChange,
    handleChange,
    handleBlur,
    timezone,
    setFieldChanged,
    setFieldValue,
    renderCityItem,
    renderCityValue,
    renderCountryItem,
    renderCountryValue,
    renderGPOrOpticianReferralItem,
    renderGPOrOpticianReferralValue,
    renderGenderMenuItem,
    renderGenderValue,
    renderSelfBookingItem,
    renderSelfBookingValue,
  } = props;

  return (
    <div className="flex-[5]">
      <div className="flex flex-col p-6 bg-tertiary rounded-lg border-container">
        <div className="flex gap-2 items-center">
          <UsersEditIcon />
          <h2 className="font-semibold text-lg leading-6 tracking-tight bg-gradient-clip">
            Patient details
          </h2>
        </div>
        <div className="flex flex-col gap-3 mt-3">
          <div className="font-normal text-sm leading-4 tracking-tight text-primary">
            Gender
          </div>
          <SelectInput
            name="appointment.patient.gender"
            value={values.appointment.patient.gender}
            items={GENDER_ITEMS}
            renderValue={renderGenderValue}
            renderItem={renderGenderMenuItem}
            onBlur={handleBlur}
            onChange={value => handleFieldChange('patient.gender', value)}
            error={
              touched.appointment?.patient?.gender &&
              Boolean(errors.appointment?.patient?.gender)
            }
          />
          {touched.appointment?.patient?.gender &&
            errors.appointment?.patient?.gender && (
              <p className="">{errors.appointment?.patient?.gender}</p>
            )}
        </div>
        <div className="flex flex-col gap-3 mt-3">
          <div className="font-normal text-sm leading-4 tracking-tight text-primary">
            Date of birth
          </div>
          <DatePicker
            disableFuture
            name="appointment.patient.dob"
            placeholder="Date of birth"
            value={new Date(values.appointment.patient.dob)}
            onChange={date => handleFieldChange('patient.dob', date!)}
            timezone={timezone}
          />
          {touched.appointment?.patient?.dob &&
            errors.appointment?.patient?.dob && (
              <Typography
                variant="caption"
                color={Color.ErrorMain}
                fontWeight={400}
              >
                {/* Formik issue https://github.com/jaredpalmer/formik/issues/3683 */}
                <>{errors.appointment?.patient?.dob}</>
              </Typography>
            )}
        </div>
        <div className="flex flex-col gap-3 mt-3">
          <div className="font-normal text-sm leading-4 tracking-tight text-primary">
            Self booking
          </div>
          <SelectInput
            name="appointment.self_booking"
            value={values.appointment.self_booking ? 'Yes' : 'No'}
            items={['Yes', 'No']}
            renderValue={renderSelfBookingValue}
            renderItem={renderSelfBookingItem}
            onBlur={handleBlur}
            onChange={value =>
              handleFieldChange('self_booking', value === 'Yes' ? true : false)
            }
            error={
              touched.appointment?.self_booking &&
              Boolean(errors.appointment?.self_booking)
            }
          />
          {touched.appointment?.self_booking &&
            errors.appointment?.self_booking && (
              <p className="m-0 font-sans font-normal text-xs leading-relaxed tracking-wide text-danger ">
                {errors.appointment?.self_booking}
              </p>
            )}
        </div>
        <div className="flex flex-col gap-3 mt-3">
          <div className="font-normal text-sm leading-4 tracking-tight text-primary">
            GP or optician referral
          </div>
          <SelectInput
            name="appointment.referred_by_physician"
            value={values.appointment.referred_by_physician ? 'Yes' : 'No'}
            items={['Yes', 'No']}
            renderValue={renderGPOrOpticianReferralValue}
            renderItem={renderGPOrOpticianReferralItem}
            onBlur={handleBlur}
            onChange={value =>
              handleFieldChange(
                'referred_by_physician',
                value === 'Yes' ? true : false
              )
            }
            error={
              touched.appointment?.referred_by_physician &&
              Boolean(errors.appointment?.referred_by_physician)
            }
          />
          {touched.appointment?.referred_by_physician &&
            errors.appointment?.referred_by_physician && (
              <p className="m-0 font-sans font-normal text-xs leading-relaxed tracking-wide text-danger ">
                {errors.appointment?.referred_by_physician}
              </p>
            )}
        </div>
        <div className="flex flex-col gap-3 mt-3">
          <div className="font-normal text-sm leading-4 tracking-tight text-primary">
            Address
          </div>
          <Input
            name="appointment.patient.address.line1"
            placeholder="Enter address"
            value={values.appointment.patient.address.line1}
            onBlur={handleBlur}
            onChange={handleChange}
            error={
              touched.appointment?.patient?.address?.line1 &&
              Boolean(errors.appointment?.patient?.address?.line1)
            }
            helperText={
              touched.appointment?.patient?.address?.line1 &&
              errors.appointment?.patient?.address?.line1
                ? errors.appointment?.patient?.address?.line1
                : ''
            }
            touched={touched.appointment?.patient?.address?.line1}
          />
        </div>
        <div className="flex flex-col gap-3 mt-3">
          <div className="font-normal text-sm leading-4 tracking-tight text-primary">
            Post code
          </div>
          <Input
            name="appointment.patient.address.postcode"
            placeholder="Enter postcode"
            value={values.appointment.patient.address.postcode}
            onBlur={handleBlur}
            onChange={handleChange}
            error={
              touched.appointment?.patient?.address?.postcode &&
              Boolean(errors.appointment?.patient?.address?.postcode)
            }
            helperText={
              touched.appointment?.patient?.address?.postcode &&
              errors.appointment?.patient?.address?.postcode
                ? errors.appointment?.patient?.address?.postcode
                : ''
            }
            touched={touched.appointment?.patient?.address?.postcode}
          />
        </div>
        <div className="flex flex-col gap-3 mt-3">
          <div className="font-normal text-sm leading-4 tracking-tight text-primary">
            Country
          </div>
          <SelectInput
            name="appointment.patient.address.country"
            placeholder="Country"
            value={values.appointment.patient.address.country}
            items={countries}
            renderValue={renderCountryValue}
            renderItem={renderCountryItem}
            onBlur={handleBlur}
            onChange={value =>
              handleFieldChange('patient.address.country', value)
            }
            error={
              touched.appointment?.patient?.address?.country &&
              Boolean(errors.appointment?.patient?.address?.country)
            }
          />
          {touched.appointment?.patient?.address?.country &&
            errors.appointment?.patient?.address?.country && (
              <p className="m-0 font-sans font-normal text-xs leading-relaxed tracking-wide text-danger">
                {errors.appointment?.patient?.address?.country}
              </p>
            )}
        </div>
        <div className="flex flex-col gap-3 mt-3">
          <div className="font-normal text-sm leading-4 tracking-tight text-primary">
            Town or city
          </div>
          <SelectInput
            name="appointment.patient.address.city"
            placeholder="Enter town or city"
            value={values.appointment.patient.address.city}
            items={cities}
            renderValue={renderCityValue}
            renderItem={renderCityItem}
            onBlur={handleBlur}
            onChange={value => handleFieldChange('patient.address.city', value)}
            error={
              touched.appointment?.patient?.address?.city &&
              Boolean(errors.appointment?.patient?.address?.city)
            }
          />
          {touched.appointment?.patient?.address?.city &&
            errors.appointment?.patient?.address?.city && (
              <p className="m-0 font-sans font-normal text-xs leading-relaxed tracking-wide text-danger ">
                {errors.appointment?.patient?.address?.city}
              </p>
            )}
        </div>
        <ContactDetailsIndex
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
          touched={touched}
          setFieldTouched={setFieldChanged}
          setFieldValue={setFieldValue}
        />
      </div>
    </div>
  );
};

export default PatientDetailsComponent;
