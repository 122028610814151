import React from 'react';
import {CommonQuestionnaireComponentProps} from '../types';
import QuestionnaireHeader from '../QuestionnaireForm/QuestionnaireHeader';
import QuestionnaireTitle from '../QuestionnaireForm/QuestionnaireTitle';
import QuestionnaireForm from '../QuestionnaireForm/QuestionnaireForm';
import QuestionnaireFooter from '../QuestionnaireForm/QuestionnaireFooter';
import '../styles.scss';

const DailyLivingAndHospitalExperienceComponent: React.FC<
  CommonQuestionnaireComponentProps
> = ({
  formik,
  questionnaireType,
  handleFormSubmit,
  startIndex,
  totalPages,
  questionsToDisplay,
  currentPage,
  handlePageChange,
  handleBack,
  disableSubmit,
}) => {
  const headingMap: {[key: string]: string} = {
    hospital_experience: 'Hospital experience questionnaire',
    daily_living: 'Daily living questionnaire',
  };

  const heading = headingMap[questionnaireType!];

  return (
    <div
      className="flex flex-col flex-1 bg-primary font-primary"
      data-testid="questionnaire-component"
    >
      <QuestionnaireHeader
        handleBack={handleBack}
        data-testid="questionnaire-header"
      />
      <div className="flex-1 overflow-y-auto px-10 pt-2">
        <QuestionnaireTitle
          title={heading}
          className="w-auto text-2xl font-semibold leading-7 tracking-tight font-inter text-primary-dark text-left mb-2"
          data-testid="questionnaire-title"
        />
        <QuestionnaireForm
          formik={formik}
          startIndex={startIndex}
          questionsToDisplay={questionsToDisplay}
          questionContainerClassName="flex-1 rounded-lg my-6"
          questionClassName="font-inter font-semibold text-xl text-grey-dark leading-7 tracking-tight"
          data-testid="questionnaire-form"
        />
      </div>
      <QuestionnaireFooter
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        handleSubmit={handleFormSubmit}
        isFormValid={formik.isValid}
        disableSubmit={disableSubmit}
        data-testid="questionnaire-footer"
      />
    </div>
  );
};

export default DailyLivingAndHospitalExperienceComponent;
