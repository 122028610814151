import Box from '@mui/material/Box';
import EyeOpLogo from '$assets/svgs/eye-op.svg?react';
import HospitalMobileLogo from '$assets/svgs/site-logo.svg?react';

import AppointmentChoices from '../components/AppointmentChoices';
import BookingStepper from '../components/BookingStepper';
import Footer from '../components/Footer';
import {choicesConfirmationViewStyles as styles} from '../styles';
import {
  BookingDataUpdater,
  BookingStepperStage,
  BookingView,
  ChoicesConfirmationViewChoices,
} from '../types';
import {useMediaQuery} from '@mui/material';
import theme from '$modules/booking/theme';

interface ChoicesConfirmationViewProps {
  bookingChoices: ChoicesConfirmationViewChoices;
  setActiveView: (view: BookingView) => void;
  setBookingChoices: BookingDataUpdater;
}

function ChoicesConfirmationView(props: ChoicesConfirmationViewProps) {
  const {bookingChoices, setActiveView} = props;

  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const handleBackClick = () => {
    setActiveView(BookingView.DateSelectionView);
  };

  const handleContinueClick = () => {
    setActiveView(BookingView.RegisterWithStoneygateView);
  };

  return (
    <Box sx={styles.screenSection}>
      <Box sx={styles.bookingWrapper}>
        <Box sx={styles.content} flexDirection="column" gap={5}>
          <Box sx={styles.header}>
            <Box display="flex" alignItems="center" gap={1}>
              <EyeOpLogo className="site-logo" />
              {isLarge && <div className="h-3 w-px bg-[#EBC3FF]" />}
              {isLarge && <HospitalMobileLogo />}
            </Box>
            <Box sx={styles.hospitalLogoWrap}>
              <HospitalMobileLogo className="hosptial-mobile-logo" />
            </Box>
          </Box>
          <Box sx={styles.contentSection}>
            <Box>
              <BookingStepper activeStage={BookingStepperStage.Appointment} />
            </Box>
            <AppointmentChoices bookingChoices={bookingChoices} />
            <Footer
              disableContinue={false}
              onBackClick={handleBackClick}
              onContinueClick={handleContinueClick}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.imageSection} />
    </Box>
  );
}

export default ChoicesConfirmationView;
