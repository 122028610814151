import CalendarIcon from '$assets/svgs/calendamenuwhiteicon.svg?react';
import NotificationIcon from '$assets/svgs/notification.svg?react';
import UserSquareIcon from '$assets/svgs/user-square-icon.svg?react';
import {RouteSection, getRoute} from '$utils/route';

export const DRAWER_WIDTH = 277;

export enum NavigationItem {
  Calendar = 'CALENDAR',
  PatientList = 'PATIENT_LIST',
  Notification = 'NOTIFICATION',
}

export const NAVIGATION_TOP_ITEMS: {
  id: NavigationItem;
  title: string;
  route: string;
  icon: React.ReactNode;
}[] = [
  {
    id: NavigationItem.Calendar,
    title: 'Calendar',
    route: getRoute(RouteSection.Calendar),
    icon: <CalendarIcon />,
  },
  {
    id: NavigationItem.PatientList,
    title: 'Patient List',
    route: getRoute(RouteSection.PatientList),
    icon: <UserSquareIcon />,
  },
];

export const NAVIGATION_BOTTOM_ITEMS: {
  id: NavigationItem;
  title: string;
  route: string;
  icon: React.ReactNode;
}[] = [
  {
    id: NavigationItem.Notification,
    title: 'Notifications',
    route: getRoute(RouteSection.Notification),
    icon: <NotificationIcon />,
  },
];
