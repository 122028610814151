import {useQuery} from '@tanstack/react-query';
import {getInsuranceTypes} from '$api/bookingFlow';

export const useInsuranceTypeHook = () => {
  const {isPending, isError, error, data} = useQuery({
    queryKey: ['insurance-types'],
    queryFn: getInsuranceTypes,
  });

  return {
    isPending,
    isError,
    error,
    data,
  };
};
