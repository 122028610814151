export const enum ApiRoute {
  Constant = '/constants',
  PatientContactEmailVerification = '/patients/validate_email',
  PatientValidateNHSNumber = '/patients/validate_nhs_number',
  BookingSignIn = '/sessions/sign_in',
  BookingSendOtp = '/sessions/send_otp',
  BookingReSendOtp = '/sessions/resend_otp',
  BookingValidateOtp = '/sessions/validate_otp',
  Appointment = '/appointments',
  Login = '/sessions/user_sign_in',
  ForgotPassword = '/users/forgot_password',
  ResetPassword = '/users/reset_password',
  PatientForgotPassword = '/patients/forgot_password',
  PatientResetPassword = '/patients/reset_password',
  PatientSetPassword = '/patients/set_password',
  ValidatePasswordToken = '/validate_password_token',
  Users = '/users',
  FilterOptions = '/filter_options',
  AvailableSlots = '/users/available_slots',
  Patients = '/patients',
  OperationTheatre = '/operation_theatres/available_rooms',
  Notes = '/notes',
  Questionnaires = '/questionnaires',
  PatientCare = '/special_cares',
  Medications = '/medications',
  MedicalHistory = '/questionnaires/list_history',
  Documents = '/documents',
  UpdateDetails = '/update_details',
  ClinicalDetails = '/show_clinic_data',
  AllergyList = '/show_allergies',
  UpdateAlergy = '/update_allergies',
  UpdateClinicalDetails = '/update_clinic_data',
  UpdateStatus = '/update_status',
  Notification = '/notifications',
  PatientQuestionnaire = '/patient_questionnaires',
  Inviation = '/invitations',
  DeleteAppointment = '/appointments/delete_appointment',
  ShowAppointment = '/appointments/show_details',
  PushNotification = '/web_push_subscriptions',
  BlockSlots = '/appointments/block_slots',
  ValidatePostcode = '/addresses/validate_postcode',
}

export const enum ConstantApiParamKey {
  Booking = 'appointment',
  Appointment = 'care',
  Care = 'sub_care',
  Consultant = 'consultant',
  Relationship = 'relationship',
  Insurance = 'insurance',
  Payment = 'payment_mode',
  City = 'address',
  BookingCancellation = 'cancellation_reason',
  OperationTheatre = 'opeartion_theatre',
  PreferredTime = 'preferred_time',
  Hospital = 'hospital',
  CardType = 'card_type',
  PreferredPointOfContact = 'preferred_point_of_contact',
  Country = 'country_list',
}
