export enum AllergySeverity {
  Low = 'Low',
  Moderate = 'Moderate',
  Severe = 'Severe',
}

export const ALLERGY_SEVERITY_SEQUENCE = [
  AllergySeverity.Low,
  AllergySeverity.Moderate,
  AllergySeverity.Severe,
];
