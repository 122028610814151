import axiosInstance from '~/api';
import {ApiRoute} from '~/constants/api';
import {
  MedicalHistory,
  MedicalHistoryResponseData,
  MedicalHistoryVariables,
} from './types';

export async function getMedicalHistory(
  params: MedicalHistoryVariables
): Promise<{
  success: boolean;
  data: {
    medicalHistory: MedicalHistory[];
    allergyCount: number;
    medicationCount: number;
  };
  message: string;
}> {
  const {patientId} = params;
  const response = await axiosInstance.get<MedicalHistoryResponseData>(
    ApiRoute.MedicalHistory,
    {
      params: {
        patient_id: patientId,
      },
    }
  );

  return {
    success: response.data.success,
    data: {
      medicalHistory: response.data.data.medical_history.map(info => ({
        key: info.key,
        title: info.title,
        completedOn: info.completed_on,
        totalCount: info.total_count,
        completedCount: info.completed_count,
      })),
      allergyCount: response.data.data.allergy_count,
      medicationCount: response.data.data.medication_count,
    },
    message: response.data.message,
  };
}
