import {Box, Button, Typography} from '@mui/material';
import {useFormik} from 'formik';
import Input from '$components/Input';
import {registerWithStoneyGateViewStyles} from '../../styles';
import OtpInput from '../OtpInput';
import {FormEvent, useCallback, useEffect, useRef, useState} from 'react';
import {
  BookingDataUpdater,
  BookingView,
  RegisterWithStoneygateViewChoices,
} from '../../types';
import './styles.scss';

import {useResendOtp, useSendOtp, useValidateOtp} from '$modules/booking/hooks';
import {OtpFormValues, validationSchemaOtp} from '../OtpInput/schema';
import ArrowNarrowRight from '$assets/svgs/arrow-narrow-right.svg?react';
import CloseXSvg from '$assets/svgs/x-close.svg?react';

interface EmailOtpFormProps {
  isDisabled: boolean | undefined;
  isEmailVerifiedAlready: boolean | undefined;
  setBookingChoices: BookingDataUpdater;
  setActiveView: (view: BookingView) => void;
  setIsValidateOtpSuccess: (value: boolean) => void;
  bookingChoices: RegisterWithStoneygateViewChoices;
  setIsEmailCleared: (value: boolean) => void;
}

function EmailVerificationForm(props: EmailOtpFormProps) {
  const {
    isDisabled,
    isEmailVerifiedAlready,
    setBookingChoices,
    setActiveView,
    setIsValidateOtpSuccess,
    bookingChoices,
    // setIsEmailCleared,
  } = props;

  const {
    isPending: isOtpPending,
    requestSendOtp,
    isSuccess: isOtpSentSuccess,
    reset: resetSendOTPQuery,
  } = useSendOtp();

  const {requestValidateOtp, isSuccess: isValidateOtpSuccess} = useValidateOtp(
    setActiveView,
    setBookingChoices
  );

  const {disableSubmit: disableResendSubmit, requestResendOtp} = useResendOtp();

  const [error, setError] = useState<string | null>(null);

  // const [resetButtonClick, setResetButtonClick] = useState(false);

  const otpInputRefs = useRef<Array<HTMLInputElement | null>>(
    Array(4).fill(null)
  );

  useEffect(() => {
    setIsValidateOtpSuccess(isValidateOtpSuccess);
  });

  const handleValidateOtp = () => {
    try {
      const {otp} = formik.values;
      const otpNumber = Number(otp);
      requestValidateOtp(formik.values.email, otpNumber);
      setError(null);
    } catch (error) {
      console.error('Error validating otp:', error);
      setError('Failed to validate OTP. Please try again.');
    }
  };

  const formik = useFormik<OtpFormValues>({
    initialValues: {
      otp: '',
      email: bookingChoices.registered?.email || '',
    },
    validationSchema: validationSchemaOtp,
    onSubmit: () => {
      handleValidateOtp();
    },
  });

  const handleFormikChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name} = e.target;
    if (name === 'email') {
      formik.setValues({otp: '', email: formik.values.email});
    }
    formik.handleChange(e);
  };

  const handleResetButtonClick = () => {
    // setResetButtonClick(!resetButtonClick);
    formik.setFieldValue('email', '');
    // setIsEmailCleared(true);
    // Clear patientCarerDetails & patientContactDetail
    resetSendOTPQuery();
    setBookingChoices(draft => {
      if (draft.registered) {
        draft.registered.email = undefined;
        draft.registered.isEmailvalidated = false;
      }
      draft.patientCarerDetails = undefined;
      draft.patientContactDetail = undefined;
    });
  };

  const handleSendOtp = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const {email} = formik.values;
    requestSendOtp(email);
    formik.setValues({otp: '', email: formik.values.email});
  };

  const handleResendOtp = async () => {
    try {
      const {email} = formik.values;
      formik.setValues({otp: '', email: formik.values.email});
      requestResendOtp(email);
      setError(null);
    } catch {
      setError('Failed to resend OTP. Please try again.');
    }
  };

  const handleOtpChange = (index: number, value: string) => {
    if (value.match(/^\d+$/) || value === '') {
      const {otp} = formik.values;

      const newOtp = otp.split('');
      newOtp[index] = value;
      formik.setFieldValue('otp', newOtp.join(''));
      if (value && index < 3) {
        otpInputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleOtpKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Backspace' && !formik.values.otp[index] && index > 0) {
      otpInputRefs.current[index - 1]?.focus();
    }
  };
  const disableSendOtpButton =
    !formik.values.email ||
    !!formik.errors.email ||
    isEmailVerifiedAlready ||
    isOtpSentSuccess;

  const {values, handleSubmit} = formik;

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e) formik.handleBlur(e);
    },
    [formik]
  );

  useEffect(() => {
    if (values.otp.length === 4) {
      handleSubmit();
    }
  }, [handleSubmit, values.otp.length]);

  return (
    <form onSubmit={handleSendOtp}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={2}
      >
        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          alignItems="flex-end"
          mt={2}
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <Box sx={registerWithStoneyGateViewStyles.inputContainer}>
              <Typography variant="body1" mb={'20px'}>
                Enter your email address to receive a One-Time Password (OTP).
              </Typography>
              <Input
                name="email"
                placeholder="Email address"
                value={formik.values.email}
                onBlur={handleBlur}
                onChange={handleFormikChange}
                touched={formik.touched.email}
                endIcon={
                  isEmailVerifiedAlready || isOtpSentSuccess ? (
                    <CloseXSvg />
                  ) : null
                }
                endIconClassname="end-icon-style"
                endIconClick={handleResetButtonClick}
                error={Boolean(formik.errors.email)}
                disabled={isDisabled || isOtpSentSuccess}
                helperText={
                  formik.errors.email && formik.touched.email
                    ? formik.errors.email
                    : ''
                }
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" gap="2">
          <Button
            disabled={disableSendOtpButton}
            variant="contained"
            color="primary"
            type="submit"
          >
            {isOtpPending ? 'Sending...' : 'Send OTP'}
            <ArrowNarrowRight style={{marginLeft: '0.5rem'}} />
          </Button>
        </Box>
        {isOtpSentSuccess && (
          <Box display="flex" flexDirection="column" gap={1} mt={1}>
            <Box display="flex" flexDirection="row" gap={1}>
              <Typography
                variant="body1"
                sx={registerWithStoneyGateViewStyles.otpInfoText}
              >
                Enter the OTP sent to {formik.values.email}. The OTP will expire
                in 15 minutes. If you didn't receive it, check your spam folder
                or request a new one.
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" gap={1}>
              <Box display="flex" flexDirection="row" gap={2}>
                {[...Array(4)].map((_, index) => (
                  <OtpInput
                    key={index}
                    value={formik.values.otp[index] || ''}
                    onChange={value => handleOtpChange(index, value)}
                    onKeyDown={e => handleOtpKeyDown(index, e)}
                    maxLength={1}
                    autoFocus={index === 0}
                    inputRef={el => (otpInputRefs.current[index] = el)}
                  />
                ))}
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                gap={1}
                alignItems="center"
              ></Box>
            </Box>
            {
              <Box display="flex" flexDirection="row" gap={1} mt={2}>
                <Typography variant="body1">Didn't receive the OTP?</Typography>
                {disableResendSubmit ? (
                  <Typography variant="body1" color="red">
                    Resending...
                  </Typography>
                ) : (
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={registerWithStoneyGateViewStyles.sendAgainBtn}
                    onClick={handleResendOtp}
                  >
                    Click here to send again
                  </Typography>
                )}
              </Box>
            }
          </Box>
        )}
        {error && (
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        )}
      </Box>
    </form>
  );
}

export default EmailVerificationForm;
