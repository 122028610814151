import theme from '$modules/frontdesk/theme';
import {scrollbarStyles} from '$styles/index';

export const eoSection = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflowX: 'hidden',
  overflowY: 'auto',
  ...scrollbarStyles,

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
};

export const eoMainHeader = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 6),
  borderBottom: '1px solid',
  borderBottomColor: '#E2e5e8',
};

export const eoDivider = {
  padding: theme.spacing(1),
  borderBottom: '1px solid',
  borderBottomColor: '#E2e5e8',
};

export const eoMainSection = {
  background: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflowX: 'hidden',
  overflowY: 'auto',
  ...scrollbarStyles,
};

export const eoSectionHead = {
  padding: theme.spacing(2, 3),
};

export const eoContentSection = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflowX: 'hidden',
  overflowY: 'auto',

  padding: theme.spacing(4),
  gridGrap: theme.spacing(3),

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(2, 6),
  },
};

export const eoDetailsSection = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,

  gridGap: theme.spacing(3),

  [theme.breakpoints.up('lg')]: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },
};

export const eoContentFooter = {
  display: 'flex',
  flexDirection: 'column',
  gridGap: theme.spacing(2),
  padding: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gridGap: theme.spacing(0),
    padding: theme.spacing(4, 0),
  },
};

export const eoDetailsContent = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflowX: 'hidden',
  overflowY: 'auto',
  gridGap: theme.spacing(3),
};

export const patientDetailsCard = {
  display: 'flex',
  flexDirection: 'column',
  gridGap: theme.spacing(3),
};

export const eoCard = {
  background: theme.palette.common.white,
  borderRadius: '0.75rem',
  padding: theme.spacing(2, 3),
  border: '1px solid #47546726',
};

export const eoPenidngListCard = {
  background: theme.palette.common.white,
  borderRadius: '0.75rem',
  padding: theme.spacing(2, 3),
  border: '1px solid #47546726',
};

export const threeColGrid = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridGap: theme.spacing(3),

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
};

export const fourColGrid = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridGap: theme.spacing(2),

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
};

export const fourColGridSpan = {
  [theme.breakpoints.up('md')]: {
    gridColumn: 'span 4',
  },
};
export const bookingDetailsText = {
  marginBottom: '1.125rem',
};
export const IconStyle = {
  marginRight: '0.5rem',
};
export const patientNameTooltip = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '10rem',
  cursor: 'pointer',
};
export const doctorNameTooltip = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '10rem',
  cursor: 'pointer',
};

export const ellipsisStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  maxWidth: '100%',
};

export const timeBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '70px',
};

export const infoStackStyle = {
  display: 'flex',
  alignItems: 'center',
  flex: 1,
};

export const infoBoxStyle = {
  flex: 1,
};

export const buttonBoxStyle = {
  flex: '0 0 auto',
};

export const timeTypographyStyle = {
  fontSize: '1.2rem',
  whiteSpace: 'nowrap',
};
export const arrowIconStyle = {
  marginLeft: '0.5rem',
};

export const eoBack = {
  display: 'flex',
  gap: 1,
  alignItems: 'center',
  cursor: 'pointer',
};

export const bookingDetailsContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
};

export const eoPatientContactContainer = {
  ...eoCard,
  height: '100%',
  ...patientDetailsCard,
};

export const eoFinancialDetailsContainer = {
  display: 'flex',
  flexDirection: 'column',
};

export const patientDetailContainer = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
};
