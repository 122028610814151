import React from 'react';
import {RadioGroup, FormControlLabel, Radio, TextField} from '@mui/material';
import {FormikProps, FormikValues} from 'formik';
import MultiTextInput from '$components/MultiTextInput';
import {EllipseRadioChecked} from '$assets/svgs';
import Input from '$components/Input';
import '../styles.scss';
import {getErrorMessage, getSubQuestionnaireId} from '../utils';
import {
  Question,
  QuestionConditionType,
  QuestionType,
} from '~/services/api/MedicalHistory/Questionnaire/types';

interface QuestionnaireComponentProps {
  formik: FormikProps<FormikValues>;
  question: Question;
}

const QuestionnaireComponent: React.FC<QuestionnaireComponentProps> = ({
  question,
  formik,
}) => {
  switch (question.question_type) {
    case QuestionType.Radio:
      return (
        <div>
          <RadioGroup
            row
            name={`${question.id}`}
            value={formik.values[`${question.id}`] || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {question.options?.map((option: string) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio checkedIcon={<EllipseRadioChecked />} />}
                label={option}
              />
            ))}
          </RadioGroup>
          {question.sub_questions &&
            question.sub_questions.length > 0 &&
            question.sub_questions.map(subQuestion => {
              const shouldShowSubQuestion =
                (formik.values[question.id.toString()] &&
                  subQuestion.condition === QuestionConditionType.All) ||
                subQuestion.condition === formik.values[`${question.id}`];
              if (!shouldShowSubQuestion) return null;
              return (
                <div key={subQuestion.id} className="mt-4">
                  <p className="font-inter text-lg text-tertiary leading-7 tracking-tight">
                    {subQuestion.question}
                  </p>
                  <TextField
                    multiline
                    placeholder={subQuestion.placeholder || ''}
                    name={getSubQuestionnaireId({question, subQuestion})}
                    value={
                      formik.values[
                        getSubQuestionnaireId({question, subQuestion})
                      ] || ''
                    }
                    onChange={formik.handleChange}
                    className="daily-living-hospital-experience-sub-question-text-field"
                  />
                  <p className="text-error justify-center items-center">
                    {getErrorMessage(
                      formik.errors[
                        getSubQuestionnaireId({question, subQuestion})
                      ]
                    )}
                  </p>
                </div>
              );
            })}
        </div>
      );
    case QuestionType.MultiText:
      return <MultiTextInput name={`${question.id}`} formik={formik} />;
    default:
      return (
        <div>
          <Input
            name={`${question.id}`}
            className="w-full max-w-sm mt-1"
            value={formik.values[`${question.id}`] || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={question.placeholder || ''}
          />
        </div>
      );
  }
};

export default QuestionnaireComponent;
