import {getFilterOptions} from '$api/frontdesk';
import {useQuery} from '@tanstack/react-query';
import FilterOptionsComponent from './component';
import FetchError from '$components/FetchError';
import FilterOptionSkeleton from './FilterOptionSkeleton';

interface FilterOptionsContainerProps {
  containStatus: boolean;
  containAppointmentTypes: boolean;
  containConsultants: boolean;
  filters: {
    status?: string[];
    appointmentTypes: number[];
    consultants: number[];
  };
  onFilterChange: (
    type: 'status' | 'appointmentTypes' | 'consultants',
    id: number | string
  ) => void;
  onApply: () => void;
  onReset: () => void;
}

const FilterOptionsContainer: React.FC<FilterOptionsContainerProps> = ({
  containStatus,
  containAppointmentTypes,
  containConsultants,
  filters,
  onFilterChange,
  onApply,
  onReset,
}) => {
  const {data, isPending, isError} = useQuery({
    queryKey: ['filter-options'],
    queryFn: getFilterOptions,
  });

  if (isPending) return <FilterOptionSkeleton />;
  if (isError) return <FetchError />;

  return (
    <FilterOptionsComponent
      data={data.data}
      containStatus={containStatus}
      containAppointmentTypes={containAppointmentTypes}
      containConsultants={containConsultants}
      filters={filters}
      onFilterChange={onFilterChange}
      onApply={onApply}
      onReset={onReset}
    />
  );
};

export default FilterOptionsContainer;
