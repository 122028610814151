export const CONSULTATION_TITLE = 'Select a consultation';
export const SURGEY_CONSULTATION_TITLE = 'Please select a surgery';

interface ITitleMap {
  [key: string]: string;
  new: string;
  surgery: string;
}
export const TITLE_MAP: ITitleMap = {
  new: CONSULTATION_TITLE,
  surgery: SURGEY_CONSULTATION_TITLE,
};
