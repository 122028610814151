import {createSlice} from '@reduxjs/toolkit';
import {AuthState} from './types';
import {
  loadAuthReducer,
  resetAuthReducer,
  updateRefreshPendingReducer,
} from './reducers';

const initialState: AuthState = {
  refreshToken: null,
  token: null,
  user: null,
  isRefreshPending: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loadAuth: loadAuthReducer,
    resetAuth: resetAuthReducer,
    updateRefreshPending: updateRefreshPendingReducer,
  },
});

export const {loadAuth, resetAuth, updateRefreshPending} = authSlice.actions;
export default authSlice.reducer;
