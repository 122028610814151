import axiosInstance from '~/api';
import {ApiRoute} from '~/constants/api';

interface AxiosRequestConfig {
  params?: Record<string, string | number | boolean>;
  headers?: Record<string, string>;
  responseType?: 'blob';
}
export const downloadPDF = async (
  url: string,
  filename: string,
  config: AxiosRequestConfig = {}
) => {
  const response = await axiosInstance.get(url, {
    ...config,
    headers: {
      ...config.headers,
      Format: 'pdf',
    },
    responseType: 'blob',
  });

  const blob = new Blob([response.data], {type: 'application/pdf'});
  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(downloadUrl);
};

export const downloadMedicalHistoryPDF = (
  patientId: number,
  questionnaireType: string
) =>
  downloadPDF(
    ApiRoute.PatientQuestionnaire,
    `patient_${questionnaireType}_report.pdf`,
    {
      params: {patient_id: patientId, type: questionnaireType},
    }
  );
