import {Box, Button} from '@mui/material';
import StopCircleIcon from '$assets/svgs/stop-circle.svg?react';
import '../styles.scss';
import {
  eoCheckInTime,
  eoCheckInTimeContainer,
  eoCheckInTimerContainer,
  eoStopTimer,
} from './styles';

type CheckInTimerComponentProps = {
  currentMinutes: number;
  currentSeconds: number;
  handleEndTimer: () => void;
  showStopTimer: boolean;
};

const CheckInTimerComponent = (props: CheckInTimerComponentProps) => {
  const {currentMinutes, currentSeconds, handleEndTimer, showStopTimer} = props;

  return (
    <Box sx={eoCheckInTimerContainer}>
      <Box>Time since check in</Box>
      <Box sx={eoCheckInTimeContainer} className="primary">
        <Box sx={eoCheckInTime}>{currentMinutes}</Box>:
        <Box sx={eoCheckInTime}>{currentSeconds}</Box>
      </Box>
      {showStopTimer && (
        <Button
          onClick={handleEndTimer}
          sx={eoStopTimer}
          variant="text"
          color="error"
        >
          <StopCircleIcon className="stop-timer-icon" /> Stop Timer
        </Button>
      )}
    </Box>
  );
};

export default CheckInTimerComponent;
