import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import FrontdeskButton from '$components/FrontdeskButton';
import './styles.scss';

interface LogoutDialogProps {
  open: boolean;
  handleClose: () => void;
  onLogout: () => void;
}

function LogoutDialog(props: LogoutDialogProps) {
  const {open, handleClose, onLogout} = props;

  const handleLogoutClick = () => {
    onLogout();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} className="logout-dialog">
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to log out?
      </DialogTitle>
      <DialogActions>
        <FrontdeskButton
          title="Cancel"
          variant="secondary"
          onClick={handleClose}
        />
        <FrontdeskButton
          title="Logout"
          variant="primary"
          onClick={handleLogoutClick}
        />
      </DialogActions>
    </Dialog>
  );
}

export default LogoutDialog;
