import {useEffect, useState} from 'react';
import {useImmer} from 'use-immer';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Header from './components/Header';
import SwitchActiveLayoutView from './components/SwitchActiveView';
import {BookingData, BookingView} from './types';
import {
  isChoicesConfirmationViewChoice,
  isAppSummaryViewChoice,
  handleBeforeUnload,
} from './utils';
import ChoicesConfirmationView from './views/ChoicesConfirmationView';
import TransitionView from './views/TransitionView';
import AppSummaryView from './views/AppSummaryView';
import theme from './theme';
import './styles.scss';
import {Box} from '@mui/material';
import styles from './styles';

function BookingScreen() {
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BookingScreenContent />
    </ThemeProvider>
  );
}

function BookingScreenContent() {
  const [activeView, setActiveView] = useState(BookingView.TransitionView);
  const [bookingData, setBookingData] = useImmer<BookingData>({});

  if (activeView === BookingView.TransitionView) {
    return <TransitionView setActiveView={setActiveView} />;
  }

  if (
    activeView === BookingView.ChoicesConfirmationView &&
    isChoicesConfirmationViewChoice(bookingData)
  ) {
    return (
      <ChoicesConfirmationView
        bookingChoices={bookingData}
        setActiveView={setActiveView}
        setBookingChoices={setBookingData}
      />
    );
  }

  if (
    activeView === BookingView.AppSummaryView &&
    isAppSummaryViewChoice(bookingData)
  ) {
    return (
      <AppSummaryView
        bookingChoices={bookingData}
        setActiveView={setActiveView}
        setBookingData={setBookingData}
      />
    );
  }

  return (
    <Box sx={styles.wrapper}>
      <Header setBookingData={setBookingData} setActiveView={setActiveView} />
      <SwitchActiveLayoutView
        bookingData={bookingData}
        setBookingData={setBookingData}
        activeView={activeView}
        setActiveView={setActiveView}
      />
    </Box>
  );
}

export default BookingScreen;
