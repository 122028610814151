import {useState} from 'react';
import NotificationsComponent from './component';
import {NotificationSubTab} from './constant';
import {useNotificationList, useNotificationMarkRead} from './hooks';

function Notifications() {
  const [activeTab, setActiveTab] = useState<NotificationSubTab>(
    NotificationSubTab.All
  );

  const {
    notifications,
    pendingRequestCount,
    totalNotifications,
    newBookingCount,
    appointmentCancelledCount,
    newPatientsCount,
    isLoading,
    isError,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useNotificationList({
    perPage: 20,
    type: activeTab === NotificationSubTab.All ? undefined : activeTab,
  });

  const {sendMarkReadRequest, isPending, disableSubmit} =
    useNotificationMarkRead();

  return (
    <NotificationsComponent
      sendMarkReadRequest={sendMarkReadRequest}
      isMarkReadPending={isPending}
      isMarkReadDisabled={disableSubmit}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      notifications={notifications}
      pendingRequestCount={pendingRequestCount}
      totalNotifications={totalNotifications}
      newBookingCount={newBookingCount}
      appointmentCancelledCount={appointmentCancelledCount}
      newPatientsCount={newPatientsCount}
      activeTab={activeTab}
      fetchNextPage={fetchNextPage}
      setActiveTab={setActiveTab}
      isLoading={isLoading}
      isError={isError}
    />
  );
}

export default Notifications;
