import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import styles from './styles';

function FetchError() {
  return (
    <Box sx={styles.container}>
      <Alert severity="error" variant="outlined">
        <AlertTitle>Network Error</AlertTitle>
        We encountered a network connection issue. Please try again later.
      </Alert>
    </Box>
  );
}

export default FetchError;
