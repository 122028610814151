import {useLocation, useNavigate} from 'react-router-dom';
import {Badge, Button} from '@mui/material';
import {Notification} from './types';
import {notificationIcon} from './constant';
import {getRoute, RouteSection} from '$utils/route';
import {RoutePath} from '$constants/routes';
import {timeFromNow} from '$utils/date';
import {useNotificationMarkRead} from './hooks';

interface NotificationCardProps {
  notification: Notification;
}

function NotificationCard(props: NotificationCardProps) {
  const {notification} = props;
  const {title, description, unread, createdAt, notificationType} =
    notification;

  const navigate = useNavigate();
  const {sendMarkReadRequest} = useNotificationMarkRead();
  const location = useLocation();
  const pathName = location.pathname;

  const timeFromNowString = timeFromNow(createdAt);

  const handleNotificationClick = () => {
    navigate(
      getRoute(
        RouteSection.Calendar,
        RoutePath.staff.calendar.bookingDetails,
        notification.resourceId
      ),
      {state: {backRoute: pathName}}
    );

    sendMarkReadRequest({notificationId: notification.id});
  };

  return (
    <Button
      className="w-full justify-start items-start normal-case text-left flex gap-4 py-3.5 border-bottom"
      onClick={handleNotificationClick}
    >
      <div className="flex items-start justify-center">
        <Badge
          invisible={!unread}
          color="error"
          badgeContent=" "
          variant="dot"
          anchorOrigin={{vertical: 'top', horizontal: 'left'}}
        >
          <div className="p-2 bg-primary-medium rounded-lg flex primary">
            {notificationIcon[notificationType]}
          </div>
        </Badge>
      </div>
      <div>
        <p className="text-sm font-bold primary">{title}</p>
        <p className="text-sm font-normal text-primary">{description}</p>
        <p className="text-xs text-grey-dark font-normal">
          {timeFromNowString}
        </p>
      </div>
    </Button>
  );
}

export default NotificationCard;
