import {FormikErrors, FormikTouched} from 'formik';
import {EditBookingFormData} from '../schema';
import {Box, MenuItem} from '@mui/material';
import {useCareTypeHook} from '../../OfflineBooking/hooks/useCareTypeHook';
import {consultantSelectionViewStyles} from '$modules/booking/styles';
import {PreferredTime, SubCare} from '../types';
import {useConsultants} from '../../../hooks';
import {usePreferredTimeHook} from '../../hooks/usePreferredTimeHook';
import {getNameInitials} from '~/modules/booking/utils';
import {Consultant} from '~/modules/booking/types';
import FetchError from '~/components/FetchError';
import Loading from '~/components/Loading';
import '../styles.scss';
import AppointmentDetailsComponent from './component';
import FinancialDetailsIndex from '../FinancialDetails.tsx';

type AppointmentDetailsIndex = {
  values: EditBookingFormData;
  errors: FormikErrors<EditBookingFormData>;
  touched: FormikTouched<EditBookingFormData>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  setFieldTouched: (field: string, touched?: boolean) => void;
  setFieldValue: (field: string, value: unknown) => void;
  appointmentId: string;
  careTypeId: number;
};

const AppointmentDetailsIndex = (props: AppointmentDetailsIndex) => {
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    touched,
    values,
    careTypeId,
  } = props;

  const {
    isPending: isSubCareTypePending,
    data: subCareTypes,
    isError: isSubCareTypeError,
  } = useCareTypeHook(careTypeId);
  const {
    isPending: isPreferredTimePending,
    data: preferredTimes,
    isError: isPreferredTimeError,
  } = usePreferredTimeHook();

  const {
    isLoading: isConsultantPending,
    consultants,
    isError: isConsultantError,
  } = useConsultants({
    subCareId: values?.appointment?.sub_care_type_id,
    appointmentTypeId: values?.appointment?.appointment_type_id,
  });

  if (isConsultantPending || isSubCareTypePending || isPreferredTimePending) {
    return <Loading />;
  }

  if (
    !subCareTypes?.data ||
    !preferredTimes?.data ||
    isConsultantError ||
    isPreferredTimeError ||
    isSubCareTypeError
  ) {
    return <FetchError />;
  }

  const renderPreferredTimeMenuItem = (item: PreferredTime) => {
    return (
      <MenuItem
        key={item.id}
        value={item.id.toString()}
        sx={consultantSelectionViewStyles.menuItemContainer}
      >
        <span>{item.display_name}</span>
      </MenuItem>
    );
  };

  const renderSubCareTypeMenuItem = (item: SubCare) => {
    return (
      <MenuItem
        key={item.id}
        value={item.id.toString()}
        sx={consultantSelectionViewStyles.menuItemContainer}
      >
        <span>{item.title}</span>
      </MenuItem>
    );
  };

  const handleFieldChange = (
    field: string,
    value: number | string | boolean | Date
  ) => {
    if (field === 'preferred_time') {
      const preferredTime = preferredTimes!.data.find(ele => ele.id === value);
      const preferredTimeName = preferredTime?.name || 'All time';
      setFieldValue('appointment.preferred_time', preferredTimeName);
    } else {
      field = `appointment.${field}`;
      setFieldValue(field, value);
    }
  };

  const renderSubCareTypeValue = (value: string) => {
    if (!value) return 'Select surgery type';
    const item = subCareTypes!.data.find(it => it.id.toString() === value);
    if (!item) {
      return '';
    }
    return (
      <Box sx={consultantSelectionViewStyles.menuItemContainer}>
        <span>{item.title}</span>
      </Box>
    );
  };

  const renderConsultantMenuItem = (item: Consultant) => {
    return (
      <MenuItem
        key={item.id}
        value={item.id.toString()}
        sx={consultantSelectionViewStyles.valueContainer}
      >
        {item.profileUrl ? (
          <img
            src={item.profileUrl}
            style={consultantSelectionViewStyles.menuItemProfileImage}
          />
        ) : (
          <span style={consultantSelectionViewStyles.menuItemInitial}>
            {getNameInitials(item.fullName)}
          </span>
        )}
        <span>{item.fullName}</span>
      </MenuItem>
    );
  };

  const renderConsultantValue = (value: string) => {
    if (!value) return 'Select a consultant';
    const item = consultants.find(it => it.id.toString() === value);
    if (!item) {
      handleFieldChange('doctor_id', '');
      return '';
    }

    return (
      <Box sx={consultantSelectionViewStyles.valueContainer}>
        {item.profileUrl ? (
          <img
            src={item.profileUrl}
            style={consultantSelectionViewStyles.menuItemProfileImage}
          />
        ) : (
          <span style={consultantSelectionViewStyles.menuItemInitial}>
            {getNameInitials(item.fullName)}
          </span>
        )}
        <span>{item.fullName}</span>
      </Box>
    );
  };

  return (
    <div className="flex flex-col flex-[5] gap-6">
      <AppointmentDetailsComponent
        consultants={consultants}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        handleFieldChange={handleFieldChange}
        preferredTimes={preferredTimes.data}
        renderCareTypeMenuItem={renderSubCareTypeMenuItem}
        renderCareTypeValue={renderSubCareTypeValue}
        renderConsultantMenuItem={renderConsultantMenuItem}
        renderConsultantValue={renderConsultantValue}
        renderPreferredTimeValue={renderConsultantValue}
        renderPreferredTimeMenuItem={renderPreferredTimeMenuItem}
        values={values}
        renderSubCareTypeMenuItem={renderSubCareTypeMenuItem}
        renderSubCareTypeValue={renderSubCareTypeValue}
        subCareTypes={subCareTypes.data}
        touched={touched}
      />
      <FinancialDetailsIndex
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched}
        values={values}
      />
    </div>
  );
};

export default AppointmentDetailsIndex;
