import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import SelectYear from './SelectYear';
import Input from '$components/Input';
import DatePicker from '$components/DatePicker';
import {EllipseRadioChecked} from '$assets/svgs';
import {FormikErrors, FormikTouched, FormikValues} from 'formik';
import {getErrorMessage, getSubQuestionnaireId} from '../utils';
import moment, {momentDate} from '$utils/date';
import useTimezone from '../../../../../../../../hooks/useTimezone';
import {
  Question,
  QuestionConditionType,
  QuestionType,
} from '~/services/api/MedicalHistory/Questionnaire/types';

interface QuestionCardProps {
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  values: FormikValues;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleFieldChange: (fieldId: string, value: string) => void;
  handleDateChange: (fieldId: string, date: Date | null) => void;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  fieldName: string;
  id: number;
  question: Question;
  startIndex: number;
  renderYearItem: (item: string) => JSX.Element;
  renderYearValue: (value: string, placeholder: string) => JSX.Element;
}

function QuestionCard(props: QuestionCardProps) {
  const {
    id,
    question,
    startIndex,
    renderYearItem,
    renderYearValue,
    fieldName,
    errors,
    touched,
    values,
    handleFieldChange,
    handleDateChange,
    handleChange,
    handleBlur,
  } = props;

  const timezone = useTimezone();

  const fieldError = errors[fieldName];
  const fieldTouched = touched[fieldName];
  const questionId = question.id.toString();
  const fieldValue = values[questionId];
  const {question_type: questionType, placeholder} = question;
  const inputFieldTypes: QuestionType[] = [
    QuestionType.TextField,
    QuestionType.NumericField,
  ];

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2">
        <p>{startIndex + id + 1}.</p>
        <p>{question.question}</p>
      </div>
      {questionType === QuestionType.Radio && (
        <div className="">
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="answer"
              name={questionId}
              value={fieldValue}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {question.options?.map((option: string, idx: number) => (
                <FormControlLabel
                  key={questionId + idx}
                  value={option}
                  control={<Radio checkedIcon={<EllipseRadioChecked />} />}
                  label={option}
                />
              ))}
            </RadioGroup>
            {fieldTouched && fieldError && (
              <p className="text-color justify-center items-center">
                {getErrorMessage(fieldError)}
              </p>
            )}
          </FormControl>
          {question.sub_questions &&
            question.sub_questions.length > 0 &&
            question.sub_questions.map((subQuestion, idx) => {
              const shouldShowSubQuestion =
                (values[questionId] &&
                  subQuestion.condition === QuestionConditionType.All) ||
                subQuestion.condition === values[questionId];
              if (!shouldShowSubQuestion) return null;
              return (
                <div
                  key={questionId + idx}
                  className="flex flex-col gap-2 items-start"
                >
                  <div className="flex items-center gap-1">
                    {values[getSubQuestionnaireId({question, subQuestion})] && (
                      <p className="text-[#475467] text-sm">Year:</p>
                    )}
                    <SelectYear
                      placeholder={subQuestion.placeholder || ''}
                      name={values[subQuestion.id.toString()] || ''}
                      value={
                        values[
                          getSubQuestionnaireId({question, subQuestion})
                        ] || ''
                      }
                      items={Array.from({length: 100}, (_, i) =>
                        (moment().year() - i).toString()
                      )}
                      onBlur={handleBlur}
                      renderValue={value => renderYearValue(value, 'Year')}
                      renderItem={renderYearItem}
                      onChange={value =>
                        handleFieldChange(
                          getSubQuestionnaireId({question, subQuestion}),
                          value
                        )
                      }
                    />
                  </div>
                  {touched[getSubQuestionnaireId({question, subQuestion})] &&
                    errors[getSubQuestionnaireId({question, subQuestion})] && (
                      <p className="text-error justify-center items-center">
                        {getErrorMessage(
                          errors[getSubQuestionnaireId({question, subQuestion})]
                        )}
                      </p>
                    )}
                </div>
              );
            })}
        </div>
      )}
      {inputFieldTypes.includes(questionType) && (
        <Input
          name={questionId}
          value={fieldValue || ''}
          onChange={event => handleFieldChange(questionId, event.target.value)}
          onBlur={handleBlur}
          placeholder={placeholder || ''}
        />
      )}
      {questionType === QuestionType.Date && (
        <DatePicker
          name={questionId}
          value={fieldValue ? momentDate(fieldValue).toDate() : null}
          onChange={date => handleDateChange(questionId, date)}
          placeholder={placeholder || ''}
          disableFuture
          timezone={timezone}
        />
      )}
    </div>
  );
}

export default QuestionCard;
