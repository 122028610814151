import {
  PostCreateNoteVariables,
  PutModifyNoteVariables,
  deleteNote,
  getNotesList,
  postCreateNote,
  putModifyNote,
} from '$api/patientList/notes';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {Note} from './types';
import {handleErrorResponse} from '$utils/axios';
import {ToastType} from '$components/Toast/constant';
import toast from '$utils/toast';
import {useMemo, useState} from 'react';

export function useNotes(patientId: number) {
  const {data, isLoading, isError} = useQuery({
    queryKey: ['patient-details-notes', patientId],
    queryFn: () => getNotesList({patientId}),
  });

  const notes: Note[] = useMemo(() => {
    const dataNotes = data?.data || [];
    return dataNotes.map(note => ({
      id: note.id,
      createdBy: {
        id: note.created_by.id,
        profileUrl: note.created_by.profile_url,
        fullName: note.created_by.full_name,
      },
      appointmentId: note.appointment_id,
      patientId: note.patient_id,
      title: note.title,
      description: note.description,
      createdAt: new Date(note.created_at),
      documentUrl: note.document_url,
    }));
  }, [data]);

  return {notes, isLoading, isError};
}

export function useCreateNote(onSuccess: () => void) {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const queryClient = useQueryClient();

  const {mutate, isPending} = useMutation({
    mutationKey: ['patient-details-create-note'],
    mutationFn: postCreateNote,
    onSuccess: data => {
      toast(ToastType.Success, 'Create note', data.message);
      queryClient.invalidateQueries({queryKey: ['patient-details-notes']});
      onSuccess();
    },
    onError: error => handleErrorResponse(error, 'Create note'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendCreateNoteRequest = (variables: PostCreateNoteVariables) => {
    setDisableSubmit(true);
    mutate(variables);
  };

  return {sendCreateNoteRequest, isPending, disableSubmit};
}

export function useModifyNote(onSuccess: () => void) {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const queryClient = useQueryClient();

  const {mutate, isPending} = useMutation({
    mutationKey: ['patient-details-modify-note'],
    mutationFn: putModifyNote,
    onSuccess: data => {
      toast(ToastType.Success, 'Modify note', data.message);
      queryClient.invalidateQueries({queryKey: ['patient-details-notes']});
      onSuccess();
    },
    onError: error => handleErrorResponse(error, 'Modify note'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendModifyNoteRequest = (variables: PutModifyNoteVariables) => {
    setDisableSubmit(true);
    mutate(variables);
  };

  return {sendModifyNoteRequest, isPending, disableSubmit};
}

export const useDeleteNotes = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {mutate: deleteNoteRequest, isPending: isDeletingNote} = useMutation({
    mutationKey: ['patient-details-delete-note'],
    mutationFn: deleteNote,
    onMutate: () => setDisableSubmit(true),
    onSuccess: data => {
      toast(ToastType.Success, 'Modify note', data.message);
      queryClient.invalidateQueries({queryKey: ['patient-details-notes']});
      onSuccess();
    },
    onError: error => handleErrorResponse(error, 'Delete note'),
    onSettled: () => {
      setDisableSubmit(false);
    },
  });

  return {deleteNoteRequest, isDeletingNote, disableSubmit};
};
