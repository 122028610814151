import {type FormatterInput} from '@fullcalendar/core';

export enum CalendarView {
  Day = 'timeGridDay',
  Week = 'timeGridWeek',
  Month = 'dayGridMonth',
}

export const MONTH_YEAR: FormatterInput = {
  month: 'short',
  year: 'numeric',
};

export const WEEK_DAY_MONTH_YEAR: FormatterInput = {
  weekday: 'long',
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};
