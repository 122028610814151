import {RefObject} from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridView from '@fullcalendar/daygrid';
import timeGridView from '@fullcalendar/timegrid';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import Divider from '@mui/material/Divider';
import ListIcon from '$assets/svgs/list.svg?react';
// import AlarmCloskIcon from '$assets/svgs/alarm-clock.svg?react';
import PlusSquareIcon from '$assets/svgs/plus-square.svg?react';
import SearchRefractionIcon from '$assets/svgs/search-refraction.svg?react';
import FrontdeskButton from '$components/FrontdeskButton';
import FrontdeskSearchbox from '$components/FrontdeskSearchBox';
import CalendarRangeChange from './CalendarRangeChange';
import ConsultantAutocomplete from './ConsultantAutocomplete';
import CalendarViewSwitch from './CalendarViewSelection';
import {CalendarView, MONTH_YEAR, WEEK_DAY_MONTH_YEAR} from './constant';
import {
  CustomContentGenerator,
  DayHeaderContentArg,
  EventClickArg,
  EventContentArg,
  SlotLabelContentArg,
} from '@fullcalendar/core';
import {CalendarEvent, Consultant, FilterOptions} from '../../type';
import {useNavigate} from 'react-router-dom';
import OfflineBookingContainer from '../../Calendar/OfflineBooking';
import './styles.scss';
import CalendarFilterMenu from './CalendarFilterMenu';
import {Updater} from 'use-immer';
import {RoutePath} from '$constants/routes';
import {RouteSection, getRoute} from '$utils/route';
import {getTimeOfDay} from '$utils/date';
import useAuthUser from '../../../../hooks/useAuthUser';
import {UserRoleName} from '$modules/admin/constant';

interface CalendarViewComponentProps {
  timezone: string;
  calendarEvents: CalendarEvent[];
  selectedConsultant: Consultant | null;
  setSelectedConsultant: (value: Consultant | null) => void;
  searchText: string;
  setSearchText: (value: string) => void;
  consultants: Consultant[];
  calendarRef: RefObject<FullCalendar>;
  currentView: CalendarView;
  rangeText?: string;
  title?: string;
  today: Date;
  newAppointmentModalOpen: boolean;
  handleNewAppointmentModalOpen: () => void;
  changeCalendarView: (view: CalendarView) => void;
  handleCalendarPrev: () => void;
  handleCalendarNext: () => void;
  renderDayEvent: CustomContentGenerator<EventContentArg>;
  renderMonthEvent: CustomContentGenerator<EventContentArg>;
  renderWeekEvent: CustomContentGenerator<EventContentArg>;
  renderWeekHeaderContent: CustomContentGenerator<DayHeaderContentArg>;
  renderSlotLabelContent: CustomContentGenerator<SlotLabelContentArg>;
  handleEventClick: (args: EventClickArg) => void;
  selectedFilterOptions: FilterOptions;
  setSelectedFilterOptions: Updater<FilterOptions>;
}

function CalendarViewComponent(props: CalendarViewComponentProps) {
  const navigate = useNavigate();
  const {fullName, roleName} = useAuthUser()!;
  const {
    timezone,
    calendarEvents,
    selectedConsultant,
    setSelectedConsultant,
    searchText,
    setSearchText,
    consultants,
    calendarRef,
    currentView,
    rangeText,
    title,
    changeCalendarView,
    handleCalendarPrev,
    handleCalendarNext,
    renderDayEvent,
    renderMonthEvent,
    renderWeekEvent,
    renderWeekHeaderContent,
    renderSlotLabelContent,
    selectedFilterOptions,
    setSelectedFilterOptions,
    handleEventClick,
    newAppointmentModalOpen,
    handleNewAppointmentModalOpen,
  } = props;

  const firstName =
    roleName === UserRoleName.Doctor
      ? fullName.split(' ')[1]
      : fullName.split(' ')[0];

  const handleAppointmentListNavigation = () => {
    navigate(
      getRoute(RouteSection.Calendar, RoutePath.staff.calendar.appointments)
    );
  };

  const handlePendingRequestsNavigation = () => {
    navigate(
      getRoute(RouteSection.Calendar, RoutePath.staff.calendar.pendingRequests)
    );
  };

  let greetingMessage = `Good ${getTimeOfDay()}`;
  if (firstName) greetingMessage += `, ${firstName}`;
  greetingMessage += '! 👋';

  const isFrontdesk = roleName === UserRoleName.Frontdesk;
  // const isConsultant = roleName === UserRoleName.Doctor;

  return (
    <div className="calendar-view-container flex flex-col flex-1 p-10">
      <div>
        <h1 className="calendar-view-heading text-2xl mb-5 font-semibold">
          {greetingMessage}
        </h1>
        <div className="flex">
          <div className="flex-1">
            <h2 className="calendar-view-sub-heading text-lg font-semibold mb-2">
              Calendar
            </h2>
            <p className="calendar-view-description text-sm font-normal">
              Stay on top of your daily schedule and keep informed about the
              latest booking requests.
            </p>
          </div>
          <div className="flex gap-6 items-center">
            <FrontdeskButton
              title="Appointment list"
              startIcon={<ListIcon />}
              onClick={handleAppointmentListNavigation}
            />
            <FrontdeskSearchbox
              value={searchText}
              placeholder="Search by name"
              startIcon={<SearchRefractionIcon />}
              onChange={setSearchText}
            />
          </div>
        </div>

        <Divider className="mt-4 mb-6" />

        <div className="flex justify-between mb-1.5">
          <div className="flex flex-1 gap-x-3 gap-y-8 flex-wrap">
            <CalendarRangeChange
              rangeText={rangeText}
              onPrev={handleCalendarPrev}
              onNext={handleCalendarNext}
            />

            {/*isConsultant && (
              <FrontdeskButton
                title="Your schedule"
                startIcon={<AlarmCloskIcon />}
                onClick={() => {}}
              />
            )*/}

            {isFrontdesk && (
              <div className="min-w-56">
                <ConsultantAutocomplete
                  value={selectedConsultant}
                  showAllConsultants={currentView === CalendarView.Day}
                  consultants={consultants}
                  onChange={setSelectedConsultant}
                />
              </div>
            )}
            <CalendarViewSwitch
              view={currentView}
              changeView={changeCalendarView}
            />
          </div>
          <div className="flex gap-3 items-start">
            {isFrontdesk && (
              <FrontdeskButton
                variant="tertiary"
                title="Pending requests"
                onClick={handlePendingRequestsNavigation}
              />
            )}

            {isFrontdesk && (
              <FrontdeskButton
                title="New appointment"
                startIcon={<PlusSquareIcon />}
                onClick={handleNewAppointmentModalOpen}
              />
            )}
            <CalendarFilterMenu
              selectedFilterOptions={selectedFilterOptions}
              setSelectedFilterOptions={setSelectedFilterOptions}
            />
          </div>
          {newAppointmentModalOpen && (
            <OfflineBookingContainer
              newAppointmentModalOpen={newAppointmentModalOpen}
              handleNewAppointmentModalOpen={handleNewAppointmentModalOpen}
            />
          )}
        </div>

        <h3 className="calendar-view-month-day-title text-lg font-semibold my-3">
          {title?.replace(/, /g, ' ')}
        </h3>
      </div>

      <div className="calendar-view-full-calendar-container flex-1">
        <FullCalendar
          ref={calendarRef}
          timeZone={timezone}
          nowIndicator={true}
          plugins={[momentTimezonePlugin, dayGridView, timeGridView]}
          headerToolbar={false}
          firstDay={1}
          height="100%"
          initialView={isFrontdesk ? CalendarView.Day : CalendarView.Week}
          eventClick={handleEventClick}
          allDaySlot={false}
          eventDisplay="block"
          slotLabelContent={renderSlotLabelContent}
          slotDuration={{minute: 5}}
          views={{
            timeGridDay: {
              dayHeaders: false,
              slotDuration: {minute: 20},
              eventContent: renderDayEvent,
              titleFormat: WEEK_DAY_MONTH_YEAR,
            },
            timeGridWeek: {
              eventContent: renderWeekEvent,
              dayHeaderContent: renderWeekHeaderContent,
              titleFormat: MONTH_YEAR,
            },
            dayGridMonth: {
              eventContent: renderMonthEvent,
              titleFormat: MONTH_YEAR,
            },
          }}
          events={calendarEvents}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }}
        />
      </div>
    </div>
  );
}

export default CalendarViewComponent;
