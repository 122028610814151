import {dateToDDMMYYYY} from '$utils/date';
import {truncateText} from '$modules/frontdesk/utils';

interface PatientDetail {
  label: string;
  value: string;
  labelVariant: 'subtitle1' | 'subtitle2';
  titleVariant: 'subtitle1' | 'subtitle2';
  containsTooltip: boolean;
  helperFunction: ((value: string) => string) | null;
  className: string;
}

export const PATIENT_DETAILS_MAP: PatientDetail[] = [
  {
    label: 'Gender',
    value: 'gender',
    labelVariant: 'subtitle1',
    titleVariant: 'subtitle2',
    containsTooltip: false,
    helperFunction: null,
    className: '',
  },

  {
    label: 'Date of birth',
    value: 'dob',
    labelVariant: 'subtitle1',
    titleVariant: 'subtitle2',
    containsTooltip: false,
    helperFunction: dateToDDMMYYYY,
    className: 'mb-1',
  },

  {
    label: 'Self booking',
    value: 'self_booking',
    labelVariant: 'subtitle1',
    titleVariant: 'subtitle2',
    containsTooltip: false,
    helperFunction: null,
    className: 'mb-1',
  },

  {
    label: 'GP or optician referral',
    value: 'referred_by_physician',
    labelVariant: 'subtitle1',
    titleVariant: 'subtitle2',
    containsTooltip: false,
    helperFunction: null,
    className: 'mb-1',
  },

  {
    label: 'Address',
    value: 'line1',
    labelVariant: 'subtitle1',
    titleVariant: 'subtitle2',
    containsTooltip: true,
    helperFunction: truncateText,
    className: 'mb-1',
  },

  {
    label: 'Post code',
    value: 'postcode',
    labelVariant: 'subtitle1',
    titleVariant: 'subtitle2',
    containsTooltip: false,
    helperFunction: null,
    className: 'mb-1',
  },

  {
    label: 'Town or city',
    value: 'city_name',
    labelVariant: 'subtitle1',
    titleVariant: 'subtitle2',
    containsTooltip: true,
    helperFunction: null,
    className: 'mb-1',
  },

  {
    label: 'Country',
    value: 'country_name',
    labelVariant: 'subtitle1',
    titleVariant: 'subtitle2',
    containsTooltip: true,
    helperFunction: null,
    className: 'mb-1',
  },

  {
    label: 'Reason of booking',
    value: 'reason',
    labelVariant: 'subtitle1',
    titleVariant: 'subtitle2',
    containsTooltip: true,
    helperFunction: truncateText,
    className: 'mb-1',
  },
];
