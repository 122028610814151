import {useLocation, useNavigate} from 'react-router-dom';
import {
  patientNameTooltip,
  doctorNameTooltip,
  timeBoxStyle,
  infoStackStyle,
  infoBoxStyle,
  buttonBoxStyle,
  timeTypographyStyle,
} from '$modules/frontdesk/styles';
import {Box, Stack, Divider, Typography, Button, Tooltip} from '@mui/material';
import {timeAgo} from '$modules/frontdesk/utils';
import {AppointmentInfo} from '$modules/frontdesk/type';
import ArrowNarrowRight from '$assets/svgs/arrow-narrow-right.svg?react';
import {RouteSection, getRoute} from '$utils/route';
import {RoutePath} from '$constants/routes';
import {dateToDDMMYYYY, dateToTimeShort} from '$utils/date';
import {formatFullName} from '$utils/helper';
import Loading from '$components/Loading';
import NoDataFound from '$components/NoDataFound';

interface PendingListItemProps {
  appointments: AppointmentInfo[];
  isLoading: boolean;
}

const PendingListItem: React.FC<PendingListItemProps> = ({
  appointments,
  isLoading,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;

  const createNavigateHandler = (appointmentId: number) => {
    return () =>
      navigate(
        getRoute(
          RouteSection.Calendar,
          RoutePath.staff.calendar.bookingDetails,
          appointmentId
        ),
        {state: {backRoute: pathName}}
      );
  };

  return (
    <div
      className="flex h-screen w-full flex-col gap-4"
      style={{minWidth: 'max-content'}}
    >
      {isLoading ? (
        <Loading color="info" />
      ) : appointments.length > 0 ? (
        <div className="flex w-full flex-col gap-4">
          {appointments.map(ele => (
            <div
              key={ele.appointment?.id}
              className="w-full min-w-fit cursor-pointer rounded-xl border-[1px] border-solid border-[#47546726] bg-white px-4 py-2"
            >
              <Stack
                direction="row"
                spacing={2}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Box sx={timeBoxStyle}>
                  <Typography variant="dayTitle" sx={timeTypographyStyle}>
                    {ele.appointment?.createdAt
                      ? timeAgo(new Date(ele.appointment.createdAt))
                      : '-'}
                  </Typography>
                  <Typography variant="body1">ago</Typography>
                </Box>
                <Stack direction="row" spacing={2} sx={infoStackStyle}>
                  <Box sx={infoBoxStyle}>
                    <Tooltip title={ele.appointment.patientName} arrow>
                      <Typography variant="h4" sx={patientNameTooltip} noWrap>
                        {formatFullName(ele.appointment?.patientName) || '-'}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Box sx={infoBoxStyle}>
                    <Tooltip title={ele.appointment.doctorName} arrow>
                      <Typography variant="body1" sx={doctorNameTooltip} noWrap>
                        {ele.appointment?.doctorName
                          ? formatFullName(ele.appointment?.doctorName)
                          : 'No preference'}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Box sx={infoBoxStyle}>
                    <Typography variant="body1" noWrap>
                      {ele.appointment?.date
                        ? dateToDDMMYYYY(ele.appointment.date)
                        : '-'}
                    </Typography>
                  </Box>
                  <Box sx={infoBoxStyle}>
                    <Typography variant="body1" noWrap>
                      {dateToTimeShort(ele.appointment?.startTime) || '-'}
                    </Typography>
                  </Box>
                  <Box sx={buttonBoxStyle}>
                    <Button
                      variant="contained"
                      onClick={createNavigateHandler(ele.appointment.id)}
                    >
                      Manage
                      <ArrowNarrowRight style={{marginLeft: '0.5rem'}} />
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            </div>
          ))}
        </div>
      ) : (
        !isLoading && (
          <div className="no-data-found-wrapper">
            <NoDataFound
              dataTitle="No Appointments yet!"
              dataDescription=" Appointment will appear once the patient books the
                      appointment"
            />
          </div>
        )
      )}
    </div>
  );
};

export default PendingListItem;
