import {MEDICATION_HISTORY_SKELETON_ITEM_COUNT} from '$modules/frontdesk/constant';

const MedicationHistorySkeleton = () => {
  const skeletonItems = Array.from({
    length: MEDICATION_HISTORY_SKELETON_ITEM_COUNT,
  });
  return (
    <div className="animate-pulse space-y-4 h-64 overflow-y-auto">
      {skeletonItems.map((_, index) => (
        <div
          key={index}
          className="flex items-center justify-center mt-2 h-13 p-4 bg-white shadow rounded-lg"
        >
          <div className="flex items-center space-x-4 w-full">
            <div className="bg-gray-200 h-8 w-8 rounded-full"></div>
            <div className="bg-gray-200 h-6 w-4/5 rounded"></div>
          </div>
          <div className="ml-4">
            <div className="bg-gray-200 h-5 w-5 rounded-md"></div>
          </div>
          <div className="ml-4">
            <div className="ml-2">
              <div className="bg-gray-100 h-5 w-5 rounded-full"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default MedicationHistorySkeleton;
