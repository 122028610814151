import axiosInstance from '~/services/api/axios';
import {
  POSTSessionRefreshResponseData,
  POSTSessionRefreshVariables,
} from './types';

export async function postSessionRefresh(
  variables: POSTSessionRefreshVariables
) {
  const response = await axiosInstance.post<POSTSessionRefreshResponseData>(
    '/sessions/refresh',
    {
      refresh_token: variables.refreshToken,
    }
  );

  return response.data;
}
