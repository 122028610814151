import {FormikErrors, FormikTouched} from 'formik';
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import RadioBox from '$components/RadioBox';
import CarerDetailForm from './CarerDetailForm';
import PatientDetailsForm from './PatientDetailForm';
import styles from '../../styles';
import {PatientCarerDetailsFormData} from './schema';
import theme from '$modules/booking/theme';

interface PatientCarerDetailFormProps {
  timezone: string;
  values: PatientCarerDetailsFormData;
  errors: FormikErrors<PatientCarerDetailsFormData>;
  touched: FormikTouched<PatientCarerDetailsFormData>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  setTouched: (touched: FormikTouched<PatientCarerDetailsFormData>) => void;
  setFieldTouched: (field: string) => void;
  setFieldValue: (field: string, value: unknown) => void;
  isPostCodeError: boolean;
}

function PatientCarerDetailForm(props: PatientCarerDetailFormProps) {
  const {
    timezone,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setTouched,
    setFieldTouched,
    setFieldValue,
    isPostCodeError,
  } = props;

  const handleIsCarerDifferentChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    const value = e.target.value;
    let newValue = undefined;

    if (value === String(true)) {
      newValue = true;
    } else if (value === String(false)) {
      newValue = false;
    }

    setFieldValue('isCarerDifferent', newValue);
    setTouched({...touched, carer: undefined});
  };

  return (
    <>
      <PatientDetailsForm
        timezone={timezone}
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        isPostCodeError={isPostCodeError}
      />

      <Box flexDirection="column" display="flex" gap={2}>
        <Typography variant="h3" sx={styles.stepsTypeHead}>
          Are you booking on behalf of someone else?
        </Typography>
        <RadioGroup
          row
          sx={{display: 'flex', gap: '2rem'}}
          onChange={handleIsCarerDifferentChange}
          value={String(values.isCarerDifferent)}
        >
          <RadioBox
            value={String(true)}
            label="Yes"
            error={touched.isCarerDifferent && Boolean(errors.isCarerDifferent)}
          />
          <RadioBox
            value={String(false)}
            label="No"
            error={touched.isCarerDifferent && Boolean(errors.isCarerDifferent)}
          />
        </RadioGroup>
        {touched.isCarerDifferent && errors.isCarerDifferent && (
          <Typography
            color={theme.palette.error.main}
            fontWeight={400}
            fontSize={14}
          >
            {errors.isCarerDifferent}
          </Typography>
        )}
      </Box>

      {values.isCarerDifferent && (
        <CarerDetailForm
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
        />
      )}
    </>
  );
}

export default PatientCarerDetailForm;
