import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {PostUserVariables, getUsersList, postCreateUser} from '$api/admin';
import {useState} from 'react';
import {ToastType} from '$components/Toast/constant';
import toast from '$utils/toast';
import {handleErrorResponse} from '$utils/axios';

export function useUsers() {
  const {data, isLoading} = useQuery({
    queryKey: ['admin-users-list'],
    queryFn: getUsersList,
  });

  return {isLoading, users: data?.users || []};
}

export function useCreateUser(onSuccess: () => void) {
  const queryClient = useQueryClient();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {isPending, mutate} = useMutation({
    mutationKey: ['admin-user-create'],
    mutationFn: postCreateUser,
    onSuccess: data => {
      toast(ToastType.Success, 'User created', data.message);
      queryClient.invalidateQueries({queryKey: ['admin-users-list']});
      onSuccess();
    },
    onError: error => handleErrorResponse(error, 'Create user'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendCreateUserRequest = (variables: PostUserVariables) => {
    setDisableSubmit(true);
    mutate(variables);
  };

  return {isPending, disableSubmit, sendCreateUserRequest};
}
