class LocalStorage {
  static AUTH_USER_DATA = 'auth_user_data';
  static CALENDAR_VIEW_SELECTED_CONSULTANT =
    'staff_calendar_selected_consultant';
  static SUBSCRIPTION_APPLICATION_SERVER_PUBLIC_KEY =
    'subscription_application_server_public_key';
  static APPOINTMENT_LIST_SELECTED_DATE = 'appointment_list_selected_date';
  static BOOKING_BLOCKED_DATE = 'booking_blocked_date';
  static BOOKING_BLOCKED_SLOT = 'booking_blocked_slot';

  static setItem(key: string, value: string) {
    return localStorage.setItem(key, value);
  }

  static getItem(key: string) {
    return localStorage.getItem(key);
  }

  static removeItem(key: string) {
    return localStorage.removeItem(key);
  }

  static removeAll() {
    return localStorage.clear();
  }
}

export default LocalStorage;
