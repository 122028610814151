import {PropsWithChildren} from 'react';
import {UserRoleName} from '$modules/admin/constant';
import useAuthUser from '../../hooks/useAuthUser';
import {Navigate} from 'react-router-dom';
import {RoutePath} from '$constants/routes';

interface ProtectedRouteProps extends PropsWithChildren {
  roles: UserRoleName[];
  fallbackRoute?: string;
}

function ProtectedRoute(props: ProtectedRouteProps) {
  const {children, roles, fallbackRoute} = props;

  const user = useAuthUser();

  if (user && roles.includes(user.roleName)) {
    return children;
  }

  return <Navigate to={fallbackRoute || RoutePath.loginRoute} />;
}

export default ProtectedRoute;
