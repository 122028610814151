import {usePatientQuestionnaire} from '~/services/api/MedicalHistory/Questionnaire/hooks';
import Loading from '~/components/Loading';
import FetchError from '~/components/FetchError';
import LifeStyleQuestionnaireForm from './form';
import {QuestionnaireType} from '~/services/api/MedicalHistory/Questionnaire/types';

interface LifeStyleQuestionnaireProps {
  patientId: number;
  currentPage: number;
  itemsPerPage: number;
  handlePageChange: (page: number) => void;
  handleBack: () => void;
}

const LifeStyleQuestionnaire = (props: LifeStyleQuestionnaireProps) => {
  const {currentPage, handleBack, handlePageChange, itemsPerPage, patientId} =
    props;

  const {
    submitPatientQuestionnaire,
    disableSubmit,
    questionnaire,
    error,
    isCreatingPatientQuestionnaire,
    isLoading,
  } = usePatientQuestionnaire({
    patientId,
    historyType: QuestionnaireType.Lifestyle,
  });

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <FetchError />;
  }

  return (
    <LifeStyleQuestionnaireForm
      currentPage={currentPage}
      disableSubmit={disableSubmit || isCreatingPatientQuestionnaire}
      handleBack={handleBack}
      handlePageChange={handlePageChange}
      isCreatingPatientQuestionnaire={isCreatingPatientQuestionnaire}
      itemsPerPage={itemsPerPage}
      questionnaire={questionnaire}
      questionnaireType={QuestionnaireType.Lifestyle}
      submitPatientQuestionnaire={submitPatientQuestionnaire}
    />
  );
};

export default LifeStyleQuestionnaire;
