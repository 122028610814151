import CircularProgress from '@mui/material/CircularProgress';
import LeftAngleArrowIcon from '~/assets/svgs/left-angle-arrow.svg?react';
import RightArrowIcon from '~/assets/svgs/right-arrow.svg?react';
import BookingButton from '~/modules/booking/components/BookingButton';
import DisclaimerComponent from '../DisclaimerComponent';

interface FooterProps {
  disableBack?: boolean;
  disableContinue?: boolean;
  continueLoading?: boolean;
  onBackClick?: () => void;
  onContinueClick?: () => void;
  continueButtonLabel?: string;
}

function Footer(props: FooterProps) {
  const {
    disableBack = false,
    disableContinue = false,
    continueLoading = false,
    onBackClick,
    onContinueClick,
    continueButtonLabel,
  } = props;

  return (
    <>
      {(onBackClick || onContinueClick) && (
        <div className="flex justify-between items-center">
          {onBackClick && (
            <BookingButton
              variant="secondary"
              disabled={disableBack}
              startIcon={<LeftAngleArrowIcon />}
              onClick={onBackClick}
              testId="booking-footer-back"
            >
              Back
            </BookingButton>
          )}
          {onContinueClick && (
            <BookingButton
              disabled={disableContinue}
              endIcon={
                continueLoading ? (
                  <CircularProgress
                    size="1rem"
                    className="text-white opacity-80"
                  />
                ) : (
                  <RightArrowIcon />
                )
              }
              onClick={onContinueClick}
              testId="booking-footer-continue"
            >
              {continueButtonLabel ? continueButtonLabel : 'Continue'}
            </BookingButton>
          )}
        </div>
      )}
      <DisclaimerComponent />
    </>
  );
}

export default Footer;
