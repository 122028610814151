import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useImmer} from 'use-immer';
import {Button, Dialog, IconButton} from '@mui/material';
import XCloseIcon from '$assets/svgs/x-close.svg?react';
import PlusSquareIcon from '$assets/svgs/plus-square.svg?react';
import FrontdeskButton from '$components/FrontdeskButton';
import {getNewID} from '$utils/helper';
import AllergyForm from './AllergyForm';
import {AllergySeverity} from '../constant';
import {useModifyAllergy} from '../../hooks';
import {Allergy, AllergyFormData} from '../types';
import useAuthUser from '../../../../../../../hooks/useAuthUser';
import '../styles.scss';

interface AllergyDialogProps {
  open: boolean;
  onClose: () => void;
  allergies: Allergy[];
}

function AllergyDialog(props: AllergyDialogProps) {
  const {open, onClose, allergies} = props;

  const user = useAuthUser();
  const params = useParams();
  const patientId = Number(params.patientId);
  const [allergyList, setAllergyList] = useImmer<AllergyFormData[]>([
    {name: '', level: '', isNew: getNewID()},
  ]);

  const handleSuccess = () => {
    onClose();
  };

  const {
    sendModifyAllergyRequest,
    isPending,
    disableSubmit: disableSubmitRequest,
  } = useModifyAllergy(handleSuccess);

  const handleClose = () => {
    onClose();
  };

  const formItems = allergyList
    .filter(all => !all.destroy)
    .map(allergy => {
      const handleNameChange = (value: string) => {
        setAllergyList(draft => {
          const all = draft.find(a =>
            a.isNew ? a.isNew === allergy.isNew : a.id === allergy.id
          );

          if (all) all.name = value;
        });
      };

      const handleSeverityChange = (value: AllergySeverity | '') => {
        setAllergyList(draft => {
          const all = draft.find(a =>
            a.isNew ? a.isNew === allergy.isNew : a.id === allergy.id
          );

          if (all) all.level = value;
        });
      };

      const handleRemoveAllergy = () => {
        setAllergyList(draft => {
          const all = draft.find(a =>
            a.isNew ? a.isNew === allergy.isNew : a.id === allergy.id
          );

          if (all) all.destroy = true;
        });
      };

      return (
        <AllergyForm
          key={allergy.isNew || allergy.id}
          allergy={allergy}
          setName={handleNameChange}
          setSeverity={handleSeverityChange}
          onRemove={handleRemoveAllergy}
        />
      );
    });

  const handleAddAllergy = () => {
    setAllergyList(draft => {
      draft.push({name: '', level: '', isNew: getNewID()});
    });
  };

  const handleSaveAllergy = () => {
    sendModifyAllergyRequest({
      hospitalId: user?.hospitalId,
      patientId: patientId,
      allergies: [...allergies, ...allergyList],
    });
  };

  useEffect(() => {
    setAllergyList(() => [{name: '', level: '', isNew: getNewID()}]);
  }, [allergies, setAllergyList, open]);

  const disableSubmit = allergyList.reduce((acc, obj) => {
    if (acc) return true;

    if (!obj.level || !obj.name) {
      return true;
    }

    return false;
  }, false);

  return (
    <Dialog className="allergy-dialog" open={open} onClose={handleClose}>
      <div className="header">
        <h2 className="title">Add allergy</h2>
        <IconButton onClick={handleClose}>
          <XCloseIcon />
        </IconButton>
      </div>
      <div className="body">{formItems}</div>
      <div className="actions">
        <Button
          className="add-allergy-button"
          startIcon={<PlusSquareIcon />}
          onClick={handleAddAllergy}
        >
          Add allergy
        </Button>

        <div className="main-actions">
          <FrontdeskButton
            variant="secondary"
            title="Cancel"
            onClick={handleClose}
          />
          <FrontdeskButton
            variant="primary"
            title="Save"
            loading={isPending}
            disabled={disableSubmit || disableSubmitRequest}
            onClick={handleSaveAllergy}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default AllergyDialog;
