import React from 'react';
import {TextField, IconButton} from '@mui/material';
import {FormikValues} from 'formik';
import FrontdeskButton from '$components/FrontdeskButton';
import DeleteIconBox from '$assets/svgs/deleteicon.svg';
import './styles.scss';
import {BUTTON_TEXTS} from '$modules/frontdesk/PatientList/constant';

interface MultiTextInputProps {
  name: string;
  formik: FormikValues;
}

const MultiTextInput: React.FC<MultiTextInputProps> = ({name, formik}) => {
  const fields: string[] = Array.isArray(formik.values[name])
    ? formik.values[name]
    : [];

  const handleAddField = (): void => {
    const newFields: string[] = [...fields, ''];
    formik.setFieldValue(name, newFields);
  };

  const handleChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const newFields: string[] = [...fields];
    newFields[index] = event.target.value;
    formik.setFieldValue(name, newFields);
  };

  const handleDeleteField = (index: number): void => {
    const newFields: string[] = fields.filter((_, i) => i !== index);
    formik.setFieldValue(name, newFields);
  };

  return (
    <div>
      {fields.map((value: string, index: number) => (
        <div key={index} className="multi-text-field-container">
          <TextField
            multiline
            value={value}
            onChange={e => handleChange(index, e)}
            placeholder="Add hospital admission"
            className="multi-text-field"
            fullWidth
            margin="normal"
          />
          <IconButton
            onClick={() => handleDeleteField(index)}
            aria-label="delete"
            className="delete-button"
            size="small"
          >
            <img src={DeleteIconBox} alt="Delete" />
          </IconButton>
        </div>
      ))}
      <FrontdeskButton
        title={BUTTON_TEXTS.ADD_HOSPITAL_ADMISSION}
        onClick={handleAddField}
        className="add-button"
      />
    </div>
  );
};

export default MultiTextInput;
