import Dialog from '@mui/material/Dialog';
import CalendarApprovedIcon from '$assets/svgs/calendar-approved.svg?react';
import XCloseIcon from '$assets/svgs/x-close.svg?react';
import UserSquareIcon from '$assets/svgs/user-square.svg?react';
import CalendarCheckIcon from '$assets/svgs/calendar-check-1.svg?react';
import SyringeIcon from '$assets/svgs/syringe.svg?react';
import './styles.scss';
import {IconButton} from '@mui/material';
import {AppointmentDetail} from '../../type';
import FrontdeskButton from '$components/FrontdeskButton';

interface ConfirmBookingDialogProps {
  open: boolean;
  onClose: () => void;
  appointmentDetails: AppointmentDetail;
  selectedDate: Date;
}

function ConfirmBookingDialog(props: ConfirmBookingDialogProps) {
  const {appointmentDetails, open, selectedDate, onClose} = props;
  const {doctor, careType} = appointmentDetails;
  const dateString = selectedDate.toLocaleDateString('en-GB');

  return (
    <Dialog open={open} className="confirm-booking-dialog">
      <div className="header-container">
        <div className="calender-container">
          <CalendarApprovedIcon />
        </div>
        <IconButton onClick={onClose}>
          <XCloseIcon />
        </IconButton>
      </div>
      <h2 className="text-2xl font-semibold">Booking confirmed</h2>
      <div className="info-container">
        <div className="info-cell">
          <UserSquareIcon />
          <p className="info-label">Consultant</p>
          <p className="info-value">{doctor?.fullName || '-'}</p>
        </div>
        <div className="info-cell">
          <CalendarCheckIcon />
          <p className="info-label">Date</p>
          <p className="info-value">{dateString}</p>
        </div>
        <div className="info-cell">
          <SyringeIcon />
          <p className="info-label">Appointment</p>
          <p className="info-value">{careType?.title || '-'}</p>
        </div>
      </div>
      <div className="action-container">
        <FrontdeskButton title="Okay" onClick={onClose} />
      </div>
    </Dialog>
  );
}

export default ConfirmBookingDialog;
