import {AppointmentInfo} from '$modules/frontdesk/type';
import UpcomingEventsComponent from './component';

interface UpcomingEventsProps {
  upcomingEvents: AppointmentInfo[];
}

function UpcomingEvents(props: UpcomingEventsProps) {
  const {upcomingEvents} = props;

  return <UpcomingEventsComponent upcomingEvents={upcomingEvents} />;
}

export default UpcomingEvents;
