import Input from '$components/Input';
import PaymentCardIcon from '$components/PaymentCardIcon';
import SelectInput from '$components/SelectInput';
import {Insurance, PaymentCard} from '$modules/booking/types';
import {INSURANCE_OPTIONS} from '$modules/frontdesk/constant';
import {PatientInsuranceDetailFormData} from './schema';
import {FormikErrors, FormikTouched} from 'formik';

interface PaymentDetailsComponentProps {
  insuranceProviderList: Insurance[];
  cards: PaymentCard[];
  values: PatientInsuranceDetailFormData;
  errors: FormikErrors<PatientInsuranceDetailFormData>;
  touched: FormikTouched<PatientInsuranceDetailFormData>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleSelfPayingChange: (value: string) => void;
  handlePaymentMethodChange: (value: string) => void;
  renderPaymentValue: (value: string) => React.ReactNode;
  renderPaymentItem: (item: string) => React.ReactNode;
  renderMenuItem: (item: Insurance) => React.ReactNode;
  renderValue: (value: string) => React.ReactNode;
}
const PaymentDetailsComponent: React.FC<PaymentDetailsComponentProps> = ({
  insuranceProviderList,
  cards,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  handleSelfPayingChange,
  handlePaymentMethodChange,
  renderPaymentValue,
  renderPaymentItem,
  renderMenuItem,
  renderValue,
}) => {
  const selfPaying = values.selfPaying;

  const cardItems = cards.map(card => (
    <div key={card.id} className="payment-card">
      <PaymentCardIcon type={card.name} />
    </div>
  ));

  return (
    <>
      <h2 className="dialog-title">Payment details</h2>
      <div className="payment-details-container">
        <div className="input-container">
          <SelectInput
            name="selfPaying"
            value={
              selfPaying === null
                ? ''
                : selfPaying
                  ? 'Self paying'
                  : 'Not self paying'
            }
            items={INSURANCE_OPTIONS}
            renderValue={renderPaymentValue}
            renderItem={renderPaymentItem}
            onBlur={handleBlur}
            onChange={handleSelfPayingChange}
          />
        </div>
        {!selfPaying && (
          <div className="private-insurance-container">
            <div className="private-insurance-text">
              Private insurance details
            </div>
            <div className="private-insurance-form-container">
              <div className="input-container">
                <SelectInput
                  name="insuranceId"
                  items={insuranceProviderList}
                  renderValue={renderValue}
                  renderItem={renderMenuItem}
                  value={String(values.insuranceId) || ''}
                  onBlur={handleBlur}
                  onChange={handlePaymentMethodChange}
                />
              </div>
              <div className="input-container">
                <Input
                  name="membershipNumber"
                  placeholder="Membership number"
                  value={values.membershipNumber || ''}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched?.membershipNumber}
                  error={Boolean(errors?.membershipNumber)}
                  helperText={
                    touched?.membershipNumber && errors?.membershipNumber
                      ? errors?.membershipNumber
                      : ''
                  }
                />
              </div>
            </div>
          </div>
        )}

        {selfPaying && (
          <div className="private-insurance-container">
            <div className="private-insurance-text">
              We accept the following cards
            </div>
            <div className="private-insurance-form-container">
              <div className="payment-cards-container">{cardItems}</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentDetailsComponent;
