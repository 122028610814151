import {FormikErrors, FormikTouched} from 'formik';
import {EditPatientDetailsFormData} from './schema';
import Input from '$components/Input';
import SelectInput from '$components/SelectInput';
import {GENDER_ITEMS} from './constants';
import {Typography} from '@mui/material';
import PhoneNumberInput from '$modules/booking/components/PhoneNumberInput';
import theme from '$modules/frontdesk/theme';
import DatePicker from '$components/DatePicker';
import {City, Country} from '$modules/booking/types';
import useTimezone from '../../../../../../hooks/useTimezone';

interface EditPatientDetailsFormProps {
  values: EditPatientDetailsFormData;
  errors: FormikErrors<EditPatientDetailsFormData>;
  touched: FormikTouched<EditPatientDetailsFormData>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleFieldChange: (
    field: string,
    defaultValue: unknown
  ) => (value: unknown) => void;
  cities: City[];
  renderCityValue: (value: string) => JSX.Element;
  renderCityItem: (item: {code: string; name: string}) => JSX.Element;
  countries: Country[];
  countryCode: string;
  renderCountryValue: (value: string) => JSX.Element;
  renderCountryItem: (item: {code: string; name: string}) => JSX.Element;
  renderItem: (item: string) => JSX.Element;
  renderValue: (value: string, placeholder: string) => JSX.Element;
}

function EditPatientDetailsForm(props: EditPatientDetailsFormProps) {
  const {
    values,
    errors,
    touched,
    cities,
    countries,
    countryCode,
    handleBlur,
    handleChange,
    handleFieldChange,
    renderCityItem,
    renderCityValue,
    renderCountryItem,
    renderCountryValue,
    renderItem,
    renderValue,
  } = props;

  const timezone = useTimezone();

  return (
    <div className="edit-container">
      <div className="edit-left-view">
        <div className="form-item">
          <p> Date of Birth </p>
          <DatePicker
            isDOB
            disableFuture
            name="patient.dob"
            placeholder="Date of birth"
            value={values.patient.dob}
            onChange={handleFieldChange('dob', null)}
            timezone={timezone}
          />
          {touched.patient?.dob && errors.patient?.dob && (
            <div className="error-text">{errors.patient.dob}</div>
          )}
        </div>
        <div className="form-item">
          <p> Gender </p>
          <SelectInput
            name="patient.gender"
            value={values.patient.gender}
            items={GENDER_ITEMS}
            renderValue={value => renderValue(value, 'Gender')}
            renderItem={renderItem}
            onBlur={handleBlur}
            onChange={handleFieldChange('gender', '')}
          />
          {touched.patient?.gender && errors.patient?.gender && (
            <div className="error-text">{errors.patient.gender}</div>
          )}
        </div>

        <div className="form-item">
          <p> Email address </p>
          <Input
            name="patient.email"
            disabled
            placeholder="Email address"
            value={values.patient.email}
            onChange={handleChange}
            touched={touched.patient?.email}
            onBlur={handleBlur}
            error={Boolean(errors.patient?.email)}
            helperText={
              touched.patient?.email && errors.patient?.email
                ? errors.patient.email
                : ''
            }
          />
        </div>

        <div className="form-item">
          <p> Address line 1 </p>
          <Input
            name="patient.line1"
            placeholder="Address line 1"
            value={values.patient.line1}
            onChange={handleChange}
            touched={touched.patient?.line1}
            onBlur={handleBlur}
            error={Boolean(errors.patient?.line1)}
            helperText={
              touched.patient?.line1 && errors.patient?.line1
                ? errors.patient.line1
                : ''
            }
          />
        </div>
        <div className="form-item">
          <p> Address line 2 </p>
          <Input
            name="patient.line2"
            placeholder="Address line 2"
            value={values.patient.line2}
            onChange={handleChange}
            touched={touched.patient?.line2}
            onBlur={handleBlur}
            error={Boolean(errors.patient?.line2)}
            helperText={
              touched.patient?.line2 && errors.patient?.line2
                ? errors.patient.line2
                : ''
            }
          />
        </div>
      </div>
      <div className="edit-right-view">
        <div className="form-item">
          <p> Post code </p>
          <Input
            name="patient.postCode"
            placeholder="Postcode"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.patient.postCode}
            touched={touched.patient?.postCode}
            error={Boolean(errors.patient?.postCode)}
            helperText={
              touched.patient?.postCode && errors.patient?.postCode
                ? errors.patient.postCode
                : ''
            }
          />
        </div>
        <div className="form-item">
          <p> Country </p>
          <SelectInput
            name="patient.country"
            placeholder="Country"
            value={values.patient.country}
            items={countries}
            renderValue={renderCountryValue}
            renderItem={renderCountryItem}
            onBlur={handleBlur}
            onChange={handleFieldChange('country', '')}
            error={touched.patient?.country && Boolean(errors.patient?.country)}
          />
          {touched.patient?.country && errors.patient?.country && (
            <div className="error-text">{errors.patient.country}</div>
          )}
        </div>

        <div className="form-item">
          <p>Town or city</p>
          <SelectInput
            name="patient.city"
            value={values.patient.city}
            items={cities}
            placeholder="Town or city"
            renderValue={renderCityValue}
            renderItem={renderCityItem}
            onBlur={handleBlur}
            onChange={handleFieldChange('city', '')}
          />
          {touched.patient?.city && errors.patient?.city && (
            <div className="error-text">{errors.patient.city}</div>
          )}
        </div>

        <div className="form-item">
          <p> Phone number </p>
          <PhoneNumberInput
            name="patient.phoneNumber"
            value={values.patient.phoneNumber}
            country={countryCode}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {touched.patient?.phoneNumber && errors.patient?.phoneNumber && (
            <Typography
              variant="caption"
              color={theme.palette.error.main}
              fontWeight={400}
            >
              {errors.patient.phoneNumber}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditPatientDetailsForm;
