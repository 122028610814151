import {SxProps} from '@mui/material';

import theme from '$modules/booking/theme';

const styles: Record<string, SxProps> = {
  container: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    borderRadius: '0.5rem',
  },
  countryContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.common.white,
    borderRadius: '0.5rem',
    paddingLeft: '1rem',
  },
  input: {
    flex: 1,
    fontSize: '1rem',
    color: theme.palette.text.primary,
    fontWeight: 400,
    letterSpacing: '-0.75px',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.common.white,

    '& fieldset': {
      borderWidth: 0,
    },

    '& input': {
      padding: 0,
    },

    '& input::placeholder': {
      color: theme.palette.text.primary,
    },

    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },
    },
  },
};

export const hideSpinnerStyles = {
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
};

export default styles;
