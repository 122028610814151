import {useImmer} from 'use-immer';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import CloseCircleIcon from '$assets/svgs/close-circle.svg?react';
import CheckCircleIcon from '$assets/svgs/check-circle.svg?react';
import {
  ConsultantSelectionViewChoices,
  BookingDataUpdater,
  BookingData,
} from '../../types';
import styles from './styles';
import AppointmentTypeSection from './AppointmentTypeSection';

interface BookingChoiceDialogProps {
  open: boolean;
  bookingChoices: ConsultantSelectionViewChoices;
  setBookingChoices: BookingDataUpdater;
  onClose: () => void;
}

function BookingChoicesDialog(props: BookingChoiceDialogProps) {
  const {open, bookingChoices, setBookingChoices, onClose} = props;

  const [formChoices, setFormChoices] = useImmer<BookingData>(bookingChoices);

  const handleCloseButtonClick = () => {
    onClose();
    setFormChoices(() => bookingChoices);
  };

  const handleSave = () => {
    if (formChoices.appointment && formChoices.care && formChoices.subcare) {
      setBookingChoices(draft => {
        draft.appointment = formChoices.appointment;
        draft.care = formChoices.care;
        draft.subcare = formChoices.subcare;
      });
    }
    onClose();
  };

  const disableSaveButton = !(
    formChoices.appointment &&
    formChoices.care &&
    formChoices.subcare
  );

  return (
    <Dialog open={open} onClose={handleCloseButtonClick} sx={styles.wrapper}>
      <Button
        variant="text"
        sx={styles.closeButton}
        onClick={handleCloseButtonClick}
      >
        <CloseCircleIcon />
      </Button>
      <DialogContent sx={styles.body}>
        <Stack spacing={3}>
          <AppointmentTypeSection
            formChoices={formChoices}
            setFormChoices={setFormChoices}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={styles.actionsContainer}>
        <Button
          variant="contained"
          endIcon={<CheckCircleIcon />}
          disabled={disableSaveButton}
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BookingChoicesDialog;
