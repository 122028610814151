import {SxProps, Theme} from '@mui/material/styles';

export const otpInputStyle: SxProps<Theme> = {
  '& .MuiInputBase-input': {
    textAlign: 'center',
    fontSize: '1.6rem',
    padding: '6px 10px',
    width: '3rem',
    height: '3rem',
    backgroundColor: 'white',
  },
};
