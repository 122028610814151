import axiosInstance from '$api/index';
import {ApiRoute} from '$constants/api';

export interface deleteAppointmentVariables {
  token: string;
}

export interface getAppointmentDetailsVariables {
  token: string;
}

export interface DeleteAppointmentResponseData {
  success: boolean;
  message: string;
}

export interface GetAppointmentDetailsResponseData {
  success: boolean;
  message: string;
  data: {
    id: number;
    start_time: string | null;
    end_time: string | null;
    date: string;
    status: string;
    appointment_type_name: string;
    created_at: string;
    preferred_time: string;
    patient_name: string;
    doctor_name: string;
    confirm_at: string | null;
  };
}

export async function deleteAppointment(variables: deleteAppointmentVariables) {
  const {token} = variables;

  const response = await axiosInstance.delete<DeleteAppointmentResponseData>(
    ApiRoute.DeleteAppointment,
    {
      params: {
        token,
      },
    }
  );

  return response.data;
}

export async function getAppointmentDetails(
  variables: getAppointmentDetailsVariables
) {
  const {token} = variables;

  const response = await axiosInstance.get<GetAppointmentDetailsResponseData>(
    ApiRoute.ShowAppointment,
    {
      params: {
        token,
      },
    }
  );

  return response.data;
}
