import {SetStateAction} from 'react';
import {useOperationTheatreHook} from '../hooks/useOperationTheatreHook';
import {FormState} from '../types';
import {OfflineBookingStep} from '../constant';
import Loading from '$components/Loading';

interface OperationTheatreSectionProps {
  dateSelected: Date | null;
  selectedStartTime: Date | null;
  selectedEndTime: Date | null;
  selectedOperationTheatre: number;
  setActiveStep: React.Dispatch<SetStateAction<OfflineBookingStep>>;
  setOfflineBookingFormData: React.Dispatch<SetStateAction<FormState>>;
}

function OperationThreatreSection(props: OperationTheatreSectionProps) {
  const {
    dateSelected,
    selectedOperationTheatre,
    selectedStartTime,
    selectedEndTime,
    setOfflineBookingFormData,
  } = props;

  const {data: operationTheatres, isPending} = useOperationTheatreHook(
    dateSelected || undefined,
    selectedStartTime || undefined,
    selectedEndTime || undefined
  );

  const handleClick = (operationTheatreId: number) => {
    setOfflineBookingFormData((prevState: FormState) => ({
      appointment: {
        ...prevState.appointment,
        operation_theatre_id: operationTheatreId,
      },
    }));
  };

  return (
    <>
      <h2 className="dialog-title title-gap">Select an operating theatre</h2>
      <div className="operation-theatre-container">
        {isPending ? (
          <Loading />
        ) : (
          operationTheatres &&
          operationTheatres.data.length > 0 &&
          operationTheatres.data.map(items => (
            <div
              className={`${
                selectedOperationTheatre === items.id
                  ? 'operation-theatre-selected '
                  : ''
              } operation-theatre-card`}
              onClick={() => handleClick(items.id)}
              key={items.name}
            >
              <span className="operation-theatre-card-text">{items.name}</span>
            </div>
          ))
        )}
      </div>
    </>
  );
}

export default OperationThreatreSection;
