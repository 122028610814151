import {SxProps} from '@mui/material';
import theme from '$modules/booking/theme';

const styles: Record<string, SxProps> = {
  picker: {
    width: '100%',
    background: 'transparent',
    borderRadius: '0.5rem',
    border: 'none',

    '& .MuiInputBase-root .MuiInputBase-input': {
      background: theme.palette.common.white,
      padding: '0.75rem 1.5rem',
    },

    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },

    '& .MuiInputBase-root': {
      background: theme.palette.common.white,
      borderRadius: '0.5rem',
      paddingRight: '20px',

      '&:focus-visible': {
        outline: 'none',
      },

      '&:hover, &:focus': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: theme.palette.primary.main,
        },
      },
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.grey[200],
    },

    '& input::placeholder': {
      color: theme.palette.text.primary,
    },
  },
  layout: {
    marginTop: '12px',
    borderRadius: '50px',
    backgroundColor: 'transparent',

    '.MuiPickersDay-today': {
      backgroundColor: 'transparent',
      border: '0px !important',
    },

    '.MuiPickersDay-today:focus': {
      backgroundColor: 'transparent',
      border: '0px !important',
    },

    '.MuiPickersDay-root.Mui-selected': {
      color: theme.palette.common.white,
      borderRadius: '12px',
      border: '0px solid',
      backgroundColor: theme.palette.primary.main,
    },

    '.MuiPickersDay-root:focus.Mui-selected': {
      color: theme.palette.common.white,
      borderRadius: '12px',
      border: '0px solid',
      backgroundColor: theme.palette.primary.main,
    },

    '.MuiPickersDay-root:hover': {
      borderRadius: '12px',
      border: '0px solid',
      backgroundColor: theme.palette.primary2.main,
    },
  },
  staticPicker: {
    borderRadius: '0.5rem',

    '.MuiPickersLayout-contentWrapper': {
      maxHeight: '300px',
    },
  },
  staticLayout: {
    '.MuiPickersDay-dayWithMargin': {
      border: 'none',
    },
    '.MuiPickersDay-root.Mui-selected': {
      border: 'none',
      fontWeight: 700,
      color: theme.palette.primary.main,
    },

    '.MuiPickersDay-root:focus.Mui-selected': {
      border: 'none',
      fontWeight: 700,
      color: theme.palette.primary.main,
    },

    '.MuiPickersDay-root:hover': {
      border: 'none',
      fontWeight: 700,
      backgroundColor: theme.palette.primary2.main,
    },
  },
};

export default styles;
