import {
  eoMainSection,
  eoContentSection,
  eoDetailsSection,
  eoDetailsContent,
  eoContentFooter,
  eoSection,
  eoMainHeader,
  bookingDetailsText,
  IconStyle,
  eoBack,
  eoPatientContactContainer,
  eoFinancialDetailsContainer,
  bookingDetailsContainer,
} from '$modules/frontdesk/styles';
import {Box, Typography, Stack, Button, Grid, Tooltip} from '@mui/material';
import AppointmentDetails from './AppointmentDetails';
import BookingToConfirmIcon from '$assets/svgs/BookingToConfirm.svg?react';
import BookmarkCheckIcon from '$assets/svgs/bookmark-check.svg?react';
import AlertCircelIcon from '$assets/svgs/alert-circle.svg?react';
import ArrowNarrowRight from '$assets/svgs/arrow-narrow-right.svg?react';
import PatientDetails from './PatientDetails';
import {
  extractAppointmentDetails,
  extractContactDetails,
  extractFinancialDetails,
  extractPatientDetails,
  getChipStatusColors,
} from '$modules/frontdesk/utils';
import FinancialDetails from './FinancialDetails';
import ContactDetails from './ContactDetails';
import {ArrowLeftIcon, EditBookingIcon} from '$assets/svgs';
import {useLocation, useNavigate} from 'react-router-dom';
import {BookingDetails} from '$modules/frontdesk/type';
import {useState} from 'react';
import CancelAppointmentContainer from './CancelAppointment';
import CheckInPatientContainer from './CheckInPatient';
import ChipComponent from '$components/ChipComponent';
import {StatusIconLabelMap} from '../types';
import CheckInTimer from './CheckInTimer';
import {RoutePath} from '$constants/routes';
import {RouteSection, getRoute} from '$utils/route';
import useAuthUser from '../../../../hooks/useAuthUser';
import {formatFullName} from '$utils/helper';
import {dateToDDMMYYYY} from '$utils/date';
import FrontdeskButton from '$components/FrontdeskButton';
import NoConsultantSelectedDialog from './NoConsultantSelectedDialog';
import './styles.scss';
import {
  useAppointmentDetails,
  useConfirmBooking,
} from '$modules/frontdesk/hooks';
import ConfirmBookingDialog from '../ConfirmBooking/ConfirmBookingDialog';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';

const BookingDetailsComponent: React.FC<BookingDetails> = bookingData => {
  const location = useLocation();
  const backRoute = location?.state?.backRoute;
  const navigate = useNavigate();
  const user = useAuthUser();
  const isLoggedInUserDoctor = user?.roleName.includes('Doctor');
  const [noConsultantSelectedDialog, setNoConsultantSelectedDialog] =
    useState(false);
  const [cancelAppointmentModal, setCancelAppointmentModal] =
    useState<boolean>(false);
  const [checkInPatientModalOpen, setCheckInPatientModalOpen] =
    useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  const {isPending, disableSubmit, sendConfirmBookingRequest, queryClient} =
    useConfirmBooking(() => setShowConfirmationDialog(true));
  const appointmentDetails = extractAppointmentDetails(bookingData);
  const financialDetails = extractFinancialDetails(bookingData);
  const patientDetails = extractPatientDetails(bookingData);
  const contactDetails = extractContactDetails(bookingData);
  const {
    id: appointmentId,
    start_time: startTime,
    end_time: endTime,
    preferred_time: preferredTime,
    date,
    check_in_time,
    patient_profile_exist: isPatientProfileExist,
    patient: {full_name: patientName = '', id: patientId} = {},
    status: appointmentStatus,
  } = bookingData.appointment;

  const isCheckInDone = check_in_time !== null;
  const {bgColor, textColor} = getChipStatusColors(appointmentDetails.status);
  const statusIconLabelMap: StatusIconLabelMap = {
    confirm: {
      label: `Booking Confirmed ${
        appointmentDetails.confirm_at
          ? 'on ' + dateToDDMMYYYY(new Date(appointmentDetails.confirm_at))
          : ''
      }`,
      icon: <BookmarkCheckIcon className="mt-1" />,
    },
    cancel: {
      label: 'Booking Cancelled',
      icon: <AlertCircelIcon className="mt-1" />,
    },
    pending: {
      label: 'Booking to confirm',
      icon: <BookingToConfirmIcon className="mt-1" />,
    },
    complete: {
      label: `Booking Confirmed ${
        appointmentDetails.confirm_at
          ? 'on ' + dateToDDMMYYYY(new Date(appointmentDetails.confirm_at))
          : ''
      }`,
      icon: <BookmarkCheckIcon className="mt-1" />,
    },
  };
  const {appointmentDetails: confirmAppointmentDetails, isLoading} =
    useAppointmentDetails({
      appointmentId,
    });

  const fullName = patientDetails.full_name || '-';
  const nameElement =
    fullName.length > 30 ? (
      <Tooltip title={fullName}>
        <span>{formatFullName(fullName)}</span>
      </Tooltip>
    ) : (
      <span>{fullName}</span>
    );

  const handleConfirmBooking = () => {
    const {doctor} = bookingData.appointment || {};
    if (doctor?.id) {
      sendConfirmBookingRequest({
        appointmentId: appointmentId,
        startTime: new Date(startTime),
        endTime: new Date(endTime),
        doctorId: doctor.id,
        preferredTime: preferredTime,
        date: new Date(date),
        confirmAt: new Date(),
      });
    } else if (!doctor) {
      setNoConsultantSelectedDialog(true);
      return;
    }
  };

  const handleRescheduleBooking = () => {
    if (!bookingData.appointment.doctor) {
      setNoConsultantSelectedDialog(true);
      return;
    }
    navigate(
      getRoute(
        RouteSection.Calendar,
        RoutePath.staff.calendar.rescheduleBooking,
        appointmentDetails.appointmentId
      )
    );
  };

  const handleEditBooking = () => {
    if (!bookingData.appointment.doctor) {
      setNoConsultantSelectedDialog(true);
      return;
    }
    navigate(
      getRoute(
        RouteSection.Calendar,
        RoutePath.staff.calendar.editBooking,
        appointmentDetails.appointmentId
      ),
      {
        state: bookingData,
      }
    );
  };

  const handleOpenPatientDetails = () => {
    navigate(getRoute(RouteSection.PatientList, '', patientId), {
      state: patientId,
    });
  };

  const handleBackClick = () => {
    const route = backRoute || getRoute(RouteSection.Calendar);
    navigate(route);
  };

  const handleConfirmBookingDialog = () => {
    queryClient.invalidateQueries({
      queryKey: ['appointment-detail', appointmentId],
    });
    setShowConfirmationDialog(false);
  };

  if (isLoading) return <Loading />;
  if (!confirmAppointmentDetails) return <FetchError />;

  return (
    <Box sx={eoSection}>
      <Box sx={eoMainSection}>
        <Box sx={eoMainHeader}>
          <Box sx={eoBack} onClick={handleBackClick}>
            <ArrowLeftIcon />
            <Typography variant="subtitle2">Back</Typography>
          </Box>
        </Box>
        <Box sx={eoContentSection}>
          <Box sx={bookingDetailsContainer}>
            <Box>
              <Typography variant="pageTitle" style={bookingDetailsText}>
                {appointmentStatus === 'pending'
                  ? 'Booking details'
                  : 'Appointment details'}
              </Typography>
              <Stack spacing={3} direction={{xs: 'column', md: 'row'}}>
                <Typography variant="h3">{nameElement}</Typography>
                <ChipComponent
                  hasIcon
                  className={
                    appointmentDetails.status === 'pending'
                      ? 'chip-gradient'
                      : ''
                  }
                  label={statusIconLabelMap[appointmentDetails.status]?.label}
                  bgColor={bgColor}
                  textColor={textColor}
                  icon={statusIconLabelMap[appointmentDetails.status]?.icon}
                />
              </Stack>
            </Box>
            <Box>
              {appointmentDetails.check_in_time && (
                <CheckInTimer
                  appointmentId={appointmentDetails.appointmentId}
                  startTime={appointmentDetails.check_in_time}
                  endTime={appointmentDetails.check_out_time || undefined}
                />
              )}
            </Box>
          </Box>

          <Box sx={eoDetailsSection}>
            <Box sx={eoDetailsContent}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={5} sx={eoFinancialDetailsContainer}>
                  <Grid container direction="column" spacing={4}>
                    <Grid item>
                      <AppointmentDetails {...appointmentDetails} />
                    </Grid>
                    <FinancialDetails {...financialDetails} />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Box sx={eoPatientContactContainer}>
                    <PatientDetails {...patientDetails} />
                    <ContactDetails {...contactDetails} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {!isLoggedInUserDoctor &&
            !isCheckInDone &&
            appointmentStatus !== 'cancel' && (
              <Box sx={eoContentFooter}>
                <Stack
                  direction={{xs: 'column', md: 'row'}}
                  spacing={{xs: 2, md: 2}}
                >
                  <Button
                    className="edit-appointment-btn"
                    onClick={handleEditBooking}
                    disabled={
                      appointmentDetails.status === 'cancel' || isCheckInDone
                    }
                  >
                    <EditBookingIcon style={IconStyle} />
                    Edit booking
                  </Button>
                  {!isCheckInDone && appointmentStatus !== 'cancel' && (
                    <Button
                      className="edit-appointment-btn"
                      onClick={handleRescheduleBooking}
                      disabled={
                        appointmentDetails.status === 'cancel' || isCheckInDone
                      }
                    >
                      Reschedule
                    </Button>
                  )}
                  {appointmentDetails.status === 'confirm' &&
                    !isCheckInDone && (
                      <Button
                        className="cancel-appointment-btn"
                        onClick={() => setCancelAppointmentModal(true)}
                        disabled={isCheckInDone}
                      >
                        Cancel Appointment
                      </Button>
                    )}
                </Stack>
                <Stack
                  direction={{xs: 'column', md: 'row'}}
                  spacing={{xs: 2, md: 4}}
                >
                  {appointmentDetails.status === 'confirm' ? (
                    <FrontdeskButton
                      title={
                        isCheckInDone
                          ? 'Open patient details'
                          : 'Check-in patient'
                      }
                      onClick={() => {
                        setCheckInPatientModalOpen(!checkInPatientModalOpen);
                      }}
                      disabled={isCheckInDone}
                      startIcon={<ArrowNarrowRight style={IconStyle} />}
                    />
                  ) : (
                    !isCheckInDone &&
                    appointmentStatus !== 'cancel' && (
                      <FrontdeskButton
                        title="Confirm booking"
                        onClick={handleConfirmBooking}
                        startIcon={<ArrowNarrowRight style={IconStyle} />}
                        loading={isPending}
                        disabled={disableSubmit}
                      />
                    )
                  )}
                  {isCheckInDone && (
                    <FrontdeskButton
                      title="Open patient details"
                      onClick={handleOpenPatientDetails}
                      startIcon={<ArrowNarrowRight style={IconStyle} />}
                    />
                  )}
                </Stack>
              </Box>
            )}
        </Box>
        {noConsultantSelectedDialog && (
          <NoConsultantSelectedDialog
            open={noConsultantSelectedDialog}
            onClose={() => setNoConsultantSelectedDialog(false)}
            appointmentId={appointmentDetails.appointmentId}
            appointmentDetails={bookingData}
          />
        )}
        {cancelAppointmentModal && (
          <CancelAppointmentContainer
            cancelAppointmentModal={cancelAppointmentModal}
            setCancelAppointmentModal={setCancelAppointmentModal}
          />
        )}
        {checkInPatientModalOpen && (
          <CheckInPatientContainer
            checkInPatientModalOpen={checkInPatientModalOpen}
            setCheckInPatientModalOpen={setCheckInPatientModalOpen}
            appointmentId={appointmentId}
            patientProfileExist={isPatientProfileExist}
            patientName={patientName}
          />
        )}
        {showConfirmationDialog && (
          <ConfirmBookingDialog
            open={showConfirmationDialog}
            onClose={handleConfirmBookingDialog}
            appointmentDetails={confirmAppointmentDetails}
            selectedDate={new Date(date)}
          />
        )}
      </Box>
    </Box>
  );
};

export default BookingDetailsComponent;
