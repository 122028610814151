import {Box, Avatar, Typography} from '@mui/material';
import {noDataFoundContainer, noDataFoundDetail} from './styles';
import nodatafound from '$assets/images/no-data-found.png';

interface NoDataFoundProps {
  dataTitle: string;
  dataDescription: string;
}
const NoDataFound: React.FC<NoDataFoundProps> = ({
  dataTitle,
  dataDescription,
}) => {
  return (
    <Box sx={noDataFoundContainer}>
      <Box sx={noDataFoundDetail}>
        <Avatar
          src={nodatafound}
          alt="no-data-found"
          sx={{width: 241, height: 145}}
        />
        <Typography variant="subtitle2">{dataTitle}</Typography>
        <Typography variant="body1">{dataDescription}</Typography>
      </Box>
    </Box>
  );
};

export default NoDataFound;
