import React from 'react';
import {Box, Typography} from '@mui/material';
import {ArrowLeftIcon, SearchRefractionIcon} from '$assets/svgs';
import {useLocation, useNavigate} from 'react-router-dom';
import FrontdeskSearchbox from '$components/FrontdeskSearchBox';
import {getRoute, RouteSection} from '$utils/route';

interface CalendarDetailsPageHeaderProps {
  headerClassName: object;
  headerBackButtonClassName: object;
  headerBackLabel: string;
  containsSearchBox: boolean;
  searchText?: string;
  setSearchText?: (value: string) => void;
}
const CalendarDetailsPageHeader: React.FC<CalendarDetailsPageHeaderProps> = ({
  headerClassName,
  headerBackButtonClassName,
  headerBackLabel,
  containsSearchBox,
  searchText = '',
  setSearchText = () => {},
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const backRoute = location?.state?.backRoute;

  const handleBackClick = () => {
    navigate(backRoute || getRoute(RouteSection.Calendar));
  };

  return (
    <Box sx={headerClassName}>
      <Box sx={headerBackButtonClassName} onClick={handleBackClick}>
        <ArrowLeftIcon />
        <Typography variant="subtitle2">{headerBackLabel}</Typography>
      </Box>
      {containsSearchBox && (
        <FrontdeskSearchbox
          value={searchText}
          placeholder="Search by name"
          startIcon={<SearchRefractionIcon />}
          onChange={setSearchText}
        />
      )}
    </Box>
  );
};

export default CalendarDetailsPageHeader;
