import React from 'react';
import {EditBookingFormData} from '../schema';
import {FormikErrors, FormikTouched} from 'formik';
import {MenuItem, Typography} from '@mui/material';
import {Insurance, Payment} from '~/modules/booking/types';
import FinancialDetailsComponent from './component';
import {Color} from '~/constants/style';
import {useInsuranceTypeHook} from '../../OfflineBooking/hooks/useInsuranceTypeHook';
import {usePaymentMethodHook} from '../../OfflineBooking/hooks/usePaymentMethodHook';
import Loading from '~/components/Loading';
import FetchError from '~/components/FetchError';

interface FinancialDetailsIndexProps {
  values: EditBookingFormData;
  errors: FormikErrors<EditBookingFormData>;
  touched: FormikTouched<EditBookingFormData>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  setFieldTouched: (field: string, touched?: boolean) => void;
  setFieldValue: (field: string, value: unknown) => void;
}

const FinancialDetailsIndex = (props: FinancialDetailsIndexProps) => {
  const {errors, handleBlur, handleChange, setFieldValue, touched, values} =
    props;

  const {
    isPending: isInsuranceTypePending,
    data: insuranceTypes,
    isError: isInsuranceTypeError,
  } = useInsuranceTypeHook();
  const {
    isPending: isPaymentMethodTypePending,
    data: paymentMethods,
    isError: isPaymentMethodError,
  } = usePaymentMethodHook();

  if (isInsuranceTypePending || isPaymentMethodTypePending) {
    return <Loading />;
  }

  if (
    !insuranceTypes?.data ||
    !paymentMethods?.data ||
    isInsuranceTypeError ||
    isPaymentMethodError
  ) {
    return <FetchError />;
  }

  const handleSelfPayingChange = (value: string) => {
    setFieldValue('appointment.self_paying', value === 'Yes' ? true : false);
  };

  const handlePaymentMethodChange = (value: string) => {
    if (!value) {
      if (values.appointment.self_paying) {
        setFieldValue('appointment.payment_mode', '');
      } else {
        setFieldValue('appointment.insurance_id', '');
      }
      return;
    }
    if (!values.appointment.self_paying) {
      const item = insuranceTypes.data.find(it => it.id.toString() === value);
      if (item) {
        setFieldValue('appointment.insurance_id', item.id.toString());
        setFieldValue('appointment.payment_mode', '');
      } else {
        setFieldValue('appointment.insurance_id', '');
      }
    } else {
      const item = paymentMethods.data.find(it => it.id.toString() === value);
      if (item) {
        setFieldValue('appointment.payment_mode', item.name);
        setFieldValue('appointment.insurance_id', '');
        setFieldValue('appointment.membership_no', '');
      } else {
        setFieldValue('appointment.payment_mode', '');
      }
    }
  };

  const renderSelfPayingValue = (value: string) => {
    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value === '' ? 'Select an option' : value}
      </Typography>
    );
  };
  const renderSelfPayingItem = (item: string) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  };

  const renderInsuranceMenuItem = (item: Insurance) => {
    return (
      <MenuItem key={item.id} value={item.id.toString()}>
        <Typography variant="subtitle1">{item.name}</Typography>
      </MenuItem>
    );
  };

  const renderInsuranceValue = (value: string) => {
    if (!value || isNaN(+value)) return 'Select insurer';
    const item = insuranceTypes.data.find(it => it.id.toString() === value);
    if (!item) {
      return '';
    }
    return item.name;
  };
  const renderPaymentMethodMenuItem = (item: Payment) => {
    return (
      <MenuItem key={item.id} value={item.id.toString()}>
        <Typography variant="subtitle1">{item.name}</Typography>
      </MenuItem>
    );
  };

  const renderPaymentMethodValue = (value: string) => {
    if (!value || isNaN(+value)) return 'Select payment method';
    const item = paymentMethods.data.find(it => it.id.toString() === value);
    if (!item) {
      return '';
    }
    return item.name;
  };
  return (
    <FinancialDetailsComponent
      values={values}
      errors={errors}
      touched={touched}
      insuranceTypes={insuranceTypes.data}
      paymentMethods={paymentMethods.data}
      renderSelfPayingValue={renderSelfPayingValue}
      renderSelfPayingItem={renderSelfPayingItem}
      renderInsuranceMenuItem={renderInsuranceMenuItem}
      renderInsuranceValue={renderInsuranceValue}
      renderPaymentMethodMenuItem={renderPaymentMethodMenuItem}
      renderPaymentMethodValue={renderPaymentMethodValue}
      handleSelfPayingChange={handleSelfPayingChange}
      handlePaymentMethodChange={handlePaymentMethodChange}
      handleBlur={handleBlur}
      handleChange={handleChange}
    />
  );
};

export default FinancialDetailsIndex;
