import DialogComponent from '$components/DialogComponent';
import './styles.scss';
import {XCircleIcon} from '$assets/svgs';
import FrontdeskButton from '$components/FrontdeskButton';

interface DeleteConfirmDialogProps {
  isDisabled: boolean;
  isOpen: boolean;
  onDelete: () => void;
  onClose: () => void;
  title: string;
}

function DeleteConfirmDialog(props: DeleteConfirmDialogProps) {
  const {isDisabled, onClose, onDelete, isOpen, title} = props;

  return (
    <DialogComponent
      open={isOpen}
      handleClose={onClose}
      headerIcon={<XCircleIcon className="header-icon-container" />}
      dialogBody={
        <div className="confirm-delete-container">
          <h1 className="title">
            Are you sure you want to delete this {title} ?
          </h1>
          <div className="footer">
            <FrontdeskButton
              title="Cancel"
              disabled={isDisabled}
              className="cancel-btn"
              variant="danger"
              onClick={onClose}
            />
            <FrontdeskButton
              className="delete-btn"
              variant="primary"
              title="Delete"
              loading={isDisabled}
              disabled={isDisabled}
              onClick={onDelete}
            />
          </div>
        </div>
      }
    />
  );
}

export default DeleteConfirmDialog;
