import MasterCardIcon from '$assets/svgs/Mastercard.svg?react';
import VisaCardIcon from '$assets/svgs/visa-logo.svg?react';
import MaestroCardIcon from '$assets/svgs/Maestro.svg?react';
import VisaDebitCardIcon from '$assets/svgs/visa-debit.svg?react';

interface PaymentCardProps {
  type: string;
}

function PaymentCardIcon(props: PaymentCardProps) {
  const {type} = props;

  switch (type) {
    case 'Mastercard':
      return <MasterCardIcon />;
    case 'Visa':
      return <VisaCardIcon />;
    case 'Maestro':
      return <MaestroCardIcon />;
    case 'Visa Debit':
      return <VisaDebitCardIcon />;
    default:
      return null;
  }
}

export default PaymentCardIcon;
