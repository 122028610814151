import React from 'react';
import NotesOverviewComponent from './component';
import {useNotes} from '../../Notes/hooks';
import FetchError from '$components/FetchError';
import {PatientDetailTab} from '../../constant';
import NotesOverviewSkeleton from './NotesOverviewSkeleton';

interface NotesOverviewContainerProps {
  patientId: number;
  isFrontDesk: boolean;
  setActiveTab: React.Dispatch<React.SetStateAction<PatientDetailTab>>;
  setOverviewSelectedNoteId: React.Dispatch<
    React.SetStateAction<number | null>
  >;
}
const NotesOverviewContainer: React.FC<NotesOverviewContainerProps> = ({
  patientId,
  isFrontDesk,
  setActiveTab,
  setOverviewSelectedNoteId,
}) => {
  const {notes, isLoading: isNotesLoading} = useNotes(patientId);
  if (isNotesLoading) return <NotesOverviewSkeleton />;
  if (!notes) return <FetchError />;

  return (
    <NotesOverviewComponent
      isFrontDesk={isFrontDesk}
      setActiveTab={setActiveTab}
      setOverviewSelectedNoteId={setOverviewSelectedNoteId}
      notes={notes}
    />
  );
};

export default NotesOverviewContainer;
