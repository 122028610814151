import {useState} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';
import SelectInput from '$components/SelectInput';
import Footer from '../components/Footer';
import BookingStepper from '../components/BookingStepper';
import BookingChoicesDialog from '../components/BookingChoicesDialog';
import AppointmentCard from '../components/AppointmentCard';
import styles, {consultantSelectionViewStyles} from '../styles';
import {
  BookingDataUpdater,
  BookingStepperStage,
  BookingView,
  Consultant,
  ConsultantSelectionViewChoices,
} from '../types';
import {getNameInitials} from '../utils';
import {NO_PREFERENCE_CONSULTANT} from '../constant';
import {Link} from '@mui/material';
import {useConsultants} from '$modules/frontdesk/hooks';
import {isNumber} from '$utils/number';

interface ConsultantSelectionViewProps {
  bookingChoices: ConsultantSelectionViewChoices;
  setActiveView: React.Dispatch<React.SetStateAction<BookingView>>;
  setBookingChoices: BookingDataUpdater;
}

function ConsultantSelectionView(props: ConsultantSelectionViewProps) {
  const {bookingChoices, setActiveView, setBookingChoices} = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const {isLoading, consultants, isError} = useConsultants({
    subCareId: bookingChoices?.care?.id,
    appointmentTypeId: bookingChoices?.appointment?.id,
  });

  if (isLoading) return <Loading />;
  if (isError) return <FetchError />;

  const consultantItems: Consultant[] = [
    NO_PREFERENCE_CONSULTANT,
    ...consultants,
  ];

  const handleConsultantChange = (value: string) => {
    setBookingChoices(draft => {
      if (!value) {
        draft.consultant = undefined;
      } else if (value === NO_PREFERENCE_CONSULTANT.id.toString()) {
        draft.consultant = NO_PREFERENCE_CONSULTANT;
        if (draft.date) {
          draft.date = undefined;
        }
        if (draft.slot) {
          draft.slot = undefined;
        }
      } else {
        draft.consultant = consultantItems.find(it => it.id === Number(value));
        if (draft.date) {
          draft.date = undefined;
        }
        if (draft.slot) {
          draft.slot = undefined;
        }
      }
    });
  };

  const handleBackClick = () => {
    setActiveView(BookingView.SubcareSelectionView);
  };

  const handleContinueClick = () => {
    setActiveView(BookingView.DateSelectionView);
  };

  const renderConsultantMenuItem = (item: Consultant) => {
    return (
      <MenuItem
        key={item.id}
        value={item.id.toString()}
        className="overflow-x-auto no-scrollbar"
        sx={consultantSelectionViewStyles.menuItemContainer}
      >
        {isNumber(item.id) &&
          (item.profileUrl ? (
            <img
              src={item.profileUrl}
              style={consultantSelectionViewStyles.menuItemProfileImage}
            />
          ) : (
            <div style={consultantSelectionViewStyles.menuItemInitial}>
              {getNameInitials(item.fullName)}
            </div>
          ))}
        <Box sx={consultantSelectionViewStyles.menuItemBody}>
          <Box sx={consultantSelectionViewStyles.menuItemDetails}>
            <Box display="flex" gap="0.25rem" flexWrap="wrap">
              <Typography>{item.fullName}</Typography>{' '}
              {item.doctorDetails && (
                <>
                  <Typography sx={consultantSelectionViewStyles.fontThin}>
                    Consultation cost
                  </Typography>{' '}
                  <Typography>
                    £{item.doctorDetails?.consultationFee}
                  </Typography>
                </>
              )}
            </Box>
            {item.doctorDetails && (
              <Box>
                <Typography sx={consultantSelectionViewStyles.fontThin}>
                  {item.doctorDetails.description}
                </Typography>
              </Box>
            )}
          </Box>
          {item.doctorDetails?.bioLink && (
            <Box>
              <Link
                target="_blank"
                href={item.doctorDetails?.bioLink}
                sx={consultantSelectionViewStyles.menuItemBioText}
                className="underline-hover"
              >
                Read more
              </Link>
            </Box>
          )}
        </Box>
      </MenuItem>
    );
  };

  const renderValue = (value: string) => {
    if (!value) return 'Select a consultant';

    if (value === NO_PREFERENCE_CONSULTANT.id.toString()) {
      return NO_PREFERENCE_CONSULTANT.fullName;
    }

    const item = consultantItems.find(it => it.id.toString() === value);
    if (!item) {
      handleConsultantChange('');
      return '';
    }

    return (
      <Box sx={consultantSelectionViewStyles.valueContainer}>
        {item.profileUrl ? (
          <img
            src={item.profileUrl}
            style={consultantSelectionViewStyles.menuItemProfileImage}
          />
        ) : (
          <div style={consultantSelectionViewStyles.menuItemInitial}>
            {getNameInitials(item.fullName)}
          </div>
        )}
        <div>{item.fullName}</div>
      </Box>
    );
  };

  return (
    <Box sx={styles.sectionWrapperWithStepper} flexDirection="column" gap={5}>
      <Stack gap={5} sx={styles.section}>
        <Box>
          <BookingStepper activeStage={BookingStepperStage.Appointment} />
        </Box>
        <Typography variant="h2" sx={styles.subHeading}>
          Let's book your appointment
        </Typography>
        <Box sx={styles.scrollSection}>
          <Box sx={styles.stepsTypeWrap}>
            <Box display="flex" flexDirection="column" gap={4}>
              <Typography variant="h3" sx={styles.stepsTypeHead}>
                You have selected:
              </Typography>

              <AppointmentCard
                bookingChoices={bookingChoices}
                onOpenDialog={() => setDialogOpen(true)}
              />

              <BookingChoicesDialog
                bookingChoices={bookingChoices}
                setBookingChoices={setBookingChoices}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
              />
            </Box>
            <Box display="flex" flexDirection="column" gap={4} sx={{flex: 1}}>
              <Typography variant="h3" sx={styles.stepsTypeHead}>
                Select a consultant:
              </Typography>
              <SelectInput
                items={consultantItems}
                placeholder="Select a consultant"
                renderValue={renderValue}
                renderItem={renderConsultantMenuItem}
                onChange={handleConsultantChange}
                value={bookingChoices.consultant?.id.toString() || ''}
              />
            </Box>
          </Box>
        </Box>
      </Stack>
      <Box sx={styles.footerSection}>
        <Footer
          disableContinue={bookingChoices.consultant === undefined}
          onBackClick={handleBackClick}
          onContinueClick={handleContinueClick}
        />
      </Box>
    </Box>
  );
}

export default ConsultantSelectionView;
