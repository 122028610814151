import {useEffect, useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {
  GetAppointmentDetailsParams,
  GetAppointmentListParams,
  GetBookingSlotParams,
  PatchConfirmBookingErrorResponseData,
  PatchConfirmBookingPayload,
  PatchRescheduleBookingPayload,
  getAppointmentDetails,
  getAppointmentList,
  getBookingSlots,
  getFilterOptions,
  patchConfirmBooking,
  patchRescheduleBooking,
} from '$api/frontdesk';
import toast from '$utils/toast';
import {ToastType} from '$components/Toast/constant';
import {
  networkErrorToast,
  technicalGlitchToast,
} from '$constants/toastmessages';
import {useNavigate, useParams} from 'react-router-dom';
import {RouteSection, getRoute} from '$utils/route';
import {RoutePath} from '$constants/routes';
import {Consultant} from './type';
import {GetConsultantParams, getConsultants} from '$api/bookingFlow';

export function useConfirmBooking(showSuccessDialog: () => void) {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const queryClient = useQueryClient();

  const {isPending, mutate} = useMutation({
    mutationKey: ['booking-confirm'],
    mutationFn: patchConfirmBooking,
    onSuccess: () => {
      showSuccessDialog();
    },
    onError: error => {
      if (error instanceof AxiosError) {
        const axiosError =
          error as AxiosError<PatchConfirmBookingErrorResponseData>;
        const response = axiosError.response;

        if (response) {
          if (response.status === 500) {
            return technicalGlitchToast();
          }

          if (response.data) {
            const data = response.data;
            return toast(ToastType.Error, 'Confirm Booking', data.message);
          }
        }

        if (axiosError.message === 'Network Error') {
          return networkErrorToast();
        }

        return technicalGlitchToast();
      } else {
        technicalGlitchToast();
      }
    },
    onSettled: () => setDisableSubmit(false),
  });

  const sendConfirmBookingRequest = (payload: PatchConfirmBookingPayload) => {
    setDisableSubmit(true);
    mutate(payload);
  };

  return {
    isPending,
    disableSubmit,
    sendConfirmBookingRequest,
    queryClient,
  };
}

export function useRescheduleBooking() {
  const navigate = useNavigate();
  const params = useParams();
  const appointmentId = Number(params.id);
  const queryClient = useQueryClient();

  const [disableSubmit, setDisableSubmit] = useState(false);

  const {isPending, mutate} = useMutation({
    mutationKey: ['booking-reschedule'],
    mutationFn: patchRescheduleBooking,
    onSuccess: data => {
      navigate(
        getRoute(
          RouteSection.Calendar,
          RoutePath.staff.calendar.bookingDetails,
          appointmentId
        )
      );

      queryClient.invalidateQueries({
        queryKey: ['appointment-detail'],
      });

      return toast(ToastType.Success, 'Reschedule Booking', data.message);
    },
    onError: error => {
      if (error instanceof AxiosError) {
        const axiosError =
          error as AxiosError<PatchConfirmBookingErrorResponseData>;
        const response = axiosError.response;

        if (response) {
          if (response.status === 500) {
            return technicalGlitchToast();
          }

          if (response.data) {
            const data = response.data;
            return toast(ToastType.Error, 'Reschedule Booking', data.message);
          }
        }

        if (axiosError.message === 'Network Error') {
          return networkErrorToast();
        }

        return technicalGlitchToast();
      } else {
        technicalGlitchToast();
      }
    },
    onSettled: () => setDisableSubmit(false),
  });

  const sendRescheduleBookingRequest = (
    payload: PatchRescheduleBookingPayload
  ) => {
    setDisableSubmit(true);
    mutate(payload);
  };

  return {
    isPending,
    disableSubmit,
    sendRescheduleBookingRequest,
  };
}

export function useBookingSlots(params: GetBookingSlotParams) {
  const {
    date,
    hospitalId,
    doctorId,
    preferredTime,
    appointmentTypeId,
    subCareTypeId,
  } = params;

  const {data, isLoading, isRefetching, isError} = useQuery({
    queryKey: [
      'booking-slots',
      date,
      hospitalId,
      doctorId,
      preferredTime,
      appointmentTypeId,
      subCareTypeId,
    ],
    queryFn: () => getBookingSlots(params),
  });

  return {
    slots: data?.slots || [],
    slotsStartTime: data?.slotsStartTime,
    slotsEndTime: data?.slotsEndTime,
    isLoading: isLoading || isRefetching,
    isError: isError,
  };
}

export function useConsultants(params: GetConsultantParams) {
  const {data, isLoading, isError} = useQuery({
    queryKey: ['consultants', params.appointmentTypeId, params.subCareId],
    queryFn: () =>
      getConsultants({
        subCareId: params.subCareId,
        appointmentTypeId: params.appointmentTypeId,
      }),
  });

  const consultants: Consultant[] = useMemo(() => {
    return (
      data?.data?.map(con => ({
        id: con.id,
        fullName: con.full_name,
        profileUrl: con.profile_url,
        doctorDetails: con.doctor_details
          ? {
              bioLink: con.doctor_details.bio_link,
              followupFee: con.doctor_details.followup_fee,
              consultationFee: con.doctor_details.consultation_fee,
              specializations: con.doctor_details.specializations,
              description: con.doctor_details.description,
            }
          : null,
      })) ?? []
    );
  }, [data]);

  return {isLoading, consultants, isError};
}

export function useAppointmentList(params: GetAppointmentListParams) {
  const {doctorId, appointmentType, status, fromDate, toDate, search} = params;

  const {data, isLoading, isError, isPending} = useQuery({
    queryKey: [
      'frontdesk-appointments',
      doctorId,
      appointmentType,
      status,
      fromDate,
      toDate,
      search,
    ],
    queryFn: () => getAppointmentList(params),
  });

  return {
    isLoading,
    isError,
    isPending,
    appointments: data?.appointments || [],
    totalPages: data?.totalPages || 0,
    totalRecords: data?.totalRecords || 0,
  };
}

export function useAppointmentDetails(params: GetAppointmentDetailsParams) {
  const {appointmentId} = params;

  const {data, isLoading} = useQuery({
    queryKey: ['frontdesk-appointments', appointmentId],
    queryFn: () => getAppointmentDetails(params),
  });

  return {isLoading, appointmentDetails: data};
}

export function useAppointmentFilterOptions() {
  const {data, isPending, isError} = useQuery({
    queryKey: ['filter-options'],
    queryFn: getFilterOptions,
  });

  const filterOptions = data?.data || {
    status: [],
    consultant: [],
    appointment_type: [],
  };

  return {filterOptions, isPending, isError};
}

export function useDebounce<T>(initialValue: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(initialValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [initialValue, delay]);

  return debouncedValue;
}
