export const appendObjectToFormData = (
  formData: FormData,
  obj: unknown,
  parentKey = ''
) => {
  // Check if obj is an object and not null
  if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj).forEach(key => {
      const value = (obj as Record<string, unknown>)[key];
      // Construct the key for formData
      const fullKey = parentKey ? `${parentKey}[${key}]` : key;

      // return if value is undefined
      if (value === undefined) {
        return;
      }

      // check if value is itslef an nested object
      if (
        value instanceof Object &&
        !(value instanceof File) &&
        !(value instanceof Date) &&
        !Array.isArray(value)
      ) {
        // Recursively append object properties
        appendObjectToFormData(formData, value, fullKey);
      } else {
        // Handle File or Blob types directly
        if (value instanceof File || value instanceof Blob) {
          formData.append(fullKey, value);
        } else {
          // Convert other types to string and append
          const stringValue =
            value === null || Number.isNaN(value) ? '' : String(value);
          formData.append(fullKey, stringValue);
        }
      }
    });
  }
};
