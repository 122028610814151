import {type SxProps} from '@mui/material';

const styles: Record<string, SxProps> = {
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default styles;
