import {INCHES_IN_FOOT} from './constant';

export const calculateBMI = (
  weight: number,
  heightInMeters: number,
  decimalPlaces: number
): number => {
  const factor = 10 ** decimalPlaces;
  return Math.trunc((weight / heightInMeters ** 2) * factor) / factor;
};

export const normalizeHeight = (feet: number, inches: number): string => {
  feet += Math.floor(inches / INCHES_IN_FOOT);
  inches %= INCHES_IN_FOOT;
  const normalizedHeight = `${feet}.${inches}`;
  return normalizedHeight;
};

export const splitHeight = (
  heightInFeet: number
): {feet: number; inches: number} => {
  const [feet, inches = 0] = heightInFeet.toString().split('.').map(Number);
  return {feet, inches};
};
