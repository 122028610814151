function NotePulse() {
  return (
    <div className="animate-pulse w-full max-w-64 aspect-square rounded-lg bg-white p-4 flex flex-col gap-4 justify-between">
      <div className="flex flex-col gap-4">
        <div className="w-1/2 bg-slate-200 h-4 rounded-md"></div>
        <div className="flex flex-col gap-2">
          <div className="bg-slate-200 h-6 rounded-md"></div>
          <div className="w-3/4 bg-slate-200 h-6 rounded-md"></div>
          <div className="bg-slate-200 h-6 rounded-md"></div>
          <div className="w-3/4 bg-slate-200 h-6 rounded-md"></div>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <div className="bg-slate-200 h-10 w-10 rounded-full"></div>
        <div className="w-1/2 bg-slate-200 h-4 rounded-md"></div>
      </div>
    </div>
  );
}

export default NotePulse;
