import {SxProps} from '@mui/material';
import theme from '$modules/booking/theme';

const styles: Record<string, SxProps> = {
  list: {
    marginTop: '24px',
    marginBottom: '32px',
    position: 'relative',
  },
  listItem: {
    padding: theme.spacing(0, 0, 0, 1.5),

    '& :before': {
      content: '""',
      height: '0.313rem',
      width: '0.313rem',
      background: theme.palette.primary.main,
      borderRadius: '50%',
      position: 'absolute',
      transform: 'translateY(-50%)',
      left: 0,
      top: '50%',
      marginRight: '0.5rem',
    },
  },
  iconListItem: {
    padding: 0,
  },
  itemText: {
    ...theme.typography.caption,
  },
};

export default styles;
