import {XCloseIcon} from '$assets/svgs';
import IconButton from '$components/IconButton';
import {Button, Dialog} from '@mui/material';
import React, {useState, useEffect} from 'react';
import './styles.scss';
import CheckBox from '$components/CheckBox';
import FrontdeskButton from '$components/FrontdeskButton';
import {usePatientCareHook} from '../hooks/usePatientCareHook';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';
import {useCreatePatientCare} from '../hooks/hooks';

interface PatientCareDialogProps {
  patientId: number;
  open: boolean;
  handleClose: () => void;
  patientCaresList: {id: number; name: string}[];
}

const PatientCareDialog: React.FC<PatientCareDialogProps> = ({
  patientId,
  open,
  handleClose,
  patientCaresList,
}) => {
  const {isPending, data} = usePatientCareHook();
  const [selectedCares, setSelectedCares] = useState<Set<number>>(new Set());
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
  const {sendCreatePatientCareRequest} = useCreatePatientCare(handleClose);

  useEffect(() => {
    const initialSelectedCares = new Set(patientCaresList.map(care => care.id));
    setSelectedCares(initialSelectedCares);
    setIsSaveDisabled(initialSelectedCares.size === 0);
  }, [patientCaresList]);

  if (isPending) return <Loading />;
  if (!data) return <FetchError />;
  const patientCares = data?.data || [];

  const half = Math.ceil(patientCares.length / 2);
  const firstHalf = patientCares.slice(0, half);
  const secondHalf = patientCares.slice(half);

  const handleCheckboxChange = (id: number) => {
    setSelectedCares(prev => {
      const newSelectedCares = new Set(prev);
      if (newSelectedCares.has(id)) {
        newSelectedCares.delete(id);
      } else {
        newSelectedCares.add(id);
      }
      setIsSaveDisabled(newSelectedCares.size === 0);
      return newSelectedCares;
    });
  };

  const handleSave = () => {
    const selectedCaresArray = Array.from(selectedCares);
    sendCreatePatientCareRequest({patientId, special_care: selectedCaresArray});
  };

  return (
    <Dialog open={open} className="dialog-container">
      <div className="dialog-header">
        <div className="heading">Patient care</div>
        <IconButton onClick={handleClose}>
          <XCloseIcon />
        </IconButton>
      </div>
      <div className="dialog-body">
        <div className="title">Select the specific needs for this patient</div>
        <div className="care-types">
          <div className="care-column">
            {firstHalf.map(care => (
              <CheckBox
                label={care.name}
                key={care.id}
                checked={selectedCares.has(care.id)}
                onChange={() => handleCheckboxChange(care.id)}
              />
            ))}
          </div>
          <div className="care-column">
            {secondHalf.map(care => (
              <CheckBox
                label={care.name}
                key={care.id}
                checked={selectedCares.has(care.id)}
                onChange={() => handleCheckboxChange(care.id)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="dialog-footer">
        <Button onClick={handleClose} className="cancel-btn">
          Cancel
        </Button>
        <FrontdeskButton
          title="Save"
          onClick={handleSave}
          disabled={isSaveDisabled}
        />
      </div>
    </Dialog>
  );
};

export default PatientCareDialog;
