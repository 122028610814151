import {TIMELINE_TAB_LIST, TimelineTab} from './constant';
import TabCard from './TabCard';
import UpcomingEvents from './UpcomingEvents';
import './styles.scss';
import SectionItem from './SectionItem';
import {AppointmentInfo} from '$modules/frontdesk/type';
import {Section, TestRecord} from './types';
import ConsultationCard from './ConsultationCard';
import {SetStateAction, useMemo} from 'react';
import TestCard from './TestCard';
import SectionLoading from './SectionLoading';

interface TimelineComponentProps {
  activeTab: TimelineTab;
  setActiveTab: React.Dispatch<SetStateAction<TimelineTab>>;
  consultationSections: Section<AppointmentInfo>[];
  testSections: Section<TestRecord>[];
  upcomingEvents: AppointmentInfo[];
  totalConsultation: number;
  totalTest: number;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
}

function TimelineComponent(props: TimelineComponentProps) {
  const {
    activeTab,
    setActiveTab,
    consultationSections,
    testSections,
    upcomingEvents,
    totalConsultation,
    totalTest,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = props;

  const tabCountMap: Record<TimelineTab, number> = {
    [TimelineTab.Consultation]: totalConsultation,
    [TimelineTab.Test]: totalTest,
  };

  const tabs = TIMELINE_TAB_LIST.map(tab => (
    <TabCard
      key={tab.value}
      active={activeTab === tab.value}
      label={tab.label}
      count={tabCountMap[tab.value]}
      onClick={() => setActiveTab(tab.value)}
    />
  ));

  const renderConsultationCard = (item: AppointmentInfo) => {
    return <ConsultationCard key={item.appointment.id} consultation={item} />;
  };

  const renderTestCard = (item: TestRecord) => {
    return <TestCard key={item.id} testRecord={item} />;
  };

  const sectionItems = useMemo(() => {
    switch (activeTab) {
      case TimelineTab.Consultation:
        return consultationSections.map(section => (
          <SectionItem
            key={section.sectionKey}
            records={section.records}
            title={section.sectionTitle}
            renderItem={renderConsultationCard}
          />
        ));
      case TimelineTab.Test:
        return testSections.map(section => (
          <SectionItem
            key={section.sectionKey}
            records={section.records}
            title={section.sectionTitle}
            renderItem={renderTestCard}
          />
        ));
      default:
        return null;
    }
  }, [activeTab, consultationSections, testSections]);

  const handleScroll: React.UIEventHandler<HTMLDivElement> = ev => {
    const currentTarget = ev.currentTarget;
    const scrollTop = currentTarget.scrollTop;
    const totalScroll = currentTarget.scrollHeight - currentTarget.clientHeight;

    const percent = (scrollTop / totalScroll) * 100;

    if (percent > 95 && !isFetchingNextPage && hasNextPage) fetchNextPage();
  };

  return (
    <div className="timeline flex-1 flex flex-col overflow-auto">
      <div>
        <h1 className="text-2xl font-semibold primary-dark mb-6">
          Patient timeline
        </h1>
        <div className="flex gap-4 mb-6">{tabs}</div>
      </div>
      <div className="flex flex-1 gap-6 overflow-auto">
        <div className="flex-1 overflow-auto" onScroll={handleScroll}>
          {sectionItems}
          {isFetchingNextPage && <SectionLoading />}
        </div>
        <div>
          <UpcomingEvents upcomingEvents={upcomingEvents} />
        </div>
      </div>
    </div>
  );
}

export default TimelineComponent;
