import FrontdeskButton from '$components/FrontdeskButton';
import {UserRoleName} from '$modules/admin/constant';
import {IconButton, Tooltip} from '@mui/material';
import useAuthUser from '../../../../../hooks/useAuthUser';
import NoteAuthor from './NoteAuthor';
import {Note} from './types';
import {DownloadIcon, EyeIcon} from '$assets/svgs';
import {downloadAttachment, viewAttachment} from './utils';

interface NoteDisplayProps {
  title: string;
  description: string;
  documentUrl?: string;
  author: Note['createdBy'];
  onModifyClick: () => void;
}

function NoteDisplay(props: NoteDisplayProps) {
  const {title, description, documentUrl, author, onModifyClick} = props;
  const {roleName} = useAuthUser()!;

  const isConsultant = roleName === UserRoleName.Doctor;

  return (
    <div className="flex flex-col flex-1 ml-6">
      <div className="flex-1 bg-white rounded-xl p-6 flex flex-col">
        <div className="flex items-center mb-4">
          <h4 className="text-2xl mr-8">Note</h4>
          <NoteAuthor
            profileUrl={author.profileUrl}
            fullName={author.fullName}
          />
          {documentUrl && (
            <div className="flex gap-1">
              <Tooltip title="Download Attachment">
                <IconButton
                  onClick={() => downloadAttachment(documentUrl, 'attachment')}
                >
                  <DownloadIcon className="h-4 w-4 primary" />
                </IconButton>
              </Tooltip>
              <Tooltip title="View Attachment">
                <IconButton onClick={() => viewAttachment(documentUrl)}>
                  <EyeIcon className="h-4 w-4 primary" />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
        <p className="note-title w-full">{title}</p>
        <pre className="flex-1 note-description text-wrap">{description}</pre>
      </div>
      <div className="flex justify-end gap-3 mt-8">
        {isConsultant && (
          <FrontdeskButton title="Modify note" onClick={onModifyClick} />
        )}
      </div>
    </div>
  );
}

export default NoteDisplay;
