import React from 'react';

const TimelineSkeleton = () => {
  const cards = [1, 2, 3, 4];

  // To divide the skeleton cards in groups
  const shouldShowDivider = cards.map((_, index) => {
    return (index + 1) % 2 === 0 && index !== cards.length - 1;
  });

  return (
    <div className="flex flex-row">
      <div className="timeline flex-1 flex flex-col overflow-auto">
        <div>
          <h1 className="text-2xl font-semibold primary-dark">
            Patient timeline
          </h1>
        </div>

        <div className="flex gap-4 mt-4 mb-4 ml-2">
          <div className="bg-white h-10 w-32 rounded animate-pulse"></div>
          <div className="bg-white h-10 w-32 rounded animate-pulse"></div>
        </div>
        <div className="p-2 rounded-lg">
          {cards.map((_, index) => (
            <React.Fragment key={index}>
              <div className="bg-white rounded-lg p-4 mb-8 w-4/5 flex flex-col gap-4 animate-pulse">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <div className="bg-gray-100 h-10 w-10 rounded-full"></div>
                    <div className="flex-1">
                      <div className="bg-gray-200 h-5 w-48 rounded mb-2"></div>
                      <div className="bg-gray-200 h-5 w-36 rounded"></div>
                    </div>
                  </div>
                  <div className="text-right">
                    <div className="bg-gray-200 h-4 w-20 rounded mb-2"></div>
                    <div className="bg-gray-200 h-4 w-12 rounded"></div>
                  </div>
                </div>
              </div>

              {shouldShowDivider[index] && (
                <div className="flex justify-start mb-8">
                  <div className="bg-gray-200 h-5 w-32 rounded animate-pulse"></div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TimelineSkeleton;
