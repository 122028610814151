import {useFormik} from 'formik';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import BookingStepper from '../components/BookingStepper';
import {
  BookingDataUpdater,
  BookingStepperStage,
  BookingView,
  LastStepViewChoices,
} from '../types';
import styles from '../styles';
import Footer from '../components/Footer';
import LastStepForm from '../components/LastStepForm';
import {
  LastStepFormData,
  lastStepSchema,
} from '../components/LastStepForm/schema';
import {useAppointment} from '../hooks';

interface LastStepViewProps {
  bookingChoices: LastStepViewChoices;
  setActiveView: (view: BookingView) => void;
  setBookingChoices: BookingDataUpdater;
}

function LastStepView(props: LastStepViewProps) {
  const {bookingChoices, setActiveView, setBookingChoices} = props;

  const {saveAppointment, disableSubmit, isPending} = useAppointment(
    bookingChoices,
    setActiveView,
    setBookingChoices
  );

  const handleSubmit = (values: LastStepFormData) => {
    const {password} = values;

    if (password) {
      setBookingChoices(draft => {
        draft.password = password;
      });
    }

    saveAppointment({password});
  };

  const formik = useFormik<LastStepFormData>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: lastStepSchema,
    onSubmit: handleSubmit,
  });

  const handleBackClick = () => {
    setActiveView(BookingView.PatientInsuranceDetailView);
  };
  const handleContinueClick = () => {
    formik.submitForm();
  };

  return (
    <Box sx={styles.sectionWrapperWithStepper} flexDirection="column" gap={5}>
      <Stack gap={5} sx={styles.section}>
        <Box>
          <BookingStepper activeStage={BookingStepperStage.Insurance} />
        </Box>
        <Box>
          <Typography variant="h2" sx={styles.subHeading}>
            The last step! Save your information (optional)
          </Typography>
          <Typography variant="body1">
            By simply providing a password, you can create an account and unlock
            quick booking for your next appointments, making your experience
            even easier.
          </Typography>
        </Box>
        <LastStepForm
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          setFieldTouched={formik.setFieldTouched}
          setFieldValue={formik.setFieldValue}
        />
      </Stack>

      <Box sx={styles.footerSection}>
        <Footer
          continueLoading={isPending}
          continueButtonLabel="Complete booking"
          disableContinue={disableSubmit}
          onBackClick={handleBackClick}
          onContinueClick={handleContinueClick}
        />
      </Box>
    </Box>
  );
}

export default LastStepView;
