import {FormikErrors, FormikTouched} from 'formik';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import {useQuery} from '@tanstack/react-query';
import {getRelationships} from '$api/bookingFlow';
import Input from '$components/Input';
import SelectInput from '$components/SelectInput';
import {Color} from '$constants/style';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';
import theme from '$modules/booking/theme';
import PhoneNumberInput from '../PhoneNumberInput';
import styles from '../../styles';
import {PatientCarerDetailsFormData} from './schema';
import {Relationship} from '../../types';

interface CarerDetailsFormProps {
  values: PatientCarerDetailsFormData;
  errors: FormikErrors<PatientCarerDetailsFormData>;
  touched: FormikTouched<PatientCarerDetailsFormData>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  setFieldTouched: (field: string) => void;
  setFieldValue: (field: string, value: unknown) => void;
}

function CarerDetailForm(props: CarerDetailsFormProps) {
  const {values, errors, touched, handleBlur, handleChange, setFieldValue} =
    props;

  const carer = values.carer;
  const touchedCarer = touched.carer;
  const errorsCarer = errors.carer;

  const {data, isLoading} = useQuery({
    queryKey: ['relationships'],
    queryFn: getRelationships,
  });

  if (isLoading) return <Loading />;
  if (!data) return <FetchError />;

  const relationshipItems = data.data;

  const handleRelationshipChange = (relationship: string) => {
    setFieldValue('carer.relationship', relationship);
  };

  const renderRelationshipValue = (value: string) => {
    if (value === '')
      return (
        <Typography color={Color.TextPrimayPlaceholder}>
          Relationship with patient
        </Typography>
      );

    const item = relationshipItems.find(it => it.name === value);
    if (!item) {
      handleRelationshipChange('');
      return '';
    }

    return <Typography>{item.name}</Typography>;
  };

  const renderRelationshipItem = (item: Relationship) => {
    return (
      <MenuItem key={item.name} value={item.name}>
        {item.name}
      </MenuItem>
    );
  };

  return (
    <Box flexDirection="column" display="flex" gap={2}>
      <Typography variant="h3" sx={styles.stepsTypeHead}>
        Patient carer details
      </Typography>
      <Box sx={styles.stepsTypeWrap}>
        <Box display="flex" flexDirection="column" gap={2} flex={1}>
          <Input
            name="carer.firstName"
            placeholder="First name"
            value={carer?.firstName || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={Boolean(touchedCarer?.firstName)}
            error={Boolean(errorsCarer?.firstName)}
            helperText={
              touchedCarer?.firstName && errorsCarer?.firstName
                ? errorsCarer.firstName
                : ''
            }
          />
          <Input
            name="carer.lastName"
            placeholder="Last name"
            value={carer?.lastName || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touchedCarer?.lastName}
            error={Boolean(errorsCarer?.lastName)}
            helperText={
              touchedCarer?.lastName && errorsCarer?.lastName
                ? errorsCarer.lastName
                : ''
            }
          />
          <Input
            name="carer.email"
            placeholder="Email address"
            value={carer?.email || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touchedCarer?.email}
            error={Boolean(errorsCarer?.email)}
            helperText={
              touchedCarer?.email && errorsCarer?.email ? errorsCarer.email : ''
            }
          />
          <Box display="flex" flexDirection="column" gap={1}>
            <PhoneNumberInput
              name="carer.phoneNumber"
              value={values.carer?.phoneNumber}
              country={values?.patient?.country}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {touchedCarer?.phoneNumber && errorsCarer?.phoneNumber && (
              <Typography
                variant="caption"
                color={theme.palette.error.main}
                fontWeight={400}
              >
                {errorsCarer?.phoneNumber}
              </Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1} flex={1}>
          <SelectInput
            name="carer.relation"
            items={relationshipItems}
            value={carer?.relationship || ''}
            placeholder="Relationship with patient"
            renderItem={renderRelationshipItem}
            renderValue={renderRelationshipValue}
            onBlur={handleBlur}
            onChange={handleRelationshipChange}
          />
          {touchedCarer?.relationship && errorsCarer?.relationship && (
            <Typography
              variant="caption"
              color={theme.palette.error.main}
              fontWeight={400}
            >
              {errorsCarer?.relationship}
            </Typography>
          )}
          <Typography variant="caption">
            Please specify your relation with the patient you're booking for.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default CarerDetailForm;
