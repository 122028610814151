import {Box, IconButton, Typography} from '@mui/material';
import XSquareIcon from '$assets/svgs/x-square.svg?react';
import CheckSquareIcon from '$assets/svgs/check-square.svg?react';
import ToastXIcon from '$assets/svgs/toast-x.svg?react';
import theme from '$modules/booking/theme';
import styles from './styles';
import {ToastType} from './constant';

const colorMap: Record<ToastType, {main: string; light: string}> = {
  [ToastType.Error]: {
    main: theme.palette.error.main,
    light: theme.palette.error.light,
  },
  [ToastType.Success]: {
    main: theme.palette.success.main,
    light: theme.palette.success.light,
  },
};

const iconMap: Record<ToastType, React.ReactNode> = {
  [ToastType.Error]: <XSquareIcon />,
  [ToastType.Success]: <CheckSquareIcon />,
};

interface ToastProps {
  type: ToastType;
  title: string;
  message: string | string[];
  closeToast?: () => void;
}

function Toast(props: ToastProps) {
  const {type, title, message, closeToast} = props;
  const errorsMessage = () => {
    if (Array.isArray(message)) {
      return (
        <ul>
          {message.map((msg, index) => (
            <li key={index}>
              <Typography variant="caption" color={theme.palette.text.primary}>
                {msg}
              </Typography>
            </li>
          ))}
        </ul>
      );
    }
    return message;
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.sideBar} bgcolor={colorMap[type].main} />
      <Box sx={styles.iconSection}>
        <Box
          sx={styles.iconContainer}
          bgcolor={colorMap[type].light}
          color={colorMap[type].main}
        >
          {iconMap[type]}
        </Box>
      </Box>
      <Box sx={styles.contentContainer}>
        <Typography variant="subtitle2" color={colorMap[type].main}>
          {title}
        </Typography>

        {Array.isArray(message) ? (
          errorsMessage()
        ) : (
          <Typography variant="caption" color={theme.palette.text.primary}>
            {message}
          </Typography>
        )}
      </Box>
      <Box sx={styles.closeContainer} color={theme.palette.text.primary}>
        <IconButton onClick={closeToast}>
          <ToastXIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
export default Toast;
