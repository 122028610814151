import React from 'react';
import {FormikErrors, FormikTouched} from 'formik';
import Input from '$components/Input';
import SelectInput from '$components/SelectInput';
import {PatientDetailTwoFormData} from './schema';
import './styles.scss';
import {City, Country} from '$modules/booking/types';

interface PatientDetailsTwoComponentProps {
  values: PatientDetailTwoFormData;
  errors: FormikErrors<PatientDetailTwoFormData>;
  touched: FormikTouched<PatientDetailTwoFormData>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleFieldChange: (
    field: string,
    defaultValue: unknown
  ) => (value: unknown) => void;
  cities: City[];
  countries: Country[];
  renderCityValue: (value: string) => JSX.Element;
  renderCityItem: (item: {code: string; name: string}) => JSX.Element;
  renderCountryValue: (value: string) => JSX.Element;
  renderCountryItem: (item: {code: string; name: string}) => JSX.Element;
  renderItem: (item: string) => JSX.Element;
  renderValue: (value: string, placeholder: string) => JSX.Element;
  isPostcodeError: boolean;
}

const PatientDetailsTwoComponent: React.FC<PatientDetailsTwoComponentProps> = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  handleFieldChange,
  cities,
  countries,
  renderCityItem,
  renderCityValue,
  renderCountryItem,
  renderCountryValue,
  isPostcodeError,
}) => {
  const {patient} = values;
  const errorsPatient = errors.patient || {};
  const touchedPatient = touched.patient || {};

  return (
    <>
      <h2 className="dialog-title">Patient details 2</h2>
      <div className="patient-detail-two-form-container">
        <div className="patient-detail-two-input-container">
          <div className="field-label">Address Line 1</div>
          <Input
            name="patient.address"
            placeholder="Address Line 1"
            value={patient.address}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touchedPatient?.address}
            error={Boolean(errorsPatient?.address)}
            helperText={
              touchedPatient?.address && errorsPatient?.address
                ? errorsPatient.address
                : ''
            }
          />
        </div>
        <div className="patient-detail-two-input-container">
          <div className="field-label">Address Line 2 (optional)</div>
          <Input
            name="patient.address2"
            placeholder="Address Line 2 (optional)"
            value={patient.address2 || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touchedPatient?.address2}
            error={Boolean(errorsPatient?.address2)}
            helperText={
              touchedPatient?.address2 && errorsPatient?.address2
                ? errorsPatient.address2
                : ''
            }
          />
        </div>

        <div className="patient-detail-two-input-container">
          <div className="field-label">Country</div>
          <SelectInput
            name="patient.country"
            placeholder="Country"
            value={patient.country}
            items={countries}
            renderValue={renderCountryValue}
            renderItem={renderCountryItem}
            onBlur={handleBlur}
            onChange={handleFieldChange('country', '')}
            error={touchedPatient?.country && Boolean(errorsPatient?.country)}
          />
          {touchedPatient?.country && errorsPatient?.country && (
            <div className="patient-details-two-error-text">
              {errorsPatient.country}
            </div>
          )}
        </div>
        <div className="patient-detail-two-input-container">
          <div className="field-label">Town or city</div>
          <SelectInput
            name="patient.city"
            placeholder="Town or city"
            value={patient.city}
            items={cities}
            renderValue={renderCityValue}
            renderItem={renderCityItem}
            onBlur={handleBlur}
            onChange={handleFieldChange('city', '')}
            error={touchedPatient?.city && Boolean(errorsPatient?.city)}
          />
          {touchedPatient?.city && errorsPatient?.city && (
            <div className="patient-details-two-error-text">
              {errorsPatient.city}
            </div>
          )}
        </div>
        <div className="patient-detail-two-input-container">
          <div className="field-label">Postcode</div>
          <Input
            name="patient.postcode"
            placeholder="Postcode"
            onBlur={handleBlur}
            onChange={handleChange}
            value={patient.postcode}
            touched={touchedPatient?.postcode}
            error={Boolean(errorsPatient?.postcode || isPostcodeError)}
            helperText={
              isPostcodeError ||
              (touchedPatient?.postcode && errorsPatient?.postcode)
                ? errorsPatient.postcode
                : ''
            }
          />
        </div>
      </div>
    </>
  );
};

export default PatientDetailsTwoComponent;
