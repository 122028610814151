import PencilIcon from '$assets/svgs/pencil.svg?react';
import LinkIcon from '$assets/svgs/share-04.svg?react';
import {IconButton, Tooltip} from '@mui/material';
import NoteAuthor from './NoteAuthor';
import {Note} from './types';
import {dateToDDMMYYYY} from '$utils/date';
import {UserRoleName} from '$modules/admin/constant';
import useAuthUser from '../../../../../hooks/useAuthUser';
import {DownloadIcon, EyeIcon, TrashIcon} from '$assets/svgs';
import {downloadAttachment, viewAttachment} from './utils';
import DeleteConfirmDialog from '$components/DeleteConfirmDialog';

interface NoteCardProps {
  note: Note;
  onEditClick: () => void;
  onExpandClick: () => void;
  showDeleteModal: boolean;
  setShowDeleteModal: (value: boolean) => void;
  deleteNoteRequest: (noteId: number) => void;
  isNoteDeleting: boolean;
  disableClick: boolean;
}

function NoteCard(props: NoteCardProps) {
  const {
    note,
    onEditClick,
    onExpandClick,
    deleteNoteRequest,
    isNoteDeleting,
    disableClick,
    setShowDeleteModal,
    showDeleteModal,
  } = props;
  const {roleName} = useAuthUser()!;

  const isConsultant = roleName === UserRoleName.Doctor;

  const author = note.createdBy;

  const dateString = dateToDDMMYYYY(note.createdAt);
  const description =
    note.description.length > 100
      ? note.description.slice(0, 100) + '...'
      : note.description;

  return (
    <div className="w-full max-w-64 aspect-square rounded-lg bg-white p-4 flex flex-col">
      <p className="text-sm tertiary mb-2">{dateString}</p>
      <p className="text-primary font-bold text-lg mb-1">{note.title}</p>
      <p className="flex-1 text-sm tertiary mb-2">{description}</p>
      <NoteAuthor profileUrl={author.profileUrl} fullName={author.fullName} />
      <div className="flex justify-end text-base">
        <IconButton
          disabled={isNoteDeleting || disableClick}
          onClick={() => setShowDeleteModal(true)}
        >
          <TrashIcon className="h-4 w-4 primary" />
        </IconButton>
        {isConsultant && (
          <IconButton onClick={onEditClick}>
            <PencilIcon className="h-4 w-4 primary" />
          </IconButton>
        )}
        <IconButton onClick={onExpandClick}>
          <LinkIcon className="h-4 w-4 primary" />
        </IconButton>
        {note.documentUrl && (
          <div className="flex gap-1">
            <Tooltip title="Download Attachment">
              <IconButton
                onClick={() =>
                  downloadAttachment(note.documentUrl, 'attachment')
                }
              >
                <DownloadIcon className="h-4 w-4 primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Attachment">
              <IconButton onClick={() => viewAttachment(note.documentUrl)}>
                <EyeIcon className="h-4 w-4 primary" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
      {showDeleteModal && (
        <DeleteConfirmDialog
          title="Note"
          isDisabled={isNoteDeleting || disableClick}
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onDelete={() => deleteNoteRequest(note.id)}
        />
      )}
    </div>
  );
}

export default NoteCard;
