import theme from '$modules/frontdesk/theme';
import {scrollbarStyles} from '$styles/index';

export const appointmentListContainer = {
  background: theme.palette.primary.main,
  padding: theme.spacing(0, 6),

  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflowX: 'hidden',
  overflowY: 'auto',
  ...scrollbarStyles,

  //   [theme.breakpoints.up('md')]: {
  //     flexDirection: 'column',
  //   },
};
export const appointmentListHeader = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 0),
  borderBottom: '1px solid',
  borderBottomColor: 'rgba(71, 84, 103, 0.15)',
};

export const appointmentListHeaderBackButton = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 1,
  cursor: 'pointer',
};

export const appointmentListSection = {
  //   background: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  padding: theme.spacing(2, 0),
  overflowX: 'hidden',
  overflowY: 'auto',
  ...scrollbarStyles,
};

export const appointmentListContentSection = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  //   overflowX: 'hidden',
  //   overflowY: 'auto',

  padding: theme.spacing(4),
  gridGrap: theme.spacing(3),

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(2, 6),
  },
};

export const appointmentListDetailsSection = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,

  gridGap: theme.spacing(3),
  overflowX: 'hidden',
  overflowY: 'auto',
  ...scrollbarStyles,

  [theme.breakpoints.up('lg')]: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },
};

export const appointmentListDetailsContent = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflowX: 'hidden',
  overflowY: 'auto',
  gridGap: theme.spacing(3),
};

export const appointmentListItemContainer = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  overflowX: 'hidden',
  overflowY: 'auto',
  ...scrollbarStyles,
};

export const listItemCard = {
  background: theme.palette.common.white,
  borderRadius: '0.75rem',
  padding: theme.spacing(1, 3),
  height: '80px',
  border: '1px solid #47546726',
  cursor: 'pointer',

  overflowX: 'hidden',
  overflowY: 'auto',
  ...scrollbarStyles,
};

export const listItemDate = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

export const listItemDateDay = {
  lineHeight: '1.375rem',
  color: '#475467',
};

export const listItemPatientInfo = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};

export const listItemPatientNameTimeContainer = {
  display: 'flex',
  gap: 5,
};

export const listItemTime = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
};
