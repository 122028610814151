import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import styles from './styles';
import {NO_PREFERENCE_CONSULTANT} from '../../constant';
import {DateSelectionViewChoices} from '../../types';

interface AppointmentSummaryTitleProps {
  choices: DateSelectionViewChoices;
}

function AppointmentSummaryTile(props: AppointmentSummaryTitleProps) {
  const {choices} = props;

  const items = [
    choices.appointment.title,
    choices.care.title,
    choices.subcare.title,
  ];

  if (choices.consultant.fullName !== NO_PREFERENCE_CONSULTANT.fullName) {
    items.push(choices.consultant.fullName);
  }

  const description = items.join(' - ');

  return (
    <Stack sx={styles.summaryWrap}>
      <Typography variant="h4" sx={styles.summaryTitle}>
        Appointment Summary:
      </Typography>
      <Typography variant="body1">{description}</Typography>
    </Stack>
  );
}

export default AppointmentSummaryTile;
