import {APPOINTMENTLIST_SKELETON_ITEM_COUNT} from '$modules/frontdesk/constant';

const ListComponentSkeleton = () => {
  const skeletonItems = Array.from({
    length: APPOINTMENTLIST_SKELETON_ITEM_COUNT,
  });

  const skeletonList = skeletonItems.map((_, index) => (
    <div
      key={index}
      className="w-full min-w-fit animate-pulse rounded-xl bg-white px-4 py-2"
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col justify-center border-gray-200 pr-4">
          <div className="h-10 w-14 bg-gray-200 rounded-md"></div>
        </div>
        <div className="flex w-full items-center justify-between pl-4">
          <div className="w-full">
            <div className="h-5 w-2/3 bg-gray-200 rounded-full"></div>
            <div className="mt-4 flex gap-4">
              <div className="h-4 w-1/3 bg-gray-200 rounded-full"></div>
              <div className="flex items-center gap-1"></div>
            </div>
          </div>
        </div>
        <div className="flex mr-7">
          <div className="h-8 w-20 rounded-xl bg-gray-200"></div>
        </div>
      </div>
    </div>
  ));

  return <div className="flex w-full flex-col gap-4">{skeletonList}</div>;
};

export default ListComponentSkeleton;
