import {getCareTypes} from '$api/bookingFlow';
import {useQuery} from '@tanstack/react-query';

export const useCareTypeHook = (bookingId?: number) => {
  const {isPending, isError, error, data} = useQuery({
    queryKey: ['care-types', bookingId],
    queryFn: () => getCareTypes(bookingId),
  });

  return {
    isPending,
    isError,
    error,
    data,
  };
};
