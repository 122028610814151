import LeftArrowIcon from '$assets/svgs/chevron-left-arrow.svg?react';
import RigthArrowIcon from '$assets/svgs/chevron-right-arrow.svg?react';
import {Button} from '@mui/material';

interface CalendarRangeChangeProps {
  rangeText?: string;
  onPrev?: () => void;
  onNext?: () => void;
  disabled?: boolean;
}

function CalendarRangeChange(props: CalendarRangeChangeProps) {
  const {disabled, rangeText, onPrev, onNext} = props;

  return (
    <div className="calendar-range-change-container flex items-center bg-white">
      <Button
        data-testId="left-arrow"
        className="calendar-range-left-container flex items-center justify-center"
        onClick={onPrev}
        disabled={disabled}
      >
        <LeftArrowIcon />
      </Button>
      <div className="calendar-range-label-container flex items-center justify-center px-5 py-1">
        <span className="select-none text-sm">{rangeText}</span>
      </div>
      <Button
        data-testid="right-arrow"
        className="calendar-range-right-container flex items-center justify-center"
        onClick={onNext}
        disabled={disabled}
      >
        <RigthArrowIcon />
      </Button>
    </div>
  );
}

export default CalendarRangeChange;
