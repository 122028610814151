import FrontdeskButton from '$components/FrontdeskButton';
import {useNavigate} from 'react-router-dom';
import {RouteSection, getRoute} from '$utils/route';
import {RoutePath} from '$constants/routes';
import {CalendarCheckIcon, SyringeIcon, UserSquareIcon} from '$assets/svgs';
import {useAppointmentDetails} from '$modules/frontdesk/hooks';
import FetchError from '$components/FetchError';
import Loading from '$components/Loading';
import {FormState} from '../types';

interface BookingConfirmedProps {
  appointmentId: number;
  offlineBookingType: string;
  offlineBookingFormData: FormState;
}
const BookingConfirmed: React.FC<BookingConfirmedProps> = ({
  appointmentId,
  offlineBookingType,
  offlineBookingFormData,
}) => {
  const selectedAppointmentTypeId =
    offlineBookingFormData.appointment.appointment_type_id;
  const navigate = useNavigate();
  const {appointmentDetails, isLoading} = useAppointmentDetails({
    appointmentId,
  });
  if (isLoading) return <Loading />;
  if (!appointmentDetails) return <FetchError />;
  const appointment = appointmentDetails?.appointment;
  const doctor = appointmentDetails?.doctor;
  const appointmentType = appointmentDetails?.appointmentType;
  const selectedDate = appointment?.date;
  const dateString = selectedDate?.toLocaleDateString('en-GB');
  const operationTheatre = appointmentDetails?.operationTheatre;
  const operationTheatreName = operationTheatre ? operationTheatre.name : '-';
  const handleNavigation = () => {
    navigate(
      getRoute(
        RouteSection.Calendar,
        RoutePath.staff.calendar.bookingDetails,
        appointmentId
      )
    );
  };

  return (
    <>
      <div className="offline-confirm-booking-dialog">
        <div className="booking-confirmed-text">
          {offlineBookingType === 'new' || selectedAppointmentTypeId === 5
            ? 'Booking Confirmed'
            : 'Surgery booking confirmed'}
        </div>
        {offlineBookingType === 'surgery' &&
          selectedAppointmentTypeId !== 5 && (
            <div className="info-container">
              <div className="info-cell">
                <UserSquareIcon />
                <p className="info-label">Surgeon</p>
                <p className="info-value">{doctor?.fullName || '-'}</p>
              </div>
              <div className="info-cell">
                <CalendarCheckIcon />
                <p className="info-label">Date</p>
                <p className="info-value">{dateString}</p>
              </div>
              <div className="info-cell">
                <SyringeIcon />
                <p className="info-label">Surgery</p>
                <p className="info-value">{appointmentType?.title || '-'}</p>
              </div>
              <div className="info-cell">
                <SyringeIcon />
                <p className="info-label">Operation theatre</p>
                <p className="info-value">{operationTheatreName}</p>
              </div>
            </div>
          )}
      </div>
      <div className="dialog-footer">
        <FrontdeskButton
          title="Open booking details"
          onClick={handleNavigation}
        />
      </div>
    </>
  );
};
export default BookingConfirmed;
