export const noDataFoundContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const noDataFoundDetail = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 2,
};
