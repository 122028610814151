import {type SxProps} from '@mui/material';

const styles: Record<string, SxProps> = {
  container: {
    flex: 1,
    display: 'flex',
  },
  sideBar: {
    width: '8px',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingY: '12px',
  },
  iconSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '10px',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px',
    borderRadius: '4px',
  },
  closeContainer: {},
};

export default styles;
