import {useMutation, useQuery} from '@tanstack/react-query';
import {getAppointmentDetails, putAppointmentDetails} from './service';
import {GETAppointmentDetailsParams} from './types';
import {useNavigate} from 'react-router-dom';
import toast from '~/utils/toast';
import {ToastType} from '~/components/Toast/constant';
import {getRoute, RouteSection} from '~/utils/route';
import {RoutePath} from '~/constants/routes';
import {handleErrorResponse} from '~/utils/axios';
import {useState} from 'react';

export const useAppointmentDetails = (params: GETAppointmentDetailsParams) => {
  const {appointmentId} = params;
  const {data, isPending, isError} = useQuery({
    queryKey: ['appointment-detail', appointmentId],
    queryFn: () => getAppointmentDetails({appointmentId}),
  });

  return {
    appointmentDetails: data?.data.appointment,
    isPending,
    isError,
  };
};
export const useModifyAppointmentDetails = (appointmentId: string) => {
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {
    mutate: modifyAppointmentDetails,
    isPending: isModifyingAppointmentDetails,
  } = useMutation({
    mutationKey: ['edit-appointment-details', appointmentId],
    mutationFn: putAppointmentDetails,
    onMutate: () => setDisableSubmit(true),
    onSettled: () => setDisableSubmit(false),
    onSuccess: () => {
      toast(ToastType.Success, 'Appointment Details edited successfully', '');
      navigate(
        getRoute(
          RouteSection.Calendar,
          RoutePath.staff.calendar.bookingDetails,
          appointmentId
        )
      );
    },
    onError: error => handleErrorResponse(error, 'Edit Appointment Details'),
  });

  return {
    modifyAppointmentDetails,
    isModifyingAppointmentDetails,
    disableSubmit,
  };
};
