import {InferType, boolean, date, number, object, string} from 'yup';
import {ValidationMessage} from '$constants/messages';
import {
  NAME_VALIDATION_REGEX,
  POSTCODE_VALIDATION_REGEX,
} from '$constants/regex';
import moment from '$utils/date';

export const patientCarerDetailFormSchema = object({
  patient: object({
    nhsNumber: number()
      .transform((_, val) => (Number.isNaN(val) ? undefined : val))
      .typeError(ValidationMessage.NHSNumberInvalid)
      .positive(ValidationMessage.NHSNumberInvalid)
      .integer(ValidationMessage.NHSNumberInvalid)
      .min(1_000_000_000, ValidationMessage.NHSNumberLength)
      .max(9_999_999_999, ValidationMessage.NHSNumberLength),
    title: string().required(ValidationMessage.TitleRequired),

    firstName: string()
      .trim()
      .max(150, ValidationMessage.FirstNameExceedCharacterLimit)
      .matches(NAME_VALIDATION_REGEX, ValidationMessage.NameInvalidCharacter)
      .required(ValidationMessage.FirstNameRequired),

    lastName: string()
      .trim()
      .max(150, ValidationMessage.LastNameExceedCharacterLimit)
      .matches(NAME_VALIDATION_REGEX, ValidationMessage.NameInvalidCharacter)
      .required(ValidationMessage.LastNameRequired),

    gender: string().required(ValidationMessage.GenderRequired),
    dob: date()
      .max(new Date(), ValidationMessage.DateOfBirthFutureDate)
      .required(ValidationMessage.DateOfBirthRequired)
      .test('is-18-or-older', '', value => {
        if (!value) return false;
        const today = moment();
        const birthDate = moment(value);
        const age = today.diff(birthDate, 'years', true);
        return age >= 18;
      }),

    address: string()
      .trim()
      .max(150, ValidationMessage.AddressLine1ExceedCharacterLimit)
      .required(ValidationMessage.AddressLine1Required),
    address2: string()
      .trim()
      .nullable()
      .max(150, ValidationMessage.AddressLine2ExceedCharacterLimit),

    postcode: string()
      .trim()
      .max(7, ValidationMessage.PostcodeExceedCharacterLimit)
      .matches(POSTCODE_VALIDATION_REGEX, ValidationMessage.PostcodeInvalid)
      .required(ValidationMessage.PostcodeRequired),

    city: string()
      .trim()
      .max(50, ValidationMessage.CityExceedCharacterLimit)
      .required(ValidationMessage.CityRequired),
    country: string()
      .trim()
      .max(60, ValidationMessage.CountryExceedCharacterLimit)
      .required(ValidationMessage.CountryRequired),
  }).required(),
  isCarerDifferent: boolean().required(ValidationMessage.SelectOption),
  carer: object({
    firstName: string()
      .trim()
      .max(150, ValidationMessage.FirstNameExceedCharacterLimit)
      .matches(NAME_VALIDATION_REGEX, ValidationMessage.NameInvalidCharacter)
      .when('$isCarerDifferent', {
        is: (value: boolean) => value === true,
        then: schema => schema.required(ValidationMessage.FirstNameRequired),
        otherwise: schema => schema.notRequired(),
      }),

    lastName: string()
      .trim()
      .max(150, ValidationMessage.LastNameExceedCharacterLimit)
      .matches(NAME_VALIDATION_REGEX, ValidationMessage.NameInvalidCharacter)
      .when('$isCarerDifferent', {
        is: (value: boolean) => value === true,
        then: schema => schema.required(ValidationMessage.LastNameRequired),
        otherwise: schema => schema.notRequired(),
      }),

    email: string()
      .trim()
      .email(ValidationMessage.EmailInvalid)
      .max(350, ValidationMessage.EmailExceedCharacterLimit)
      .when('$isCarerDifferent', {
        is: (value: boolean) => {
          return value === true;
        },
        then: schema => schema.required(ValidationMessage.EmailRequired),
        otherwise: schema => schema.notRequired(),
      }),

    phoneNumber: number().when('$isCarerDifferent', {
      is: (value: boolean) => {
        return value === true;
      },
      then: schema =>
        schema
          .typeError(ValidationMessage.PhoneNumberInvalid)
          .positive(ValidationMessage.PhoneNumberInvalid)
          .integer(ValidationMessage.PhoneNumberInvalid)
          .min(10_000_00, ValidationMessage.PhoneNumberInvalid)
          .max(999_999_999_999_999, ValidationMessage.PhoneNumberInvalid)
          .required(ValidationMessage.PhoneNumberRequired),
      otherwise: schema =>
        schema
          .transform(value => (isNaN(value) ? undefined : value))
          .nullable()
          .notRequired(),
    }),

    relationship: string().when('$isCarerDifferent', {
      is: (value: boolean) => value === true,
      then: schema => schema.required(ValidationMessage.RelationshipRequired),
      otherwise: schema => schema.notRequired(),
    }),
  }).notRequired(),
});

export type PatientCarerDetailsValidatedFormData = InferType<
  typeof patientCarerDetailFormSchema
>;

export type PatientCarerDetailsFormData = {
  patient: {
    nhsNumber?: number;
    title: string;

    firstName: string;
    lastName: string;
    gender: string;
    dob: Date | null;

    address: string;
    address2?: string | null;

    postcode: string;

    city: string;
    country: string;
  };
  isCarerDifferent?: boolean;
  carer: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber: number;
    relationship?: string;
  };
};
