import {useFormik} from 'formik';
import {SetStateAction, useCallback, useState} from 'react';
import {ArrowNarrowRightIcon} from '$assets/svgs';
import {Button, MenuItem, Typography} from '@mui/material';
import FrontdeskButton from '$components/FrontdeskButton';
import {PatientDetailTwoFormData, patientDetailTwoFormSchema} from './schema';
import PatientDetailsTwoComponent from './component';
import {FormState} from '../../types';
import {Color} from '$constants/style';
import {
  useCities,
  useCountries,
  useValidatePostcode,
} from '$modules/booking/hooks';
import {OfflineBookingStep} from '../../constant';
import './styles.scss';

interface PatientDetailsTwoContainerProps {
  offlineBookingFormData: FormState;
  setActiveStep: React.Dispatch<SetStateAction<OfflineBookingStep>>;
  setOfflineBookingFormData: React.Dispatch<React.SetStateAction<FormState>>;
}

const renderItem = (item: string) => (
  <MenuItem key={item} value={item}>
    {item}
  </MenuItem>
);

const renderValue = (value: string, placeholder: string) => (
  <Typography color={value ? undefined : Color.TextPrimayPlaceholder}>
    {value || placeholder}
  </Typography>
);

const PatientDetailsTwoContainer: React.FC<PatientDetailsTwoContainerProps> = ({
  offlineBookingFormData,
  setActiveStep,
  setOfflineBookingFormData,
}) => {
  const [isPostcodeValid, setIsPostcodeValid] = useState(false);

  const handleSubmit = () => {
    const {address, address2, postcode, city, country} = formik.values.patient;
    setOfflineBookingFormData((prevState: FormState) => ({
      appointment: {
        ...prevState.appointment,
        patient: {
          ...prevState.appointment.patient,
          address: {
            ...prevState.appointment.patient.address,
            line1: address.trim(),
            line2: address2 ? address2.trim() : '',
            city: city.trim(),
            postcode: postcode.trim(),
            country: country.trim(),
          },
        },
      },
    }));
    setActiveStep(OfflineBookingStep.ContactDetails);
  };

  const {
    isLoading: isValidatingPostcode,
    isError: isPostcodeError,
    validatePostcode,
  } = useValidatePostcode(() => {
    setIsPostcodeValid(true);
    handleSubmit();
  });

  const patientDetails = offlineBookingFormData?.appointment?.patient;

  const handleFormSubmit = () => {
    if (isPostcodeValid) {
      handleSubmit();
    } else {
      validatePostcode(
        formik.values.patient.postcode,
        formik.values.patient.country
      );
    }
  };

  const formik = useFormik<PatientDetailTwoFormData>({
    initialValues: {
      patient: {
        address: patientDetails?.address.line1 || '',
        address2: patientDetails?.address.line2 || undefined,
        postcode: patientDetails?.address.postcode || '',
        city: patientDetails?.address.city || '',
        country: patientDetails?.address.country || 'GB',
      },
    },
    validationSchema: patientDetailTwoFormSchema,
    onSubmit: handleFormSubmit,
  });

  const {countries} = useCountries();
  const {cities} = useCities(formik.values?.patient?.country);
  const {setFieldTouched, setFieldValue} = formik;

  const renderCountryValue = (value: string) => {
    const item = countries.find(it => it.code === value);
    if (item) value = item.name;

    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value ? value : 'Select country'}
      </Typography>
    );
  };

  const renderCountryItem = (item: {code: string; name: string}) => (
    <MenuItem key={item.code} value={item.code}>
      {item.name}
    </MenuItem>
  );

  const renderCityValue = (value: string) => {
    const item = cities.find(it => it.code === value);
    if (item) value = item.name;

    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value === '' ? 'Select City' : value}
      </Typography>
    );
  };

  const renderCityItem = (item: {code: string; name: string}) => (
    <MenuItem key={item.code} value={item.code}>
      {item.name}
    </MenuItem>
  );

  const handleBackButtonClick = () => {
    setActiveStep(OfflineBookingStep.PatientDetailsOne);
  };

  const handleContinueClick = () => {
    formik.submitForm();
  };

  const handleFieldChange = useCallback(
    (field: string, defaultValue: unknown) => {
      const key = `patient.${field}`;
      return (value: unknown) => {
        setFieldTouched(key);
        setFieldValue(key, value || defaultValue);

        if (field === 'country') {
          setFieldValue('patient.city', '');
          setFieldTouched('patient.city', false);
        }
        if (field === 'city') {
          setFieldValue('patient.postcode', '');
          setFieldTouched('patient.postcode', false);
        }
        if (field === 'postcode' || field === 'country') {
          setIsPostcodeValid(false);
        }
      };
    },
    [setFieldTouched, setFieldValue]
  );
  return (
    <div className="patient-details-two-container">
      <PatientDetailsTwoComponent
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        handleFieldChange={handleFieldChange}
        cities={cities}
        countries={countries}
        renderCityItem={renderCityItem}
        renderCityValue={renderCityValue}
        renderCountryItem={renderCountryItem}
        renderCountryValue={renderCountryValue}
        renderItem={renderItem}
        renderValue={renderValue}
        isPostcodeError={isPostcodeError}
      />
      <div className="patient-details-two-dialog-footer">
        <Button className="back-btn" onClick={handleBackButtonClick}>
          Back
        </Button>
        <FrontdeskButton
          title="Continue"
          className="patient-details-two-continue-btn"
          endIcon={<ArrowNarrowRightIcon />}
          onClick={handleContinueClick}
          disabled={isValidatingPostcode}
          loading={isValidatingPostcode}
        />
      </div>
    </div>
  );
};

export default PatientDetailsTwoContainer;
