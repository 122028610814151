import {type SxProps} from '@mui/material';

import theme from '$modules/booking/theme';
import {scrollbarStyles} from '$styles/index';

const styles: Record<string, SxProps> = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gridGap: theme.spacing(4),

    [theme.breakpoints.up('lg')]: {
      overflowX: 'hidden',
      overflowY: 'auto',
      gridGap: theme.spacing(5),

      ...scrollbarStyles,
    },
  },
  subTitle: {
    color: theme.palette.primary.main,
  },
};

export default styles;
