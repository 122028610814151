import ListIcon from '$assets/svgs/list.svg?react';
import {AppointmentInfo} from '$modules/frontdesk/type';
import RecentUpdateItem from './RecentUpdateItem';

interface RecentUpdateComponentProps {
  appointments: AppointmentInfo[];
}

function RecentUpdateComponent(props: RecentUpdateComponentProps) {
  const {appointments} = props;

  const items = appointments.map(app => (
    <RecentUpdateItem key={app.appointment.id} appointment={app} />
  ));

  return (
    <div className="recent-updates overflow-auto flex flex-col mt-2 row-span-3">
      <div className="title-container mb-5">
        <div className="title flex items-center">
          <ListIcon className="h-6 w-6" />
          <span className="title-text">Recent updates</span>
        </div>
      </div>

      <div className="overflow-auto flex-1">{items}</div>
    </div>
  );
}

export default RecentUpdateComponent;
