import {ToastType} from '$components/Toast/constant';
import toast from '$utils/toast';

export const technicalGlitchToast = () =>
  toast(
    ToastType.Error,
    'Ops! We encountered a technical glitch',
    'Please try submitting your request shortly. Sorry for the inconvenience!'
  );

export const networkErrorToast = () =>
  toast(
    ToastType.Error,
    'Network Error',
    'Please check internet connection and try again'
  );

export const invalidLinkToast = () =>
  toast(
    ToastType.Error,
    'Invalid link',
    'Your verification link is expired or Invalid',
    'invalid-link-toast'
  );
