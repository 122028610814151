import {useParams} from 'react-router-dom';
import FetchError from '~/components/FetchError';
import NotFound from '~/components/NotFound';
import {useAppointmentDetails} from '~/services/api/appointments/hooks';
import EditBookingComponent from './component';
import EditBookingSkeleton from './EditBookingSkeleton';

const EditBookingContainer = () => {
  const params = useParams();
  const appointmentId = Number(params.id);

  const {
    appointmentDetails,
    isError: isAppointmentDetailsFetchError,
    isPending: isAppointmentDetailsPending,
  } = useAppointmentDetails({
    appointmentId,
  });

  if (!appointmentId) return <NotFound />;
  if (isAppointmentDetailsPending) return <EditBookingSkeleton />;
  if (!appointmentDetails || isAppointmentDetailsFetchError)
    return <FetchError />;

  return <EditBookingComponent appointmentDetails={appointmentDetails} />;
};

export default EditBookingContainer;
