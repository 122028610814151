import {useId} from 'react';
import {Menu} from '@mui/material';
import FrontdeskButton from '$components/FrontdeskButton';
import FilterFunnelIcon from '$assets/svgs/filter-funnel.svg?react';
import FilterItem from './FilterItem';
import {
  LAST_APPOINTMENT,
  PATIENT_STATUS,
} from '$modules/frontdesk/PatientList/constant';
import {LastAppointment, PatientStatus} from '../../constant';

interface FilterMenuComponentProps {
  archivedOnly: boolean;
  anchorElement: HTMLElement | null;
  onFilterClick: React.MouseEventHandler<HTMLButtonElement>;
  patientStatus: PatientStatus[];
  lastAppointment?: LastAppointment;
  setPatientStatus: (value: PatientStatus[]) => void;
  setLastAppointment: (value?: LastAppointment) => void;
  onMenuClose: () => void;
  onReset: () => void;
  onSave: () => void;
}

function FilterMenuComponent(props: FilterMenuComponentProps) {
  const {
    archivedOnly,
    anchorElement,
    onFilterClick,
    onMenuClose,
    patientStatus,
    lastAppointment,
    setPatientStatus,
    setLastAppointment,
    onSave,
    onReset,
  } = props;
  const menuId = useId();

  const isMenuOpen = anchorElement !== null;

  const statusItems = PATIENT_STATUS.map(item => {
    const checked = patientStatus.includes(item.value);
    const handleClick = () => {
      if (checked)
        setPatientStatus(patientStatus.filter(status => status !== item.value));
      else setPatientStatus([...patientStatus, item.value]);
    };

    return (
      <FilterItem
        key={item.value}
        name={item.title}
        checked={checked}
        onClick={handleClick}
      />
    );
  });

  const lastAppointmentItems = LAST_APPOINTMENT.map(item => {
    const checked = lastAppointment === item.value;
    const handleClick = () => {
      if (checked) setLastAppointment(undefined);
      else setLastAppointment(item.value);
    };

    return (
      <FilterItem
        key={item.value}
        name={item.title}
        checked={checked}
        onClick={handleClick}
      />
    );
  });

  return (
    <div>
      <FrontdeskButton
        variant="secondary"
        title="Filter"
        aria-controls={isMenuOpen ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        startIcon={<FilterFunnelIcon />}
        onClick={onFilterClick}
      />
      <Menu
        className="filter-menu-container"
        id={menuId}
        open={isMenuOpen}
        anchorEl={anchorElement}
        onClose={onMenuClose}
      >
        {/* Having two explicit checks because */}
        {/* Menu component does not accept fragment as a child */}
        {!archivedOnly && <h4 className="sub-heading">Status</h4>}
        {!archivedOnly && statusItems}

        <h4 className="sub-heading">Last appointment</h4>
        {lastAppointmentItems}

        <div className="action-button-container">
          <FrontdeskButton
            title="Reset"
            variant="tertiary-filled"
            onClick={onReset}
          />
          <FrontdeskButton title="Apply" onClick={onSave} />
        </div>
      </Menu>
    </div>
  );
}

export default FilterMenuComponent;
