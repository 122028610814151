import {SetStateAction} from 'react';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LeftArrowIcon from '$assets/svgs/left-arrow.svg?react';
import RightArrowIcon from '$assets/svgs/right-arrow.svg?react';
import FrontdeskButton from '$components/FrontdeskButton';
import CalendarRangeChange from '../CalendarView/CalendarRangeChange';
import CalendarTimeline from '../CalendarTimeline';
import {AppointmentDetail, AppointmentInfo, Slot} from '../../type';
import {dateToTimeShort} from '$utils/date';
import {
  appointmentDetailToCalendarEvent,
  appointmentInfoToCalendarEvent,
} from '../../utils';
import {useNavigate} from 'react-router-dom';
import TimeSlotPicker from '$components/TimeSlotPicker';
import {RoutePath} from '$constants/routes';
import {RouteSection, getRoute} from '$utils/route';
import SelectInput from '$components/SelectInput';
import {MenuItem, Typography} from '@mui/material';
import {Color} from '$constants/style';
import {BookingPreferredTime} from '$modules/booking/types';
import {BOOKING_PREFERRED_TIMES} from '$modules/booking/constant';

interface ConfirmBookingComponentProps {
  preferredTime: string;
  setPreferredTime: React.Dispatch<SetStateAction<string>>;
  slotsStartTime?: Date;
  slotsEndTime?: Date;
  appointmentDetails: AppointmentDetail;
  disableRangeChange?: boolean;
  appointments: AppointmentInfo[];
  slots: Slot[];
  setSelectedSlot: React.Dispatch<SetStateAction<Slot | undefined>>;
  isPending: boolean;
  isSlotsLoading: boolean;
  disableSubmit: boolean;
  selectedSlot?: Slot;
  selectedDate: Date;
  setSelectedDate: React.Dispatch<SetStateAction<Date>>;
  onNextClick: () => void;
  onPrevClick: () => void;
  onConfirmBookingClick: () => void;
}

function ConfirmBookingComponent(props: ConfirmBookingComponentProps) {
  const navigate = useNavigate();
  const {
    preferredTime,
    slotsStartTime,
    slotsEndTime,
    appointmentDetails,
    appointments,
    selectedSlot,
    slots,
    isPending,
    isSlotsLoading,
    disableSubmit,
    selectedDate,
    setSelectedSlot,
    setPreferredTime,
    onNextClick,
    onPrevClick,
    onConfirmBookingClick,
  } = props;

  const appointmentId = appointmentDetails.appointment.id;
  const appointmentStatus = appointmentDetails.appointment.status;
  const consultantName = appointmentDetails.doctor.fullName;

  const events = appointments
    .filter(app => app.appointment.id !== appointmentDetails.appointment.id)
    .map(appointmentInfoToCalendarEvent);
  const activeEvents = selectedSlot
    ? [
        appointmentDetailToCalendarEvent(appointmentDetails, {
          start: selectedSlot.startTime,
          end: selectedSlot.endTime,
        }),
      ]
    : [];

  const selectedStartTime = selectedSlot
    ? dateToTimeShort(selectedSlot.startTime)
    : '';
  const selectedEndTime = selectedSlot
    ? dateToTimeShort(selectedSlot.endTime)
    : '';

  const dateText = selectedDate.toLocaleString('en-US', {
    day: 'numeric',
    month: 'short',
  });

  const handleAppointmentNavigation = () => {
    navigate(
      getRoute(
        RouteSection.Calendar,
        RoutePath.staff.calendar.bookingDetails,
        appointmentId
      )
    );
  };

  const renderPreferredTimeValue = (value: string) => {
    if (!value) {
      return (
        <Typography color={Color.TextPrimayPlaceholder}>
          Preferred time
        </Typography>
      );
    }

    const item = BOOKING_PREFERRED_TIMES.find(it => it.id === value);
    if (!item) {
      return (
        <Typography color={Color.TextPrimayPlaceholder}>
          Preferred time
        </Typography>
      );
    }

    return <Typography>{item.title}</Typography>;
  };

  const renderPreferredTimeItem = (item: {
    id: BookingPreferredTime;
    title: string;
  }) => (
    <MenuItem key={item.id} value={item.id}>
      {item.title}
    </MenuItem>
  );

  const handlePreferredTimeChange = (value: string) => {
    setPreferredTime(value);
    setSelectedSlot(undefined);
  };

  const handleBackClick = () => {
    navigate(
      getRoute(
        RouteSection.Calendar,
        RoutePath.staff.calendar.bookingDetails,
        appointmentId
      )
    );
  };

  return (
    <div className="confirm-booking-container flex flex-col flex-1 pt-5 pb-10 w-full overflow-auto">
      <div className="primary-dark flex gap-3 items-center px-10">
        <IconButton className="primary-dark" onClick={handleBackClick}>
          <LeftArrowIcon className="primary-dark" />
        </IconButton>
        <span className="font-normal text-base">Back</span>
      </div>

      <Divider className="mt-3 mx-10" />

      <div className="overflow-auto px-10 flex-1">
        <h2 className="primary-dark text-2xl font-semibold mb-10 mt-6">
          Confirm Booking
        </h2>

        <h3 className="font-semibold text-lg mb-3">Preferred time</h3>
        <div className="mb-10 flex flex-col max-w-80">
          <SelectInput
            items={BOOKING_PREFERRED_TIMES}
            placeholder="Preferred time"
            value={preferredTime}
            onChange={handlePreferredTimeChange}
            renderItem={renderPreferredTimeItem}
            renderValue={renderPreferredTimeValue}
          />
        </div>

        <h3 className="font-semibold text-lg mb-3">Time frame</h3>
        {slots && slots.length > 0 && (
          <TimeSlotPicker
            isLoading={isSlotsLoading}
            consultantName={consultantName}
            preferredTime={preferredTime}
            slots={slots}
            selectedSlot={selectedSlot}
            setSelectedSlot={setSelectedSlot}
          />
        )}
        {selectedSlot && (
          <>
            <h3 className="font-semibold text-lg mb-3">Appointment duration</h3>
            <p className="font-normal text-base flex gap-4 mb-10">
              <span>From</span>{' '}
              <span className="primary font-semibold underline">
                {selectedStartTime}
              </span>{' '}
              <span>to</span>{' '}
              <span className="primary font-semibold underline">
                {selectedEndTime}
              </span>
            </p>
          </>
        )}
        <h3 className="font-semibold text-lg mb-3">Consultant’s schedule</h3>
        <div className="flex">
          <CalendarRangeChange
            rangeText={dateText}
            onPrev={onPrevClick}
            onNext={onNextClick}
          />
        </div>

        <div className="flex-1">
          {slotsStartTime && slotsEndTime && (
            <CalendarTimeline
              appointmentStatus={appointmentStatus}
              slotsStartTime={slotsStartTime}
              slotsEndTime={slotsEndTime}
              activeEvents={activeEvents}
              events={events}
            />
          )}
        </div>
      </div>

      <div className="flex justify-between px-10 pt-5">
        <FrontdeskButton
          variant="secondary"
          title="Back"
          startIcon={<LeftArrowIcon />}
          onClick={handleAppointmentNavigation}
        />
        <FrontdeskButton
          loading={isPending}
          disabled={disableSubmit || !selectedSlot}
          title="Send booking confirmation"
          startIcon={
            <span className="flex h-4 w-4 justify-center items-center">
              <RightArrowIcon />
            </span>
          }
          onClick={onConfirmBookingClick}
        />
      </div>
    </div>
  );
}

export default ConfirmBookingComponent;
