import {ApiRoute} from '$constants/api';
import {UserRoleName} from '$modules/admin/constant';
import {
  NotificationSubTab,
  NotificationType,
} from '$modules/frontdesk/Notifications/constant';
import axiosInstance from '.';

export interface GetNotificationListResponseBody {
  success: boolean;
  data: {
    notifications: {
      id: number;
      title: string;
      description: string;
      notification_type: NotificationType;
      created_at: string;
      seen_at: string | null;
      sender_id: number;
      sender_type: UserRoleName;
      sender_name: string;
      unread: boolean;
      resource_id: number;
      resource_type: string;
    }[];
    total_pages: number;
    total_records: number;
    new_booking_request_count: number;
    pending_request_count: number;
    cancelled_appointment_count: number;
    new_patients_count: number;
    all_notification_count: number;
  };
  message: string;
}

export interface GetNotificationListParams {
  pageNumber?: number;
  perPage?: number;
  status?: 'read' | 'unread';
  type?: NotificationSubTab;
}

export async function getNotificationList(params: GetNotificationListParams) {
  const response = await axiosInstance.get<GetNotificationListResponseBody>(
    ApiRoute.Notification,
    {
      params: {
        page_no: params.pageNumber,
        per_page: params.perPage,
        status: params.status,
        notification_type: params.type,
      },
    }
  );

  return response.data;
}

export interface GetNotificationUnreadCountResponseBody {
  success: boolean;
  data: {
    unread_notifications_count: number;
  };
  message: '';
}

export async function getNotificationUnreadCount() {
  const response =
    await axiosInstance.get<GetNotificationUnreadCountResponseBody>(
      `${ApiRoute.Notification}/unread_count`
    );

  return response.data;
}

export interface PutNotificationMarkReadResponseBody {
  success: boolean;
  data: never[];
  message: string;
}

export interface PutNotificationMarkReadVariables {
  notificationId?: number;
}

export async function putNotificationMarkRead(
  variables: PutNotificationMarkReadVariables
) {
  const response = await axiosInstance.put<PutNotificationMarkReadResponseBody>(
    `${ApiRoute.Notification}/mark_read`,
    {notification_id: variables.notificationId}
  );

  return response.data;
}

export interface PostPushSubscriptionRequestData {
  subscription: PushSubscriptionJSON;
}

export interface PostPushSubscriptionResponseData {
  message: string;
}

export interface PostPushSubscriptionVariables {
  subscription: PushSubscriptionJSON;
}

export async function postSendPushSubscription(
  variables: PostPushSubscriptionVariables
) {
  const {subscription} = variables;

  const response = await axiosInstance.post<PostPushSubscriptionResponseData>(
    `${ApiRoute.PushNotification}`,
    {subscription: subscription}
  );

  return response.data;
}
