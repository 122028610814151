import {getOperationTheatre} from '$api/frontdesk';
import {useQuery} from '@tanstack/react-query';

export const useOperationTheatreHook = (
  date?: Date,
  startTime?: Date,
  endTime?: Date
) => {
  const {isPending, isError, error, data} = useQuery({
    queryKey: ['operation-theatre', date, startTime, endTime],
    queryFn: () => getOperationTheatre({date, startTime, endTime}),
  });

  return {
    isPending,
    isError,
    error,
    data,
  };
};
