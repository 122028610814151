import {useMemo} from 'react';
import {Notification} from './types';
import {segregateNotifications} from './utils';
import Section from './Section';
import NotificationLoading from './NoficationLoading';

interface NotificationSectionListProps {
  notifications: Notification[];
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
}

function NotificationSectionList(props: NotificationSectionListProps) {
  const {notifications, isFetchingNextPage, hasNextPage, fetchNextPage} = props;

  const sections = useMemo(() => {
    const groupedNotifs = segregateNotifications(notifications);

    return [
      {title: 'Today', notifications: groupedNotifs.today},
      {title: 'This week', notifications: groupedNotifs.thisWeek},
      {title: 'This month', notifications: groupedNotifs.thisMonth},
      {title: 'Past notifications', notifications: groupedNotifs.past},
    ];
  }, [notifications]);

  const sectionItems = sections.map(sec =>
    sec.notifications.length ? (
      <Section
        key={sec.title}
        title={sec.title}
        notifications={sec.notifications}
      />
    ) : null
  );

  const handleScroll: React.UIEventHandler<HTMLDivElement> = ev => {
    const currentTarget = ev.currentTarget;
    const scrollTop = currentTarget.scrollTop;
    const totalScroll = currentTarget.scrollHeight - currentTarget.clientHeight;

    const percent = (scrollTop / totalScroll) * 100;

    if (percent > 95 && !isFetchingNextPage && hasNextPage) fetchNextPage();
  };

  return (
    <div className="overflow-auto flex-1" onScroll={handleScroll}>
      <div className="max-w-6xl">
        {sectionItems}
        {!notifications.length && (
          <div className="flex flex-1 justify-center items-center">
            <p className="text-xl tertiary">No notifications found</p>
          </div>
        )}
        {isFetchingNextPage && <NotificationLoading />}
      </div>
    </div>
  );
}

export default NotificationSectionList;
