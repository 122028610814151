import {type SxProps} from '@mui/material';
import theme from '$modules/booking/theme';

const styles: Record<string, SxProps> = {
  iconButton: {
    borderRadius: '8px',
    background: theme.palette.grey[200],

    svg: {
      width: '24px',
      height: '24px',
      color: theme.palette.text.secondary,
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      svg: {
        color: theme.palette.primary2.darkest,
      },
    },

    '&.Mui-disabled': {
      background: theme.palette.grey[200],
      opacity: '0.6',

      svg: {
        color: theme.palette.text.secondary,
      },
    },
  },
};

export default styles;
