import ErrorIcon from '$assets/svgs/error.svg?react';
import theme from '$modules/booking/theme';
import styles, {Container, IconWrap, TextareaAutosize} from './styles';
import {SxProps} from '@mui/material';
import {TextAreaProps} from './types';

function TextArea(props: TextAreaProps) {
  const {
    name,
    placeholder,
    error,
    disabled = false,
    value,
    onBlur,
    onChange,
  } = props;

  let sx: SxProps = {...theme.typography.caption};

  if (disabled) {
    sx = {...sx, ...styles.disabledTextArea};
  } else if (error) {
    sx = {...sx, ...styles.errorTextArea};
  }

  return (
    <Container>
      <TextareaAutosize
        name={name}
        aria-label="empty textarea"
        placeholder={placeholder}
        minRows={5}
        maxRows={5}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        sx={sx}
      />
      {error && (
        <IconWrap sx={styles.iconWrap}>
          <ErrorIcon />
        </IconWrap>
      )}
    </Container>
  );
}

export default TextArea;
