import {loadAuth, resetAuth} from '~/redux/authSlice';
import {UserAuthData} from '~/redux/authSlice/types';
import store from '~/redux/store';
import LocalStorage from './localStore';

export function isLoggedIn(): boolean {
  return store.getState().auth.token !== null;
}

export function getAuthToken(): string | null {
  return store.getState().auth.token;
}

export function getRefreshToken(): string | null {
  return store.getState().auth.refreshToken;
}

export function getRefreshPending(): boolean {
  return store.getState().auth.isRefreshPending;
}

export function login(token: string, user: UserAuthData, refreshToken: string) {
  const authData = {token, refreshToken, user};

  LocalStorage.setItem(LocalStorage.AUTH_USER_DATA, JSON.stringify(authData));
  store.dispatch(loadAuth(authData));
}

export function logout() {
  store.dispatch(resetAuth());
  LocalStorage.removeItem(LocalStorage.AUTH_USER_DATA);
}
