import createTheme from '@mui/material/styles/createTheme';
import {Border, Color, Font, THEME_SHADOWS} from '$constants/style';

const theme = createTheme({
  palette: {
    common: {
      black: Color.Black,
      white: Color.White,
    },
    primary: {
      main: Color.PrimaryMain,
      light: Color.PrimaryLight,
      dark: Color.PrimaryDark,
    },
    primary2: {
      main: Color.Primary2Main,
      light: Color.Primary2Light,
      dark: Color.Primary2Dark,
      darkest: Color.Primary2Darkest,
    },
    secondary: {
      main: Color.SecondaryMain,
      light: Color.SecondaryMain,
      dark: Color.SecondaryDark,
    },
    text: {
      primary: Color.TextPrimary,
      secondary: Color.TextSecondary,
      tertiary: Color.TextTertiary,
    },
    grey: {
      50: Color.Grey50,
      100: Color.Grey100,
      200: Color.Grey200,
      300: Color.Grey300,
      400: Color.Grey400,
      500: Color.Grey500,
    },
    error: {
      main: Color.ErrorMain,
      light: Color.ErrorLight,
    },
    success: {
      main: Color.SuccessMain,
      light: Color.SuccessLight,
    },
    pending: {
      main: Color.PendingMain,
    },
    customGradients: {
      background: Color.GradientBackground,
    },
  },

  typography: {
    htmlFontSize: 16,
    fontFamily: Font.Lexend,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: Font.Lexend,
      fontSize: '3rem',
      lineHeight: 1.5,
      fontWeight: 700,
      letterSpacing: 0,
      color: Color.Grey500,
    },
    h2: {
      fontFamily: Font.Lexend,
      fontSize: '2rem',
      lineHeight: 1.5,
      fontWeight: 600,
      letterSpacing: 0,
      color: Color.Grey500,
    },
    h3: {
      fontFamily: Font.Lexend,
      fontSize: '1.5rem',
      lineHeight: 1.5,
      fontWeight: 700,
      letterSpacing: 0,
      color: Color.Grey500,
    },
    h4: {
      fontFamily: Font.Lexend,
      fontSize: '1.125rem',
      lineHeight: 1.5,
      fontWeight: 600,
      letterSpacing: 0,
      color: Color.TextSecondary,
    },
    subtitle1: {
      fontFamily: Font.Lexend,
      fontSize: '0.875rem',
      lineHeight: 1.5,
      fontWeight: 400,
      letterSpacing: 0,
    },
    subtitle2: {
      fontFamily: Font.Lexend,
      fontSize: '0.875rem',
      lineHeight: 1.5,
      fontWeight: 600,
      letterSpacing: 0,
    },
    body1: {
      fontFamily: Font.Lexend,
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 400,
      letterSpacing: 0,
    },
    body2: {
      fontFamily: Font.Lexend,
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 600,
      letterSpacing: 0,
    },
    caption: {
      fontFamily: Font.Lexend,
      fontSize: '0.875rem',
      lineHeight: 1.5,
      fontWeight: 300,
      letterSpacing: 0,
    },
  },

  // It is currently required to have 25 shadows
  // https://github.com/mui/material-ui/issues/28820
  shadows: THEME_SHADOWS,

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          padding: '0.5rem 1.5rem',
          borderRadius: '0.5rem',

          '&.MuiButton-sizeSmall': {
            fontSize: '0.875rem',
            padding: '0.25rem 1.5rem',
          },

          '&.MuiButton-sizeLarge': {
            fontSize: '1.25rem',
          },
        },
        // Style overrides for the Button component
        containedPrimary: {
          background: Color.GradientBackground,
          color: Color.White,
          boxShadow: 'none',
          fontWeight: '400',
          padding: '0.5rem 1.5rem',
          textTransform: 'capitalize',

          '&:hover': {
            background: Color.GradientHoverBackground,
          },

          '&:focus': {
            background: Color.GradientHoverBackground,
          },

          '&.Mui-disabled': {
            background: Color.GradientDisabledBackground, // Background color with 42% opacity for disabled buttons
            color: Color.BlackOpacity40,
          },
        },
        containedSecondary: {
          background: Color.White,
          color: Color.PrimaryMain,
          border: Border.Normal,
          boxShadow: 'none',
          fontWeight: '400',
          padding: '0.5rem 1.5rem',
          textTransform: 'capitalize',

          '&:hover': {
            background: Color.Grey200,
            color: Color.PrimaryMain,
            border: Border.Normal,
          },

          '&:focus': {
            background: Color.Grey200,
            color: Color.PrimaryMain,
            border: Border.Normal,
          },

          '&.Mui-disabled': {
            background: Color.ButtonDisabledBackground,
            color: Color.BlackOpacity60,
            border: Border.Disabled,
          },
        },
        outlinedSecondary: {
          background: Color.White,
          color: Color.PrimaryMain,
          border: Border.Normal,
          boxShadow: 'none',
          padding: '0.5rem 1.5rem',
          textTransform: 'capitalize',

          '&:hover': {
            background: Color.Grey200,
            color: Color.PrimaryMain,
            border: Border.Normal,
          },

          '&:focus': {
            background: Color.Grey200,
            color: Color.PrimaryMain,
            border: Border.Normal,
          },

          '&.Mui-disabled': {
            background: Color.ButtonDisabledBackground,
            color: Color.BlackOpacity60,
            border: Border.Disabled,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          borderRadius: '0.25rem',
          borderColor: Color.PrimaryDark,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '0.625rem',
          boxShadow: THEME_SHADOWS[1],
        },
      },
    },
  },
});

export default theme;
