import {AxiosError} from 'axios';
import {useState} from 'react';
import {useMutation} from '@tanstack/react-query';
import {
  postVerifyPatientContactEmail,
  PostPatientEmailErrorResponseData,
  PostPatientEmailPayload,
} from '$api/bookingFlow';
import {ToastType} from '$components/Toast/constant';
import {
  networkErrorToast,
  technicalGlitchToast,
} from '$constants/toastmessages';
import toast from '$utils/toast';

export function useValidateUnqEmail(onSuccess: () => void) {
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {isPending, mutate} = useMutation({
    mutationKey: ['validate-unq-email'],
    mutationFn: postVerifyPatientContactEmail,
    onSuccess: onSuccess,
    onError: error => {
      if (error instanceof AxiosError) {
        const axiosError =
          error as AxiosError<PostPatientEmailErrorResponseData>;
        const response = axiosError.response;

        if (response) {
          if (response.status === 500) {
            return technicalGlitchToast();
          }

          if (response.data) {
            const data = response.data;
            return toast(
              ToastType.Error,
              'Email verification error',
              data.message
            );
          }
        }

        if (axiosError.message === 'Network Error') {
          return networkErrorToast();
        }

        return technicalGlitchToast();
      } else {
        technicalGlitchToast();
      }
    },
    onSettled: () => setDisableSubmit(false),
  });

  const verifyEmail = (variables: PostPatientEmailPayload) => {
    setDisableSubmit(true);
    mutate(variables);
  };

  return {
    disableSubmit,
    isPending,
    verifyEmail,
  };
}
