import React from 'react';
import {
  CHECK_IN_PATIENT_TITLE_DESCRIPTION_IF_PATIENT_PROFILE_DOES_NOT_EXISTS,
  CHECK_IN_PATIENT_TITLE_DESCRIPTION_IF_PATIENT_PROFILE_EXISTS,
  CHECK_IN_PATIENT_TITLE_PREFIX,
  CHECK_IN_PATIENT_TITLE_SUFFIX,
} from './constant';
import {Button} from '@mui/material';
import FrontdeskButton from '$components/FrontdeskButton';
import {useCheckInPatientHook} from '../../hooks/useCheckInPatientHook';
import './styles.scss';
import {formatFullName} from '$utils/helper';

interface CheckInPatientComponentProps {
  checkInPatientModalOpen: boolean;
  setCheckInPatientModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  appointmentId: number;
  patientProfileExist: boolean;
  patientName: string;
}

const CheckInPatientComponent: React.FC<CheckInPatientComponentProps> = ({
  checkInPatientModalOpen,
  setCheckInPatientModalOpen,
  appointmentId,
  patientProfileExist,
  patientName,
}) => {
  const {isPending, sendCheckInPatient} = useCheckInPatientHook(
    setCheckInPatientModalOpen
  );

  const handleConfirmClick = () => {
    const payload = {
      appointmentId: appointmentId,
      check_in_time: new Date().toISOString(),
      check_out_time: '',
    };
    sendCheckInPatient(payload);
  };

  return (
    <div className="checkin-patient-container">
      <div className="checkin-patient-title">
        <div className="title-text">
          {`${CHECK_IN_PATIENT_TITLE_PREFIX} ${formatFullName(patientName)} ${
            patientProfileExist ? '?' : CHECK_IN_PATIENT_TITLE_SUFFIX
          }`}
        </div>
        <div className="title-desc">
          {patientProfileExist
            ? CHECK_IN_PATIENT_TITLE_DESCRIPTION_IF_PATIENT_PROFILE_EXISTS
            : CHECK_IN_PATIENT_TITLE_DESCRIPTION_IF_PATIENT_PROFILE_DOES_NOT_EXISTS}
        </div>
      </div>
      <div className="checkin-patient-footer">
        <Button
          className="cancel-btn"
          onClick={() => setCheckInPatientModalOpen(!checkInPatientModalOpen)}
        >
          Cancel
        </Button>
        <FrontdeskButton
          title="Confirm"
          loading={isPending}
          onClick={handleConfirmClick}
        />
      </div>
    </div>
  );
};

export default CheckInPatientComponent;
