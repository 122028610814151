import {ApiRoute} from '$constants/api';
import {UserData} from '$modules/admin/types';
import store from '$redux/store';
import axiosInstance from '.';

export interface GetUsersListResponseData {
  data: {
    id: number;
    profile_url: string | null;
    full_name: string;
    hospital_id: number | null;
    email: string;
    country_code: string;
    mobile_number: string;
    role_name: string;
  }[];
  message: string;
}

export async function getUsersList(): Promise<{users: UserData[]}> {
  const state = store.getState();
  const hospitalId = state.auth.user?.hospitalId;
  const token = state.auth.token;

  const response = await axiosInstance.get<GetUsersListResponseData>(
    ApiRoute.Users,
    {
      params: {
        hospital_id: hospitalId,
      },
      headers: {
        Authorization: token,
      },
    }
  );

  const responseData = response.data;

  return {
    users:
      responseData?.data?.map(item => ({
        id: item.id,
        profileUrl: item.profile_url,
        fullName: item.full_name,
        hospitalId: item.hospital_id,
        email: item.email,
        countryCode: item.country_code,
        mobileNumber: item.mobile_number,
        roleName: item.role_name,
      })) || [],
  };
}

export interface PostUserResponseData {
  message: string;
}

export interface PostUserVariables {
  firstName: string;
  lastName: string;
  countryCode: string;
  mobileNumber?: number | null;
  email: string;
  roleId: number;
}

export async function postCreateUser(variables: PostUserVariables) {
  const state = store.getState();
  const hospitalId = state.auth.user?.hospitalId;
  const token = state.auth.token;

  const {firstName, lastName, countryCode, mobileNumber, email, roleId} =
    variables;

  const response = await axiosInstance.post<PostUserResponseData>(
    ApiRoute.Users,
    {
      user: {
        first_name: firstName,
        last_name: lastName,
        country_code: countryCode,
        mobile_number: mobileNumber,
        email: email,
        role_id: roleId,
        hospital_id: hospitalId,
      },
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return response.data;
}
