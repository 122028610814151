import {PatientDetailTab} from '../constant';
import OverviewComponent from './component';
interface OverviewContainerProps {
  setActiveTab: React.Dispatch<React.SetStateAction<PatientDetailTab>>;
  setOverviewSelectedNoteId: React.Dispatch<
    React.SetStateAction<number | null>
  >;
}
const OverviewContainer: React.FC<OverviewContainerProps> = ({
  setActiveTab,
  setOverviewSelectedNoteId,
}) => {
  return (
    <OverviewComponent
      setActiveTab={setActiveTab}
      setOverviewSelectedNoteId={setOverviewSelectedNoteId}
    />
  );
};

export default OverviewContainer;
