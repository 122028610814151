import {ValidationError} from 'yup';
import {
  AppointmentRequestData,
  PostLoginResponseData,
  PostValidateOtpResponseData,
} from '$api/bookingFlow';
import {
  AppSummaryViewChoices,
  BookingChoices,
  BookingChoicesAction,
  BookingChoicesActionType,
  CareSelectionViewChoices,
  ChoicesConfirmationViewChoices,
  ConsultantSelectionViewChoices,
  DateSelectionViewChoices,
  PatientContactDetailViewChoices,
  GPOrOpticianReferralViewChoices,
  RegisterWithStoneygateViewChoices,
  PatientCarerDetailsViewChoices,
  PatientInsuranceDetailViewChoices,
  LastStepViewChoices,
  BookingData,
  BookingPreferredTime,
  SubcareSelectionViewChoices,
} from './types';
import {passwordSchema} from './components/LastStepForm/schema';
import store from '$redux/store';
import {isNumber} from '$utils/number';

export function bookingChoicesReducer(
  state: BookingChoices,
  action: BookingChoicesAction
): BookingChoices {
  switch (action.type) {
    case BookingChoicesActionType.setAppointment:
      return {
        ...state,
        appointment: action.payload,
      };
    case BookingChoicesActionType.unsetAppointment:
      return {
        ...state,
        appointment: undefined,
      };
    case BookingChoicesActionType.setCare:
      return {
        ...state,
        care: action.payload,
      };
    case BookingChoicesActionType.unsetCare:
      return {
        ...state,
        care: undefined,
      };
    case BookingChoicesActionType.setSubcare:
      return {
        ...state,
        subcare: action.payload,
      };
    case BookingChoicesActionType.unsetSubcare:
      return {
        ...state,
        subcare: undefined,
      };
    case BookingChoicesActionType.setConsultant:
      return {
        ...state,
        consultant: action.payload,
      };
    case BookingChoicesActionType.unsetConsultant:
      return {
        ...state,
        consultant: undefined,
      };
    case BookingChoicesActionType.setBookingAppointmentCare:
      return {
        ...state,
        appointment: action.payload.appointment,
        care: action.payload.care,
        subcare: action.payload.subcare,
      };
    case BookingChoicesActionType.setDate:
      return {
        ...state,
        date: action.payload,
      };
    case BookingChoicesActionType.setTime:
      return {
        ...state,
        time: action.payload,
      };
    case BookingChoicesActionType.setRegistered:
      return {
        ...state,
        registered: action.payload,
      };
    case BookingChoicesActionType.setPatientCarerDetails:
      return {
        ...state,
        patientCarerDetails: action.payload,
      };
    case BookingChoicesActionType.unsetPatientCarerDetails:
      return {
        ...state,
        patientCarerDetails: undefined,
      };
    case BookingChoicesActionType.setGPOrOpticianReferral:
      return {
        ...state,
        gpOrOpticianReferral: action.payload,
      };
    case BookingChoicesActionType.unsetGPOrOpticianReferral:
      return {
        ...state,
        gpOrOpticianReferral: undefined,
      };
    case BookingChoicesActionType.setLoginData:
      return {
        ...state,
        loginData: action.payload,
      };
    case BookingChoicesActionType.setPatientContactDetail:
      return {
        ...state,
        patientContactDetail: action.payload,
      };
    case BookingChoicesActionType.unsetPatientContactDetail:
      return {
        ...state,
        patientContactDetail: undefined,
      };
    case BookingChoicesActionType.setPatientInsuranceDetails:
      return {
        ...state,
        patientInsuranceDetail: action.payload,
      };

    case BookingChoicesActionType.setCreateAppointment:
      return {
        ...state,
        createAppointment: action.payload,
      };
    case BookingChoicesActionType.setLastStep:
      return {
        ...state,
        lastStep: action.payload,
      };
    default:
      return state;
  }
}

export function isCareSelectionViewChoices(
  choices: BookingData
): choices is CareSelectionViewChoices {
  return choices.appointment !== undefined;
}

export function isSubcareSelectionViewChoices(
  choices: BookingData
): choices is SubcareSelectionViewChoices {
  return choices.appointment !== undefined && choices.care !== undefined;
}

export function isConsultantSelectionViewChoice(
  choices: BookingData
): choices is ConsultantSelectionViewChoices {
  return (
    choices.appointment !== undefined &&
    choices.care !== undefined &&
    choices.subcare !== undefined
  );
}

export function isDateSelectionViewChoice(
  choices: BookingData
): choices is DateSelectionViewChoices {
  return (
    choices.appointment !== undefined &&
    choices.care !== undefined &&
    choices.subcare !== undefined &&
    choices.consultant !== undefined
  );
}

export function isChoicesConfirmationViewChoice(
  choices: BookingData
): choices is ChoicesConfirmationViewChoices {
  return (
    choices.appointment !== undefined &&
    choices.care !== undefined &&
    choices.subcare !== undefined &&
    choices.consultant !== undefined &&
    choices.date !== undefined &&
    choices.slot !== undefined
  );
}
export function isRegisterWithStoneygateViewChoice(
  choices: BookingData
): choices is RegisterWithStoneygateViewChoices {
  return (
    choices.appointment !== undefined &&
    choices.care !== undefined &&
    choices.subcare !== undefined &&
    choices.consultant !== undefined &&
    choices.date !== undefined &&
    choices.slot !== undefined
  );
}

export function isPatientCarerDetailsViewChoice(
  choices: BookingData
): choices is PatientCarerDetailsViewChoices {
  return (
    choices.appointment !== undefined &&
    choices.care !== undefined &&
    choices.subcare !== undefined &&
    choices.consultant !== undefined &&
    choices.date !== undefined &&
    choices.slot !== undefined &&
    choices.registered !== undefined
  );
}

export function isPatientContactDetailViewChoice(
  choices: BookingData
): choices is PatientContactDetailViewChoices {
  return (
    choices.appointment !== undefined &&
    choices.care !== undefined &&
    choices.subcare !== undefined &&
    choices.consultant !== undefined &&
    choices.date !== undefined &&
    choices.slot !== undefined &&
    choices.registered !== undefined &&
    choices.patientCarerDetails !== undefined &&
    choices.gpOrOpticianReferral !== undefined
  );
}

export function isGPOrOpticianReferralViewViewChoice(
  choices: BookingData
): choices is GPOrOpticianReferralViewChoices {
  return (
    choices.appointment !== undefined &&
    choices.care !== undefined &&
    choices.subcare !== undefined &&
    choices.consultant !== undefined &&
    choices.date !== undefined &&
    choices.slot !== undefined &&
    choices.registered !== undefined &&
    choices.patientCarerDetails !== undefined
  );
}

export function isPatientInsuranceDetailViewChoice(
  choices: BookingData
): choices is PatientInsuranceDetailViewChoices {
  return (
    choices.appointment !== undefined &&
    choices.care !== undefined &&
    choices.subcare !== undefined &&
    choices.consultant !== undefined &&
    choices.date !== undefined &&
    choices.slot !== undefined &&
    choices.registered !== undefined &&
    choices.patientCarerDetails !== undefined &&
    choices.patientContactDetail !== undefined
  );
}

export function isLastStepViewChoices(
  choices: BookingData
): choices is LastStepViewChoices {
  return (
    choices.appointment !== undefined &&
    choices.care !== undefined &&
    choices.subcare !== undefined &&
    choices.consultant !== undefined &&
    choices.date !== undefined &&
    choices.slot !== undefined &&
    choices.registered !== undefined &&
    choices.patientCarerDetails !== undefined &&
    choices.gpOrOpticianReferral !== undefined &&
    choices.patientContactDetail !== undefined
  );
}

export function isAppSummaryViewChoice(
  choices: BookingData
): choices is AppSummaryViewChoices {
  return (
    choices.appointment !== undefined &&
    choices.care !== undefined &&
    choices.subcare !== undefined &&
    choices.consultant !== undefined &&
    choices.date !== undefined &&
    choices.slot !== undefined &&
    choices.registered !== undefined &&
    choices.patientCarerDetails !== undefined &&
    choices.gpOrOpticianReferral !== undefined &&
    choices.patientContactDetail !== undefined
  );
}

export function getNameInitials(name: string) {
  return name.trim()[0] || '-';
}

export function loginResponseToBookingData(
  responseData: PostLoginResponseData
): BookingData {
  const {data} = responseData;

  const {
    prefix,
    first_name,
    nhs_number,
    last_name,
    gender,
    dob,
    address,
    email,
    preferred_point_of_contact,
    last_appointment_details,
  } = data.patient;

  const contactNumber = last_appointment_details
    ? Number(last_appointment_details?.contact_mobile_number)
    : NaN;

  const nhsNumber = nhs_number ? Number(nhs_number) : NaN;

  return {
    patientContactDetail: {
      patient: {
        email: email,
        phoneNumber: contactNumber,
        preferredContactMethod: preferred_point_of_contact,
      },
    },
    patientCarerDetails: {
      isCarerDifferent: false,
      patient: {
        nhsNumber: nhsNumber,
        title: prefix,
        firstName: first_name,
        lastName: last_name,
        gender: gender,
        dob: new Date(dob),
        city: address?.city,
        address: address?.line1,
        address2: address?.line2,
        country: address?.country,
        postcode: address?.postcode,
      },
    },
  };
}

export function bookingChoicesToAppointmentRequestBody(
  choices: BookingData
): AppointmentRequestData {
  const {
    appointment,
    care,
    subcare,
    consultant,
    date,
    slot,
    patientCarerDetails,
    patientContactDetail,
    gpOrOpticianReferral,
    appointmentId,
    password,
    patientInsuranceDetail,
    registered,
  } = choices;

  const patientUser = store.getState().auth.user;

  const carerDetails = patientCarerDetails?.carer;
  const patientDetails = patientCarerDetails?.patient;

  const nhsNumber = isNumber(patientDetails?.nhsNumber)
    ? patientDetails?.nhsNumber
    : undefined;

  return {
    appointment: {
      id: appointmentId,
      appointment_type_id: appointment?.id,
      care_type_id: care?.id,
      sub_care_type_id: subcare?.id,
      date: date?.toISOString(),
      doctor_id: consultant?.id,
      preferred_time: BookingPreferredTime.All,
      start_time: slot?.startTime.toISOString(),
      end_time: slot?.endTime.toISOString(),
      referred_by_physician: gpOrOpticianReferral?.hasReferral,
      referral: gpOrOpticianReferral?.referralFile,
      reason:
        gpOrOpticianReferral?.bookingReason &&
        gpOrOpticianReferral?.bookingReason.trim(),
      self_booking: !patientCarerDetails?.isCarerDifferent,
      self_paying: patientInsuranceDetail?.selfPaying,
      payment_mode: patientInsuranceDetail?.paymentMode || undefined,
      insurance_id: patientInsuranceDetail?.insuranceId,
      membership_no: patientInsuranceDetail?.membershipNumber?.trim(),
      hospital_id: patientUser?.hospitalId,
      preferred_point_of_contact:
        patientContactDetail?.patient.preferredContactMethod,
      country_code: '+44',
      contact_mobile_number: (
        patientContactDetail?.patient.phoneNumber ||
        registered?.patientMobileNumber
      )?.toString(),
      contact_email:
        patientContactDetail?.patient.email.trim() || registered?.email?.trim(),

      patient: {
        id: registered?.patientId,
        nhs_number: nhsNumber,
        prefix: patientDetails?.title,
        first_name: patientDetails?.firstName.trim(),
        last_name: patientDetails?.lastName.trim(),
        dob: patientDetails?.dob.toISOString(),
        gender: patientDetails?.gender,
        country_code: '+44',
        mobile_number: patientUser?.mobileNumber,
        email: patientUser?.email,
        password: password?.trim(),

        address: {
          line1: patientDetails?.address.trim(),
          line2: patientDetails?.address2 && patientDetails?.address2.trim(),
          city: patientDetails?.city.trim(),
          country: patientDetails?.country.trim(),
          postcode: patientDetails?.postcode.trim(),
        },
      },

      care_taker: carerDetails && {
        first_name: carerDetails.firstName?.trim(),
        last_name: carerDetails.lastName?.trim(),
        country_code: '+44',
        mobile_number: carerDetails.phoneNumber,
        email: carerDetails.email?.trim(),
        relationship: carerDetails.relationship,
      },
    },
  };
}

export function getPasswordErrors(password?: string): Set<string> {
  if (!password) return new Set();

  try {
    passwordSchema.validateSync(password, {abortEarly: false});
  } catch (error) {
    if (error instanceof ValidationError) {
      return error.inner.reduce((acc, obj) => {
        acc.add(obj.message);
        return acc;
      }, new Set<string>());
    }
  }

  return new Set();
}

export function ValidateOtpResponseToBookingData(
  responseData: PostValidateOtpResponseData
): BookingData {
  const {data} = responseData;

  const {
    prefix,
    nhs_number,
    first_name,
    last_name,
    gender,
    dob,
    address,
    email,
    preferred_point_of_contact,
    mobile_number,
  } = data.patient;

  return {
    patientContactDetail: {
      patient: {
        email: email,
        phoneNumber: Number(mobile_number),
        preferredContactMethod: preferred_point_of_contact,
      },
    },
    patientCarerDetails: {
      isCarerDifferent: false,
      patient: {
        nhsNumber: Number(nhs_number),
        title: prefix,
        firstName: first_name,
        lastName: last_name,
        gender: gender,
        dob: new Date(dob),
        city: address?.city,
        address: address?.line1,
        address2: address?.line2,
        country: address?.country,
        postcode: address?.postcode,
      },
    },
  };
}

export const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  event.preventDefault();
};
