import {type SxProps} from '@mui/material';

import theme from '$modules/booking/theme';

const styles: Record<string, SxProps> = {
  appointmentCard: {
    background: theme.palette.common.white,
    borderRadius: '0.5rem',
    border: theme.palette.grey[300],
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  primaryDarkestText: {
    color: theme.palette.primary.main,
  },
};

export default styles;
