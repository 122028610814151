import {FaceContentIcon, PlusSquareIcon} from '$assets/svgs';
import {PATIENTCARELIST_SKELETONIITEM_COUNT} from '$modules/frontdesk/constant';
import '././styles.scss';

const PatientCareListSkeleton = () => {
  const skeletonItems = Array.from({
    length: PATIENTCARELIST_SKELETONIITEM_COUNT,
  });
  return (
    <div className="patient-care overflow-auto row-span-2">
      <div className="title-container">
        <div className="title">
          <FaceContentIcon />
          <span className="title-text">Patient care</span>
        </div>
        <PlusSquareIcon className="icon patinet-care-icon" />
      </div>
      <div className="box-container space-y-2 mt-4">
        {skeletonItems.map((_, index) => (
          <div
            key={index}
            className="box-item w-full h-10 bg-gray-200 rounded-md animate-pulse"
          ></div>
        ))}
      </div>
    </div>
  );
};

export default PatientCareListSkeleton;
