import {parseSnakeCase} from '../utils';

export const statusColorMap: {[key: string]: string} = {
  to_be_confirm: 'bg-[#d92d20]',
  pending: 'bg-[#d92d20]',
  confirm: 'bg-[#03820f]',
};

export const getAppointmentStatus = (status: string) => {
  return status === 'pending' ? 'To be confirmed' : parseSnakeCase(status);
};
