import UserCircleIcon from '$assets/svgs/user-circle.svg?react';
import {Avatar} from '@mui/material';

interface NoteAuthorProps {
  profileUrl: string | null;
  fullName: string;
}

function NoteAuthor(props: NoteAuthorProps) {
  const {profileUrl, fullName} = props;

  return (
    <div className="flex items-center">
      {profileUrl ? (
        <Avatar src={profileUrl} className="h-8 w-8 aspect-square" />
      ) : (
        <UserCircleIcon className="primary h-8 w-8 aspect-square" />
      )}
      <span className="ml-2 text-sm tertiary">By: </span>
      <span className="ml-1 text-sm font-bold text-primary">{fullName}</span>
    </div>
  );
}

export default NoteAuthor;
