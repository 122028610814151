import React from 'react';
import {TextField} from '@mui/material';
import {otpInputStyle} from './style';

interface OtpInputProps {
  value: string;
  onChange: (value: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  maxLength: number;
  autoFocus?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
}

const OtpInput: React.FC<OtpInputProps> = ({
  value,
  onChange,
  onKeyDown,
  maxLength,
  autoFocus = false,
  inputRef,
}) => {
  return (
    <TextField
      name="otp"
      value={value}
      onChange={e => onChange(e.target.value)}
      onKeyDown={onKeyDown}
      inputProps={{
        maxLength: maxLength,
      }}
      sx={otpInputStyle}
      type="tel"
      autoFocus={autoFocus}
      inputRef={inputRef}
    />
  );
};

export default OtpInput;
